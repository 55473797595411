import useAxios from 'app/useAxios';
import { PowerProblemsItem } from 'features/ExternalPowerProblemsReportManager/PowerProblemsReportModels';
import { setPowerProblemsItems } from 'features/ExternalPowerProblemsReportManager/powerProblemsReportSlice';
import { User, UserAccountsItem, UserStatuses } from 'features/UserManager/UserModels';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useToast } from 'shared/hooks';
//import {  setUserProjects, setUserStatuses, setUsers } from '../../userSlice';

export const usePowerProblemReport = () => {
  const {loading, request } = useAxios();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { showError, showSuccess } = useToast();

  
  interface filterData{
  startDate: string,
  endDate: string,
}
  return {
    getPowerProblemsReport: (filter: filterData) =>
    request<undefined, { data: PowerProblemsItem[], succeeded:boolean }>({
      method: 'post',
      showSuccessMessageCode: undefined,
      url: `/maintenance/batteryreport/power-problem`,
      onDataReceiveCompleted: (data) => {
        dispatch(setPowerProblemsItems(data.data));
        if(!data.succeeded){
          showError(t('error_toast_text'));
        }
      },
      payload:filter
    }),
    isLoadingUser: loading,
    isLoading: loading,
  };
};

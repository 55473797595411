import { FC } from 'react';
import localStorageService from 'shared/localStorage';
import { DashboardData } from 'shared/models';

interface CustomizedLabelProps {
  value: number;
  y: number;
  x: number;
	width:number;
	name:string;
	data:DashboardData[];
  stroke:string;
  height:number;
	selectItem: (name:string)=>void
}

export const CustomizedLabel: FC<CustomizedLabelProps> = (props:CustomizedLabelProps) => {
  const isRTL =localStorageService.isRTL;
  const {x, y, stroke, value, width, height,data,name, selectItem} = props;
  const currentItem=data.find((item)=>item.name===name)
	const key=currentItem.key
  return (
		<text style={{cursor:"pointer"}} onClick={()=>selectItem(key)} x={x + width+4} y={y+height/2} dy={5} fill={stroke} fontSize={14} textAnchor={isRTL ? 'end' : 'start'} >{value}</text>
  );
};

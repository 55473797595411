import { useAppSelector, useExtendedTranslation } from 'shared/hooks';
import { useState } from 'react';
export const useFilterRegisterType = () => {
  const { t } = useExtendedTranslation();
  const registerTypeFilterItems = [
    {
      value: 'Status Bit',
      label: t('read_only_bits_register_type'),
    },
    {
      value: 'Set Bit',
      label: t('set_bit_register_type'),
    },
    {
      value: 'Read Only',
      label: t('read_only_register_type'),
    },
    {
      value: 'Set Points',
      label: t('set_points_register_type'),
    },
    {
      value: 'Accumulators',
      label: t('accumulators_register_type'),
    },
  ];
  const [registerTypeFilter, setRegisterTypeFilter] = useState<string>('');
  const onChangeRegisterTypeFilter = (value: string) => setRegisterTypeFilter(value);
  return {
    registerTypeFilter,
    onChangeRegisterTypeFilter,
    registerTypeFilterItems,
  };
};

import { FC } from 'react';
import { Row, Icon } from 'shared/ui';
import { Typography, Box, Tooltip} from '@mui/material';
import { SxProps } from '@mui/system';
import { cross_blue} from 'shared/assets';
import { getFormattedDate } from 'shared/lib';
type Props = {
  labels: Record<string, string>;
  onChange: (value: string) => void;
  onExpirationDateClick: ()=> void;
  value: string;
  sx?: SxProps;
  selected?: boolean;
  tooltipText:string;
};

export const ControlLabelWithDelete: FC<Props> = ({ labels, sx, value, onChange, onExpirationDateClick, selected, tooltipText}) => {
  return (
    <Row
      sx={{
        height: '33px',
        px: '2px',
        color: 'black',
        display: 'flex',
        justifyContent: 'space-between',
        columnGap: '10px',
        borderBottom: '1px solid ',
        borderColor: selected ? 'primary.dark' : 'secondary.light',
        mx: '10px',
        fontSize: '12px',
        '&:hover': { bgcolor: 'secondary.contrastText' },
        ...sx,
      }}
    >
      <Typography
        sx={{
          width: '15%',
          fontWeight: 'revert',
          fontSize: 'revert',
        }}
      >
        {labels.id}
      </Typography>
      <Typography
        sx={{
          width: '65%',
          fontWeight: 'revert',
          fontSize: 'revert',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {labels.name}
      </Typography>
      <Typography
        sx={{
          width: '100px',
          fontWeight: '700',
          fontSize: 'revert',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          color: 'primary.dark',
        }}
        onClick={onExpirationDateClick}
      >
      {labels.expirationDate && getFormattedDate(labels.expirationDate)}
      </Typography>
      <Row sx={{width:'20px'}}> 
       <Tooltip title={tooltipText} placement="top">
      <Box><Icon sx={{ cursor: 'pointer' }} onClick={() => onChange(value)} src={cross_blue} /></Box>
      </Tooltip> 
   
      </Row>
    </Row>
  );
};

import useAxios from 'app/useAxios';
import {
  AssignedCustomer,
  IrrigationController,
  IrrigationFactorData,
  PlantType,
  RainData,
  WeatherStation,
} from 'features/WeatherStationsManager/WeatherStationModels';
import {
  setWeatherStation,
  setAssignedCustomers,
  setPlantType,
  setEvaporationAndRainData,
  setIrrigationFactorData,
  setIrrigationControllers,
  setGeographicZone,
} from 'features/WeatherStationsManager/WeatherStationsSlice';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useToast } from 'shared/hooks';

interface NewMaxEvaporationRate {
  wE_ObjectID: number;
  customerID: number;
  newValue: number;
}

interface RetroactiveUpdateData {
  wE_ObjectID: number;
  customerID: number;
  dateFrom: string;
  dateTo: string;
}
interface TargetFactorUpdateData {
  objectID: number;
  customerID: number;
  date_: string;
  factor: number;
}
interface PlantTypeUpdateData {
  id: number;
  status: number;
}

export const useWeatherStationsManager = () => {
  const dispatch = useAppDispatch();
  const { loading, request } = useAxios();
  const { showError } = useToast();
  const { t } = useTranslation();
  return {
    getWeatherStations: () =>
      request<undefined, { data: WeatherStation[]; succeeded: boolean }>({
        method: 'get',
        showSuccessMessageCode: undefined,
        url: `/weatherStation`,
        onDataReceiveCompleted: (data) => {
          if (data.succeeded) {
            dispatch(setWeatherStation(data?.data));
          } else {
            showError(t('error_toast_text'));
          }
        },
      }),

    getAssignedCustomers: (objectID: number) =>
      request<undefined, { data: AssignedCustomer[]; succeeded: boolean }>({
        method: 'get',
        showSuccessMessageCode: undefined,
        url: `/weatherStation/assignedCustomers?objectID=${objectID}`,
        onDataReceiveCompleted: (data) => {
          if (data.succeeded) {
            dispatch(setAssignedCustomers(data?.data));
          } else {
            showError(t('error_toast_text'));
          }
        },
      }),

    getPlantTypeData: (objectID: number, customerID: number) =>
      request<undefined, { data: PlantType[]; succeeded: boolean }>({
        method: 'get',
        showSuccessMessageCode: undefined,
        url: `/weatherStation/plantType?objectID=${objectID}&customerID=${customerID}`,
        onDataReceiveCompleted: (data) => {
          if (data.succeeded) {
            dispatch(setPlantType(data?.data));
          } else {
            showError(t('error_toast_text'));
          }
        },
      }),

    getEvaporationAndRainData: (dateFrom: string, dateTo: string, objectID: number) =>
      request<undefined, { data: RainData[]; succeeded: boolean }>({
        method: 'get',
        showSuccessMessageCode: undefined,
        url: `/weatherStation/evaporationAndRainData?dateFrom=${dateFrom}&dateTo=${dateTo}&objectID=${objectID}`,
        onDataReceiveCompleted: (data) => {
          if (data.succeeded) {
            dispatch(setEvaporationAndRainData(data?.data));
          } else {
            showError(t('error_toast_text'));
          }
        },
      }),

    getIrrigationFactorData: (dateFrom: string, dateTo: string, objectID: number, customerID: number) =>
      request<undefined, { data: IrrigationFactorData[]; succeeded: boolean }>({
        method: 'get',
        showSuccessMessageCode: undefined,
        url: `/weatherStation/irrigationFactorData?dateFrom=${dateFrom}&dateTo=${dateTo}&objectID=${objectID}&customerID=${customerID}`,
        onDataReceiveCompleted: (data) => {
          if (data.succeeded) {
            dispatch(setIrrigationFactorData(data?.data));
          } else {
            showError(t('error_toast_text'));
          }
        },
      }),

    updateMaxEvaporationRate: (data: NewMaxEvaporationRate) =>
      request<undefined, { succeeded: boolean }>({
        method: 'put',
        showSuccessMessageCode: undefined,
        url: `/weatherStation/maxEvaporationRate?`,
        payload: data,
        onDataReceiveCompleted: (data) => {
          if (data.succeeded) {
            return true;
            //dispatch(setIrrigationFactorData(data?.data));
          } else {
            showError(t('error_toast_text'));
          }
        },
      }),

    updateRetroactive: (data: RetroactiveUpdateData) =>
      request<undefined, { succeeded: boolean }>({
        method: 'put',
        showSuccessMessageCode: undefined,
        url: `/weatherStation/retroactive`,
        payload: data,
        onDataReceiveCompleted: (data) => {
          if (data.succeeded) {
            return true;
            //dispatch(setIrrigationFactorData(data?.data));
          } else {
            showError(t('error_toast_text'));
          }
        },
      }),

    getIrrigationControllers: (objectID: number, customerID: number) =>
      request<undefined, { data: IrrigationController[]; succeeded: boolean }>({
        method: 'get',
        showSuccessMessageCode: undefined,
        url: `/weatherStation/irrigationControllers?weObjectID=${objectID}&customerID=${customerID}`,
        onDataReceiveCompleted: (data) => {
          if (data.succeeded) {
            dispatch(setIrrigationControllers(data?.data));
          } else {
            showError(t('error_toast_text'));
          }
        },
      }),

    getGeographicZone: (customerID: number) =>
      request<undefined, { data: IrrigationController[]; succeeded: boolean }>({
        method: 'get',
        showSuccessMessageCode: undefined,
        url: `/weatherStation/geographicZone?customerID=${customerID}`,
        onDataReceiveCompleted: (data) => {
          if (data.succeeded) {
            dispatch(setGeographicZone(data?.data));
          } else {
            showError(t('error_toast_text'));
          }
        },
      }),

    updateTargetFactor: (data: TargetFactorUpdateData[]) =>
      request<undefined, { succeeded: boolean }>({
        method: 'put',
        showSuccessMessageCode: undefined,
        url: `/weatherStation/targetFactor`,
        payload: { factors: data },
        onDataReceiveCompleted: (data) => {
          if (data.succeeded) {
            return true;
            //dispatch(setIrrigationFactorData(data?.data));
          } else {
            showError(t('error_toast_text'));
          }
        },
      }),

    updatePlantType: (data: PlantTypeUpdateData[], objectID: number, customerID: number) =>
      request<undefined, { succeeded: boolean }>({
        method: 'put',
        showSuccessMessageCode: undefined,
        url: `/weatherStation/plantType`,
        payload: {
          objectID,
          customerID,
          PlantTypes: data,
        },
        onDataReceiveCompleted: (data) => {
          if (data.succeeded) {
            return true;
            //dispatch(setIrrigationFactorData(data?.data));
          } else {
            showError(t('error_toast_text'));
          }
        },
      }),

    loading,
    isLoadingWeatherStations: loading,
  };
};


import { Component } from "../whatsAppModels";
import { Params } from "./SendWaMessage/hooks/useWaMessageSettings";

const getValues=(str:string)=>{
    const regex = /\{\{(\d+)\}\}/g;
    const matches = str.match(regex);
    return matches ? matches.map(match => match.replace(/^{{|}}$/g, '')) : []
}
const addValues=(values:string[], type:string)=>{
    const params:Params[]=[]
    values.forEach(value=>{
        params.push({
            number:value,
            type:type,
            key:`${type}_${value}`,
            value: ''
        })
    })
  
    return params
 
}
export const getParamsMessage = (currentTemplate: Component[]) => {
    let params:Params[] = [];
    
    const header = currentTemplate.find(item=>item.type=="HEADER")
    const body = currentTemplate.find(item=>item.type=="BODY")
    const footer = currentTemplate.find(item=>item.type=="FOOTER")

        if(header){
            const values = getValues(header.text)
            params = params.concat(addValues(values, 'header'))
        }
        if(body){
            const values = getValues(body.text)
            params = params.concat(addValues(values, 'body'))
        }
        if(footer){
            const values = getValues(footer.text)
            params = params.concat(addValues(values, 'footer'))
        }
  return params;
};

import { Box, CircularProgress } from '@mui/material';
import { BaseSelect, Filter, RealTimeControllerTable } from 'features';
import { EditController } from 'features/ControllerManager/components';
import { FC, useEffect, useMemo, useState } from 'react';
import { useControllers, useGetCustomers } from 'shared/api';
import { useAppDispatch, useAppSelector, useExtendedTranslation } from 'shared/hooks';
import { getSearchedControllers } from 'shared/lib';
import { Controller, DeviceModelTypes } from 'shared/models';
import { toggleEditController } from 'shared/slices';
import { BaseBtn, BigTitle, Loader, Row, SearchInput } from 'shared/ui';
import TotalRows from 'shared/ui/TotalRows';
import { useFilterControllers, useFormatXlsxData, useSearchControllers } from './hooks';
import { BaseExportToExcel } from 'components';
import { GridComparatorFn } from '@mui/x-data-grid-pro';
import { TABLE_TITLES, alarmStatusValueGetter, batteryVoltageValueGetter, controllerTimeValueGetter, currentFlowValueGetter, dataSourceValueGetter, rainRateValueGetter, syncDateValueGetter, totalSlavesValueGetter } from './constants';
export type TableTitle = {
  title: string;
  key: string;
  comparator?: GridComparatorFn;
  valueGetter?: FC;
  width: number | string;
};

export const RealTimeControllerDataManager = () => {
  const dispatch = useAppDispatch();
  const [controllers, setControllers] = useState<Controller[]>([]);
  useEffect(() => {
    getCustomer();
  }, []);
  const {
    sourceTypeFilter,
    onChangeSourceTypeFilter,
    sourceTypesFilterItems,
    modelTypeFilter,
    onChangeModelTypeFilter,
    modelTypesFilterItems,
    customerFilterItems,
    selectedCustomer,
    onChangeSelectedCustomer,
  } = useFilterControllers();
  const getFormatControllers = (controllers) => {
    const newControllers = controllers.map((item) => {
      return item;
    });
    return newControllers;
  };

  const { formatTable } = useFormatXlsxData(modelTypeFilter);
  const { getControllersWithState, isLoadingControllers, error } = useControllers();
  const { isLoadingCustomers, getCustomer } = useGetCustomers();
  const { searchValue, changeSearch } = useSearchControllers();
  const { t } = useExtendedTranslation();
  //temp
  const { currentController } = useAppSelector((st) => st.controllers);
  const { openEditController } = useAppSelector((st) => st.modals);
  //
  const [selectedController, setSelectedController] = useState<Controller>(null);
  const [openControllerEditModal, setOpenControllerEditModal] = useState(false);
  const tableControllers = useMemo(() => {

    const filteredControllers = controllers;
    const formatedControllers = getFormatControllers(filteredControllers);
    const searchedControllers = getSearchedControllers(formatedControllers, searchValue);
    return searchedControllers;
  }, [searchValue, controllers]);
  const handleSelectedCustomer = (event) => {
    setControllers([]);
    onChangeSelectedCustomer(event);
  };
  const handleChangeSourceTypeFilter = (event) => {
    setControllers([]);
    onChangeSourceTypeFilter(event);
  };
  const handleChangeModelTypeFilter = (event) => {
    setControllers([]);
    onChangeModelTypeFilter(event);
  };
  const onDeviceStateFetch = () => {
    setControllers([]);
    getControllersWithState({
      customerId: selectedCustomer,
      modelId: modelTypeFilter,
      dataSourceType: sourceTypeFilter,
    }).then((data) => {
      setControllers(data?.data ?? []);
    });
  };
  const handleOpenControllerEditModal = (openEditController: boolean) => {
    setOpenControllerEditModal(openEditController);
  };
  const closeControllerEditModal = () => {
    dispatch(toggleEditController());
    //setOpenControllerEditModal(false);
  };
  //temp
  useEffect(() => {
    setSelectedController(currentController);
  }, [currentController]);

  const [tableTitles, setTableTitles] = useState<TableTitle[]>([]);
  useEffect(() => {
    createTableTitles();
  }, []);

  const createTableTitles = () => {
    const tableTitles: TableTitle[] = [...TABLE_TITLES];

    switch (modelTypeFilter) {
      case 'I1':
        tableTitles.push({
          title: 'alarm_status',
          key: 'alarmStatus',
          valueGetter: alarmStatusValueGetter,
          width: '100%',
        });
        tableTitles.push({
          title: 'battery_voltage',
          key: 'batteryVoltage',
          valueGetter: batteryVoltageValueGetter,
          width: '100%',
        });
        tableTitles.push({
          title: 'controller_real_time',
          key: 'controllerTime',
          valueGetter: controllerTimeValueGetter,
          width: '100%',
        });
        tableTitles.push({
          title: 'data_source',
          key: 'dataSource',
          valueGetter: dataSourceValueGetter,
          width: '100%',
        });
        tableTitles.push({ title: 'sync_date', key: 'syncDate', valueGetter: syncDateValueGetter, width: '100%' });
        break;
      case 'IP':
        tableTitles.push({
          title: 'battery_voltage',
          key: 'batteryVoltage',
          valueGetter: batteryVoltageValueGetter,
          width: '100%',
        });
        tableTitles.push({
          title: 'current_flow',
          key: 'currentFlow',
          valueGetter: currentFlowValueGetter,
          width: '100%',
        });
        tableTitles.push({
          title: 'data_source',
          key: 'dataSource',
          valueGetter: dataSourceValueGetter,
          width: '100%',
        });
        tableTitles.push({ title: 'sync_date', key: 'syncDate', valueGetter: syncDateValueGetter, width: '100%' });
        tableTitles.push({
          title: 'total_slaves_flow',
          key: 'totalSlavesFlow',
          valueGetter: totalSlavesValueGetter,
          width: '100%',
        });
        break;
      case 'WE':
        tableTitles.push({
          title: 'battery_voltage',
          key: 'batteryVoltage',
          valueGetter: batteryVoltageValueGetter,
          width: '100%',
        });
        tableTitles.push({
          title: 'data_source',
          key: 'dataSource',
          valueGetter: dataSourceValueGetter,
          width: '100%',
        });
        tableTitles.push({ title: 'rain_rate', key: 'rainRate', valueGetter: rainRateValueGetter, width: '100%' });
        tableTitles.push({ title: 'sync_date', key: 'syncDate', valueGetter: syncDateValueGetter, width: '100%' });
        break;
    }
    setTableTitles(tableTitles);
  };
  const buildTableData = (value: string) => {

  }
  const BaseExportToExcelComponent = useMemo(
    () => (<BaseExportToExcel
      tableData={tableControllers}
      sheetName="CONTROLLERS"
      fileName="realTimeControllersData.xlsx"
      buttonText={t('export_controllers_page_button')}
      formattedTable={formatTable(tableControllers)}
      dateColumnIndexes={[10]}
      tableTitles={tableTitles}
      headerItemsPrefix={'_real_time_controller_grid_table'}
    />)
    ,
    [tableControllers]
  );

  //


  return (
    <>
      {isLoadingCustomers ? (
        <Loader />
      ) : (
        <>
          <Row sx={{ mb: '12px' }}>
            <BigTitle sx={{ mr: '25px' }}>{t('real_time_controller_data_title')}</BigTitle>
          </Row>
          <SearchInput
            value={searchValue}
            onChange={changeSearch}
            placeholder={`${t('which_client_are_you_looking_for_search_placeholder_controller_page')}`}
          />
          <Row flexWrap="wrap" rowGap="5px">
            <BigTitle sx={{ fontSize: '24px', mr: '20px', mt: '16px' }}>{t('filter_controllers_page_title')}</BigTitle>
            <BaseSelect
              title={`${t('customer_label')}`}
              value={selectedCustomer}
              onChange={handleSelectedCustomer}
              items={customerFilterItems}
              hideAllButton
              withSearch
              noSelectLabel={`${t('customer_label')}`}
              sx={{ width: '260px', mb: '3px' }}
            />
            <Filter
              title={`${t('source_type_label')}`}
              value={sourceTypeFilter.toString()}
              onChange={handleChangeSourceTypeFilter}
              items={sourceTypesFilterItems}
              sx={{ width: '260px' }}
              placeholder={`${t('source_type_placeholder')}`}
              hideAllButton
              hideResetButton
            />
            <Filter
              title={`${t('model_type_label')}`}
              value={modelTypeFilter}
              onChange={(value) => { handleChangeModelTypeFilter(value); buildTableData(value) }}
              items={modelTypesFilterItems}
              sx={{ width: '260px' }}
              placeholder={`${t('model_type_placeholder')}`}
              hideAllButton
              hideResetButton
            />
            <BaseBtn onClick={onDeviceStateFetch} sx={{ mt: '16px', height: '29px' }}>
              {t('controllers_refresh_button')}
            </BaseBtn>
            <Row sx={{ flexGrow: 1 }} />
            <Box sx={{ display: 'flex', marginLeft: 'auto', alignItems: 'flex-end' }}>
              <TotalRows count={tableControllers.length} />

              {BaseExportToExcelComponent}
            </Box>
          </Row>
          {isLoadingControllers ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '60%',
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <RealTimeControllerTable tableControllers={tableControllers} modelTypeFilter={modelTypeFilter} />
          )}
        </>
      )}

      <EditController
        currentController={selectedController}
        openControllerEditModal={openEditController}
        onCloseModal={closeControllerEditModal}
        showRegistersButton
      />
    </>
  );
};

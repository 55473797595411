import { SelectChangeEvent } from '@mui/material';
import { Customer } from 'features/CustomerManager/customerModels';
import { useEffect, useMemo, useRef, useState } from 'react';
import { checkCustomerChanges } from 'shared/lib';

export const useEditCustomerChanges = (currentCustomer, openEditCustomer) => {

  const [values, setValues] = useState<Customer>({
    id: 0,
    customerName: '',
    customerStatusId: 0,
    eMailAddress: '',
    officePhoneNumber: '',
    customerTypeId: 0,
    notes: '',
    createdDate: '',
    operationStartDate: '',
    contactPersonPhoneNumber: '',
    contactPersonName: '',
    postAddress: '',
    customerAgreementStatusId: 0,
    shortNote: '',
    siteUrlCode: 'DEFAULT',
    siteUrlCodeSec: 'DEFAULT-SEC',
  });

  useEffect(() => {
    currentCustomer && setValues(currentCustomer as Customer);
  }, []);

  useEffect(() => {
    currentCustomer && setValues(currentCustomer as Customer);
  }, [openEditCustomer]);

  const isChanged = useMemo(() => {
    return currentCustomer && checkCustomerChanges(currentCustomer as Customer, values);
  }, [values]);

  const onChangeValues = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const onChangeDateValues = (name: string, value: string) => {
    setValues((prev) => ({ ...prev, [name]: value }));
  };
  const onChangeSelectValues = (e: SelectChangeEvent<unknown>) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const boxRef = useRef<HTMLDivElement>();

  return {
    values,
    isChanged,
    onChangeValues,
    onChangeDateValues,
    onChangeSelectValues,
    boxRef,
  };
};

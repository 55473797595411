import { User } from "../UserModels";


export const getSearchedUsers = (users: User[], searchValue: string) => {
  if (!users) return [];
  const searched = users.filter((c) => {
    const values = Object.values(c);

    return values.some((v) => v?.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase().trim()));
  });

  return searched;
};

export const checkUserChanges = (initial: User, checking: User) => {
  const keys = Object.keys(initial);

  const changes = keys.map((key) => (checking?.[key as keyof User] ?? '') !== (initial?.[key as keyof User] ?? ''));

  const isChanged = changes.includes(true);

  return isChanged;
};

import { useAppDispatch } from 'shared/hooks';
import { setCustomerFilter } from 'shared/slices';
import { useAppSelector, useExtendedTranslation } from 'shared/hooks';
import { useState } from 'react';
interface SimProviderItem {
  value: string;
  label: string;
}
export const useDropDown = () => {
  const { t } = useExtendedTranslation();
  const { customers } = useAppSelector((st) => st.customers);
  const { controllers } = useAppSelector((st) => st.controllers);

  const dropDownCustomerItems = customers.map((s) => {
    return {
			value: s.id.toString(),
			label: s.customerName,
    };
  });
  const [customerItem, setCustomerItem] = useState<string>('');
  const onChangeCustomerItems = (values: string) =>  {
    setCustomerItem(values)
    setControllerItem('')
  };

 const dropDownControllersItems = controllers.map((s) => {
    return {
			value: s.deviceId.toString(),
			label: s.objectName,
    };
  });
  const [controllerItem, setControllerItem] = useState<string>('');
  const onChangeControllerItems = (values: string) =>  setControllerItem(values);




  return {
    customerItem,
    onChangeCustomerItems,
    dropDownCustomerItems,

    controllerItem,
    onChangeControllerItems,
    dropDownControllersItems
  };
};

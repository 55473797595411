import { SelectChangeEvent } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useAppSelector } from 'shared/hooks';
import { checkServiceCallChanges } from 'shared/lib';
import { ServiceCall } from 'shared/models';

export const useEditServiceCallsChanges = () => {
  const { openEditServiceCall } = useAppSelector((st) => st.modals);
  const { currentServiceCall } = useAppSelector((st) => st.serviceCalls);
  const [values, setValues] = useState<ServiceCall>({
    serviceCallID: 0,
    createDate: '',
    currentStatusCode: 0,
    currentStatus: '',
    customerID: 0,
    customerName: '',
    closeDate:'',
    controllerID: 0,
    controllerName: '',
    openByUserID: 0,
    openByUserName: '',
    faultEquipmentCode: 0,
    faultEquipment: '',
    faultDescription: '',
    notesForTechnician: '',
    assignedTechnicianID: 0,
    assignedTechnicianName: '',
    actionDescription: '',
    takenActionCode: 0,
    takenAction: '',
    replacedEquipment1Code: 0,
    replacedEquipment1: '',
    replacedEquipment2Code: 0,
    replacedEquipment2: '',
  });

  useEffect(() => {
    currentServiceCall && setValues(currentServiceCall as ServiceCall);
  }, []);

  useEffect(() => {
    currentServiceCall && setValues(currentServiceCall as ServiceCall);
  }, [openEditServiceCall]);

  const isChanged = useMemo(() => {
    return currentServiceCall && checkServiceCallChanges(currentServiceCall as ServiceCall, values);
  }, [values]);

  const onChangeValues = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const onChangeDateValues = (name: string, value: string) => {
    setValues((prev) => ({ ...prev, [name]: value }));
  };
  const onChangeSelectValues = (e: SelectChangeEvent<unknown>) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const boxRef = useRef<HTMLDivElement>();

  const onEscape = (e: KeyboardEventInit) => {
    if (e.code === 'Escape') {
      // (boxRef.current as HTMLDivElement)?.click();
    }
  };

  useEffect(() => {
    if (openEditServiceCall) {
      window.addEventListener('keyup', onEscape);
    } else {
      window.removeEventListener('keyup', onEscape);
    }
  }, [openEditServiceCall]);

  return {
    values,
    isChanged,
    onChangeValues,
    onChangeDateValues,
    onChangeSelectValues,
    boxRef,
  };
};

import { BaseExportOptions } from "entities_";
import { FC, useEffect, useMemo } from "react";
export type TableTitle<T> = { title: string; key: keyof T; width: number | string };
export type TableTitleSimple = { title: string; key: string; width: number | string };
interface Props<T> {
	tableData: T[];
	sheetName: string;
	fileName: string;
	buttonText: string;
	dateColumnIndexes?: number[];
	stringColumnIndexes?: number[];
	formattedTable: {
		[x: string]: string | number;
	}[];
	tableTitles: TableTitle<T>[] | TableTitleSimple[];
	headerItemsPrefix: string;
	preHeaderItemsPrefix?: string;
	needCutHeaderName?: boolean;
	dateAndTimeColumnIndexes?: number[];
	width?: string;
	sortModel?: { field: string, sort: 'asc' | 'desc' }
}


export const BaseExportToExcel = <T,>({
	tableData,
	sheetName,
	fileName,
	dateColumnIndexes,
	stringColumnIndexes,
	formattedTable,
	dateAndTimeColumnIndexes,
	buttonText,
	tableTitles,
	headerItemsPrefix,
	preHeaderItemsPrefix,
	needCutHeaderName,
	width,
	sortModel
}: Props<T>) => {
	const BaseExportToExcelComponent = useMemo(
		() => {
			return <BaseExportOptions
				tableData={tableData}
				sheetName={sheetName}
				fileName={fileName}
				buttonText={buttonText}
				dateColumnIndexes={dateColumnIndexes}
				stringColumnIndexes={stringColumnIndexes}
				dateAndTimeColumnIndexes={dateAndTimeColumnIndexes}
				formattedTable={formattedTable}
				tableTitles={tableTitles}
				headerItemsPrefix={headerItemsPrefix}
				preHeaderItemsPrefix={preHeaderItemsPrefix}
				needCutHeaderName={needCutHeaderName}
				width={width}
				sortModel={sortModel}
			/>
		}
		,
		[tableData, tableTitles]
		//tableTitles - need for replaced-equipment-report
	);
	return (
		<>{BaseExportToExcelComponent}</>

	)
}
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { getFormattedDate } from 'shared/lib';
interface aboutState {
  versionNumber: string;
  instanceNote: string;
  buildDate: string;
  dbInstanceName: string;
  dbServerName: string;
  versionNotes: string;
}

const initialState: aboutState = {
  versionNumber: '',
  instanceNote: '',
  buildDate: '',
  dbInstanceName: '',
  dbServerName: '',
  versionNotes: '',
};

const aboutSlice = createSlice({
  name: 'popups',
  initialState,
  reducers: {
    setAboutInfo: (state, action: PayloadAction<aboutState>) => {
      state.versionNumber = action.payload.versionNumber;
      state.instanceNote = action.payload.instanceNote;
      state.buildDate = getFormattedDate(action.payload.buildDate as string);
      state.dbInstanceName = action.payload.dbInstanceName;
      state.dbServerName = action.payload.dbServerName;
      state.versionNotes = action.payload.versionNotes;
    },
  },
});

export const { setAboutInfo } = aboutSlice.actions;

export const aboutReducer = aboutSlice.reducer;

import React, { useRef, useEffect } from 'react';

interface OutsideClickListenerProps {
  onOutsideClick: () => void;
  children: React.ReactNode;
}

export const OutsideClickListener: React.FC<OutsideClickListenerProps> = ({ onOutsideClick, children }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const handleClick = (event: MouseEvent) => {
    if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
      onOutsideClick();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  return <div ref={containerRef}>{children}</div>;
};

import { Box, IconButton, Stack } from '@mui/material';
import { FC } from 'react';
import { BigTitle, Row, Modal } from 'shared/ui';
import { close_popup } from 'shared/assets';
import { UnderConstructionBlock } from 'entities_';
import { t } from 'i18next';
interface Props {
  openModal: boolean;
  handleCloseModal: () => void;
}

export const ChangeExpirationDate: FC<Props> = ({ openModal, handleCloseModal }) => {
  return (
    <Modal open={openModal} onClose={handleCloseModal}>
      <Box>
        <Stack direction="row" sx={{ mb: '16px' }} justifyContent="space-between" alignItems="center">
          <BigTitle></BigTitle>
          <IconButton onClick={handleCloseModal}>
            <img src={close_popup} />
          </IconButton>
        </Stack>
        <UnderConstructionBlock />
      </Box>
    </Modal>
  );
};

import { FC, useEffect } from 'react';
import { Stack, IconButton } from '@mui/material';
import { Box, Table, TableBody, Typography, TableCell, useTheme, TableRow } from '@mui/material';
import { Filter } from 'features';
import { useAppDispatch, useExtendedTranslation, useAppSelector } from 'shared/hooks';
import { Modal } from 'shared/ui';
import { toggleOpenAbout } from 'shared/slices';
import { useLanguageSelect } from './hooks';
import { close_popup } from 'shared/assets';
import { useGetAbout } from 'shared/api';
export const About: FC = () => {
  const { palette } = useTheme();
  const dispatch = useAppDispatch();
  const { t } = useExtendedTranslation();
  const { currentLanguage, onChangeLanguage, languages } = useLanguageSelect();
  const { openAbout } = useAppSelector((st) => st.modals);
  const { versionNumber, instanceNote, buildDate, dbInstanceName, dbServerName, versionNotes } = useAppSelector(
    (st) => st.about
  );
  const { getAbout } = useGetAbout();
  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([getAbout()]);
    };
    fetchData();
  }, []);
  const closeModal = () => {
    dispatch(toggleOpenAbout());
  };
  const onClickAway = () => {
    closeModal();
  };

  return (
    <Modal open={openAbout} onClose={closeModal} PaperProps={{ sx: { maxWidth: '562px', borderRadius: '12px' } }}>
      <Box
        sx={{
          p: '41px 36px 36px 36px',
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
        }}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography sx={{ fontSize: '24px', fontWeight: '600' }}>{t('title_about_modal')}</Typography>
          <IconButton onClick={closeModal}>
            <img src={close_popup} />
          </IconButton>
        </Stack>

        <Typography sx={{ fontSize: '24px', fontWeight: '600', color: palette.primary.main }}>
          {t('agam_back_office_system_about_modal')}
        </Typography>

        <Table>
          <TableBody>
            <TableRow>
              <TableCell
                sx={{
                  marginRight: '5px',
                  fontWeight: '600',
                  fontSize: '16px',
                  padding: '16px 0px',
                  paddingRight: '6px',
                  border: 'none',
                }}
              >
                {t('front_end_about_modal')}
              </TableCell>
              <TableCell
                sx={{
                  color: palette.primary.main,
                  fontWeight: '600',
                  fontSize: '16px',
                  padding: '16px 0px',
                  border: 'none',
                }}
              >{`${process.env.REACT_APP_VERSION} ${t('at_about_modal')} ${process.env.REACT_APP_DEPLOY_DATE
                } (${'weatherStations'})`}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  marginRight: '5px',
                  fontWeight: '600',
                  fontSize: '16px',
                  padding: '16px 0px',
                  paddingRight: '6px',
                  border: 'none',
                }}
              >
                {t('back_end_about_modal')}
              </TableCell>
              <TableCell
                sx={{
                  color: palette.primary.main,
                  fontWeight: '600',
                  fontSize: '16px',
                  padding: '16px 0px',
                  border: 'none',
                }}
              >{`${versionNumber} ${t('at_about_modal')} ${buildDate}  (${versionNotes}) `}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  marginRight: '5px',
                  fontWeight: '600',
                  fontSize: '16px',
                  padding: '16px 0px',
                  paddingRight: '6px',
                  border: 'none',
                }}
              >
                {t('translation_about_modal')}
              </TableCell>
              <TableCell
                sx={{
                  color: palette.primary.main,
                  fontWeight: '600',
                  fontSize: '16px',
                  padding: '16px 0px',
                  border: 'none',
                }}
              >
                {t('translation_version')}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  marginRight: '5px',
                  fontWeight: '600',
                  fontSize: '16px',
                  padding: '16px 0px',
                  paddingRight: '6px',
                  border: 'none',
                }}
              >
                {t('note_about_modal')}
              </TableCell>
              <TableCell
                sx={{
                  color: palette.primary.main,
                  fontWeight: '600',
                  fontSize: '16px',
                  padding: '16px 0px',
                  border: 'none',
                }}
              >
                {instanceNote}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  marginRight: '5px',
                  fontWeight: '600',
                  fontSize: '16px',
                  padding: '16px 0px',
                  paddingRight: '6px',
                  border: 'none',
                }}
              >
                {t('database_about_modal')}
              </TableCell>
              <TableCell
                sx={{
                  color: palette.primary.main,
                  fontWeight: '600',
                  fontSize: '16px',
                  padding: '16px 0px',
                  border: 'none',
                }}
              >{`${dbServerName} (${dbInstanceName})`}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  marginRight: '5px',
                  fontWeight: '600',
                  fontSize: '16px',
                  padding: '16px 0px',
                  paddingRight: '6px',
                  border: 'none',
                }}
              >
                {t('language_about_modal')}
              </TableCell>
              <TableCell
                sx={{
                  color: palette.primary.main,
                  fontWeight: '600',
                  fontSize: '16px',
                  padding: '16px 0px',
                  border: 'none',
                }}
              >
                <Filter
                  title=""
                  value={currentLanguage}
                  onChange={onChangeLanguage}
                  items={languages}
                  hideAllButton
                  hideResetButton
                  sx={{ width: '260px' }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Typography sx={{ marginRight: '5px', marginTop: '60px', fontSize: '16px', fontWeight: '400' }}>
          {t('copyright_about_modal')}
        </Typography>
        <Typography sx={{ marginRight: '5px', fontSize: '16px', fontWeight: '400' }}></Typography>
      </Box>
    </Modal>
  );
};

import { TableTitle, TranslateColumn } from 'components/BaseTable';
import { DashboardDetails } from 'shared/models';
import { BatteryStatusItem } from '../batteryStatusItem';
type FilterItems = {
  value: string;
  label: string;
};
export const activeHoursItems: FilterItems[] = [
  { value: '0', label: '0_active_Hours_battery_status_report' },
  { value: '1', label: '1_active_Hour_battery_status_report' },
  { value: '2', label: '2_active_Hours_battery_status_report' },
  { value: '3', label: '3_active_Hours_battery_status_report' },
  { value: '4', label: '4_active_Hours_battery_status_report' },
  { value: '5', label: '5_active_Hours_battery_status_report' },
  { value: '6', label: '6_active_Hours_battery_status_report' },
  { value: '7', label: '7_active_Hours_battery_status_report' },
  { value: '8', label: '8_active_Hours_battery_status_report' },
  { value: '9', label: '9_active_Hours_battery_status_report' },
  { value: '10', label: '10_active_Hours_battery_status_report' },
  { value: '11', label: '11_active_Hours_battery_status_report' },
  { value: '12', label: '12_active_Hours_battery_status_report' },
  { value: '13', label: '13_active_Hours_battery_status_report' },
  { value: '14', label: '14_active_Hours_battery_status_report' },
  { value: '15', label: '15_active_Hours_battery_status_report' },
  { value: '16', label: '16_active_Hours_battery_status_report' },
  { value: '17', label: '17_active_Hours_battery_status_report' },
  { value: '18', label: '18_active_Hours_battery_status_report' },
  { value: '19', label: '19_active_Hours_battery_status_report' },
  { value: '20', label: '20_active_Hours_battery_status_report' },
  { value: '21', label: '21_active_Hour_battery_status_report' },
  { value: '22', label: '22_active_Hours_battery_status_report' },
  { value: '23', label: '23_active_Hours_battery_status_report' },
  { value: '24', label: '24_active_Hours_battery_status_report' },
];

export const disconnectsItems: FilterItems[] = [
  { value: '10', label: '10%' },
  { value: '20', label: '20%' },
  { value: '30', label: '30%' },
  { value: '40', label: '40%' },
  { value: '50', label: '50%' },
  { value: '60', label: '60%' },
  { value: '70', label: '70%' },
  { value: '80', label: '80%' },
  { value: '90', label: '90%' },
  { value: '100', label: '100%' },
];

export const serviceCallsItems: FilterItems[] = [
  { value: 'Hide', label: 'hide_controllers_with_open_service_calls' },
  { value: 'Show', label: 'show_only_controllers_with_open_service_calls' },

];




//18px - 1 letter
export const TABLE_TITLES: TableTitle<BatteryStatusItem>[] = [
  { title: 'project_id', key: 'projectId', width: '100%' },
  { title: 'project_name', key: 'projectName', width: '300%' },
  { title: 'hwid', key: 'HWID', width: '100%' },
  { title: 'object_id', key: 'objectId', width: '100%' },
  { title: 'controller_name', key: 'controllerName', width: '400%' },
  { title: 'ctlver', key: 'CTLVer', width: '100%' },
  { title: 'powersupcode', key: 'powerSupCode', width: '300%' },
  { title: 'disconnect_type', key: 'disconnectType', width: '200%' },
  { title: 'last_open_service_call_date', key: 'lastOpenServiceCallDate', width: '200%' },
];
// export const TRANSLATE_COLUMNS: TranslateColumn<DashboardDetails>[]=[
// { key: 'deviceType', additionalKey:'_chart_item' },
// { key: 'cellProvider', additionalKey:'_chart_item_cell_provider' },
// {key: 'disabledFlag', additionalKey:''  }

// ]

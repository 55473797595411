import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { userReducer } from 'features/UserManager/userSlice';
import { customerReducer } from 'shared/slices';
import { controllerReducer } from 'shared/slices';
import { profileReducer } from 'shared/slices';
import { modalReducer } from 'shared/slices';
import { popupReducer } from 'shared/slices';
import { aboutReducer } from 'shared/slices';
import { deviceTransferringReducer } from 'shared/slices';
import { batteryStatusReportReducer } from 'shared/slices';
import { simManagementReducer } from 'shared/slices';
import { globalSliceReducer } from 'shared/slices';
import { serviceCallsReducer } from 'shared/slices';
import { equipmentReplacementReportReducer } from 'shared/slices';
import { networkProtectionReducer } from 'shared/slices';
import { dashboardReducer } from 'shared/slices';
import { dashboardDetailsReducer } from 'shared/slices';
import { powerProblemsReducer  } from 'features/ExternalPowerProblemsReportManager/powerProblemsReportSlice';
import { fotaReducer } from 'features/FotaManager/fotaSlice';
import { wAMessageStatusReducer } from 'features/WAMessageStatusManager/WAMessageStatusSlice';
import { whatsAppReducer } from 'features/CustomerManager/whatsAppSlice';
import { allServiceCallsReducer } from 'features/ServiceCallsReportManager/AllServiceCalls';
import { weatherStationReducer } from 'features/WeatherStationsManager/WeatherStationsSlice'
const rootReducer = combineReducers({
  customers: customerReducer,
  controllers: controllerReducer,
  deviceTransferring: deviceTransferringReducer,
  profile: profileReducer,
  users: userReducer,
  modals: modalReducer,
  popups: popupReducer,
  about: aboutReducer,
  batteryStatusReport: batteryStatusReportReducer,
  simManagement: simManagementReducer,
  globalSlice: globalSliceReducer,
  serviceCalls: serviceCallsReducer,
  dashboard: dashboardReducer,
  equipmentReplacementReport: equipmentReplacementReportReducer,
  networkProtection: networkProtectionReducer,
  dashboardDetails: dashboardDetailsReducer,
  powerProblems: powerProblemsReducer,
  fota: fotaReducer,
  whatsApp: whatsAppReducer,
  wAMessageStatus: wAMessageStatusReducer,
  allServiceCalls: allServiceCallsReducer,
  weatherStation: weatherStationReducer
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];

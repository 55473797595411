import { SelectChangeEvent } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useAppSelector } from 'shared/hooks';
import { checkSimChanges } from 'shared/lib';
import { Sim } from 'shared/models';

export const useEditSimChanges = () => {
  const { openEditSim } = useAppSelector((st) => st.modals);
  const { currentSim } = useAppSelector((st) => st.simManagement);

  const [values, setValues] = useState<Sim>({
    id: 0,
    simNumber: '',
    phoneNumber: '',
    simProvider: '',
    statusCode: '',
    lastDeviceId: 0,
    deviceName: '',
    customerName: '',
    customerId: 0,
    activationDate: '',
    deActivationDate: '',
    lastCommunicationDate: '',
    lastUpdateDate: '',
    notes: '',
  });

  useEffect(() => {
    currentSim && setValues(currentSim as Sim);
  }, []);

  useEffect(() => {
    currentSim && setValues(currentSim as Sim);
  }, [openEditSim]);

  const isChanged = useMemo(() => {
    return currentSim && checkSimChanges(currentSim as Sim, values);
  }, [values]);

  const onChangeValues = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const onChangeDateValues = (name: string, value: string) => {
    setValues((prev) => ({ ...prev, [name]: value }));
  };
  const onChangeSelectValues = (e: SelectChangeEvent<unknown>) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const boxRef = useRef<HTMLDivElement>();

  const onEscape = (e: KeyboardEventInit) => {
    if (e.code === 'Escape') {
      // (boxRef.current as HTMLDivElement)?.click();
    }
  };

  useEffect(() => {
    if (openEditSim) {
      window.addEventListener('keyup', onEscape);
    } else {
      window.removeEventListener('keyup', onEscape);
    }
  }, [openEditSim]);

  return {
    values,
    isChanged,
    onChangeValues,
    onChangeDateValues,
    onChangeSelectValues,
    boxRef,
  };
};

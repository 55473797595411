import { BorderBottom, BorderColor } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { BaseBtn } from 'shared/ui';
import { Row, } from 'shared/ui';
import { useTranslation } from 'react-i18next';
type Props = {
  titles: { text: string, value: number }[];
  activeTab: number;
  changeTab: (value: number) => void;
};
export const BaseTabs: FC<Props> = ({ titles, activeTab, changeTab }) => {
  const { t } = useTranslation();
  return (
    <Row spacing="0px" mb={'0px'} sx={{ borderBottom: '1px solid', borderColor: 'primary.dark', height: '36px' }}>
      {titles.map(title =>
        <Box key={title.value} onClick={() => changeTab(title.value)} sx={{ borderRadius: '20px' }}>
          {/* btnColor={activeTab===title.value ? "primary.dark": 'info.main'} */}
          {activeTab === title.value ? <Box sx={{ cursor: "pointer", color: 'primary.dark', padding: '5px 20px', border: '1px solid', borderColor: 'primary.dark', borderBottomColor: 'white', marginBottom: '-1px', borderRadius: '15px 15px 0 0', fontWeight: '700' }}>
            {t(title.text)}
          </Box> :

            <Box sx={{ cursor: "pointer", color: 'rgba(1, 48, 112, 0.5)', padding: '5px 20px', border: '1px solid', borderColor: 'rgba(1, 48, 112, 0.5)', marginBottom: '-1px', borderRadius: '15px 15px 0 0', background: '#F7F9FA', fontWeight: '400' }}>
              {t(title.text)}
            </Box>
          }

        </Box>
      )}
    </Row>
  );
};

import { AxiosError } from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { axiosBase } from 'shared/config';
import { useAppDispatch, useToast } from 'shared/hooks';
import { CustomerDto } from 'shared/models';
import { toggleAddCustomer, toggleDeleteCustomer } from 'shared/slices';

type DeleteDTO = {
  id: number;
};

export const useDeleteCustomer = () => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation()
  const { showError, showSuccess } = useToast();

  const [isLoading, setLoading] = useState(false);

  const deleteCustomer = async (dto: DeleteDTO) => {
    setLoading(true);

    try {
      await axiosBase.delete('/Customer', { data: dto });
      showSuccess(t('customer_successfully_deleted_toast_text'));
    } catch (error) {
      const err = error as AxiosError;
      console.log('#ERROR', err);
      showError(t('error_toast_text'));
    } finally {
      setLoading(false);
      dispatch(toggleDeleteCustomer());
    }
  };

  return { isLoading, deleteCustomer };
};

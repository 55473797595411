import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Controller,ControllerRegisters, DailyConsumption, ControllerClearDataFilter } from './controllerModels';
interface CustomerState {
  controllers: Controller[];
  selectedControllers: number[];
  customerIDForFilter: number | string;
  currentController: Controller | null;
  dailyConsumption: DailyConsumption[];
  controllerClearDataFilter: ControllerClearDataFilter;
  defaultData: { startDate: string; endDate: string };
  controllerClearDataFilterChanged: boolean;
  controllerRegisters:ControllerRegisters
}

const initialState: CustomerState = {
  controllers: [],
  selectedControllers: [],
  customerIDForFilter: '',
  currentController: null,
  dailyConsumption: [
    {
      date: '',
      totalProg: 0,
      totalActual: 0,
      totalUncontrolled: 0,
    },
  ],
  controllerClearDataFilter: {
    startDate: '2023-07-20',
    endDate: '2023-07-29',
    uncontrolledFlow: true,
    actualConsumption: true,
  },
  defaultData: {
    startDate: '',
    endDate: '',
  },
  controllerClearDataFilterChanged: false,
  controllerRegisters: null
};

const controllerSlice = createSlice({
  name: 'controllers',
  initialState,
  reducers: {
    setControllers: (state, action: PayloadAction<Controller[]>) => {
      
      state.controllers = action.payload
    },
    setCustomerFilter: (state, action: PayloadAction<number | string>) => {
      state.customerIDForFilter = action.payload;
    },
    setCurrentController: (state, action: PayloadAction<Controller | null>) => {
      state.currentController = action.payload;
    },
    editCurrentController: (state, action: PayloadAction<Controller>) => {
      const editingControllers = action.payload;
      state.controllers = state.controllers.map((c) =>
        c.deviceId === editingControllers.deviceId ? editingControllers : c
      );
    },
    setDailyConsumption: (state, action: PayloadAction<DailyConsumption[]>) => {
      state.dailyConsumption = action.payload;
    },
    setDailyConsumptionDefaultData: (state, action: PayloadAction<{ startDate: string; endDate: string }>) => {
      state.defaultData = action.payload;
      state.controllerClearDataFilter = {
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        uncontrolledFlow: true,
        actualConsumption: true,
      };
    },
    setControllerClearDataFilter: (state, action: PayloadAction<ControllerClearDataFilter>) => {
      state.controllerClearDataFilter = action.payload;
      controllerSlice;
    },
    setDailyConsumptionChanged: (state, action: PayloadAction<boolean>) => {
      state.controllerClearDataFilterChanged = action.payload;
    },
  },
});

export const {
  setControllers,
  setCustomerFilter,
  setCurrentController,
  editCurrentController,
  setDailyConsumption,
  setDailyConsumptionDefaultData,
  setControllerClearDataFilter,
  setDailyConsumptionChanged,
} = controllerSlice.actions;

export const controllerReducer = controllerSlice.reducer;

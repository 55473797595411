import { Box, IconButton, Stack } from '@mui/material';
import { AssignedCustomer } from 'features/WeatherStationsManager/WeatherStationModels';
import { t } from 'i18next';
import { FC } from 'react';
import { close_popup } from 'shared/assets';
import { BaseBtn, BigTitle, Modal, Row } from 'shared/ui';

export type CustomerItem = {
  value: string;
  label: string;
  isMainCustomer: boolean;
};

interface Props {
  customer: CustomerItem;
  controllerName: string;
  openModal: boolean;
  onCloseModal: () => void;
}

export const DeleteCustomer: FC<Props> = ({ customer, openModal, onCloseModal, controllerName }) => {
  const deleteCustomer = () => {};
  return (
    <Modal open={openModal} onClose={onCloseModal} padding={'8px 16px'}>
      <Box>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <BigTitle>{t('are_you_sure_to_delete_customer_modal')}</BigTitle>
          <IconButton onClick={onCloseModal}>
            <img src={close_popup} />
          </IconButton>
        </Stack>
        <Box>
          <Row spacing="20px" mb={'10px'}>
            <Box>{t('station_name_delete_customer_modal')}</Box>
            <Box>{customer?.label}</Box>
          </Row>
          <Row spacing="20px" mb={'10px'}>
            <Box>{t('client_name_delete_customer_modal')}</Box>
            <Box>{controllerName}</Box>
          </Row>
        </Box>
        <Row justifyContent="center">
          <Row width={300} gap="10px">
            <BaseBtn onClick={onCloseModal} btnColor="info.main" fullWidth>
              {t('cancel_delete_assigned_customer_button')}
            </BaseBtn>
            <BaseBtn type="submit" onClick={deleteCustomer} btnColor="primary.dark" fullWidth>
              {t('delete_assigned_customer_button')}
            </BaseBtn>
          </Row>
        </Row>
      </Box>
    </Modal>
  );
};

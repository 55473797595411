import useAxios from 'app/useAxios';
import { useAppDispatch } from 'shared/hooks';
import { Controller, DeviceModelTypes, ControllerRegisters} from 'shared/models';
import { setControllers} from 'shared/slices';

interface ControllerDataForRequest{
  customerId: number | string,
  modelId:DeviceModelTypes,
  dataSourceType:DataSourceTypes,
  syncDate?:string,
  devicetId:number
  }


export const enum DataSourceTypes {Realtime=0,History=1}
export const useControllers = () => {
  const dispatch = useAppDispatch();
  const { loading, request,error} = useAxios();

  return {
    getControllers: (customerId: number | string) =>
      request<{ customerId: number | string}, { data: Controller[] }>({
        method: 'post',
        url: `/device`,
        showSuccessMessageCode: undefined,
        onDataReceiveCompleted: (data) => {
          dispatch(setControllers(data?.data));
        },
        payload:{ customerId: customerId?.toString() ? customerId?.toString() : null }
      }),
    getControllersWithState: (input:ControllerDataForRequest) =>
      request<{ customerId: number | string}, { data: Controller[] }>({
        method: 'post',
        url: `/device/state`,
        showSuccessMessageCode: undefined,
        onDataReceiveCompleted: (data) => {
          dispatch(setControllers(data?.data));
        },
        payload: {...input,customerId:input.customerId?.toString()}
      }),
    getControllerWithState: (input:ControllerDataForRequest) =>
      request<{ customerId: number | string}, { data: Controller }>({
        method: 'post',
        url: `/device/state`,
        showSuccessMessageCode: undefined,
        payload: {...input,customerId:input.customerId?.toString()}
      }),

      isLoadingControllers: loading,
      error
  };
};
import { MappedExcelSimData } from 'shared/models';
import { IconButton, Stack, Typography, LinearProgress, Box, CircularProgress } from '@mui/material';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { close_popup } from 'shared/assets';
import { useAnchorEl, useAppDispatch, useAppSelector, useToast } from 'shared/hooks';
import {
  toggleImportSim,
  setSimImportResult,
  // toggleCreateProviderModal,
  setSimImportStatus,
  setSimImportTotalRows,
  setSimImportProvider,
  setSimImportErrors,
  setSimNotValidRows,
  setSimImportFileName,
} from 'shared/slices';
import { BigTitle, Confirm, Modal, Row } from 'shared/ui';
import { ImportExcel } from 'features';
import { useOperatorsSelect, useConnection } from './hooks';
import { BaseBtn } from '../../shared/ui';
import { useSimManagement } from 'shared/api';
// import checkIcon from '../../shared/assets/check.svg';
// import trashIcon from '../../shared/assets/trash.svg';
import { ImportSimProviderFilter } from 'features/ImportSimProviderFilter';
import { useMutation, useQueryClient } from 'react-query';
import { info_icon, close_popup } from 'shared/assets/';
import { CustomTooltip } from './components/CustomTooltip';
import { relative } from 'path';
export const ImportSim: FC = () => {
  const { isLoading, isUploadingSim, saveSimsFromExcel, getOperator } = useSimManagement();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { showSuccess } = useToast();
  const { simOperatorsItems, onChangeSimOperatorsSelect, simOperatorsSelect } = useOperatorsSelect();
  const { openImportSim } = useAppSelector((st) => st.modals);
  const [loading, setLoading] = useState(false);
  const {
    simImportResult,
    simImportProvider,
    simImportFileName,
    simImportErrors,
    simImportStatus,
    simImportTotalRows,
    simNotValidRows,
  } = useAppSelector((st) => st.simManagement);
  const [excelData, setExcelData] = useState<MappedExcelSimData[] | null>(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipHold, setTooltipHold] = useState(false);
  const { isOpen, anchorEl, onOpenMenu, onCloseMenu } = useAnchorEl();
  // const closeModal = () => dispatch(toggleImportSim());
  const closeModal = () => {
    dispatch(toggleImportSim());
    resetModal();
  };
  const resetModal = () => {
    dispatch(setSimImportStatus('no_file_selected_import_status'));
    dispatch(setSimImportProvider(''));
    dispatch(setSimImportTotalRows(0));
    dispatch(setSimNotValidRows([]));
    dispatch(setSimImportErrors([]));
    dispatch(setSimImportFileName(''));
    onChangeSimOperatorsSelect('');
    setExcelData(null);
  };
  const handleClose = (e: React.MouseEvent<HTMLElement>) => {
    excelData ? onOpenMenu(e) : closeModal();
  };

  const { connectToSimHub, progress, setProgress, connectionId, stopConnection } = useConnection();

  const changeExcelData = (MappedExcelSimData: MappedExcelSimData[] | null) => {
    setTooltipHold(false);
    handleShowTooltip(false);
    setExcelData(MappedExcelSimData);
    if (MappedExcelSimData && !MappedExcelSimData[0]) {
      //dispatch(setSimImportTotalRows(0));

      dispatch(setSimNotValidRows(Array.from({ length: simImportTotalRows })));
    }
    dispatch(setSimImportResult(null));
    setLoading(false);
    // showSuccess('file_load_success_import_sim');
  };

  useEffect(() => {
    if (!loading && !simImportErrors.length && simImportTotalRows > 0) {
      showSuccess(t('file_load_success_import_sim'));
    }
  }, [loading]);

  useEffect(() => {
    if (!isUploadingSim && openImportSim) {
      showSuccess(t('database_update_success_import_sim'));
    }
  }, [isUploadingSim]);

  // const handleOpenCreateOperator = () => {
  //   dispatch(toggleCreateProviderModal());
  // };

  // const handleRemoveData = () => {
  //   setExcelData(null);
  //   dispatch(setSimImportResult(null));
  // };

  useEffect(() => {
    if (openImportSim) {
      connectToSimHub();
    } else {
      stopConnection();
      setExcelData(null);
      dispatch(setSimImportResult(null));
      onChangeSimOperatorsSelect('');
    }
  }, [openImportSim]);

  useEffect(() => {
    getOperator();
  }, []);

  const queryClient = useQueryClient();

  const mutation = useMutation(
    () => saveSimsFromExcel({ connectionId, data: excelData, simOperator: simOperatorsSelect }),
    {
      onSuccess: () => queryClient.invalidateQueries(['sim data']),
    }
  );

  const handleSaveExcelData = () => {
    if (excelData) {
      // saveSimsFromExcel({ connectionId, data: excelData, simOperator: simOperatorsSelect });
      mutation.mutate();
      // if (progress >= 98) {

      // }
      setExcelData(null);
    }
  };

  const handleShowTooltip = (status: boolean) => {
    if (!tooltipHold) {
      setTooltipOpen(status);
    }
  };
  const handleHoldTooltip = (status: boolean) => {
    setTooltipHold(status);
  };
  const handleCloseTooltip = () => {
    setTooltipHold(false);
    setTooltipOpen(false);
  };
  const validRows = simImportTotalRows - simNotValidRows.length;

  useEffect(() => {
    const defaultProvider = () => {
      onChangeSimOperatorsSelect(simImportProvider);
    };
    defaultProvider();
  }, [simImportProvider]);

  const selectFile = () => {
    setLoading(true);
    onChangeSimOperatorsSelect('');
    dispatch(setSimImportStatus('no_file_selected_import_status'));
    dispatch(setSimImportProvider(''));
    dispatch(setSimImportTotalRows(0));
    dispatch(setSimNotValidRows([]));
    dispatch(setSimImportErrors([]));
    setProgress(0);
  };
  const closeButtonRef = useRef<HTMLButtonElement | null>(null);
  const handleModalClose = () => {
    if (closeButtonRef.current) {
      closeButtonRef.current.click();
    }
  };
  return (
    <Modal
      open={openImportSim}
      PaperProps={{
        sx: {
          width: '496px',
          height: '596px',
          borderRadius: '12px',
          pl: '36px',
          pr: '26px',
          pt: '41px',
          pb: '29px',
        },
      }}
      disableEscapeKeyDown
      onClose={handleModalClose}
    >
      {loading && (
        <CircularProgress
          sx={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}
          size={40}
        />
      )}
      {isLoading ? (
        <Box sx={{ width: '420px', mt: '5px', position: 'absolute', top: '50%' }}>
          {progress ? (
            <LinearProgress variant="determinate" value={progress} />
          ) : (
            <CircularProgress
              sx={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}
              size={40}
            />
          )}
        </Box>
      ) : (
        <></>
      )}
      <Box sx={{ opacity: loading || isLoading ? '0.2' : 1 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <BigTitle>{t('import_SIM_List_modal')}</BigTitle>

          <IconButton ref={closeButtonRef} onClick={handleClose}>
            <img src={close_popup} />
          </IconButton>
        </Stack>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            mt: '20px',
            mb: '32px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flex: 1,
              }}
            >
              <Typography
                sx={{
                  color: '#27272A',
                  fontFamily: 'Noto Sans Hebrew',
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '200%',
                  letterSpacing: '0.326px',
                }}
              >
                {t('sim_file_to_import')}
              </Typography>
            </Box>

            <Box sx={{ flex: 1 }}>
              <ImportExcel onSelectFile={selectFile} onChange={changeExcelData} onResetFile={resetModal} />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
              }}
            >
              <Typography
                sx={{
                  color: '#27272A',
                  fontFamily: 'Noto Sans Hebrew',
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '200%',
                  letterSpacing: '0.326px',
                }}
              >
                {t('sim_total')}
              </Typography>
            </Box>
            <Box sx={{ marginRight: 'auto', flex: 1, color: '#083F81' }}>
              {!loading && simImportTotalRows ? simImportTotalRows - 1 : 0}
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
              }}
            >
              <Typography
                sx={{
                  color: '#27272A',
                  fontFamily: 'Noto Sans Hebrew',
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '200%',
                  letterSpacing: '0.326px',
                }}
              >
                {t('sim_valid_rows')}
              </Typography>
            </Box>
            <Box sx={{ marginRight: 'auto', flex: 1, color: '#083F81' }}>
              {loading || validRows < 1 ? 0 : validRows - 1}
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
              }}
            >
              <Typography
                sx={{
                  color: '#27272A',
                  fontFamily: 'Noto Sans Hebrew',
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '200%',
                  letterSpacing: '0.326px',
                }}
              >
                {t('sim_status')}
              </Typography>
            </Box>
            {simImportErrors.length ? (
              <CustomTooltip
                open={tooltipOpen || tooltipHold}
                onClose={() => handleShowTooltip(false)}
                title={
                  <React.Fragment>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <BigTitle></BigTitle>
                        <IconButton
                          sx={{ width: '15px', height: '15px', cursor: 'pointer', position: 'relative' }}
                          onClick={() => handleCloseTooltip()}
                        >
                          <img style={{ width: '20px', height: '20px' }} src={close_popup} />
                        </IconButton>
                      </Stack>
                      {simImportErrors.map((error, index) => (
                        <Typography key={index}>
                          {error.errInfo.row}
                          {t(error.errInfo.error)}
                        </Typography>
                      ))}
                    </Box>
                  </React.Fragment>
                }
                placement="right-start"
              >
                <Box
                  onClick={() => handleHoldTooltip(true)}
                  onMouseEnter={() => handleShowTooltip(true)}
                  sx={{ marginRight: 'auto', display: 'flex', flex: 1, cursor: 'pointer', alignItems: 'center' }}
                >
                  <Box sx={{ color: '#083F81', mr: '5px' }}>{t(simImportStatus)}</Box>
                  <img
                    src={info_icon}
                    style={{
                      height: '18px',
                      width: '18px',
                    }}
                  />
                </Box>
              </CustomTooltip>
            ) : (
              <Box sx={{ marginRight: 'auto', flex: 1, color: '#083F81' }}>{t(simImportStatus)} </Box>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
              }}
            >
              <Typography
                sx={{
                  color: '#27272A',
                  fontFamily: 'Noto Sans Hebrew',
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '200%',
                  letterSpacing: '0.326px',
                }}
              >
                {t('sim_provider_name_title')}
              </Typography>
            </Box>
            <Box sx={{ marginRight: 'auto', flex: 1 }}>
              {simOperatorsItems && simOperatorsItems.length && (
                <ImportSimProviderFilter
                  title={``}
                  value={simOperatorsSelect}
                  onChange={onChangeSimOperatorsSelect}
                  items={simOperatorsItems}
                  // defaultValue={simImportProvider}
                  sx={{
                    width: '100%',
                    '.meaningless-key-1f2hyfb-MuiButtonBase-root-MuiButton-root': { justifyContent: 'space-between' },
                  }}
                  messageEmptyFilter={`${t('select_provider_sim_provider')}`}
                  placeholder={`${t('filter_sim_provider_placeholder')}`}
                />
              )}
            </Box>
          </Box>
        </Box>
        {!simImportResult && (
          <Box
            sx={{
              width: '148px',
            }}
          >
            <BaseBtn
              disabled={(excelData && !excelData.length) || !simOperatorsSelect}
              sx={{ fontFamily: 'Noto Sans Hebrew' }}
              btnColor="primary.dark"
              style={{ height: '34px' }}
              onClick={handleSaveExcelData}
              fullWidth
            >
              {t('sim_update')}
            </BaseBtn>
          </Box>
        )}
        {/* <Typography
        sx={{
          mt: '10px',
          fontFamily: 'Noto Sans Hebrew',
          fontSize: '12px',
          fontWeight: 500,
          lineHeight: 'normal',
          color: 'info.dark',
        }}
      >
        {t('sim_import_btn_label')}
      </Typography> */}
        {/* <Row mt={'6px'} columnGap="16px">
        <ImportExcel onChange={changeExcelData} />
        {excelData && (
          <>
            <Box component="img" src={checkIcon} sx={{ width: '27px', height: '27px' }}></Box>
            <Box
              onClick={handleRemoveData}
              component="img"
              src={trashIcon}
              sx={{ width: '27px', height: '27px', cursor: 'pointer' }}
            ></Box>
          </>
        )}
      </Row> */}
        <Box></Box>

        <Row mt={'6px'} columnGap="16px">
          {/* {simOperatorsItems && simOperatorsItems.length && (
          <Filter
            title={`${t('sim_provider_filter_title')}`}
            value={simOperatorsSelect}
            onChange={onChangeSimOperatorsSelect}
            items={simOperatorsItems}
            sx={{
              width: '470px',
              mt: '24px',
              '.meaningless-key-1f2hyfb-MuiButtonBase-root-MuiButton-root': { justifyContent: 'space-between' },
            }}
            messageEmptyFilter={`${t('display_all_sim_provider')}`}
            placeholder={`${t('filter_sim_provider_placeholder')}`}
          />
        )}
        <BaseBtn
          sx={{ fontFamily: 'Noto Sans Hebrew', height: '29px', marginTop: '38px', width: '240px' }}
          btnColor="primary.dark"
          onClick={handleOpenCreateOperator}
        >
          {t('sim_open_new_operator')}
        </BaseBtn> */}
        </Row>
        {/* <Typography
        sx={{
          mt: '24px',
          fontFamily: 'Noto Sans Hebrew',
          fontSize: '24px',
          fontWeight: 600,
          lineHeight: 'normal',
          color: '#083F81',
        }}
      >
        {t('sim_provider_name_title')}
      </Typography> */}
        {/* {excelData ? (
        <Typography>number of lists: {excelData.length}</Typography>
      ) : (
        <Typography>download the Excel file</Typography>
      )} */}
        {/* {!simImportResult ? (
        <BaseBtn
          disabled={!excelData || !simOperatorsSelect}
          sx={{ fontFamily: 'Noto Sans Hebrew' }}
          btnColor="info.main"
          onClick={handleSaveExcelData}
        >
          save
        </BaseBtn>
      ) : ( */}

        <Box
          sx={{
            mt: '20px',
            display: 'flex',
            columnGap: '10px',
            mb: '30px',
          }}
        >
          <Box sx={{ flex: 1 }}>
            <Typography
              sx={{
                color: '#27272A',
                fontFamily: 'Noto Sans Hebrew',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '200%',
                letterSpacing: '0.326px',
              }}
            >
              {t('sim_total')}
            </Typography>
            <Typography
              sx={{
                color: '#27272A',
                fontFamily: 'Noto Sans Hebrew',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '200%',
                letterSpacing: '0.326px',
              }}
            >
              {t('sim_new_lists')}
            </Typography>
            {/* <Typography
            sx={{
              color: '#27272A',
              fontFamily: 'Noto Sans Hebrew',
              fontSize: '16px',
              fontWeight: 600,
              lineHeight: '43px',
              letterSpacing: '0.326px',
            }}
          >
            {t('sim_updates')}
          </Typography> */}
            <Typography
              sx={{
                color: '#27272A',
                fontFamily: 'Noto Sans Hebrew',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '200%',
                letterSpacing: '0.326px',
              }}
            >
              {t('sim_unchanged')}
            </Typography>
            <Typography
              sx={{
                color: '#27272A',
                fontFamily: 'Noto Sans Hebrew',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '200%',
                letterSpacing: '0.326px',
              }}
            >
              {t('sim_errors')}
            </Typography>
          </Box>

          <Box sx={{ flex: 1 }}>
            <Typography
              sx={{
                color: '#23609B',
                fontFamily: 'Noto Sans Hebrew',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '200%',
                letterSpacing: '0.326px',
              }}
            >
              {simImportResult ? simImportResult?.total : 0}{' '}
            </Typography>
            <Typography
              sx={{
                color: 'green',
                fontFamily: 'Noto Sans Hebrew',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '200%',
                letterSpacing: '0.326px',
              }}
            >
              {simImportResult ? simImportResult.created : 0}{' '}
            </Typography>
            {/* <Typography
            sx={{
              color: '#23609B',
              fontFamily: 'Noto Sans Hebrew',
              fontSize: '16px',
              fontWeight: 600,
              lineHeight: '43px',
              letterSpacing: '0.326px',
            }}
          >
            {simImportResult ? simImportResult?.updated : 0}
          </Typography> */}
            <Typography
              sx={{
                color: '#23609B',
                fontFamily: 'Noto Sans Hebrew',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '200%',
                letterSpacing: '0.326px',
              }}
            >
              {simImportResult ? simImportResult?.unchanged : 0}{' '}
            </Typography>
            <Typography
              sx={{
                color: 'red',
                fontFamily: 'Noto Sans Hebrew',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '200%',
                letterSpacing: '0.326px',
              }}
            >
              {simImportResult ? simImportResult?.errors : 0}{' '}
            </Typography>
          </Box>
        </Box>
        <Row sx={{ position: 'absolute', bottom: '40px', width: '87%' }} justifyContent="space-between">
          {/* <Typography
          sx={{
            fontFamily: 'Noto Sans Hebrew',
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: 'normal',
            color: '#083F81',
          }}
        >
          {t('sim_total')} {!excelData ? 0 : excelData.length} SIM`s
        </Typography> */}
          <Row justifyContent="flex-end" width="100%">
            {!simImportResult && (
              <Box
                sx={{
                  width: '148px',
                }}
              >
                <BaseBtn
                  sx={{ fontFamily: 'Noto Sans Hebrew' }}
                  btnColor="info.main"
                  style={{ height: '34px' }}
                  fullWidth
                  onClick={handleClose}
                >
                  {t('sim_cancel')}
                </BaseBtn>
              </Box>
            )}
            {/* {!simImportResult && (
            <BaseBtn
              disabled={!excelData || !simOperatorsSelect}
              sx={{ fontFamily: 'Noto Sans Hebrew' }}
              btnColor="primary.dark"
              style={{ height: '34px' }}
              onClick={handleSaveExcelData}
              fullWidth
            >
              {t('sim_update')}
            </BaseBtn>
          )} */}
            {simImportResult && (
              <Box
                sx={{
                  width: '148px',
                }}
              >
                <BaseBtn
                  sx={{ fontFamily: 'Noto Sans Hebrew' }}
                  btnColor="primary.dark"
                  style={{ height: '34px' }}
                  fullWidth
                  onClick={closeModal}
                >
                  {t('sim_ok')}
                </BaseBtn>
              </Box>
            )}
          </Row>
        </Row>
        <Confirm
          open={isOpen}
          anchorEl={anchorEl}
          onClose={onCloseMenu}
          confirm={closeModal}
          buttonTexts={[t('sim_cancel_confirm'), t('sim_back_confirm')]}
        />
      </Box>
      {/* )} */}
    </Modal>
  );
};

import React, { FC, PropsWithChildren } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext, ILoginResponse } from 'shared/contexts';
import localStorageService from 'shared/localStorage';
export const AuthWrapper: FC<PropsWithChildren> = ({ children }) => {
  const push = useNavigate();
  const { pathname } = useLocation();

  const getDataFromLocalStorage = () => {
    const data = localStorageService.loginDefaultPage;
    return data ? JSON.parse(data) : null;
  };
  const getDefaultPageLink = () => {
    switch (getDataFromLocalStorage()) {
      case "dashboard":
        return '/dashboard'
      case "shortcuts":
        return '/'
      default:
        return '/'
    }
  }
  const handleSaveToken = (data: ILoginResponse) => {
    const { token } = data;
    localStorageService.userToken = token;
    push(getDefaultPageLink());
  };

  const logout = () => {
    localStorageService.userToken = undefined;
    push('/login');
  };

  // React.useEffect(() => {
  //   if (!token) {
  //     push('/login');
  //   }
  // }, []);

  React.useEffect(() => {
    const token = localStorageService.userToken;
    const isLogin = pathname === '/login';
    const isResetPAssword = '/reset-password'
    if (isResetPAssword === pathname) return
    if (token && isLogin) push('/');
    if (!token && !isLogin) push('/login');
  }, [pathname]);

  return <AuthContext.Provider value={{ handleSaveToken, logout }}>{children}</AuthContext.Provider>;
};

import { useTranslation } from 'react-i18next';
import { UserHistory } from "features/UserManager/UserModels";
export const useFormatXlsxData = () => {
  const { t } = useTranslation();
  const formatTable = (arr: UserHistory[]) => {
    
    const exportArr = arr.map((item) => {
      return {
        [t('action_date_user_history_grid_table')]: item.actionDate,
        [t('action_code_user_history_grid_table')]: item.actionCode ? t(`${item.actionCode}_action_code`) : '',
				[t('status_user_history_grid_table')]:  t(`${item.status}_status_code`),
				[t('user_name_user_history_grid_table')]: item.userName,
				[t('user_login_user_history_grid_table')]: item.userLogin,
				[t('app_name_user_history_grid_table')]: item.appName,
				
				[t('action_by_user_name_user_history_grid_table')]: item.actionByUserName,
				[t('action_by_user_login_user_history_grid_table')]: item.actionByUserLogin,
				[t('action_data_user_history_grid_table')]: item.actionData,
        [t('user_id_user_history_grid_table')]: item.userID ? item.userID : '',
				[t('action_by_user_id_user_history_grid_table')]: item.actionByUserID,
				[t('app_key_user_history_grid_table')]: item.appKey,

        
      };
    });

    return exportArr;
  };

  return { formatTable };
};

import { t } from 'i18next';
import { getDateValues, getFormattedTime } from 'shared/lib';
import { usePlugins } from '../hooks/usePlugins';
import { COUNT_OF_Y_LINES, POSITION_OF_Y_TOOLTIP, WIDTH_OF_Y_AXES } from '../components/Constants';
import localStorageService from 'shared/localStorage';
import {
  chart_manual_valve_opening_start,
  valve_open,
  warning_fault,
  flow_rate,
  water_leakage,
  valve_open_manual,
  signal_lost,
  valve_icon_strong_green,
  missing_pulses,
  low_flow_rate,
  high_flow_rate,
  valve_icon_darker_gray,
  valve_icon_grey,
} from 'shared/assets';
export interface ValueInfo {
  startTime: number;
  endTime: number;
  commandDescription?: string;
  userName?: string;
}
export type CursorMemo = {
  set: (left: number, top: number) => void;
  get: () => { left: number; top: number };
};
interface Opts {
  cursorMemo: CursorMemo;
  valvesInfo: ValueInfo[][] | number;
}
interface InfoLineData {
  label: string;
  dataIndex: number;
}

export const WEEK_DAY_NAMES_ENG = ['Su', 'M', 'Tu', 'W', 'Th', 'F', 'Sa'];
export const WEEK_DAY_NAMES_HE = ['א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ש'];

export const tooltipsPlugin = (opts: Opts) => {
  const { chartSize } = usePlugins();
  let cursortt;
  let seriestt;
  let tooltipDiv;

  function init(u, opts, data) {
    const over = u.over;
    seriestt = opts.series;
    tooltipDiv = document.createElement('div');
    cursortt = tooltipDiv;
    tooltipDiv.className = 'tooltip2';
    tooltipDiv.style.pointerEvents = 'none';
    tooltipDiv.style.position = 'absolute';
    tooltipDiv.style.background = '#F6F6F6E6';
    tooltipDiv.style.padding = '4px';
    tooltipDiv.style.border = '1px solid #888';
    tooltipDiv.style.borderRadius = '12px';

    over.appendChild(tooltipDiv);

    function hideTips() {
      cursortt.style.display = 'none';
      tooltipDiv.style.display = 'none';
    }

    function showTips() {
      tooltipDiv.style.display = 'block';
    }

    over.addEventListener('mouseleave', () => {
      if (!u.cursor._lock) {
        hideTips();
      }
    });

    over.addEventListener('mouseenter', () => {
      showTips();
    });

    if (u.cursor.left < 0) hideTips();
    else showTips();
  }

  const padding = document.createElement('span');
  padding.style.display = 'block';
  padding.style.height = '10px';

  const addDivider = (spans: HTMLElement) => {
    const divider = document.createElement('span');
    divider.style.borderBottom = '1px solid #D1D1D1';
    divider.style.width = '100%';
    divider.style.display = 'block';
    divider.style.height = '10px';
    spans.appendChild(divider);
  };

  const addIcon = (span: HTMLElement, iconSrc, show: boolean, width?: number, height?: number) => {
    const icon = document.createElement('img');
    icon.src = iconSrc;
    icon.width = width ? width : 16;
    icon.height = height ? height : 16;
    icon.style.margin = '0 2px';
    icon.style.opacity = show ? '1' : '0';
    span.appendChild(icon);
  };

  const getCurrentTimeStamp = ({ min, max, value, width }) => {
    const percentage = value / width;
    const timeStamp = min + percentage * (max - min);
    return Math.floor(timeStamp);
  };
  const findRateStamps = (currentValve, timeStamp: number) => {
    return currentValve.filter((item) => {
      if (item.startTime && item.endTime) {
        return item.startTime < timeStamp && timeStamp < item.endTime;
      }
      if (item.startTime && !item.endTime) {
        return item.startTime < timeStamp;
      }
      if (!item.startTime && item.endTime) {
        return timeStamp < item.endTime;
      }
    });
  };
  const addNewInfoLine = (spans: HTMLElement, missingPulse: ValueInfo, infoType) => {
    const label = document.createElement('span');
    const span = document.createElement('span');
    //span.style.marginLeft = "20px"
    switch (infoType) {
      case 'missingPulse':
        {
          label.textContent = t('missing_pulses_chart_tooltip');
          addIcon(span, missing_pulses, true, 14, 20);
        }
        break;
      case 'lowFlowItem':
        {
          label.textContent = t('low_flow_chart_tooltip');
          addIcon(span, low_flow_rate, true, 14, 20);
        }
        break;
      case 'highFlowItem':
        {
          label.textContent = t('high_flow_chart_tooltip');
          addIcon(span, high_flow_rate, true, 14, 20);
        }
        break;
      default:
        break;
    }
    //label.style.display = "inline-block"
    label.style.marginLeft = '4px';

    const startTime = document.createElement('span');
    const endTime = document.createElement('span');
    const separator = document.createElement('span');
    startTime.style.fontWeight = 'bold';
    const dots = document.createElement('span');
    //dots.textContent = `${t('irrigation_chart_tooltip_dots')}`;
    startTime.textContent = missingPulse.startTime ? getFormattedTime(missingPulse.startTime * 1000) : '';
    separator.textContent = `${t('irrigation_chart_tooltip_start_end_separator')}`;
    endTime.textContent = missingPulse.endTime ? getFormattedTime(missingPulse.endTime * 1000) : '';
    endTime.style.fontWeight = 'bold';
    //irrigation_chart_tooltip_separator
    span.appendChild(label);
    span.appendChild(dots);
    span.appendChild(startTime);
    span.appendChild(separator);
    span.appendChild(endTime);
    span.style.display = 'flex';

    spans.appendChild(span);
  };
  const addWaterLeakageLine = (opts: Opts, timeStamp: number, spans: HTMLElement, data: InfoLineData) => {
    const currentValve = opts.valvesInfo[data.dataIndex];

    const highFlowRateStamps = findRateStamps(currentValve, timeStamp);
    const highFlowRateStamp = highFlowRateStamps[0];
    if (!highFlowRateStamp) {
      return false; //
    }

    const span = document.createElement('span');

    span.style.display = 'block';
    span.style.color = '#E00000';
    span.style.fontWeight = 'bold';

    if (highFlowRateStamp.valve) {
      const dots = document.createElement('span');
      //dots.textContent = `${t('irrigation_chart_tooltip_dots')}`;
      highFlowRateStamps.forEach((item) => {
        const span = document.createElement('span');
        const startTime = document.createElement('span');
        const endTime = document.createElement('span');
        const separator = document.createElement('span');
        startTime.textContent = item.startTime ? getFormattedTime(item.startTime * 1000) : '';
        separator.textContent = `${t('irrigation_chart_tooltip_start_end_separator')}`;
        endTime.textContent = item.endTime ? getFormattedTime(item.endTime * 1000) : '';

        const valveElem = document.createElement('span');
        const valve = item.valve;
        valveElem.textContent = valve;
        valveElem.style.fontWeight = 'bold';
        valveElem.style.width = '20px';
        addIcon(span, warning_fault, true);
        span.appendChild(valveElem);
        const label = document.createElement('span');
        label.textContent = `${t(data.label)}`;
        span.appendChild(dots);
        span.appendChild(label);

        span.appendChild(startTime);
        span.appendChild(separator);
        span.appendChild(endTime);
        span.appendChild(padding);
        span.style.display = 'block';
        spans.appendChild(span);
      });
      return true;
    } else {
      const startTime = document.createElement('span');
      const endTime = document.createElement('span');
      const separator = document.createElement('span');
      startTime.textContent = highFlowRateStamp.startTime ? getFormattedTime(highFlowRateStamp.startTime * 1000) : '';

      startTime.style.display = 'inline-block';
      startTime.style.width = '66px';
      startTime.style.margin = '0 4px';

      separator.textContent = `${t('irrigation_chart_tooltip_start_end_separator')}`;
      endTime.textContent = highFlowRateStamp.endTime ? getFormattedTime(highFlowRateStamp.endTime * 1000) : '';

      endTime.style.display = 'inline-block';
      endTime.style.width = '66px';
      endTime.style.margin = '0 4px';

      const label = document.createElement('span');
      label.style.display = 'inline-block';
      label.style.width = '110px';
      //label.style.margin = '0 4px'
      label.textContent = `${t(data.label)}`;
      const dots = document.createElement('span');
      //dots.textContent = `${t('irrigation_chart_tooltip_dots')}`;
      addIcon(span, water_leakage, true, 16, 14);
      span.appendChild(label);
      span.appendChild(dots);
      span.appendChild(startTime);
      span.appendChild(separator);
      span.appendChild(endTime);
      span.appendChild(padding);
      spans.appendChild(span);
    }
  };
  const buildHeader = (spans, timeStamp, status) => {
    const span = document.createElement('span');
    span.style.padding = '10px 0';
    span.style.display = 'flex';
    span.style.whiteSpace = 'nowrap';
    span.style.alignItems = 'center';
    const timeTitle = document.createElement('span');
    timeTitle.textContent = `${t('irrigation_chart_tooltip_time')} `;
    timeTitle.style.paddingRight = '4px';
    const { year, month, day, hour, min, weekDay, sec } = getDateValues(timeStamp * 1000);
    const { isRTL } = localStorageService;
    const date = document.createElement('span');
    const time = document.createElement('span');
    date.style.fontWeight = 'bold';
    time.style.fontWeight = 'bold';
    date.textContent = `${day}.${month}.${year} ${isRTL ? WEEK_DAY_NAMES_HE[weekDay] : WEEK_DAY_NAMES_ENG[weekDay]} `;
    time.textContent = `${' '} ${hour}:${min}:${sec} ${' '}`;
    time.style.margin = '0 12px';
    span.appendChild(timeTitle);
    span.appendChild(date);
    span.appendChild(time);
    //span.appendChild(time);

    const divider = document.createElement('span');
    divider.style.marginLeft = 'auto';
    span.appendChild(divider);

    //hide header icons

    // addIcon(span, warning_fault, status.systemFaults)
    // addIcon(span, flow_rate, status.flowRate)
    // addIcon(span, valve_open_manual, status.manualOperation)
    // addIcon(span, valve_icon_strong_green, status.valveIsOpen)
    // addIcon(span, signal_lost, status.communicationFault)

    spans.insertBefore(span, spans.firstChild);
  };

  function setCursor(u) {
    const { left, top, idx } = u.cursor;
    const { width, height } = chartSize;
    const widthX = u.width - WIDTH_OF_Y_AXES;
    const tooltipWidth = 420;
    const tooltipHeight = 160;
    const borderWidth = 100;
    //const otherSide = 0;
    const otherSide =
      left + tooltipWidth + 60 + borderWidth > width ? tooltipWidth + POSITION_OF_Y_TOOLTIP : -POSITION_OF_Y_TOOLTIP;
    const otherYSide = top + tooltipHeight + 40 + borderWidth > height ? -tooltipHeight - 20 : 20;
    opts?.cursorMemo?.set(left, top);
    // tooltipDiv.style.whiteSpace = "pre"

    tooltipDiv.style.left = left - otherSide + 'px';
    //tooltipDiv.style.top = top + otherYSide + 'px';
    tooltipDiv.style.top = '0px';
    tooltipDiv.style.width = `${tooltipWidth}px`;
    tooltipDiv.style.borderLeft = '1px solid #888';
    tooltipDiv.style.borderRight = '1px solid #888';
    tooltipDiv.style.borderTop = '1px solid #888';
    tooltipDiv.style.borderBottom = '1px solid #888';
    tooltipDiv.style.display = 'block';
    tooltipDiv.style.boxShadow = '0px 4px 4px 0px #00000040';
    tooltipDiv.style.minHeight = `${tooltipHeight}px`;
    const timeStamp = getCurrentTimeStamp({ min: u.scales.x.min, max: u.scales.x.max, value: left, width: widthX });

    const index = opts.valvesInfo[23].findIndex((item) => item > timeStamp);

    const flowRate = opts.valvesInfo[24][index - 1];

    const spans = document.createElement('span');
    const series = u.series;
    tooltipDiv.textContent = '';

    const status = {
      valveIsOpen: false,
      flowRate: false,
      manualOperation: false,
      systemFaults: false,
      communicationFault: false,
    };
    series.forEach((item, index) => {
      const span = document.createElement('span');
      span.style.display = 'flex';
      //span.style.paddingBottom = "10px"
      if (index === 1) {
        addDivider(spans);
        if (flowRate > 0) {
          status.flowRate = true;
        }
        const flow_rate_item = document.createElement('span');
        flow_rate_item.style.display = 'flex';
        flow_rate_item.style.alignItems = 'center';
        flow_rate_item.style.paddingTop = '10px';
        const label = document.createElement('span');
        const dots = document.createElement('span');
        const value = document.createElement('span');
        const flowRateUnit = document.createElement('span');

        flowRateUnit.style.fontWeight = 'bold';
        label.textContent = `${t('flow_rate_chart_tooltip')}`;
        dots.textContent = `${t('irrigation_chart_tooltip_dots')}`;
        flowRateUnit.textContent = ` ${t('flow_rate_unit_chart_tooltip')}`;
        value.textContent = flowRate + '';
        value.style.fontWeight = 'bold';
        flowRateUnit.style.color = '#083F81';
        value.style.color = '#083F81';
        value.style.width = '38px';
        value.style.margin = '0 4px';
        addIcon(flow_rate_item, flow_rate, true);
        flow_rate_item.appendChild(label);
        flow_rate_item.appendChild(dots);

        flow_rate_item.appendChild(value);
        flow_rate_item.appendChild(flowRateUnit);

        span.appendChild(flow_rate_item);

        //

        //const label = document.createElement('span');

        //
        spans.appendChild(span);
        addWaterLeakageLine(opts, timeStamp, spans, { label: 'water_leakage_chart_tooltip', dataIndex: 17 });
        spans.appendChild(padding);
        let error = false;
        error = !!findRateStamps(opts.valvesInfo[17], timeStamp).length;
        status.systemFaults = error ? true : status.systemFaults;

        status.communicationFault = !!findRateStamps(opts.valvesInfo[22], timeStamp).length;

        const userActions = findRateStamps(opts.valvesInfo[16], timeStamp);
        if (userActions.length) {
          //header userActions
          const userActionsBlock = document.createElement('span');
          const userActionsTitle = document.createElement('span');
          const userActionsTitleIcon = document.createElement('span');
          userActionsTitleIcon.style.paddingTop = '4px';
          const userActionsTitleText = document.createElement('span');
          userActionsTitleText.style.fontWeight = 'bold';
          addIcon(userActionsTitleIcon, valve_open_manual, true, 14);

          userActionsTitleText.textContent = `${t('user_commands_chart_tooltip')}`;
          userActionsTitle.appendChild(userActionsTitleIcon);
          userActionsTitle.appendChild(userActionsTitleText);
          userActionsBlock.appendChild(userActionsTitle);

          //content userActions
          //   userActions.foreach((item) => {
          //      const row = document.createElement('span');
          //      const time = document.createElement('span');
          //const commandDescription =
          //const userName =
          //const  = document.createElement('span');
          //});

          console.log(userActions);
          spans.appendChild(userActionsBlock);
        }
        addDivider(spans);
      }
      if (item.label > 0 && item.label < 17) {
        //if (!u.cursor.idxs[index]) return

        const indexOfStartValvesInArray = 15 - (index - (COUNT_OF_Y_LINES - 15));
        const currentValve = opts.valvesInfo[indexOfStartValvesInArray];

        if (!currentValve) {
          //			console.log("currentValve not found")
          return;
        }

        const openCloseValveStamp = currentValve.find((item) => {
          if (item.startTime && item.endTime) {
            return item.startTime < timeStamp && timeStamp < item.endTime;
          }
          if (item.startTime && !item.endTime) {
            return item.startTime < timeStamp;
          }
          if (!item.startTime && item.endTime) {
            return timeStamp < item.endTime;
          }
        });

        let error = false;
        const missingPulses = findRateStamps(opts.valvesInfo[20], timeStamp);
        error = !!missingPulses.length;
        status.systemFaults = error ? true : status.systemFaults;
        const lowFlow = findRateStamps(opts.valvesInfo[19], timeStamp);
        error = !!lowFlow.length;
        status.systemFaults = error ? true : status.systemFaults;
        const highFlow = findRateStamps(opts.valvesInfo[18], timeStamp);
        error = !!highFlow.length;
        status.systemFaults = error ? true : status.systemFaults;

        // error = !!userActions.length;

        const highFlowItem = highFlow.find((el) => el.valve == item.label);
        const missingPulsesItem = missingPulses.find((el) => el.valve == item.label);
        const lowFlowItem = lowFlow.find((el) => el.valve == item.label);

        if (!openCloseValveStamp && !highFlowItem && !missingPulsesItem && !lowFlowItem) {
          //			console.log("openCloseValveStamp not found")
          return;
        }

        // if (!error) {
        // 	//			console.log("openCloseValveStamp not found")
        // 	return
        // }

        const manualOpenValvesData = opts.valvesInfo[21];
        const indexOfStartManualValvesInArray = 15 - (index - (COUNT_OF_Y_LINES - 15));
        const manualOpenValveData = manualOpenValvesData.filter(
          (item) => item.valve + 1 == indexOfStartManualValvesInArray + 1
        );

        //console.log("indexOfStartManualValvesInArray", indexOfStartManualValvesInArray)
        const manualOpenValveStamp = manualOpenValveData.find((item) => {
          if (item.manualValve && item.manualValveEnd) {
            return item.manualValve < timeStamp && timeStamp < item.manualValveEnd;
          }
          if (item.manualValve && !item.manualValveEnd) {
            return item.manualValve < timeStamp;
          }
          if (!item.manualValve && item.manualValveEnd) {
            return timeStamp < item.manualValveEnd;
          }
          return false;
        });

        span.style.display = 'block';
        span.style.paddingTop = '10px';

        //span.appendChild(padding)
        const label = document.createElement('span');
        label.style.display = 'flex';
        label.style.alignItems = 'center';
        label.style.fontWeight = 'bold';
        const valveTitle = document.createElement('span');
        const sharpTitle = document.createElement('span');
        const valueTitle = document.createElement('span');
        valveTitle.textContent = `${t('valve_chart_tooltip')}`;
        sharpTitle.textContent = `${t('irrigation_chart_tooltip_sharp')}`;
        valueTitle.textContent = `${item.label}`;

        //label.style.display = 'block'
        //label.style.width = '320px'
        // label.style.fontWeight = 'bold'
        // label.style.width = "16px";

        const dots = document.createElement('span');
        //dots.textContent = `${t('irrigation_chart_tooltip_dots')}`;

        const open = document.createElement('span');
        open.textContent = `${t('irrigation_chart_tooltip_opened_valve')}`;
        open.style.color = '#007BFF';
        open.style.width = '60px';
        open.style.marginLeft = '4px';
        open.style.display = 'inline-block';
        const closed = document.createElement('span');
        closed.style.width = '56px';
        closed.style.marginLeft = '4px';
        closed.style.display = 'inline-block';
        closed.style.color = '#083F81';
        closed.textContent = `${t('irrigation_chart_tooltip_closed_valve')}`;

        const divider = document.createElement('span');
        divider.textContent = `${t('irrigation_chart_tooltip_open_close_separator')}`;

        const startTime = document.createElement('span');
        startTime.style.width = '68px';
        startTime.style.margin = '0 4px';
        startTime.style.display = 'inline-block';
        startTime.style.color = '#007BFF';
        startTime.style.fontWeight = 'bold';
        const endTime = document.createElement('span');
        endTime.style.width = '60px';
        endTime.style.margin = '0 4px';
        endTime.style.display = 'inline-block';
        endTime.style.color = '#083F81';
        endTime.style.fontWeight = 'bold';
        const separator = document.createElement('span');

        if (manualOpenValveStamp) {
          addIcon(label, valve_icon_darker_gray, true);
        } else if (openCloseValveStamp && openCloseValveStamp.startTime) {
          addIcon(label, valve_icon_strong_green, true);
        } else {
          addIcon(label, valve_icon_grey, true);
        }

        label.appendChild(valveTitle);
        label.appendChild(sharpTitle);
        label.appendChild(valueTitle);

        startTime.textContent =
          openCloseValveStamp && openCloseValveStamp.startTime
            ? getFormattedTime(openCloseValveStamp.startTime * 1000)
            : '';
        //separator.textContent = `${t('irrigation_chart_tooltip_separator')}`
        endTime.textContent =
          openCloseValveStamp && openCloseValveStamp.endTime
            ? getFormattedTime(openCloseValveStamp.endTime * 1000)
            : '';

        status.valveIsOpen = true;

        span.appendChild(label);
        const sep = document.createElement('span');
        sep.style.display = 'inline-block';
        //sep.style.width = "20px";
        span.appendChild(sep);
        if (manualOpenValveStamp) {
          addIcon(span, chart_manual_valve_opening_start, true);
          status.manualOperation = true;
        } else {
          addIcon(span, valve_open, true, 14);
        }

        span.appendChild(open);
        span.appendChild(dots);
        span.appendChild(startTime);
        span.appendChild(divider);
        span.appendChild(closed);
        span.appendChild(separator);
        span.appendChild(endTime);
        spans.appendChild(span);

        // if (!missingPulses.length && !lowFlow.length && !highFlow.length) {
        //
        // }

        if (missingPulses.length) {
          const missingPulse = missingPulses.find((el) => el.valve == item.label);

          if (missingPulse) {
            addNewInfoLine(spans, missingPulse, 'missingPulse');
          }
        }
        if (lowFlow.length) {
          const lowFlowItem = lowFlow.find((el) => el.valve == item.label);

          if (lowFlowItem) {
            addNewInfoLine(spans, lowFlowItem, 'lowFlowItem');
          }
        }
        if (highFlow.length) {
          const highFlowItem = highFlow.find((el) => el.valve == item.label);

          if (highFlowItem) {
            addNewInfoLine(spans, highFlowItem, 'highFlowItem');
          }
        }

        spans.appendChild(padding);
        addDivider(spans);
      }
    });
    buildHeader(spans, timeStamp, status);
    tooltipDiv.appendChild(spans);
  }

  return {
    hooks: {
      init,
      setCursor,
    },
  };
};

import React, { FC, useMemo, useState } from 'react';
import { Box, IconButton, Checkbox, Typography, styled } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';
import { Cell, MenuList, Icon } from 'shared/ui';
import { useAnchorEl, useAppDispatch, useAppSelector } from 'shared/hooks';
import { GridRowParams } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { toggleOpenNotSupportModal } from 'shared/slices';
import { getFormattedDate } from 'shared/lib';
const SelectMark = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '5px',
  p: 0,
  height: '100%',
  backgroundColor: theme.palette.primary.dark,
  borderTopRightRadius: '10px',
  borderBottomRightRadius: '10px',
}));

interface visibleColumns {
  width: number;
}
interface CustomGridRowParams extends GridRowParams {
  visibleColumns: visibleColumns[];
}

export const ScriptParametersItem: FC<CustomGridRowParams> = (props: CustomGridRowParams) => {
  const { scriptParamDescription,
    scrEvent,
    dstEvent } = props.row;
  const { visibleColumns } = props;
  //const { currentScreenUser } = useAppSelector((st) => st.users);
  const cellSizes = visibleColumns.map((s) => s.width);
  const { isOpen, anchorEl, onOpenMenu, onCloseMenu } = useAnchorEl();
  const [activeModal, setActiveModal] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleOpenEditModal = () => {
    dispatch(toggleOpenNotSupportModal());
  };
  const editScriptParamDescription = () => {
    dispatch(toggleOpenNotSupportModal());
  };
  const addScriptParamDescription = () => {
    dispatch(toggleOpenNotSupportModal());
  };
  //const selected = useMemo(() => currentScreenUser?.userID === userID, [currentScreenUser]);
  const selected = false;
  return (
    <>
      <Box
        id={`user_${scriptParamDescription}`}
        sx={{
          cursor: 'pointer',
          '&': {
            bgcolor: selected ? '#d0e7ff' : 'info.main',
          },
          '&:hover': {
            bgcolor: selected ? '#c0d7ff' : '#f0f0f0',
          },
          '& .MuiBox-root': {
            cursor: 'pointer',
          },
        }}
      >
        <>
          <Cell cellSize={cellSizes[0]} sx={{ position: 'relative' }}>
            {scriptParamDescription}
            {selected && <SelectMark />}
            <IconButton sx={{ height: '33px', opacity: 0 }}></IconButton>
          </Cell>
          <Cell cellSize={cellSizes[1]}>{scrEvent ? scrEvent : ''}</Cell>
          <Cell cellSize={cellSizes[2]}>{dstEvent ? dstEvent : ''}</Cell>
          {scriptParamDescription ? (
            <Cell onClick={editScriptParamDescription} cellSize={cellSizes[3]}>
              {t('edit_script_param')}
            </Cell>
          ) : (
            <Cell onClick={addScriptParamDescription} cellSize={cellSizes[3]}>
              {t('add_new_script_param')}
            </Cell>
          )}
        </>
      </Box>
    </>
  );
};

import { AlertProps, Box, IconButton } from '@mui/material';
import React from 'react';
import { Icon, Text } from '../../shared/ui';
import { close } from '../../shared/assets';

interface Props extends AlertProps {
  message: string;
}

export const Toast = React.forwardRef<HTMLDivElement, Props>(function Toast(props, ref) {
  const { color, message, onClose } = props;
  return (
    <Box
      ref={ref}
      sx={{
        minHeight: '35px',
        borderRadius: '20px',
        bgcolor: `${color}.main`,
        position: 'relative',
      }}
    >
      <Box sx={{ my: '10px', ml: '15px', mr: '50px', maxWidth: '350px' }}>
        <Text sx={{ fontFamily: 'Noto Sans Hebrew', fontSize: 18, lineHeight: '25px' }}>{message}</Text>
      </Box>

      <IconButton onClick={onClose} sx={{ position: 'absolute', top: 7, right: 10 }}>
        <Icon src={close} />
      </IconButton>
    </Box>
  );
});

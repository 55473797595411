import { createContext } from 'react';

interface IToastState {
  showSuccess: (message: string) => void;
  showError: (message: string) => void;
}

export const ToastContext = createContext<IToastState>({
  showSuccess: () => {},
  showError: () => {},
});

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ProfileState {
  name: string;
  email: string;
  roles: string[];
}

const initialState: ProfileState = {
  name: '',
  email: '',
  roles: [''],
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfileInfo: (state, action: PayloadAction<ProfileState>) => {
      const { name, email, roles } = action.payload;

      state.name = name;
      state.email = email;
      state.roles = roles;
    },
  },
});

export const { setProfileInfo } = profileSlice.actions;

export const profileReducer = profileSlice.reducer;

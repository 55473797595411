import React, { FC, useMemo, memo, useState } from 'react';
import { Box, Checkbox, IconButton, Typography, styled } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';
import { Cell, Icon, MenuList } from 'shared/ui';
import { useAnchorEl, useAppDispatch, useAppSelector } from 'shared/hooks';
import { delete_icon, duplicate, edit } from 'shared/assets';
import { getFormattedDate } from 'shared/lib';
import { setCurrentController, toggleEditController } from 'shared/slices'; //
import { GridRowParams } from '@mui/x-data-grid-pro';
import { checked_box, un_checked_box } from 'shared/assets';
import { useTranslation } from 'react-i18next';
import { useSelectedControllers } from './hooks/useSelectedControllers';

const SelectMark = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '5px',
  p: 0,
  height: '100%',
  backgroundColor: theme.palette.primary.dark,
  borderTopRightRadius: '10px',
  borderBottomRightRadius: '10px',
}));

interface visibleColumns {
  width: number;
}
interface CustomGridRowParams extends GridRowParams {
  visibleColumns: visibleColumns[];
}

export const ControllersItem: FC<CustomGridRowParams> = memo((props: CustomGridRowParams) => {
  const {
    customerId,
    customerName,
    deviceId,
    objectName,
    hwId,
    modelId,
    stationId,
    gatewayId,
    installAddress,
    gisHorisontal,
    gisVertical,
    latitudeDec,
    longitudeDec,
    installDate,
    objectNotes,
  } = props.row;
  const { checked, onChangeSelectedControllers } = useSelectedControllers(deviceId);
  const { visibleColumns } = props;
  const { currentCustomer } = useAppSelector((st) => st.customers);
  const { currentController } = useAppSelector((st) => st.controllers);
  const cellSizes = visibleColumns.map((s) => s.width);
  const { isOpen, anchorEl, onOpenMenu, onCloseMenu } = useAnchorEl();
  const [activeModal, setActiveModal] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const handleOpenMenu = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setActiveModal(!activeModal);
    onOpenMenu(e);
  };
  const handleCloseMenu = () => {
    setActiveModal(!activeModal);
    onCloseMenu();
  };
  const handleOpenEditModal = () => {
    onCloseMenu();
    setActiveModal(!activeModal);
    dispatch(setCurrentController(props.row));
    dispatch(toggleEditController());
  };
  const deleteUser = () => {
    onCloseMenu();
  };
  const selected = useMemo(() => currentController?.deviceId === deviceId, [currentController]);

  return (
    <>
      <Box
        id={`controller_${deviceId}`}
        sx={{
          '&': {
            bgcolor: checked || selected || activeModal ? 'info.light' : 'info.main',
          },
          '&:hover': {
            bgcolor: 'secondary.main',
          },
        }}
      >
        <>
          <Cell cellSize={cellSizes[0]} hideTooltip sx={{ position: 'relative' }}>
            <Checkbox
              size="small"
              checked={checked}
              onChange={onChangeSelectedControllers}
              icon={<Icon src={un_checked_box} />}
              checkedIcon={<Icon src={checked_box} />}
            ></Checkbox>
             {selected && <SelectMark />}
          </Cell>
          <Cell cellSize={cellSizes[1]} hideTooltip sx={{ position: 'relative' }}>
            {deviceId}
          </Cell>
          <Cell cellSize={cellSizes[2]} onClick={handleOpenEditModal}>
            {objectName}
          </Cell>
          <Cell cellSize={cellSizes[3]}>{customerId}</Cell>
          <Cell cellSize={cellSizes[4]}>{customerName}</Cell>
          <Cell cellSize={cellSizes[5]}>{hwId}</Cell>
          <Cell cellSize={cellSizes[6]}>{modelId}</Cell>
          <Cell cellSize={cellSizes[7]}>{stationId}</Cell>
          <Cell cellSize={cellSizes[8]}>{gatewayId}</Cell>
          <Cell cellSize={cellSizes[9]}>{installAddress}</Cell>
          <Cell cellSize={cellSizes[10]}>{latitudeDec}</Cell>
          <Cell cellSize={cellSizes[11]}>{longitudeDec}</Cell>
          <Cell cellSize={cellSizes[12]}>{installDate ? getFormattedDate(installDate as string) : ''}</Cell>
          <Cell cellSize={cellSizes[13]}>{objectNotes}</Cell>
          <Cell cellSize={cellSizes[14]} hideTooltip>
            <IconButton onClick={handleOpenMenu} sx={{ height: '33px' }}>
              <MoreHoriz sx={{ fontSize: '24px' }} />
            </IconButton>
          </Cell>
        </>
      </Box>
      <MenuList
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        actions={[
          { icon: edit, title: `${t('edit-controllers-table-modal')}`, onClick: handleOpenEditModal },
          { icon: delete_icon, title: `${t('delete-controllers-table-modal')}`, onClick: deleteUser },
          { icon: duplicate, title: `${t('duplicate-controllers-table-modal')}`, onClick: handleCloseMenu },
        ]}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          mr: '40px',
          '& .MuiPaper-root': {
            borderRadius: '12px',
          },
        }}
      />
    </>
  );
});

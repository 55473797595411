import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'shared/hooks';
import { Customer } from 'shared/models';

export const toHerb = (text: string) => {
  return text.split('').reverse().join('');
};

export const useFormatXlsxData = () => {
  const { customerStatuses, customerTypes, customerAgreementCodes } = useAppSelector((st) => st.customers);
  const { t } = useTranslation();

  const formatTable = (arr: Customer[]) => {
    const exportArr = arr.map((item) => {
      const status =
        customerStatuses.find((s) => s.customerStatusCode === item.customerStatusId)?.customerStatus ?? 'None';
      const type = customerTypes.find((t) => t.customerTypeID === item.customerTypeId)?.customerType ?? 'None';
      const agreementStatus =
        customerAgreementCodes.find((s) => s.code === item.customerAgreementStatusId)?.text ?? 'None';
      return {
        [t('id_customer_grid_table')]: item.id,
        [t('name_customer_grid_table')]: item.customerName,
        [t('type_customer_grid_table')]: type,
        [t('status_customer_grid_table')]: status,
        [t('agreement_customer_grid_table')]: agreementStatus,
        [t('address_customer_grid_table')]: item.postAddress,
        [t('phone_customer_grid_table')]: item.officePhoneNumber,
        [t('mail_customer_grid_table')]: item.eMailAddress,
        [t('created_customer_grid_table')]: item.createdDate,
        [t('started_customer_grid_table')]: item.operationStartDate,
        [t('notes_customer_grid_table')]: item.notes,
        [t('contact_name_customer_grid_table')]: item.contactPersonName,
        [t('contact_phone_customer_grid_table')]: item.contactPersonPhoneNumber,
      };
    });

    return exportArr;
  };

  return { formatTable };
};

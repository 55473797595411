import { FC, useMemo, useState, memo } from 'react';
import { Box, Checkbox, Typography, styled } from '@mui/material';
import { Cell, Icon } from 'shared/ui';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { GridRowParams } from '@mui/x-data-grid-pro';
import { checked_box, un_checked_box } from 'shared/assets';
import { useSelectedControllersToMove } from './hooks/useSelectedControllersToMove';

const SelectMark = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '5px',
  p: 0,
  height: '100%',
  backgroundColor: theme.palette.primary.dark,
  borderTopRightRadius: '10px',
  borderBottomRightRadius: '10px',
}));

interface visibleColumns {
  width: number;
}
interface CustomGridRowParams extends GridRowParams {
  visibleColumns: visibleColumns[];
}

export const ControllersToMoveItem: FC<CustomGridRowParams> = memo((props: CustomGridRowParams) => {
  const { customerId, customerName, id, objectName } = props.row;
  const { checked, onChangeSelectedControllersToMove, selectedControllersToMove } = useSelectedControllersToMove(id);
  const { visibleColumns } = props;
  const { currentCustomer } = useAppSelector((st) => st.customers);
  const cellSizes = visibleColumns.map((s) => s.width);

  const dispatch = useAppDispatch();

  const handleChange = () => {
    onChangeSelectedControllersToMove(id);
  };
  const selected = useMemo(() => currentCustomer?.id === id, [currentCustomer]);

  return (
    <>
      <Box
        id={`customer_${id}`}
        sx={{
          '&': {
            bgcolor: checked ? 'info.light' : 'info.main',
          },
          '&:hover': {
            bgcolor: 'secondary.main',
          },
        }}
      >
        <>
          <Cell cellSize={cellSizes[0]} hideTooltip sx={{ position: 'relative' }}>
            <Checkbox
              checked={checked}
              onChange={handleChange}
              icon={<Icon src={un_checked_box} />}
              checkedIcon={<Icon src={checked_box} />}
            ></Checkbox>
            {selected && <SelectMark />}
          </Cell>
          <Cell cellSize={cellSizes[1]}>{id}</Cell>
          <Cell cellSize={cellSizes[2]}>{objectName}</Cell>
          <Cell cellSize={cellSizes[3]}>{customerId}</Cell>
          <Cell cellSize={cellSizes[4]}>{customerName}</Cell>
        </>
      </Box>
    </>
  );
});

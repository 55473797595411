import useAxios from 'app/useAxios';
import { User, UserAccountsItem, UserStatuses } from 'features/UserManager/UserModels';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useToast } from 'shared/hooks';
import {  setUserProjects, setUserStatuses, setUsers } from '../../userSlice';
interface NewAssociatedAccount {
  userId: number,
  associatedProjectId: number,
  associatedUserId: number,
  accessCode: number,
}
export const useUserManagement = () => {
  const {loading, request } = useAxios();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { showError, showSuccess } = useToast();


  const getUserProjects= (currentUserID: number) => request<undefined, { data:UserAccountsItem[], succeeded:boolean }>({
    method: 'get',
    showSuccessMessageCode: undefined,
    url: `/users/associatedaccounts/byid?mainUserId=${currentUserID}`,
    onDataReceiveCompleted: (data) => {
      if (data) {
        dispatch(setUserProjects(data.data));
      } else {
        showError(t('error_toast_text'));
      }
    },
  });
  return {
    getUsers: () =>
    request<undefined, { data: User[], succeeded:boolean }>({
      method: 'get',
      showSuccessMessageCode: undefined,
      url: `/users/report`,
      onDataReceiveCompleted: (data) => {
        if(data.succeeded){
        dispatch(setUsers(data.data));
        }else{
          showError(t('something_went_wrong_toast_text'));
        }
      },
    }),
    getUserStatuses: () =>
    request<undefined, { data: UserStatuses[], succeeded:boolean }>({
      method: 'get',
      showSuccessMessageCode: undefined,
      url: `/users/statuslist`,
      onDataReceiveCompleted: (data) => {
        if(data.succeeded){
          dispatch(setUserStatuses(data.data));
        }else{
          showError(t('something_went_wrong_toast_text'));
        }
      },
    }),
    addAssociatedAccount: async (data: NewAssociatedAccount) =>{
      const result= await request<NewAssociatedAccount, {  data: {result:number, },  succeeded :boolean, error:{message:string} }>({
          method: 'put',
          showSuccessMessageCode: undefined,
          url: `/user/associateaccount`,
          payload: data,
        })
        if (result?.succeeded){
          if(result.data.result>0){
            showSuccess(t('associated_account_added_toast_text'));
            getUserProjects(data.userId);
          }else{
            showError(t(`operation_failed_${result.data.result}`))
          }
          return
        }
         showError(t(result.error.message));
      },
    deleteAssociatedAccount: async (data: NewAssociatedAccount) =>{
      const result= await request<NewAssociatedAccount, {  data: {result:number, }, succeeded :boolean  }>({
          method: 'put',
          showSuccessMessageCode: undefined,
          url: `/user/associateaccount`,
          payload: data,
        })
        if (result?.succeeded){
          showSuccess(t('associated_account_deleted_toast_text'));
          getUserProjects(data.userId);
          return
        }
        showError(t('error_toast_text'));
      },
    getUserProjects,
    isLoadingUser: loading,
    isLoading: loading,
  };
};

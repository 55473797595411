import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PowerProblemsItem } from './PowerProblemsReportModels';

interface PowerProblemsState {
  powerProblemsItems: PowerProblemsItem[];
}

const initialState: PowerProblemsState = {
  powerProblemsItems: [],
};

const powerProblemsSlice = createSlice({
  name: 'powerProblems',
  initialState,
  reducers: {
    setPowerProblemsItems: (state, action: PayloadAction<PowerProblemsItem[]>) => {
      state.powerProblemsItems = action.payload;
    },
  },
});

export const { setPowerProblemsItems } = powerProblemsSlice.actions;

export const powerProblemsReducer = powerProblemsSlice.reducer;

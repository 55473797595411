import { MoreHoriz } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { useAnchorEl } from "shared/hooks";
import { isHebrewFont } from "shared/lib";
import localStorageService from "shared/localStorage";
import { GridMenuList } from 'shared/ui';
interface MenuListAction<T> {
	icon: string, title: string, onClick: (row: T) => void;
}
interface Props<T> {
	actions: MenuListAction<T>[]
	row: T
}

export const ActionMenu = <T,>({ actions, row }: Props<T>) => {
	const isRTL = localStorageService.isRTL;
	const getDirection = (value) => {
		if (isRTL) {
			return (isHebrewFont(value?.toString() ?? '') ? 'rtl' : 'ltr')
		}
		return isHebrewFont(value?.toString() ?? '') ? 'ltr' : 'ltr'
	}
	const { isOpen, anchorEl, onOpenMenu, onCloseMenu } = useAnchorEl();
	const handleCloseMenu = () => {
		onCloseMenu();

	};
	const handleOpenMenu = (e: React.MouseEvent<HTMLElement>) => {
		e.stopPropagation();
		onOpenMenu(e);
	};
	return (
		<>
			<Box
				style={{}}
				sx={{
					fontFamily: 'Noto Sans Hebrew',
					direction: getDirection,
					fontSize: '13px',
					fontWeight: 500,
					userSelect: 'none',
					cursor: 'pointer',
					'&:hover': {
						color: 'black',
						textDecoration: 'none',
					},
					whiteSpace: 'nowrap',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
				}}
			>
				<IconButton onClick={handleOpenMenu} sx={{ height: '33px' }}>
					<MoreHoriz sx={{ fontSize: '24px' }} />
				</IconButton>

			</Box>
			<GridMenuList
				open={isOpen}
				anchorEl={anchorEl}
				onClose={handleCloseMenu}
				actions={actions}
				row={row}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				sx={{
					mr: '40px',
					'& .MuiPaper-root': {
						borderRadius: '12px',
					},
				}}
			/>
		</>
	);
};

import { MappedExcelSimData } from 'shared/models';
// import { export_icon } from '../../shared/assets';
import { BaseBtn, Icon, DeleteWithConfirmation } from '../../shared/ui';
import { FC, useEffect, useState, ChangeEvent, useRef } from 'react';
import { useAppDispatch, useAppSelector, useExtendedTranslation } from 'shared/hooks';
import { useToast } from 'shared/hooks';
// import * as ExcelJS from 'exceljs';
import * as XLSX from 'xlsx';
import { Box, Tooltip, Typography, useTheme } from '@mui/material';
import { useValidateExcelData, useMapExcelData } from './hooks';
import { useSimManagement } from 'shared/api';
import {
  setSimImportErrors,
  setSimImportFileName,
  setSimImportProvider,
  setSimImportStatus,
  setSimImportTotalRows,
  setSimNotValidRows,
} from 'shared/slices';

interface Props {
  onChange?: (value: MappedExcelSimData[]) => void;
  onSelectFile: () => void;
  onResetFile: () => void;
}
export const ImportExcel: FC<Props> = ({ onChange, onSelectFile, onResetFile }) => {
  const { SIM_IMPORT } = useAppSelector((st) => st.globalSlice);
  const { simImportFileName } = useAppSelector((st) => st.simManagement);
  // const [isLoading, setIsLoading] = useState<boolean>(false);
  const [excelData, setExcelData] = useState<string[][] | null>(null);
  const { t } = useExtendedTranslation();
  const dispatch = useAppDispatch();
  const { showError, showSuccess } = useToast();
  // const { simImportStatus } = useAppSelector((st) => st.simManagement);
  const { simOperators } = useAppSelector((st) => st.simManagement);
  const { getOperator } = useSimManagement();
  // const [fileName, setFileName] = useState<string | null>('select_file_import_sim');
  const { direction } = useTheme();
  const isRtl = direction === 'rtl';
  const isNotEmptyArray = (arr: any[]) => arr.length > 0;

  useEffect(() => {
    if (excelData && excelData.length && excelData[0].length) {
      const { indexSIMNumber, indexPhoneNumber, indexSIMStatus } = useValidateExcelData(excelData, SIM_IMPORT);

      if (indexSIMNumber !== -1 && indexPhoneNumber !== -1) {
        const index = {
          simIndex: indexSIMNumber,
          phoneIndex: indexPhoneNumber,
          statusCode: indexSIMStatus,
        };

        const { mappedExcelData, dataIntegrity, uniqueValues, errors, notValidRows, simIndex } = useMapExcelData(
          excelData,
          index,
          SIM_IMPORT
        );

        const id: string[] = [];

        excelData.map((simArr) => {
          const simArrValue = simArr[simIndex]?.toString();
          if (simArrValue) {
            SIM_IMPORT.SIM_CHECK_OPERATORS.forEach((operator) => {
              if (simArrValue.includes(operator.searchString) && !id.includes(operator.id)) {
                id.push(operator.id);
              }
            });
          }
        });

        if (id.length === 1) {
          simOperators.filter((item) =>
            item.operatorID.toString() === id[0] ? dispatch(setSimImportProvider(item.operatorName)) : ''
          );
        }

        dispatch(setSimImportErrors(errors));
        dispatch(setSimNotValidRows(notValidRows));

        if (!uniqueValues) {
          showError(t('error_data_unique_values_excel_file'));
          dispatch(setSimImportStatus('some_invalid_rows_import_status'));
        }
        if (!dataIntegrity) {
          // console.log(mappedExcelData);
          onChange?.(mappedExcelData);
          // showError(t('error_data_integrity_excel_file'));
          dispatch(setSimImportStatus('some_invalid_rows_import_status '));
        }
        if (mappedExcelData.length) {
          onChange?.(mappedExcelData);
        } else {
          showError(t('error_not_found_sim_or_phone_excel_file'));
          onChange?.(mappedExcelData);
          dispatch(setSimNotValidRows([]));
          dispatch(setSimImportErrors([{ errInfo: { error: t('error_not_found_sim_or_phone_excel_file') } }]));
          dispatch(setSimImportStatus('some_invalid_rows_import_status'));
        }
      } else {
        showError(t('error_not_found_sim_or_phone_excel_file'));
        dispatch(setSimNotValidRows([]));
        const error = `Not found: ${indexSIMNumber == -1 ? 'sim number' : ''} ${
          indexPhoneNumber == -1 ? 'Phone number' : ''
        } `;
        dispatch(setSimImportErrors([{ errInfo: { error: error } }]));

        dispatch(setSimImportStatus('no_data_found'));
        console.log('-1!!!');
        onChange?.([]);
      }
    }
  }, [excelData]);
  const clearFile = () => {
    onResetFile();
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const handleButtonClick = () => {
    if (fileInputRef && fileInputRef.current) {
      // fileInputRef.current.value = '';
      // dispatch(setSimImportProvider(''));
      // dispatch(setSimImportTotalRows(0));
      // dispatch(setSimNotValidRows([]));
      // dispatch(setSimImportErrors([]));
      // dispatch(setSimImportStatus('no_file_selected_import_status'));
      // setFileName('select_file_import_sim');
      fileInputRef.current.click();
    }
    // dispatch(setSimNotValidRows([]));
    // dispatch(setSimImportTotalRows(0));
  };
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    console.log('start validation');
    const data = e.target.files;

    // dispatch(setSimImportTotalRows(0));
    // dispatch(setSimNotValidRows([]));
    // dispatch(setSimImportErrors([]));

    // setFileName(data && data[0]?.name);
    if (data && data[0]?.name) {
      onSelectFile();
      dispatch(setSimImportFileName(data[0]?.name));
      dispatch(setSimImportProvider(''));
    }
    const file = e.target.files?.[0];
    if (file && file.type) {
      console.log('>>>', file.type);
    }
    if (
      data?.length !== 0 &&
      file &&
      (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'text/csv')
    ) {
      const reader = new FileReader();
      dispatch(setSimImportStatus('valid_in_progress_import_status'));

      reader.onload = (e) => {
        if (!e.target) {
          return 0;
        }
        const data = e.target.result;
        try {
          const workbook = XLSX.read(data, { type: 'binary' });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          const parsedData: string[][] = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          const filteredData = parsedData.filter(isNotEmptyArray);
          if (filteredData && filteredData.length && filteredData[0].length) {
            setExcelData(filteredData);
            dispatch(setSimImportTotalRows(filteredData.length));

            getOperator();

            // const operatorIds = [
            //   { searchString: '8997201', id: '1' },
            //   { searchString: '8997202', id: '2' },
            //   { searchString: '8997250', id: '50' },
            // ];

            dispatch(setSimImportStatus('ready_to_import_import_status'));
          } else {
            showError(t('error_empty_excel_file'));
            dispatch(setSimImportErrors([{ errInfo: { error: t('error_empty_excel_file') } }]));
            dispatch(setSimImportStatus('no_data_found_import_status'));
          }
        } catch (error) {
          showError(t('error_invalid_excel_file'));
          dispatch(setSimImportStatus('error_invalid_excel_file'));
          dispatch(setSimImportErrors([{ errInfo: { error: 'Error: the file format must be .xlsx or .csv' } }]));
          onChange?.([]);
        }
      };

      reader.readAsBinaryString(file);
    } else {
      if (
        (file && file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
        (file && file.type !== 'text/csv')
      ) {
        showError(t('error_invalid_excel_file'));
        dispatch(setSimImportStatus('error_invalid_excel_file'));
        dispatch(setSimImportErrors([{ errInfo: { error: 'Error: the file format must be .xlsx or .csv' } }]));
      }
      onChange?.([]);
      // dispatch(setSimImportStatus('no_file_selected_import_status'));
    }
    console.log('finish validation');
  };
  return (
    <Box
      sx={{
        display: 'flex',
        columnGap: '10px',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
      }}
    >
      <Tooltip title={simImportFileName} placement="top">
        <Typography
          sx={{
            color: '#A1A1AA',
            fontFamily: 'Noto Sans Hebrew',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: '100px',
          }}
        >
          {simImportFileName !== '' ? simImportFileName : t('select_file_import_sim')}
        </Typography>
      </Tooltip>
      {simImportFileName && <DeleteWithConfirmation onDelete={clearFile} />}
      <BaseBtn
        sx={{ fontFamily: 'Noto Sans Hebrew', borderRadius: '10px', width: '79px', height: '29px' }}
        btnColor="info.main"
        onClick={handleButtonClick}
        disabled={!!simImportFileName}
      >
        {t('select_btn_import_sim')}
        {/* <Icon src={export_icon} sx={{ ml: '7px' }} /> */}
        <input ref={fileInputRef} type="file" hidden accept=".xlsx, .csv" onChange={handleFileChange} />
      </BaseBtn>
    </Box>
  );
};

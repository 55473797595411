import localStorageService from "shared/localStorage";

export const useTokenInfo = () => {
  const token = localStorageService.userToken;
  if (!token) return { accessLevel: 0 };
  const [encodedPayload, encodedHeader] = token.split('.');
  const header = JSON.parse(atob(encodedHeader));

  const accessLevel = header.backOfficeAccessLevel;
  const userId = header.id;
  return { accessLevel, userId };
};

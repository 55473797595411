import { User } from "features/UserManager/UserModels";


export type TableTitle = { title: string; key: keyof User; width: number | string };

export const TABLE_TITLES: TableTitle[] = [
  { title: 'user_ID', key: 'userID', width: '100%' },
  { title: 'user_name', key: 'userName', width: '200%' },
  { title: 'user_login', key: 'userLogin', width: '150%' },
  { title: 'e_mail_address', key: 'eMailAddress', width: '100%' },
  { title: 'phone_number', key: 'phoneNumber', width: '100%' },
];

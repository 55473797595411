import { t } from 'i18next';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { setCustomerFilter } from 'shared/slices';
export const useFilter = () => {
  const { customerIDForFilter } = useAppSelector((st) => st.controllers);
  const dispatch = useAppDispatch();


  const customerFilter = customerIDForFilter.toString();
  const onChangeCustomerFilter = (value: string) => dispatch(setCustomerFilter(value ? parseInt(value) : ''));
  
  const [statusFilter, setStatusFilter] = useState<string>('');

  const [dateFrom, setDateFrom] = useState<string>('');
  const [dateTo, setDateTo] = useState<string>('');

  const [actionsFilter, setActionsFilter] = useState<string>('');
  const statusFilterItems = [
    {
      value: 'initiated',
      label: t('initiated_item_Filter'),
    },
    {
      value: 'failed',
      label: t('failed_item_Filter'),
    },
    {
      value: 'delivered',
      label: t('delivered_item_Filter'),
    },
    {
      value: 'read',
      label: t('read_item_Filter'),
    },
  ];

  const actionsFilterItems = [
    {
      value: '1',
      label: t('delete_message_action'),
    },

  ];

  const onChangeStatusFilter = (value: string) => {
    setStatusFilter(value);
  };
  const onChangeDateTo = (value: string) => {
    setDateTo(value);
  };
  const onChangeDateFrom = (value: string) => {
    setDateFrom(value);
  };
  const onChangeActionsFilter = (value: string) => setActionsFilter(value);
  return {
    customerFilter,
    onChangeCustomerFilter,
    statusFilter,
    onChangeStatusFilter,
    statusFilterItems,
    onChangeDateTo,
    onChangeDateFrom,
    dateFrom,
    dateTo,
    actionsFilter,
    onChangeActionsFilter,
    actionsFilterItems,
  };
};

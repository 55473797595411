import { customers, service_calls_report, setup } from 'shared/assets';
import { BasePageSettings, Icon } from 'shared/ui';
import { BigTitle } from 'shared/ui';
import { Grid, Box } from '@mui/material';
import {
  clients,
  inventory,
  users,
  sim,
  network_protection,
  real_time_controller_data,
  battery,
  big_battery,
  equipment,
  dashboard,
} from 'shared/assets';
import { useTheme } from '@mui/material';
import { Typography } from '@mui/material';
import { Link, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import localStorageService from 'shared/localStorage';
const StyledLink = styled(Link)`
  text-decoration: none;
`;

export const HomePageManager = () => {
  const { palette } = useTheme();
  const { t } = useTranslation();
  const PAGE_OPTIONS = [
    { name: t('make_dashboard_default'), value: 'dashboard' },
    { name: t('make_shortcuts_default'), value: 'shortcuts' },
  ];
  const [defaultPage, setDefaultPage] = useState<string>('shortcuts');
  useEffect(() => {
    setDefaultValue();
  }, []);
  const getDataFromLocalStorage = () => {
    const data = localStorageService.loginDefaultPage;
    return data ? JSON.parse(data) : 'shortcuts';
  };
  const setDefaultValue = () => {
    const data = getDataFromLocalStorage();
    setDefaultPage(data);
  };
  const handleChange = (value: string) => {
    localStorageService.loginDefaultPage = JSON.stringify(value);
    setDefaultPage(value);
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '37px', mt: '22px' }}>
        <BigTitle sx={{ mr: '25px' }}>{t('agam_back_office_system_home_page')}</BigTitle>
        <Box>
          <BasePageSettings handleChange={handleChange} selected={defaultPage} options={PAGE_OPTIONS} />
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <StyledLink href="/customers">
            <Box
              sx={{
                border: `1px solid ${palette.primary.dark}`,
                borderRadius: '10px',
                height: '220px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'white',
                '&:hover': {
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                },
              }}
            >
              <Icon src={customers} />

              <Typography
                sx={{
                  color: 'primary.dark',
                  fontSize: '32px',
                  lineHeight: '41px',
                  fontWeight: 500,
                  ml: '10px',
                  textDecoration: 'none',
                }}
              >
                {t('customers_button_home_page')}
              </Typography>
            </Box>
          </StyledLink>
        </Grid>
        <Grid item xs={12} md={4}>
          <StyledLink href="/controllers">
            <Box
              sx={{
                border: `1px solid ${palette.primary.dark}`,
                borderRadius: '10px',
                height: '220px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'white',
                '&:hover': {
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                },
              }}
            >
              <Icon src={inventory} />
              <Typography
                sx={{
                  color: 'primary.dark',
                  fontSize: '32px',
                  lineHeight: '41px',
                  fontWeight: 500,
                  ml: '10px',
                }}
              >
                {t('inventory_button_home_page')}
              </Typography>
            </Box>
          </StyledLink>
        </Grid>
        <Grid item xs={12} md={4}>
          <StyledLink href="/users">
            <Box
              sx={{
                border: `1px solid ${palette.primary.dark}`,
                borderRadius: '10px',
                height: '220px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'white',
                '&:hover': {
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                },
              }}
            >
              <Icon src={clients} />
              <Typography
                sx={{
                  color: 'primary.dark',
                  fontSize: '32px',
                  lineHeight: '41px',
                  fontWeight: 500,
                  ml: '10px',
                }}
              >
                {t('users_button_home_page')}
              </Typography>
            </Box>
          </StyledLink>
        </Grid>
        <Grid item xs={12} md={2}>
          <StyledLink href="/dashboard">
            <Box
              sx={{
                border: `1px solid ${palette.primary.dark}`,
                borderRadius: '10px',
                height: '220px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'white',
                '&:hover': {
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  rowGap: '10px',
                  textAlign: 'center',
                }}
              >
                <Icon src={dashboard} />
                <Typography
                  sx={{
                    color: 'primary.dark',
                    fontSize: '24px',
                    lineHeight: '30px',
                    fontWeight: 400,
                    ml: '10px',
                  }}
                >
                  {t('dashboard_button_home_page')}
                </Typography>
              </Box>
            </Box>
          </StyledLink>
        </Grid>
        <Grid item xs={12} md={2}>
          <StyledLink href="/controller-details?customerType=1">
            <Box
              sx={{
                border: `1px solid ${palette.primary.dark}`,
                borderRadius: '10px',
                height: '220px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'white',
                '&:hover': {
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  rowGap: '10px',
                  textAlign: 'center',
                }}
              >
                <Icon src={real_time_controller_data} />
                <Typography
                  sx={{
                    color: 'primary.dark',
                    fontSize: '24px',
                    lineHeight: '30px',
                    fontWeight: 400,
                    ml: '10px',
                  }}
                >
                  {t('controller_data_home_page')}
                </Typography>
              </Box>
            </Box>
          </StyledLink>
        </Grid>
        <Grid item xs={12} md={2}>
          <StyledLink href="/network-protection">
            <Box
              sx={{
                border: `1px solid ${palette.primary.dark}`,
                borderRadius: '10px',
                height: '220px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'white',
                '&:hover': {
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  rowGap: '10px',
                  textAlign: 'center',
                }}
              >
                <Icon src={network_protection} />
                <Typography
                  sx={{
                    color: 'primary.dark',
                    fontSize: '24px',
                    lineHeight: '30px',
                    fontWeight: 400,
                    ml: '10px',
                  }}
                >
                  {t('network_protection_home_page')}
                </Typography>
              </Box>
            </Box>
          </StyledLink>
        </Grid>

        <Grid item xs={12} md={2}>
          <StyledLink href="/service-calls-report">
            <Box
              sx={{
                border: `1px solid ${palette.primary.dark}`,
                borderRadius: '10px',
                height: '220px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'white',
                '&:hover': {
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  rowGap: '10px',
                }}
              >
                <Icon src={service_calls_report} />
                <Typography
                  sx={{
                    color: 'primary.dark',
                    fontSize: '24px',
                    lineHeight: '30px',
                    fontWeight: 400,
                    ml: '10px',
                  }}
                >
                  {t('service_calls_report_button_home_page')}
                </Typography>
              </Box>
            </Box>
          </StyledLink>
        </Grid>
        <Grid item xs={12} md={2}>
          <StyledLink href="/external-Power-problems-report">
            <Box
              sx={{
                border: `1px solid ${palette.primary.dark}`,
                borderRadius: '10px',
                height: '220px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'white',
                '&:hover': {
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  rowGap: '10px',
                  textAlign: 'center',
                }}
              >
                <Icon src={battery} />
                <Typography
                  sx={{
                    color: 'primary.dark',
                    fontSize: '24px',
                    lineHeight: '30px',
                    fontWeight: 400,
                    ml: '10px',
                  }}
                >
                  {t('power_problem_report_button_home_page')}
                </Typography>
              </Box>
            </Box>
          </StyledLink>
        </Grid>
        <Grid item xs={12} md={2}>
          <StyledLink href="/battery-status-report">
            <Box
              sx={{
                border: `1px solid ${palette.primary.dark}`,
                borderRadius: '10px',
                height: '220px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'white',
                '&:hover': {
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  rowGap: '10px',
                  textAlign: 'center',
                }}
              >
                <Icon src={big_battery} />
                <Typography
                  sx={{
                    color: 'primary.dark',
                    fontSize: '24px',
                    lineHeight: '30px',
                    fontWeight: 400,
                    ml: '10px',
                  }}
                >
                  {t('battery_button_home_page')}
                </Typography>
              </Box>
            </Box>
          </StyledLink>
        </Grid>
      </Grid>
    </>
  );
};


import { Box, Tooltip } from '@mui/material';
import { FC, useLayoutEffect, useRef, useState } from 'react';

interface CustomTooltip {
	children: React.ReactNode;
	width: number;
}

export const BaseTooltip: FC<CustomTooltip> = ({ children, width }) => {
	const textElementRef = useRef<HTMLDivElement>(null);
	const [hoverStatus, setHover] = useState(false);

	const checkWidth = () => {
		if (textElementRef.current) {
			const compare = textElementRef.current.scrollWidth + 20 > width;
			setHover(compare);
		}
	};

	return (
		<Tooltip title={children} onMouseEnter={() => checkWidth()} disableHoverListener={!hoverStatus} style={{ position: 'absolute', right: '0' }}>
			<div ref={textElementRef} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
				{children}
			</div>
		</Tooltip>
	);
};
import { ButtonProps } from '@mui/material';
import { FC } from 'react';
import { action_start } from 'shared/assets';
import { useExtendedTranslation } from 'shared/hooks';
import { BaseBtn, Icon } from 'shared/ui';
interface Props extends ButtonProps {
  actionsFilter: string;
  selectedUsers: number[];
}

export const UserActionBtn: FC<Props> = ({ actionsFilter, onClick, selectedUsers }) => {
  const { t } = useExtendedTranslation();
  return (
    <BaseBtn disabled={!actionsFilter || !selectedUsers.length} onClick={onClick} sx={{ mt: '16px', height: '29px' }}>
      {t('users_action_start')}
      <Icon sx={{ ml: '6px' }} src={action_start} />
    </BaseBtn>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { axiosBase } from 'shared/config';
import { useAppDispatch, useToast } from 'shared/hooks';
import { setCustomerAgreements } from 'shared/slices';

export const useGetCustomerAgreementCodes = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { showError } = useToast();
  const push = useNavigate();

  const [isLoadingAgreementCodes, setLoading] = React.useState(false);

  const getCustomerAgreementCodes = async () => {
    setLoading(true);

    try {
      const { data } = await axiosBase.get('/Customer/CustomerAgreementCodes');

      dispatch(setCustomerAgreements(data?.data));
    } catch (error: any) {
      console.log('#ERROR', error);
      if (error?.response?.status === 401) {
        push('/login');
      } else {
        showError(t('something_went_wrong_toast_text'));
      }
    } finally {
      setLoading(false);
    }
  };

  return {
    getCustomerAgreementCodes,
    isLoadingAgreementCodes,
  };
};

import {
  Alert,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { DataSourceTypes, useControllers } from 'shared/api';
import { Controller, DeviceModelTypes, ControllerRegisters } from 'shared/models';
import { Layout } from '../../shared/ui';
import { Header } from '../../widgets';
import { useControllerRegisters } from 'shared/api/useControllerRegister';

///ui for test by Oleg only will be developed in next sprint after aprrove

function StateDetails(props: { data: Record<string, string | number | object | boolean> }) {
  const data = props.data;

  return data ? (
    <table
      style={{
        border: '1px solid #000', // Table border
        borderCollapse: 'collapse', // Collapse borders to prevent double borders

        // Optional: You can add more styling to the table as needed
        width: '100%',
      }}
    >
      <thead>
        <tr>
          {Object.keys(data).map((key) => (
            <th key={key}>{key}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          {Object.keys(data).map((key) => (
            <>
              {<td key={key}>{JSON.stringify(data[key])}</td>}

            </>
          ))}
        </tr>
      </tbody>
    </table>
  ) : (
    <></>
  );
}

export const ControllersStatePage = () => {
  const { getControllersWithState, isLoadingControllers, error } = useControllers();
  const { getControllerRegisterEvents, isLoadingControllerRegisters } = useControllerRegisters();

  const [dataSourceType, setDataSourceType] = useState<DataSourceTypes>(DataSourceTypes.Realtime);
  const [controllers, setControllers] = useState<Controller[]>([]);

  const [controllerRegisters, setControllerRegisters] = useState<ControllerRegisters>();

  const handleSourceTypeChange = (event: SelectChangeEvent) => {
    const value = event.target.value;
    if (typeof value == 'number') setDataSourceType(value);
  };

  const [modelId, setModelId] = useState<DeviceModelTypes>('I1');
  const handleModelTypeChange = (event: SelectChangeEvent) => {
    const value = event.target.value;
    if (typeof value == 'string') setModelId(value as DeviceModelTypes);
  };

  const onDeviceStateFetch = () => {
    setControllers([]);

    getControllersWithState({ customerId, dataSourceType, modelId }).then((data) => {
      if (data?.data) setControllers(data?.data ?? []);
    });
  };

  const [customerId, setCustomerId] = useState('67');
  const handleCustomerIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (typeof value == 'string') setCustomerId((value as string)?.toString());
  };

  return (
    <Layout>
      <Header />
      <Layout.Body>
        <Stack sx={{ minWidth: 120 }} gap={3}>
          <FormControl fullWidth>
            <InputLabel id="source-type">Source Type</InputLabel>
            <Select
              labelId="source-type"
              value={dataSourceType.toString()}
              label="Source Type"
              onChange={handleSourceTypeChange}
            >
              <MenuItem value={0}>Realtime</MenuItem>
              <MenuItem value={1}>History</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="model-type">Model Type</InputLabel>
            <Select labelId="model-type" value={modelId.toString()} label="Model Type" onChange={handleModelTypeChange}>
              <MenuItem value={'I1'}>I1</MenuItem>
              <MenuItem value={'IP'}>IP</MenuItem>
              <MenuItem value={'WE'}>WE</MenuItem>
            </Select>
          </FormControl>
          <TextField
            onChange={handleCustomerIdChange}
            inputProps={{ type: 'number' }}
            label="Customer no"
            variant="standard"
            defaultValue={customerId}
          />
          <Button onClick={onDeviceStateFetch} disabled={!customerId || isLoadingControllers} variant="outlined">
            {isLoadingControllers ? 'Loading...' : 'Get Controller/Customer  State Data'}
          </Button>

          {!isLoadingControllers && !error && (
            <ol dir="ltr">
              {controllers.map(({ state, hwId, deviceId, objectNotes }) => (
                <li key={hwId}>
                  <strong>{objectNotes}</strong> {`deviceId:${deviceId}    hwId:${hwId}`}
                  <StateDetails data={state} />
                  <button hidden={!state}
                    disabled={isLoadingControllerRegisters}
                    type="button"
                    onClick={async (e) => {
                      e.preventDefault();

                      const result = await getControllerRegisterEvents({ deviceId });
                      setControllerRegisters(result.data);
                    }}
                  >
                    {!isLoadingControllerRegisters ? 'Get Data' : 'Loading...'}
                  </button>
                </li>
              ))}
            </ol>
          )}
          {error && <Alert severity="error">{error}</Alert>}
        </Stack>
        <Modal
          openModal={!!controllerRegisters}
          closeModal={() => {
            setControllerRegisters(undefined);
          }}

        >
          {controllerRegisters &&
            Object.entries(controllerRegisters).map((item) => (
              <details key={item[0]}>
                <summary>{item[0]}</summary>
                {/* <StateDetails data={item[1]}></StateDetails> */}
                <p>
                  <pre style={{ backgroundColor: '#D3D3D3' }}>{JSON.stringify(item[1], null, 4)}</pre>
                </p>
              </details>
            ))}
        </Modal>
      </Layout.Body>
    </Layout>
  );
};

function Modal({ openModal, closeModal, children }) {
  const ref = useRef<HTMLDialogElement>();

  useEffect(() => {
    if (openModal) {
      ref.current?.showModal();
    } else {
      ref.current?.close();
    }
  }, [openModal]);

  return (
    <dialog dir="ltr" ref={ref} onCancel={closeModal} style={{ minHeight: '490px', minWidth: '70%' }}>
      <div style={{ textAlign: 'right' }}>
        {' '}
        <button onClick={closeModal}>x</button>
      </div>

      <div style={{ maxHeight: '410px', overflow: 'auto', margin: '0.1rem', padding: '10px' }}>{children}</div>
    </dialog>
  );
}

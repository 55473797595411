import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'shared/hooks';

export const useFilterCustomers = () => {
  const { customerStatuses, customerTypes, customerAgreementCodes } = useAppSelector((st) => st.customers);

  const agreementFilterItems = customerAgreementCodes.map((s) => ({
    value: s.code.toString(),
    label: s.text,
  }));
  const statusFilterItems = customerStatuses.map((s) => ({
    value: s.customerStatusCode.toString(),
    label: s.customerStatus,
  }));
  const actionsItemsFilter = [
    {
      value: '1',
      label: t('send_wa_message_action'),
    },
  ];
  const [actionsFilter, setActionsFilter] = useState<string>('');
  const typeFilterItems = customerTypes.map((t) => ({ value: t.customerTypeID.toString(), label: t.customerType }));

  const allAgreements = agreementFilterItems.map((item) => item.value);

  const [agreementFilterValues, setAgreementFilterItems] = useState<string[]>(allAgreements);
  const [statusFilter, setStatusFilter] = useState<string>('1');
  const [typeFilter, setTypeFilter] = useState<string>('1');
  const onChangeAgreementFilterItems = (values: string[]) => setAgreementFilterItems(values);
  const onChangeStatusFilter = (value: string) => setStatusFilter(value);
  const onChangeTypeFilter = (value: string) => setTypeFilter(value);
  const onChangeActionsFilter = (value: string) => setActionsFilter(value);

  useEffect(() => {
    setAgreementFilterItems(allAgreements);
  }, [customerAgreementCodes]);

  return {
    agreementFilterValues,
    statusFilter,
    typeFilter,
    onChangeAgreementFilterItems,
    onChangeStatusFilter,
    onChangeTypeFilter,
    agreementFilterItems,
    statusFilterItems,
    typeFilterItems,
    actionsFilter,
    onChangeActionsFilter,
    actionsItemsFilter,
  };
};

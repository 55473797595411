import { useTranslation } from 'react-i18next';
import { accessLevelItems } from '../constants';
import { User } from '../UserModels';
export const useFormatXlsxData = () => {
  const { t } = useTranslation();
  const formatTable = (arr: User[]) => {
    
    const exportArr = arr.map((item) => {
      return {
        [t('customer_ID_user_grid_table')]: item.customerID,
        [t('user_ID_user_grid_table')]: item.userID,
        [t('user_name_user_grid_table')]: item.userName,
        [t('user_login_user_grid_table')]: item.userLogin,
        [t('user_status_user_grid_table')]: t(item.userStatus),
        [t('last_login_date_user_grid_table')]: item.lastLoginDate,
        [t('last_month_login_count_user_grid_table')]: item.lastMonthLoginCount,
        [t('last_password_self_update_user_grid_table')]: item.lastPasswordSelfUpdate,
        [t('last_password_team_update_user_grid_table')]: item.lastPasswordTeamUpdate,
        [t('next_password_update_user_grid_table')]: item.nextPasswordUpdate,
        [t('e_mail_address_user_grid_table')]: item.eMailAddress,
        [t('phone_number_user_grid_table')]: item.phoneNumber,
        [t('user_role_description_user_grid_table')]: item.userRoleDescription,
        [t('notes_user_grid_table')]: item.notes,
        [t('back_office_access_level_user_grid_table')]:item.backOfficeAccessLevel ? t(`${accessLevelItems[item.backOfficeAccessLevel]}`):t(`access_level_no_access`),
        [t('foreign_projects_count_user_grid_table')]: item.projectsCount,
        [t('foreign_project_access_user_grid_table')]: t(`${item.foreignProjectAccess}_access_level`),
      };
    });

    return exportArr;
  };

  return { formatTable };
};

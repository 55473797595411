import { FC, useState,useEffect} from 'react';
import { PieChart, Pie,Legend, Cell, ResponsiveContainer } from 'recharts';
import { CustomizedLegend } from './DoughnutChartsComponents'
import { ChartNames, DashboardData} from 'shared/models';
type Props = {
	data: DashboardData[],
  chartName: ChartNames
  openDashboardDetails: (chartName: string, itemName: string ) => void;
};
import localStorageService from 'shared/localStorage';
export const DoughnutCharts: FC<Props>= ({data, chartName, openDashboardDetails}) => {

  let zeroValue = 0
  data.forEach((item)=>{if(item.value<1){
    zeroValue++;
  }})
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
    window.removeEventListener('resize', handleResize);
    };
  }, []);
  const selectItem=(e)=>{
    openDashboardDetails(chartName, e.key ||  e.payload.key)
  }
  const selectLabel=(name:string)=>{
    openDashboardDetails(chartName, name)
  }
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = (props) => {
    const { cx, cy, midAngle, outerRadius, fill, payload,key, percent, name, value} = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const delta = Math.abs(1 / cos) + 10;
    const sx = cx + outerRadius * cos;
    const sy = cy + outerRadius * sin;
    const mx = cx + (outerRadius + delta) * cos;
    const my = cy + (outerRadius + delta) * sin;
    const ex = mx + Number(cos.toFixed(1)) * 20;
    const ey = my;
    const findTextAnchor= ()=>{
      if(isRTL){
        return  cos >= 0 ? 'end' : 'start'
      }
      return cos >= 0  ? 'start' : 'end'
    }
    if(!value){
      return <></>
    }
    return (
      <g>
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        {/* <rect x={ex + (cos >= 0 ? 1 * 5 : -1 * 17)} y={ey - 4} width={12} height={8} fill={fill} /> */}
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 4}
          y={ey + 4}
          fontSize={"9px"}
          textAnchor={findTextAnchor()}
          onClick={()=>selectLabel(key)}
          style={{cursor:"pointer"}}
        >
          <tspan >{name}</tspan><tspan opacity="0">א</tspan><tspan>({value})</tspan>
        </text>
      </g>
    );
  };
  const isRTL =localStorageService.isRTL;
  const isLabelVisible = windowWidth >= 1450;
  return (
      <ResponsiveContainer width="100%" height="100%">
        <PieChart width={320} height={320} margin={{
            top: 5,
            right: 10,
            left: 10,
            bottom: 5,
          }}>
            <Legend  content={(props)=><CustomizedLegend {...props} selectItem={selectItem} />} layout="vertical" verticalAlign="top" align={isRTL ? 'right' :'left' } />
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            label={isLabelVisible ? renderCustomizedLabel : null}
            outerRadius={94}
						innerRadius={75}
						paddingAngle={5}
            fill="#8884d8"
            dataKey="value"
            labelLine={false}
            onClick={selectItem}
            cursor={'pointer'}
            startAngle={isRTL ? 60: 220}
            endAngle={isRTL ? 420: 580}
            isAnimationActive={false}
          >
          </Pie>
        </PieChart>
      </ResponsiveContainer>
  );
};

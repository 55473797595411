import { Box, Button, ButtonProps, CircularProgress } from '@mui/material';
import { FC, useState } from 'react';
import { isHebrewFont } from 'shared/lib';
import './style.css';
interface Props extends ButtonProps {
  loading?: boolean;
  btnColor?: string;
  onLongClick: () => void;
  onClick: () => void;
}


export const BaseLongClickBtn: FC<Props> = ({ loading, children, sx, onClick, onLongClick, type, disabled, fullWidth, btnColor }) => {
  const [timerId, setTimerId] = useState(null);
  const [isButtonPressed, setIsButtonPressed] = useState(null);
  const onPressed = () => {
    setIsButtonPressed(true)
    const id = setTimeout(() => { onLongClick(); setIsButtonPressed(false) }, 2000);
    setTimerId(id);
  }
  const handleMouseUp = () => {

    clearTimeout(timerId);
    setTimerId(null);
    setIsButtonPressed(false)
    if (isButtonPressed) {
      onClick()
    }
  };
  const isHebrew = isHebrewFont(children?.toString() ?? '');
  return (
    <Button
      type={type}
      onMouseDown={onPressed}
      onMouseUp={handleMouseUp}
      fullWidth={fullWidth}
      disabled={disabled || loading}
      disableFocusRipple
      disableRipple
      sx={{

        fontFamily: isHebrew ? 'Noto Sans Hebrew' : 'Inter',
        textTransform: 'capitalize',
        borderRadius: '10px',
        height: '40px',
        px: '15px',
        color: btnColor === 'info.main' ? 'primary.dark' : 'info.main',
        borderStyle: `solid`,
        borderWidth: `1px`,
        borderColor: btnColor === 'info.main' || !btnColor ? 'primary.dark' : btnColor,
        bgcolor: btnColor ?? 'primary.dark',
        transition: 'all 0.3s',
        '&:hover': {
          bgcolor: btnColor ?? 'primary.dark',
          boxShadow: '0px 0px 4px #245AA5',
        },
        '&:disabled': {
          color: btnColor === 'info.main' ? 'primary.dark' : 'info.main',
          opacity: loading ? 1 : 0.5,
        },
        position: 'relative',
        ...sx,
      }}
    >
      <Box sx={{
        position: 'absolute',
        animation: isButtonPressed ? 'fillButton 1.9s' : 'none',
        background: '#245AA566',
        height: '100%',
        left: 0,
        borderRadius: '10px',
      }}></Box>
      {loading ? <CircularProgress size={25} sx={{ color: 'info.main' }} /> : children}
    </Button >
  );
};

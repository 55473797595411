import { IconButton, Stack } from '@mui/material';
import { useDeleteCustomer } from 'features/CustomerManager/hooks/api/useDeleteCustomer';
import { useTranslation } from 'react-i18next';

import { close_popup } from 'shared/assets';
import { BaseBtn, BigTitle, Modal, Row } from 'shared/ui';

interface Props {
  openDeleteCustomerModal: boolean;
  onCloseModal: () => void;
}

export const DeleteCustomer = ({ openDeleteCustomerModal, onCloseModal }: Props) => {
  const { t } = useTranslation();
  const closeModal = () => onCloseModal();
  const { isLoading } = useDeleteCustomer();

  const handleDelete = () => {
    onCloseModal();
  };

  return (
    <Modal open={openDeleteCustomerModal} onClose={closeModal}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <BigTitle>{t('delete-customer_delete_modal')}</BigTitle>
        <IconButton onClick={closeModal}>
          <img src={close_popup} />
        </IconButton>
      </Stack>

      <Row justifyContent="center" mt={'35px'}>
        <Row spacing="15px" width={500}>
          <BaseBtn onClick={closeModal} btnColor="info.main" fullWidth>
            {t('cancel_delete_modal')}
          </BaseBtn>

          <BaseBtn onClick={handleDelete} loading={isLoading} btnColor="primary.dark" fullWidth>
            {t('delete_delete_modal')}
          </BaseBtn>
        </Row>
      </Row>
    </Modal>
  );
};

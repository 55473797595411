import { useAppDispatch } from 'shared/hooks';
import { Dashboard } from 'shared/models';
import useAxios from './useAxios';
import { setDashboard} from 'shared/slices';

export const useDashboard = () => {
  const dispatch = useAppDispatch();
  const { loading, request,error} = useAxios();

  return {
    getDashboard: () =>
      request<undefined, { data: Dashboard }>({
        method: 'get',
        url: `/dashboard`,
        showSuccessMessageCode: undefined,
        onDataReceiveCompleted: (data) => {
          dispatch(setDashboard(data?.data));
        },
      }),

      isLoadingDashboard: loading,
      error
  };
};
import { Box, IconButton, Stack, Tooltip, Typography, Zoom } from '@mui/material';
import { FC, useState } from 'react';
import { SxProps } from '@mui/system';
import { BaseBtn, BaseInfo, BigTitle, Modal, Row } from 'shared/ui';
import { t } from 'i18next';
import { close_popup, errorIcon, success } from 'shared/assets';
import { ProgressBar } from 'shared/ui/ProgressBar';
import { Text } from 'shared/ui/Text';
import { FotaSettings } from 'features/FotaManager/fotaModels';
import { useChangeFotaSettings } from 'features/FotaManager/hooks';
type Props = {
  openModal: boolean;
  onCloseModal: () => void;
  finishedUpdate: () => void;
  updateFota: () => void;
  selectedFotaIds: number[];
  selectedStatus: string;
  deviceType?: string;
  downloadTimeFrom?: number;
  downloadTimeTo?: number;
  restartTimeFrom?: number;
  restartTimeTo?: number;
  currentVersionMin?: number;
  currentVersionMax?: number;
  newVersionMin?: number;
  newVersionMax?: number;
  specialVersion?: number;
  notes?: string;
  currentUserId?: number;
};

export const ProgressChanging: FC<Props> = ({
  openModal,
  onCloseModal,
  finishedUpdate,
  updateFota,
  selectedFotaIds,
  selectedStatus,
  deviceType,
  currentVersionMin,
  currentVersionMax,
  newVersionMin,
  newVersionMax,
  specialVersion,
  notes,
  currentUserId,
  downloadTimeFrom,
  downloadTimeTo,
  restartTimeFrom,
  restartTimeTo,
}) => {
  const handleCancelUpdate = () => {
    onCloseModal();
    clearData();
  };
  const clearData = () => {
    setIsFinishUpdate(false);
    setErorUpload([]);
    setSuccessUpload([]);
    setUploadNumber(0);
  };
  const handleUpdate = async () => {
    setIsUpdating(true);
    for (const item of selectedFotaIds) {
      await updateProccess(item);
      setUploadNumber((uploadNumber) => uploadNumber + 1);
    }
    setIsUpdating(false);
    setIsFinishUpdate(true);
    updateFota();
  };
  const { changeFotaSettings } = useChangeFotaSettings();
  const updateProccess = async (item: number) => {
    const data: FotaSettings = {
      deviceType,
      fotaStatus: selectedStatus,
      currentVersionMin,
      currentVersionMax,
      newVersionMin,
      newVersionMax,
      specialVersion,
      notes,
      hwId: item,
      currentUserId,
      downloadTimeFrom,
      downloadTimeTo,
      restartTimeFrom,
      restartTimeTo,
    };
    const result = await changeFotaSettings(data);
    if (result?.data?.updateResult) {
      //updateFota();
      //setLoading(true);
      //showSuccess(t('fota_settings_successfully_updated_toast_text'));
      //closeModal();
      setSuccessUpload((successUpload) => [...successUpload, item]);
      return;
    }
    setErorUpload((errorUpload) => [...errorUpload, item]);

    //showError(t('error_update_fota_settings_toast_text'));
  };

  const [isUpdating, setIsUpdating] = useState(false);
  const [isFinishUpdate, setIsFinishUpdate] = useState(false);
  const [errorUpload, setErorUpload] = useState<number[]>([]);
  const [successUpload, setSuccessUpload] = useState<number[]>([]);
  const [uploadNumber, setUploadNumber] = useState<number>(0);
  const getProgress = () => {
    return (100 / selectedFotaIds.length) * uploadNumber;
  };
  const handleFinishedUpdate = () => {
    onCloseModal();
    clearData();
    finishedUpdate();
  };

  return (
    <Modal open={openModal} sx={{ width: 600, margin: 'auto' }} onClose={onCloseModal}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <BigTitle>{t('change_fota_settings_info_modal')}</BigTitle>
        <IconButton onClick={onCloseModal}>
          <img src={close_popup} />
        </IconButton>
      </Stack>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '465px',
          height: '470px',
          border: '1px solid #dddddd',
          borderTopLeftRadius: '12px',
          borderTopRightRadius: '12px',
          mt: '40px',
        }}
      >
        <Box
          sx={{
            height: '38px',
            bgcolor: 'primary.dark',
            color: 'white',
            pt: '10px',
            pl: '16px',
            borderTopLeftRadius: '12px',
            borderTopRightRadius: '12px',
            mb: '40px',
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '14px',
              fontFamily: 'Noto Sans Hebrew',
              lineHeight: '17px',
            }}
          >
            {t('update_confirmation_fota_settings_update_modal')}
          </Typography>
        </Box>
        <Box
          sx={{
            textAlign: 'center',
            mb: '23px',
          }}
        >
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '16px',
              fontFamily: 'Noto Sans Hebrew',
              lineHeight: '19px',
              color: 'primary.dark',
            }}
          >
            {`${t('you_chose_to_change_fota_settings_for_fota_settings_update_modal')} `}
            <Box component={'span'} sx={{ textDecoration: 'underline' }}>
              {selectedFotaIds.length}
            </Box>{' '}
            {t('controllers_fota_settings_update_modal')}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '200px',
            margin: '0 auto',
            mb: '80px',
          }}
        >
          <Text noWrap sx={{ color: 'info.dark', fontSize: '12px', fontWeight: 500, pr: '2px', lineHeight: '15px' }}>
            {t('new_status_label')}
          </Text>
          <BaseInfo
            sx={{ backgroundColor: 'white' }}
            text={selectedStatus === '0' ? t('fota_status_Disable') : t('fota_status_Active')}
          />
        </Box>
        {isUpdating ? (
          <>
            <Box
              sx={{
                textAlign: 'center',
                mb: '0px',
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Noto Sans Hebrew',
                  fontWeight: 600,
                  fontSize: '16px',
                  lineHeight: '19px',
                  color: 'primary.dark',
                }}
              >
                {t('update_progress_fota_settings_update_modal')}
              </Typography>
            </Box>
            <Box margin={'20px'}>
              <ProgressBar progress={getProgress()} />
            </Box>
          </>
        ) : isFinishUpdate ? (
          <>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
                rowGap: '14px',
                mb: '20px',
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Noto Sans Hebrew',
                  fontWeight: 600,
                  fontSize: '16px',
                  lineHeight: '19px',
                  color: errorUpload.length ? '#D74747' : '#166534',
                  mb: '16px',
                }}
              >
                {errorUpload.length > 0
                  ? t('error_fota_fota_settings_update_modal')
                  : t('done_fota_fota_settings_update_modal')}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  columnGap: '8px',
                }}
              >
                <img src={success} />
                <Typography
                  sx={{
                    fontFamily: 'Noto Sans Hebrew',
                    fontWeight: 700,
                    fontSize: '16px',
                    lineHeight: '19px',
                    color: '#166534',
                    textDecoration: 'underline',
                  }}
                >
                  {successUpload.length}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'Noto Sans Hebrew',
                    fontWeight: 700,
                    fontSize: '16px',
                    lineHeight: '19px',
                    color: '#166534',
                  }}
                >
                  {t('fota_changed_successfully_fota_settings_update_modal')}
                </Typography>
              </Box>
              {errorUpload.length > 0 && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    columnGap: '8px',
                  }}
                >
                  <img src={errorIcon} />
                  <Typography
                    sx={{
                      fontFamily: 'Noto Sans Hebrew',
                      fontWeight: 700,
                      fontSize: '16px',
                      lineHeight: '19px',
                      color: 'red',
                      textDecoration: 'underline',
                    }}
                  >
                    {errorUpload.length}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: 'Noto Sans Hebrew',
                      fontWeight: 700,
                      fontSize: '16px',
                      lineHeight: '19px',
                      color: 'red',
                    }}
                  >
                    {t('fota_did_not_update_fota_settings_update_modal')}
                  </Typography>
                  <Tooltip
                    title={`${t('error_to_update_fota')} ${errorUpload.length > 1 ? errorUpload.join(', ') : errorUpload}`}
                    placement="right-start"
                    TransitionComponent={Zoom}
                  >
                    <Box
                      component={'span'}
                      sx={{
                        width: '51px',
                        height: '15px',
                        border: '1px solid #D74747',
                        borderRadius: '10px',
                      }}
                    >
                      <Typography
                        sx={{
                          color: '#D74747',
                          fontFamily: 'Noto Sans Hebrew',
                          fontSize: '12px',
                          fontWeight: 500,
                          lineHeight: '13px',
                        }}
                      >
                        {t('view_device_transferring_view_button')}
                      </Typography>
                    </Box>
                  </Tooltip>
                </Box>
              )}
            </Box>
            <Row justifyContent="center">
              <Row width={100}>
                <BaseBtn type="submit" onClick={handleFinishedUpdate} btnColor="primary.dark" fullWidth>
                  {t('done_button_fota_settings_update_modal')}
                </BaseBtn>
              </Row>
            </Row>
          </>
        ) : (
          <>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '21px',
                textAlign: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: '16px',
                  fontFamily: 'Noto Sans Hebrew',
                  lineHeight: '19px',
                  color: 'primary.dark',
                }}
              >
                {t('are_you_sure_fota_settings_update_modal')}
              </Typography>
              <Row justifyContent="center">
                <Row width={300} gap="10px">
                  <BaseBtn onClick={handleCancelUpdate} btnColor="info.main" fullWidth>
                    {t('cancel_fota_settings_update_modal')}
                  </BaseBtn>
                  <BaseBtn type="submit" onClick={handleUpdate} btnColor="primary.dark" fullWidth>
                    {t('update_fota_settings_update_modal')}
                  </BaseBtn>
                </Row>
              </Row>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};

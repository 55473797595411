import { useTheme } from '@mui/material';
import { Layout } from '../../shared/ui';
import { Header, HomePageManager } from '../../widgets';
export const HomePage = () => {
  return (
    <Layout>
      <Header />
      <Layout.Body>
        <HomePageManager />
      </Layout.Body>
    </Layout>
  );
};

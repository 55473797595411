import { WATemplate } from 'features/CustomerManager/whatsAppModels';
import { ChangeEvent, useState } from 'react';

export const useSearchMessagesTemplates = () => {
  const [searchValue, setSearchValue] = useState('');
  const changeSearch = (e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value);
  const getSearchedMessagesTemplates = (messagesTemplates: WATemplate[], searchValue: string) => {
    const searched = messagesTemplates.filter((c) => {
      const values = Object.values(c);

      return values.some((v) => v?.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase().trim()));
    });

    return searched;
  };
  return {
    searchValue,
    changeSearch,
    getSearchedMessagesTemplates,
  };
};

import ClickAwayListener from '@mui/base/ClickAwayListener';
import { PlayArrow } from '@mui/icons-material';
import {
  Box,
  FormControl,
  FormControlLabel,
  IconButton,
  IconButtonProps,
  Radio,
  RadioGroup,
  useTheme,
} from '@mui/material';
import { SxProps } from '@mui/system';
import List from 'rc-virtual-list';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { clear_filter } from 'shared/assets';
import { useAnchorEl, useExtendedTranslation } from '../../shared/hooks';
import { BaseBtn, Icon, Row, SearchInput, Text } from '../../shared/ui';
import { CustomFormControlLabel } from '../../shared/ui/CustomFormControlLabel';
interface ResetButtonProps extends IconButtonProps {
  showHomeButton?: boolean;
}

const ResetButton: FC<ResetButtonProps> = ({ onClick, sx }) => (
  <IconButton
    onClick={onClick}
    sx={{
      position: 'absolute',
      zIndex: 2,
      height: '30px',
      width: '33px',
      ...sx,
    }}
  >
    <Icon src={clear_filter} />
  </IconButton>
);

interface Props {
  title: string;
  items: { value: string; label: string }[];
  values?: string[];
  onChange?: (value: string[]) => void;
  onOpenedMenu?: (value: boolean) => void;
  applyFilter?: () => void;
  withSearch?: boolean;
  withConfirm?: boolean;
  hideAllButton?: boolean;
  sx?: SxProps;
  noSelectLabel?: string;
  formInput?: boolean;
  disabled?: boolean;
  hideValue?: boolean;
  marginRight?: string;
  fullWidth?: boolean;
  hideLayoutMargin?: boolean
  boxWidth?: string;
  listHeight?: number;
}

export const BaseMultiselect: FC<Props> = ({
  sx,
  title,
  formInput,
  items,
  values,
  onChange,
  onOpenedMenu,
  applyFilter,
  withSearch,
  hideAllButton,
  noSelectLabel,
  disabled,
  hideValue,
  marginRight,
  fullWidth,
  hideLayoutMargin,
  boxWidth,
  listHeight
}) => {
  const { t } = useExtendedTranslation();
  const { isOpen, anchorEl, onOpenMenu, onCloseMenu } = useAnchorEl();
  const [innerValues, setInnerValues] = useState<string[]>([]);
  const [showApplyButton, setShowApplyButton] = useState<boolean>(false);
  const [showList, setShowList] = useState<boolean>(false);
  const [valueItems, setValueItems] = useState<{ value: string; label: string }[]>(items);
  const [filterItems, setfilterItems] = useState<{ value: string; label: string }[]>(items);

  const handleChange = (value: string) => {
    const index = innerValues.findIndex((item) => item === value);
    if (index > -1) {
      innerValues.splice(index, 1);
      setInnerValues([...innerValues]);
    }
    if (index < 0) {
      innerValues.push(value);
      setInnerValues([...innerValues]);
    }
    setShowApplyButton(!areValuesEqual(innerValues, values));
  };
  const { palette } = useTheme();
  useEffect(() => {
    setValueItems([...items]);
  }, [items]);
  useEffect(() => {
    setInnerValues([...values]);
  }, [values]);

  const areValuesEqual = (innerValues: string[], values: string[]) => {
    if (innerValues.length !== values.length) {
      return false;
    }
    const sortedInnerValues = innerValues.slice().sort();
    const sortedValues = values.slice().sort();
    for (let i = 0; i < sortedInnerValues.length; i++) {
      if (sortedInnerValues[i] !== sortedValues[i]) {
        return false;
      }
    }
    return true;
  };

  const saveSelected = () => {
    onChange?.(innerValues);
  };
  const cancelSaveSelected = () => {
    setInnerValues([...values]);
  };
  const handleToggleAll = () => {
    setInnerValues([]);
    setShowApplyButton(!areValuesEqual([], values));
  };

  const [search, setSearch] = useState('');
  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

  useEffect(() => {
    setfilterItems(
      valueItems.filter((i) => {
        const findByLabel = i.label.toLowerCase().includes(search.toLowerCase().trim());
        const findByValue = i.value.toLowerCase().includes(search.toLowerCase().trim());
        return findByLabel || findByValue;
      })
    );
  }, [search, valueItems]);
  const handleClose = () => {
    if (!isOpen) return;
    onCloseMenu();
    setShowApplyButton(false);
    cancelSaveSelected();
    onOpenedMenu && onOpenedMenu(false);
  };

  const clearFilterValue = () => {
    applyFilter && applyFilter();
    setInnerValues([]);
    onChange?.([]);
  };
  const isShowResetButton = () => {
    return values.length;
  };
  const getSelectedNames = () => {
    const selectedNames = items.filter((item) => innerValues.includes(item.value));
    return selectedNames.map((item) => item.label);
  };
  const handleApply = () => {
    //

    onCloseMenu();
    setShowApplyButton(false);

    saveSelected();
    applyFilter && applyFilter();

    onOpenedMenu && onOpenedMenu(false);
  };
  return (
    <Box sx={{ mr: marginRight ? marginRight : '20px', ...sx, width: fullWidth ? '100%' : 'auto' }}>
      {formInput ? (
        <Text
          noWrap
          sx={{
            fontSize: '14px',
            fontWeight: 600,
            textTransform: 'capitalize',
            color: palette.primary.dark,
            marginBottom: hideLayoutMargin ? '0px' : '3px',
            marginTop: hideLayoutMargin ? '0px' : '3px',
          }}
        >
          {title}
        </Text>
      ) : (
        <Text noWrap sx={{ color: 'info.dark', fontSize: '12px', fontWeight: 500, pr: '2px', height: '15px' }}>
          {title}
        </Text>
      )}
      <Row
        position="relative"
        sx={{
          justifyContent: values && values.length ? 'space-between' : '',
        }}
      >
        {<>{isShowResetButton() ? <ResetButton onClick={clearFilterValue} /> : null}</>}

        <BaseBtn
          btnColor="info.main"
          disabled={disabled}
          onClick={(e) => {
            onOpenMenu(e);
            onOpenedMenu && onOpenedMenu(true);
            handleClose();
          }}
          sx={{
            borderColor: formInput ? palette.primary.dark : 'primary.dark',
            opacity: '1 !important',
            height: '29px',
            minWidth: '100%',
            px: 0,
            bgcolor: disabled ? 'info.contrastText' : 'info.main',
          }}
        >
          <Text
            noWrap
            sx={{
              color: 'primary.dark',
              fontSize: '14px',
              fontWeight: 500,
              width: 'calc(100% - 40px)',
              pl: values && values.length ? '35px' : '10px',
              textAlign: 'left',
              fontFamily: 'Noto Sans Hebrew !@important',
            }}
          >
            {values && values.length ? (
              <>{getSelectedNames().toString()}</>
            ) : (
              <Box component="span" sx={{ color: '#d3d3d3 !important' }}>
                {noSelectLabel ? noSelectLabel : t('display_all_filter_base_select')}
              </Box>
            )}
          </Text>

          <PlayArrow
            sx={{
              m: '7px',
              fontSize: '14px',
              color: formInput ? palette.info.dark : 'primary.dark',
              transform: `rotate(${isOpen ? '-90deg' : '90deg'})`,
            }}
          />
        </BaseBtn>
      </Row>
      {isOpen && (
        <ClickAwayListener onClickAway={handleClose}>
          <Box
            sx={{
              position: 'absolute',
              zIndex: '20',
              background: 'white',
              width: boxWidth ? boxWidth : '300px',
              borderTop: '1px solid grey',
              borderColor: 'rgba(255, 255, 255, 1)',
              borderRadius: '10px',
              boxShadow:
                '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
              mt: '5px',
              '& .MuiPaper-root': {
                borderRadius: '12px',
              },
            }}
          >
            <Box sx={{ position: 'relative' }}>
              <Box sx={{ maxHeight: '400px', overflowY: 'auto' }}>
                {withSearch && (
                  <SearchInput
                    onfocus
                    value={search}
                    onChange={handleChangeSearch}
                    placeholder={`${t('filter_search_placeholder')}`}
                    noBorder
                  />
                )}

                <Box
                  sx={{
                    borderTop: '1px solid grey',
                    borderColor: withSearch ? 'info.dark' : 'info.main',
                  }}
                >
                  {filterItems.length === 0 ? (
                    <Text sx={{ color: 'primary.main', textAlign: 'center', my: '10px' }}>{t('filter_no_items')}</Text>
                  ) : (
                    <FormControl sx={{ width: '100%' }}>
                      <>
                        <RadioGroup value={values} sx={{ width: '100%' }}>
                          {!hideAllButton && (
                            <Box
                              sx={{
                                borderBottom: '1px solid black',
                                borderColor: 'primary.light',
                                px: '8px',
                                mb: '2px',
                              }}
                            >
                              <Box
                                onClick={() => handleToggleAll()}
                                sx={{
                                  borderRadius: '10px',
                                  cursor: 'pointer',
                                }}
                              >
                                <FormControlLabel
                                  control={
                                    <Radio
                                      checked={innerValues.length === items.length || !innerValues.length}
                                      size="small"
                                    />
                                  }
                                  onClick={() => handleToggleAll()}
                                  label={
                                    <Text sx={{ color: 'black', pr: '10px' }}>
                                      {t('display_all_filter_base_select')}
                                    </Text>
                                  }
                                  sx={{
                                    fontSize: '11px',
                                    mx: 0,
                                  }}
                                />
                              </Box>
                            </Box>
                          )}
                          {/* //itemHeight={33} */}
                          <Box>
                            <List data={filterItems} height={listHeight ? listHeight : 201} itemHeight={33} itemKey="value">
                              {(index) => (
                                <CustomFormControlLabel
                                  key={index.value}
                                  onChange={() => handleChange(index.value)}
                                  value={index.value}
                                  labels={{ value: index.value, label: index.label }}
                                  hideValue={hideValue}
                                  checked={innerValues}
                                  withCheckboxes
                                />
                              )}
                            </List>
                          </Box>
                        </RadioGroup>
                      </>
                    </FormControl>
                  )}
                </Box>
              </Box>
              <Box sx={{ padding: '5px' }}>
                {showApplyButton && (
                  <BaseBtn onClick={handleApply} disabled={false} btnColor="primary.dark" fullWidth>
                    {t('apply_changes_multiselect')}
                  </BaseBtn>
                )}
              </Box>
            </Box>
          </Box>
        </ClickAwayListener>
      )}
    </Box>
  );
};

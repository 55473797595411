import { Box, useTheme } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Text } from 'shared/ui';
import { top_chevron } from 'shared/assets/';
interface Props {
  min: number;
  max: number;
  value: number;
  onChange: (value: number) => void;
  disabled?: boolean;
}

export const NumberSelect: FC<Props> = ({ min, max, value, disabled, onChange }) => {
  const { palette } = useTheme();
  const [canIncrementHours, setCanIncrementHours] = useState<boolean>(false);
  const [canDecrementHours, setCanDecrementHours] = useState<boolean>(false);
  const onIncrementHour = () => {
    if (canIncrementHours) {
      onChange(++value);
    }
  };
  const onDecrementHour = () => {
    if (canDecrementHours) {
      onChange(--value);
    }
  };

  useEffect(() => {
    if (disabled) {
      setCanIncrementHours(false);
      return;
    }
    setCanIncrementHours(value + 1 <= max);
  }, [value, min, max]);

  useEffect(() => {
    if (disabled) {
      setCanDecrementHours(false);
      return;
    }
    setCanDecrementHours(value - 1 >= min);
  }, [value, min, max]);

  return (
    <Box>
      <Box
        onClick={onIncrementHour}
        sx={{
          background: canIncrementHours ? 'white' : palette.secondary.light,
          width: '36px',
          height: '36px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '50%',
          cursor: canIncrementHours ? 'pointer' : 'default',
        }}
      >
        <img
          src={top_chevron}
          style={{
            height: '7px',
            width: '12px',
          }}
        />
      </Box>
      <Text
        sx={{
          fontSize: '18px',
          fontWeight: 400,
          color: 'black',
          width: '36px',
          textAlign: 'center',
          marginTop: '10px',
          marginBottom: '10px',
          cursor: 'default',
        }}
      >
        {disabled ? '00' : value}
      </Text>
      <Box
        onClick={onDecrementHour}
        sx={{
          background: canDecrementHours ? 'white' : palette.secondary.light,
          width: '36px',
          height: '36px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '50%',
          transform: 'scaleY(-1)',
          cursor: 'pointer',
        }}
      >
        <img
          src={top_chevron}
          style={{
            height: '7px',
            width: '12px',
          }}
        />{' '}
      </Box>
    </Box>
  );
};

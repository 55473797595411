import { Stack, IconButton, Box } from '@mui/material';
import { BaseInputAndSelect, BaseSelect, Filter } from 'features';
import { t } from 'i18next';
import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { close_popup } from 'shared/assets';
import { useAnchorEl, useAppDispatch, useAppSelector } from 'shared/hooks';
import { NewController } from 'shared/models';
import { setInitHwId, toggleAddController } from 'shared/slices';
import { BaseBtn, BaseInput, BigTitle, Confirm, Loader, Modal, Row } from 'shared/ui';
import { useFilterControllers } from './hooks';
import { useControllers } from 'shared/api';
import { replaceSpacesWithUnderscores } from 'shared/lib';

interface Props {
  customerFilter: string;
  openAddControllerModal: boolean;
  onCloseModal: () => void;
}
const initialErrors = {
  controllersCount: '',
  nameTemplate: '',
  initialControllerId: '',
};

export const AddController: FC<Props> = ({ customerFilter, openAddControllerModal, onCloseModal }) => {
  const dispatch = useAppDispatch();
  const { getControllers, getInitHwId } = useControllers();
  const { controllersCountItems, modelFilterItems, customerFilterItems } = useFilterControllers();
  const { createController, isLoadingControllers } = useControllers();
  const [isChanged, setIsChanged] = useState(false);
  const { isOpen, anchorEl, onOpenMenu, onCloseMenu } = useAnchorEl();
  const [errors, setErrors] = useState(initialErrors);
  const { initHwId } = useAppSelector((st) => st.controllers);
  const initialValue = {
    controllersCount: '200',
    modelId: 'I1',
    customerId: '87',
    nameTemplate: t('new_controller_name'),
    notes: '',
    initialControllerId: '',
  };
  const [value, setValue] = useState<NewController>(initialValue);
  const handleCloseAddModal = (e: React.MouseEvent<HTMLElement>) => {
    isChanged ? onOpenMenu(e) : closeModal();
  };
  const [hasErrors, setHasErrors] = useState(false);
  useEffect(() => {
    setHasErrors(!Object.values(errors).every((value) => value === ''));
  }, [errors]);
  useEffect(() => {
    if (openAddControllerModal) {
      getInitHwId();
      return;
    }
    dispatch(setInitHwId(null));
  }, [openAddControllerModal]);
  useEffect(() => {
    if (initHwId) {
      setValue((prevValues) => ({
        ...prevValues,
        initialControllerId: initHwId.toString(),
      }));
    }
  }, [initHwId]);

  const closeModal = () => {
    dispatch(toggleAddController());
    setValue(initialValue);
    setIsChanged(false);
    setErrors(initialErrors);
    onCloseModal();
  };
  const handleSave = async () => {
    if (hasErrors) return;
    const formattedValue = {
      controllersCount: parseInt(value.controllersCount),
      modelId: value.modelId,
      customerId: parseInt(value.customerId),
      nameTemplate: value.nameTemplate,
      notes: value.notes,
      initHwId: parseInt(value.initialControllerId),
    };
    const result = await createController(formattedValue);
    if (result.data.processResultValue < 1) return;
    closeModal();
    getControllers(customerFilter);
  };
  const onChangeControllersCount = (value: string) => {
    checkControllersCount(value);
    setValue((prevValues) => ({
      ...prevValues,
      controllersCount: value,
    }));
    setIsChanged(true);
  };
  const onChangeInitialControllerId = (value: string) => {
    //checkControllersCount(value);
    setValue((prevValues) => ({
      ...prevValues,
      initialControllerId: value,
    }));
    setIsChanged(true);
  };
  const onChangeModelId = (value: string) => {
    setValue((prevValues) => ({
      ...prevValues,
      modelId: value,
    }));
    setIsChanged(true);
  };
  const onChangeName = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newValue = event.target.value;
    checkControllersName(newValue);
    setValue((prevValues) => ({
      ...prevValues,
      ['nameTemplate']: newValue,
    }));
    setIsChanged(true);
  };
  const onChangeValues = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, propertyName: string) => {
    const newValue = event.target.value;
    setValue((prevValues) => ({
      ...prevValues,
      [propertyName]: newValue,
    }));
    setIsChanged(true);
  };
  const onChangeCustomerValue = (value: string) => {
    setValue((prevValues) => ({
      ...prevValues,
      customerId: value,
    }));
    setIsChanged(true);
  };
  const checkControllersName = (value: string) => {
    if (!value) {
      setErrors((prevValues) => ({
        ...prevValues,
        ['nameTemplate']: 'please enter controller name',
      }));
      return;
    }
    if (value.length < 3) {
      setErrors((prevValues) => ({
        ...prevValues,
        ['nameTemplate']: 'name must contain at least 3 characters',
      }));
      return;
    }
    if (value.length > 50) {
      setErrors((prevValues) => ({
        ...prevValues,
        ['nameTemplate']: 'max length 50 characters',
      }));
      return;
    }
    setErrors((prevValues) => ({
      ...prevValues,
      ['nameTemplate']: '',
    }));
  };
  const checkControllersCount = (value: string) => {
    if (parseInt(value) <= 2000 && parseInt(value) > 0) {
      setErrors((prevValues) => ({
        ...prevValues,
        ['controllersCount']: '',
      }));
      return;
    }
    setErrors((prevValues) => ({
      ...prevValues,
      ['controllersCount']: 'controllers_count_must_be_between_1_and_2000',
    }));
  };

  const closeButtonRef = useRef<HTMLButtonElement | null>(null);
  const handleModalClose = () => {
    if (closeButtonRef.current) {
      closeButtonRef.current.click();
    }
  };
  return (
    <Modal open={openAddControllerModal} sx={{ width: 660, margin: 'auto' }} onClose={handleModalClose}>
      {!initHwId && (
        <Box sx={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, zIndex: 20 }}>
          <Loader />
        </Box>
      )}
      <Box sx={{ opacity: initHwId ? '1' : '0.5' }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <BigTitle>{t('add_controller_add_modal')}</BigTitle>
          <IconButton ref={closeButtonRef} onClick={handleCloseAddModal}>
            <img src={close_popup} />
          </IconButton>
        </Stack>
        <Row spacing="20px" mb={'10px'} alignItems="flex-start" justifyContent="space-between">
          <Box width={'50%'}>
            <Row alignItems="flex-start" justifyContent="space-between">
              <BaseInputAndSelect
                title={`${t('controllers_count_add_modal')} *`}
                value={value.controllersCount}
                onChange={onChangeControllersCount}
                items={controllersCountItems}
                hideAllButton
                hideResetButton
                hideValue
                controlsWidth={160}
                formInput
                small
                type="number"
                compact
                width={'255px'}
                sx={{ width: '255px' }}
                error={!!errors.controllersCount}
                helperText={replaceSpacesWithUnderscores(errors.controllersCount)}
              />
            </Row>
            <BaseInput
              label={`${t('Initial_controller_id_label_controller_add_modal')}`}
              name="initialControllerId"
              onChange={(event) => onChangeInitialControllerId(event.target.value)}
              value={value.initialControllerId}
              small
              type="number"
              compact
              helperText={''}
            />

            <BaseInput
              label={`${t('controller_name_controller_add_modal')} *`}
              name="nameTemplate"
              error={!!errors.nameTemplate}
              helperText={replaceSpacesWithUnderscores(errors.nameTemplate)}
              onChange={(event) => onChangeName(event)}
              value={value.nameTemplate}
              small
              compact
            />
          </Box>
          <Box width={'50%'}>
            <BaseSelect
              title={`${t('model_id_label_controller_add_modal')}  *`}
              value={value.modelId}
              onChange={onChangeModelId}
              items={modelFilterItems}
              hideAllButton
              noSelectLabel={`${t('select_model_id_add_modal')}`}
              sx={{ width: '250px' }}
              hideValue
              formInput
              withoutMargin
            />
            <BaseSelect
              title={`${t('customer_label_controller_add_modal')}  *`}
              onChange={onChangeCustomerValue}
              value={value.customerId}
              items={customerFilterItems}
              hideAllButton
              showOnlyResetButton
              withSearch
              showHomeButton
              hideResetButton
              formInput
              noSelectLabel={`${t('customer_label_controller_add_modal')}`}
              sx={{ width: '250px' }}
              withoutMargin
            />
          </Box>
        </Row>
        <Box width={'100%'}>
          <BaseInput
            label={`${t('Notes_label_controller_add_modal')}`}
            name="notes"
            value={value.notes}
            onChange={(event) => onChangeValues(event, 'notes')}
            error={false}
            helperText={''}
            medium
            compact
          />
        </Box>
        <Row justifyContent="flex-end" mt={'35px'}>
          <Row spacing="15px" width={500}>
            <BaseBtn onClick={handleCloseAddModal} btnColor="info.main" fullWidth>
              {t('Cancel_button_controller_add_modal')}
            </BaseBtn>

            <BaseBtn
              onClick={handleSave}
              disabled={!isChanged || hasErrors || !value.nameTemplate}
              loading={isLoadingControllers}
              btnColor="primary.dark"
              fullWidth
            >
              {t('Update_button_controller_add_modal')}
              {hasErrors}
            </BaseBtn>
          </Row>
        </Row>
        <Confirm
          open={isOpen}
          anchorEl={anchorEl}
          onClose={onCloseMenu}
          confirm={closeModal}
          buttonTexts={[t('cancel_add_add_modal'), t('back_to_add_add_modal')]}
        />
      </Box>
    </Modal>
  );
};

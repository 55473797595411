import { Box, Checkbox, CircularProgress, FormControlLabel, Typography } from '@mui/material';
import { BaseExportToExcel, CustomerSelect } from 'components';
import { BaseDataPicker, Filter, ReplacedEquipmentReportTable } from 'features';
import { useEffect, useMemo, useState } from 'react';
import { useGetCustomers, useReplacedEquipmentReportManagement, } from 'shared/api';
import { action_start, checked_box, un_checked_box } from 'shared/assets';
import { useAppDispatch, useAppSelector, useExtendedTranslation } from 'shared/hooks';
import { ReplacedEquipmentReportFilter } from 'shared/models';
import { clearEquipmentReplacementReportItems } from 'shared/slices';
import { BaseBtn, BigTitle, Icon, Row } from 'shared/ui';
import TotalRows from 'shared/ui/TotalRows';
import { useFilterReplacedEquipment, useFormatXlsxData, useGetDefaultParams } from './hooks';
import { TABLE_TITLES } from './constants';
export type TableTitle = { title: string; key: string; width: number | string };
export const ReplacedEquipmentReportManager = () => {
  const { equipmentReplacementReportItems } = useAppSelector((st) => st.equipmentReplacementReport);
  const [isStartWasPressed, setIsStartWasPressed] = useState(false);
  const [tableTitles, setTableTitles] = useState<TableTitle[]>([]);
  const { t } = useExtendedTranslation();
  const dispatch = useAppDispatch();
  const {
    startDate,
    endDate,
  } = useGetDefaultParams();
  const initialFilter = {
    startDate: startDate,
    endDate: endDate,
  };
  const { formatTable } = useFormatXlsxData();
  const [filter, setfilter] = useState<ReplacedEquipmentReportFilter>(initialFilter);
  const [advancedParameters, setAdvancedParameters] = useState(false);
  const { getReplacedEquipmentTypes, getReplacedEquipmentReport } = useReplacedEquipmentReportManagement();
  const { equipmentTypes } = useAppSelector((st) => st.equipmentReplacementReport);
  const { isLoadingCustomers } = useGetCustomers();
  const [isLoading, setLoading] = useState(false);
  const toggleAdvancedParameters = () => {
    setAdvancedParameters(!advancedParameters);
  };
  useEffect(() => {
    if (equipmentReplacementReportItems.length) {
      createTableTitles();
    }
  }, [equipmentReplacementReportItems]);
  const onChangeDateValues = (param: string, value: string) => {
    setfilter((prevValues) => ({
      ...prevValues,
      [param]: value,
    }));
  };
  useEffect(() => {
    const fetchData = async () => {
      getReplacedEquipmentTypes()
    };
    fetchData();
  }, []);
  const generateReport = async () => {
    setIsStartWasPressed(true);
    dispatch(clearEquipmentReplacementReportItems())
    setLoading(true);
    await getReplacedEquipmentReport({
      customerID: customersFilterValue ? customersFilterValue : 0,
      dateFrom: filter.startDate,
      dateTo: filter.endDate,
      eqTypes: equipmentTypesFilterValues.join()
    });
    setLoading(false);

  };
  const { items } = useAppSelector((st) => st.batteryStatusReport);
  const createTableTitles = (() => {
    const tableTitles: TableTitle[] = [...TABLE_TITLES]
    equipmentTypes.forEach((item => {
      // eslint-disable-next-line no-prototype-builtins
      if (equipmentReplacementReportItems[0].hasOwnProperty(item.name)) {
        tableTitles.push({ title: item.name, key: item.name, width: '50%' },)
      }
    }))
    setTableTitles(tableTitles)
  })
  const {
    customersFilterValue,
    onChangeCustomersFilterItem,
    equipmentTypesFilterItems,
    onChangeEquipmentTypesFilter,
    equipmentTypesFilterValues
  } = useFilterReplacedEquipment();

  const BaseExportToExcelComponent = useMemo(
    () => (<BaseExportToExcel
      tableData={equipmentReplacementReportItems}
      sheetName="REPLACED EQUIPMENT REPORT"
      fileName="replacedEquipmentReport.xlsx"
      buttonText={t('export_battery_status_report_items_page_button')}
      formattedTable={formatTable(equipmentReplacementReportItems)}
      tableTitles={tableTitles}
      dateColumnIndexes={[0, 1]}
      headerItemsPrefix={''}
      preHeaderItemsPrefix={'equipment_type_'}
    />)
    ,
    [equipmentReplacementReportItems, tableTitles]
  );

  return (
    <>
      <Row sx={{ mb: '12px' }}>
        <BigTitle sx={{ mr: '25px' }}>{t('title_equipment_replaced_report')}</BigTitle>
      </Row>
      <Row sx={{ mt: '6px' }} flexWrap="wrap" rowGap="5px" alignItems="flex-end">
        <BigTitle sx={{ fontSize: '24px', mr: '20px' }}>{t('equipment_replaced_report_start')}</BigTitle>
        <BaseDataPicker
          label={`${t('start_date_filter_equipment_replaced_report_label')}`}
          name="startDate"
          value={filter.startDate}
          onChange={onChangeDateValues}
        />
        <BigTitle sx={{ fontSize: '24px', ml: '20px', mr: '20px' }}>{t('equipment_replaced_report_end')}</BigTitle>
        <BaseDataPicker
          label={`${t('end_date_filter_equipment_replaced_report_label')}`}
          name="endDate"
          value={filter.endDate}
          onChange={onChangeDateValues}
          error={null}
        />
        <FormControlLabel
          value="end"
          sx={{ ml: '20px' }}
          control={
            <Checkbox
              size="small"
              checked={advancedParameters}
              onChange={toggleAdvancedParameters}
              icon={<Icon src={un_checked_box} />}
              checkedIcon={<Icon src={checked_box} />}
            ></Checkbox>
          }
          label={`${t('advanced_parameters_filter_equipment_replaced_report')}`}
          labelPlacement="end"
        />
        <CustomerSelect
          title={`${t('customer_label')}`}
          value={customersFilterValue}
          onChange={onChangeCustomersFilterItem}
          hideAllButton
          showHomeButton
          showOnlyResetButton
          withSearch
          sx={{ width: '260px' }}
        />
        {!advancedParameters && (
          <>
            <BaseBtn onClick={generateReport} sx={{ mt: '16px', height: '29px', mr: '20px' }}>
              {t('filter_equipment_replaced_action_start')}
              <Icon sx={{ ml: '6px' }} src={action_start} />
            </BaseBtn>
            <Row sx={{ flexGrow: 1 }} />
            <Box sx={{ display: 'flex', marginLeft: 'auto', alignItems: 'flex-end' }}>
              <TotalRows count={equipmentReplacementReportItems ? equipmentReplacementReportItems.length : 0} />


              {BaseExportToExcelComponent}
            </Box>
          </>
        )}
      </Row>
      {advancedParameters && (
        <Row sx={{ mt: '6px' }} flexWrap="wrap" rowGap="5px" alignItems="flex-end">
          <Filter
            title={`${t('equipment_types_filter')}`}
            checkBoxValues={equipmentTypesFilterValues}
            withConfirm
            onChangeCheckBoxValues={onChangeEquipmentTypesFilter}
            items={equipmentTypesFilterItems}
            sx={{ width: '260px' }}
            messageEmptyFilter="display_all_equipment_types"
          />
          <>
            <BaseBtn onClick={generateReport} sx={{ mt: '16px', height: '29px', mr: '20px' }}>
              {t('filter_equipment_replaced_action_start')}
              <Icon sx={{ ml: '6px' }} src={action_start} />
            </BaseBtn>
            <Row sx={{ flexGrow: 1 }} />
            <Box sx={{ display: 'flex', marginLeft: 'auto', alignItems: 'flex-end' }}>
              <TotalRows count={equipmentReplacementReportItems ? equipmentReplacementReportItems.length : 0} />

              {BaseExportToExcelComponent}

            </Box>
          </>
        </Row>
      )}
      {!equipmentReplacementReportItems.length && isStartWasPressed && isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '60%',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        equipmentReplacementReportItems.length ? <ReplacedEquipmentReportTable
          tableItems={equipmentReplacementReportItems}
          isStartedLoadingTable={isStartWasPressed}
        /> :
          isStartWasPressed && <Typography
            sx={{
              fontFamily: 'Noto Sans Hebrew',
              fontSize: '24px',
              fontWeight: 600,
              color: 'primary.dark',
              textAlign: 'center',
              mt: '20px',
            }}
          >
            {t('no_controllers_report_table_grid_table')}
          </Typography>
      )}
    </>
  );
};

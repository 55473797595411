import { Box } from '@mui/material';
import { BaseMultiselect, BaseTable } from 'components';
import { t } from 'i18next';
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { refresh } from 'shared/assets';
import { BaseBtn, Icon, Loader, Row, SearchInput, Text } from 'shared/ui';
import { useFilter } from './hooks/useFilter';
import { useAppSelector } from 'shared/hooks';
import { useWeatherStationsManager } from 'features/WeatherStationsManager/hooks/api';
import { Filter } from 'features';
import { TABLE_TITLES } from './constants';
import { relative } from 'path';

interface Props {
  customerID: number;
  objectID: number;
}

export const IrrigationControllers: FC<Props> = ({ customerID, objectID }) => {
  const { getGeographicZone, getIrrigationControllers, loading } = useWeatherStationsManager();

  const handleOpenUpdateIrrigationControllers = () => {
    getIrrigationControllers(objectID, customerID);
  };
  const { irrigationControllers } = useAppSelector((st) => st.weatherStation);

  const [searchValue, setSearchValue] = useState('');

  const [selectedIrrigationControllers, setSelectedIrrigationControllers] = useState([]);
  const [highlightCheckboxes, setHighlightCheckboxes] = useState([]);

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value);
  const {
    onChangeGeographicZone,
    geographicZoneFilter,
    geographicZoneItems,
    irrigationControllerStatus,
    onChangeIrrigationControllerStatus,
    irrigationControllerStatusItems,
    getSearchedIrrigationController,
  } = useFilter();

  useEffect(() => {
    getGeographicZone(customerID);
    getIrrigationControllers(objectID, customerID);
  }, []);
  useEffect(() => {
    if (irrigationControllers) {
      const selectedControllers = [];
      const highlightCheckboxes = [];
      irrigationControllers.forEach((item) => {
        if (item.statusEx == 1) {
          selectedControllers.push(item.objectID);
        }
        if (item.statusEx == -1) {
          highlightCheckboxes.push(item.objectID);
        }
      });
      setSelectedIrrigationControllers(selectedControllers);
      setHighlightCheckboxes(highlightCheckboxes);
    }
  }, [irrigationControllers]);
  const filteredIrrigationControllers = useMemo(() => {
    if (!irrigationControllers) return [];
    let filteredItems = irrigationControllers.filter((c) => geographicZoneFilter.includes(c?.zoneID?.toString()));
    if (!geographicZoneFilter.length) {
      filteredItems = irrigationControllers;
    }
    filteredItems = irrigationControllerStatus
      ? filteredItems.filter((c) => irrigationControllerStatus === c?.status?.toString())
      : filteredItems;
    const searchedCustomers = getSearchedIrrigationController(filteredItems, searchValue);
    return searchedCustomers;
  }, [searchValue, geographicZoneFilter, irrigationControllerStatus]);

  return (
    <Box>
      <Row spacing="20px" alignItems="end" justifyContent={'space-between'}>
        <Box>
          <Text sx={{ fontSize: '20px', m: 0, fontWeight: 900, color: 'primary.dark' }}>
            {t('irrigation_controllers_meteorological_station_modal')}
          </Text>
        </Box>
        <BaseBtn
          onClick={(e) => {
            handleOpenUpdateIrrigationControllers();
          }}
          sx={{ height: '29px' }}
        >
          <Icon src={refresh} />
          {`${t('update_max_evaporation_rates')}`}
        </BaseBtn>
      </Row>
      <Row alignItems="end" sx={{ margin: '8px 0' }}>
        <SearchInput
          value={searchValue}
          onChange={handleChangeSearch}
          placeholder={`${t('search_irrigation_controllers')}`}
        />
      </Row>
      <Row spacing="20px" alignItems="end">
        <Text sx={{ fontSize: '20px', m: 0, fontWeight: 900, color: 'primary.dark' }}>
          {t('filters_details_title')}
        </Text>
        <Box>
          <Row spacing="20px" alignItems="end">
            <Box sx={{ minWidth: '40%' }}>
              <BaseMultiselect
                title={`${t('geographic_zone_label')}`}
                values={geographicZoneFilter}
                onChange={onChangeGeographicZone}
                // applyFilter={onSelectFilter}
                items={geographicZoneItems}
                withSearch
                sx={{ marginRight: '0px' }}
              />
            </Box>
            <Box sx={{ minWidth: '40%' }}>
              <Filter
                title={`${t('irrigation_controller_status_label')}`}
                value={irrigationControllerStatus}
                onChange={(value) => {
                  onChangeIrrigationControllerStatus(value);
                }}
                items={irrigationControllerStatusItems}
                fullWidth
                messageEmptyFilter="display_all_irrigation_controller_status"
                marginRight={'0px'}
                sx={{ width: '180px' }}
              />
            </Box>
          </Row>
        </Box>
      </Row>
      <Box height={'350px'} sx={{ overflow: 'hidden', width: '100%', position: 'relative' }}>
        {loading && (
          <Box
            sx={{
              background: '#ffffffcc',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '300px',
              position: 'absolute',
              zIndex: 50,
            }}
          >
            <Loader />
          </Box>
        )}
        <BaseTable
          tableTitles={TABLE_TITLES}
          //translateColumns={TRANSLATE_COLUMNS}
          tableItems={filteredIrrigationControllers}
          loadedItems={true}
          idField={'objectID'}
          noItemsText={t('no_irrigation_controllers')}
          headerItemsPrefix={'_irrigation_controllers_grid_table'}
          // onRowSelectionModelChange={rowSelectionModelChange}
          selectedIDs={selectedIrrigationControllers}
          highlightCheckboxes={highlightCheckboxes}
          maxHeight={'300px'}
          checkboxSelection
          defaultScrollWidth={32}
        />
      </Box>
    </Box>
  );
};

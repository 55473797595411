import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { axiosBase } from 'shared/config';
import { useAppDispatch, useToast, useAppSelector } from 'shared/hooks';
import { Controller } from 'shared/models';
import { setDailyConsumption, setDailyConsumptionChanged, setDailyConsumptionDefaultData } from 'shared/slices';
type DailyConsumption = {
  objectID: number;
  startDate: string;
  endDate: string;
  actionType: string;
};
type DailyConsumptionParams = {
  objectID:number,
  startDate:string,
  endDate:string,
  uncontrolledFlow:boolean
  actualConsumption:boolean
};

export const useDailyConsumption = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { showError, showSuccess } = useToast();
  const push = useNavigate();
  const {  controllerClearDataFilter } = useAppSelector((st) => st.controllers);
  const [isLoadingDailyConsumption, setLoading] = React.useState(true);
  const getDailyConsumption = async (dailyConsumptionParams: DailyConsumption ) => {
    setLoading(true);
    dispatch(setDailyConsumptionChanged(false));
    try {
      const { data } = await axiosBase.post('/device/dailyconsumption', dailyConsumptionParams);
      dispatch(setDailyConsumption(data?.data));
    } catch (error: any) {
      console.log('#ERROR', error?.response?.status);
      if (error?.response?.status === 401) {
        push('/login');
      } else {
        showError(t('something_went_wrong_toast_text'));
      }
    } finally {
      setLoading(false);
    }
  };
  const getDailyConsumptionInitialdates = async (objectID: number) => {
    setLoading(true);
    try {
      const sendData = {
        objectID,
      };
      const { data } = await axiosBase.post('/device/dailyconsumption/initialdates', sendData);
      const result = {
        startDate: data?.data.graphDateStart,
        endDate: data?.data.graphDateEnd,
      };
      dispatch(setDailyConsumptionDefaultData(result));
    } catch (error: any) {
      console.log('#ERROR', error?.response?.status);
      if (error?.response?.status === 401) {
        push('/login');
      } else {
        showError(t('something_went_wrong_toast_text'));
      }
    } finally {
      setLoading(false);
    }
  };
  const deleteDailyConsumption = async (currentController:Controller) => {
    setLoading(true);
    dispatch(setDailyConsumptionChanged(false));

    try {
      const objectID = currentController ? currentController.deviceId : null;
      if (!objectID) return;
      const filter = {
        objectID,
        startDate: controllerClearDataFilter.startDate,
        endDate: controllerClearDataFilter.endDate,
        uncontrolledFlow: controllerClearDataFilter.uncontrolledFlow,
        actualConsumption: controllerClearDataFilter.actualConsumption,
      };
      const { data } = await axiosBase.put('/device/dailyconsumption/clear', filter);
      showSuccess(t('daily_consumption_success_deleted_graph_data'));
    } catch (error: any) {
      console.log('#ERROR', error?.response?.status);
      if (error?.response?.status === 401) {
        push('/login');
      } else {
        showError(t('something_went_wrong_toast_text'));
      }
    } finally {
      setLoading(false);
    }
  };

  const logDailyConsumption = async (dailyConsumptionParams:DailyConsumptionParams) => {
    setLoading(true);

    try {
       await axiosBase.post('/device/dailyconsumption/logClearData', dailyConsumptionParams);
    } catch (error: any) {
      console.log('#ERROR', error?.response?.status);
      if (error?.response?.status === 401) {
        push('/login');
      } else {
        showError(t('something_went_wrong_toast_text'));
      }
    } finally {
      setLoading(false);
    }
  };

  return {
    getDailyConsumption,
    isLoadingDailyConsumption,
    deleteDailyConsumption,
    getDailyConsumptionInitialdates,
    logDailyConsumption
  };
};

import { useAppDispatch } from 'shared/hooks';
import { setCustomerFilter } from 'shared/slices';
import { useAppSelector, useExtendedTranslation } from 'shared/hooks';
import { useState, useEffect, useMemo } from 'react';
import { IrrigationController } from 'features/WeatherStationsManager/WeatherStationModels';
export const useFilter = () => {
  const { t } = useExtendedTranslation();

  const { geographicZones } = useAppSelector((st) => st.weatherStation);
  const geographicZoneItems = useMemo(() => geographicZones.map((s) => ({
    value: s.zoneID.toString(),
    label: s.zoneName,
  })), [geographicZones])

  const irrigationControllerStatusItems = [
    {
      value: '0',
      label: `${t('irrigation_controller_status_disable')}`,
    },
    {
      value: '1',
      label: `${t('irrigation_controller_status_active')}`,
    },
  ];



  const [geographicZoneFilter, setGeographicZoneFilter] = useState<string[]>([]);
  const onChangeGeographicZone = (values: string[]) => setGeographicZoneFilter(values);

  const [irrigationControllerStatus, setIrrigationControllerStatus] = useState<string>('1');
  const onChangeIrrigationControllerStatus = (value: string) => {
    setIrrigationControllerStatus(value);
  };


  const getSearchedIrrigationController = (irrigationController: IrrigationController[], searchValue: string) => {
    const searched = irrigationController.filter((c) => {
      const values = Object.values(c);

      return values.some((v) => v?.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase().trim()));
    });

    return searched;
  };

  return {
    onChangeGeographicZone,
    geographicZoneFilter,
    geographicZoneItems,
    irrigationControllerStatus,
    onChangeIrrigationControllerStatus,
    irrigationControllerStatusItems,
    getSearchedIrrigationController
  };
};

import { AppBar } from '@mui/material';
import { Navigation, UserInfo } from 'features';

import { Logo, Row } from 'shared/ui';

export const Header = () => {
  return (
    <AppBar
      position="relative"
      sx={{
        bgcolor: 'primary.dark',
        height: '64px',
        boxShadow: 'none',
        px: '35px',
        display: 'flex',
        zIndex: 50,
      }}
    >
      <Row sx={{ height: '100%' }}>
        <Logo to={'/'} />
        <Navigation />
        <Row sx={{ flexGrow: 1 }} />
        <UserInfo />
        {/* <Notifications /> */}
      </Row>
    </AppBar>
  );
};

import { Controller } from 'shared/models';

import { TableTitle} from 'components/BaseTable';

export const TABLE_TITLES: TableTitle<Controller>[] = [
  { title: 'ID', key: 'deviceId', width: 60 },
  { title: 'Controller_Name', key: 'objectName', width: '300%' },
  { title: 'Customer_ID', key: 'customerId', width: '100%' },
  { title: 'Customer_Name', key: 'customerName', width: '100%' },
  { title: 'Hardware_ID', key: 'hwId', width: '100%' },
  { title: 'Model', key: 'modelId', width: '100%' },
  { title: 'Station', key: 'stationId', width: '100%' },
  { title: 'Gateway', key: 'gatewayId', width: '100%' },
  { title: 'Install_address', key: 'installAddress', width: '100%' },
  { title: 'Latitude_Dec', key: 'latitudeDec', width: '100%' },
  { title: 'Longitude_Dec', key: 'longitudeDec', width: '100%' },
  { title: 'Install_Date', key: 'installDate', width: '100%', type:'date'},
  { title: 'Notes', key: 'objectNotes', width: '200%' },
];

import { FC } from "react";

interface CustomizedAxisXTickProps {
  x: number;
  y: number;
  payload: { value: string };
  isSmallChart?: boolean;
  bigLegendText?: boolean;
}
export const CustomizedAxisXTick: FC<CustomizedAxisXTickProps> = (props:CustomizedAxisXTickProps) => {
  const { x, y, payload,  isSmallChart, bigLegendText} = props;
  return (
    <g transform={`translate(${x},${y})`}>
    { isSmallChart || bigLegendText?  <text x={-13} y={bigLegendText ? 8: 2} dy={10} fontSize='11px' textAnchor="middle" fill="black" transform='rotate(-35)'>
          {payload.value}
      </text> : 
      <text x={0} y={2} dy={8} fontSize='11px' textAnchor="middle" fill="black">
          {payload.value}
      </text> 
      }
    </g>
  );
};

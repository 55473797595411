import { useAppDispatch,useAnchorEl, useAppSelector, useExtendedTranslation } from 'shared/hooks';
import { Row, BigTitle, SearchInput, Loader, Text, Icon, BaseInfo, MenuList, BasePageSettings} from 'shared/ui';
import { Grid, Box, useTheme, Stack, Button, Checkbox, Link} from '@mui/material';
import { ChartBlock } from './components';
import { FC, useEffect, useState } from 'react';
import { useDashboard } from 'shared/api';
import localStorageService from 'shared/localStorage';
export const DashboardManager = () => {
  const dispatch = useAppDispatch();
  const { t } = useExtendedTranslation();
  const PAGE_OPTIONS=[{name:t('make_dashboard_default'), value:'dashboard'}, {name:t('make_shortcuts_default'),value:'shortcuts' } ]
  const { palette } = useTheme();
  const { getDashboard } = useDashboard();
  const { dashboard } = useAppSelector((st) => st.dashboard);
  const [defaultPage, setDefaultPage] = useState<string>('shortcuts');
  
  useEffect(() => {
    const fetchData = async () => {
      getDashboard()
    };
    fetchData();
    setDefaultValue();
  }, []);
  const getDataFromLocalStorage = () => {
    const data = localStorageService.loginDefaultPage;
    return data ? JSON.parse(data) : 'shortcuts';
  };
  const setDefaultValue=()=>{
    const data = getDataFromLocalStorage();
    setDefaultPage(data)
  }
  const handleChange=(value:string)=>{
    localStorageService.loginDefaultPage =  JSON.stringify(value);
    setDefaultPage(value)
  }
  
  return (
    <>
          <Stack direction="row" justifyContent="space-between" alignItems="end" sx={{ mb: '12px' }}>
            <BigTitle sx={{ mr: '25px' }}>{t('dashboard_title')}</BigTitle>
            <Text noWrap sx={{ color: 'rgba(0, 0, 0, 0.75)', fontSize: '16px', fontWeight: 500, pr: '2px' }}>
             {t('total_controllers_count')}
             <Link
              href={'/controller-details?customerType=1'}
              sx={{ color: 'primary.dark', ml: '6px', textDecoration: 'underline', cursor: 'pointer' }}
            >
              {dashboard?.totalDevicesCount}
            </Link> 
            </Text>
            <Row sx={{ flexGrow: 1 }} />
            <Box>
            <BasePageSettings handleChange={handleChange}  selected={defaultPage} options={PAGE_OPTIONS} />
          </Box>
          </Stack>
          {dashboard && <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
           <ChartBlock dashboard={dashboard} chartName="deviceTypeCount" valueNameKey="deviceType" title={t('device_type_chart_title')}/>
          </Grid>
          <Grid item xs={12} md={4}>
            <ChartBlock dashboard={dashboard} chartName="cellProviderDeviceCount" valueNameKey="cellProvider" title={t('cellular_provider_chart_title')}/>
          </Grid>
          <Grid item xs={12} md={4}>
             <ChartBlock dashboard={dashboard} withoutTranslation bigLegendText chartName="customerDeviceCount" valueNameKey="customerName" title={t('customers_count_chart_title')}/>
          </Grid>
          <Grid item xs={12} md={4}>
            <ChartBlock dashboard={dashboard} withoutTranslation chartName="installDateDeviceCount" valueNameKey="yearCategory" title={t('install_date_chart_title')}/>
          </Grid>
          <Grid item xs={12} md={4}>
            <ChartBlock dashboard={dashboard} chartName="communicationIntervalDeviceCount" valueNameKey="interval" title={t('last_communication_chart_title')}/>
          </Grid>
          <Grid item xs={12} md={4}>
            {/* <ChartBlock dashboard={dashboard} chartName="" valueNameKey="" title={t('')}/> */}
            <Box
              sx={{
                border: `1px solid ${palette.primary.dark}`,
                borderRadius: '10px',
                height: '350px',
                display: 'flex',
                flexDirection:'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor:'white'
              }}
            ></Box>
          </Grid>
          </Grid>}
    </>
  );
};

import { t } from 'i18next';
import { useState } from 'react';
import { useServiceCalls } from './api/useServiceCalls';
import dayjs from 'dayjs';
import { useAppSelector } from 'shared/hooks';
import { formatDtoDate } from 'shared/lib';
export const useFilter = () => {
  const { SERVICE_CALL } = useAppSelector((st) => st.globalSlice);
  
  const { faultEquipment, actionType, assignedTech } = useAppSelector((st) => st.allServiceCalls);
  const dbYday = dayjs();
  const startDateDayjs =  dbYday.subtract(SERVICE_CALL.REPORT_START_DAYS_AGO, 'day');
  const defaultStartDate = startDateDayjs.format('YYYY-MM-DD');

  const endDateDayjs = dbYday;
  const defaultEndDate = endDateDayjs.format('YYYY-MM-DD');


  const [startDate, setStartDate] = useState<string>(defaultStartDate);
  const [endDate, setEndDate] = useState<string>( defaultEndDate);
  const [minCallCount, setMinCallCount] = useState<number>(3);

  const [faultEquipmentValue, setFaultEquipmentValue]= useState<string[]>( [])
  const [actionTypeValue, setActionTypeValue]= useState<string[]>( [])
  const [assignedTechValue, setAssignedTechValue]= useState<string[]>( [])
  const [callStatusValue, setCallStatusValue]= useState<string[]>( [])
  const [datesIntervalFilter, setDatesIntervalFilter]= useState<string>('Half-year back')
  
const onChangeStartDate=(startDate:string)=>{
  setDatesIntervalFilter('')
  setStartDate(startDate)
  
}
const onChangeEndDate=(endDate:string)=>{
  setDatesIntervalFilter('')
  setEndDate(endDate)
}
const onChangeMinCallCount=(count:number)=>{
  setMinCallCount(count)
}

const onChangeFaultEquipment=(items: string[])=>{
  setFaultEquipmentValue(items)
}
const faultEquipmentItems = faultEquipment.map((value) => ({ value:value.languageCode, label: t(`${value.languageCode}`) }));


const onChangeActionType=(items: string[])=>{
  setActionTypeValue(items)
}
const actionTypeItems = actionType.map((value) => ({ value:value.languageCode, label: t(`${value.languageCode}`) }));

const onChangeAssignedTech = (items: string[])=>{
  setAssignedTechValue(items)
}

const onChangeCallStatus = (items: string[])=>{
  setCallStatusValue(items)
}
const onChangeDatesIntervalFilter= (item: string)=>{
  setDatesIntervalFilter(item)
  
const today = new Date();
    switch (item) {
        case 'Half-year back': {
        const sixMonthsAgo = new Date(today);
        sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
        const startDate=formatDtoDate(sixMonthsAgo);
        const endDate=formatDtoDate(today );
        setEndDate(endDate )
        setStartDate(startDate)
        return {startDate, endDate}
        }
        case 'Year back': {
        const lastYear = new Date(today);
        lastYear.setFullYear(lastYear.getFullYear() - 1);
        const startDate=formatDtoDate(lastYear);
        const endDate=formatDtoDate(today );
        setEndDate(endDate )
        setStartDate(startDate)
        return {startDate, endDate}
        }
        case 'Previous year': {
          const previousYear = new Date(today.getFullYear() - 1, 0, 1);
          const previousYearEnd = new Date(today.getFullYear() - 1, 11, 31);
          const startDate=formatDtoDate(previousYear);
          const endDate=formatDtoDate(previousYearEnd);
          setEndDate(endDate )
          setStartDate(startDate)
          return {startDate, endDate}
        }
        case 'Current year': {
        const currentYear = new Date(today.getFullYear(), 0, 1);
        const startDate=formatDtoDate(currentYear);
        const endDate=formatDtoDate(today);
        setEndDate(endDate )
        setStartDate(startDate)
        return {startDate, endDate}
      }

      default:
        return {startDate, endDate}
    }

}

const assignedTechItems = assignedTech.map((value) => ({ value:value.userName, label: t(`${value.userName}`) }));

const callStatusItems = [
  {
    value: 'open calls',
    label: t('open_calls_call_status_filter_item'),
  },
  {
    value: 'closed calls',
    label: t('closed_calls_call_status_filter_item'),
  },
  {
    value: 'complex calls',
    label: t('complex_calls_call_status_filter_item'),
  },
];

const datesIntervalItems = [

  {
    value: 'Half-year back',
    label: t('Half_year_back_dates_Interval_filter_item'),
  },
  {
    value: 'Year back',
    label: t('year_back_dates_Interval_filter_item'),
  },
  {
    value: 'Previous year',
    label: t('previous_year_dates_Interval_filter_item'),
  }, 
  
  {
    value: 'Current year',
    label: t('current_year_dates_Interval_filter_item'),
  },

]
  return {

    startDate,
		endDate,
		minCallCount,
    onChangeStartDate,
		onChangeEndDate,
    onChangeMinCallCount,

    faultEquipmentValue,
		onChangeFaultEquipment,
		faultEquipmentItems,

    actionTypeValue,
		onChangeActionType,
		actionTypeItems,

    assignedTechValue,
		onChangeAssignedTech,
		assignedTechItems,

    callStatusValue,
    onChangeCallStatus,
    callStatusItems,

    datesIntervalFilter,
    onChangeDatesIntervalFilter,
    datesIntervalItems
  };
};




import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { axiosBase } from 'shared/config';
import { useAppDispatch, useAuth, useToast } from 'shared/hooks';
import { replaceSpacesWithUnderscores } from 'shared/lib/replaceSpacesWithUnderscores';
import { setProfileInfo } from 'shared/slices/profileSlice';

import localStorageService from 'shared/localStorage';
//import jwt from 'jsonwebtoken';
type LoginDTO = {
  userName: string;
  password: string;
};

export const useLogin = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { showError } = useToast();
  const { handleSaveToken } = useAuth();
  const [isLoading, setLoading] = useState(false);

  const login = async (authDto: LoginDTO) => {
    setLoading(true);

    try {
      const {
        data: { data, error },
      } = await axiosBase.post('/Auth/login', authDto);

      if (data) {
        dispatch(setProfileInfo(data));


        const token = data.token;
        try {
          const [encodedHeader, encodedPayload, signature] = token.split('.');
          const header = JSON.parse(atob(encodedHeader));
          const payload = JSON.parse(atob(encodedPayload));


          if (+payload.backOfficeAccessLevel > 0) {
              localStorageService.currentUserName=data?.name;
              localStorageService.currentUserMail=data?.email;
           
          } else {
            showError(t(`access_error_toast_text`));
          }
        } catch (error) {
          console.error('Error decoding token:', error);
        }
        handleSaveToken(data);
      } else {
        // showError(error?.message);
        showError(t(`${replaceSpacesWithUnderscores(error?.message || '')}_login_toast_text`));
      }
    } catch (error: any) {
      console.error('ERROR', error);
      showError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return { isLoading, login };
};

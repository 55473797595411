import { FC } from 'react';
import { Box, List, ListItemButton, Popover, PopoverProps, Typography } from '@mui/material';
import { MenuListItem } from '../../models';
import { isHebrewFont } from 'shared/lib';
import checkOne from '../../../shared/assets/check-one.svg';

interface Props extends PopoverProps {
  actions: MenuListItem[];
}

export const MenuList: FC<Props> = ({ open, anchorEl, onClose, actions, ...props }) => {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      {...props}
    >
      <List disablePadding>
        {actions.map(({ icon, title, onClick }, index) => (
          <ListItemButton
            onClick={onClick}
            key={title}
            disabled={!onClick}
            sx={{
              m: '5px',
              borderRadius: '10px',
              '&.Mui-disabled': {
                opacity: 1,
                color: 'black',
                display: 'flex',
                flexDirection: 'column',
              },
              '&:hover': {
                bgcolor: 'primary.light',
              },
            }}
          >
            {index === 2 && !onClick ? (
              <>
                <img src={icon} />
                <Typography
                  sx={{
                    ml: '11px',
                    fontFamily: isHebrewFont(title?.toString() ?? '') ? 'Noto Sans Hebrew' : 'Inter',
                  }}
                >
                  {title}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '3px',
                    width: '100%',
                    ml: '20px',
                  }}
                >
                  <Box component={'img'} src={checkOne} />
                  <Typography
                    sx={{
                      textAlign: 'left',
                      fontFamily: isHebrewFont(title?.toString() ?? '') ? 'Noto Sans Hebrew' : 'Inter',
                      fontWeight: 400,
                      fontSize: '10px',
                      color: '#013070',
                    }}
                  >
                    {`${process.env.REACT_APP_DEPLOY_DATE}`}
                  </Typography>
                </Box>
              </>
            ) : (
              <>
                <img src={icon} />
                <Typography
                  sx={{ ml: '11px', fontFamily: isHebrewFont(title?.toString() ?? '') ? 'Noto Sans Hebrew' : 'Inter' }}
                >
                  {title}
                </Typography>
              </>
            )}
          </ListItemButton>
        ))}
      </List>
    </Popover>
  );
};

import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { SxProps } from '@mui/system';

type Props = {
  text: string;
  sx?: SxProps;
};

export const BaseInfo: FC<Props> = ({ text, sx }) => {
  return (
    <Box
      sx={{
        border: '1px solid black',
        borderRadius: '10px',
        borderColor: 'primary.dark',
        mr: '20px',
        padding: '6px',
        ...sx,
      }}
    >
      <Typography
        noWrap
        align={'center'}
        sx={{
          fontSize: '14px',
          fontWeight: 500,
          color: 'primary.dark',
          minWidth: '60px',
          lineHeight: '15px',
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

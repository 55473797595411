

import { FC } from 'react';
import localStorageService from 'shared/localStorage';

interface CustomizedAxisYTickProps {
  x: number;
  y: number;
  payload: { value: string };
}

export const CustomizedAxisYTick: FC<CustomizedAxisYTickProps> = (props:CustomizedAxisYTickProps) => {
  const isRTL =localStorageService.isRTL;
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={-5} dy={8} fontSize='11px' textAnchor={isRTL ? 'start' : 'end'} fill="black">
          {payload.value}
      </text>
    </g>
  );
};

import { Box } from '@mui/material';
import { BaseBtn, BigTitle, Row, SearchInput } from 'shared/ui';
import { useSearchMessagesTemplates } from './hooks/useSearchMessagesTemplates';
import { t } from 'i18next';
import { Filter } from 'features/Filter';
import { useFilterMessagesTemplates } from './hooks/useFilterMessagesTemplates';
import { useEffect, useMemo, useState } from 'react';
import { useMessageTemplates } from 'features/CustomerManager/components/SendWaMessage/hooks';
import { useAppSelector } from 'shared/hooks';
import { BaseTable } from 'components';
import { TABLE_TITLES } from './constants';
import { edit, delete_icon, send_message } from 'shared/assets';
import { CreateMessageTemplate, DeleteMessageTemplate, EditMessageTemplate } from './components';
import { WATemplate } from 'features/CustomerManager/whatsAppModels';
export const MessagesTemplatesManager = () => {
  const { searchValue, changeSearch, getSearchedMessagesTemplates } = useSearchMessagesTemplates();
  const { messageTemplateLang, onChangeMessageTemplateLang, messageTemplateLangItems } = useFilterMessagesTemplates();
  const { getMessageTemplates, isLoading } = useMessageTemplates();

  const [showCreateMessageTemplateModal, setShowCreateMessageTemplateModal] = useState(false);
  const [showEditMessageTemplate, setShowEditMessageTemplateModal] = useState(false);
  const [currentMessageTemplate, setCurrentMessageTemplate] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      getMessageTemplates();
    };
    fetchData();
  }, []);
  const handleEditMessageTemplate = (value: WATemplate) => {
    setCurrentMessageTemplate(value);
    setShowEditMessageTemplateModal(true);
  };
  const closeCreateMessageTemplateModal = () => {
    setShowCreateMessageTemplateModal(false);
  };
  const openCreateMessageTemplateModal = () => {
    setShowCreateMessageTemplateModal(true);
  };
  const openEditMessageTemplateModal = (value: WATemplate) => {
    setCurrentMessageTemplate(value);
    setShowEditMessageTemplateModal(true);
  };
  const closeEditMessageTemplateModal = () => {
    setShowEditMessageTemplateModal(false);
  };
  const closeDeleteMessageModal = () => {
    setShowDeleteModal(false);
  };
  const updateMessageTemplates = () => {
    getMessageTemplates();
  };
  const handleDeleteMessageTemplate = (value: WATemplate) => {
    setCurrentMessageTemplate(value);
    setShowDeleteModal(true);
  };
  const actions = useMemo(
    () => [
      { icon: edit, title: `${t('view-messages-templates-grid-table-modal')}`, onClick: handleEditMessageTemplate },
      // { icon: send_message, title: `${t('send-messages-templates-grid-table-modal')}`, onClick: handleSendMessage },
      {
        icon: delete_icon,
        title: `${t('delete-message-templates-grid-table-modal')}`,
        onClick: handleDeleteMessageTemplate,
      },
    ],
    []
  );
  const { messageTemplatesData } = useAppSelector((st) => st.whatsApp);
  const messageTemplates = useMemo(() => {
    if (!messageTemplatesData) return [];
    const filteredmessageTemplates = messageTemplateLang
      ? messageTemplatesData.data.filter((item) => {
        return item.language.toUpperCase() === messageTemplateLang.toUpperCase();
      })
      : messageTemplatesData.data;

    const searchedMessageTemplates = getSearchedMessagesTemplates(filteredmessageTemplates, searchValue);

    return searchedMessageTemplates;
  }, [searchValue, messageTemplatesData, messageTemplateLang]);

  return (
    <>
      <Box>
        <Row sx={{ mb: '12px' }}>
          <BigTitle sx={{ mr: '25px' }}>{t('messages_templates_title')}</BigTitle>
        </Row>
        <SearchInput
          value={searchValue}
          onChange={changeSearch}
          placeholder={`${t('message_template_search_placeholder')}`}
        />
        <Row sx={{ mt: '6px' }} flexWrap="wrap" rowGap="5px" alignItems="flex-end">
          <BigTitle sx={{ fontSize: '24px', mr: '20px' }}>{t('filter_messages_templates_lang')}</BigTitle>
          <Filter
            title={`${t('lang_label')}`}
            value={messageTemplateLang}
            onChange={(value) => onChangeMessageTemplateLang(value)}
            items={messageTemplateLangItems}
            messageEmptyFilter="messages_templates_lang_display_all"
            placeholder={`${t('messages_templates_lang_placeholder')}`}
            hideResetButton
            sx={{ width: '260px' }}
          />
          <Row sx={{ flexGrow: 1 }} />
          <BaseBtn
            onClick={openCreateMessageTemplateModal}
            sx={{
              height: '29px',
            }}
          >
            {t('create_template')}
          </BaseBtn>
        </Row>
        <BaseTable
          tableTitles={TABLE_TITLES}
          tableItems={messageTemplatesData ? messageTemplates : []}
          loadedItems={!!messageTemplatesData}
          selectedRowID={currentMessageTemplate?.id}
          idField={'id'}
          noItemsText={t('no_messages_templates_grid_table')}
          headerItemsPrefix={'_messages_templates_grid_table'}
          linkIndex={[0]}
          cellClick={openEditMessageTemplateModal}
          actions={actions}
          sortModel={[{ field: 'objectName', sort: 'asc' }]}
        //onRowSelectionModelChange={rowSelectionModelChange}
        // hideSelectAll={isNaN(parseInt(customerFilter)) || customerFilter === '87'}
        />
        {/* {messageTemplatesData.toString()} */}
      </Box>
      <CreateMessageTemplate
        onCloseModal={closeCreateMessageTemplateModal}
        openCustomerAddModal={showCreateMessageTemplateModal}
        updateMessageTemplates={updateMessageTemplates}
      />
      <EditMessageTemplate
        onCloseModal={closeEditMessageTemplateModal}
        openCustomerAddModal={showEditMessageTemplate}
        currentMessageTemplate={currentMessageTemplate}
      />
      <DeleteMessageTemplate
        openDeleteModal={showDeleteModal}
        onCloseModal={closeDeleteMessageModal}
        currentMessageTemplate={currentMessageTemplate}
        updateMessageTemplates={updateMessageTemplates}
      />
    </>
  );
};

import ClickAwayListener from '@mui/base/ClickAwayListener';
import { PlayArrow } from '@mui/icons-material';
import {
  Box,
  FormControl,
  FormControlLabel,
  IconButton,
  IconButtonProps,
  Radio,
  RadioGroup,
  useTheme,
} from '@mui/material';
import { SxProps } from '@mui/system';
import { Customer } from 'features/CustomerManager/customerModels';
import { useGetCustomers } from 'features/CustomerManager/hooks';
import List from 'rc-virtual-list';
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { clear_filter, home } from 'shared/assets';
import { useAnchorEl, useAppSelector, useExtendedTranslation } from '../../shared/hooks';
import { BaseBtn, Icon, Row, SearchInput, Text } from '../../shared/ui';
import { CustomFormControlLabel } from '../../shared/ui/CustomFormControlLabel';
interface ResetButtonProps extends IconButtonProps {
  showHomeButton?: boolean;
}

const ResetButton: FC<ResetButtonProps> = ({ onClick, sx, showHomeButton }) => (
  <IconButton
    onClick={onClick}
    sx={{
      position: 'absolute',
      zIndex: 2,
      height: '29px',
      width: '33px',
      ...sx,
    }}
  >
    <Icon src={showHomeButton ? home : clear_filter} />
  </IconButton>
);

interface Props {
  title: string;
  value?: string;
  onChange?: (value: string) => void;
  withSearch?: boolean;
  withConfirm?: boolean;
  hideAllButton?: boolean;
  showHomeButton?: boolean;
  defaultValue?: string;
  sx?: SxProps;
  showOnlyResetButton?: boolean;
  noSelectLabel?: string;
  formInput?: boolean;
  disabled?: boolean;
  hideValue?: boolean;
  hideResetButton?: boolean;
  controlsWidth?: number;
  withoutMargin?: boolean;
}

export const CustomerSelect: FC<Props> = ({
  sx,
  title,
  formInput,
  value,
  onChange,
  withSearch,
  hideAllButton,
  showHomeButton,
  defaultValue,
  showOnlyResetButton,
  noSelectLabel,
  disabled,
  hideValue,
  hideResetButton,
  controlsWidth,
  withoutMargin,
}) => {
  const { t } = useExtendedTranslation();
  const { isOpen, anchorEl, onOpenMenu, onCloseMenu } = useAnchorEl();
  const [innerValue, setInnerValue] = useState<string>('');
  const [valueItems, setValueItems] = useState<Customer[]>();
  const [filterItems, setfilterItems] = useState<Customer[]>();
  const { getCustomer } = useGetCustomers();
  const { customers } = useAppSelector((st) => st.customers);
  const handleChange = (value: string) => {
    onChange?.(value);
    onCloseMenu();
  };
  const { palette } = useTheme();

  useEffect(() => {
    if (customers && customers.length) {
      setValueItems([...customers]);
      return;
    }
    getCustomer();
  }, [customers]);

  useEffect(() => {
    setInnerValue(value);
  }, [value]);

  const currentFilter = useMemo(
    () => valueItems && valueItems.find((item) => item.id.toString() === value),
    [value, valueItems]
  );
  const [search, setSearch] = useState('');
  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

  useEffect(() => {
    if (valueItems && valueItems.length) {
      setfilterItems(
        valueItems.filter((i) => {
          const findByLabel = i.customerName.toLowerCase().includes(search.toLowerCase().trim());
          const findByValue = i.id.toString().toLowerCase().includes(search.toLowerCase().trim());
          return findByLabel || findByValue;
        })
      );
    }
  }, [search, valueItems]);

  const handleClose = () => {
    onCloseMenu();
  };
  const toggleMenu = (e) => {
    isOpen ? handleClose() : onOpenMenu(e);
  };
  const clearFilterValue = () => onChange?.('');

  const homeButtonClick = () => {
    if (defaultValue) {
      handleChange(defaultValue);
    }
  };
  const isShowResetButton = () => {
    if (hideResetButton) return;
    return value && !isNaN(parseInt(value)) && (value === '87' || showOnlyResetButton);
  };
  const isShowHomeButton = () => {
    return value && !isNaN(parseInt(value)) && value !== '87' && !showOnlyResetButton;
  };
  return (
    <>
      {valueItems && valueItems.length ? (
        <Box sx={{ mr: withoutMargin ? '' : '20px', ...sx }}>
          {formInput ? (
            <Text
              noWrap
              sx={{
                fontSize: '14px',
                fontWeight: 600,
                textTransform: 'capitalize',
                color: palette.primary.dark,
                marginBottom: '3px',
                marginTop: '3px',
              }}
            >
              {title}
            </Text>
          ) : (
            <Text noWrap sx={{ color: 'info.dark', fontSize: '12px', fontWeight: 500, pr: '2px' }}>
              {title}
            </Text>
          )}

          <Row
            position="relative"
            sx={{
              justifyContent: value !== '' && value && !isNaN(parseInt(value)) ? 'space-between' : '',
            }}
          >
            {/* showHomeButton */}
            {showHomeButton && <>{isShowHomeButton() && <ResetButton showHomeButton onClick={homeButtonClick} />}</>}
            {<>{isShowResetButton() ? <ResetButton onClick={clearFilterValue} /> : null}</>}

            <BaseBtn
              btnColor="info.main"
              disabled={disabled}
              onClick={toggleMenu}
              sx={{
                borderColor: formInput ? palette.info.dark : 'primary.dark',
                opacity: '1 !important',
                height: '29px',
                minWidth: '100%',
                px: 0,
                bgcolor: disabled ? 'info.contrastText' : 'info.main',
              }}
            >
              <Text
                noWrap
                sx={{
                  color: 'primary.dark',
                  fontSize: '14px',
                  fontWeight: 500,
                  width: 'calc(100% - 40px)',
                  pl:
                    showHomeButton && !hideResetButton
                      ? value !== '' && customers.length && value && !isNaN(parseInt(value))
                        ? '35px'
                        : '10px'
                      : '5px',
                  textAlign: 'left',
                  fontFamily: 'Noto Sans Hebrew !@important',
                }}
              >
                {currentFilter?.id ? (
                  <>
                    {!hideValue && (
                      <>
                        <span> {currentFilter?.customerName}</span>
                        {` - `}
                      </>
                    )}
                    <span>{currentFilter?.id}</span>
                  </>
                ) : (
                  <Box component="span" sx={{ color: '#d3d3d3 !important' }}>
                    {noSelectLabel ? noSelectLabel : t('display_all_filter_base_select')}
                  </Box>
                )}
              </Text>

              <PlayArrow
                sx={{
                  m: '7px',
                  fontSize: '14px',
                  color: formInput ? palette.info.dark : 'primary.dark',
                  transform: `rotate(${isOpen ? '-90deg' : '90deg'})`,
                }}
              />
            </BaseBtn>
          </Row>

          {isOpen && (
            <ClickAwayListener onClickAway={handleClose}>
              <Box
                sx={{
                  position: 'absolute',
                  zIndex: '20',
                  background: 'white',
                  width: '300px',
                  borderTop: '1px solid grey',
                  borderColor: 'rgba(255, 255, 255, 1)',
                  borderRadius: '10px',
                  boxShadow:
                    '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
                  mt: '5px',
                  '& .MuiPaper-root': {
                    borderRadius: '12px',
                  },
                }}
              >
                <Box>
                  {withSearch && (
                    <SearchInput
                      onfocus
                      value={search}
                      onChange={handleChangeSearch}
                      placeholder={`${t('filter_search_placeholder')}`}
                      noBorder
                    />
                  )}

                  <Box
                    sx={{
                      borderTop: '1px solid grey',
                      borderColor: withSearch ? 'info.dark' : 'info.main',
                    }}
                  >
                    {filterItems.length === 0 ? (
                      <Text sx={{ color: 'primary.main', textAlign: 'center', my: '10px' }}>
                        {t('filter_no_items')}
                      </Text>
                    ) : (
                      <FormControl sx={{ width: controlsWidth ? `${controlsWidth}px` : '100%' }}>
                        <RadioGroup value={value} sx={{ width: '100%' }}>
                          {!hideAllButton && (
                            <Box
                              sx={{
                                borderBottom: '1px solid black',
                                borderColor: 'primary.light',
                                px: '8px',
                                mb: '2px',
                              }}
                            >
                              <Box
                                onClick={() => handleChange('')}
                                sx={{
                                  borderRadius: '10px',
                                  cursor: 'pointer',
                                  '&:hover': { bgcolor: 'primary.light' },
                                }}
                              >
                                <FormControlLabel
                                  control={<Radio checked={value === ''} size="small" />}
                                  label={
                                    <Text sx={{ color: 'black', pr: '10px' }}>
                                      {noSelectLabel ? noSelectLabel : t('display_all_filter_base_select')}
                                    </Text>
                                  }
                                  sx={{
                                    fontSize: '11px',
                                    mx: 0,
                                  }}
                                />
                              </Box>
                            </Box>
                          )}

                          <>
                            <Box
                              sx={{
                                maxHeight: '500px',
                              }}
                            >
                              {defaultValue && (
                                <CustomFormControlLabel
                                  sx={{
                                    color: 'primary.dark',
                                    fontWeight: '700',
                                  }}
                                  key={value}
                                  onChange={() => handleChange(defaultValue)}
                                  value={defaultValue}
                                  selected
                                  labels={{
                                    value: defaultValue,
                                    label:
                                      customers.find((item) => item.id.toString() === defaultValue)?.customerName || '',
                                  }}
                                />
                              )}
                              <List data={filterItems} height={401} itemHeight={33} itemKey="id">
                                {(item) => (
                                  <CustomFormControlLabel
                                    hideValue={hideValue}
                                    onChange={() => handleChange(item.id.toString())}
                                    value={item.id.toString()}
                                    labels={{ value: item.id.toString(), label: item.customerName }}
                                  />
                                )}
                              </List>
                            </Box>
                          </>
                        </RadioGroup>
                      </FormControl>
                    )}
                  </Box>
                </Box>
              </Box>
            </ClickAwayListener>
          )}
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

import { ICItem } from 'shared/models';

interface CustomICItem extends ICItem {
  options: string | null;
}
export type TableTitle = { title: string; key: keyof CustomICItem; width: number | string };

export const TABLE_TITLES: TableTitle[] = [
  { title: '', key: 'options', width: '50%' },
  { title: 'index', key: 'srcIndex', width: '50%' },
  { title: 'controller_id', key: 'srcObjectID', width: '100%' },
  { title: 'controller_name', key: 'srcObjectName', width: '150%' },
  { title: 'model_id', key: 'srcModelID', width: '100%' },
  { title: 'hwid', key: 'srcHWID', width: '100%' },
  { title: 'version_number', key: 'srcVersion', width: '100%' },
	{ title: 'relation_type', key: 'script_Type', width: '100%' },
  
];

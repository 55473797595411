import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  BatteryStatusItem,
  BatteryStatusExclude,
  BatteryStatusExcludeFilter,
  BatteryStatusReportProject,
  BatteryStatusReportProjectFilter,
} from 'shared/models';

interface BatteryStatusItemState {
  items: BatteryStatusItem[];
  excludeControllers: BatteryStatusExclude[];
  excludeProjects: BatteryStatusExclude[];
  filter: BatteryStatusExcludeFilter;
  isBatteryStatusItemsLoading: boolean;
  batteryStatusReportProject: BatteryStatusReportProject;
  filterGraph: BatteryStatusReportProjectFilter;
}

const initialState: BatteryStatusItemState = {
  items: [],
  excludeControllers: [],
  excludeProjects: [],
  isBatteryStatusItemsLoading: false,
  batteryStatusReportProject: {
    deviceId: 0,
    deviceName: '',
    projectId: 0,
    projectName: '',
    eventId: 0,
    eventDate: '',
    dataIndex: 0,
    dataSource: 0,
    dataValue: 0,
  },
  filter: {
    startDate: '2023-02-02',
    endDate: '2023-02-03',
    dayTimeStart: '13:00',
    dayTimeEnd: '17:00',
    nightTimeStart: '01:00',
    nightTimeEnd: '05:00',
    connectHMin: '5',
    daysPositiveP: '100',
  },
  filterGraph: {
    fromDate: '',
    toDate: '',
    deviceId: 0,
  },
};

const batteryStatusReportSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    setBatteryStatusItems: (state, action: PayloadAction<BatteryStatusItem[]>) => {
      state.items = action.payload;
    },
    setBatteryStatusFilter: (state, action: PayloadAction<BatteryStatusExcludeFilter>) => {
      state.filter = action.payload;
    },
    setBatteryStatusExcludeControllers: (state, action: PayloadAction<BatteryStatusExclude[]>) => {
      state.excludeControllers = action.payload;
    },
    setBatteryStatusExcludeProjects: (state, action: PayloadAction<BatteryStatusExclude[]>) => {
      state.excludeProjects = action.payload;
    },
    setBatteryStatusItemsLoading: (state, action: PayloadAction<boolean>) => {
      state.isBatteryStatusItemsLoading = action.payload;
    },
    setBatteryStatusReportProject: (state, action: PayloadAction<BatteryStatusReportProject>) => {
      state.batteryStatusReportProject = action.payload;
    },
    setCurrentBatteryStatusReportProjectID: (state, action: PayloadAction<number>) => {
      state.filterGraph.deviceId = action.payload;
      //state.filterGraph.deviceId = 17341;push
     //  state.filterGraph.deviceId = 17341;
      //state.filterGraph.deviceId = 10421;
    },
    setCurrentBatteryStatusReportProjectDates: (state, action: PayloadAction<BatteryStatusReportProjectFilter>) => {
      state.filterGraph.fromDate = action.payload.fromDate;
      state.filterGraph.toDate = action.payload.toDate;
    },
  },
});

export const {
  setBatteryStatusItems,
  setBatteryStatusExcludeControllers,
  setBatteryStatusExcludeProjects,
  setBatteryStatusFilter,
  setBatteryStatusItemsLoading,
  setBatteryStatusReportProject,
  setCurrentBatteryStatusReportProjectID,
  setCurrentBatteryStatusReportProjectDates,
} = batteryStatusReportSlice.actions;

export const batteryStatusReportReducer = batteryStatusReportSlice.reducer;



import { TableTitle, TranslateColumn} from 'components/BaseTable';
import { WAMessageStatus } from '../WAMessageStatusModels';
import dayjs from 'dayjs';

export const TABLE_TITLES: TableTitle< WAMessageStatus>[] = [
  { title: 'created_at', key: 'createdAt', width: '100%', type: 'date',},
//   { title: 'customer_Name', key: 'customerName', width: 160 },
  { title: 'message_body', key: 'messageBody', width: '300%' },
  { title: 'status', key: 'status', width: '75%' },
  { title: 'status_date', key: 'statusDate', width: '100%', type: 'date' },
  { title: 'customer_id', key: 'customerID', width: '50%' },
  { title: 'customer_name', key: 'customerName', width: '100%' },
  { title: 'to_phone', key: 'toPhone', width: '100%' },
  { title: 'error_message', key: 'errorMessage', width: '200%' },
];
export const TRANSLATE_COLUMNS: TranslateColumn<WAMessageStatus>[]=[
    {  key: 'status', additionalKey:'_message_status_item' },
    {  key: 'toPhone', preKey:"+" },
]



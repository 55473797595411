import { t } from 'i18next';
import {  useEffect, useState } from 'react';
import { useAppSelector } from 'shared/hooks';
import { Component } from 'features/CustomerManager/whatsAppModels';
import { getParamsMessage } from '../../WaMessageHelpers';
import localStorageService from 'shared/localStorage';
  

export interface Params {
  number:string;
  type:string;
  value: string;
  key: string;
}

export const useWaMessageSettings = () => {

  
  interface MessageLanguages {
      value: string,
      label: string,
  }



  const { messageTemplatesData } = useAppSelector((st) => st.whatsApp);


  const languages = [{
    value:'he',
    label: 'HE',
  },
  {
    value:'en',
    label: 'EN',
  },

]
  const { currentLang } = localStorageService;
  const [selectedMessageTemplate, setSelectedMessageTemplate] = useState<string>('');
  const [selectedMessageLanguage, setSelectedMessageLanguage] = useState<string>(currentLang);
  const [messageLanguages, setMessageLanguages] = useState<MessageLanguages[]>(languages);
  const [ currentTemplate, setCurrentTemplate] = useState<Component[]>(null);
  const [ messageTemplates, setMessageTemplates] = useState<MessageLanguages[]>([]);
  let uniqueNames =[]
  const getMessageTemplates=(selectedMessageLanguage:string)=>{
    if(selectedMessageLanguage){
      uniqueNames = messageTemplatesData?.data.filter((s) => s.language===selectedMessageLanguage).map((s) => ({name:s.name, id:s.id}));
     }else{
       uniqueNames = messageTemplatesData?.data.map((s) => ({name:s.name, id:s.id}));
     }
      const messageTemplates=[]
      if(uniqueNames){
      uniqueNames.forEach((s) => (messageTemplates.push({
        value: s.id,
        label: s.name,
      })));
      }
      setMessageTemplates(messageTemplates)
  }



  const onChangeMessageTemplate = (value: string) => {
    // setSelectedMessageLanguage('')
    // setMessageLanguages([])
    setCurrentTemplate(null)
    setParamsMessage([])
    
    setSelectedMessageTemplate(value);
    const selectedMessageTemplates= messageTemplatesData?.data.filter(item=>item.id=== value)

    //setMessageLanguages(languages)

    const currentLang = localStorageService.currentLang
    const filteredLanguages = languages.filter(language => {
      const currentLangLower = currentLang.toLowerCase();
      const labelLower = language.label.toLowerCase();
      return labelLower.includes(currentLangLower);
  });
  const filteredLabels = filteredLanguages.map(language => language.value);
      if (filteredLabels[0]){
      
       // onChangeMessageLanguage(filteredLabels[0])
      //  setSelectedMessageLanguage(filteredLabels[0]);
       // setTimeout(()=>{onChangeMessageLanguage(filteredLabels[0])},0)
    //    setSelectedMessageLanguage(filteredLabels[0]);
    //    const selectedTemplate= messageTemplatesData?.data.find(item=>item.id=== value)
     
    // setParamsMessage(getParamsMessage(selectedTemplate.components))
      }
      if(selectedMessageTemplates && selectedMessageTemplates.length){
        setCurrentTemplate(selectedMessageTemplates[0].components);
        setParamsMessage(getParamsMessage(selectedMessageTemplates[0].components))
      }
  }
  const onChangeMessageLanguage = (value: string) =>{

    setSelectedMessageLanguage(value);
    // const selectedTemplate= messageTemplatesData?.data.find(item=>item.id=== value)
    setCurrentTemplate(null);
    setSelectedMessageTemplate(null);
    getMessageTemplates(value)
    setParamsMessage([])
  }

  const [paramsMessage, setParamsMessage] = useState<Params[]>([]);
  const onChangeParamsMessage = (key: string, value: string) => {
        const index = paramsMessage.findIndex(item=>item.key===key)
        setParamsMessage([...paramsMessage.slice(0, index), { ...paramsMessage[index], value }, ...paramsMessage.slice(index + 1)])
    
}


  return {
    selectedMessageTemplate,
    onChangeMessageTemplate,
    messageTemplates,

    selectedMessageLanguage,
    onChangeMessageLanguage,
    messageLanguages,

    paramsMessage,
    onChangeParamsMessage,

    currentTemplate
  };
};

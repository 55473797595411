import { Box } from '@mui/material';
import { root_point_icon } from 'shared/assets';
import {  Row,  } from 'shared/ui';
import { Icon } from 'shared/ui';
type Marker = {
  lat: number;
  lng: number;
  text: string;
};

export const RootPoint = (marker: Marker) => (
	<Row sx={{width: '300px'}} >
	<Icon src={root_point_icon} 
		sx={{
			display: 'inline-block',
			backgroundColor: 'white',
			width: '20px',
			height: '20px',
			marginLeft: '-10px',
			marginTop: '-10px',
			borderRadius: '50%',
		}}/>
	{marker.text}</Row>
);
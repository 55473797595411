import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { axiosBase } from 'shared/config';
import { useAppDispatch, useToast, useTokenInfo } from 'shared/hooks';
import {
  setServiceCalls,
  setCallsFaultEquipmentCodes,
  setCallsTechnicianCodes,
  setCallsStatusCodes,
  toggleEditServiceCall,
  toggleAddServiceCall,
} from 'shared/slices';
import { useAppSelector } from 'shared/hooks';
import { ServiceCall } from 'shared/models';
export const useServiceCallsManagement = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { showError, showSuccess } = useToast();
  const push = useNavigate();
  const { userId } = useTokenInfo();
  const [isLoading, setLoading] = React.useState(false);
  // const [isLoadingGraphBatteryStatusReport, setGraphLoading] = React.useState(false);
  const { filterGraph } = useAppSelector((st) => st.batteryStatusReport);
  const getServiceCalls = async (controllerID:number) => {
    if (!filterGraph) return;
    setLoading(true);
  
    const controllerId=controllerID?controllerID: filterGraph.deviceId
    try {
      const { data } = await axiosBase.get(`/servicecalls/controllerId=${controllerId}`);
      dispatch(setServiceCalls(data.data));
    } catch (error: any) {
      console.log('#ERROR', error);
      if (error?.response?.status === 401) {
        push('/login');
      } else {
        showError(t('something_went_wrong_toast_text'));
        
      }
    } finally {
      setLoading(false);
    }
  };
  const getServiceCallsFaultEquipmentCodes = async () => {
    if (!filterGraph) return;
    setLoading((pref) => true);
    try {
      const { data } = await axiosBase.get(`/servicecalls/faultequipment`);
      const sortedEquipmentCodes = data.data.sort((a: { languageCode: string }, b: { languageCode: string }) =>
        a.languageCode.localeCompare(b.languageCode)
      );
      dispatch(setCallsFaultEquipmentCodes(sortedEquipmentCodes));
    } catch (error: any) {
      console.log('#ERROR', error?.response?.status);
      if (error?.response?.status === 401) {
        push('/login');
      } else {
        showError(t('something_went_wrong_toast_text'));
      }
    } finally {
      setLoading(false);
    }
  };
  const getServiceCallsTechnicianCodes = async () => {
    if (!filterGraph) return;
    setLoading((pref) => true);
    try {
      const { data } = await axiosBase.get(`/servicecalls/techusers`);
      const sortedTechnicianCodes = data.data.sort((a: { userName: string }, b: { userName: string }) =>
        a.userName.localeCompare(b.userName)
      );
      dispatch(setCallsTechnicianCodes(sortedTechnicianCodes));
    } catch (error: any) {
      console.log('#ERROR', error?.response?.status);
      if (error?.response?.status === 401) {
        push('/login');
      } else {
        showError(t('something_went_wrong_toast_text'));
      }
    } finally {
      setLoading(false);
    }
  };
  const getServiceCallsStatusCodes = async () => {
    if (!filterGraph) return;
    setLoading((pref) => true);
    try {
      const { data } = await axiosBase.get(`/servicecalls/faultstatus`);
      dispatch(setCallsStatusCodes(data.data));
    } catch (error: any) {
      console.log('#ERROR', error?.response?.status);
      if (error?.response?.status === 401) {
        push('/login');
      } else {
        showError(t('something_went_wrong_toast_text'));
      }
    } finally {
      setLoading(false);
    }
  };
  const editServiceCall = async (values: ServiceCall) => {
    if (!filterGraph) return;
    setLoading((pref) => true);
    try {
      const serviceCall: any = values;
      serviceCall.id = serviceCall.serviceCallID;
      serviceCall.faultEquipment = serviceCall.faultEquipmentCode;
      serviceCall.remarksToTech = '1';
      const { data } = await axiosBase.put(`/servicecalls`, serviceCall);
      dispatch(toggleEditServiceCall());
      getServiceCalls(values.controllerID);
      showSuccess(t('service_call_successfully_updated_toast_text'));
      //dispatch(setCallsStatusCodes(data.data));
    } catch (error: any) {
      console.log('#ERROR', error?.response?.status);
      if (error?.response?.status === 401) {
        push('/login');
      } else {
        showError(t('something_went_wrong_toast_text'));
      }
    } finally {
      setLoading(false);
    }
  };
  const useAddServiceCall = async (values: ServiceCall) => {
    setLoading((pref) => true);
    const {
      currentStatusCode,
      createDate,
      openByUserName,
      customerID,
      customerName,
      controllerID,
      controllerName,
      faultEquipment,
      faultEquipmentCode,
      faultDescription,
      assignedTechnicianID,
      notesForTechnician,
      takenAction,
      replacedEquipment1,
      replacedEquipment2,
      actionDescription,
    } = values;
    const serviceCall = {
      currentStatusCode,
      callPriority: '1',
      customerID: userId,
      controllerID,
      faultEquipment: faultEquipmentCode,
      faultDescription,
      assignedTechnicianID,
      notesForTechnician: notesForTechnician,
    };
    try {
      const { data } = await axiosBase.post('/servicecalls', serviceCall);
      dispatch(toggleAddServiceCall());
      getServiceCalls(controllerID);
      showSuccess(t('service_call_successfully_created_toast_text'));
    } catch (error: any) {
      console.log('#ERROR', error?.response?.status);
      if (error?.response?.status === 401) {
        push('/login');
      } else {
        showError(t('something_went_wrong_toast_text'));
      }
    } finally {
      setLoading(false);
    }
  };
  return {
    getServiceCalls,
    getServiceCallsFaultEquipmentCodes,
    getServiceCallsTechnicianCodes,
    getServiceCallsStatusCodes,
    useAddServiceCall,
    editServiceCall,
    isLoading,
  };
};

import { SimManager } from 'features';
import { Layout } from 'shared/ui';
import { Header } from 'widgets';
import { EditSim, ImportSim, NewProvider } from 'widgets';
export const SimManagementPage = () => {
  return (
    <Layout>
      <Header />
      <Layout.Body>
        <SimManager />
        <EditSim />
        <ImportSim />
        <NewProvider />
      </Layout.Body>
    </Layout>
  );
};

import { TableTitle, TranslateColumn } from 'components/BaseTable';
import { Sim } from '../simModels';




export const SIM_TABLE_TITLES: TableTitle<Sim>[] = [
	{ title: 'id', key: 'id', width: 70 },
	{ title: 'simNumber', key: 'simNumber', width: '200%' },
	{ title: 'phoneNumber', key: 'phoneNumber', width: '100%' },
	{ title: 'simProvider', key: 'simProvider', width: '100%' },
	{ title: 'statusCode', key: 'statusCode', width: '100%' },
	{ title: 'lastDeviceId', key: 'lastDeviceId', width: '100%' },
	{ title: 'deviceName', key: 'deviceName', width: '100%' },
	{ title: 'customerName', key: 'customerName', width: '150%' },
	{ title: 'customerId', key: 'customerId', width: '100%' },
	{ title: 'activationDate', key: 'activationDate', width: '100%', type: 'date' },
	{ title: 'deActivationDate', key: 'deActivationDate', width: '100%', type: 'date' },
	{ title: 'lastCommunicationDate', key: 'lastCommunicationDate', width: '100%', type: 'date' },
	{ title: 'lastUpdateDate', key: 'lastUpdateDate', width: '100%', type: 'date' },
	{ title: 'notes', key: 'notes', width: '150%' },
];

export const TRANSLATE_COLUMNS: TranslateColumn<Sim>[] = [
	{ key: 'statusCode', additionalKey: '' },

]

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { axiosBase } from 'shared/config';
import { useAppDispatch, useToast } from 'shared/hooks';
import { Customer } from 'shared/models';
import { editCurrentCustomer, toggleEditCustomer } from 'shared/slices';

export const useEditCustomer = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { showError, showSuccess } = useToast();
  const push = useNavigate();

  const [isLoading, setLoading] = useState(false);

  const editCustomer = async (customerDto: Customer) => {
    setLoading(true);

    try {
      const { data } = await axiosBase.put('/Customer', customerDto);
      const customer: Customer = data?.data;
      dispatch(editCurrentCustomer(customer));
      showSuccess(t('customer_successfully_updated_toast_text'));
      return true;
    } catch (error: any) {
      console.log('#ERROR', error);
      if (error?.response?.status === 401) {
        push('/login');
      } else {
        showError(t('error_toast_text'));
      }
      return false;
    } finally {
      setLoading(false);
    }
  };

  return { isLoading, editCustomer };
};

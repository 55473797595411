import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { axiosBase } from 'shared/config';
import { useAppDispatch, useToast } from 'shared/hooks';
import { setCustomerTypes } from 'shared/slices';
export const useGetCustomerTypes = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { showError } = useToast();
  const push = useNavigate();

  const [isLoadingTypes, setLoading] = React.useState(false);

  const getCustomerTypes = async () => {
    setLoading(true);

    try {
      const { data } = await axiosBase.get('/Customer/CustomerTypes');

      const updatedArray = data?.data.map((obj: { id: number; name: string; description: string }) => {
        return {
          customerTypeID: obj.id,
          customerType: obj.name,
          customerTypeDescription: obj.description,
        };
      });
      dispatch(setCustomerTypes(updatedArray));
    } catch (error: any) {
      console.log('#ERROR', error);
      if (error?.response?.status === 401) {
        push('/login');
      } else {
        showError(t('something_went_wrong_toast_text'));
      }
    } finally {
      setLoading(false);
    }
  };

  return {
    getCustomerTypes,
    isLoadingTypes,
  };
};

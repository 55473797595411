import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ICProject, ICItem, NodesPoints, NodesConnections, NodesLines, NodesEndPoints, NPScript, NPSortIndex, ScriptParam} from 'shared/models';
interface ModalState {
	iCProjects: ICProject[],
	iCItems:ICItem[],
  currentNPController:ICItem,
  nodesPoints:NodesPoints[],
  nodesConnections:NodesConnections[],
  nodesLines:NodesLines[],
  nodesEndPoints: NodesEndPoints[],
  currentNPControllerId:string,
  NPScripts: NPScript[],
  NPSortIndexes: NPSortIndex[],
  NPControllerList:ICItem[],
  NPDefaultSortIndex: string,
  currentConnectedController: ICItem,
  scriptParams: ScriptParam[]
}

const initialState: ModalState = {
  iCProjects: [],
	iCItems:[],
  currentNPController:null,
  nodesPoints:[],
  nodesConnections:[],
  nodesLines:[],
  nodesEndPoints:[],
  currentNPControllerId:'',
  NPScripts: [],
  NPSortIndexes: [],
  NPControllerList:[],
  NPDefaultSortIndex:'',
  currentConnectedController:null,
  scriptParams: []
}

const networkProtectionManagementSlice = createSlice({
  name: 'IC',
  initialState,
  reducers: {
    setICProjects: (state, action: PayloadAction<ICProject[]>) => {
      state.iCProjects = action.payload;
    },
		setICResults: (state, action: PayloadAction<ICItem[]>) => {
			state.iCItems = action.payload;
		},
    setCurrentNPController: (state, action: PayloadAction<ICItem>)=>{
      state.currentNPController = action.payload;
    },
    setNodesPoints:(state, action: PayloadAction<NodesPoints[]>)=>{
      state.nodesPoints = action.payload;
    },
    setNodesConnections:(state, action: PayloadAction<NodesConnections[]>)=>{
      state.nodesConnections = action.payload;
    },
    setNodesLines:(state, action: PayloadAction<NodesLines[]>)=>{
      state.nodesLines = action.payload;
    },
    setNodesEndPoints:(state, action: PayloadAction<NodesEndPoints []>)=>{
      state.nodesEndPoints = action.payload;
    },
    setCurrentNPControllerId:(state, action: PayloadAction<string>)=>{
      state.currentNPControllerId = action.payload;
    },
    setNPScripts:(state, action: PayloadAction<NPScript[]>)=>{
      state.NPScripts = action.payload;
    },
    setNPSortIndex:(state, action: PayloadAction<NPSortIndex[]>)=>{
      state.NPSortIndexes = action.payload;
    },
    setNPControllerList:(state, action: PayloadAction<ICItem[]>)=>{
      state.NPControllerList = action.payload;
    },
    setNPDefaultSortIndex:(state, action: PayloadAction<string>)=>{
      state.NPDefaultSortIndex = action.payload;
    },
    setCurrentConnectedController:(state, action: PayloadAction<ICItem>)=>{
      state.currentConnectedController = action.payload;
    },
    setScriptParameters:(state, action: PayloadAction<ScriptParam[]>)=>{
      state.scriptParams = action.payload;
    }
  },
});

export const {
  setICProjects,
	setICResults,
  setNodesPoints,
  setNodesConnections,
  setCurrentNPController,
  setNodesLines,
  setNodesEndPoints,
  setNPScripts,
  setNPSortIndex,
  setNPControllerList,
  setCurrentNPControllerId,
  setNPDefaultSortIndex,
  setCurrentConnectedController,
  setScriptParameters
} = networkProtectionManagementSlice.actions;

export const networkProtectionReducer = networkProtectionManagementSlice.reducer;

export const inputStyles = {
  my: '20px',
  fontFamily: 'Noto Sans Hebrew',

  '& .MuiFormHelperText-root': {
    lineHeight: 1,
    position: 'absolute',
    bottom: '-20px',
    color: 'error.main',
    fontSize: '16px',
    fontWeight: 500,
    fontFamily: 'Noto Sans Hebrew',
  },
  input: {
    fontFamily: 'Noto Sans Hebrew',
    color: 'primary.dark',
    fontSize: '21px',
    lineHeight: '10px',
    border: 'none',
    '&::placeholder': {
      opacity: 1,
      color: 'info.dark',
      lineHeight: '16px',
    },
  },
  fieldset: {
    border: 'none !important',
  },
};

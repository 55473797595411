import { Dialog, DialogContent, DialogProps, useTheme } from '@mui/material';
import { FC } from 'react';

interface CustomDialogProps extends DialogProps {
  width?: string;
  height?: string;
  minHeight?: string;
  maxHeight?: string;
  padding?: string;
  onClose?: (e: React.MouseEvent<HTMLElement>) => void;
}

export const Modal: FC<CustomDialogProps> = ({ open, onClose, children, width, height, maxHeight, padding, minHeight, ...props }) => {
  const { palette } = useTheme();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      PaperProps={{
        sx: {
          maxWidth: width ? width : '1050px',
          background: 'info.main',
          borderRadius: '12px',
          p: padding ? padding : '35px',
          paddingRight: '30px',
          maxHeight: maxHeight ? maxHeight : 'auto',
          height: height ? height : 'auto',
          minHeight: minHeight ? minHeight : 'auto',
        },
      }}
      {...props}
    >
      <DialogContent
        sx={{
          p: 0,
          overflowX: 'auto',
          backgroundColor: 'info.main',
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: palette.primary.dark,
            borderRadius: '30px',
          },
          '&::-webkit-scrollbar': {
            height: '7px',
            width: '7px',
            backgroundColor: palette.info.main,
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: palette.info.main,
            m: '10px',
            borderRadius: '30px',
          },
        }}
      >
        {children}
      </DialogContent>
    </Dialog>
  );
};

import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import { BaseMultiselect, BaseTable } from 'components';
import { BaseDataPicker } from 'features';
import { Filter } from 'features/Filter';
import { FOTA_FILES_TABLE_TITLES } from 'features/FotaManager/constants';
import { FotaFile } from 'features/FotaManager/fotaModels';
import { t } from 'i18next';
import { FC, useEffect, useMemo, useState } from 'react';
import { arrow_right } from 'shared/assets';
import { useAppSelector } from 'shared/hooks';
import { BaseBtn, BigTitle, Icon, Row, SearchInput } from 'shared/ui';
import { useFilterFota } from './../../hooks/useFilterFota';
import { FileInfo } from '../FileInfo';
import localStorageService from 'shared/localStorage';
type Props = {
  searchValue: string;
  changeSearch: (value: string) => void;
  sx?: SxProps;
  onSelectFilter: () => void;
  setLoading: (value: boolean) => void;
  fotaChanged: boolean;
  fotaUpdated: () => void;
};

export const TableFotaHeader: FC<Props> = ({
  searchValue,
  changeSearch,
  sx,
  onSelectFilter,
  setLoading,
  fotaChanged,
  fotaUpdated,
}) => {
  const {
    customerIDs,
    onChangeCustomerFilter,
    customerFilterItems,
    modelFilterItems,
    modelFilter,
    onChangeModelFilter,
    fotaStatus,
    onChangeFotaStatus,
    fotaStatusItems,
    selectedFotaTypes,
    onChangeFotaTypes,
    fotaTypesItems,
    startLastRequestDate,
    onChangeStartLastRequestDate,
    endLastRequestDate,
    onChangeEndLastRequestDate,
    updateFota,
    loading,
  } = useFilterFota();
  const { fotaFiles } = useAppSelector((st) => st.fota);
  const [selectedFile, setSelectedFile] = useState<FotaFile>(null);
  const [isShowFileModal, setIsShowFileModal] = useState(false);
  const handleOpenFotaFileEditModal = (value) => {
    setSelectedFile(value);
    setIsShowFileModal(true);
  };
  const fotaFilesBaseTable = useMemo(
    () => (
      <BaseTable
        tableTitles={FOTA_FILES_TABLE_TITLES}
        tableItems={fotaFiles}
        loadedItems={true}
        selectedRowID={selectedFile?.fileName}
        idField={'fileName'}
        noItemsText={t('no_fota_files_grid_table111')}
        headerItemsPrefix={'_fota_files_grid_table'}
        height={'185px'}
        // dateColumnIndexes={[11]}
        linkIndex={[0]}
        cellClick={handleOpenFotaFileEditModal}
      />
    ),
    [fotaFiles, selectedFile]
  );
  const { isRTL } = localStorageService;
  useEffect(() => {
    setLoading(loading);
  }, [loading]);
  const closeFileModal = () => {
    setIsShowFileModal(false);
  };
  useEffect(() => {
    if (fotaChanged) {
      updateFota();
      fotaUpdated();
    }
  }, [fotaChanged]);
  return (
    <Row spacing="20px" alignItems="flex-start" justifyContent="space-between">
      <Box width={'calc(50% - 10px)'}>
        <Row sx={{ mb: '16px' }}>
          <BigTitle sx={{ mr: '25px' }}>{t('fota_title')}</BigTitle>
        </Row>
        <SearchInput
          value={searchValue}
          onChange={(value) => changeSearch(value.target.value)}
          placeholder={`${t('filter_search_placeholder')}`}
          sx={{ mb: '24px' }}
        />
        <Row sx={{ mb: '16px' }} alignItems="flex-start" width={'calc(100%)'}>
          <Box width={'100%'}>
            <Row spacing="20px" alignItems="flex-start" justifyContent={'space-between'} width={'100%'}>
              <Box>
                <BigTitle sx={{ fontSize: '24px', mt: '14px', whiteSpace: 'nowrap' }}>{t('filter_fota')}</BigTitle>
              </Box>
              <Row spacing="20px" alignItems="flex-start" justifyContent={'flex-end'} width={'calc(100% - 100px)'}>
                <Box width={'calc(25%)'} maxWidth={'calc(25%)'} minWidth={'calc(25%)'}>
                  {!!customerFilterItems.length && (
                    <BaseMultiselect
                      title={`${t('customer_label')}`}
                      values={customerIDs}
                      onChange={onChangeCustomerFilter}
                      applyFilter={onSelectFilter}
                      items={customerFilterItems}
                      withSearch
                      fullWidth
                    />
                  )}
                </Box>
                <Box width={'calc(25%)'} maxWidth={'calc(25%)'} minWidth={'calc(25%)'}>
                  {!!modelFilterItems && (
                    <BaseMultiselect
                      title={`${t('model_id_label')}`}
                      values={modelFilter}
                      onChange={onChangeModelFilter}
                      applyFilter={onSelectFilter}
                      items={modelFilterItems}
                      withSearch
                      hideValue
                      fullWidth
                    />
                  )}
                </Box>

                <Box width={'calc(25% - 20px)'} maxWidth={'calc(25% - 20px)'} minWidth={'calc(25% - 20px)'}>
                  {!!fotaTypesItems.length && (
                    <BaseMultiselect
                      title={`${t('device_type_label')}`}
                      values={selectedFotaTypes}
                      onChange={onChangeFotaTypes}
                      applyFilter={onSelectFilter}
                      items={fotaTypesItems}
                      withSearch
                      hideValue
                      fullWidth
                    />
                  )}
                </Box>
                <Box width={'calc(25% - 20px)'} maxWidth={'calc(25% - 20px)'} minWidth={'calc(25% - 20px)'}>
                  <Filter
                    title={`${t('fota_status_label')}`}
                    value={fotaStatus}
                    onChange={(value) => {
                      onSelectFilter();
                      onChangeFotaStatus(value);
                    }}
                    items={fotaStatusItems}
                    fullWidth
                    messageEmptyFilter="display_all_customer_status"
                    marginRight={'0px'}
                  />
                </Box>
              </Row>
            </Row>
            <Row alignItems="flex-start" justifyContent={'flex-end'} width={'100%'}>
              <Box>
                <BigTitle sx={{ fontSize: '24px', mt: '28px', mr: '8px', whiteSpace: 'nowrap' }}>
                  {t('last_request_date_fota')}
                </BigTitle>
              </Box>
              <Row alignItems="flex-start" spacing="20px" justifyContent={'flex-end'} width={'calc(100%)'}>
                <Box width={'calc(33%)'} maxWidth={'calc(33%)'} minWidth={'calc(33%)'}>
                  <Box sx={{ mt: '8px', position: 'relative' }}>
                    <BaseDataPicker
                      label={`${t('start_last_request_date_label')}`}
                      name="startLastRequestDate"
                      value={startLastRequestDate}
                      onChange={(name, value) => {
                        onChangeStartLastRequestDate(value);
                      }}
                      clearIcon
                    />
                    {/* <Icon
                      src={arrow_right}
                      sx={{
                        py: '7px',
                        position: 'absolute',
                        top: '20px',
                        right: '-16px',
                        transform: isRTL ? 'rotate(180deg)' : 'rotate(0deg)',
                      }}
                    /> */}
                    <Box sx={{
                      mr: '10px', position: 'absolute', top: '16px',
                      right: '-26px', fontWeight: 700, fontSize: "20px", color: "#888"
                    }}>{t('arrow_symbol')}</Box>
                  </Box>
                </Box>

                <Box width={'calc(33% - 20px)'} maxWidth={'calc(33% - 20px)'} minWidth={'calc(33% - 20px)'}>
                  <Box sx={{ mt: '8px' }}>
                    <BaseDataPicker
                      label={`${t('end_last_request_date_label')}`}
                      name="endLastRequestDate"
                      value={endLastRequestDate}
                      onChange={(name, value) => {
                        onChangeEndLastRequestDate(value);
                      }}
                      error={null}
                      clearIcon
                    />
                  </Box>
                </Box>
                <Box width={'calc(33% - 20px)'} maxWidth={'calc(33% - 20px)'} minWidth={'calc(33% - 20px)'}>
                  <BaseBtn onClick={updateFota} sx={{ mt: '28px', height: '29px' }} fullWidth>
                    {t('update_fota')}
                  </BaseBtn>
                </Box>
              </Row>
            </Row>
          </Box>
        </Row>
      </Box>
      <FileInfo onCloseModal={closeFileModal} openModal={isShowFileModal} selectedFile={selectedFile}></FileInfo>
      <Box width={'calc(50% - 10px)'}>
        <BigTitle sx={{ fontSize: '24px', mr: '20px' }}>{t('fota_firmware_files')}</BigTitle>
        {fotaFilesBaseTable}
      </Box>
    </Row>
  );
};

import { useTranslation } from 'react-i18next';
import { Fota } from '../fotaModels';
import { convertMinToTime } from '../fotaHelpers';
export const useFormatXlsxData = () => {
  const { t } = useTranslation();
  const formatTable = (arr: Fota[]) => {
    
    const exportArr = arr.map((item) => {
      return {
        [t('Customer_ID_fota_grid_table')]: item.customerID,
        [t('Customer_Name_fota_grid_table')]: item.customerName,
        [t('Customer_Zone_fota_grid_table')]: item.customerZone,
        [t('HWID_fota_grid_table')]: item.hwid,
        [t('Object_Name_fota_grid_table')]: item.objectName,
        [t('Device_Type_fota_grid_table')]: t(`${item.deviceType}_device_type`),
        [t('Version_Current_fota_grid_table')]: item.versionCurrent,
        [t('Version_New_fota_grid_table')]: item.versionNew,
        [t('Is_Allow_Update_fota_grid_table')]: t(`${item.isAllowUpdate}_fota_status`),
        [t('Last_Request_fota_grid_table')]: t(`${item.lastRequest}_last_request`),
        [t('Last_Operation_DT_fota_grid_table')]: t(`${item.lastOperationDT}`),
        [t('Download_Percent_fota_grid_table')]: item.downloadPercent? item.downloadPercent+"%" : "",
        [t('Last_Request_DT_fota_grid_table')]: item.lastRequestDT,
        [t('Notes_fota_grid_table')]: item.notes,
        [t('download_Time_fota_grid_table')]: item.downloadTime,
        [t('last_Comm_DT_fota_grid_table')]: item.lastCommDT,
        [t('controller_Version_fota_grid_table')]: item.controllerVersion,
        [t('model_ID_fota_grid_table')]: item.modelID,
        [t('object_ID_fota_grid_table')]: item.objectID,
        [t('di_Device_Type_fota_grid_table')]: item.diDeviceType,
        [t('customer_Type_fota_grid_table')]: item.customerType,
        [t('special_Version_fota_grid_table')]: item.specialVersion,
        [t('fs_Device_Type_fota_grid_table')]: item.fsDeviceType,

        [t('current_Version_Min_fota_grid_table')]: item.currentVersionMin,
        [t('current_Version_Max_fota_grid_table')]: item.currentVersionMax,
        [t('new_Version_Min_fota_grid_table')]: item.newVersionMin,
        [t('new_Version_Max_fota_grid_table')]: item.newVersionMax,

        [t('download_Time_From_fota_grid_table')]: convertMinToTime(item.downloadTimeFrom),
        [t('download_Time_To_fota_grid_table')]:  convertMinToTime(item.downloadTimeTo),
        [t('restart_Time_From_fota_grid_table')]:  convertMinToTime(item.restartTimeFrom),
        [t('restart_Time_To_fota_grid_table')]:  convertMinToTime(item.restartTimeTo),

        [t('check_Time_fota_grid_table')]: item.checkTime,
        [t('check_Current_Version_fota_grid_table')]: item.checkCurrentVersion,
        [t('check_New_Version_fota_grid_table')]: item.checkNewVersion,
        [t('check_Flash_Bank_Current_fota_grid_table')]: item.checkFlashBankCurrent,
        [t('check_Flash_Bank_Boot_fota_grid_table')]: item.checkFlashBankBoot,
        [t('check_Result_fota_grid_table')]: item.checkResult,
        [t('download_Version_fota_grid_table')]: item.downloadVersion,

        [t('download_Offset_fota_grid_table')]: item.downloadOffset,
        [t('confirm_Time_fota_grid_table')]: item.confirmTime,
        [t('confirm_Current_Version_fota_grid_table')]: item.confirmCurrentVersion,
        [t('confirm_New_Version_fota_grid_table')]: item.confirmNewVersion,
        [t('confirm_Is_CRC_Valid_fota_grid_table')]: item.confirmIsCRCValid,
        [t('confirm_Result_fota_grid_table')]: item.confirmResult,
        [t('device_Type_From_Request_fota_grid_table')]: item.deviceTypeFromRequest,
       };
    });

    return exportArr;
  };

  return { formatTable };
};

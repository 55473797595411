import { yupResolver } from '@hookform/resolvers/yup';
import { Box, IconButton, MenuItem, Stack } from '@mui/material';
import { DatePicker } from 'features/DatePicker';
import { useMemo, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { close_popup } from 'shared/assets';
import {
  useAnchorEl,
  useAppSelector,
  useCustomerDateErrors,
  useExtendedTranslation,
  useToast,
} from 'shared/hooks';
import { getDeepTrimCustomerValues, } from 'shared/lib';
import { BaseBtn, BigTitle, Confirm, Input, Modal, Row, Select } from 'shared/ui';
import { useAddCustomerChanges } from './hooks';
import { useAddCustomer } from 'features/CustomerManager/hooks/api/useAddCustomer';
import { Customer } from 'features/CustomerManager/customerModels';
import { customerSchema } from 'features/CustomerManager/customerSchema';

interface Props {
  openCustomerAddModal: boolean
  onCloseModal: () => void
  setCurrentCustomer: (customer: Customer) => void
}


export const AddCustomer = ({ openCustomerAddModal, onCloseModal, setCurrentCustomer }: Props) => {
  const { t } = useExtendedTranslation();

  const { customers, customerTypes, customerStatuses, customerAgreementCodes } = useAppSelector((st) => st.customers);
  const { showError } = useToast();
  const { isOpen, anchorEl, onOpenMenu, onCloseMenu } = useAnchorEl();
  const { isLoading, addCustomer } = useAddCustomer();

  const newUserId = useMemo(() => customers[customers.length - 1]?.id + 1, [customers]);
  const { values, isTyped, onChangeValues, onChangeDateValues, onChangeSelectValues, boxRef } = useAddCustomerChanges(openCustomerAddModal);
  const { createdDateError, setCreatedDateError, startDateError, setStartDateError } = useCustomerDateErrors();

  const closeModal = () => onCloseModal();
  const handleCloseAddModal = (e: React.MouseEvent<HTMLElement>) => {
    if (isLoading) return;

    form.clearErrors();
    setCreatedDateError(null);
    setStartDateError(null);

    isTyped ? onOpenMenu(e) : closeModal();
  };


  const form = useForm<Customer>({
    mode: 'onSubmit',
    resolver: yupResolver(customerSchema),
  });

  const handleSave = async () => {
    if (createdDateError || startDateError) return;

    const nameIsExist = customers.find(
      (c) => c.customerName.toLowerCase().trim() === values.customerName.toLowerCase().trim()
    );
    if (nameIsExist) {
      showError(t('customer_with_such_name_is_already_exist_message'));
      return;
    }

    const trimedValues = getDeepTrimCustomerValues(values);

    (addCustomer(trimedValues)).then((result) => {
      if (result) {

        setCurrentCustomer(values)
        onCloseModal()
      }
    })

  };
  const closeButtonRef = useRef<HTMLButtonElement | null>(null);
  const handleModalClose = () => {
    if (closeButtonRef.current) {
      closeButtonRef.current.click();
    }
  };
  return (
    <Modal open={openCustomerAddModal} onClose={handleModalClose}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <BigTitle>{t('add_customer_title')}</BigTitle>

        <IconButton ref={closeButtonRef} onClick={handleCloseAddModal}>
          <img src={close_popup} />
        </IconButton>
      </Stack>

      <FormProvider {...form}>
        <Box component="form" onSubmit={form.handleSubmit(handleSave)} autoComplete="off">
          <Row spacing="20px" alignItems="flex-start" justifyContent="space-between">
            <Box width={'30%'}>
              <Input label={`${t('customer_id_add_modal')} *`} value={newUserId} name="id" disabled />
              <Input
                label={`${t('customer_name_add_modal')} *`}
                name="customerName"
                value={values.customerName}
                onChange={onChangeValues}
              />
              <Select
                label={`${t('customer_agreement_label_add_modal')} *`}
                name="customerAgreementStatusId"
                value={values.customerAgreementStatusId}
                onChange={onChangeSelectValues}
                displayEmpty
                sx={{
                  fontFamily: 'Noto Sans Hebrew !important',
                }}
              >
                {customerAgreementCodes.map((a) => (
                  <MenuItem
                    key={a.code}
                    value={a.code}
                    sx={{
                      fontFamily: 'Noto Sans Hebrew !important',
                    }}
                  >
                    {a.text}
                  </MenuItem>
                ))}
              </Select>
              <Input
                label={`${t('post_address_add_modal')}`}
                name="postAddress"
                value={values.postAddress}
                onChange={onChangeValues}
              />
              <Input
                label={`${t('email_address_add_modal')}`}
                name="eMailAddress"
                value={values.eMailAddress}
                onChange={onChangeValues}
              />
            </Box>
            <Box width={'30%'}>
              <Input
                label={`${t('office_phone_number_add_modal')}`}
                name="officePhoneNumber"
                value={values.officePhoneNumber}
                onChange={onChangeValues}
              />
              <Input
                label={`${t('contact_person_name_add_modal')}`}
                name="contactPersonName"
                value={values.contactPersonName}
                onChange={onChangeValues}
              />
              <Input
                label={`${t('contact_person_phone_number_add_modal')}`}
                name="contactPersonPhoneNumber"
                value={values.contactPersonPhoneNumber}
                onChange={onChangeValues}
              />
              <DatePicker
                label={`${t('created_date_add_modal')}`}
                name="createdDate"
                value={values.createdDate}
                onChange={onChangeDateValues}
                error={createdDateError}
                setError={setCreatedDateError}
              />
              <DatePicker
                label={`${t('operation_start_date_add_modal')}`}
                name="operationStartDate"
                value={values.operationStartDate}
                onChange={onChangeDateValues}
                error={startDateError}
                setError={setStartDateError}
              />
            </Box>
            <Box width={'30%'}>
              <Select
                label={`${t('customer_type_label_add_modal')} *`}
                name="customerTypeId"
                value={values.customerTypeId}
                onChange={onChangeSelectValues}
                displayEmpty
                sx={{
                  fontFamily: 'Noto Sans Hebrew !important',
                }}
              >
                {customerTypes.map((t) => (
                  <MenuItem
                    key={t.customerTypeID}
                    value={t.customerTypeID}
                    sx={{
                      fontFamily: 'Noto Sans Hebrew !important',
                    }}
                  >
                    {t.customerType}
                  </MenuItem>
                ))}
              </Select>
              <Select
                label={`${t('customer_status_label_add_modal')} *`}
                name="customerStatusId"
                value={values.customerStatusId}
                onChange={onChangeSelectValues}
                displayEmpty
                sx={{
                  fontFamily: 'Noto Sans Hebrew !important',
                }}
              >
                {customerStatuses.map((s) => (
                  <MenuItem
                    key={s.customerStatusCode}
                    value={s.customerStatusCode}
                    sx={{
                      fontFamily: 'Noto Sans Hebrew !important',
                    }}
                  >
                    {s.customerStatus}
                  </MenuItem>
                ))}
              </Select>
              <Input
                label={`${t('notes_add_modal')}`}
                name="notes"
                value={values.notes}
                onChange={onChangeValues}
                big
              />

              <Input
                label={`${t('short_note_add_modal')}`}
                name="shortNote"
                value={values.shortNote ?? ''}
                onChange={onChangeValues}
              />
            </Box>
          </Row>

          <Row justifyContent="flex-end" mt={'35px'}>
            <Row width={300}>
              <BaseBtn onClick={handleCloseAddModal} btnColor="info.main" fullWidth>
                {t('cancel_add_modal')}
              </BaseBtn>
              <Box ref={boxRef} onClick={handleCloseAddModal} sx={{ height: '40px', width: '25px' }} />
              <BaseBtn type="submit" disabled={!isTyped} loading={isLoading} btnColor="primary.dark" fullWidth>
                {t('save_add_modal')}
              </BaseBtn>
            </Row>
          </Row>
        </Box>
      </FormProvider>

      <Confirm
        open={isOpen}
        anchorEl={anchorEl}
        onClose={onCloseMenu}
        confirm={closeModal}
        buttonTexts={[t('cancel_add_add_modal'), t('back_to_add_add_modal')]}
      />
    </Modal>
  );
};

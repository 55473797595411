import { Box } from '@mui/material';
import { t } from 'i18next';
import { BigTitle, BaseBtn, Row, Icon, Text } from 'shared/ui';
import { ServiceCallsTable } from 'features/ServiceCallsTable';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { EditServiceCalls, AddServiceCalls } from 'widgets';
import { toggleAddServiceCall } from 'shared/slices';
import { FC } from 'react';
import { PowerProblemsItem } from 'features/ExternalPowerProblemsReportManager/PowerProblemsReportModels';

interface Props {
  changedServiceCalls: () => void;
  currentPowerProblem: PowerProblemsItem;
}

export const ServiceCalls: FC<Props> = ({ changedServiceCalls, currentPowerProblem }) => {
  const dispatch = useAppDispatch();
  const { serviceCalls } = useAppSelector((st) => st.serviceCalls);
  const handleOpenAddModal = () => {
    dispatch(toggleAddServiceCall());
  };
  return (
    <Box>
      <Row sx={{ mt: '6px' }} flexWrap="wrap" rowGap="5px" alignItems="flex-end">
        <BigTitle>{t('last_service_calls_title')}</BigTitle>
        <BaseBtn onClick={handleOpenAddModal} sx={{ fontFamily: 'Noto Sans Hebrew', marginLeft: '20px' }}>
          <Box component="span">{t('add_service_call')}</Box>
          <Text sx={{ fontSize: '25px', mb: '4px', ml: '4px' }}>+</Text>
        </BaseBtn>
      </Row>
      <ServiceCallsTable serviceCalls={serviceCalls} />
      <EditServiceCalls changedServiceCalls={changedServiceCalls} />
      <AddServiceCalls
        customerID={currentPowerProblem.projectID}
        customerName={currentPowerProblem.projectName}
        controllerID={currentPowerProblem.objectID}
        controllerName={currentPowerProblem.objectName}
        faultEquipmentCode={30}
        changedServiceCalls={changedServiceCalls}
      />
    </Box>
  );
};

import React from 'react';

export const useAnchorEl = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const isOpen = Boolean(anchorEl);

  const onOpenMenu = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };
  const onCloseMenu = () => setAnchorEl(null);

  return {
    isOpen,
    anchorEl,
    onOpenMenu,
    onCloseMenu,
  };
};

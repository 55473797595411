import { useTranslation } from 'react-i18next';
import { WAMessageStatus } from '../WAMessageStatusModels';
import { getFormattedDateAndTime } from 'shared/lib';
export const useFormatXlsxData = () => {
  const { t } = useTranslation();
  const formatTable = (arr: WAMessageStatus[]) => {

    const exportArr = arr.map((item) => {
      return {
        [t('created_at_wa_message_status_user_grid_table')]: item.createdAt ? getFormattedDateAndTime(item.createdAt as string): '' ,
        [t('message_body_wa_message_status_user_grid_table')]: item.messageBody,
        [t('status_wa_message_status_user_grid_table')]: t(item.status+"_message_status_item"),
        [t('status_date_wa_message_status_user_grid_table')]: item.statusDate ? getFormattedDateAndTime(item.statusDate as string): '',
        [t('customer_id_wa_message_status_user_grid_table')]: item.customerID,
        [t('customer_name_wa_message_status_user_grid_table')]: item.customerName,
        [t('to_phone_wa_message_status_user_grid_table')]: item.toPhone,
        [t('error_message_wa_message_status_user_grid_table')]: item.errorMessage,
      };
    });

    return exportArr;
  };

  return { formatTable };
};

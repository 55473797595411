import { useState, useEffect } from 'react';
import { AxiosError, AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import axiosBase from 'shared/config/axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useToast } from 'shared/hooks';
type RequestParams<TRequest,TResponse> = {url:string,showSuccessMessageCode:string, 
  params?:object,
  payload?:object,
  method:Method,
  onDataReceiveCompleted?:(data:TResponse)=>void} ;

const useAxios = () => {
  // const [axiosError, setAxiosError] = useState<AxiosError>();
  const [processState, setProcessState] = useState<{error:undefined|string,loading:boolean}>({error:undefined,loading:false});
  const push = useNavigate();
  const { t } = useTranslation();
  const { showError, showSuccess } = useToast();

  const request = async <TRequest,TResponse>(requestParams:RequestParams<TRequest,TResponse>) => {
    try {
      setProcessState({loading:true,error:undefined});
      const result = await axiosBase.request<TRequest,AxiosResponse<TResponse>>({data:requestParams.payload, method: requestParams.method, url:requestParams.url, params:requestParams.params});
      // setResponse(result.data);
      if(requestParams.onDataReceiveCompleted){
      requestParams.onDataReceiveCompleted(result.data);
      }
      if(requestParams.showSuccessMessageCode)
       showSuccess(t(requestParams.showSuccessMessageCode));
       return result.data
      
    }  catch (error: any) {
       //console.error(error);
       
      // setAxiosError(error);
      if (error?.response?.status === 401) {
        push('/login');
      } else {
        setProcessState(state=>({...state, error:error?.message}));
        showError(t('error_toast_text'));
      
      }
    } finally {
      setProcessState(state=>({...state, loading:false}));
    }
  };
  return { loading:processState.loading, error:processState.error, request };
}

export default useAxios;

import { Box, Checkbox, IconButton, MenuItem, Stack } from '@mui/material';
import { BaseSelect, ScriptParametersTable } from 'features';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useControllers,
  useGetCustomers,
  useGetScriptParametersDataGrid,
  useNetworkProtectionManagement,
} from 'shared/api';
import { checked_box, close_popup, un_checked_box } from 'shared/assets';
import { useAnchorEl, useAppDispatch, useAppSelector } from 'shared/hooks';
import { ConnectedControllerItem } from 'shared/models';
import { setScriptParameters, toggleEditConnectedController } from 'shared/slices';
import { BigTitle, Confirm, Modal, Row, Select, Text } from 'shared/ui';
import { BaseBtn, BaseInput, Icon } from '../../shared/ui';
import { useDropDown } from './hooks';
export const EditConnectedControllerModal: FC = () => {
  const [isChanged, setIsChanged] = useState(false);
  const { isOpen, anchorEl, onOpenMenu, onCloseMenu } = useAnchorEl();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { openEditConnectedController } = useAppSelector((st) => st.modals);
  const { currentNPController, NPScripts, NPSortIndexes, currentConnectedController, scriptParams } = useAppSelector(
    (st) => st.networkProtection
  );
  const closeModal = () => dispatch(toggleEditConnectedController());
  const { getCustomer, isLoadingCustomers } = useGetCustomers();
  const { getControllers, isLoadingControllers } = useControllers();
  const [showRuleEnable, setRuleEnable] = useState(false);

  const { getNPScripts, getNPSortIndex, isLoadingNPSortIndexes, updateConnectedController } =
    useNetworkProtectionManagement();

  useEffect(() => {
    if (!openEditConnectedController) {
      setValues(initialValues);
      onChangeCustomerItems('');
      onChangeControllerItems('');
      setIsChanged(false);
    }
  }, [openEditConnectedController]);

  const initialValues = {
    icProjectName: '',
    dstObjectName: '',
    dstModelID: '',
    dstVersion: '',
    icProjectID: 0,
    dstObjectCustomerID: 0,
    dstObjectID: 0,
    srcObjectID: 0,
    dstHWID: 0,
    description: '',
    ruleEnable: true,
    NPScript: '',
    sortIndex: '',
  };

  useEffect(() => {
    if (currentNPController) {
      setValues(currentNPController);
    }
  }, [currentNPController, openEditConnectedController]);

  useEffect(() => {
    if (openEditConnectedController) {
      getCustomer();
      getNPScripts();
    }
  }, [currentNPController, openEditConnectedController]);

  useEffect(() => {
    if (currentConnectedController) {
      onChangeCustomerItems(currentConnectedController.srcObjectCustomerID.toString());
      onChangeControllerItems(currentConnectedController.srcObjectID.toString());
      if (NPScripts.length) {
        setValues((prevValues) => ({
          ...prevValues,
          ['NPScript']: currentConnectedController.script_Type.trim(),
          ['sortIndex']: currentConnectedController.script_ID.toString(),
          ['description']: currentConnectedController.ruleDescription,
        }));
      }
    }
  }, [currentConnectedController, NPScripts]);
  const [values, setValues] = useState<ConnectedControllerItem>(initialValues);
  useEffect(() => {
    if (values.NPScript) {
      getNPSortIndex(values.NPScript);
    }
  }, [values.NPScript]);

  useEffect(() => {
    if (currentConnectedController && currentConnectedController.srcIsEnable) {
      setValues((prevValues) => ({
        ...prevValues,
        ['ruleEnable']: true,
      }));
      setRuleEnable(true);
    } else if (currentConnectedController) {
      setValues((prevValues) => ({
        ...prevValues,
        ['ruleEnable']: false,
      }));
      setRuleEnable(true);
    }
  }, [values.NPScript]);

  const handleCloseEditModal = (e: React.MouseEvent<HTMLElement>) => {
    isChanged ? onOpenMenu(e) : closeModal();
  };
  const onSubmit = () => {
    const data = {
      projectId: values.icProjectID,
      srcObjectId: controllerItem,
      dstObjectId: values.dstObjectID,
      scriptId: values.sortIndex,
      ruleDescription: values.description,
      scriptParams: null,
      isRuleEnable: values.ruleEnable ? 1 : 0,
      ruleID: currentConnectedController.ruleID,
    };
    updateConnectedController(data);
  };
  const {
    customerItem,
    onChangeCustomerItems,
    dropDownCustomerItems,
    controllerItem,
    onChangeControllerItems,
    dropDownControllersItems,
  } = useDropDown();
  useEffect(() => {
    if (openEditConnectedController && customerItem) {
      getControllers(customerItem);
    }
  }, [customerItem, openEditConnectedController]);

  const { getScriptParametersDataGrid } = useGetScriptParametersDataGrid();

  useEffect(() => {
    if (values.sortIndex) {
      getScriptParametersDataGrid(values.sortIndex);
    } else {
      dispatch(setScriptParameters([]));
    }
  }, [values.sortIndex]);

  const onChangeSelectValues = (event: any) => {
    const newValue = event.target.value;
    const property = event.target.name;
    setValues((prevValues) => ({
      ...prevValues,
      [property]: newValue,
    }));
    setIsChanged(true);
  };
  const onChangeValues = (event: any, propertyName: string) => {
    const newValue = event.target.value;
    setValues((prevValues) => ({
      ...prevValues,
      [propertyName]: newValue,
    }));

    setIsChanged(true);
  };
  const onChangeRuleEnable = () => {
    setValues((prevValues) => ({
      ...prevValues,
      ['ruleEnable']: !values?.ruleEnable,
    }));
    setIsChanged(true);
  };
  const getTranslate = (script_Type: string) => {
    return t(`st_${script_Type.trim()}`);
  };
  const closeButtonRef = useRef<HTMLButtonElement | null>(null);
  const handleModalClose = () => {
    if (closeButtonRef.current) {
      closeButtonRef.current.click();
    }
  };
  return (
    <Modal padding={'20px'} height={'calc(100vh - 20px)'} open={openEditConnectedController} onClose={handleModalClose}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <BigTitle>{t('network_protection_edit_connected_controller_modal_title')}</BigTitle>
        <IconButton ref={closeButtonRef} onClick={handleCloseEditModal}>
          <img src={close_popup} />
        </IconButton>
      </Stack>
      <Box>
        <Box sx={{ minHeight: '500px' }} component="form" autoComplete="off">
          <Row spacing="20px" alignItems="flex-start" justifyContent="space-between">
            <Box width={'32%'}>
              <BaseInput
                label={`${t('ic_project_name_np_edit_modal')}`}
                name="icProjectName"
                disabled
                helperText=""
                value={values?.icProjectName ?? ''}
                small
                compact
              />
              <BaseInput
                label={`${t('controller_name_np_edit_modal')}`}
                name="dstObjectName"
                disabled
                helperText=""
                value={values?.dstObjectName ?? ''}
                small
                compact
              />
              <BaseInput
                label={`${t('model_edit_np_modal')}`}
                name="dstModelID"
                disabled
                helperText=""
                value={values?.dstModelID ?? ''}
                small
                compact
              />
            </Box>
            <Box width={'32%'}>
              <BaseInput
                label={`${t('project_id_np_edit_modal')} *`}
                name="icProjectID"
                disabled
                helperText=""
                value={values?.icProjectID ?? ''}
                small
                compact
              />
              <BaseInput
                label={`${t('objectID_np_edit_modal')} *`}
                name="dstObjectID"
                disabled
                helperText=""
                value={values?.dstObjectID ?? ''}
                small
                compact
              />
              <BaseInput
                label={`${t('version_number_np_edit_modal')} *`}
                name="dstVersion"
                disabled
                helperText=""
                value={values?.dstVersion ?? ''}
                small
                compact
              />
            </Box>
            <Box width={'32%'}>
              <BaseInput
                label={`${t('customer_id_name_np_edit_modal')} *`}
                name="dstObjectCustomerID"
                disabled
                helperText=""
                value={values?.dstObjectCustomerID ?? ''}
                small
                compact
              />
              <BaseInput
                label={`${t('WHID_name_np_edit_modal')}`}
                name="dstHWID"
                disabled
                helperText=""
                value={values?.dstHWID ?? ''}
                small
                compact
              />
            </Box>
          </Row>
          <Row spacing="20px" alignItems="flex-start" justifyContent="space-between">
            <Box width={'32%'}>
              <BigTitle sx={{ marginBottom: '10px', marginTop: '20px' }}>
                {t('connected_controller_title_modal_title')}
              </BigTitle>
              {!isLoadingCustomers && (
                <BaseSelect
                  title={`${t('iCProject_label_edit_modal')}`}
                  value={customerItem}
                  onChange={onChangeCustomerItems}
                  items={dropDownCustomerItems}
                  hideAllButton
                  showHomeButton
                  showOnlyResetButton
                  withSearch
                  formInput
                  noSelectLabel={' '}
                  sx={{ width: '320px', maxWidth: '100%' }}
                />
              )}
              {
                <BaseSelect
                  title={`${t('controller_label_edit_modal')}`}
                  value={controllerItem}
                  onChange={onChangeControllerItems}
                  items={dropDownControllersItems}
                  hideAllButton
                  showHomeButton
                  showOnlyResetButton
                  withSearch
                  formInput
                  noSelectLabel={' '}
                  disabled={!customerItem || isLoadingControllers}
                  sx={{ width: '320px', maxWidth: '100%' }}
                />
              }
              {values?.NPScript && (
                <Select
                  label={`${t('script_type_label_edit_modal')}`}
                  name="NPScript"
                  value={values?.NPScript}
                  onChange={onChangeSelectValues}
                  displayEmpty
                  marginTop="4px"
                  sx={{
                    fontFamily: 'Noto Sans Hebrew !important',
                  }}
                  small
                >
                  {NPScripts.map((a) => (
                    <MenuItem key={a.type} value={a.type}>
                      {a.type ? getTranslate(a.type) : 'not select'}
                    </MenuItem>
                  ))}
                </Select>
              )}
              {values?.sortIndex && (
                <Select
                  label={`${t('index_label_edit_modal')} *`}
                  name="sortIndex"
                  value={values?.sortIndex}
                  onChange={onChangeSelectValues}
                  displayEmpty
                  disabled={!values?.NPScript || isLoadingNPSortIndexes}
                  marginTop="4px"
                  sx={{
                    fontFamily: 'Noto Sans Hebrew !important',
                  }}
                  small
                >
                  {NPSortIndexes.map((a) => (
                    <MenuItem key={a.sortIndex} value={a.sortIndex}>
                      {a.sortIndex ? a.sortIndex : 'not select'}
                    </MenuItem>
                  ))}
                </Select>
              )}
              <BaseInput
                label={`${t('description_np_edit_modal')}`}
                name="description"
                helperText=""
                value={values?.description ?? ''}
                small
                compact
                onChange={(event) => onChangeValues(event, 'description')}
              />

              {showRuleEnable && (
                <Row spacing="0px" mt="10px" alignItems="center">
                  <Checkbox
                    size="small"
                    checked={values.ruleEnable || false}
                    onChange={onChangeRuleEnable}
                    icon={<Icon src={un_checked_box} />}
                    checkedIcon={<Icon src={checked_box} />}
                  ></Checkbox>
                  <Text
                    noWrap
                    sx={{
                      color: 'primary.dark',
                      fontSize: '14px',
                      fontWeight: 500,
                      textAlign: 'left',
                      fontFamily: 'Noto Sans Hebrew !@important',
                    }}
                  >
                    {t('is_rule_enable_np_edit_modal')}{' '}
                  </Text>
                </Row>
              )}
            </Box>
            <Box width={'64%'}>
              <BigTitle sx={{ fontSize: '18px', marginTop: '24px' }}>{t('script_parameters_edit_modal')}</BigTitle>
              <ScriptParametersTable scriptParams={scriptParams} />
            </Box>
          </Row>
        </Box>
        <Box component="form" autoComplete="off">
          <Row justifyContent="flex-end" mt={'35px'}>
            <Row width={300}>
              <BaseBtn onClick={handleCloseEditModal} btnColor="info.main" fullWidth>
                {t('cancel_edit_modal')}
              </BaseBtn>
              <Box onClick={handleCloseEditModal} sx={{ height: '40px', width: '25px' }} />
              <BaseBtn
                onClick={onSubmit}
                disabled={!values?.sortIndex || !controllerItem}
                btnColor="primary.dark"
                fullWidth
              >
                {t('save_edit_modal')}
              </BaseBtn>
            </Row>
          </Row>
        </Box>
        <Confirm
          open={isOpen}
          anchorEl={anchorEl}
          onClose={onCloseMenu}
          confirm={closeModal}
          buttonTexts={[t('cancel_edit_edit_modal'), t('back_to_edit_edit_modal')]}
        />
      </Box>
    </Modal>
  );
};

import { Box, IconButton, Stack } from '@mui/material';
import { BaseSelect } from 'features/BaseSelect';

import { Customer } from 'features/CustomerManager/customerModels';
import { WATemplate } from 'features/CustomerManager/whatsAppModels';
import { useMessagesTemplates } from 'features/MessagesTemplatesManager/hooks/api/useMessagesTemplates';
import { t } from 'i18next';
import { useEffect, useRef, useState } from 'react';
import { close_popup } from 'shared/assets';
import { useAnchorEl } from 'shared/hooks';
import { BaseBtn, BaseInput, BigTitle, Confirm, Modal, Row, WaMessageBackground } from 'shared/ui';
interface Props {
  openCustomerAddModal: boolean;
  onCloseModal: () => void;
  currentMessageTemplate: WATemplate;
}
const messageLanguages = [
  {
    value: 'en',
    label: 'en',
  },
  {
    value: 'he',
    label: 'he',
  },
];
const messageCategories = [
  {
    value: 'MARKETING',
    label: 'MARKETING',
  },
  {
    value: 'UTILITY',
    label: 'UTILITY',
  },
];

export const EditMessageTemplate = ({ openCustomerAddModal, onCloseModal, currentMessageTemplate }: Props) => {
  const { isOpen, anchorEl, onOpenMenu, onCloseMenu } = useAnchorEl();
  const [isChanged, setIsChanged] = useState(false);
  const { createMessageTemplate } = useMessagesTemplates();
  const handleCloseAddModal = (e: React.MouseEvent<HTMLElement>) => {
    isChanged ? onOpenMenu(e) : closeModalAndClearData();
  };
  const closeModalAndClearData = () => {
    onCloseModal();
    setMessageTemplate('');
    setMessageTemplateName('');
    setSelectedMessageLanguage('');
    setIsChanged(false);
  };
  const [messageTemplate, setMessageTemplate] = useState('');
  const [messageTemplateName, setMessageTemplateName] = useState('');
  const [selectedMessageLanguage, setSelectedMessageLanguage] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');

  const onChangeMessageTemplate = (value: string) => {
    setIsChanged(true);
    setMessageTemplate(value);
  };
  const onChangeMessageTemplateName = (value: string) => {
    setIsChanged(true);
    setMessageTemplateName(value);
  };
  const onChangeMessageLanguage = (value: string) => {
    setIsChanged(true);
    setSelectedMessageLanguage(value);
  };
  const onChangeMessageCategory = (value: string) => {
    setIsChanged(true);
    setSelectedCategory(value);
  };
  const isValid = () => {
    if (!messageTemplateName || !messageTemplate || !selectedMessageLanguage || !selectedCategory) {
      return true;
    }
  };
  const handleSaveMessageTemplate = () => {
    const messageTemplateParams = {
      messageTemplateName: messageTemplateName,
      messageTemplateText: messageTemplate,
      messageTemplateLanguage: selectedMessageLanguage,
      messageTemplateCategory: selectedCategory,
    };
    createMessageTemplate(messageTemplateParams);
  };
  const inputRef = useRef<HTMLDivElement>();

  const boxRef = useRef<HTMLDivElement>();
  const closeButtonRef = useRef<HTMLButtonElement | null>(null);
  const handleModalClose = () => {
    if (closeButtonRef.current) {
      closeButtonRef.current.click();
    }
  };
  return (
    <Modal PaperProps={{ sx: { maxWidth: '562px' } }} onClose={handleModalClose} open={openCustomerAddModal} disableEscapeKeyDown>
      {currentMessageTemplate && (
        <Box
          sx={{
            p: '41px 36px 36px 36px',
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
          }}
        >
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <BigTitle >{t('view_message_template_title')}</BigTitle>

            <IconButton ref={closeButtonRef} onClick={handleCloseAddModal}>
              <img src={close_popup} />
            </IconButton>
          </Stack>

          <BaseInput
            label={`${t('message_template_name_edit_modal')}`}
            error={false}
            helperText={''}
            placeholder={`${t('message_template_name_placeholder_edit_modal')}`}
            value={currentMessageTemplate.name}
            onChange={() => { }}
            small
            compact
          />
          <BaseSelect
            title={`${t('select_category_message_label_edit_modal')}`}
            value={currentMessageTemplate.category}
            onChange={onChangeMessageCategory}
            items={messageCategories}
            hideAllButton
            noSelectLabel={`${t('select_category_no_select_placeholder_edit_modal')}`}
            hideValue
            formInput
            withoutMargin
            disabled
          />
          <WaMessageBackground>
            <BaseInput
              value={currentMessageTemplate.text}
              onChange={(event) => { }}
              error={false}
              textColor={'white'}
              helperText={''}
              big
              sx={{ border: 'none' }}
              ref={inputRef}
              view
            />
            {/* <textarea dir="auto">{currentMessageTemplate.text}</textarea> */}
          </WaMessageBackground>
          <BaseSelect
            title={`${t('select_lang_message_label_edit_modal')}`}
            value={currentMessageTemplate.language}
            onChange={onChangeMessageLanguage}
            items={messageLanguages}
            hideAllButton
            noSelectLabel={`${t('select_lang_message_no_select_placeholder_edit_modal')}`}
            hideValue
            formInput
            withoutMargin
            disabled
          />
          <Row justifyContent="flex-end" mt={'35px'}>
            <Row width={300}>
              {/* <BaseBtn onClick={handleCloseAddModal} btnColor="info.main" fullWidth>
              {t('cancel_add_modal')}
            </BaseBtn> */}
              <Box ref={boxRef} sx={{ height: '40px', width: '25px' }} />
            </Row>
          </Row>
          <Confirm
            open={isOpen}
            anchorEl={anchorEl}
            onClose={onCloseMenu}
            confirm={closeModalAndClearData}
            buttonTexts={[t('cancel_add_add_modal'), t('back_to_add_add_modal')]}
          />
        </Box>
      )}
    </Modal>
  );
};

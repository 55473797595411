import { useAppDispatch } from 'shared/hooks';
import { setCustomerFilter } from 'shared/slices';
import { useAppSelector, useExtendedTranslation } from 'shared/hooks';
import { useState, useEffect, useMemo } from 'react';
import { Fota, FotaFilter } from '../fotaModels';
import { useGetAllFota } from './api/useGetAllFota';
export const useFilterFota = (updateAllFota = true) => {
  const { t } = useExtendedTranslation();
  const { customers } = useAppSelector((st) => st.customers);
  const { fotaTypes } = useAppSelector((st) => st.fota);
  const dispatch = useAppDispatch();
  const [customerIDs, setCustomerIDs] = useState<string[]>([]);
  const [modelFilter, setModelFilter] = useState<string[]>(['I1']);
  const [fotaStatus, setFotaStatus] = useState<string>('1');
  const [selectedFotaTypes, setSelectedFotaTypes] = useState<string[]>([]);

  const [endLastRequestDate, setEndLastRequestDate] = useState<string>('');
  const [startLastRequestDate, setStartLastRequestDate] = useState<string>('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(setCustomerFilter(''));
  }, []);

  const { getAllFota } = useGetAllFota();
  useEffect(() => {
    if (updateAllFota) {
      setLoading(true);
      fetchData();
    }
  }, [customerIDs, modelFilter, fotaStatus, selectedFotaTypes]);
  const updateFota = () => {
    setLoading(true);
    fetchData();
  };
  const fetchData = async () => {
    let data: FotaFilter = {};
    if (endLastRequestDate || startLastRequestDate) {
      data = { lastRequestDates: { from: null, to: null } };
    }
    if (customerIDs.length) {
      data.customerIds = customerIDs;
    }
    if (modelFilter.length) {
      data.modelIDs = modelFilter;
    }
    if (selectedFotaTypes.length) {
      data.deviceType = selectedFotaTypes;
    }
    if (fotaStatus) {
      data.fotaStatus = fotaStatus;
    }
    if (startLastRequestDate) {
      data.lastRequestDates.from = startLastRequestDate;
    }
    if (endLastRequestDate) {
      data.lastRequestDates.to = endLastRequestDate;
    }
    await getAllFota(data);
    setLoading(false);
  };
  const customerFilterItems = useMemo(
    () =>
      customers.map((s) => ({
        value: s.id.toString(),
        label: s.customerName,
      })),
    [customers]
  );
  const modelFilterItems = [
    {
      value: 'DM',
      label: `${t('DM_model_type')} - ${t('dam_model_description')}`,
    },
    {
      value: 'CR',
      label: `${t('CR_model_type')} - ${t('computer_room_model_description')}`,
    },
    {
      value: 'NV',
      label: `${t('NV_model_type')} - ${t('general_controller_model_description')}`,
    },
    {
      value: 'WE',
      label: `${t('WE_model_type')} - ${t('meteorological_station_model_description')}`,
    },
    {
      value: 'IP',
      label: `${t('IP_model_type')} - ${t('network_protection_model_description')}`,
    },
    {
      value: 'LT',
      label: `${t('LT_model_type')} - ${t('lighting_controller_model_description')}`,
    },
    {
      value: 'IA',
      label: `${t('IA_model_type')} - ${t('fountain_model_description')}`,
    },
    {
      value: 'I1',
      label: `${t('I1_model_type')} - ${t('irrigation_controller_model_description')}`,
    },
    {
      value: 'SG',
      label: `${t('SG_model_type')} - ${t('prismatic_sign_model_description')}`,
    },
  ];

  const fotaTypesItems = useMemo(
    () =>
      fotaTypes.map((s) => ({
        value: s.fotaDeviceTypeCode,
        label: s.fotaDeviceTypeCode,
      })),
    [fotaTypes]
  );

  const fotaStatusItems = [
    {
      value: '0',
      label: `${t('fota_status_Disable')}`,
    },
    {
      value: '1',
      label: `${t('fota_status_Active')}`,
    },
  ];
  const onChangeCustomerFilter = (values: string[]) => {
    setCustomerIDs(values);
  };
  const onChangeModelFilter = (values: string[]) => {
    setModelFilter(values);
  };
  const onChangeFotaTypes = (values: string[]) => {
    setSelectedFotaTypes(values);
  };
  const onChangeFotaStatus = (value: string) => {
    setFotaStatus(value);
  };
  const onChangeStartLastRequestDate = (value: string) => {
    setStartLastRequestDate(value);
  };
  const onChangeEndLastRequestDate = (value: string) => {
    setEndLastRequestDate(value);
  };

  return {
    customerIDs,
    onChangeCustomerFilter,
    customerFilterItems,
    modelFilterItems,
    modelFilter,
    onChangeModelFilter,
    fotaStatus,
    onChangeFotaStatus,
    fotaStatusItems,
    selectedFotaTypes,
    onChangeFotaTypes,
    fotaTypesItems,
    startLastRequestDate,
    onChangeStartLastRequestDate,
    endLastRequestDate,
    onChangeEndLastRequestDate,
    updateFota,
    loading,
  };
};

import { useExtendedTranslation } from 'shared/hooks';
import { EN, HE, AR_SY } from 'shared/config/i18n/languages';
import localStorageService from 'shared/localStorage';
export const useLanguageSelect = () => {
  const { changeLanguage } = useExtendedTranslation();

  const languages = [
    { value: EN, label: EN },
    { value: HE, label: HE },
    { value: AR_SY, label: AR_SY },
  ];
  const currentLang =  localStorageService.currentLang;
  const currentLanguage = currentLang || HE;

  const onChangeLanguage = (value: string) => {
    changeLanguage(value);
  };
  return {
    currentLanguage,
    onChangeLanguage,
    languages,
  };
};

import { useAppDispatch } from 'shared/hooks';
import { setCustomerFilter } from 'shared/slices';
import { useAppSelector, useExtendedTranslation } from 'shared/hooks';
import { useState, useEffect, useMemo } from 'react';
export const useFilterUsers = () => {
  const { t } = useExtendedTranslation();
  const { customers } = useAppSelector((st) => st.customers);
  const { customerIDForFilter } = useAppSelector((st) => st.controllers);
  const { userStatuses } = useAppSelector((st) => st.users);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setCustomerFilter(''));
  }, []);

  const actionsFilterItems = [
    {
      value: '1',
      label: t('reset-password-grid-table-modal'),
    },
    {
      value: '2',
      label: t('ask-update-password-grid-table-modal'),
    },
  ];
  const [actionsFilter, setActionsFilter] = useState<string>('');
  const onChangeActionsFilter = (value: string) => setActionsFilter(value);
  const customerFilterItems = useMemo(()=>customers.map((s) => ({
    value: s.id.toString(),
    label: s.customerName,
  })),[customers])
  const customerFilter = customerIDForFilter.toString();
  const onChangeCustomerFilter = (value: string) => dispatch(setCustomerFilter(value ? parseInt(value) : ''));
  const userStatusFilterItems = useMemo(()=>userStatuses.map((s: { userStatus: string }) => {
    return {
      value: s.userStatus.toString(),
      label: t(`user_status_${s.userStatus.toString().substring(s.userStatus.lastIndexOf('_') + 1)}`),
    };
  }),[userStatuses])
  const [userStatusFilter, setUserStatusFilter] = useState<string>('user_status_1');
  const onChangeUserStatusFilter = (values: string) => setUserStatusFilter(values);
  return {
    customerFilter,
    onChangeCustomerFilter,
    customerFilterItems,
    userStatusFilter,
    onChangeUserStatusFilter,
    userStatusFilterItems,
    actionsFilter,
    onChangeActionsFilter,
    actionsFilterItems,
  };
};

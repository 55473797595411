import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { axiosBase } from 'shared/config';
import { ScriptParam } from 'shared/models';
import {  useAppDispatch, useToast, useAppSelector } from 'shared/hooks';
import { setICProjects, setICResults,setNodesConnections, setNodesPoints, setNodesLines,setNodesEndPoints, setNPScripts, setNPSortIndex, setNPControllerList, toggleNewConnectedController, toggleEditConnectedController, setNPDefaultSortIndex, setScriptParameters} from 'shared/slices';
import useAxios from './useAxios';
export const useNetworkProtectionManagement = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { showError, showSuccess} = useToast();
  const push = useNavigate();
  const [isLoadingNPItems, setLoading] = React.useState(false);
  const { currentNPController, currentNPControllerId } = useAppSelector((st) => st.networkProtection);
  const [isLoadingNPSortIndexes, setIsLoadingNPSortIndexes,] = React.useState(false);
  const [isLoadingScriptParametersData, setIsLoadingScriptParametersData] = React.useState(false);
  
  const getICProjects= async () => {
    setLoading(true);
    try {
      const { data } = await axiosBase.get('/networkprotection/projects');
      dispatch(setICProjects(data.data));
    } catch (error: any) {
      console.log('#ERROR', error);
      if (error?.response?.status === 401) {
        push('/login');
      } else {
        showError(t('something_went_wrong_toast_text'));
      }
    } finally {
      setLoading(false);
    }
  };
  const getICRulesFull= async () => {
    setLoading(true);

    try {
      const { data } = await axiosBase.get('/networkprotection/rules');
      dispatch(setICResults(data.data));
    } catch (error: any) {
      console.log('#ERROR', error);
      if (error?.response?.status === 401) {
        push('/login');
      } else {
        showError(t('something_went_wrong_toast_text'));
      }
    } finally {
      setLoading(false);
    }
  };

  const getNodesPoints= async () =>{
    try {
      const { data } = await axiosBase.get(`/networkprotection/map/points?id=${currentNPControllerId}`);
      dispatch(setNodesPoints(data.data));
    } catch (error: any) {
      console.log('#ERROR', error);
      if (error?.response?.status === 401) {
        push('/login');
      } else {
        showError(t('something_went_wrong_toast_text'));
      }
    } finally {
      setLoading(false);
    }
  }
  const getNodesEndPoints= async () =>{
    try {
      const { data } = await axiosBase.get(`/networkprotection/map/endpoints?id=${currentNPControllerId}`);
      dispatch(setNodesEndPoints(data.data));
    } catch (error: any) {
      console.log('#ERROR', error);
      if (error?.response?.status === 401) {
        push('/login');
      } else {
        showError(t('something_went_wrong_toast_text'));
      }
    } finally {
      setLoading(false);
    }
  }


  const getNodesConnections= async () =>{
    try {
      const { data } = await axiosBase.get(`/networkprotection/map/connections?id=${currentNPControllerId}`);
      dispatch(setNodesConnections(data.data));
    } catch (error: any) {
      console.log('#ERROR', error);
      if (error?.response?.status === 401) {
        push('/login');
      } else {
        showError(t('something_went_wrong_toast_text'));
      }
    } finally {
      setLoading(false);
    }
  }
  const getNodesLines= async () =>{
    try {
      const { data } = await axiosBase.get(`/networkprotection/map/Lines?id=${currentNPControllerId}`);
      dispatch(setNodesLines(data.data));
    } catch (error: any) {
      console.log('#ERROR', error);
      if (error?.response?.status === 401) {
        push('/login');
      } else {
        showError(t('something_went_wrong_toast_text'));
      }
    } finally {
      setLoading(false);
    }
  }


  const getNPScripts= async () =>{
    try {
      const { data } = await axiosBase.get(`/networkprotection/controller/dropdown/scripts`);
      dispatch(setNPScripts(data.data));
    } catch (error: any) {
      console.log('#ERROR', error);
      if (error?.response?.status === 401) {
        push('/login');
      } else {
        showError(t('something_went_wrong_toast_text'));
      }
    } finally {
      setLoading(false);
    }
  }
  const getNPSortIndex = async (scriptType: string) =>{
    setIsLoadingNPSortIndexes(true)
    try {
      const { data } = await axiosBase.get(`/networkprotection/controller/dropdown/sortindex?type=${scriptType}`);
      dispatch(setNPSortIndex(data.data));
    } catch (error: any) {
      console.log('#ERROR', error);
      if (error?.response?.status === 401) {
        push('/login');
      } else {
        showError(t('something_went_wrong_toast_text'));
      }
    } finally {
      setIsLoadingNPSortIndexes(false)
      setLoading(false);
    }
  }
  const addNewConnectedController  = async (connectedControllerData: any) =>{
 
    try {
      const { data } = await axiosBase.post(`/networkprotection/controller/`, connectedControllerData);
      const result= data.data.result;
      switch (result) {
        case -5:
          showError(t('one_or_more_of_destination_registers_already_used_by_existing_scripts'));
          break;
        case -4:
          showError(t('@ScriptID_doesn’t_exist'));
          break;
        case -3:
          showError(t('@dstObjectID_doesn’t_exist'));
          break;
        case -2:
          showError(t('@srcObjectID_doesn’t_exist'));
          break;
        case -1:
          showError(t('Project_ID_doesn’t_exist'));
          break;
        default:
                //dispatch(setNPSortIndex(data.data));
                showSuccess(t('new_connected_controller_successfully_added_toast_text'));
                dispatch(toggleNewConnectedController())
                getNPControllerList()
          break;
      }

    } catch (error: any) {
      console.log('#ERROR', error);
      if (error?.response?.status === 401) {
        push('/login');
      } else {
        showError(t('something_went_wrong_toast_text'));
      }
    } finally {
      setLoading(false);
    }
  }
  const updateConnectedController= async (connectedControllerData: any) =>{
 
    try {
      const { data } = await axiosBase.put(`/networkprotection/controller/`, connectedControllerData);
      const result= data.data.result;
      switch (result) {
        case -5:
          showError(t('one_or_more_of_destination_registers_already_used_by_existing_scripts'));
          break;
        case -4:
          showError(t('@ScriptID_doesn’t_exist'));
          break;
        case -3:
          showError(t('@dstObjectID_doesn’t_exist'));
          break;
        case -2:
          showError(t('@srcObjectID_doesn’t_exist'));
          break;
        case -1:
          showError(t('Project_ID_doesn’t_exist'));
          break;
        default:
                showSuccess(t('connected_controller_successfully_updated_toast_text'));
                dispatch(toggleEditConnectedController())
                getNPControllerList()
          break;
      }

    } catch (error: any) {
      console.log('#ERROR', error);
      if (error?.response?.status === 401) {
        push('/login');
      } else {
        showError(t('something_went_wrong_toast_text'));
      }
    } finally {
      setLoading(false);
    }
  }
  const getNPControllerList= async () =>{
    try {
      const { data } = await axiosBase.get(`/networkprotection/rules/byid/?id=${currentNPControllerId}`);
      dispatch(setNPControllerList(data.data));
    } catch (error: any) {
      console.log('#ERROR', error);
      if (error?.response?.status === 401) {
        push('/login');
      } else {
        showError(t('something_went_wrong_toast_text'));
      }
    } 
  }
  const getNPDefaultSortIndex = async (scriptType: string, id: string) =>{
    setIsLoadingNPSortIndexes(true)
    try {
      const { data } = await axiosBase.get(`/networkprotection/controller/dropdown/sortindex/defaultvalue?type=${scriptType}&id=${id} `);
      dispatch(setNPDefaultSortIndex(data.data.id));
    } catch (error: any) {
      console.log('#ERROR', error);
      if (error?.response?.status === 401) {
        push('/login');
      } else {
        showError(t('something_went_wrong_toast_text'));
      }
    } finally {
      setIsLoadingNPSortIndexes(false)
      setLoading(false);
    }
  }



  return {
    getICProjects,
    getICRulesFull,
    getNodesPoints,
    getNodesConnections,
    getNodesLines,
    getNodesEndPoints,
    getNPScripts,
    getNPSortIndex,
    addNewConnectedController,
    updateConnectedController,
    getNPControllerList,
    getNPDefaultSortIndex,
    isLoadingNPItems,
    isLoadingNPSortIndexes,
    isLoadingScriptParametersData
  };
};

import { FC, memo, useEffect, useRef, useState } from 'react';
import { Box, } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ScriptParam } from 'shared/models';
import { ScriptParametersItem } from 'entities_';
import { TABLE_TITLES } from './constants';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { BigTitle } from 'shared/ui';
import { DataGridPro, GridColDef, GridColumnHeaders, useGridApiRef } from '@mui/x-data-grid-pro';
import { useUserManagement } from 'features/UserManager/hooks/api/useUserManagement';
interface Props {
  scriptParams: ScriptParam[];
  disabled?: boolean
}

export const ScriptParametersTable: FC<Props> = memo(({ scriptParams, disabled }) => {
  const customScriptParams = [...scriptParams];
  customScriptParams.push({
    scriptParamDescription: '',
    scrEvent: 0,
    dstEvent: 0
  });
  const apiRef = useGridApiRef();
  const tableRef = useRef<HTMLTableElement | null>(null);
  const MemoizedRow = memo(ScriptParametersItem);
  const MemoizedColumnHeaders = GridColumnHeaders;
  const { t } = useTranslation();
  const [unitWidth, setUnitWidth] = useState(0);
  const { isLoading } = useUserManagement();
  useEffect(() => {
    calculateUnitWidth();
  }, []);
  const calculateUnitWidth = () => {

    const scroolWidth = 25;
    let screenWidth = window.innerWidth;
    if (tableRef && tableRef.current) {
      screenWidth = tableRef.current.clientWidth - scroolWidth;
    }
    let sumOfStaticWidth = 0;
    let sumOfPercentWidth = 0;
    TABLE_TITLES.forEach((title) => {
      if (typeof title.width === 'number') {
        sumOfStaticWidth += title.width;
      } else if (typeof title.width === 'string') {
        sumOfPercentWidth += parseInt(title.width);
        return 20;
      }
    });
    const dinamicWidth = screenWidth - sumOfStaticWidth;
    const unitWidth = dinamicWidth / sumOfPercentWidth;
    setUnitWidth(unitWidth);
  };
  const calculateWidthInPixels = (width: number | string): number => {
    if (typeof width === 'number') {
      return width;
    } else if (typeof width === 'string') {
      return parseInt(width) * unitWidth;
    }
    return 100;
  };
  const columns: GridColDef<ScriptParam>[] = TABLE_TITLES.map((title) => ({
    field: title.key,
    headerName: `${t(title.title !== '' ? `${title.title}_script_parameters_grid_table` : '')}`,
    width: calculateWidthInPixels(title.width),
    headerClassName: 'headerTable',
  }));
  function CustomSortedAscendingIcon() {
    return <Box />;
  }
  function CustomSortedDescendingIcon() {
    return <Box />;
  }
  function CustomNoRowsOverlay() {
    return <ArrowDropUpIcon />;
  }

  return (
    <div ref={tableRef} style={{ width: '100%', position: 'relative', height: '185px', opacity: disabled ? 0.5 : 1 }}>
      {unitWidth && (
        <>
          {disabled && <Box style={{ width: '100%', height: '185px', position: 'absolute', zIndex: "2" }}></Box>}
          <DataGridPro
            {...customScriptParams}
            getRowId={(row) => row.scriptParamDescription}
            loading={isLoading}
            checkboxSelection={false}
            disableRowSelectionOnClick
            components={{
              Row: MemoizedRow,
              ColumnHeaders: MemoizedColumnHeaders,
              ColumnSortedAscendingIcon: CustomSortedAscendingIcon,
              ColumnSortedDescendingIcon: CustomSortedDescendingIcon,
              NoResultsOverlay: CustomNoRowsOverlay,
            }}
            apiRef={apiRef}
            density={'compact'}
            rows={customScriptParams}
            columns={columns}
            disableColumnMenu
            hideFooter
            sortModel={[
            ]}
            sx={{
              maxHeight: '25vh',
              height: '185px',
              minHeight: '185px',
              maxWidth: '100%',
              borderRadius: '12px',
              position: 'relative',
              mt: '12px',
              pr: '1px',
              bgcolor: 'info.main',
              overflowY: 'auto',
              '.MuiDataGrid-columnHeaders': {
                backgroundColor: '#0a3e90',
              },
              '.headerTable': {
                backgroundColor: '#0a3e90',
                color: 'white',
                borderRight: '1px solid #7a7a7a',
                fontWeight: 'bold',
                pr: '2px',
                height: '38px',
                '.MuiDataGrid-columnSeparator': {
                  opacity: '0 !important',
                },
                svg: {
                  fill: 'white',
                },
              },
              '.MuiDataGrid-virtualScrollerRenderZone': {},
              '.MuiDataGrid-virtualScroller': {
                overflow: `${!customScriptParams.length && 'hidden'}`,
              },
              '& .MuiDataGrid-virtualScrollerContent': {
                marginTop: '-1px',
              },
            }}
          />

        </>
      )}
    </div>
  );
});

import { Box, CircularProgress } from '@mui/material';
import { LineBarChart } from 'components/LineBarChart';
import { useWeatherStationsManager } from 'features/WeatherStationsManager/hooks/api';
import { t } from 'i18next';
import { FC, useEffect, useState } from 'react';
import { useAppSelector } from 'shared/hooks';
import { Row } from 'shared/ui';

interface Props {
  loading: boolean;
  startReportDate: string;
  endReportDate: string;
}
const CHAT_COLORS = [
  {
    stroke: '#dda069',
    fill: '#dda06999',
  },
  {
    stroke: '#2222cc',
    fill: '#2222cc99',
  },
];

export const EvaporationAndRainData: FC<Props> = ({ loading, startReportDate, endReportDate }) => {
  const [formattedData, setFormattedData] = useState([]);
  const [popupPadding, setPopupPadding] = useState(null);

  const { evaporationAndRainData } = useAppSelector((st) => st.weatherStation);

  useEffect(() => {
    const timestamps = [];
    const eTValues = [null];
    const rainValues = [null];
    if (evaporationAndRainData.length) {
      const firstTimeStamp = new Date(startReportDate).getTime();
      timestamps.push(firstTimeStamp / 1000 - 50000);

      evaporationAndRainData.forEach((item) => {
        const timestamp = new Date(item.date_).getTime();
        //	console.log(item.date_);
        timestamps.push(timestamp / 1000);
        eTValues.push(item.etValue);
        rainValues.push(item.rainValue);
      });

      eTValues.push(null);
      rainValues.push(null);
      const lastTimeStamp = new Date(endReportDate).getTime();
      timestamps.push(lastTimeStamp / 1000 + 50000);
    }
    if (!eTValues.some((value) => value !== null && value !== 0)) {
      const data = [null, eTValues, rainValues];
      setFormattedData(data);
      return;
    }
    const data = [timestamps, eTValues, rainValues];
    setFormattedData(data);
  }, [evaporationAndRainData]);

  useEffect(() => {
    function handleResize() {
      setTimeout(() => {
        setPopupPadding(window.innerWidth * 0.35 + 10);
      }, 0);
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Box sx={{ display: 'block', width: '100%', mt: '16px', position: 'relative' }}>
      <Box sx={{ textAlign: 'center' }}>{t('evaporation_and_precipitation_chart_title')}</Box>
      <Box>
        {loading ? (
          <Row height={260} justifyContent="center" margin="20px auto">
            <CircularProgress disableShrink />
          </Row>
        ) : (
          <LineBarChart
            data={formattedData}
            startDate={'2021-01-01'}
            endDate={'2021-01-04'}
            popupPadding={popupPadding}
            height={300}
            chartTypes={['linear', 'bars']}
            chartColors={CHAT_COLORS}
            leftLabel={t('evaporation_line_bar_chart_label')}
            rightLabel={t('precipitation_line_bar_chart_label')}
            pointTextPosition={-6}
            showThirdScale
            yOffset={0.5}
            ySecondOffset={6}
          />
        )}
      </Box>
    </Box>
  );
};

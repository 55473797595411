import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from 'shared/hooks';
import localStorageService from 'shared/localStorage';
export const useCheckToken = () => {
  const { showError } = useToast();
  const {t} = useTranslation()
  const token = localStorageService.userToken;

  useEffect(() => {
    const invalidToken = localStorageService.invalidToken;
    if (invalidToken) {
      showError(t('your_session_has_expired_toast_text'));
      
      localStorageService.invalidToken = undefined;
    }
  }, [token]);
};


import { useTranslation } from 'react-i18next';
import { Layout } from '../../shared/ui';
import { Text } from '../../shared/ui';
export const DevTest = () => {
	const { t } = useTranslation();
	return (
		<Layout>
			<Text noWrap sx={{ fontSize: '20px', color: 'black', fontWeight: 500, pr: '2px' }}>
				Handle Leading and Trailing Spaces in Text from POEditor
			</Text>
			<Text noWrap sx={{ fontSize: '16px', color: 'black', fontWeight: 500, pr: '2px' }}>
				Lorem ipsum dolor sit, amet consectetur {t("test_trailing_spaces")} elit. Minus illum explicabo possimus! Voluptate repellendus dolorem perferendis dignissimos, minus ea sequi corrupti nesciunt hic nihil obcaecati sint magnam a cupiditate. Corporis.
			</Text>

		</Layout>
	);
};

import { TableTitle, TranslateColumn } from 'components/BaseTable';
import { PowerProblemsItem } from '../PowerProblemsReportModels';

type FilterItems = {
  value: string;
  label: string;
};

export const serviceCallsItems: FilterItems[] = [
  { value: 'Hide', label: 'hide_controllers_with_open_service_calls' },
  { value: 'Show', label: 'show_only_controllers_with_open_service_calls' },
];

export const daysPositivePItems: FilterItems[] = [
  { value: '10', label: '10%' },
  { value: '20', label: '20%' },
  { value: '30', label: '30%' },
  { value: '40', label: '40%' },
  { value: '50', label: '50%' },
  { value: '60', label: '60%' },
  { value: '70', label: '70%' },
  { value: '80', label: '80%' },
  { value: '90', label: '90%' },
  { value: '100', label: '100%' },
];

export const TABLE_TITLES: TableTitle<PowerProblemsItem>[] = [
  { title: 'date_from', key: 'datE_FROM', width: '100%', type: 'date' },
  { title: 'date_to', key: 'datE_TO', width: '100%', type: 'date' },
  { title: 'project_id', key: 'projectID', width: '100%' },
  { title: 'project_name', key: 'projectName', width: '100%' },
  { title: 'hwid', key: 'hwid', width: '100%' },
  { title: 'object_ID', key: 'objectID', width: '100%' },
  { title: 'object_name', key: 'objectName', width: '400%' },
  { title: 'device_type', key: 'deviceType', width: '100%' },
  { title: 'ctl_ver', key: 'ctlVer', width: '100%' },
  { title: 'power_sup_code', key: 'powerSupCode', width: '100%' },
  { title: 'install_date', key: 'installDate', width: '100%' },
  { title: 'last_open_service_call_date', key: 'lastOpenServiceCallDate', width: '100%', type: 'date' },
  { title: 'batt_max', key: 'batT_MAX', width: '100%' },
  { title: 'batt_min', key: 'batT_MIN', width: '100%' },
  { title: 'total_d', key: 'totaL_D', width: '100%' },
  { title: 'batt_low_d', key: 'batT_LOW_D', width: '100%' },
  { title: 'charge_time_low_d', key: 'chargE_TIME_LOW_D', width: '100%' },
  { title: 'vin_low_d', key: 'viN_LOW_D', width: '100%' },
  { title: 'no_vin_d', key: 'nO_VIN_D', width: '100%' },
];

export const TRANSLATE_COLUMNS: TranslateColumn<PowerProblemsItem>[] = [
  { key: 'powerSupCode', preKey: 'PowerSupCode_' },
];
export const DATE_COLUMN_INDEXES = [0, 1, 10, 11];
//export const DATE_COLUMN_INDEXES = [5, 6, 7, 8, 9]

import { Box } from '@mui/material';
import { BaseExportToExcel, BaseTable, CustomerSelect, PagePreloader } from 'components';
import { Filter } from 'features';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { edit } from 'shared/assets';
import { useAppSelector, useExtendedTranslation } from 'shared/hooks';
import { BaseInfo, BigTitle, Row, SearchInput, TotalRows } from 'shared/ui';
import { User } from './UserModels';
import { UserActionBtn, UserEditModal } from './components';
import { DATE_COLUMN_INDEXES, TABLE_TITLES, TRANSLATE_COLUMNS } from './constants';
import { useFilterUsers, useFormatXlsxData, useSearchUsers } from './hooks';
import { useUserManagement } from './hooks/api/';
import { getSearchedUsers } from './lib/usersHelpers';
export const UserManager = () => {
  const { formatTable } = useFormatXlsxData();
  const { t } = useExtendedTranslation();
  const { getUsers, getUserStatuses } = useUserManagement();
  const { users } = useAppSelector((st) => st.users);
  const [selectedUser, setSelectedUser] = useState<User>(null);
  const [selectedUsersID, setSelectedUsersID] = useState<number[]>([]);
  const [openUserEditModal, setOpenUserEditModal] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const {
    customerFilter,
    onChangeCustomerFilter,
    userStatusFilter,
    onChangeUserStatusFilter,
    userStatusFilterItems,
    actionsFilter,
    onChangeActionsFilter,
    actionsFilterItems,
  } = useFilterUsers();
  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([getUsers(), getUserStatuses()]);
      setLoaded(true);
    };
    fetchData();

    return () => {
      onChangeCustomerFilter('')
    };
  }, []);

  const { searchValue, changeSearch } = useSearchUsers();

  const tableUsers = useMemo(() => {
    let filteredUsers = [];
    filteredUsers = users
      .filter((c) => (userStatusFilter ? c.userStatus?.toString() === userStatusFilter : true))
      .filter((c) => (customerFilter ? c.customerID?.toString() === customerFilter : true));
    const searchedUsers = getSearchedUsers(filteredUsers, searchValue);
    return searchedUsers;
  }, [searchValue, userStatusFilterItems, userStatusFilter, customerFilter, users]);

  const handleOpenActionModal = () => {

  };
  const handleOpenUserEditModal = useCallback((row: User) => {
    setSelectedUser(row);
    setOpenUserEditModal(true);
  }, []);
  const closeUserEditModal = () => {
    setOpenUserEditModal(false);
  };
  const rowSelectionModelChange = useCallback((rows) => {
    setSelectedUsersID(rows);
  }, []);
  const actions = useMemo(
    () => [{ icon: edit, title: `${t('edit-user-grid-table-modal')}`, onClick: handleOpenUserEditModal }],
    []
  );

  const BaseExportToExcelComponent = useMemo(
    () => (<BaseExportToExcel
      tableData={tableUsers}
      sheetName="USERS"
      fileName="users.xlsx"
      buttonText={t('users_export_btn')}
      dateColumnIndexes={DATE_COLUMN_INDEXES}
      stringColumnIndexes={[11]}
      formattedTable={formatTable(tableUsers)}
      tableTitles={TABLE_TITLES}
      headerItemsPrefix={'_user_grid_table'}
    />)
    ,
    [tableUsers]
  );

  return (
    <>
      <PagePreloader loading={!loaded} />
      <Row sx={{ mb: '12px' }}>
        <BigTitle sx={{ mr: '25px' }}>{t('users_title')}</BigTitle>
      </Row>
      <SearchInput
        value={searchValue}
        onChange={changeSearch}
        placeholder={`${t('which_customer_are_you_looking_for_search_placeholder_customer_page')}`}
      />

      <Row sx={{ mt: '6px' }} flexWrap="wrap" rowGap="5px" alignItems="flex-end">
        <BigTitle sx={{ fontSize: '24px', mr: '20px' }}>{t('filter_customer_users')}</BigTitle>
        <CustomerSelect
          title={`${t('customer_label')}`}
          value={customerFilter}
          onChange={onChangeCustomerFilter}
          hideAllButton
          showHomeButton
          showOnlyResetButton
          withSearch
          sx={{ width: '260px' }}
        />
        <BigTitle sx={{ fontSize: '24px', mr: '20px' }}>{t('filter_status_users')}</BigTitle>
        {userStatusFilterItems && userStatusFilterItems.length && (
          <Filter
            title={`${t('user_status_label')}`}
            value={userStatusFilter}
            onChange={onChangeUserStatusFilter}
            items={userStatusFilterItems}
            sx={{ width: '220px' }}
            messageEmptyFilter="display_all_user_status"
            placeholder={`${t('filter_user_status_placeholder')}`}
          />
        )}

        <BigTitle sx={{ fontSize: '24px', mr: '20px', mt: '16px' }}>{t('selected_controllers_page_title')}</BigTitle>
        <BaseInfo sx={{ backgroundColor: 'white' }} text={selectedUsersID.length.toString()} />
        <Filter
          title={`${t('actions_filter')}`}
          value={actionsFilter}
          onChange={onChangeActionsFilter}
          items={actionsFilterItems}
          hideAllButton
          sx={{ width: '220px' }}
          messageEmptyFilter={`${t('actions_please_select_an_action')}`}
          placeholder={`${t('filter_search_controller_page_placeholder')}`}
        />
        <UserActionBtn actionsFilter={actionsFilter} onClick={handleOpenActionModal} selectedUsers={selectedUsersID} />
        <Row sx={{ flexGrow: 1 }} />
        <Box sx={{ display: 'flex', marginLeft: 'auto', alignItems: 'flex-end' }}>
          <TotalRows count={tableUsers.length} />

          {BaseExportToExcelComponent}
        </Box>
      </Row>

      <BaseTable
        tableTitles={TABLE_TITLES}
        tableItems={tableUsers}
        loadedItems={true}
        selectedRowID={selectedUser?.userID}
        idField={'userID'}
        noItemsText={t('no_users_sims_grid_table')}
        headerItemsPrefix={'_user_grid_table'}
        dateColumnIndexes={DATE_COLUMN_INDEXES}
        linkIndex={[2]}
        cellClick={handleOpenUserEditModal}
        checkboxSelection
        actions={actions}
        translateColumns={TRANSLATE_COLUMNS}
        onRowSelectionModelChange={rowSelectionModelChange}
        loading={!loaded}
      />
      <UserEditModal
        currentUser={selectedUser}
        openUserEditModal={openUserEditModal}
        onCloseModal={closeUserEditModal}
      />
    </>
  );
};

import { useAppDispatch } from 'shared/hooks';
import useAxios from './useAxios';
import { setModemAndModemBoardTypes } from 'shared/slices';
export const useModemAndModemBoardTypes = () => {
  const dispatch = useAppDispatch();
  const { loading, request } = useAxios();

  type Lookups = {
    modemTypes: string[];
    modemBoardTypes: string[];
  };

  return {
    getModemAndModemBoardTypes: () =>
      request<undefined, { data: Lookups }>({
        method: 'get',
        url: `/device/lookups`,
        showSuccessMessageCode: undefined,
        onDataReceiveCompleted: (data) => {
          dispatch(setModemAndModemBoardTypes(data.data));
        },
      }),
    isModemAndModemBoardTypes: loading,
  };
};

import { ControllerRegisters } from 'shared/models';


export type TableTitle = { title: string; key: keyof ControllerRegisters; width: number | string };

export const TABLE_TITLES: TableTitle[] = [
  { title: 'reg_type', key: 'regType', width: '100%' },
  { title: 'reg_number', key: 'regNumber', width: '100%' },
  { title: 'bit_number', key: 'bitNumber', width: '100%' },
  { title: 'event_id', key: 'eventID', width: '100%' },
  { title: 'description', key: 'text', width: '200%' },
  { title: 'value', key: 'value', width: '100%' },
];

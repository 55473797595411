import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { axiosBase } from 'shared/config';
import { useAppDispatch, useToast } from 'shared/hooks';
import { setCustomerStatuses } from 'shared/slices';

export const useGetCustomerStatusCodes = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { showError } = useToast();
  const push = useNavigate();

  const [isLoadingStatusCodes, setLoading] = React.useState(false);

  const getCustomerStatusCodes = async () => {
    setLoading(true);

    try {
      const { data } = await axiosBase.get('/Customer/CustomerStatusCodes');

      const updatedArray = data?.data.map((obj: { id: number; name: string; description: string }) => {
        return {
          customerStatusCode: obj.id,
          customerStatus: obj.name,
          customerStatusDescription: obj.description,
        };
      });

      dispatch(setCustomerStatuses(updatedArray));
    } catch (error: any) {
      console.log('#ERROR', error);
      if (error?.response?.status === 401) {
        push('/login');
      } else {
        showError(t('something_went_wrong_toast_text'));
      }
    } finally {
      setLoading(false);
    }
  };

  return {
    getCustomerStatusCodes,
    isLoadingStatusCodes,
  };
};

import dayjs from "dayjs";

export const convertMinToTime = (min: string | number, ) => {

    let formatedMin=min

    if(typeof formatedMin==="string") formatedMin=parseInt(formatedMin)

    const hours = Math.floor(formatedMin / 60);
    const mins = formatedMin % 60;
    return dayjs().hour(hours).minute(mins).format('HH:mm');

};
export const convertTimeToMin = (time: string ) => {
    const hourAndTime = time.split(':');
    const hours = parseInt(hourAndTime[0])
    const minutes =  parseInt(hourAndTime[1])
    return minutes + (hours*60)
};
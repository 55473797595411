import { Box } from '@mui/material';
import { t } from 'i18next';
import { FC, useEffect, useState } from 'react';
import { FormattedGraphData, getFormattedDateAndTime } from 'shared/lib';
import uPlot, { Series } from 'uplot';
import UplotReact from 'uplot-react';
import 'uplot/dist/uPlot.min.css';
interface Props {
  data: FormattedGraphData[];
  height?: number;
  showEventIds?: number[];
  startDate: string;
  endDate: string;
  popupPadding?: number;
}
import { sun, moon } from 'shared/assets';
import { useAppSelector } from 'shared/hooks';
import dayjs from 'dayjs';
const COLOR = {
  BATT_LEVEL: '#4AAFFF',
  INPUT_POWER: '#790BFA',
};

export const ComposedChart: FC<Props> = ({ data, height, showEventIds, startDate, endDate, popupPadding }) => {
  const { stepped } = uPlot.paths;
  const [startDateOnChart, setStartDateOnChart] = useState(new Date(startDate + ':00:00'));
  const [endDateOnChart, setEndDateOnChart] = useState(new Date(endDate + ':00:00'));
  const { BATT_REP } = useAppSelector((st) => st.globalSlice);
  const checkForIncorrectBatteryGraphValue = (violet) => {
    const result = violet.filter((item) => item && item != 1.6);
    return result.length ? violet : [null];
  };



  useEffect(() => {
    const date1 = new Date(startDate + 'T00:00:00');
    const date2 = new Date(endDate + 'T00:00:00');
    setStartDateOnChart(date1);
    setEndDateOnChart(date2);
  }, [data]);

  const [chartSize, setChartSize] = useState(getSize());
  useEffect(() => {
    function handleResize() {
      setTimeout(() => {
        setChartSize(getSize());
      }, 0);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  function getSize() {
    return {
      width: window.innerWidth - (popupPadding ? popupPadding : 20),
      height: height ? height : 300,
    };
  }

  const formatPoints = () => {
    const eventDate1 = [];
    const eventDate2 = [];
    const blue = [];
    const green = [];
    const red = [];
    let violet = [];
    const day = [];
    const night = [];
    const iconDay = [];
    const iconNight = [];
    const date = startDateOnChart;
    const lastDate = endDateOnChart;
    const halfOfDay = 60 * 60 * 12;
    const quarterOfDay = 60 * 60 * 6;

    let currentDateTime = date.getTime() / 1000 - quarterOfDay;

    const endDateTime = lastDate.getTime() / 1000 + halfOfDay + halfOfDay;
    const dayNightValue = 1;

    //first half of night

    eventDate1.push(currentDateTime + quarterOfDay);
    day.push(-dayNightValue);
    night.push(0);
    iconDay.push(null);
    iconNight.push(null);

    eventDate1.push(currentDateTime + halfOfDay);
    night.push(-dayNightValue);
    day.push(0);
    iconDay.push(null);
    iconNight.push(null);
    currentDateTime += halfOfDay;

    do {
      currentDateTime += halfOfDay;
      eventDate1.push(currentDateTime);
      const currentDateTimeString = new Date(currentDateTime * 1000);
      let hours = currentDateTimeString.getHours();
      //timezone fix
      if (hours == 5) {
        hours = 6
      }
      if (hours == 17) {
        hours = 18
      }

      //timezone Fix end

      if (hours >= 18) {
        night.push(0);
        day.push(-dayNightValue);
        iconDay.push(0);
        iconNight.push(0);
      } else {
        night.push(-dayNightValue);
        day.push(0);
        iconDay.push(0);
        iconNight.push(0);
      }
      eventDate1.push(currentDateTime - quarterOfDay);

      if (hours >= 18) {
        iconDay.push(-0.5);
        iconNight.push(0);
        night.push(0);
        day.push(-dayNightValue);
      } else {
        night.push(-dayNightValue);
        day.push(0);
        iconDay.push(0);
        iconNight.push(-0.5);
      }
    } while (currentDateTime < endDateTime + quarterOfDay);

    eventDate2.push(eventDate1[0]);
    green.push(null);
    red.push(null);
    blue.push(null);
    violet.push(null);

    //add firstPoints
    if (data[0]) {
      const firstSelectedDay = dayjs(startDate).format('YYYY-MM-DD');
      const firstdataDay = dayjs(data[0].eventDate).format('YYYY-MM-DD');
      if (firstSelectedDay === firstdataDay) {
        if (data[0].blue) {
          eventDate2.push(dayjs(firstdataDay).unix());
          blue.push(data[0].blue);
        }
      }
    }

    data.forEach((item, index) => {
      if (item.visualItems) {
        return;
      }
      const date = new Date(item.eventDate);
      eventDate2.push(date.getTime() / 1000);
      green.push(item.green);
      red.push(item.red);
      blue.push(item.blue);
      violet.push(item.violet);
    });
    if (data.length) {
      const lastSelectedDay = dayjs(endDate).subtract(1, 'day').format('YYYY-MM-DD');
      const lastdataDay = dayjs(data[data.length - 1].eventDate).format('YYYY-MM-DD');
      if (lastSelectedDay === lastdataDay) {
        eventDate2.push(dayjs(lastdataDay).add(1, 'day').unix());
        blue.push(blue[blue.length - 1]);
        if (violet.length) violet.push(violet[violet.length - 1]);
      }
    }
    eventDate2.push(eventDate1[eventDate1.length - 1]);
    green.push(null);
    red.push(null);
    blue.push(null);
    violet.push(null);

    violet = checkForIncorrectBatteryGraphValue(violet);
    return { eventDate1, eventDate2, blue, green, red, violet, day, night, iconDay, iconNight };
  };
  const { eventDate1, eventDate2, blue, green, red, violet, day, night, iconDay, iconNight } = formatPoints();
  const paths = (u, seriesIdx, idx0, idx1) => {
    const _stepAfter = stepped({ align: 1 }); //, ascDesc: true
    const s = u.series[seriesIdx];
    const interp = s.lineInterpolation;

    const renderer = interp == 2 ? _stepAfter : null;

    return renderer(u, seriesIdx, idx0, idx1);
  };

  function seriesPointsPlugin() {
    function drawSun(ctx, cx, cy) {
      const svg = new Image();
      svg.src = sun;
      svg.onload = function () {
        ctx.drawImage(svg, cx - 7, cy - 7, 14, 14);
      };
    }
    function drawMoon(ctx, cx, cy) {
      const svg = new Image();
      svg.src = moon;
      svg.onload = function () {
        ctx.drawImage(svg, cx - 7, cy - 7, 14, 14);
      };
    }

    function drawPointsAsStars(u, i, i0, i1) {
      const { ctx } = u;
      const { _stroke, scale } = u.series[i];

      ctx.save();

      ctx.fillStyle = _stroke;

      let j = i0;

      while (j <= i1) {
        const val = u.data[i][j];
        const cx = Math.round(u.valToPos(u.data[0][j], 'x', true));

        const cy = Math.round(u.valToPos(val, scale, true));

        if (val) {
          if (i == 3) drawSun(ctx, cx, cy);
          if (i == 4) drawMoon(ctx, cx, cy);
          ctx.fill();
        }
        j++;
      }

      ctx.restore();
    }

    return {
      opts: (u, opts) => {
        u.ctx.clearRect(0, 0, u.ctx.canvas.width, u.ctx.canvas.height);

        opts.series.forEach((s, i) => {
          if (i > 0) {
            uPlot.assign(s, {
              points: {
                show: i == 6 || i == 7 ? true : drawPointsAsStars,
              },
            });
          }
        });
      },
    };
  }

  function renderStatsPlugin({ textColor = 'blue' } = {}) {
    let startRenderTime;

    function setStartTime() {
      startRenderTime = Date.now();
    }

    function drawStats(u: uPlot) {
      const { ctx } = u;
      const { left, top, width, height } = u.bbox;
      const displayText = 'Time to Draw: ' + (Date.now() - startRenderTime) + 'ms';

      ctx.save();
      ctx.fillStyle = textColor;
      ctx.textAlign = 'left';
      ctx.textBaseline = 'top';
      ctx.fillText(displayText, left + 10, top + 10);

      const xLft = u.bbox.left;
      const xRgt = u.bbox.left + u.bbox.width;
      const yTop = u.bbox.top;
      const yBtm = u.bbox.top + u.bbox.height;

      ctx.beginPath();
      ctx.strokeStyle = '#999';
      ctx.lineWidth = 2;
      ctx.setLineDash([]);
      ctx.moveTo(xLft, yTop);
      ctx.lineTo(xLft, yBtm);
      ctx.moveTo(xRgt, yBtm);
      ctx.lineTo(xRgt, yTop);
      ctx.stroke();

      ctx.restore();
    }

    return {
      hooks: {
        drawClear: setStartTime,
        draw: drawStats,
        drawSeries: [
          (u, si) => {
            return null;
          },
        ],
      },
    };
  }

  function tooltipsPlugin(opts) {
    let cursortt;
    let seriestt;

    function init(u, opts, data) {
      const over = u.over;

      const tt = (cursortt = document.createElement('div'));
      tt.className = 'tooltip2';
      tt.style.pointerEvents = 'none';
      tt.style.position = 'absolute';
      tt.style.background = 'rgba(0,0,255,0.1)';
      over.appendChild(tt);

      seriestt = opts.series.map((s, i) => {
        if (i == 0) return;
        const tt = document.createElement('div');
        tt.className = `tooltip tooltip-${i}`;
        tt.style.pointerEvents = 'none';
        tt.style.position = 'absolute';
        tt.style.background = 'rgba(255,255,255,1)';
        tt.style.color = s.color;
        over.appendChild(tt);
        return tt;
      });

      function hideTips() {
        cursortt.style.display = 'none';
        seriestt.forEach((tt, i) => {
          if (i == 0) return;

          tt.style.display = 'none';
        });
      }

      function showTips() {
        cursortt.style.display = null;
        seriestt.forEach((tt, i) => {
          if (i == 0) return;

          const s = u.series[i];
          tt.style.display = s.show ? null : 'none';
        });
      }

      over.addEventListener('mouseleave', () => {
        if (!u.cursor._lock) {
          hideTips();
        }
      });

      over.addEventListener('mouseenter', () => {
        showTips();
      });

      if (u.cursor.left < 0) hideTips();
      else showTips();
    }

    function setCursor(u) {
      const { left, top, idx } = u.cursor;
      const { width } = chartSize;
      const tooltipWidth = 190;
      const borderWidth = 100;
      const otherSide = left + tooltipWidth + borderWidth > width ? 190 : 0;
      opts?.cursorMemo?.set(left, top);
      seriestt.forEach((tt, i) => {
        const s = u.series[i];
        if (s.show) {
          const xVal: number = u.data[0][idx];
          const yVal = u.data[i][idx];
          //if(idx<8) return
          const timeDateVal = getFormattedDateAndTime(new Date(xVal * 1000));
          if (i == 5) {
            tt.textContent = t('graph_popup_battery_status') + ' ' + (yVal ? yVal.toFixed(2) : '0.00');

            tt.style.left = left - otherSide + 'px';
            tt.style.top = top + 2 * 20 + 'px';
            tt.style.width = `${tooltipWidth}px`;
            tt.style.borderLeft = '1px solid #888';
            tt.style.borderRight = '1px solid #888';
          }
          if (i == 8) {
            tt.textContent = t('graph_popup_input_power') + ' ' + (yVal ? yVal.toFixed(2) : '0.00');

            tt.style.left = left - otherSide + 'px';
            tt.style.top = top + 3 * 20 + 'px';
            tt.style.width = `${tooltipWidth}px`;
            tt.style.borderLeft = '1px solid #888';
            tt.style.borderRight = '1px solid #888';
            tt.style.borderBottom = '1px solid #888';
          }
          if (i == 2) {
            tt.textContent = t('graph_popup_time') + ' ' + timeDateVal;

            tt.style.left = left - otherSide + 'px';
            tt.style.top = top + (i - 1) * 20 + 'px';
            tt.style.width = `${tooltipWidth}px`;
            tt.style.borderTop = '1px solid #888';
            tt.style.borderLeft = '1px solid #888';
            tt.style.borderRight = '1px solid #888';
          }
        }
      });
    }

    return {
      hooks: {
        init,
        setCursor,
      },
    };
  }
  let cursLeft = -10;
  let cursTop = -10;
  const cursorMemo = {
    set: (left, top) => {
      cursLeft = left;
      cursTop = top;
    },
    get: () => ({ left: cursLeft, top: cursTop }),
  };

  const series: any = [
    {
      label: 'date',
    },
  ];

  series.push({
    paths: paths as Series.PathBuilder,
    label: ' ',
    width: 1 / devicePixelRatio,
    drawStyle: 0,
    points: {},
    lineInterpolation: 2,
    stroke: '#f8b36e',
    fill: '#f8b36e',
  });
  series.push({
    paths: paths as Series.PathBuilder,
    label: ' ',
    width: 1 / devicePixelRatio,
    drawStyle: 0,
    points: {},
    lineInterpolation: 2,
    stroke: '#00000000',
    fill: '#78307e',
  });
  series.push({
    paths: paths as Series.PathBuilder,
    label: ' ',
    width: 0,
    drawStyle: 0,
    points: {},
    lineInterpolation: 2,
    stroke: '#ff00ff',
    fill: '#00000000',
  });
  series.push({
    paths: paths as Series.PathBuilder,
    label: ' ',
    width: 0,
    drawStyle: 0,
    lineInterpolation: 2,
    stroke: '#00ff00',
    fill: '#00000000',
  });

  series.push({
    paths: paths as Series.PathBuilder,
    label: t('graph_battery_status_report_current_Battery_Level'),
    width: 2 / devicePixelRatio,
    drawStyle: 0,
    lineInterpolation: 2,
    stroke: COLOR.BATT_LEVEL,
    fill: '#00000000',
    scale: 'battery_status',
  });
  series.push({
    paths: (u) => null,
    points: {
      space: 0,
      fill: 'green',
    },
    label: t('graph_battery_status_report_communication_restored'),
    width: 2 / devicePixelRatio,
    stroke: 'green',
    fill: 'green',
    drawStyle: 0,
    scale: 'battery_status',
  });
  series.push({
    paths: (u) => null,
    points: {
      space: 0,
      fill: 'red',
    },
    label: t('graph_battery_status_report_communication_disrupted'),
    width: 2 / devicePixelRatio,
    stroke: 'red',
    fill: 'red',
    scale: 'battery_status',
  });
  if (showEventIds && showEventIds.includes(1702)) {
    series.push({
      paths: paths as Series.PathBuilder,
      label: t('graph_input_power_status_report_current_Battery_Level'),
      width: 2 / devicePixelRatio,
      drawStyle: 0,
      dash: [2, 2],
      lineInterpolation: 2,
      stroke: COLOR.INPUT_POWER,
      fill: '#00000000',
      scale: 'power_status',
    });
  }

  const options: any = {
    ...chartSize,
    scales: {
      y: {
        range: (self: any, min: number, max: number) => {
          return [-1, 14];
        },
      },
      x: {
        range: (u, min, max) => [eventDate2[0], eventDate2[eventDate2.length - 1]],
      },
      battery_status: {
        range: (self: any, min: number, max: number) => {
          const vVatMax = BATT_REP.GRAPH_VBAT_MAX_SCALE ? BATT_REP.GRAPH_VBAT_MAX_SCALE - 1 : max + 1;
          const vVatMaxWithoutData = BATT_REP.GRAPH_VBAT_MAX_SCALE ? BATT_REP.GRAPH_VBAT_MAX_SCALE : 8;
          return data.length ? [-(vVatMax / 10), vVatMax + 1] : [-1, vVatMaxWithoutData];
        },
      },
      power_status: {
        range: (self: any, min: number, max: number) => {
          const vVinMax = BATT_REP.GRAPH_VIN_MAX_SCALE ? BATT_REP.GRAPH_VIN_MAX_SCALE - 1 : max + 1;
          const vVinMaxWithoutData = BATT_REP.GRAPH_VIN_MAX_SCALE ? BATT_REP.GRAPH_VIN_MAX_SCALE : 8;

          return data.length ? [-(vVinMax / 10), vVinMax + 1] : [-1, vVinMaxWithoutData];
        },
      },
    },
    legend: {
      show: false,
      live: false,
      markers: {
        show: false,
      },
    },
    cursor: {
      points: {
        fill: (u, sidx) => {
          return u.series[sidx].stroke();
        },
        stroke: () => {
          return '#ffffff';
        },
        width: () => {
          return 2;
        },
        size: (u, sidx) => {
          return u.series[sidx].points.size * 2;
        },
      },
    },

    tzDate: (ts) => {
      return uPlot.tzDate(new Date(ts * 1e3), 'Etc/UTC');
    },
    fmtDate: (tpl) => {
      return uPlot.fmtDate('{DD}.{MM}.{YYYY}');
    },

    series,
    axes: [
      {
        space: 100,
        grid: { show: false },
      },
      {
        scale: 'battery_status',
        label: t('graph_battery_status_report_current_Battery_Level'),
        labelFont: '18px Noto Sans Hebrew',
        stroke: COLOR.BATT_LEVEL,
        space: () => {
          const size = BATT_REP.GRAPH_VBAT_MAX_SCALE ? BATT_REP.GRAPH_VBAT_MAX_SCALE : 7;
          return chartSize.height / (size * 2);
        },
      },
      {
        scale: 'power_status',
        label: t('graph_input_power_status_report_current_Battery_Level'),
        labelFont: '18px Noto Sans Hebrew',
        stroke: COLOR.INPUT_POWER,
        side: 1,
        grid: { show: false },
      },
    ],

    plugins: [
      tooltipsPlugin({
        cursorMemo,
      }),
      seriesPointsPlugin(),
      renderStatsPlugin({ textColor: 'white' }),
    ],
  };
  const options2 = { ...options };
  options2.axes = [
    {
      space: 100,
      grid: { show: false },
    },
    {
      scale: 'battery_status',
      stroke: '#00000000',
      label: t('graph_battery_status_report_current_Battery_Level'),
      labelFont: '18px Noto Sans Hebrew',
      grid: { show: false },
      ticks: {
        show: false,
      },
    },
    {
      scale: 'power_status',
      side: 1,
      stroke: '#00000000',
      label: t('graph_input_power_status_report_current_Battery_Level'),
      labelFont: '18px Noto Sans Hebrew',
      grid: { show: false },
      ticks: {
        show: false,
      },
    },
  ];

  const data1: uPlot.AlignedData = [eventDate2, [], [], [], [], blue, green, red, violet];
  const data2: uPlot.AlignedData = [eventDate1, night, day, iconDay, iconNight, [], [], [], []];
  return (
    <Box sx={{ padding: ' 0', position: 'relative', margin: 'auto' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          width: '100%',
          '.tooltip': {
            paddingLeft: '20px',
          },
          '.tooltip:before': {
            content: '""',
            display: 'inline-block',
            position: 'absolute',
            left: '5px',
            top: '10px',
            width: '10px',
            height: '10px',
            borderRadius: '50%',
          },
          '.tooltip-5:before': {
            background: COLOR.BATT_LEVEL,
          },
          '.tooltip-8:before': {
            background: COLOR.INPUT_POWER,
          },
        }}
      >
        {/* <Box sx={{  maxHeight:'5px', width:'140px', whiteSpace: 'nowrap'}}>
      <Box sx={{ alignItems:'center', justifyContent:'center', transform: `rotate(90deg)`, display:'flex', position:'absolute', left:-60}}>
        <Box sx={{height:"16px", width:"16px", background:COLOR.BATT_LEVEL, mr:"4px"}}></Box>
        <span>{t('graph_battery_status_report_current_Battery_Level')}</span>
        </Box>
      </Box> */}
        <Box>
          {eventDate1 && !!eventDate1.length && (
            <Box sx={{ position: 'relative' }}>
              <Box sx={{ position: 'absolute', top: '0px', opacity: '1' }}>
                <UplotReact options={options2} data={data2} onCreate={(chart) => { }} onDelete={(chart) => { }} />
              </Box>
              <Box sx={{ opacity: '1' }}>
                <UplotReact options={options} data={data1} onCreate={(chart) => { }} onDelete={(chart) => { }} />
              </Box>
            </Box>
          )}
        </Box>
        {/* <Box sx={{ maxHeight:'5px', whiteSpace: 'nowrap', display:'flex'}}>
      <Box sx={{ alignItems:'center',width:'140px', justifyContent:'center', transform: `rotate(90deg)`, display:'flex', position:'absolute', right:-80}}>
        <Box sx={{height:"16px", width:"16px", background:COLOR.INPUT_POWER , mr:"4px"}}></Box>
        <span> {t('graph_input_power_status_report_current_Battery_Level')}</span>
        </Box>
      </Box> */}
      </Box>
    </Box>
  );
};
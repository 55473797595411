import { Box, TextField, TextFieldProps, Typography, useTheme } from '@mui/material';
import { forwardRef, useEffect, useState } from 'react';
import { MenuList, Row, BaseBtn, Text, NumberSelect } from 'shared/ui';
import { useTranslation } from 'react-i18next';
import { autoFillColor } from 'shared/config';
import { isHebrewFont } from 'shared/lib';
import Popover from '@mui/material/Popover';
import { top_chevron } from 'shared/assets/';

type Props = TextFieldProps & {
  label?: string;
  startTimeLabel: string;
  endTimeLabel: string;
  minHour: number;
  maxHour: number;
  value: string;
  onChanged: (value: string) => void;
  selectableMinutes?: boolean;
  fullWidth?: boolean;
  marginRight?: string;
};

export const BaseTimePicker = forwardRef<unknown, Props>(
  (
    { label, startTimeLabel, endTimeLabel, minHour, maxHour, value, onChanged, selectableMinutes, fullWidth, marginRight, ...props },
    ref
  ) => {
    const [isOpenTimePicker, setOpenTimePicker] = useState(false);
    const { palette, direction } = useTheme();
    const [startHour, setStartHour] = useState<number>(minHour);
    const [endHour, setEndHour] = useState<number>(maxHour);
    const [startMin, setStartMin] = useState<number>(0);
    const [endMin, setEndMin] = useState<number>(0);

    const isHebrew = isHebrewFont(value.toString() ?? '');
    const isRtl = direction === 'rtl';
    const { t } = useTranslation();
    useEffect(() => {
      if (endHour === startHour && startMin > endMin) {
        if (startMin === 59) {
          setEndMin(startMin);
          setStartMin(startMin - 1);
          return;
        }
        setEndMin(startMin + 1);
      }
    }, [endHour, startHour]);
    const filteredTime = (startHour: number, startMin: number, endHour: number, endMin: number) => {
      let sMin = `${startMin}`;
      let eMin = `${endMin}`;
      if (startMin < 10) {
        sMin = `0${startMin}`;
      }
      if (endMin < 10) {
        eMin = `0${endMin}`;
      }

      if (startHour < 10 && endHour < 10) {
        return `0${startHour}:${sMin}-0${endHour}:${eMin}`;
      }
      if (startHour < 10) {
        return `0${startHour}:${sMin}-${endHour}:${eMin}`;
      }
      if (endHour < 10) {
        return `${startHour}:${sMin}-0${endHour}:${eMin}`;
      } else {
        return `${startHour}:${sMin}-${endHour}:${eMin}`;
      }
    };

    const onOpenMenu = (event: any) => {
      setAnchorEl(event.target);
      setOpenTimePicker(true);
    };
    const handleClose = () => {
      setAnchorEl(null);
      setOpenTimePicker(false);
    };
    const handleApply = () => {
      setAnchorEl(null);
      setOpenTimePicker(false);
      onChanged(filteredTime(Number(startHour), Number(startMin), Number(endHour), Number(endMin)));

      //  startHour endHour
    };
    const getMinMinutesForEnd = () => {
      if (startHour == endHour) {
        return startMin;
      }
      return 0;
    };
    const getMaxMinutesForStart = () => {
      if (startHour == endHour) {
        return endMin;
      }
      return 59;
    };
    useEffect(() => {
      const times = value.split('-');
      if (times[0] === '' && times[1] === '') {
        setStartHour(minHour);
        setEndHour(maxHour);
        return;
      }
      const startHourAndMin = times[0].split(':');
      const endHourAndMin = times[1].split(':');
      setStartHour(parseInt(startHourAndMin[0]));
      setEndHour(parseInt(endHourAndMin[0]));
      setStartMin(parseInt(startHourAndMin[1]));
      setEndMin(parseInt(endHourAndMin[1]));
    }, [value]);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    return (
      <Box sx={{ mr: marginRight ? marginRight : fullWidth ? '10px' : '0px', width: fullWidth ? '100%' : 'auto' }}>
        {label && (
          <Typography
            noWrap
            sx={{
              fontSize: '12px',
              fontWeight: 600,
              color: palette.info.dark,
            }}
          >
            {label}
          </Typography>
        )}
        <TextField
          onClick={onOpenMenu}
          fullWidth
          autoComplete="none"
          value={value}
          sx={{
            background: palette.info.main,
            borderRadius: '12px',
            color: 'black',

            '& .MuiOutlinedInput-root': {
              '& > fieldset': { border: `1.5px solid ${palette.primary.dark}` },
              '&.Mui-disabled > fieldset': { border: `1.5px solid ${palette.primary.dark}` },
              '&.Mui-disabled': { bgcolor: 'info.contrastText' },
            },
            '& .Mui-disabled': {
              WebkitTextFillColor: 'info.dark',
            },
            '& .MuiFormHelperText-root': {
              lineHeight: '12px',
              position: 'absolute',
              bottom: '-15px',
              color: 'error.main',
              fontSize: '12px',
              fontWeight: 500,
              fontFamily: 'Noto Sans Hebrew',
              ml: '5px',
            },
            'input, textarea': {
              direction: isRtl ? (isHebrew ? 'ltr' : 'rtl') : isHebrew ? 'rtl' : 'ltr',
              fontFamily: 'Noto Sans Hebrew',
              ...autoFillColor,
              caretColor: 'transparent',
            },
          }}
          inputProps={{ min: 0 }}
          InputProps={{
            sx: {
              height: '29px',
              borderRadius: '12px',
              borderColor: palette.error.main,
              color: palette.primary.dark,
            },
          }}
          inputRef={ref}
          color="primary"
          {...props}
        />

        <Popover
          open={isOpenTimePicker}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          sx={{ direction: isRtl ? (isHebrew ? 'ltr' : 'rtl') : isHebrew ? 'rtl' : 'ltr' }}
        >
          <Box sx={{ p: 2 }}>
            <Row sx={{ marginBottom: '22px' }}>
              <Box sx={{ width: '100%', padding: '10px' }}>
                <Box sx={{ marginBottom: '8px', whiteSpace: 'nowrap' }}>{startTimeLabel}</Box>
                <Box
                  sx={{
                    background: palette.info.contrastText,
                    borderRadius: '12px',
                    padding: '5px',
                  }}
                >
                  <Box sx={{ width: '125px', margin: 'auto' }}>
                    <Row spacing="24px">
                      <NumberSelect
                        min={minHour}
                        max={endHour - (selectableMinutes ? 0 : 1)}
                        value={startHour}
                        onChange={setStartHour}
                      />
                      <Text
                        sx={{
                          fontSize: '18px',
                          fontWeight: 400,
                          color: 'black',
                          textAlign: 'center',
                          cursor: 'default',
                        }}
                      >
                        :
                      </Text>
                      <NumberSelect
                        min={0}
                        max={getMaxMinutesForStart()}
                        value={startMin}
                        disabled={!selectableMinutes}
                        onChange={setStartMin}
                      />
                    </Row>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ width: '100%', padding: '10px' }}>
                <Box sx={{ marginBottom: '8px', whiteSpace: 'nowrap' }}>{endTimeLabel}</Box>
                <Box
                  sx={{
                    background: palette.info.contrastText,
                    borderRadius: '12px',
                    padding: '5px',
                  }}
                >
                  <Box sx={{ width: '125px', margin: 'auto' }}>
                    <Row spacing="24px">
                      <NumberSelect
                        min={startHour + (selectableMinutes ? 0 : 1)}
                        max={maxHour}
                        value={endHour}
                        onChange={setEndHour}
                      />
                      <Text
                        sx={{
                          fontSize: '18px',
                          fontWeight: 400,
                          color: 'black',
                          textAlign: 'center',
                          cursor: 'default',
                        }}
                      >
                        :
                      </Text>
                      <NumberSelect
                        min={getMinMinutesForEnd()}
                        max={59}
                        value={endMin}
                        disabled={!selectableMinutes}
                        onChange={setEndMin}
                      />
                    </Row>
                  </Box>
                </Box>
              </Box>
            </Row>
            <Row sx={{ m: '5px 0px 2px 0px', minWidth: '500px' }}>
              <BaseBtn sx={{ margin: '0 10px' }} onClick={handleClose} btnColor="info.main" fullWidth>
                {t('cancel_date_filter')}
              </BaseBtn>
              <BaseBtn sx={{ margin: '0 10px' }} onClick={handleApply} btnColor="primary.dark" fullWidth>
                {t('apply_date_filter')}
              </BaseBtn>
            </Row>
          </Box>
        </Popover>
      </Box>
    );
  }
);

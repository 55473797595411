import { Box } from '@mui/material';
import { ValvesReportView } from 'features/ControllerManager/controllerModels';
import { t } from 'i18next';
import { FC, useMemo, useState } from 'react';
import localStorageService from 'shared/localStorage';
import { BaseBtn } from 'shared/ui';
import 'uplot/dist/uPlot.min.css';
import { TimeLineChart } from './components/TimeLineChart';

interface Props {
  data: ValvesReportView[];
  userActionsEventIDs: number[];
  height?: number;
  startDate: string;
  endDate: string;
  popupPadding?: number;
  deviceType: string;
}

export const IrrigationChart: FC<Props> = ({
  data,
  height,
  startDate,
  endDate,
  popupPadding,
  deviceType,
  userActionsEventIDs,
}) => {
  const { isRTL } = localStorageService;
  const [isScalable, setIsScalable] = useState(false);
  const [isResetZoom, setIsResetZoom] = useState(false);
  const resetZoom = () => {
    setIsResetZoom(true);
  };

  const isChangedZoom = (status: boolean) => {
    setIsScalable(status);
    if (!status) {
      setIsResetZoom(false);
    }
  };

  const MemoizedLineChart = useMemo(
    () => (
      <TimeLineChart
        allData={data}
        userActionsEventIDs={userActionsEventIDs}
        height={height}
        startDate={startDate}
        endDate={endDate}
        popupPadding={popupPadding}
        isChangedZoom={isChangedZoom}
        isResetZoom={isResetZoom}
        deviceType={deviceType}
      ></TimeLineChart>
    ),
    [data, isResetZoom]
  );

  return (
    <Box sx={{ padding: ' 0', position: 'relative', margin: 'auto' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          width: '100%',
          '.tooltip': {
            paddingLeft: '20px',
          },
          '.tooltip:before': {
            content: '""',
            display: 'inline-block',
            position: 'absolute',
            left: '5px',
            top: '10px',
            width: '10px',
            height: '10px',
            borderRadius: '50%',
          },
          '.tooltip-5:before': {
            background: 'red',
          },
          '.tooltip-8:before': {
            background: 'green',
          },
          '.u-cursor-pt': {
            //borderRadius: '0 !important'
            opacity: '0',
          },
          '.u-over': {
            overflow: 'hidden',
            borderBottom: '1px solid #666',
            borderLeft: '1px solid #666',
            borderRight: '1px solid #666',
          },
          '.u-cursor-x, .u-cursor-y': {
            borderRight: '2px dashed rgb(90, 147, 211)',
            borderLeft: '2px dashed rgb(90, 147, 211)',
          },
        }}
      >
        <Box>
          <Box>
            <Box>
              <BaseBtn
                sx={{
                  height: '29px',
                  position: 'absolute',
                  right: isRTL ? 'auto' : '0',
                  left: isRTL ? '0' : ' auto',
                  zIndex: '20',
                  top: '10px',
                  display: isScalable ? 'flex' : 'none',
                }}
                btnColor="primary.dark"
                onClick={resetZoom}
              >
                {t('reset_graph_zoom')}
              </BaseBtn>
              {MemoizedLineChart}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

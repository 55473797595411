
export type TableTitle = { title: string; key:  string; width: number | string };

export const TABLE_TITLES: TableTitle[] = [
  { title: 'date_from', key: 'DateFrom', width: '100%' },
  { title: 'date_to', key:'DateTo', width: '100%' },
  { title: 'customer_id', key: 'CustomerID', width: '50%' },
  { title: 'customer_name', key: 'CustomerName', width: '200%' },
 
];

import { Box, TypographyProps } from '@mui/material';
import { FC } from 'react';
import { Icon } from '../Icon';
import { wa_message } from 'shared/assets';
import { relative } from 'path';
import localStorageService from 'shared/localStorage';

export const WaMessageBackground: FC<TypographyProps> = ({ children, ...props }) => {
  const isRTL = localStorageService.isRTL;
  return (
    <Box
      {...props}
      sx={{
        position: 'relative',
        margin: '16px 0',

        backgroundColor: '#51B470',
        borderRadius: '20px',
      }}
    >
      <Box sx={{ zIndex: '1', minHeight: '80px', position: 'relative', margin: '16px', color: 'white' }}>
        {children}
      </Box>
    </Box>
  );
};

import { FC } from 'react';
import { Box } from '@mui/material';
import { Loader } from 'shared/ui';


interface Props {
  loading:boolean;
}


export const PagePreloader: FC<Props> = ({loading}) => {

  return (
    <>
     {loading ? <Box
        sx={{
          width: '100%',
          height: '100%',
          background: '#ffffff',
          zIndex: '30',
          position: 'absolute',
          top: '0px',
          opacity: '0.7',
          left: '0',
        }}
      >
    <Loader />
  </Box> : <></>}
  </>
  );
};



import { Checkbox, CheckboxProps } from '@mui/material';
import { FC } from 'react';
import { checked_box, un_checked_box } from 'shared/assets';
import { Icon, Row, Text } from 'shared/ui';
interface Props extends CheckboxProps {
	label: string
	value: boolean
	labelColor?: string
	marginTop?: string
	disabled?: boolean
}

export const BaseCheckbox: FC<Props> = ({ label, value, labelColor, marginTop, disabled, ...props }) => {
	return (
		<Row spacing="0px" sx={{ opacity: disabled ? 0.5 : 1 }} mt={marginTop ? marginTop : "10px"} alignItems="center" >
			<Checkbox
				size="small"
				icon={<Icon src={un_checked_box} />}
				checked={value}
				checkedIcon={<Icon src={checked_box} />}
				{...props}
			></Checkbox>
			<Text
				noWrap
				sx={{
					color: labelColor ? labelColor : 'primary.dark',
					fontSize: '14px',
					fontWeight: 500,
					textAlign: 'left',
					fontFamily: 'Noto Sans Hebrew !@important',
				}}
			>{label}</Text></Row>
	);
};

import { BatteryStatusExcludeFilter } from 'shared/models';
import { useAppSelector } from 'shared/hooks';
export const useGetDefaultParams = () => {
  const { BATT_REP } = useAppSelector((st) => st.globalSlice);
  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return year + '-' + month + '-' + day;
  };
  const today = new Date();
	today.setDate(1);
	today.setHours(-1);
  const endDate = formatDate(today);
	today.setDate(1);
  const  startDate= formatDate(today);



  // const saveToLocalStorage = (filter: BatteryStatusExcludeFilter) => {
  //   localStorage.setItem('battery_status_report', JSON.stringify(filter));
  // };
  // const getDataFromLocalStorage = () => {
  //   const data = localStorage.getItem('battery_status_report');
  //   return data ? JSON.parse(data) : null;
  // };
  //const localData = getDataFromLocalStorage();

  // const dayTimeStart: string = localData ? localData.dayTimeStart : BATT_REP.DAY_TIME_START;
  // const dayTimeEnd: string = localData ? localData.dayTimeEnd : BATT_REP.DAY_TIME_END;
  // const nightTimeStart: string = localData ? localData.nightTimeStart : BATT_REP.NIGHT_TIME_START;
  // const nightTimeEnd: string = localData ? localData.nightTimeEnd : BATT_REP.NIGHT_TIME_END;
  // const connectHMin: string = localData ? localData.connectHMin : BATT_REP.MIN_CONNECTION_TIME.toString();
  // const daysPositiveP: string = localData
  //   ? localData.daysPositiveP
  //   : BATT_REP.DISCONNECTED_DAYS_IN_THE_PERIOD.toString();

  return {
    startDate,
    endDate,
    // dayTimeStart,
    // dayTimeEnd,
    // nightTimeStart,
    // nightTimeEnd,
    // connectHMin,
    // daysPositiveP,
    // saveToLocalStorage,
  };
};

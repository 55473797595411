import clients from './clients.svg';
import inventory from './inventory.svg';
import users from './users.svg';
import configuration from './configuration.svg';
import reports from './reports.svg'
import maintenance from './maintenance.svg'
import dashboard from './dashboard.svg'
import experimental from './experimental.svg'
import  customers  from './customers.svg';

export const navIcons = [dashboard, customers, inventory,clients , maintenance, reports,  experimental ,configuration];

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { axiosBase } from 'shared/config';
import { useAppDispatch, useToast } from 'shared/hooks';
import {
  setSims,
  setSimStatuses,
  setSimProviders,
  setSimOperators,
  setSimImportResult,
  toggleCreateProviderModal,
  setSimsLoading,
} from 'shared/slices';
import { setCurrentSim, toggleEditSim, updateSim } from 'shared/slices';
import { Sim } from 'shared/models';
export const useSimManagement = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { showError, showSuccess } = useToast();
  const push = useNavigate();
  const [isLoadingSim, setLoadingSim] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [isUploadingSim, setIsUploadingSim] = React.useState(false);

  // const [isLoadingGraphBatteryStatusReport, setGraphLoading] = React.useState(false);

  const getSim = async () => {
    setLoading((pref) => true);
    try {
      const { data } = await axiosBase.get('/sim');
      dispatch(setSims(data.data));
    } catch (error: any) {
      console.log('#ERROR', error?.response?.status);
      if (error?.response?.status === 401) {
        push('/login');
      } else {
        showError(t('something_went_wrong_toast_text'));
      }
    } finally {
      setLoadingSim(false);
      dispatch(setSimsLoading(false));
    }
  };
  const getStatuses = async () => {
    setLoading(true);
    try {
      const { data } = await axiosBase.get('/sim/status');

      dispatch(setSimStatuses(data.data));
    } catch (error: any) {
      console.log('#ERROR', error?.response?.status);
      if (error?.response?.status === 401) {
        push('/login');
      } else {
        showError(t('something_went_wrong_toast_text'));
      }
    }
    setLoading(false);
  };
  const getProvider = async () => {
    setLoading(true);
    try {
      const { data } = await axiosBase.get('/sim/provider');

      dispatch(setSimProviders(data.data));
    } catch (error: any) {
      console.log('#ERROR', error?.response?.status);
      if (error?.response?.status === 401) {
        push('/login');
      } else {
        showError(t('something_went_wrong_toast_text'));
      }
    }
    setLoading(false);
  };
  const getOperator = async () => {
    setLoading(true);
    try {
      const { data } = await axiosBase.get('/sim/operator');

      dispatch(setSimOperators(data.data));
    } catch (error: any) {
      console.log('#ERROR', error?.response?.status);
      if (error?.response?.status === 401) {
        push('/login');
      } else {
        showError(t('something_went_wrong_toast_text'));
      }
    }
    setLoading(false);
  };
  const addOperator = async (operatorName: string) => {
    setLoading(true);
    const id = Math.round(Math.random() * 1000);
    const operatorData = {
      operatorID: id,
      operatorCode: id.toString(),
      operatorName,
    };
    try {
      await axiosBase.post('sim/operator', operatorData);
      await getOperator();
      showSuccess(t('sim_successfully_operator_saved_toast_text'));
      dispatch(toggleCreateProviderModal());
    } catch (error: any) {
      console.log('#ERROR', error);
      if (error?.response?.status === 401) {
        push('/login');
      } else {
        showError(t('error_toast_text'));
      }
    } finally {
      setLoading(false);
    }
  };
  const editSim = async (simDto: Sim) => {
    setLoading(true);

    try {
      const { data } = await axiosBase.put('/sim', simDto);

      const sim: Sim = data?.data;

      dispatch(setCurrentSim(sim));
      dispatch(updateSim(sim));
      showSuccess(t('sim_successfully_updated_toast_text'));
      dispatch(toggleEditSim());
    } catch (error: any) {
      console.log('#ERROR', error);
      if (error?.response?.status === 401) {
        push('/login');
      } else {
        showError(t('error_toast_text'));
      }
    } finally {
      setLoading(false);
    }
  };

  const saveSimsFromExcel = async (simsData: { connectionId: string; data: any; simOperator: string }) => {
    setLoading(true);
    setIsUploadingSim(true);
    try {
      const { data } = await axiosBase.post('sim/import', simsData);
      dispatch(setSimImportResult(data.data));
      setLoadingSim(true);
      dispatch(setSimsLoading(true));
      getSim();
      setIsUploadingSim(false);
    } catch (error: any) {
      console.log('#ERROR', error);
      if (error?.response?.status === 401) {
        push('/login');
      } else {
        showError(t('error_toast_text'));
      }
    } finally {
      setLoading(false);
    }
  };
  return {
    getStatuses,
    getProvider,
    getOperator,
    getSim,
    editSim,
    isLoadingSim,
    isLoading,
    isUploadingSim,
    saveSimsFromExcel,
    addOperator,
  };
};

import { TextField, InputProps, InputAdornment, Box } from '@mui/material';
import { FC, useEffect, useRef } from 'react';
import { search } from './assets';
import { autoFillColor } from 'shared/config';
import { isHebrewFont } from 'shared/lib';

interface Props extends InputProps {
  noBorder?: boolean;
  onfocus?: boolean;
}

export const SearchInput: FC<Props> = ({ noBorder, onfocus, sx, placeholder, name, type, value, onChange }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (onfocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <>
      <input name={name} style={{ display: 'none' }} />
      <TextField
        inputRef={inputRef}
        value={value}
        onChange={onChange}
        type={type}
        fullWidth
        placeholder={placeholder}
        autoComplete={'off'}
        sx={{
          '& .MuiInputBase-root': {
            border: noBorder ? 'none' : '1px solid blue',
            borderColor: 'primary.main',
            borderRadius: '10px',
            pl: '18px',
            height: '49px',
            fontFamily: 'Inter',
            background: 'white',
          },
          fieldset: {
            border: 'none !important',
          },
          input: {
            fontFamily: isHebrewFont(value?.toString() ?? '') ? 'Noto Sans Hebrew' : 'Inter',

            ...autoFillColor,
          },
          'input::placeholder': {
            fontFamily: isHebrewFont(placeholder ?? '') ? 'Noto Sans Hebrew' : 'Inter',
          },
          ...sx,
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Box component="img" src={search} />
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  EquipmentReplacementReportItem,
  EquipmentType
} from 'shared/models';

interface EquipmentReplacementReportState {
  equipmentReplacementReportItems: EquipmentReplacementReportItem[];
  equipmentTypes: EquipmentType[];
}

const initialState: EquipmentReplacementReportState = {
  equipmentReplacementReportItems: [],
  equipmentTypes: [{
    id:1,
    name:'test-1'
  },
  {
    id:2,
    name:'test-2'
  }
]
};

const equipmentReplacementReportSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    clearEquipmentReplacementReportItems: (state) => {
      state.equipmentReplacementReportItems = [];
    },
    addEquipmentReplacementReportItems: (state, action: PayloadAction<EquipmentReplacementReportItem[]>) => {
      state.equipmentReplacementReportItems.push(...action.payload);
    },
    setEquipmentTypes: (state, action: PayloadAction<EquipmentType[]>) => {
      state.equipmentTypes = action.payload;
    },
  },
});

export const {
  clearEquipmentReplacementReportItems,
  setEquipmentTypes,
  addEquipmentReplacementReportItems
} = equipmentReplacementReportSlice.actions;

export const equipmentReplacementReportReducer = equipmentReplacementReportSlice.reducer;

import { Box, IconButton, Radio, Stack } from '@mui/material';
import { AssignedCustomer } from 'features/WeatherStationsManager/WeatherStationModels';
import { t } from 'i18next';
import { FC, useEffect, useState } from 'react';
import { close_popup } from 'shared/assets';
import { useAppSelector } from 'shared/hooks';
import { BaseBtn, BigTitle, Modal, Row } from 'shared/ui';


interface Props {
	openModal: boolean;
	onCloseModal: () => void;
	update: (selectedMethod: "option 1" | "option 2") => void
	loading: boolean;
}

export const UpdateMaxEvaporationRates: FC<Props> = ({
	openModal,
	onCloseModal,
	update,
	loading
}) => {
	const { WEATHER_STATIONS } = useAppSelector((st) => st.globalSlice);
	const [selectedMethod, setSelectedMethod] = useState<"option 1" | "option 2">(null);
	const handleCloseModal = () => {
		setSelectedMethod(null)
		onCloseModal()
	}
	useEffect(() => {
		if (!openModal) {
			setSelectedMethod(null)
		}
	}, [openModal]);


	return (
		<Modal open={openModal} onClose={handleCloseModal}
			padding={'8px 16px'}>
			<Box>
				<Stack direction="row" justifyContent="space-between" alignItems="center">
					<BigTitle>{t('update_max_evaporation_rates_modal')}</BigTitle>
					<IconButton onClick={handleCloseModal}>
						<img src={close_popup} />
					</IconButton>
				</Stack>


				<Row onClick={() => setSelectedMethod('option 1')} sx={{ cursor: 'pointer', justifyContent: "space-between" }}>
					<Row >

						<Radio checked={selectedMethod === 'option 1'} size="small" />
						<span>{t('option_1_for_update_button_max_evaporation_rates_modal')}</span>
						<span>{WEATHER_STATIONS.DAYS_FOR_UPDATE_MAX_EVAPORATION_RATES} </span>
						<span>{t('days_for_update_button_max_evaporation_rates_modal')}</span>
					</Row>
				</Row>
				<Row onClick={() => setSelectedMethod('option 2')} sx={{ cursor: 'pointer', justifyContent: "space-between" }}>
					<Row >
						<Radio checked={selectedMethod === 'option 2'} size="small" />
						{t('option_2_for_update_button_max_evaporation_rates_modal')}
					</Row>
				</Row>

				{/* Save the new evaporation rate and update the irrigation factor for the past 30 days.
				Save the new value for future use only, without retroactive updates. */}

				<Row justifyContent="center">
					<Row width={300} gap="10px">
						<BaseBtn onClick={handleCloseModal} btnColor="info.main" fullWidth>
							{t('cancel_update_max_evaporation_rates_modal')}
						</BaseBtn>
						<BaseBtn loading={loading} disabled={!selectedMethod} type="submit" onClick={() => update(selectedMethod)} btnColor="primary.dark" fullWidth>
							{t('update_update_max_evaporation_rates_modal')}
						</BaseBtn>
					</Row>
				</Row>
			</Box>
		</Modal >
	);
};

import { TableTitle, TranslateColumn } from 'components/BaseTable';
import { User } from '../UserModels';
type AccessLevelItems = {
  0?: string
  1?: string
  5?: string
  10?: string
  15?: string
};

export const accessLevelItems: AccessLevelItems =
{
  '0': 'access_level_no_access',
  '1': 'access_level_operator',
  '5': 'access_level_customer_manager',
  '10': 'access_level_system_manager',
  '15': 'access_level_administrator'
}

export const TABLE_TITLES: TableTitle<User>[] = [
  { title: 'customer_ID', key: 'customerID', width: '100%' },
  { title: 'user_ID', key: 'userID', width: '100%' },
  { title: 'user_name', key: 'userName', width: '200%' },
  { title: 'user_login', key: 'userLogin', width: '150%' },
  { title: 'user_status', key: 'userStatus', width: '100%' },
  { title: 'last_login_date', key: 'lastLoginDate', width: '100%', type: 'date' },
  { title: 'last_month_login_count', key: 'lastMonthLoginCount', width: '100%' },
  { title: 'last_password_self_update', key: 'lastPasswordSelfUpdate', width: '100%' },
  { title: 'last_password_team_update', key: 'lastPasswordTeamUpdate', width: '100%' },
  { title: 'next_password_update', key: 'nextPasswordUpdate', width: '100%' },
  { title: 'e_mail_address', key: 'eMailAddress', width: '100%' },
  { title: 'phone_number', key: 'phoneNumber', width: '100%' },
  { title: 'user_role_description', key: 'userRoleDescription', width: '100%' },
  { title: 'notes', key: 'notes', width: '100%' },
  { title: 'back_office_access_level', key: 'backOfficeAccessLevel', width: '100%' },
  { title: 'foreign_projects_count', key: 'projectsCount', width: '100%' },
  { title: 'foreign_project_access', key: 'foreignProjectAccess', width: '100%' },
];
export const TRANSLATE_COLUMNS: TranslateColumn<User>[] = [
  { key: 'userStatus', additionalKey: '' },
  { key: 'foreignProjectAccess', additionalKey: '_access_level' },
  { key: 'backOfficeAccessLevel', translationMap: accessLevelItems },

]

export const DATE_COLUMN_INDEXES = [5, 7, 8, 9]
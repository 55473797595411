import { Box, IconButton, Radio, Stack } from '@mui/material';
import { BaseTable } from 'components';
import { User, UserAccountsItem } from 'features/UserManager/UserModels';
import { t } from 'i18next';
import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { close_popup } from 'shared/assets';
import { useAppSelector } from 'shared/hooks';
import { BaseBtn, BaseInput, BigTitle, Modal, Row } from 'shared/ui';
import { Text } from 'shared/ui/Text';
import { DeleteAssociateAccountModal } from '../DeleteAssociateAccountModal';
import { NewAssociateAccountModal } from '../NewAssociateAccountModal';
import { TABLE_TITLES } from './constants';

interface ModalState {
  isOpenModal: boolean
  currentUserProjectAccess: string
  closeModal: () => void
  linkedAccountsChanged: () => void
  changeCurrentUserProjectAccess: (string) => void
  isLoading: boolean
  currentUser: User
}


export const EditPermissions: FC<ModalState> = (props: ModalState) => {
  const { isOpenModal, closeModal, linkedAccountsChanged, changeCurrentUserProjectAccess, currentUser, currentUserProjectAccess, isLoading } = props;
  const initialValues = {
    customerID: 0,
    userID: 0,
    userName: '',
    userLogin: '',
    userStatus: '',
    lastLoginDate: '',
    lastMonthLoginCount: '',
    lastPasswordSelfUpdate: '',
    lastPasswordTeamUpdate: '',
    nextPasswordUpdate: '',
    eMailAddress: '',
    phoneNumber: '',
    userRoleDescription: '',
    notes: '',
    backOfficeAccessLevel: 0,
    projectsCount: 0,
    foreignProjectAccess: '',
    userPhone: '',
    userEmail: '',
    isMainContact: 0
  };

  const [values, setValues] = useState<User>(initialValues);
  const [formattedUserProjects, setFormattedUserProjects] = useState<UserAccountsItem[]>([]);
  const [isChanged, setIsChanged] = useState(false);
  const { userProjects } = useAppSelector((st) => st.users);
  const [isOpenDeleteAssociateAccountModal, setIsOpenDeleteAssociateAccountModal] = useState(false);
  const [isOpenNewAssociateAccount, setIsOpenNewAssociateAccount] = useState(false);
  const [currentAssociateAccount, setCurrentAssociateAccount] = useState<UserAccountsItem>(null);

  useEffect(() => {
    setDefaultData()
  }, [isOpenModal, currentUserProjectAccess]);

  useEffect(() => {
    addRowWithAddAction()
  }, [userProjects]);

  const setDefaultData = () => {
    if (isOpenModal) {
      setValues(currentUser);
    }
    if (currentUserProjectAccess) {
      handleChangeAccessLevel(currentUserProjectAccess)
    }
  }
  const addRowWithAddAction = () => {

    const formattedUserProjects = [...userProjects]
    formattedUserProjects.push({
      customerID: 0,
      customerName: '',
      userID: 0,
      userName: '',
      userLogin: '',
      eMailAddress: '',
      mainUserID: 0,
      phoneNumber: '',
    })
    setFormattedUserProjects(formattedUserProjects)

  }
  const onChangeValues = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, propertyName: keyof User) => {
    const newValue = event.target.value;
    setValues((prevValues) => ({
      ...prevValues,
      [propertyName]: newValue,
    }));
    setIsChanged(true);
  };

  const handleChangeAccessLevel = (value: string) => {
    setValues((prevValues) => ({
      ...prevValues,
      ['foreignProjectAccess']: value,
    }));

    if (currentUserProjectAccess) {
      setIsChanged(!(currentUserProjectAccess === value));
      return
    }
    setIsChanged(!(currentUser.foreignProjectAccess === value));
  };
  const handleCloseEditModal = () => {
    setIsChanged(false);
    closeModal();
  };
  const handleSave = () => {
    changeCurrentUserProjectAccess(values.foreignProjectAccess)
    handleCloseEditModal()
  };
  const handleDeleteUserProject = useCallback((row: UserAccountsItem) => {
    setCurrentAssociateAccount(row)
    setIsOpenDeleteAssociateAccountModal(true)
    setIsChanged(true)
    linkedAccountsChanged()
  }, [])
  const handleAddUserProject = () => {
    setIsOpenNewAssociateAccount(true)
    setIsChanged(true)
    linkedAccountsChanged()
  }
  const closeDeleteAssociateAccountModal = () => {
    setIsOpenDeleteAssociateAccountModal(false)
  }
  const closeAddUserProjectModal = () => {
    setIsOpenNewAssociateAccount(false)
  }
  const lastRowOption = useMemo(() => ({ title: `${t('add_new_associate_account')}`, onClick: handleAddUserProject }), [])
  const rowOption = useMemo(() => ({ title: `${t('delete_associate_account')}`, onClick: handleDeleteUserProject }), [])

  return (
    <Modal open={isOpenModal} onClose={handleCloseEditModal}>
      <>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <BigTitle>{t('change_permissions_for_multiple_projects_edit_modal')}</BigTitle>
          <IconButton onClick={handleCloseEditModal}>
            <img src={close_popup} />
          </IconButton>
        </Stack>
        <Box component="form" autoComplete="off">
          <Row spacing="20px" alignItems="flex-start" justifyContent="space-between">
            <Box width={'33%'}>
              <BaseInput
                label={`${t('customer_id_edit_modal')} *`}
                name="customerID"
                helperText={''}
                value={values?.customerID}
                onChange={(event) => onChangeValues(event, 'customerID')}
                disabled
                small
              />
              <BaseInput
                label={`${t('user_login_edit_modal')} *`}
                name="customerID"
                helperText={''}
                value={values?.userLogin}
                onChange={(event) => onChangeValues(event, 'customerID')}
                disabled
                small
              />
            </Box>
            <Box width={'33%'}>
              <BaseInput
                label={`${t('user_id_edit_modal')} *`}
                name="customerID"
                helperText={''}
                value={values?.userID}
                onChange={(event) => onChangeValues(event, 'customerID')}
                disabled
                small
              />
              <BaseInput
                label={`${t('phone_number_edit_modal')} *`}
                name="customerID"
                helperText={''}
                value={values?.phoneNumber ? values?.phoneNumber : ''}
                onChange={(event) => onChangeValues(event, 'customerID')}
                disabled
                small
              />
            </Box>
            <Box width={'33%'}>
              <BaseInput
                label={`${t('user_name_edit_modal')} *`}
                name="customerID"
                helperText={''}
                value={values?.userName}
                onChange={(event) => onChangeValues(event, 'customerID')}
                disabled
                small
              />
              <BaseInput
                label={`${t('e_mail_address_edit_modal')} *`}
                name="customerID"
                helperText={''}
                value={values?.eMailAddress ? values?.eMailAddress : ''}
                onChange={(event) => onChangeValues(event, 'customerID')}
                disabled
                small
              />
            </Box>
          </Row>
          <Box mt={'15px'}>
            <Text noWrap sx={{ color: 'info.dark', fontSize: '12px', fontWeight: 500, pr: '2px', lineHeight: '15px' }}>
              {t('access_level_title_edit_modal')}
            </Text>
            <Row spacing="20px" alignItems="flex-start" justifyContent="flex-start">
              <Row justifyContent="flex-start" onClick={() => handleChangeAccessLevel('REGULAR')}>
                <Radio checked={values.foreignProjectAccess === 'REGULAR'} size="small" />
                <Text sx={{ color: 'black', pr: '10px', cursor: 'pointer' }}>
                  {t('regular_user_access_level_edit_modal')}
                </Text>
              </Row>
              <Row justifyContent="flex-start" onClick={() => handleChangeAccessLevel('SYSTEM-FULL')}>
                <Radio checked={values.foreignProjectAccess === 'SYSTEM-FULL'} size="small" />
                <Text sx={{ color: 'black', pr: '10px', cursor: 'pointer' }}>
                  {t('supervisor_access_level_edit_modal')}
                </Text>
              </Row>
              <Row justifyContent="flex-start" onClick={() => handleChangeAccessLevel('MULTIPLE-RESTRICTED')}>
                <Radio checked={values.foreignProjectAccess === 'MULTIPLE-RESTRICTED'} size="small" />
                <Text sx={{ color: 'black', pr: '10px', cursor: 'pointer' }}>
                  {t('advanced_user_access_level_edit_modal')}
                </Text>{' '}
              </Row>
            </Row>
          </Box>
          <Row justifyContent="flex-start" mt={'15px'}>
            <Text
              noWrap
              sx={{ color: 'rgba(42, 93, 133, 1)', fontSize: '16px', fontWeight: 500, pr: '2px', lineHeight: '15px' }}
            >
              {t('associated_accounts_title_edit_modal')}
            </Text>
          </Row>
          <Row justifyContent="flex-end">
            <BaseTable
              tableTitles={TABLE_TITLES}
              tableItems={formattedUserProjects}
              loadedItems={!isLoading}
              idField={"userID"}
              noItemsText={t('no_users_sims_grid_table')}
              headerItemsPrefix={'_users_accounts_grid_table'}
              options={rowOption}
              lastRowOption={lastRowOption}
              height={"210px"}
              disabled={!(values?.foreignProjectAccess === 'MULTIPLE-RESTRICTED')}
            />
          </Row>
          <Row justifyContent="flex-end" mt={'15px'}>
            <Row width={300}>
              <BaseBtn onClick={handleCloseEditModal} btnColor="info.main" fullWidth>
                {t('cancel_edit_modal')}
              </BaseBtn>
              <Box onClick={handleCloseEditModal} sx={{ height: '40px', width: '25px' }} />
              <BaseBtn onClick={handleSave} disabled={!isChanged} btnColor="primary.dark" fullWidth>
                {t('save_edit_modal')}
              </BaseBtn>
            </Row>
          </Row>
        </Box>
        <NewAssociateAccountModal isOpenModal={isOpenNewAssociateAccount} closeModal={closeAddUserProjectModal} currentUser={currentUser} />
        <DeleteAssociateAccountModal isOpenModal={isOpenDeleteAssociateAccountModal} closeModal={closeDeleteAssociateAccountModal} currentUser={currentUser} currentAssociateAccount={currentAssociateAccount} />
      </>
    </Modal>

  );
};

import { Box, IconButton, Stack } from '@mui/material';
import { BaseSelect } from 'features/BaseSelect';
import { Fota } from 'features/FotaManager/fotaModels';
import { useFilterFota } from 'features/FotaManager/hooks/useFilterFota';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { convertTimeToMin } from 'features/FotaManager/fotaHelpers';
import { arrow_right, close_popup } from 'shared/assets';
import { useAnchorEl, useAppSelector, useTokenInfo } from 'shared/hooks';
import {
  BaseBtn,
  BaseInfo,
  BaseInput,
  BaseTimePicker,
  BigTitle,
  Confirm,
  Icon,
  Modal,
  ModalLabel,
  Row,
} from 'shared/ui';
import { ProgressChanging } from '..';
import localStorageService from 'shared/localStorage';

interface Props {
  openModal: boolean;
  onCloseModal: () => void;
  selectedFota: Fota;
  isMultiChangeFotaSettings?: boolean;
  selectedFotaItems: number[];
  setLoading: (value: boolean) => void;
  allFota: Fota[];
  updateFota: () => void;
}
const defaultDownloadTimePeriod = {
  timeStart: '00:00',
  timeEnd: '23:59',
};
const defaultRestartTimePeriod = {
  timeStart: '00:00',
  timeEnd: '23:59',
};

const defaultCurrentVersion = {
  from: '',
  to: '',
};

const defaultNewVersion = {
  from: '',
  to: '',
};

export const ChangeFotaSettings = ({
  openModal,
  onCloseModal,
  selectedFota,
  selectedFotaItems,
  setLoading,
  allFota,
  updateFota,
}: Props) => {
  const { t } = useTranslation();
  const closeModal = () => {
    clearData();
    onCloseModal();
  };

  const [selectedType, setSelectedType] = useState<string>('');
  const [selectedStatus, setSelectedStatus] = useState<string>('');
  const [downloadTimePeriod, setDownloadTimePeriod] = useState(defaultDownloadTimePeriod);
  const [restartTimePeriod, setRestartTimePeriod] = useState(defaultRestartTimePeriod);
  const [currentVersion, setCurrentVersion] = useState(defaultCurrentVersion);
  const [newVersion, setNewVersion] = useState(defaultNewVersion);
  const [specialVersion, setSpecialVersion] = useState('');
  const [notes, setNotes] = useState('');
  const { isOpen, anchorEl, onOpenMenu, onCloseMenu } = useAnchorEl();
  const [isChanged, setIsChanged] = useState(false);

  const boxRef = useRef<HTMLDivElement>();
  const { userId } = useTokenInfo();
  const handleCloseModal = (e: React.MouseEvent<HTMLElement>) => {
    //if (isLoading) return;
    isChanged ? onOpenMenu(e) : closeModal();
  };
  // const { updateFota, loading } = useFilterFota(false);
  const [selectedFotaIds, setSelectedFotaIds] = useState([]);
  const [isShowProgressChangingModal, setIsShowProgressChangingModal] = useState(false);
  const closeProgressChangingModal = () => {
    setIsShowProgressChangingModal(false);
  };
  // useEffect(() => {
  //   setLoading(loading);
  // }, [loading]);
  useEffect(() => {
    if (selectedFota && openModal) {
      setSelectedType(selectedFota.deviceType);
      setSelectedStatus(selectedFota.isAllowUpdate.toString());
      setSpecialVersion(selectedFota.specialVersion?.toString());
      setCurrentVersion({
        from: selectedFota.currentVersionMin?.toString(),
        to: selectedFota.currentVersionMax?.toString(),
      });
      setNewVersion({
        from: selectedFota.newVersionMin?.toString(),
        to: selectedFota.newVersionMax?.toString(),
      });
      setNotes(selectedFota.notes);
      if (selectedFota.restartTimeFrom !== '00:00' && selectedFota.restartTimeTo !== '00:00') {
        setRestartTimePeriod({
          timeStart: selectedFota.restartTimeFrom,
          timeEnd: selectedFota.restartTimeTo,
        });
      }
      if (selectedFota.downloadTimeFrom !== '00:00' && selectedFota.downloadTimeTo !== '00:00') {
        setDownloadTimePeriod({
          timeStart: selectedFota.downloadTimeFrom,
          timeEnd: selectedFota.downloadTimeTo,
        });
      }
    }
  }, [openModal]);

  useEffect(() => {
    if (openModal && !selectedFota) {
      checkForSimilarDeviceType();
    }
  }, [openModal]);

  const checkForSimilarDeviceType = () => {
    const selected = allFota.filter((item) => {
      return selectedFotaItems.includes(item.hwid);
    });
    const firstDeviceType = selected[0].deviceType;

    for (let i = 1; i < selected.length; i++) {
      if (selected[i].deviceType !== firstDeviceType) {
        return false;
      }
    }
    setSelectedType(firstDeviceType);
  };
  const clearData = () => {
    setSelectedType('');
    setSelectedStatus('');
    setSpecialVersion('');
    setCurrentVersion(defaultCurrentVersion);
    setNewVersion(defaultNewVersion);
    setNotes('');
    setRestartTimePeriod(defaultRestartTimePeriod);
    setDownloadTimePeriod(defaultDownloadTimePeriod);
    setIsChanged(false);
  };
  const onChangeSelectedType = (value: string) => {
    setSelectedType(value);
    setIsChanged(true);
  };
  const onChangeSelectedStatus = (value: string) => {
    setSelectedStatus(value);
    setIsChanged(true);
  };
  const onChangeDownloadTimePeriod = (value) => {
    const result = value.split('-');
    setDownloadTimePeriod({
      timeStart: result[0],
      timeEnd: result[1],
    });
    setIsChanged(true);
  };
  const onChangeRestartTimePeriod = (value) => {
    const result = value.split('-');
    setRestartTimePeriod({
      timeStart: result[0],
      timeEnd: result[1],
    });
    setIsChanged(true);
  };
  const { isRTL } = localStorageService;
  const { fotaTypes } = useAppSelector((st) => st.fota);
  const fotaTypesItems = useMemo(
    () =>
      fotaTypes.map((s) => ({
        value: s.fotaDeviceTypeCode,
        label: s.fotaDeviceTypeCode,
      })),
    [fotaTypes]
  );
  const handleUpdate = async () => {
    if (selectedFota) {
      setSelectedFotaIds([selectedFota.hwid]);
    } else {
      setSelectedFotaIds([...selectedFotaItems]);
    }
    setIsShowProgressChangingModal(true);
  };
  const finishedUpdate = () => {
    closeModal();
  };
  const changeVersion = (value, key) => {
    const newValue = value.target.value;
    switch (key) {
      case 'currentVersionFrom':
        setCurrentVersion((prevValues) => ({
          ...prevValues,
          ['from']: newValue,
        }));
        break;
      case 'currentVersionTo':
        setCurrentVersion((prevValues) => ({
          ...prevValues,
          ['to']: newValue,
        }));
        break;
      case 'newVersionFrom':
        setNewVersion((prevValues) => ({
          ...prevValues,
          ['from']: newValue,
        }));
        break;
      case 'newVersionTo':
        setNewVersion((prevValues) => ({
          ...prevValues,
          ['to']: newValue,
        }));
        break;
    }
    setIsChanged(true);
  };

  const changeSpecialVersion = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSpecialVersion(e.target.value);
    setIsChanged(true);
  };

  const changeNotes = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setNotes(e.target.value);
    setIsChanged(true);
  };

  const fotaStatusItems = [
    {
      value: '0',
      label: `${t('fota_status_Disable')}`,
    },
    {
      value: '1',
      label: `${t('fota_status_Active')}`,
    },
  ];
  const closeButtonRef = useRef<HTMLButtonElement | null>(null);
  const handleModalClose = () => {
    if (closeButtonRef.current) {
      closeButtonRef.current.click();
    }
  };
  return (
    <Modal open={openModal} sx={{ width: 600, margin: 'auto' }} onClose={handleModalClose}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <BigTitle>{t('change_fota_settings_info_modal')}</BigTitle>
        <IconButton onClick={handleCloseModal} ref={closeButtonRef}>
          <img src={close_popup} />
        </IconButton>
      </Stack>
      <Box sx={{ mt: '16px' }}>
        <Row spacing={'8px'} sx={{ maxWidth: '100%' }}>
          <Box sx={{ width: '175px' }}>
            <ModalLabel text={t('controller_info_modal')} />
            <ModalLabel text={t('device_type_info_modal')} />
            <ModalLabel text={t('fota_status_info_modal')} />
          </Box>
          <Box sx={{ width: '100%' }}>
            {selectedFota && (
              <BaseInfo sx={{ backgroundColor: 'white', width: '100%', mb: '16px' }} text={selectedFota.objectName} />
            )}
            {!!selectedFotaItems.length && !selectedFota && (
              <BaseInfo
                sx={{ backgroundColor: 'white', width: '100%', mb: '16px' }}
                text={selectedFotaItems.length.toString()}
              />
            )}
            <BaseSelect
              value={selectedType}
              onChange={onChangeSelectedType}
              items={fotaTypesItems}
              hideAllButton
              hideValue
              controlsWidth={200}
              noSelectLabel={`${t('select_device_type_edit_modal')}`}
              sx={{ width: '200px', mb: '16px' }}
            />
            <BaseSelect
              value={selectedStatus}
              onChange={onChangeSelectedStatus}
              items={fotaStatusItems}
              hideAllButton
              hideValue
              controlsWidth={200}
              noSelectLabel={`${t('select_fota_status_edit_modal')}`}
              sx={{ width: '200px', mb: '16px' }}
            />
          </Box>
        </Row>
        <BigTitle sx={{ mb: '4px' }}>{t('advanced_parameters_info_modal')}</BigTitle>
        <Row spacing={'8px'} sx={{ width: '100%' }} mb={'8px'} alignItems="flex-start" justifyContent="space-between">
          <BaseTimePicker
            startTimeLabel={t('selected_time_from')}
            endTimeLabel={t('selected_time_to')}
            label={`${t('download_time_period_info_modal')}`}
            minHour={0}
            maxHour={23}
            value={`${downloadTimePeriod.timeStart}-${downloadTimePeriod.timeEnd}`}
            onChanged={onChangeDownloadTimePeriod}
            selectableMinutes
            fullWidth
          />
          <BaseTimePicker
            startTimeLabel={t('selected_time_from')}
            endTimeLabel={t('selected_time_to')}
            label={`${t('restart_time_period_info_modal')}`}
            minHour={0}
            maxHour={23}
            value={`${restartTimePeriod.timeStart}-${restartTimePeriod.timeEnd}`}
            onChanged={onChangeRestartTimePeriod}
            selectableMinutes
            fullWidth

          />
        </Row>
        <Row spacing={'16px'} mb={'16px'}>
          <Box sx={{ width: '50%' }}>
            <Box sx={{ fontSize: '16px', fontWeight: '600', color: 'primary.main' }}>
              {t('current_version_info_modal')}
            </Box>
            <Row spacing={'16px'}>
              <BaseInput
                label={`${t('from_current_version_edit_info_modal')}`}
                name="currentVersionFrom"
                helperText=""
                value={currentVersion.from}
                onChange={(event) => {
                  changeVersion(event, 'currentVersionFrom');
                }}
                small
                compact
                type={'number'}
                smallLabel
                changeColorOnFocus
                placeholder={t('from_current_version_placeholder_edit_info_modal')}
              />
              <Box sx={{ position: 'relative', width: '16px' }}>
                {/* <Icon
                  sx={{ position: 'absolute', top: '4px', transform: isRTL ? 'rotate(180deg)' : 'rotate(0deg)' }}
                  src={arrow_right}
                /> */}
                <Box sx={{ position: 'absolute', top: '-4px', mr: '10px', fontWeight: 700, fontSize: "20px", color: "#888" }}>{t('arrow_symbol')}</Box>
              </Box>
              <BaseInput
                label={`${t('to_current_version_edit_info_modal')}`}
                name="currentVersionTo"
                helperText=""
                value={currentVersion.to}
                onChange={(event) => {
                  changeVersion(event, 'currentVersionTo');
                }}
                small
                compact
                type={'number'}
                smallLabel
                changeColorOnFocus
                placeholder={t('to_current_version_placeholder_edit_info_modal')}
              />
            </Row>
          </Box>
          <Box sx={{ width: '50%' }}>
            <Box sx={{ fontSize: '16px', fontWeight: '600', color: 'primary.main' }}>{t('new_version_info_modal')}</Box>
            <Row spacing={'16px'}>
              <BaseInput
                label={`${t('from_new_version_edit_info_modal')}`}
                name="newVersionFrom"
                helperText=""
                value={newVersion.from}
                onChange={(event) => {
                  changeVersion(event, 'newVersionFrom');
                }}
                small
                compact
                type={'number'}
                smallLabel
                changeColorOnFocus
                placeholder={t('from_new_version_placeholder_edit_info_modal')}
              />
              <Box sx={{ position: 'relative', width: '16px' }}>
                {/* <Icon sx={{ position: 'absolute', top: '4px' }} src={arrow_right} /> */}
                <Box sx={{ position: 'absolute', top: '-4px', mr: '10px', fontWeight: 700, fontSize: "20px", color: "#888" }}>{t('arrow_symbol')}</Box>
              </Box>
              <BaseInput
                label={`${t('to_new_version_edit_info_modal')}`}
                name="newVersionTo"
                helperText=""
                value={newVersion.to}
                onChange={(event) => {
                  changeVersion(event, 'newVersionTo');
                }}
                small
                compact
                type={'number'}
                smallLabel
                changeColorOnFocus
                placeholder={t('to_new_version_placeholder_edit_info_modal')}
              />
            </Row>
          </Box>
        </Row>
        <Row spacing={'8px'} sx={{ maxWidth: '100%' }}>
          <Box sx={{ width: '175px' }}>
            <ModalLabel text={t('special_version_info_modal')} />
            <ModalLabel text={t('note_info_modal')} />
          </Box>
          <Box sx={{ width: '100%' }}>
            <BaseInput
              name="specialVersion"
              helperText=""
              value={specialVersion}
              onChange={changeSpecialVersion}
              small
              compact
              marginBottom={16}
              type={'number'}
            />
            <BaseInput
              name="specialVersion"
              helperText=""
              value={notes}
              onChange={changeNotes}
              small
              compact
              marginBottom={16}
            />
          </Box>
        </Row>
      </Box>
      <Row justifyContent="flex-end" mt={'35px'}>
        <Row spacing="15px" width={500}>
          <BaseBtn onClick={handleCloseModal} btnColor="info.main" fullWidth>
            {t('cancel_edit_modal')}
          </BaseBtn>
          <Box ref={boxRef} onClick={handleCloseModal} sx={{ height: '40px', width: '25px' }} />
          <BaseBtn
            onClick={handleUpdate}
            loading={false}
            disabled={!isChanged || !selectedStatus || !selectedType}
            btnColor="primary.dark"
            fullWidth
          >
            {t('update_edit_modal')}
          </BaseBtn>
        </Row>
        <Confirm
          open={isOpen}
          anchorEl={anchorEl}
          onClose={onCloseMenu}
          confirm={closeModal}
          buttonTexts={[t('cancel_add_add_modal'), t('back_to_add_add_modal')]}
        />
      </Row>
      {selectedFotaIds && (
        <ProgressChanging
          onCloseModal={closeProgressChangingModal}
          finishedUpdate={finishedUpdate}
          updateFota={updateFota}
          openModal={isShowProgressChangingModal}
          selectedFotaIds={selectedFotaIds}
          selectedStatus={selectedStatus}
          deviceType={selectedType}
          currentVersionMin={currentVersion.from ? parseInt(currentVersion.from) : null}
          currentVersionMax={currentVersion.to ? parseInt(currentVersion.to) : null}
          newVersionMin={newVersion.from ? parseInt(newVersion.from) : null}
          newVersionMax={newVersion.to ? parseInt(newVersion.to) : null}
          specialVersion={specialVersion ? parseInt(specialVersion) : null}
          notes={notes}
          currentUserId={userId}
          downloadTimeFrom={convertTimeToMin(downloadTimePeriod.timeStart)}
          downloadTimeTo={convertTimeToMin(downloadTimePeriod.timeEnd)}
          restartTimeFrom={convertTimeToMin(restartTimePeriod.timeStart)}
          restartTimeTo={convertTimeToMin(restartTimePeriod.timeEnd)}
        ></ProgressChanging>
      )}
    </Modal>
  );
};

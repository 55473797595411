import { useEffect, useState } from 'react';
import { MappedExcelSimData } from 'shared/models';
import { HubConnectionBuilder, HubConnection, HttpTransportType, LogLevel } from '@microsoft/signalr';
export const useConnection = () => {
  interface SimData {
    data: MappedExcelSimData[];
    simOperator: string;
  }
  const [progress, setProgress] = useState<number>(0);
  const [connectionId, setConnectionId] = useState<string>('');
  const [connection, setConnection] = useState<HubConnection | null>(null);
  const Math_round = (val: number, places: number) => {
    if (places == null) places = 0;
    return Math.round(val * Math.pow(10, places)) / Math.pow(10, places);
  };
  const connectToSimHub = () => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(`/api/hubs/simhub`, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      }) // Update with your backend URL
      .configureLogging(LogLevel.Debug)
      .build();
    setConnection(newConnection);
    newConnection.on('connected', () => {
      console.log('CONNECTION_ID = ', newConnection.connectionId);
    });

    newConnection.on('progressStarted', () => {
      setProgress(0);
    });

    newConnection.on('progressUpdate', (data: { value: number; totalCount: number }) => {
      const formattedValue = Math_round((100 * data.value) / (data.totalCount - 1), 2);

      console.log('FOMRATTED VALUE', formattedValue);

      setProgress(formattedValue);
    });

    newConnection.on('progressCompleted', () => {
      setProgress(100);
    });

    newConnection
      .start()
      .catch(console.error)
      .then(() => newConnection?.invoke('getConnectionId').then((connectionId: any) => setConnectionId(connectionId)));
  };
  const stopConnection = () => connection?.stop().catch(console.error);

  return {
    progress,
    setProgress,
    connectionId,
    connectToSimHub,
    stopConnection,
  };
};

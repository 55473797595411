import { Sim } from 'shared/models';
import { useTranslation } from 'react-i18next';
export const useFormatXlsxData = () => {
  const { t } = useTranslation();
  
  const formatTable = (arr: Sim[]) => {
    const exportArr = arr.map((item) => {

      return {
        [t('id_sim_grid_table')]: item.id,
        [t('simNumber_sim_grid_table')]: item.simNumber,
        [t('phoneNumber_sim_grid_table')]: item.phoneNumber,
        [t('simProvider_sim_grid_table')]: item.simProvider,
        [t('statusCode_sim_grid_table')]: t(item.statusCode),
        [t('lastDeviceId_sim_grid_table')]: item.lastDeviceId,
        [t('customerName_sim_grid_table')]: item.customerName,
        [t('customerId_sim_grid_table')]: item.customerId,
        [t('activationDate_sim_grid_table')]: item.activationDate,
        [t('deActivationDate_sim_grid_table')]: item.deActivationDate,
        [t('lastCommunicationDate_sim_grid_table')]: item.lastCommunicationDate,
        [t('lastUpdateDate_sim_grid_table')]: item.lastUpdateDate,
        [t('notes_sim_grid_table')]: item.notes,
      };
    });

    return exportArr;
  };

  return { formatTable };
};



import {   CheckboxProps  } from '@mui/material';
import { FC } from 'react';
import {  Checkbox} from '@mui/material';
import { checked_box, un_checked_box } from 'shared/assets';
import React, { forwardRef } from 'react';
import {  Icon} from 'shared/ui';
 interface Props extends  CheckboxProps {
	value: boolean
 }

export const BaseCheckbox: FC<Props> = forwardRef((props, ref) => {
  return (
		<Checkbox
			size="small"
			icon={<Icon src={un_checked_box} />}
			checkedIcon={<Icon src={checked_box} />}
			{...props}
		></Checkbox>
  );
})



import useAxios from 'app/useAxios';
import { User, UserAccountsItem, UserHistory, UserStatuses } from 'features/UserManager/UserModels';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useToast } from 'shared/hooks';
import { setUserActionHistory, setUserProjects, setUserStatuses, setUsers } from '../../userSlice';

export const useActionHistory = () => {
  const {loading, request } = useAxios();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { showError, showSuccess } = useToast();


 
  return {
    GetUserActionHistory: (userID:number, userLogin: string, startDateInterval: string, endDateInterval: string) =>
    request<undefined, { data: UserHistory[], succeeded:boolean }>({
      method: 'get',
      showSuccessMessageCode: undefined,
      url: `/user/GetUserActionHistory?userId=${userID}&userLogin=${userLogin}&startDate=${startDateInterval}&endDate=${endDateInterval}`,
      onDataReceiveCompleted: (data) => {
        if(data.succeeded){
        dispatch(setUserActionHistory(data.data));
        }else{
          showError(t('something_went_wrong_toast_text'));
        }
      },
    }),
   
    isLoadingUser: loading,
    isLoading: loading,
  };
};


import { TextField, InputProps, InputAdornment, Box, Paper, Divider} from '@mui/material';
import { FC, useEffect, useRef, useState} from 'react';
import { search  } from './assets';
import { arrow_down_blue, arrow_up_blue, cross_blue_thin } from 'shared/assets';
import { autoFillColor } from 'shared/config';
import { isHebrewFont } from 'shared/lib';
import { Text, Row} from 'shared/ui';

interface Props extends InputProps {
  noBorder?: boolean;
  onfocus?: boolean;
	numberOfResults?:number;
	itemNumber?:number;
  onClear:()=>void;
  onPrevious:()=>void;
  onNext:()=>void;
}

export const BaseSearch: FC<Props> = ({ noBorder, itemNumber,numberOfResults, onfocus, sx, placeholder, name, type, value, onChange, onClear, onPrevious, onNext}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (onfocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };
  const handleBlur = () => {
    setIsFocused(false);
  };
  return (
    <>
		<Paper
      component="form"
			elevation={isFocused ? 3 : 0}
			sx={{
				p: '2px 4px', display: 'flex', alignItems: 'center', width: 400,
				'&.MuiPaper-root': {
					border: `1px solid`,
					borderColor: 'primary.main',
					borderRadius: '10px',
					pl: '18px',
					height: '49px',
					fontFamily: 'Inter',
					width:'100%'
				},
			}}
    >
      <InputAdornment position="start">
              <Box component="img" src={search} />
            </InputAdornment>
			<TextField
        inputRef={inputRef}
        value={value}
        onChange={onChange}
        type={type}
        fullWidth
        placeholder={placeholder}
        autoComplete={'off'}
        onFocus={handleFocus}
        onBlur={handleBlur}
        sx={{
          
          '& .MuiInputBase-root': {
            border: 'none',
            borderColor: 'primary.main',
            height: '49px',
            fontFamily: 'Inter',
          },
          fieldset: {
            border: 'none !important',
          },
          input: {
            fontFamily: isHebrewFont(value?.toString() ?? '') ? 'Noto Sans Hebrew' : 'Inter',
            color:'primary.main',
            fontWeight: '700',
            ...autoFillColor,
          },
          'input::placeholder': {
            fontFamily: isHebrewFont(placeholder ?? '') ? 'Noto Sans Hebrew' : 'Inter',
          },
          ...sx,
        }}
      />
      {value && <Row>
        {numberOfResults ? <><Text sx={{ color: 'primary.main' }}>
        {itemNumber+1}/{numberOfResults}</Text>
        <Divider sx={{ height: 20, m: 1, bgcolor: 'primary.main' }} orientation="vertical" />
        <Box onClick={onPrevious} sx={{minWidth:'24px', height:'24px', mr:'4px', display:'flex', justifyContent: 'center', alignItems:'center',borderRadius:'4px', cursor:'pointer', '&:hover': { bgcolor: 'secondary.main' }
      }} >
          <Box  sx={{width:'15px', height:'15px'}} component="img" src={arrow_up_blue} />
        </Box>
        <Box onClick={onNext} sx={{minWidth:'24px', height:'24px', mr:'4px', display:'flex', justifyContent: 'center', alignItems:'center',borderRadius:'4px', cursor:'pointer','&:hover': { bgcolor: 'secondary.main' } }}>
          <Box sx={{width:'15px', height:'15px'}} component="img" src={arrow_down_blue} />
        </Box>
        </>
        : <></>}
        <Box onClick={onClear} sx={{minWidth:'24px', height:'24px', mr:'4px', display:'flex', justifyContent: 'center', alignItems:'center', borderRadius:'4px', cursor:'pointer','&:hover': { bgcolor: 'secondary.main' } }}>
          <Box sx={{width:'10px', height:'10px'}} component="img" src={cross_blue_thin} />
        </Box>
      </Row>}
    </Paper>

    </>
  );
};

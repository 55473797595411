import { Layout } from '../../shared/ui';
import { Header, NetworkProtectionEditModal, NetworkProtectionCreateModal, NewConnectedControllerModal, EditConnectedControllerModal, NotSupportModal } from '../../widgets';
import { NetworkProtectionManager } from 'features';
export const NetworkProtection = () => {
  return (
    <Layout>
      <Header />
      <Layout.Body>
        <NetworkProtectionManager />
        <NetworkProtectionEditModal />
        <NewConnectedControllerModal />
        <EditConnectedControllerModal />
        <NetworkProtectionCreateModal />
        <NotSupportModal />
      </Layout.Body>
    </Layout>
  );
};

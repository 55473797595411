import { Controller } from 'shared/models';
import { useTranslation } from 'react-i18next';
import { DeviceModelTypes } from 'shared/models';
export const useFormatXlsxData = (modelType:DeviceModelTypes) => {
  const { t } = useTranslation();

  const formatTable = (arr: Controller[]) => {
    const exportArr = arr.map((item) => {
      const translateModel = ()=>{
        if (modelType==='I1') return 'I1_'
        if (modelType==='IP') return 'IP_'
        return ''
       }
       let status=t(`${translateModel()}real_time_status_2`);
      if(item.state?.calculated?.state){
        status=t(`${translateModel()}real_time_status_${item.state.calculated.state < 8 ? item.state.calculated.state : ''}`)
      }
        const result={
          [t('Status_real_time_controller_grid_table')]: status,
          [t('ID_real_time_controller_grid_table')]: item.deviceId,
          [t('Controller_Name_real_time_controller_grid_table')]: item.objectName,
          [t('Customer_ID_real_time_controller_grid_table')]: item.customerId,
          [t('Customer_Name_real_time_controller_grid_table')]: item.customerName,
          [t('Hardware_ID_real_time_controller_grid_table')]: item.hwId,
          [t('Model_real_time_controller_grid_table')]: item.modelId,
        };
        switch (modelType) {
          case 'I1':
            result[t('alarm_status_real_time_controller_grid_table')]= item?.state?.alarmStatus
            result[t('battery_voltage_real_time_controller_grid_table')]= item?.state?.batteryVoltage
            result[t('controller_real_time_real_time_controller_grid_table')]= item?.state?.controllerTime
            result[t('sync_date_real_time_controller_grid_table')]= item?.state?.syncDate
            result[t('data_source_real_time_controller_grid_table')]= item?.state?.dataSource
            break;
          case 'IP':
            result[t('battery_voltage_real_time_controller_grid_table')]= item?.state?.batteryVoltage
            result[t('current_flow_real_time_controller_grid_table')]= item?.state?.currentFlow
            result[t('data_source_real_time_real_time_controller_grid_table')]= item?.state?.dataSource
            result[t('sync_date_real_time_controller_grid_table')]= item?.state?.syncDate
            result[t('total_slaves_flow_real_time_controller_grid_table')]= item?.state?.totalSlavesFlow
            break;
          case 'WE':
            result[t('battery_voltage_real_time_controller_grid_table')]= item?.state?.batteryVoltage
            result[t('data_source_real_time_real_time_controller_grid_table')]= item?.state?.dataSource
            result[t('rain_rate_real_time_controller_grid_table')]= item?.state?.rainRate
            result[t('sync_date_real_time_controller_grid_table')]= item?.state?.syncDate
            break;
        }



      return result
    });

    return exportArr;
  };

  return { formatTable };
};

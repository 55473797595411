import { WeatherStationsManager } from 'features/';
import { Layout } from 'shared/ui';
import { Header } from 'widgets';

export const WeatherStationsPage = () => {
	return (
		<Layout>
			<Header />
			<Layout.Body>
				<WeatherStationsManager />
			</Layout.Body>
		</Layout>
	);
};

import { IconButton, Stack } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { close_popup } from 'shared/assets';
import { useAppDispatch, useAppSelector, useAnchorEl } from 'shared/hooks';
import { toggleNetworkProtectionCreateModal } from 'shared/slices';
import { BigTitle, Modal, Row } from 'shared/ui';
import { BaseBtn, BaseInput } from '../../shared/ui';
import React from 'react';
import { Confirm } from 'shared/ui';
import { Box } from '@mui/material';


export const NetworkProtectionCreateModal: FC = () => {
  const [isChanged, setIsChanged] = useState(false);
  const { isOpen, anchorEl, onOpenMenu, onCloseMenu } = useAnchorEl();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { openNetworkProtectionCreateModal } = useAppSelector((st) => st.modals);
  const closeModal = () => dispatch(toggleNetworkProtectionCreateModal());
  useEffect(() => {
  }, [openNetworkProtectionCreateModal]);


  const handleCloseModal = (e: React.MouseEvent<HTMLElement>) => {
    isChanged ? onOpenMenu(e) : closeModal();
  };
  const onSubmit = () => {

  };

  return (
    <Modal open={openNetworkProtectionCreateModal} onClose={handleCloseModal}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <BigTitle>{t('new_network_protection_controller_modal_title')}</BigTitle>
        <IconButton onClick={handleCloseModal}>
          <img src={close_popup} />
        </IconButton>
      </Stack>
    </Modal>
  );
};

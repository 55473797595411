import { Box, TableCell, TableCellProps, useTheme } from '@mui/material';
import React, { useRef, useEffect, useState, FC } from 'react';
import { isHebrewFont } from 'shared/lib';
import Tooltip from '@mui/material/Tooltip';

interface Props extends TableCellProps {
  cellSize: number;
  hideTooltip?: boolean;
  color?: string;
}

export const Cell: FC<Props> = ({ cellSize, hideTooltip, color, children, sx, onClick }) => {
  const { palette, direction } = useTheme();
  const isHebrew = isHebrewFont(children?.toString() ?? '');
  const isRtl = direction === 'rtl';
  const textElementRef = useRef<HTMLDivElement>(null);
  const [hoverStatus, setHover] = useState(false);

  const compareSize = () => {
    if (textElementRef.current) {
      const compare = textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
      setHover(compare);
    }
  };

  useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);
    return () => {
      window.removeEventListener('resize', compareSize);
    };
  }, [cellSize]);

  return (
    <TableCell
      component="div"
      sx={{
        maxWidth: cellSize,
        p: 0,
        borderBottom: `1px solid ${palette.secondary.light}`,
        borderRight: `1px solid ${palette.secondary.light}`,
        ...sx,
      }}
    >
      <Box sx={{ width: cellSize, pl: '12px', pr: '5px' }}>
        <Tooltip title={children} disableHoverListener={!hoverStatus || hideTooltip} style={{ fontSize: '2em' }}>
          <Box
            ref={textElementRef}
            onClick={onClick}
            style={{}}
            sx={{
              fontFamily: isHebrew ? 'Noto Sans Hebrew' : 'Inter',
              direction: isRtl ? (isHebrew ? 'ltr' : 'rtl') : isHebrew ? 'rtl' : 'ltr',
              fontSize: '13px',
              fontWeight: 500,
              userSelect: 'none',
              cursor: onClick ? 'pointer' : 'default',
              color: onClick ? 'primary.light' : color ? color : 'black',
              '&:hover': {
                color: onClick ? 'primary.dark' : color ? color : 'black',
                textDecoration: onClick ? 'underline' : 'none',
              },
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {children}
          </Box>
        </Tooltip>
      </Box>
    </TableCell>
  );
};

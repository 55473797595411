import { useAppDispatch } from 'shared/hooks';

import { Fota, FotaFile, FotaFilter, FotaType } from '../../fotaModels';
import useAxios from 'shared/api/useAxios';
import { setAllFota, setFotaTypes, setFotaFiles} from 'features/FotaManager/fotaSlice';

export const useGetAllFota = () => {
  const dispatch = useAppDispatch();
  const { loading, request } = useAxios();
  return {
    getAllFota: (data: FotaFilter) =>
    request<FotaFilter, { data: Fota[] }>({
      method: 'post',
      url: `/fota`,
      showSuccessMessageCode: undefined,
      onDataReceiveCompleted: (data) => {
        dispatch(setAllFota(data?.data));
      },
      payload: data,
    }),
    getFotaTypes: () =>
    request<undefined, { data: FotaType[] }>({
      method: 'get',
      url: `/fota/types`,
      showSuccessMessageCode: undefined,
      onDataReceiveCompleted: (data) => {
        dispatch(setFotaTypes(data?.data));
      },
    }),
    getFotaFiles: () =>
    request<undefined, { data: FotaFile[] }>({
      method: 'get',
      url: `/fota/files`,
      showSuccessMessageCode: undefined,
      onDataReceiveCompleted: (data) => {
        dispatch(setFotaFiles(data?.data));
      },
    }),
    isLoadingFota: loading,
  };
};

import { BatteryStatusReportManager } from 'features';
import { Layout } from '../../shared/ui';
import { Header } from '../../widgets';
export const BatteryStatusReport = () => {
  return (
    <Layout>
      <Header />
      <Layout.Body>
        <BatteryStatusReportManager />
      </Layout.Body>
    </Layout>
  );
};

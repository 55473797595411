import { IconButton, Stack } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { close_popup } from 'shared/assets';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { toggleCreateProviderModal } from 'shared/slices';
import { BigTitle, Modal, Row } from 'shared/ui';
import { BaseBtn, BaseInput } from '../../shared/ui';
import { useSimManagement } from 'shared/api';
export const NewProvider: FC = () => {
  const { addOperator } = useSimManagement();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { openNewProviderEditModal } = useAppSelector((st) => st.modals);

  const closeModal = () => dispatch(toggleCreateProviderModal());
  const [provider, setProvider] = useState('');
  useEffect(() => {
    if (openNewProviderEditModal) {
      setProvider('');
    }
  }, [openNewProviderEditModal]);
  const onChangeProvider = (event: any) => {
    setProvider(event.target.value);
  };
  const toggleSaveNewProvider = () => {
    //closeModal();
    addOperator(provider);
  };
  return (
    <Modal
      open={openNewProviderEditModal}
      PaperProps={{
        sx: {
          width: '562px',
          borderRadius: '12px',
          pl: '36px',
          pr: '36px',
          pt: '41px',
          pb: '29px',
        },
      }}
      onClose={closeModal}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <BigTitle>{t('add_new_provider_modal')}</BigTitle>

        <IconButton onClick={closeModal}>
          <img src={close_popup} />
        </IconButton>
      </Stack>
      <BaseInput
        label={`${t('provider_name_edit_modal')} *`}
        name="name"
        helperText={''}
        value={provider}
        onChange={(event) => onChangeProvider(event)}
      />
      <Row
        sx={{
          mt: '10px',
        }}
        columnGap="10px"
        width={260}
      >
        <BaseBtn
          sx={{ fontFamily: 'Noto Sans Hebrew' }}
          btnColor="info.main"
          style={{ height: '34px' }}
          fullWidth
          onClick={closeModal}
        >
          {t('new_provider_cancel')}
        </BaseBtn>
        <BaseBtn
          sx={{ fontFamily: 'Noto Sans Hebrew' }}
          btnColor="primary.dark"
          style={{ height: '34px' }}
          fullWidth
          onClick={toggleSaveNewProvider}
          disabled={!provider}
        >
          {t('new_provider_ok')}
        </BaseBtn>
      </Row>
      {/* )} */}
    </Modal>
  );
};

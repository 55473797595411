import { FC } from 'react';
import { Typography, TypographyProps } from '@mui/material';

export const BigTitle: FC<TypographyProps> = ({ children, align, sx }) => {
  return (
    <Typography
      align={align}
      sx={{
        color: 'primary.dark',
        fontSize: '30px',
        lineHeight: '38px',
        fontWeight: 600,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { CustomerType, DashboardDetails } from 'shared/models';
interface CustomerState {
  dashboardDetails: DashboardDetails[];
  customerTypes: CustomerType[];
  modemTypes: string[];
  modemBoardTypes: string[];
}

const initialState: CustomerState = {
  dashboardDetails: [],
  customerTypes: [],
  modemTypes: [],
  modemBoardTypes: [],
};
type Lookups = {
  modemTypes: string[];
  modemBoardTypes: string[];
};
const dashboardDetailsSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    setCustomerTypes: (state, action: PayloadAction<CustomerType[]>) => {
      state.customerTypes = action.payload;
    },
    setDashboardDetails: (state, action: PayloadAction<DashboardDetails[]>) => {
      state.dashboardDetails = action.payload.map((item) => {
        const result = item;
        item.disabledFlag = item.disabledFlag ? 'C_DISABLE_FLAG_1' : 'C_DISABLE_FLAG_0';
        item.customerType =
          state.customerTypes.find((t) => t.customerTypeID === item.customerType)?.customerType ?? 'None';
        return result;
      });
    },
    setModemAndModemBoardTypes: (state, action: PayloadAction<Lookups>) => {
      state.modemTypes = action.payload.modemTypes;
      state.modemBoardTypes = action.payload.modemBoardTypes;
    },
  },
});

export const { setDashboardDetails, setModemAndModemBoardTypes } = dashboardDetailsSlice.actions;

export const dashboardDetailsReducer = dashboardDetailsSlice.reducer;

import { BatteryStatusExcludeFilter } from 'shared/models';
import { useAppSelector } from 'shared/hooks';
import localStorageService from 'shared/localStorage';
import dayjs, { Dayjs } from 'dayjs';
export const useGetDefaultParams = () => {
  const { BATT_REP } = useAppSelector((st) => st.globalSlice);

  const dbYday = dayjs();

  const startDateDayjs = dbYday.subtract(BATT_REP.REPORT_START_DAYS_AGO, 'day');
  const startDate = startDateDayjs.format('YYYY-MM-DD');

  const endDateDayjs = startDateDayjs.add(BATT_REP.REPORT_INTERVAL_DAYS - 1, 'day');
  const endDate = endDateDayjs.format('YYYY-MM-DD');
  const saveToLocalStorage = (filter: BatteryStatusExcludeFilter) => {
    const keysToCopy = ['connectHMin', 'dayTimeEnd', 'dayTimeStart', 'daysPositiveP', 'nightTimeEnd', 'nightTimeStart',];
    const batteryStatusReportData = {};
    keysToCopy.forEach(function (key) {
      batteryStatusReportData[key] = filter[key];
    });
    localStorageService.batteryStatusReport = JSON.stringify(batteryStatusReportData);
  };
  const getDataFromLocalStorage = () => {
    const data = localStorageService.batteryStatusReport;
    return data ? JSON.parse(data) : null;
  };

  const isValidTime = (value) => {
    const timePattern = /^(\d{1,2}):(\d{2})$/;
    const match = timePattern.exec(value);

    if (!match) return false;

    const [_, hours, minutes] = match;
    const isHoursValid = !isNaN(Number(hours)) && Number(hours) >= 0 && Number(hours) < 24;
    const isMinutesValid = !isNaN(Number(minutes)) && Number(minutes) >= 0 && Number(minutes) < 60;

    return isHoursValid && isMinutesValid;
  };

  const isValidNumber = (value) => {
    return !isNaN(Number(value)) && value !== null && value !== undefined;
  };

  const isValidDaysPositiveP = (value) => {
    const number = Number(value);
    return isValidNumber(value) && number >= 10 && number <= 100 && number % 10 === 0;
  };

  const localData = getDataFromLocalStorage() || {};

  const {
    dayTimeStart: storedDayTimeStart,
    dayTimeEnd: storedDayTimeEnd,
    nightTimeStart: storedNightTimeStart,
    nightTimeEnd: storedNightTimeEnd,
    connectHMin: storedConnectHMin,
    daysPositiveP: storedDaysPositiveP,
  } = localData;

  // time check
  const dayTimeStart = isValidTime(storedDayTimeStart) ? storedDayTimeStart : BATT_REP.DAY_TIME_START;
  const dayTimeEnd = isValidTime(storedDayTimeEnd) ? storedDayTimeEnd : BATT_REP.DAY_TIME_END;
  const nightTimeStart = isValidTime(storedNightTimeStart) ? storedNightTimeStart : BATT_REP.NIGHT_TIME_START;
  const nightTimeEnd = isValidTime(storedNightTimeEnd) ? storedNightTimeEnd : BATT_REP.NIGHT_TIME_END;

  // number check
  const connectHMin = isValidNumber(storedConnectHMin) ? storedConnectHMin : BATT_REP.MIN_CONNECTION_TIME.toString();
  const daysPositiveP = isValidDaysPositiveP(storedDaysPositiveP) ? storedDaysPositiveP : BATT_REP.DISCONNECTED_DAYS_IN_THE_PERIOD.toString();
  return {
    startDate,
    endDate,
    dayTimeStart,
    dayTimeEnd,
    nightTimeStart,
    nightTimeEnd,
    connectHMin,
    daysPositiveP,
    saveToLocalStorage,
  };
};

import { FC, memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ServiceCall } from 'shared/models';
import { ServiceCallItem } from 'entities_';
import { TABLE_TITLES } from './constants';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { BigTitle } from 'shared/ui';
import { useServiceCallsManagement } from 'shared/api';
import { DataGridPro, GridColDef, GridColumnHeaders, useGridApiRef } from '@mui/x-data-grid-pro';
interface Props {
  serviceCalls: ServiceCall[];
  tableMaxHeight? : number;
}

export const ServiceCallsTable: FC<Props> = memo(({ serviceCalls, tableMaxHeight }) => {
  const apiRef = useGridApiRef();
  const tableRef = useRef<HTMLTableElement | null>(null);
  const MemoizedRow = memo(ServiceCallItem);
  const MemoizedColumnHeaders = GridColumnHeaders;
  const { t } = useTranslation();
  const [unitWidth, setUnitWidth] = useState(0);
  const { isLoading } = useServiceCallsManagement();
  useEffect(() => {
    calculateUnitWidth();
  }, []);
  const calculateUnitWidth = () => {
    const scroolWidth = 20;
    let screenWidth = window.innerWidth;
    if (tableRef && tableRef.current) {
      screenWidth = tableRef.current.clientWidth - scroolWidth;
    }
    let sumOfStaticWidth = 0;
    let sumOfPercentWidth = 0;
    TABLE_TITLES.forEach((title) => {
      if (typeof title.width === 'number') {
        sumOfStaticWidth += title.width;
      } else if (typeof title.width === 'string') {
        sumOfPercentWidth += parseInt(title.width);
        return 20;
      }
    });
    const dinamicWidth = screenWidth - sumOfStaticWidth;
    const unitWidth = dinamicWidth / sumOfPercentWidth;
    setUnitWidth(unitWidth);
  };
  const calculateWidthInPixels = (width: number | string): number => {
    if (typeof width === 'number') {
      return width;
    } else if (typeof width === 'string') {
      return parseInt(width) * unitWidth;
    }
    return 100;
  };
  const columns: GridColDef<ServiceCall>[] = TABLE_TITLES.map((title) => ({
    field: title.key,
    headerName: `${t(title.title !== '' ? `${title.title}_service_call_grid_table` : '')}`,
    width: calculateWidthInPixels(title.width),
    headerClassName: 'headerTable',
  }));
  function CustomSortedAscendingIcon() {
    return <ArrowDropDownIcon />;
  }
  function CustomSortedDescendingIcon() {
    return <ArrowDropUpIcon />;
  }
  function CustomNoRowsOverlay() {
    return <ArrowDropUpIcon />;
  }

  return (
    <div ref={tableRef} style={{ width: '100%',}}>
      {unitWidth && (
        <>
          <DataGridPro
            {...serviceCalls}
            getRowId={(row) => row.serviceCallID}
            loading={isLoading}
            checkboxSelection={false}
            disableRowSelectionOnClick
            components={{
              Row: MemoizedRow,
              ColumnHeaders: MemoizedColumnHeaders,
              ColumnSortedAscendingIcon: CustomSortedAscendingIcon,
              ColumnSortedDescendingIcon: CustomSortedDescendingIcon,
              NoResultsOverlay: CustomNoRowsOverlay,
            }}
            apiRef={apiRef}
            density={'compact'}
            rows={serviceCalls}
            columns={columns}
            disableColumnMenu
            hideFooter
            initialState={{ sorting: { sortModel: [{ field: 'createDate', sort: 'desc' }] } }}
            sx={{
              maxHeight: tableMaxHeight ? `${tableMaxHeight}px`: '180px',
              maxWidth: '100%',
              borderRadius: '12px',
              position: 'relative',
              mt: '12px',
              pr: '1px',
              bgcolor: 'info.main',
              overflowY: 'auto',
              '.MuiDataGrid-columnHeaders': {
                backgroundColor: '#0a3e90',
              },
              '.headerTable': {
                backgroundColor: '#0a3e90',
                color: 'white',
                borderRight: '1px solid #7a7a7a',
                fontWeight: 'bold',
                pr: '2px',
                height: '38px',
                '.MuiDataGrid-columnSeparator': {
                  opacity: '0 !important',
                },
                svg: {
                  fill: 'white',
                },
              },
              '.MuiDataGrid-virtualScrollerRenderZone': {},
              '.MuiDataGrid-virtualScroller': {
                overflow: `${!serviceCalls.length && 'hidden'}`,
              },
              '& .MuiDataGrid-virtualScrollerContent': {
                marginTop: '-1px',
              },
            }}
          />
          {/* {!tableControllers.length && 
				<BigTitle align="center" sx={{ mt: '20px' }}>
					{t('no_controllers_controllers_grid_table')}
				</BigTitle>
			} */}
          {!serviceCalls.length && (
            <BigTitle align="center" sx={{ mt: '20px', fontSize: '28px' }}>
              {t('no_service_calls_service_calls_grid_table')}
            </BigTitle>
          )}
        </>
      )}
    </div>
  );
});

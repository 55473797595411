
import { Box } from '@mui/material';
import { node_point_icon } from 'shared/assets';
import { Icon } from 'shared/ui';
type Marker = {
  lat: number;
  lng: number;
  text: string;
};

export const NodePoint = (marker: Marker) => (
	<Box  sx={{width: '300px', textAlign: 'center'}} >
	<Icon src={node_point_icon} 
	sx={{ ml: '7px',
			display: 'inline-block',
			width: '20px',
			height: '20px',
			marginLeft: '-300px',
			marginTop: '-10px'
			}}/>
	<Box sx={{ marginLeft: '-300px',}}>{marker.text}</Box>
	</Box>
);

import useAxios from 'app/useAxios';
import {  useExtendedTranslation, useToast } from 'shared/hooks';
interface ResetLinkInfo {
	selectedContactMethod:string,
	email?:string,
	userName:string,
	userId: number,
	lang: string,
	phone?: string,
  customerId: number
  resetPasswordMessage: string,
  emailSubject: string
}
export const useReceiveResetLink = () => {
  const { showError, showSuccess } = useToast();
  const {loading, request } = useAxios();
  const { t } = useExtendedTranslation();
  return {
    receiveResetLink: async (data: ResetLinkInfo) =>{
    const result= await request<ResetLinkInfo, {data:{ 
      success :boolean, link: string }}>({
        method: 'post',
        showSuccessMessageCode: undefined,
        url: `/recoverPassword/`,
        payload: data,
      })
      if(!result.data.success){
        showError(t('error_toast_text'));
        return false
      }
      showSuccess(t('password_reset_link_sent_toast_text'));
      return result.data.link
    },
    isLoading: loading,
  }

};

import { Grid, Box, useTheme, Popover, ListItemButton, List, Typography } from '@mui/material';
import { PieCharts, BarGraphs, DoughnutCharts, HorizontalBarLists } from './';
import { useAnchorEl, useAppDispatch, useAppSelector, useExtendedTranslation } from 'shared/hooks';
import { BaseBtn, Row, Icon } from 'shared/ui';
import { FC, useEffect, useState } from 'react';
import { ChartNames, Dashboard, DashboardData, ValueNameKey } from 'shared/models';
import { isHebrewFont } from 'shared/lib';
import {
  chart_menu_active,
  chart_menu,
  bar_graph_icon,
  doughnut_chart_icon,
  horizontal_bar_icon,
  pie_chart_icon,
} from 'shared/assets';
import { COLORS, GROUP_FOR_DEVICE_TYPE_OTHER } from './../Constants/';
import localStorageService from 'shared/localStorage';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { setDashboardDetails } from 'shared/slices';

type Props = {
  dashboard: Dashboard;
  chartName: ChartNames;
  title: string;
  valueNameKey: ValueNameKey;
  withoutTranslation?: boolean;
  bigLegendText?: boolean;
};

export const ChartBlock: FC<Props> = ({
  dashboard,
  chartName,
  title,
  valueNameKey,
  withoutTranslation,
  bigLegendText,
}) => {
  const [activeChart, setActiveChart] = useState('PieCharts');
  const [data, setData] = useState<DashboardData[]>([]);
  const { palette } = useTheme();
  const { isOpen, anchorEl, onOpenMenu, onCloseMenu } = useAnchorEl();
  const { t } = useExtendedTranslation();
  const push = useNavigate();
  const dispatch = useAppDispatch();
  const { DASHBOARD } = useAppSelector((st) => st.globalSlice);
  const menu = [
    { icon: bar_graph_icon, title: `${t('bar_graph_menu_item')}`, onClick: () => changeActiveChart('BarGraphs') },
    {
      icon: horizontal_bar_icon,
      title: `${t('horizontal_bar_menu_item')}`,
      onClick: () => changeActiveChart('HorizontalBarLists'),
    },
    { icon: pie_chart_icon, title: `${t('pie_chart_menu_item')}`, onClick: () => changeActiveChart('PieCharts') },
    {
      icon: doughnut_chart_icon,
      title: `${t('doughnut_chart_menu_item')}`,
      onClick: () => changeActiveChart('DoughnutCharts'),
    },
  ];
  const getDataFromLocalStorage = () => {
    const data = localStorageService.dashboardActiveCharts;
    return data ? JSON.parse(data) : null;
  };
  const changeActiveChart = (chart: string) => {
    let data = getDataFromLocalStorage();
    if (!data) data = {};
    data[chartName] = chart;
    localStorageService.dashboardActiveCharts = JSON.stringify(data);
    setActiveChart(chart);
    onCloseMenu();
  };

  useEffect(() => {
    setDefaultChart();
    groupOtherItems();
    dispatch(setDashboardDetails([]));
  }, []);
  const setDefaultChart = () => {
    const data = getDataFromLocalStorage();
    const chartNameDefaultKeys = {
      deviceTypeCount: DASHBOARD.DEVICE_TYPES_COUNT,
      cellProviderDeviceCount: DASHBOARD.CELL_PROVIDERS_COUNT,
      installDateDeviceCount: DASHBOARD.INSTALL_DATES_COUNT,
      communicationIntervalDeviceCount: DASHBOARD.COMMUNICATION_INTERVALS_COUNT,
      customerDeviceCount: DASHBOARD.PROJECTS_COUNT,
    };
    if (!data || !data[chartName]) {
      setActiveChart(chartNameDefaultKeys[chartName]);
      return;
    }
    setActiveChart(data[chartName]);
  };
  const handleCloseMenu = () => {
    onCloseMenu();
  };
  const groupOtherItems = () => {
    let otherCount = 0;
    const groupedData: DashboardData[] = [];
    if (!chartName) {
      return;
    }
    if (chartName === 'cellProviderDeviceCount') {
      addSpecialColorsToCellProvider();
      return;
    }
    if (chartName === 'customerDeviceCount') {
      addTranslateToCustomersCount();
      return;
    }
    dashboard[chartName].forEach((item, index) => {
      if (GROUP_FOR_DEVICE_TYPE_OTHER.includes(item[valueNameKey])) {
        otherCount += item.count;
        return;
      }
      groupedData.push({
        name: withoutTranslation ? item[valueNameKey] : t(`${item[valueNameKey]}_chart_item`),
        key: item[valueNameKey],
        value: item.count,
        fill: COLORS[index],
      });
    });
    if (chartName === 'deviceTypeCount') {
      groupedData.push({
        name: t(`OTHER_ChartItem_DeviceType`),
        key: 'OTHER',
        value: otherCount,
        fill: COLORS[dashboard[chartName].length],
      });
    }
    setData(groupedData);
  };
  const addSpecialColorsToCellProvider = () => {
    const colors = {
      CELLCOM: '#6D216F',
      PELEPHONE: '#00639E',
      PARTNER: '#2CD4C3',
      OTHER: '#FF6600',
    };
    const groupedData: DashboardData[] = [];
    dashboard[chartName].forEach((item, index) => {
      groupedData.push({
        name: t(`${item[valueNameKey]}_chart_item_cell_provider`),
        key: item[valueNameKey],
        value: item.count,
        fill: colors[item[valueNameKey]],
      });
    });
    setData(groupedData);
  };
  const addTranslateToCustomersCount = () => {
    const groupedData = [];
    dashboard[chartName].forEach((item, index) => {
      groupedData.push({
        name: item[valueNameKey],
        key: item.customerIdList.toString(),
        value: item.count,
        fill: COLORS[index],
      });
    });
    groupedData[0].name = t(`agam_chart_item`);
    groupedData[groupedData.length - 2].name = t(`new_customers_chart_item`);
    groupedData[groupedData.length - 1].name = t(`OTHER_chart_item_projects`);
    setData(groupedData);
  };
  const openDashboardDetails = (chartName: ChartNames, itemName: string) => {
    // push(`/controller-details?chartName=${chartName}&itemName=${itemName}`);
    switch (chartName) {
      case 'deviceTypeCount':
        push(`/controller-details?customerType=1&deviceTypes=${itemName}`);
        break;
      case 'communicationIntervalDeviceCount':
        {
          const { startLastCommDate, endLastCommDate } = formatCommunicationInterval(itemName);
          let url = '/controller-details?customerType=1';
          if (startLastCommDate) {
            url += `&startLastCommDate=${startLastCommDate}`;
          }
          if (endLastCommDate) {
            url += `&endLastCommDate=${endLastCommDate}`;
          }
          push(url);
        }
        break;
      case 'installDateDeviceCount':
        {
          const { startInstallDate, endInstallDate } = formatAndChangeDateValues(itemName);
          push(
            `/controller-details?customerType=1&startInstallDate=${startInstallDate}&endInstallDate=${endInstallDate}`
          );
        }
        break;
      case 'cellProviderDeviceCount':
        {
          push(`/controller-details?cellularProvider=${itemName}`);
        }
        break;
      case 'customerDeviceCount':
        {
          push(`/controller-details?customerIdList=${itemName}`);
        }
        break;
      default:
        push(`/controller-details?type=1`);
        break;
    }
  };
  const formatCommunicationInterval = (value: string): { startLastCommDate: string; endLastCommDate: string } => {
    // "INTERVAL_0": "Today",
    // "INTERVAL_1": "Yesterday",
    // "INTERVAL_2": "Week",
    // "INTERVAL_3": "Month",
    // "INTERVAL_4": "Half a year",
    // "INTERVAL_5": "Year",
    // "INTERVAL_5_PLUS": "Over a year",
    const interval = dashboard.communicationIntervalDeviceCount.find((item) => item.interval === value);
    return {
      startLastCommDate: interval.dates.from ? `${interval.dates.from}+00%3A00%3A00` : null,
      endLastCommDate: interval.dates.to ? `${interval.dates.to}+00%3A00%3A00` : null,
    };
  };
  const formatAndChangeDateValues = (value: string): { startInstallDate: string; endInstallDate: string } => {
    const yearsArray = value.split('-');
    if (yearsArray.length === 1) {
      return { startInstallDate: `${yearsArray[0]}-01-01`, endInstallDate: `${yearsArray[0]}-12-31` };
    }
    return { startInstallDate: `${yearsArray[0]}-01-01`, endInstallDate: `${yearsArray[1]}-12-31` };
  };
  return (
    <>
      <Box
        sx={{
          border: `1px solid ${palette.primary.dark}`,
          borderRadius: '10px',
          height: '350px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'white',
        }}
      >
        <Row sx={{ opacity: chartName ? '1' : '0' }} justifyContent={'space-between'} width={'100%'}>
          {' '}
          <Box sx={{ m: '8px 16px ', color: 'primary.dark', fontSize: '18px', fontWeight: '700' }}>{title}</Box>
          <BaseBtn
            onClick={onOpenMenu}
            sx={{ p: '0px', minWidth: '28px', m: '8px 16px', width: '28px', height: '28px' }}
            btnColor={isOpen ? 'primary.dark' : 'white'}
          >
            {isOpen ? <Icon src={chart_menu_active} /> : <Icon src={chart_menu} />}{' '}
          </BaseBtn>
        </Row>
        {chartName && (
          <>
            {activeChart === 'PieCharts' && (
              <PieCharts data={data} chartName={chartName} openDashboardDetails={openDashboardDetails} />
            )}
            {activeChart === 'BarGraphs' && (
              <BarGraphs
                bigLegendText={bigLegendText}
                data={data}
                chartName={chartName}
                openDashboardDetails={openDashboardDetails}
              />
            )}

            {activeChart === 'DoughnutCharts' && (
              <DoughnutCharts data={data} chartName={chartName} openDashboardDetails={openDashboardDetails} />
            )}

            {activeChart === 'HorizontalBarLists' && (
              <HorizontalBarLists
                bigLegendText={bigLegendText}
                data={data}
                chartName={chartName}
                openDashboardDetails={openDashboardDetails}
              />
            )}
            <Popover
              open={isOpen}
              anchorEl={anchorEl}
              onClose={handleCloseMenu}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              sx={{
                '&': {
                  margin: '0 -10px',
                },
                '& .MuiPaper-root': {
                  backgroundColor: 'primary.dark',
                  borderRadius: '12px',
                  color: 'white',
                  p: '8px 0px',
                },
                '& .MuiButtonBase-root': {
                  m: '0px',
                  p: '2px 10px',
                  borderRadius: '0px',
                },
              }}
            >
              <List disablePadding>
                {menu.map(({ icon, title, onClick }, index) => (
                  <ListItemButton
                    onClick={onClick}
                    key={title}
                    sx={{
                      m: '5px',
                      borderRadius: '10px',
                      '&.Mui-disabled': {
                        opacity: 1,
                        color: 'black',
                        display: 'flex',
                        flexDirection: 'column',
                      },
                      '&:hover': {
                        bgcolor: 'primary.light',
                      },
                    }}
                  >
                    <img src={icon} />
                    <Typography
                      sx={{
                        ml: '11px',
                        fontFamily: isHebrewFont(title?.toString() ?? '') ? 'Noto Sans Hebrew' : 'Inter',
                      }}
                    >
                      {title}
                    </Typography>
                  </ListItemButton>
                ))}
              </List>
            </Popover>
          </>
        )}
      </Box>
      {/* <DashboardDetails isOpenModal={showDashboardDetails} closeModal={closeDashboardDetailsModal} /> */}
    </>
  );
};

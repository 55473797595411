import React, { FC, useMemo, useState } from 'react';
import { Box, IconButton, Checkbox, Typography, styled } from '@mui/material';
import { Cell, Text } from 'shared/ui';
import { useAnchorEl, useAppDispatch } from 'shared/hooks';
import { GridRowParams } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { getRegisterLanguageText } from 'shared/models';
import localStorageService from 'shared/localStorage';

interface visibleColumns {
  width: number;
}
interface CustomGridRowParams extends GridRowParams {
  visibleColumns: visibleColumns[];
  searchValue: string;
  currentSearchID: number;
}
export enum RegType {
  Unknown,
  DI,
  DO,
  AI,
  AO,
  ACC,
}
const regTypeDictionary: { [key in RegType]: string } = {
  [RegType.Unknown]: 'Unknown',
  [RegType.DI]: 'DI',
  [RegType.DO]: 'DO',
  [RegType.AI]: 'AI',
  [RegType.AO]: 'AO',
  [RegType.ACC]: 'ACC',
};
export const ControllerRegistersTableItem: FC<CustomGridRowParams> = (props: CustomGridRowParams) => {
  const { eventID, regType, regNumber, bitNumber, text, value } = props.row;
  const { visibleColumns, searchValue, currentSearchID } = props;
  const cellSizes = visibleColumns.map((s) => s.width);
  const { t } = useTranslation();

  const highlightSearch = (text, term) => {
    if (!term.trim()) {
      return text;
    }

    const regex = new RegExp(`(${term})`, 'gi');
    return text.split(regex).map((part, index) =>
      regex.test(part) ? (
        <span style={{ color: 'rgb(36, 90, 165)', fontWeight: '700' }} key={index}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  const selected = useMemo(() => currentSearchID === eventID, [currentSearchID]);
  return (
    <>
      <Box
        id={`controller_registers_${eventID}`}
        sx={{
          cursor: 'pointer',
          '&': {
            bgcolor: selected ? '#d0e7ff' : 'info.main',
          },
          '&:hover': {
            bgcolor: selected ? '#c0d7ff' : '#f0f0f0',
          },
          '& .MuiBox-root': {
            cursor: 'pointer',
          },
        }}
      >
        <>
          <Cell cellSize={cellSizes[0]} sx={{ position: 'relative' }}>
            {regTypeDictionary[regType]}
            <IconButton sx={{ height: '35px', opacity: 0 }}></IconButton>
          </Cell>
          <Cell cellSize={cellSizes[1]}>{highlightSearch(regNumber.toString(), searchValue)}</Cell>
          <Cell cellSize={cellSizes[2]}>{bitNumber}</Cell>
          <Cell cellSize={cellSizes[3]}>{highlightSearch(eventID.toString(), searchValue)}</Cell>
          <Cell cellSize={cellSizes[4]}>
            {highlightSearch(getRegisterLanguageText(text, localStorageService.currentLang), searchValue)}
          </Cell>
          <Cell cellSize={cellSizes[5]}>{value}</Cell>
        </>
      </Box>
    </>
  );
};

import { BaseBtn, Row } from 'shared/ui';
import { Box, Checkbox} from '@mui/material';
import { checked_box_white, un_checked_box_white, setup_white, setup} from 'shared/assets';
import {  Icon, } from 'shared/ui';
import { FC, useState } from 'react';
type Options = {
name:string,
value:string,
}
type Props =  {
  handleChange: (string)=>void;
  selected: string;
	options:Options[];
};


export const BasePageSettings: FC<Props> = ({handleChange, selected, options}) =>{
	const [openMenu, setOpenMenu] = useState<boolean>(false);
	const toggleOpenMenu = ()=>{
		setOpenMenu(!openMenu)
	}
	return ( <Box sx={{ position: 'relative' }}>
	{openMenu && <Box display={'flex'}     sx={{borderRadius: '15px', overflow:'hidden', flexDirection: 'column', position: 'absolute', top:'0px', right:'50px'}}>
		{  options.map((item, index)=>(
		<BaseBtn key={`${item}-${index}`} onClick={()=>handleChange(item.value)} sx={{ fontFamily: 'Noto Sans Hebrew', borderRadius: '0' , justifyContent:"flex-start", minWidth:'250px'}} >
				<Checkbox
					checked={selected===item.value}
					icon={<Icon src={un_checked_box_white} />}
					checkedIcon={<Icon src={checked_box_white} />}
				></Checkbox>
				{item.name}
    </BaseBtn>
		)
		)}</Box>}
		<BaseBtn onClick={toggleOpenMenu} btnColor={openMenu ? 'primary.dark' : 'info.light'} sx={{p:'0px', minWidth:'40px', ml:'10px'}}>
					{openMenu ? <Icon src={setup_white}  /> :
					<Icon src={setup}  />}
          </BaseBtn>
	</Box> );
}

export default BasePageSettings;
import { FC, useState } from 'react';
import { Box, FormControl, Popover, RadioGroup } from '@mui/material';
import { ControlLabelWithDelete } from '../../shared/ui/';
import { BaseBtn, Icon, Row, Text } from '../../shared/ui';
import { PlayArrow } from '@mui/icons-material';
import { useAnchorEl, useExtendedTranslation } from '../../shared/hooks';
import { SxProps } from '@mui/system';
import { BatteryStatusExclude } from 'shared/models';

interface Props {
  title: string;
  items: BatteryStatusExclude[];
  value?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  sx?: SxProps;
  tooltipText: string;
  onExpirationDateClick: () => void;
}

export const SelectWithDelete: FC<Props> = ({ sx, title, items, tooltipText, value, onChange, onExpirationDateClick }) => {
  const { t } = useExtendedTranslation();
  const { isOpen, anchorEl, onOpenMenu, onCloseMenu } = useAnchorEl();
  const handleChange = (value: string) => {
    onChange?.(value);
    onCloseMenu();
  };
  const valueItems = [...items];

  const handleClose = () => {
    onCloseMenu();
  };

  return (
    <Box sx={{ mr: '20px', ...sx }}>
      <Text noWrap sx={{ color: 'info.dark', marginBottom: "-3px", fontSize: '12px', fontWeight: 500, pr: '2px' }}>
        {title}
      </Text>

      <Row position="relative" justifyContent="space-between">
        <BaseBtn btnColor="info.main" onClick={onOpenMenu} sx={{ height: '29px', minWidth: '100%', px: 0 }}>
          <Text
            noWrap
            sx={{
              color: 'primary.dark',
              fontSize: '14px',
              fontWeight: 500,
              width: '240px',
              pl: '10px',
              textAlign: 'left',
              fontFamily: 'Noto Sans Hebrew !@important',
            }}
          >
            {title} {valueItems.length}
            {/* <span>{currentFilter?.label}</span> */}
          </Text>

          <PlayArrow
            sx={{
              m: '7px',
              fontSize: '14px',
              color: 'primary.dark',
              transform: `rotate(${isOpen ? '-90deg' : '90deg'})`,
            }}
          />
        </BaseBtn>
      </Row>

      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        sx={{
          mt: '5px',
          '& .MuiPaper-root': {
            borderRadius: '12px',
          },
        }}
      >
        <Box>
          <Box
            sx={{
              borderTop: '1px solid grey',
              borderColor: 'info.main',
            }}
          >
            <FormControl sx={{ width: '100%' }}>
              <RadioGroup value={value} sx={{ width: '100%' }}>
                <Box
                  sx={{
                    maxHeight: '600px',
                  }}
                >
                  {valueItems.map(({ id, name, expirationDate }) => (
                    <ControlLabelWithDelete
                      key={id}
                      onChange={() => handleChange(id)}
                      value={name}
                      labels={{ id, name, expirationDate }}
                      tooltipText={tooltipText}
                      onExpirationDateClick={onExpirationDateClick}
                    />
                  ))}
                </Box>
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

import { DashboardDetails } from 'shared/models';
import { getFormattedDate } from 'shared/lib';
import { useTranslation } from 'react-i18next';
// import { toHerb } from 'features/ExportBtn/hooks';
export const useFormatXlsxData = () => {
  const { t } = useTranslation();
 
  const formatTable = (arr: DashboardDetails[]) => {
    const exportArr = arr.map((item, index) => {
      return {
        [`${1} ${t('objectID_dashboard_details_grid_table')}`]: item.objectID,
        [`${2} ${t('hWID_dashboard_details_grid_table')}`]: item.hwid,
        [`${3} ${t('object_name_dashboard_details_grid_table')}`]: item.objectName,
        [`${4} ${t('customer_id_dashboard_details_grid_table')}`]: item.customerID,
        [`${5} ${t('customer_name_dashboard_details_grid_table')}`]: item.customerName,
        [`${6} ${t('model_id_dashboard_details_grid_table')}`]: item.modelID,
        [`${7} ${t('device_type_dashboard_details_grid_table')}`]: item.deviceType,
        [`${8} ${t('controller_version_dashboard_details_grid_table')}`]: item.controllerVersion,
        [`${9} ${t('install_date_dashboard_details_grid_table')}`]: item.installDate,
        [`${10} ${t('last_comm_dt_dashboard_details_grid_table')}`]: item.lastCommDT,
        [`${11} ${t('cell_provider_dashboard_details_grid_table')}`]: item.cellProvider,
        [`${12} ${t('modem_type_dashboard_details_grid_table')}`]: item.modemType,
        [`${13} ${t('modem_last_3g_4g_dashboard_details_grid_table')}`]: item.modemLast3G4G,
        [`${14} ${t('sim_number_dashboard_details_grid_table')}`]: item.simNumber,
        [`${15} ${t('disabled_flag_dashboard_details_grid_table')}`]: t(item.disabledFlag),
        [`${16} ${t('comm_adapter_type_dashboard_details_grid_table')}`]: item.commAdapterType,
        [`${17} ${t('comm_adapter_ver_dashboard_details_grid_table')}`]: item.commAdapterVer,
        [`${18} ${t('controller_type_dashboard_details_grid_table')}`]: item.controllerType,
        [`${19} ${t('modem_board_type_dashboard_details_grid_table')}`]: item.modemBoardType,
        [`${20} ${t('customer_type_dashboard_details_grid_table')}`]: item.customerType,
        [`${21} ${t('notes_dashboard_details_grid_table')}`]: item.notes,
        [`${22} ${t('longitude_dec_dashboard_details_grid_table')}`]: item.longitude_Dec,
        [`${23} ${t('latitude_dec_dashboard_details_grid_table')}`]: item.latitude_Dec,
        [`${24} ${t('device_type2_dashboard_details_grid_table')}`]: item.deviceType2,
        [`${25} ${t('last_change_dt_dashboard_details_grid_table')}`]: item.lastChangeDT,
        [`${26} ${t('device_info_auto_note_dashboard_details_grid_table')}`]: item.deviceInfoAutoNote,

      };
      
    });
    
    return exportArr;
  };

  return { formatTable };
};

import { Typography, TypographyProps } from '@mui/material';
import { FC } from 'react';
import { isHebrewFont } from 'shared/lib';

export const Text: FC<TypographyProps> = ({ children, ...props }) => {
  return (
    <Typography
      {...props}
      sx={{
        color: 'info.main',

        fontFamily: isHebrewFont(children?.toString() ?? '') ? 'Noto Sans Hebrew' : 'Inter',
        ...props.sx,
      }}
    >
      {children}
    </Typography>
  );
};

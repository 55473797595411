import React, { FC, PropsWithChildren } from 'react';
import { IconButton, Snackbar, Box, SlideProps, Slide } from '@mui/material';
import { close } from '../../shared/assets';
import { Icon, Text } from '../../shared/ui';
import { bg_bottom, bg_top, sign } from './assets';
interface Props extends PropsWithChildren {
  open: boolean;
  message: string;
  onClose: () => void;
}

export const HelloToast: FC<Props> = ({ open, message, onClose, children }) => {
  const handleClose = (e: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return;
    onClose();
  };

  // function SlideTransition(props: SlideProps) {
  //   return <Slide {...props} direction="left" />;
  // }

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      sx={{
        mb: '40px',
        mr: '100px',
      }}
    >
      <Box
        sx={{
          width: '348px',
          height: '95px',
          borderRadius: '20px',
          bgcolor: 'primary.contrastText',
          position: 'relative',
        }}
      >
        <Icon src={bg_top} sx={{ position: 'absolute', left: '18px', top: '-20px' }} />
        <Icon src={sign} sx={{ position: 'absolute', left: '36px', top: '-8px' }} />
        <Icon src={bg_bottom} sx={{ position: 'absolute', bottom: 0, left: 0 }} />

        <Box sx={{ ml: '70px', mt: '15px', width: '242px' }}>
          <Text sx={{ fontFamily: 'Noto Sans Hebrew', fontSize: 20, lineHeight: '30px' }}>{message}</Text>
          <Box sx={{ fontFamily: 'Noto Sans Hebrew', fontSize: 13, lineHeight: '17px' }}>{children}</Box>
        </Box>

        <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 10, right: 10 }}>
          <Icon src={close} />
        </IconButton>
      </Box>
    </Snackbar>
  );
};

import { TableTitle, TranslateColumn } from 'components/BaseTable';
import { ICItem } from '../networkProtectionModels';


export const TABLE_TITLES: TableTitle<ICItem>[] = [
	{ title: 'ic_project_ID', key: 'icProjectID', width: '100%' },
	{ title: 'ic_project_name', key: 'icProjectName', width: '150%' },
	{ title: 'dst_object_ID', key: 'dstObjectID', width: '100%' },
	{ title: 'dst_object_name', key: 'dstObjectName', width: '200%' },
	{ title: 'src_object_ID', key: 'srcObjectID', width: '100%' },
	{ title: 'src_object_name', key: 'srcObjectName', width: '200%' },
	{ title: 'com_status', key: 'comStatus', width: '100%' },
	{ title: 'src_dest_count', key: 'srcDestCount', width: '100%' },
];

export const TRANSLATE_COLUMNS: TranslateColumn<ICItem>[] = [
	{ key: 'comStatus', preKey: 'ic_project_status_' },

]
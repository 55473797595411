import { Box } from '@mui/material';
import { BaseExportToExcel, BaseTable } from 'components';
import { BaseSelect, Filter } from 'features';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGetCustomers } from 'shared/api';
import { action_start, delete_icon, duplicate, edit } from 'shared/assets';
import { useAppDispatch, useAppSelector, useExtendedTranslation, useToast } from 'shared/hooks';
import { getSearchedControllers } from 'shared/lib';
import localStorageService from 'shared/localStorage';

import { BaseBtn, BigTitle, Icon, Loader, Row, SearchInput, Text, TotalRows } from 'shared/ui';
import { AddController, DeviceTransferring, EditController, UpdateDeviceName } from './components';
import { TABLE_TITLES } from './constants';
import { Controller } from './controllerModels';
import { setControllers, setCustomerFilter } from './controllerSlice';
import { clearSelectedController } from './deviceTransferringSlice';
import { useControllers, useFilterControllers, useFormatXlsxData, useSearchControllers } from './hooks';
import { BaseExportOptions } from 'entities_';
export const ControllerManager = () => {
  const dispatch = useAppDispatch();
  const { isLoadingCustomers, getCustomer } = useGetCustomers();
  const { getControllers, isLoadingControllers } = useControllers();
  const loading = isLoadingCustomers;
  const { controllers } = useAppSelector((st) => st.controllers);
  const { customerFilter, onChangeCustomerFilter, customerFilterItems } = useFilterControllers();
  const { actionsFilter, onChangeActionsFilter, actionsFilterItems } = useFilterControllers();
  const { showError } = useToast();
  const { formatTable } = useFormatXlsxData();
  const [selectedController, setSelectedController] = useState<Controller>(null);
  const [openControllerEditModal, setOpenControllerEditModal] = useState(false);
  const [openAddControllerModal, setOpenAddControllerModal] = useState(false);
  const [openDeviceTransferringModal, setOpenDeviceTransferringModal] = useState(false);
  const [selectedControllersID, setSelectedControllersID] = useState<number[]>([]);
  const [openUpdateDeviceNameModal, setOpenUpdateDeviceNameModal] = useState(false);
  useEffect(() => {
    dispatch(setCustomerFilter(87));
    const fetchData = async () => {
      await Promise.all([getCustomer()]);
    };
    fetchData();
    return () => {
      dispatch(setCustomerFilter(''));
      dispatch(setControllers([]));
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (customerFilter) {
        await getControllers(parseInt(customerFilter) || '');
      }
    };
    fetchData();

    dispatch(clearSelectedController());
  }, [customerFilter]);



  const { searchValue, changeSearch } = useSearchControllers();
  const { t } = useExtendedTranslation();
  const isAccess = () => {
    const token = localStorageService.userToken;
    if (!token) return;
    const [encodedHeader, encodedPayload, signature] = token.split('.');
    const payload = JSON.parse(atob(encodedPayload));
    if (+payload.backOfficeAccessLevel < 10) {
      showError(t('you_do_not_have_sufficient_permissions_to_use_this_feature_toast_text'));
      return;
    }
    return true;
  };
  const onClickActionStart = () => {
    if (actionsFilter === '1') {
      onClickOpenDeviceTransferring();
      return;
    }
    if (actionsFilter === '2') {
      handleOpenUpdateDeviceNameModal();
      return;
    }
  };
  const onClickOpenDeviceTransferring = () => {
    if (isAccess()) {
      setOpenDeviceTransferringModal(true);
    }
  };

  const tableControllers = useMemo(() => {

    const filteredControllers = controllers;
    const searchedControllers = getSearchedControllers(filteredControllers, searchValue);

    return searchedControllers;
  }, [searchValue, controllers]);

  const handleOpenControllerEditModal = (row: Controller) => {
    setSelectedController(row);
    setOpenControllerEditModal(true);
  };
  const closeControllerEditModal = () => {
    setOpenControllerEditModal(false);
  };
  const handleOpenAddControllerModal = () => {
    setOpenAddControllerModal(true);
  };
  const closeAddControllerModal = () => {
    setOpenAddControllerModal(false);
  };
  const closeDeviceTransferringModal = () => {
    setOpenDeviceTransferringModal(false);
  };
  const rowSelectionModelChange = useCallback((rows) => {
    setSelectedControllersID(rows);
  }, []);
  const actions = useMemo(
    () => [
      { icon: edit, title: `${t('edit-user-grid-table-modal')}`, onClick: handleOpenControllerEditModal },
      { icon: delete_icon, title: `${t('delete-customers-grid-table-modal')}`, onClick: () => { } },
      { icon: duplicate, title: `${t('duplicate-customers-grid-table-modal')}`, onClick: () => { } },
    ],
    []
  );
  const handleOpenUpdateDeviceNameModal = () => {
    setOpenUpdateDeviceNameModal(true);
  };
  const closeUpdateDeviceNameModal = () => {
    setOpenUpdateDeviceNameModal(false);
  };
  const BaseExportToExcelComponent = useMemo(
    () => (<BaseExportOptions
      tableData={tableControllers}
      sheetName="CONTROLLERS"
      fileName="controllers.xlsx"
      buttonText={t('export_controllers_page_button')}
      dateColumnIndexes={[11]}
      formattedTable={formatTable(tableControllers)}
      tableTitles={TABLE_TITLES}
      headerItemsPrefix={'_controller_grid_table'}
    />)
    ,
    [tableControllers]
  );
  const ControllerManager = useMemo(() => {
    return <BaseTable
      tableTitles={TABLE_TITLES}
      tableItems={tableControllers}
      loadedItems={!isLoadingControllers}
      selectedRowID={selectedController?.deviceId}
      idField={'deviceId'}
      noItemsText={t('no_controllers_controllers_grid_table')}
      headerItemsPrefix={'_controller_grid_table'}
      dateColumnIndexes={[11]}
      linkIndex={[1]}
      cellClick={handleOpenControllerEditModal}
      actions={actions}
      sortModel={[{ field: 'objectName', sort: 'asc' }]}
      checkboxSelection
      onRowSelectionModelChange={rowSelectionModelChange}
      hideSelectAll={isNaN(parseInt(customerFilter)) || customerFilter === '87'}
      loading={loading}
    />
  }, [tableControllers, loading, isLoadingControllers])
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Row sx={{ mb: '12px' }}>
            <BigTitle sx={{ mr: '25px' }}>{t('controllers_title')}</BigTitle>
            <BaseBtn onClick={handleOpenAddControllerModal}>
              {t('add_controler_button')}
              <Text sx={{ fontSize: '25px', mb: '4px', ml: '4px' }}>+</Text>
            </BaseBtn>
          </Row>
          <SearchInput
            value={searchValue}
            onChange={changeSearch}
            placeholder={`${t('which_client_are_you_looking_for_search_placeholder_controller_page')}`}
          />
          <Row sx={{ mb: '12px' }} flexWrap="wrap" rowGap="5px">
            <BigTitle sx={{ fontSize: '24px', mr: '20px', mt: '16px' }}>{t('filter_controllers_page_title')}</BigTitle>
            <BaseSelect
              title={`${t('customer_label')}`}
              value={customerFilter}
              onChange={onChangeCustomerFilter}
              items={customerFilterItems}
              hideAllButton
              showHomeButton
              defaultValue={'87'}
              withSearch
              sx={{ width: '260px' }}
            />
            <BigTitle sx={{ fontSize: '24px', mr: '20px', mt: '16px' }}>
              {t('selected_controllers_page_title')}
            </BigTitle>
            <TotalRows count={selectedControllersID.length} />
            <Filter
              title={`${t('actions_filter')}`}
              value={actionsFilter}
              onChange={onChangeActionsFilter}
              items={actionsFilterItems}
              hideAllButton
              sx={{ width: '260px' }}
              messageEmptyFilter={`${t('actions_please_select_an_action')}`}
              placeholder={`${t('filter_search_controller_page_placeholder')}`}
            />
            {/* <CustomerActionBtn actionsFilter={actionsFilter} onClick={onClickOpenDeviceTransferring} /> */}
            <BaseBtn
              onClick={onClickActionStart}
              disabled={!actionsFilter || !selectedControllersID.length}
              sx={{ mt: '16px', height: '29px' }}
            >
              {t('controllers_action_start')}
              <Icon sx={{ ml: '6px' }} src={action_start} />
            </BaseBtn>

            <Row sx={{ flexGrow: 1 }} />
            <Box sx={{ display: 'flex', marginLeft: 'auto', alignItems: 'flex-end' }}>
              <TotalRows count={tableControllers.length} />
              {BaseExportToExcelComponent}
            </Box>
          </Row>

          {ControllerManager}
        </>
      )}
      <EditController
        currentController={selectedController}
        openControllerEditModal={openControllerEditModal}
        onCloseModal={closeControllerEditModal}
        showRegistersButton
      />
      <AddController
        customerFilter={customerFilter}
        openAddControllerModal={openAddControllerModal}
        onCloseModal={closeAddControllerModal}
      />
      <DeviceTransferring
        selectedControllers={selectedControllersID}
        openDeviceTransferring={openDeviceTransferringModal}
        onCloseModal={closeDeviceTransferringModal}
      />
      <UpdateDeviceName
        selectedControllers={selectedControllersID}
        openUpdateDeviceName={openUpdateDeviceNameModal}
        onCloseModal={closeUpdateDeviceNameModal}
        customerFilter={customerFilter}
      />
    </>
  );
};

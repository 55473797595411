import { Fota, FotaFile} from '../fotaModels';

import { TableTitle, TranslateColumn} from 'components/BaseTable';

export const All_FOTA_TABLE_TITLES: TableTitle<Fota>[] = [
  { title: 'Customer_ID', key: 'customerID', width: 60 },
  { title: 'Customer_Name', key: 'customerName', width: 160 },
  { title: 'Customer_Zone', key: 'customerZone', width: 160 },
  { title: 'HWID', key: 'hwid', width: 80 },
  { title: 'Object_Name', key: 'objectName', width: 320 },
  { title: 'Device_Type', key: 'deviceType', width: 120 },
  { title: 'Version_Current', key: 'versionCurrent', width: 120 },
  { title: 'Version_New', key: 'versionNew', width: 120 },
  { title: 'Is_Allow_Update', key: 'isAllowUpdate', width: 160 },
  { title: 'Last_Request', key: 'lastRequest', width: 160 },
  { title: 'Last_Operation_DT', key: 'lastOperationDT', width: 200, type:'date' },
  { title: 'Download_Percent', key: 'downloadPercent', width: 120 },
  { title: 'Last_Request_DT', key: 'lastRequestDT', width: 200, type:'date' },
  { title: 'Notes', key: 'notes', width: 280 },
  { title: 'download_Time', key: 'downloadTime', width: 160 },
  { title: 'last_Comm_DT', key: 'lastCommDT', width: 160, type:'date'},
  { title: 'controller_Version', key: 'controllerVersion', width: 160 },
  { title: 'model_ID', key: 'modelID', width: 160 },
  { title: 'object_ID', key: 'objectID', width: 160 },
  { title: 'di_Device_Type', key: 'diDeviceType', width: 160 },
  { title: 'customer_Type', key: 'customerType', width: 160 },
  { title: 'special_Version', key: 'specialVersion', width: 160 },
  { title: 'fs_Device_Type', key: 'fsDeviceType', width: 160 },
  { title: 'current_Version_Min', key: 'currentVersionMin', width: 160 },
  { title: 'current_Version_Max', key: 'currentVersionMax', width: 160 },
  { title: 'new_Version_Min', key: 'newVersionMin', width: 160 },
  { title: 'new_Version_Max', key: 'newVersionMax', width: 160 },
  { title: 'download_Time_From', key: 'downloadTimeFrom', width: 160 },
  { title: 'download_Time_To', key: 'downloadTimeTo', width: 160 },
  { title: 'restart_Time_From', key: 'restartTimeFrom', width: 160 },
  { title: 'restart_Time_To', key: 'restartTimeTo', width: 160 },
  { title: 'check_Time', key: 'checkTime', width: 160, type:'date'},
  { title: 'check_Current_Version', key: 'checkCurrentVersion', width: 160 },
  { title: 'check_New_Version', key: 'checkNewVersion', width: 160 },
  { title: 'check_Flash_Bank_Current', key: 'checkFlashBankCurrent', width: 160 },
  { title: 'check_Flash_Bank_Boot', key: 'checkFlashBankBoot', width: 160 },
  { title: 'check_Result', key: 'checkResult', width: 160 },
  { title: 'download_Version', key: 'downloadVersion', width: 160 },
  { title: 'download_Offset', key: 'downloadOffset', width: 160 },
  { title: 'confirm_Time', key: 'confirmTime', width: 160, type:'date'},
  { title: 'confirm_Current_Version', key: 'confirmCurrentVersion', width: 160 },
  { title: 'confirm_New_Version', key: 'confirmNewVersion', width: 160 },
  { title: 'confirm_Is_CRC_Valid', key: 'confirmIsCRCValid', width: 160 },
  { title: 'confirm_Result', key: 'confirmResult', width: 160 },
  { title: 'device_Type_From_Request', key: 'deviceTypeFromRequest', width: 160 },
  { title: 'download_Value', key: 'downloadValue', width: 160 },
];


export const FOTA_TABLE_TITLES: TableTitle<Fota>[] = [
  { title: 'Customer_ID', key: 'customerID', width: 60 },
  { title: 'Customer_Name', key: 'customerName', width: '100%' },
  { title: 'Customer_Zone', key: 'customerZone', width: '100%' },
  { title: 'HWID', key: 'hwid', width: '50%' },
  { title: 'Object_Name', key: 'objectName', width: '200%' },
  { title: 'Device_Type', key: 'deviceType', width: '75%' },
  { title: 'Version_Current', key: 'versionCurrent', width: '75%' },
  { title: 'Version_New', key: 'versionNew', width: '75%' },
  { title: 'Is_Allow_Update', key: 'isAllowUpdate', width: '100%' },
  { title: 'Last_Request', key: 'lastRequest', width: '100%' },
  { title: 'Last_Operation_DT', key: 'lastOperationDT', width: '125%', type:'date'},
  { title: 'Download_Percent', key: 'downloadPercent', width: '75%' },
  { title: 'Last_Request_DT', key: 'lastRequestDT', width: '125%', type:'date'},
  { title: 'Notes', key: 'notes', width: '100%' },
];

export const TRANSLATE_COLUMNS: TranslateColumn<Fota>[] = [
  { key: 'deviceType', additionalKey: '_device_type' },
  { key: 'isAllowUpdate', additionalKey: '_fota_status' },
  { key: 'lastRequest', additionalKey: '_last_request' },
  { key: 'downloadPercent', additionalKey: '%' },
  { key: 'downloadValue', additionalKey: '%' },
  
]

export const FOTA_FILES_TABLE_TITLES: TableTitle<FotaFile>[] = [
  { title: 'device_Type', key: 'deviceType', width: '100%' },
  { title: 'version', key: 'version', width: '100%' },
  { title: 'fileName', key: 'fileName', width: '200%' },
  { title: 'note', key: 'note', width: '200%' },

];
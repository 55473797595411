import { Box, IconButton, Stack } from '@mui/material';
import { User, UserHistory } from 'features/UserManager/UserModels';

import { UnderConstructionBlock } from 'entities_';
import { t } from 'i18next';
import { FC } from 'react';
import { close_popup } from 'shared/assets';
import { BaseBtn, BaseInput, BigTitle, Modal, Row, Text } from 'shared/ui';
import { getFormattedDateAndTime } from 'shared/lib';

interface ModalState {
	isOpenModal: boolean
	currentUserHistory: UserHistory
	closeModal: () => void
}

export const UserHistoryInfoEditModal: FC<ModalState> = (props: ModalState) => {
	const { isOpenModal, closeModal, currentUserHistory } = props;


	const handleCloseEditModal = () => {
		closeModal();
	}

	return (
		<Modal open={isOpenModal} onClose={handleCloseEditModal}>
			<Stack direction="row" justifyContent="space-between" alignItems="center">
				<BigTitle><span>{t('user_action_history_modal_title')}</span></BigTitle>

				<IconButton onClick={handleCloseEditModal}>
					<img src={close_popup} />
				</IconButton>
			</Stack>
			{currentUserHistory && <Box>
				<Row sx={{ mb: '16px' }} alignItems="flex-start" justifyContent="space-between">
					<Box width={'32%'}>
						<BaseInput
							label={`${t('action_code_label_authentication_details_modal')}`}
							name="actionCode"
							onChange={(event) => { }}
							value={t(`${currentUserHistory.actionCode}_action_code`)}
							small
							compact
							helperText={''}
							disabled
						/>
					</Box>
					<Box width={'32%'}>
						<BaseInput
							label={t('action_date_label_authentication_details_modal')}
							name="actionDate"
							onChange={(event) => { }}
							value={getFormattedDateAndTime(currentUserHistory.actionDate)}
							small
							compact
							helperText={''}
							disabled
						/>
					</Box>
					<Box width={'32%'}>
						<BaseInput
							label={`${t('status_label_authentication_details_modal')}`}
							name="status"
							onChange={(event) => { }}
							value={t(`${currentUserHistory.status}_status_code`)}
							small
							compact
							helperText={''}
							disabled
						/>
					</Box>
				</Row>
				<Row sx={{ mb: '16px' }} alignItems="flex-start" justifyContent="space-between">
					<Box width={'32%'}>
						<BaseInput
							label={`${t('app_key_label_authentication_details_modal')}`}
							name="appKey"
							onChange={(event) => { }}
							value={currentUserHistory.appKey}
							small
							compact
							helperText={''}
							disabled
						/>
					</Box>
					<Box width={'32%'}>
						<BaseInput
							label={`${t('app_name_label_authentication_details_modal')}`}
							name="appName"
							onChange={(event) => { }}
							value={currentUserHistory.appName}
							small
							compact
							helperText={''}
							disabled
						/>
					</Box>
					<Box width={'32%'}>

					</Box>
				</Row>
				<Text sx={{ fontSize: '20px', m: 0, fontWeight: 900, color: 'primary.dark' }}>{t('user_details_title')}</Text>
				<Row sx={{ mb: '16px' }} alignItems="flex-start" justifyContent="space-between">
					<Box width={'32%'}>
						<BaseInput
							label={`${t('user_name_label_authentication_details_modal')}`}
							name="userName"
							onChange={(event) => { }}
							value={currentUserHistory.userName}
							small
							compact
							helperText={''}
							disabled
						/>
					</Box>
					<Box width={'32%'}>
						<BaseInput
							label={`${t('user_login_label_authentication_details_modal')}`}
							name="userLogin"
							onChange={(event) => { }}
							value={currentUserHistory.userLogin}
							small
							compact
							helperText={''}
							disabled
						/>
					</Box>
					<Box width={'32%'}>
						<BaseInput
							label={`${t('user_id_label_authentication_details_modal')}`}
							name="userID"
							onChange={(event) => { }}
							value={currentUserHistory.userID}
							small
							compact
							helperText={''}
							disabled
						/>
					</Box>

				</Row>
				<Text sx={{ fontSize: '20px', m: 0, fontWeight: 900, color: 'primary.dark' }}>{t('user_performed_the_operation_title')}</Text>
				<Row sx={{ mb: '16px' }} alignItems="flex-start" justifyContent="space-between">
					<Box width={'32%'}>
						<BaseInput
							label={`${t('action_by_user_name_label_authentication_details_modal')}`}
							name="actionByUserName"
							onChange={(event) => { }}
							value={currentUserHistory.actionByUserName}
							small
							compact
							helperText={''}
							disabled
						/>
					</Box>
					<Box width={'32%'}>
						<BaseInput
							label={`${t('action_by_user_login_label_authentication_details_modal')}`}
							name="actionByUserLogin"
							onChange={(event) => { }}
							value={currentUserHistory.actionByUserLogin}
							small
							compact
							helperText={''}
							disabled
						/>
					</Box>
					<Box width={'32%'}>
						<BaseInput
							label={`${t('action_by_user_id_label_authentication_details_modal')}`}
							name="actionByUserID"
							onChange={(event) => { }}
							value={currentUserHistory.actionByUserID}
							small
							compact
							helperText={''}
							disabled
						/>
					</Box>



				</Row>
				<Row justifyContent="flex-end" mt={'35px'}>
					<Row width={300}>
						<BaseBtn onClick={handleCloseEditModal} btnColor="info.main" fullWidth>
							{t('close_button_authentication_details_modal')}
						</BaseBtn>
					</Row>
				</Row>
			</Box>}
		</Modal >
	);
};
import { Slide, SlideProps, Snackbar } from '@mui/material';
import React, { FC, PropsWithChildren } from 'react';
import { Toast } from '../../features';
import { ToastContext } from 'shared/contexts';

export const ToastWrapper: FC<PropsWithChildren> = ({ children }) => {
  const [open, setOpen] = React.useState(false);
  const [color, setColor] = React.useState<'error' | 'success'>('error');
  const [message, setMessage] = React.useState('');

  const handleClose = (_: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return;
    setOpen(false);
  };

  function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="down" />;
  }

  const showSuccess = (message: string) => {
    setColor('success');
    setMessage(message);
    setOpen(true);
  };
  const showError = (message: string) => {
    setColor('error');
    setMessage(message);

    setOpen(true);
  };

  return (
    <ToastContext.Provider value={{ showSuccess, showError }}>
      <Snackbar
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={SlideTransition}
        autoHideDuration={4000}
        sx={{ zIndex: !open ? '-1' : '1400' }}
      >
        <Toast color={color} message={message} onClose={handleClose} />
      </Snackbar>

      {children}
    </ToastContext.Provider>
  );
};

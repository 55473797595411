
import { useTranslation } from 'react-i18next';
import { ServiceCall } from '../ServiceCallModels';
export const useFormatXlsxData = () => {
  const { t } = useTranslation();
  
  const formatTable = (arr: ServiceCall[]) => {
    const exportArr = arr.map((item) => {

      return {
        [t('customer_id_service_calls_grid_table')]: item.customerID,
        [t('customer_name_service_calls_grid_table')]: item.customerName,
        [t('controller_id_service_calls_grid_table')]: item.controllerID,
        [t('controller_name_service_calls_grid_table')]: item.controllerName,
        [t('fault_equipment_service_calls_grid_table')]:item.faultEquipment ? item.faultEquipment.split(",").map(item => t(item)).join(", ") : "",
        [t('hwid_service_calls_grid_table')]: item.hwid,
        [t('action_type_service_calls_grid_table')]: item.actionType ? item.actionType.split(",").map(item => t(item)).join(", "): "",
        [t('assigned_tech_service_calls_grid_table')]: item.assignedTech,
        [t('calls_count_service_calls_grid_table')]: item.callsCount,
      };
    });

    return exportArr;
  };

  return { formatTable };
};

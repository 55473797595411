import useAxios from 'app/useAxios';
import { WaTemplatesResult, WaMessageSettings } from 'features/CustomerManager/whatsAppModels';
import { setMessageTemplates } from 'features/CustomerManager/whatsAppSlice';
import { useAppDispatch } from 'shared/hooks';


export const enum DataSourceTypes {Realtime=0,History=1}
export const useMessageTemplates = () => {
  const dispatch = useAppDispatch();
  const { loading, request,error} = useAxios();

  return {
        getMessageTemplates: () =>
        request<undefined, { data: WaTemplatesResult, succeeded:boolean }>({
        method: 'get',
        showSuccessMessageCode: undefined,
        url: `/WhatsApp/messagetemplates`,
        onDataReceiveCompleted: (data) => {
            if(data.succeeded){
            dispatch(setMessageTemplates(data.data));
            }else{
           //showError(t('something_went_wrong_toast_text'));
            }
        },
        }),
        sendWAMessage:(waMessageSettings: WaMessageSettings)=>
        request<undefined, { data: any, succeeded:boolean }>({
          method: 'post',
          showSuccessMessageCode: undefined,
          url: `/WhatsApp/message`,
          payload: waMessageSettings,
          onDataReceiveCompleted: (data) => {
              if(data.succeeded){
              return true
              }else{
                return false
              }
          },
          }),
      isLoading: loading,
      error
  };
};
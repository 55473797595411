import { useState } from 'react';
import { useAppSelector } from 'shared/hooks';
import { useAppDispatch } from 'shared/hooks';
import { setCustomerToMove } from 'shared/slices';
import { resetSelectedControllersToMove } from 'shared/slices';

export const useCustomerToMove = () => {
  const { customers } = useAppSelector((st) => st.customers);

  const [selectedCustomerToMove, setSelectedCustomerToMove] = useState<string>(null);

  const customerItems = customers.map((s) => ({
    value: s.id.toString(),
    label: s.customerName,
  }));

  const onChangeCustomerToMove = (value: string) => {
    setSelectedCustomerToMove(value)
    // if (customerIDForFilter) {
    //   dispatch(resetSelectedControllersToMove());
    // }
    // dispatch(setCustomerToMove(parseInt(value)));
  };

  return {
    onChangeCustomerToMove,
    customerItems,
    selectedCustomerToMove
  };
};

export const autoFillColor = {
  '&:-webkit-autofill': {
    WebkitBoxShadow: '0 0 0 30px white inset !important',
    WebkitTextFillColor: 'black !important',
  },
  '&:-webkit-autofill:hover': {
    WebkitBoxShadow: '0 0 0 30px white inset !important',
    WebkitTextFillColor: 'black !important',
  },
  '&:-webkit-autofill:focus': {
    WebkitBoxShadow: '0 0 0 30px white inset !important',
    WebkitTextFillColor: 'black !important',
  },
  '&:-webkit-autofill:active': {
    WebkitBoxShadow: '0 0 0 30px white inset !important',
    WebkitTextFillColor: 'black !important',
  },
};

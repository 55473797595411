import { EquipmentReplacementReportItem } from 'shared/models';
import { getFormattedDate } from 'shared/lib';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'shared/hooks';
export const useFormatXlsxData = () => {
  const { t } = useTranslation();
  
	const {  equipmentTypes } = useAppSelector((st) => st.equipmentReplacementReport);
  const formatTable = (arr: EquipmentReplacementReportItem[]) => {
    const exportArr = arr.map((item) => {
      const tableTitles=[]
      equipmentTypes.forEach((element =>{
        // eslint-disable-next-line no-prototype-builtins
        if(item.hasOwnProperty(element.name)){
          tableTitles.push( element.name)
        }
      }))
      const result = {
        [t('equipment_type_DATE_FROM')]: item.DateFrom ? getFormattedDate(item.DateFrom): "",
        [t('equipment_type_DATE_TO')]: item.DateTo ? getFormattedDate(item.DateTo): "",
        [t('equipment_type_CUSTOMER_ID')]: item.CustomerID,
        [t('equipment_type_CUSTOMER_NAME')]: item.CustomerName,
      }

      tableTitles.map((element, i) => 
        result[t(`equipment_type_${element}`)] = item[element]
      )

      return result;
    });

    return exportArr;
  };

  return { formatTable };
};

import { MenuList, Row, Text } from 'shared/ui';
import { Avatar, Box } from '@mui/material';
import { getNameLetters } from './helpers/getNameLetters';
import { useAnchorEl, useAuth, useExtendedTranslation, useAppDispatch } from 'shared/hooks';
import { toggleOpenAbout } from 'shared/slices';
import { About } from 'widgets';
import localStorageService from 'shared/localStorage';
export const UserInfo = () => {
  const { t, changeLanguage } = useExtendedTranslation();
  const dispatch = useAppDispatch();
  const { logout } = useAuth();

  const { isOpen, anchorEl, onOpenMenu, onCloseMenu } = useAnchorEl();

  const name = localStorageService.currentUserName ?? '';
  const currentLang = localStorageService.currentLang;

  const handleChangeLang = () => {
    const currentLang = localStorageService.currentLang;
    if (currentLang === 'en') {
      changeLanguage('he');
    } else if (currentLang === 'he') {
      changeLanguage('ar-sy');
    } else {
      changeLanguage('en');
    }
    onCloseMenu();
  };
  const showAboutInfo = () => {
    onCloseMenu();
    dispatch(toggleOpenAbout());
  };
  return (
    <>
      <Row
        sx={
          {
            /* mr: '35px' */
          }
        }
      >
        <Avatar
          onClick={onOpenMenu}
          sx={{
            bgcolor: 'info.main',
            color: 'primary.main',
            cursor: 'pointer',
          }}
        >
          {getNameLetters(name)}
        </Avatar>
        <Box sx={{ ml: '8px' }}>
          <Text>{name}</Text>
        </Box>
      </Row>

      <MenuList
        open={isOpen}
        anchorEl={anchorEl}
        onClose={onCloseMenu}
        actions={[
          { title: t('logout_button'), onClick: logout },
          { title: `${currentLang === 'ar-sy' ? 'SY' : currentLang?.toUpperCase()}`, onClick: handleChangeLang },
          { title: `${t('version_title')} ${process.env.REACT_APP_VERSION}` },
          { title: t('about_button'), onClick: showAboutInfo },
        ]}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          mt: '42px',
          '& .MuiPaper-root': {
            borderRadius: '12px',
          },
        }}
      />
      <About />
    </>
  );
};

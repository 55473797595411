const DEFAULT_USER_LETTER = 'U';

export const getNameLetters = (str: string) => {
  if (!str) return DEFAULT_USER_LETTER;

  return str
    .toUpperCase()
    .split(' ')
    .map((w) => w[0])
    .join('');
};

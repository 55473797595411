import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { axiosBase } from 'shared/config';
import { useAppDispatch, useToast } from 'shared/hooks';
import {
  EquipmentType
} from 'shared/models';
import { setEquipmentTypes, addEquipmentReplacementReportItems} from 'shared/slices';
export const useReplacedEquipmentReportManagement = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { showError, showSuccess } = useToast();
  const push = useNavigate();

  const [isLoading, setLoading] = useState(false);

  const getReplacedEquipmentTypes = async () => {
    setLoading(true);
    try {
      const { data } = await axiosBase.get('/replaceequipment/types',);

      const equipmentTypes: EquipmentType[] = data?.data?.result.map((item=>({name:item, id: item})));
      dispatch(setEquipmentTypes( equipmentTypes));

    } catch (error: any) {
      console.log('#ERROR', error);
      if (error?.response?.status === 401) {
        push('/login');
      } else {
        showError(t('error_toast_text'));
      }
    } finally {
      setLoading(false);
    }
  };
	const getReplacedEquipmentReport = async (values) => {
		// eslint-disable-next-line no-useless-catch
		try {
			const { data } = await axiosBase.post('/replaceequipment/report', values);
			const replacedEquipmentReport = data?.data;

			dispatch(addEquipmentReplacementReportItems( replacedEquipmentReport.result));
		} catch (error) {
			throw error;
		} 
	}


  return { isLoading, getReplacedEquipmentTypes, getReplacedEquipmentReport };
};

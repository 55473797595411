import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector, useAnchorEl } from 'shared/hooks';
import GoogleMapReact, { fitBounds } from 'google-map-react';
import { Loader, Text, Icon} from 'shared/ui';
import { useNetworkProtectionManagement } from 'shared/api';
import { Box } from '@mui/material';
import { NodePoint, RootPoint, NodePointIP } from './components';
import { exclamation } from 'shared/assets';

interface Props {
  onlyOneProtectionUnit:boolean
}
export const NetworkProtectionMap: FC<Props> = ({onlyOneProtectionUnit}) => {
  const { GOOGLE_MAP_KEY, NETWORK_PROTECTION} = useAppSelector((st) => st.globalSlice);
  const { nodesConnections, nodesPoints, nodesLines, nodesEndPoints } = useAppSelector((st) => st.networkProtection);
  const [mapCenter, setMapCenter] = useState({
    lat: 0,
    lng: 0,
  });
  const [errorPoints, setErrorPoints] = useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { getNodesPoints, getNodesConnections, getNodesLines, getNodesEndPoints } = useNetworkProtectionManagement();

  useEffect(() => {
    const fetchData = async () => {
      getNodesPoints();
      getNodesConnections();
      getNodesLines();
      getNodesEndPoints();
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (nodesPoints.length && nodesEndPoints.length) {
      findMapCenter();
    }
  }, [nodesPoints, nodesEndPoints]);

  const allCoordinates = [
    ...nodesPoints.map((item) => [item.dstLat, item.dstLon]),
    ...nodesEndPoints.map((item) => [item.lat, item.lon]),
  ];

  const findMapCenter = () => {
    const combinedData = [
      ...nodesPoints.map(point => ({ latitude: point.dstLat, longitude: point.dstLon })),
      ...nodesEndPoints.map(point => ({ latitude: point.lat, longitude: point.lon })),
    ];
  
    const validPoints = combinedData.filter(point => point.latitude !== null && point.longitude !== null);
    if (validPoints.length > 0) {
      let minLat = Number.MAX_VALUE;
      let maxLat = -Number.MAX_VALUE;
      let minLon = Number.MAX_VALUE;
      let maxLon = -Number.MAX_VALUE;
  
      validPoints.forEach(point => {
        minLat = Math.min(minLat, point.latitude);
        maxLat = Math.max(maxLat, point.latitude);
        minLon = Math.min(minLon, point.longitude);
        maxLon = Math.max(maxLon, point.longitude);
      });
  
      const center = {
        lat: (minLat + maxLat) / 2,
        lon: (minLon + maxLon) / 2,
      };
       setMapCenter({ lat: center.lat, lng: center.lon });
       return
    }
    setErrorPoints(true)
  };

  const handleGoogleMapApi = (google) => {
    const coordinates = nodesConnections.map((item) => [
      {
        lat: item.dstLat,
        lng: item.dstLon,
      },
      {
        lat: item.srcLat,
        lng: item.srcLon,
      },
    ]);

    nodesLines.forEach((item) => {
      coordinates.push([
        {
          lat: item.dstLat,
          lng: item.dstLon,
        },
        {
          lat: item.srcLat,
          lng: item.srcLon,
        },
      ]);
    });

    coordinates.forEach((coordinate) => {
      if(coordinate[0].lat && coordinate[1].lat ){
      const flightPath = new google.maps.Polyline({
        path: coordinate,
        geodesic: true,
        strokeColor: '#000000',
        strokeOpacity: 1,
        strokeWeight: 1,
      });
   
      flightPath.setMap(google.map);
    }
    });
    const validatedCoordinates= allCoordinates.filter((item)=>{
     if(item[0] && item[1]){
      return item
     }
    })
    if (validatedCoordinates.length > 0) {
      const bounds = new google.maps.LatLngBounds();
      validatedCoordinates.forEach((coordinate) => {
        bounds.extend(new google.maps.LatLng(coordinate[0], coordinate[1]));
      });

      const boundsProps = fitBounds(
        {
          nw: { lat: bounds.getNorthEast().lat(), lng: bounds.getSouthWest().lng() },
          se: { lat: bounds.getSouthWest().lat(), lng: bounds.getNorthEast().lng() },
          ne: undefined,
          sw: undefined
        },
        {
          width: 800, 
          height: 450, 
        }
      );
      if(onlyOneProtectionUnit){
        return
      }
      google.map.setZoom(boundsProps.zoom);
    }
  };

  return (
    <Box>
      <Box sx={{ height: '450px', width: '100%' }}>
        {mapCenter && mapCenter.lat && nodesConnections.length && nodesLines.length ? (
          <GoogleMapReact
            bootstrapURLKeys={{ key: GOOGLE_MAP_KEY }}
            defaultCenter={mapCenter}
            defaultZoom={NETWORK_PROTECTION.DEFAULT_ZOOM} 
            onGoogleApiLoaded={handleGoogleMapApi}
          >
            {nodesPoints.map((item) =>
              item.nodeType === 'ROOT' ? (
                <RootPoint key={item.objectName} lat={item.dstLat} lng={item.dstLon} text={item.objectName} />
              ) : item.nodeType === 'NODE' && item.modelID === 'IP' ? (
                <NodePointIP key={item.objectName} lat={item.dstLat} lng={item.dstLon} text={item.objectName} />
              ) : (
                <NodePoint key={item.objectName} lat={item.dstLat} lng={item.dstLon} text={item.objectName} />
              )
            )}
            {nodesEndPoints.map((item) =>
              item.nodeType === 'ROOT' ? (
                <RootPoint key={item.objectName} lat={item.lat} lng={item.lon} text={item.objectName} />
              ) : item.nodeType === 'NODE' && item.modelID === 'IP' ? (
                <NodePointIP key={item.objectName} lat={item.lat} lng={item.lon} text={item.objectName} />
              ) : (
                <NodePoint key={item.objectName} lat={item.lat} lng={item.lon} text={item.objectName} />
              )
            )}
          </GoogleMapReact>
        ) : (
          errorPoints ? <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', height: '100%'}}><Text sx={{ color: 'primary.main', display:'flex', alignItems:'center' }}><Icon sx={{ width: '20px',height: '20px', mr:'5px'}} src={exclamation} />  {t('no_location_data_available')}</Text></Box>:
          <Loader /> 
        )}
      </Box>
    </Box>
  );
};
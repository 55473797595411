import { Box, IconButton, MenuItem, Stack } from '@mui/material';
import { DatePicker } from 'features';
import { useState, useEffect, useRef } from 'react';
import { close_popup } from 'shared/assets';
import { useAnchorEl, useAppSelector, useCustomerDateErrors, useExtendedTranslation, useToast } from 'shared/hooks';
import { BaseBtn, BigTitle, BaseInput, Modal, Row, Select } from 'shared/ui';
import { Confirm } from 'shared/ui';
import { useEditCustomerChanges } from './hooks';
import { formatDtoDate, replaceSpacesWithUnderscores } from 'shared/lib';
import { Customer } from 'features/CustomerManager/customerModels';
import { getDeepTrimCustomerValues } from 'features/CustomerManager/customerHelpers';
import { useEditCustomer } from 'features/CustomerManager/hooks/api/useEditCustomer';

interface Props {
  currentCustomer: Customer;
  openCustomerEditModal: boolean;
  onCloseModal: () => void;
}
const initialValues = {
  id: 0,
  customerName: '',
  customerStatusId: 0,
  eMailAddress: '',
  officePhoneNumber: '',
  customerTypeId: 0,
  notes: '',
  createdDate: '',
  operationStartDate: formatDtoDate(new Date(Date.now())),
  contactPersonPhoneNumber: formatDtoDate(new Date(Date.now())),
  contactPersonName: '',
  postAddress: '',
  customerAgreementStatusId: 0,
  shortNote: '',
  siteUrlCode: 'string',
  siteUrlCodeSec: 'string',
};

const initialErrors = {
  customerName: '',
  eMailAddress: '',
  officePhoneNumber: '',
  notes: '',
  contactPersonPhoneNumber: '',
  contactPersonName: '',
  postAddress: '',
  shortNote: '',
};
export const EditCustomer = ({ currentCustomer, openCustomerEditModal, onCloseModal }: Props) => {
  const { t } = useExtendedTranslation();
  const { showError } = useToast();

  const { customerTypes, customerStatuses, customerAgreementCodes, customers } = useAppSelector((st) => st.customers);

  const { isOpen, anchorEl, onOpenMenu, onCloseMenu } = useAnchorEl();
  const { isLoading, editCustomer } = useEditCustomer();
  const [isChanged, setIsChanged] = useState(false);
  const [values, setValues] = useState<Customer>(initialValues);
  const [errors, setErrors] = useState(initialErrors);
  const [hasErrors, setHasErrors] = useState(false);
  useEffect(() => {
    if (currentCustomer) {
      setValues(currentCustomer);
    }
  }, [currentCustomer, openCustomerEditModal]);

  useEffect(() => {
    if (isChanged) {
      validateForm();
    }
  }, [values]);
  useEffect(() => {
    setHasErrors(Object.values(errors).every((value) => value === ''));
  }, [errors]);

  const resetErrors = () => {
    setErrors(initialErrors);
  };
  const resetValues = () => {
    setValues(initialValues);
  };
  const onChangeValues = (event: any, propertyName: string) => {
    const newValue = event.target.value;
    setValues((prevValues) => ({
      ...prevValues,
      [propertyName]: newValue,
    }));
    setIsChanged(true);
  };
  const onChangeSelectValues = (event: any) => {
    const newValue = event.target.value;
    const property = event.target.name;
    setValues((prevValues) => ({
      ...prevValues,
      [property]: newValue,
    }));
    setIsChanged(true);
  };
  const onChangeDateValues = (name: string, property: string) => {
    setValues((prevValues) => ({
      ...prevValues,
      [name]: property,
    }));
    setIsChanged(true);
  };
  const checkCustomerName = () => {
    const { customerName } = values;
    if (!customerName) {
      setErrors((prevValues) => ({
        ...prevValues,
        ['customerName']: 'Please enter customer name',
      }));
      return false;
    }
    if (customerName.length < 3) {
      setErrors((prevValues) => ({
        ...prevValues,
        ['customerName']: 'Name must contain at least 3 characters',
      }));
      return false;
    }
    if (customerName.length > 50) {
      setErrors((prevValues) => ({
        ...prevValues,
        ['customerName']: 'Max length 50 characters',
      }));
      return false;
    }

    setErrors((prevValues) => ({
      ...prevValues,
      ['customerName']: '',
    }));
    return true;
  };

  const checkPostAddress = () => {
    const { postAddress } = values;
    if (postAddress && postAddress.length > 250) {
      setErrors((prevValues) => ({
        ...prevValues,
        ['postAddress']: 'Max length 250 characters',
      }));
      return false;
    }
    setErrors((prevValues) => ({
      ...prevValues,
      ['postAddress']: '',
    }));
    return true;
  };

  const checkEMailAddress = () => {
    const { eMailAddress } = values;
    if (eMailAddress && eMailAddress.length > 250) {
      setErrors((prevValues) => ({
        ...prevValues,
        ['eMailAddress']: 'Max length 250 characters',
      }));
      return false;
    }
    setErrors((prevValues) => ({
      ...prevValues,
      ['eMailAddress']: '',
    }));
    return true;
  };
  const checkOfficePhoneNumber = () => {
    const { officePhoneNumber } = values;
    if (officePhoneNumber && officePhoneNumber.length > 250) {
      setErrors((prevValues) => ({
        ...prevValues,
        ['officePhoneNumber']: 'Max length 250 characters',
      }));
      return false;
    }
    setErrors((prevValues) => ({
      ...prevValues,
      ['officePhoneNumber']: '',
    }));
    return true;
  };
  const checkContactPersonName = () => {
    const { contactPersonName } = values;
    if (contactPersonName && contactPersonName.length > 250) {
      setErrors((prevValues) => ({
        ...prevValues,
        ['contactPersonName']: 'Max length 250 characters',
      }));
      return false;
    }
    setErrors((prevValues) => ({
      ...prevValues,
      ['contactPersonName']: '',
    }));
    return true;
  };
  const checkContactPersonPhoneNumber = () => {
    const { contactPersonPhoneNumber } = values;
    if (contactPersonPhoneNumber && contactPersonPhoneNumber.length > 250) {
      setErrors((prevValues) => ({
        ...prevValues,
        ['contactPersonPhoneNumber']: 'Max length 250 characters',
      }));
      return false;
    }
    setErrors((prevValues) => ({
      ...prevValues,
      ['contactPersonPhoneNumber']: '',
    }));
    return true;
  };
  const checkNotes = () => {
    const { notes } = values;
    if (notes && notes.length > 510) {
      setErrors((prevValues) => ({
        ...prevValues,
        ['notes']: 'Max length 510 characters',
      }));
      return false;
    }
    setErrors((prevValues) => ({
      ...prevValues,
      ['notes']: '',
    }));
    return true;
  };
  const checkShortNote = () => {
    const { shortNote } = values;
    if (shortNote && shortNote.length > 50) {
      setErrors((prevValues) => ({
        ...prevValues,
        ['shortNote']: 'Max length 50 characters',
      }));
      return false;
    }
    setErrors((prevValues) => ({
      ...prevValues,
      ['shortNote']: '',
    }));
    return true;
  };
  const validateForm = () => {
    checkCustomerName();
    checkPostAddress();
    checkOfficePhoneNumber();
    checkEMailAddress();
    checkOfficePhoneNumber();
    checkContactPersonName();
    checkContactPersonPhoneNumber();
    checkNotes();
    checkShortNote();
  };
  const onSubmit = () => {
    if (hasErrors) {
      handleSave();
    }
  };

  const { boxRef } = useEditCustomerChanges(currentCustomer, openCustomerEditModal);
  const { createdDateError, setCreatedDateError, startDateError, setStartDateError } = useCustomerDateErrors();

  const handleCloseEditModal = (e: React.MouseEvent<HTMLElement>) => {
    if (isLoading) return;
    setCreatedDateError(null);
    setStartDateError(null);

    isChanged ? onOpenMenu(e) : closeModal();
  };
  const clearData = () => {
    resetValues();
    resetErrors();
    setIsChanged(false);
  };
  const closeModal = () => {
    clearData();
    onCloseModal();
  };
  const handleSave = () => {
    const nameIsExist = customers.find(
      (c) =>
        c.customerName.toLowerCase().trim() === values.customerName.toLowerCase().trim() &&
        currentCustomer?.customerName.toLowerCase().trim() !== values.customerName.toLowerCase().trim()
    );
    if (nameIsExist) {
      showError(t('customer_with_such_name_is_already_exist_message'));
      setErrors((prevValues) => ({
        ...prevValues,
        ['customerName']: t('customer_with_such_name_is_already_exist_validation_text'),
      }));
      return;
    }

    const trimedValues = getDeepTrimCustomerValues(values);

    editCustomer(trimedValues).then((result) => {
      if (result) {
        closeModal();
      }
    });
  };
  const closeButtonRef = useRef<HTMLButtonElement | null>(null);
  const handleModalClose = () => {
    if (closeButtonRef.current) {
      closeButtonRef.current.click();
    }
  };
  return (
    <Modal open={openCustomerEditModal} onClose={handleModalClose}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <BigTitle>{t('edit_customer_edit_modal')}</BigTitle>

        <IconButton ref={closeButtonRef} onClick={handleCloseEditModal}>
          <img src={close_popup} />
        </IconButton>
      </Stack>
      {values && (
        <Box component="form" autoComplete="off">
          <Row spacing="20px" alignItems="flex-start" justifyContent="space-between">
            <Box width={'30%'}>
              <BaseInput
                label={`${t('customer_id_edit_modal')} *`}
                name="id"
                helperText={''}
                value={values?.id}
                onChange={(event) => onChangeValues(event, 'id')}
                disabled
              />
              <BaseInput
                label={`${t('customer_name_edit_modal')} *`}
                name="customerName"
                error={!!errors.customerName}
                helperText={replaceSpacesWithUnderscores(errors.customerName)}
                value={values?.customerName ?? ''}
                onChange={(event) => onChangeValues(event, 'customerName')}
              />

              <Select
                label={`${t('customer_agreement_label_edit_modal')} *`}
                name="customerAgreementStatusId"
                value={values?.customerAgreementStatusId}
                onChange={onChangeSelectValues}
                displayEmpty
                sx={{
                  fontFamily: 'Noto Sans Hebrew !important',
                }}
              >
                {customerAgreementCodes.map((a) => (
                  <MenuItem
                    key={a.code}
                    value={a.code}
                    sx={{
                      fontFamily: 'Noto Sans Hebrew !important',
                    }}
                  >
                    {a.text}
                  </MenuItem>
                ))}
              </Select>
              <BaseInput
                label={`${t('post_address_edit_modal')}`}
                name="postAddress"
                value={values?.postAddress ?? ''}
                error={!!errors.postAddress}
                helperText={replaceSpacesWithUnderscores(errors.postAddress)}
                onChange={(event) => onChangeValues(event, 'postAddress')}
              />
              <BaseInput
                label={`${t('email_address_edit_modal')}`}
                name="eMailAddress"
                error={!!errors.eMailAddress}
                helperText={replaceSpacesWithUnderscores(errors.eMailAddress)}
                value={values?.eMailAddress ?? ''}
                onChange={(event) => onChangeValues(event, 'eMailAddress')}
              />
            </Box>
            <Box width={'30%'}>
              <BaseInput
                label={`${t('office_phone_number_edit_modal')}`}
                name="officePhoneNumber"
                error={!!errors.officePhoneNumber}
                helperText={replaceSpacesWithUnderscores(errors.officePhoneNumber)}
                value={values?.officePhoneNumber ?? ''}
                onChange={(event) => onChangeValues(event, 'officePhoneNumber')}
              />
              <BaseInput
                label={`${t('contact_person_name_edit_modal')}`}
                name="contactPersonName"
                error={!!errors.contactPersonName}
                helperText={replaceSpacesWithUnderscores(errors.contactPersonName)}
                value={values?.contactPersonName ?? ''}
                onChange={(event) => onChangeValues(event, 'contactPersonName')}
              />
              <BaseInput
                label={`${t('contact_person_phone_number_edit_modal')}`}
                name="contactPersonPhoneNumber"
                error={!!errors.contactPersonPhoneNumber}
                helperText={replaceSpacesWithUnderscores(errors.contactPersonPhoneNumber)}
                value={values?.contactPersonPhoneNumber ?? ''}
                onChange={(event) => onChangeValues(event, 'contactPersonPhoneNumber')}
              />
              {values.createdDate && (
                <DatePicker
                  label={`${t('created_date_edit_modal')}`}
                  name="createdDate"
                  value={values?.createdDate}
                  onChange={onChangeDateValues}
                  error={createdDateError}
                  setError={setCreatedDateError}
                />
              )}
              {values.operationStartDate && (
                <DatePicker
                  label={`${t('operation_start_date_edit_modal')}`}
                  name="operationStartDate"
                  value={values?.operationStartDate}
                  onChange={onChangeDateValues}
                  error={startDateError}
                  setError={setStartDateError}
                />
              )}
            </Box>
            <Box width={'30%'}>
              <Select
                label={`${t('customer_type_label_edit_modal')} *`}
                name="customerTypeId"
                value={values?.customerTypeId}
                onChange={onChangeSelectValues}
                displayEmpty
                sx={{
                  fontFamily: 'Noto Sans Hebrew !important',
                }}
              >
                {customerTypes.map((t) => (
                  <MenuItem
                    key={t.customerTypeID}
                    value={t.customerTypeID}
                    sx={{
                      fontFamily: 'Noto Sans Hebrew !important',
                    }}
                  >
                    {t.customerType}
                  </MenuItem>
                ))}
              </Select>
              <Select
                label={`${t('customer_status_label_edit_modal')} *`}
                name="customerStatusId"
                value={values?.customerStatusId}
                onChange={onChangeSelectValues}
                displayEmpty
                sx={{
                  fontFamily: 'Noto Sans Hebrew !important',
                }}
              >
                {customerStatuses.map((s) => (
                  <MenuItem
                    key={s.customerStatusCode}
                    value={s.customerStatusCode}
                    sx={{
                      fontFamily: 'Noto Sans Hebrew !important',
                    }}
                  >
                    {s.customerStatus}
                  </MenuItem>
                ))}
              </Select>
              <BaseInput
                label={`${t('notes_edit_modal')}`}
                name="notes"
                value={values?.notes ?? ''}
                onChange={(event) => onChangeValues(event, 'notes')}
                error={!!errors.notes}
                helperText={replaceSpacesWithUnderscores(errors.notes)}
                big
              />
              <BaseInput
                label={`${t('short_note_edit_modal')}`}
                name="shortNote"
                value={values.shortNote ?? ''}
                error={!!errors.shortNote}
                helperText={replaceSpacesWithUnderscores(errors.shortNote)}
                onChange={(event) => onChangeValues(event, 'shortNote')}
              />
            </Box>
          </Row>

          <Row justifyContent="flex-end" mt={'35px'}>
            <Row width={300}>
              <BaseBtn onClick={handleCloseEditModal} btnColor="info.main" fullWidth>
                {t('cancel_edit_modal')}
              </BaseBtn>
              <Box ref={boxRef} onClick={handleCloseEditModal} sx={{ height: '40px', width: '25px' }} />
              <BaseBtn
                onClick={onSubmit}
                disabled={!isChanged || !hasErrors}
                loading={isLoading}
                btnColor="primary.dark"
                fullWidth
              >
                {t('save_edit_modal')}
              </BaseBtn>
            </Row>
          </Row>
        </Box>
      )}
      <Confirm
        open={isOpen}
        anchorEl={anchorEl}
        onClose={onCloseMenu}
        confirm={closeModal}
        buttonTexts={[t('cancel_edit_edit_modal'), t('back_to_edit_edit_modal')]}
      />
    </Modal>
  );
};

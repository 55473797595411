import { Box } from '@mui/material';
import { BaseInfo } from '../BaseInfo';
import { Text } from '../../ui/Text';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const TotalRows: FC<{ count: number, label?: string, width?: string }> = ({ count, label, width }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: width ? width : 'auto'
      }}
    >
      <Text noWrap sx={{ color: 'info.dark', fontSize: '12px', fontWeight: 500, pr: '2px', lineHeight: '15px' }}>
        {label ? label : t('total_rows_label')}
      </Text>
      <BaseInfo sx={{ backgroundColor: 'white' }} text={count.toString()} />
    </Box>
  );
};

export default TotalRows;

import { useState, ChangeEvent,  useEffect} from 'react';
import localStorageService from 'shared/localStorage';
import { ControllerRegistersEntry, getRegisterLanguageText } from 'shared/models';
export const useSearch = (filteredRegisters: ControllerRegistersEntry[]) => {
	const [itemNumber, setItemNumber] = useState<number>(9);
	const [numberOfResults, setNumberOfResults] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>('');
	const [currentSearchID, setCurrentSearchID] = useState<number>(0);
	const [matchingElements, setMatchingElements] = useState<ControllerRegistersEntry[]>(null);
	useEffect(() => {
		if(filteredRegisters && searchValue) updateSearchResults(searchValue);
  }, [filteredRegisters]);

	const changeSearch=(item:ChangeEvent<HTMLInputElement>)=>{
		setSearchValue(item.target.value)
		updateSearchResults(item.target.value);
	}

	const updateSearchResults=(searchString:string)=>{
		const matchingEl=filteredRegisters.filter(entry => {
			return getRegisterLanguageText(entry.text, localStorageService.currentLang).toUpperCase().includes(searchString.toUpperCase()) ||
			entry.regNumber.toString().includes(searchString) ||
			entry.eventID.toString().includes(searchString);
		})
		setMatchingElements(matchingEl)
		setItemNumber(0)
		if(matchingEl.length){
		setNumberOfResults(matchingEl.length)
		setCurrentSearchID(matchingEl[0].eventID)
		return
		}
		setNumberOfResults(0)
		setCurrentSearchID(-1)
	}
	const clearSearch=()=>{
		setSearchValue('')
		setCurrentSearchID(-1)
	}
	const onPreviousItemSearch=()=>{
		if(!numberOfResults) return
		const newItemNumber=itemNumber ? itemNumber-1: numberOfResults-1
		setItemNumber(()=>newItemNumber)
		setCurrentSearchID(matchingElements[newItemNumber].eventID)
	}
	const onNextItemSearch=()=>{
		if(!numberOfResults) return
		const newItemNumber=itemNumber!=numberOfResults-1 ? itemNumber+1: 0
		setItemNumber(()=>newItemNumber)
		setCurrentSearchID(matchingElements[newItemNumber].eventID)
	}

  return {
    changeSearch,
		clearSearch,
		updateSearchResults,
		searchValue,
		onPreviousItemSearch,
		onNextItemSearch,
		numberOfResults,
		currentSearchID,
		itemNumber
  };
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { axiosBase } from 'shared/config';
import { useAppDispatch, useToast } from 'shared/hooks';
import {
  setBatteryStatusItems,
  setBatteryStatusExcludeControllers,
  setBatteryStatusExcludeProjects,
} from 'shared/slices';
import { BatteryStatusExcludeFilter, BatteryStatusReportProjectFilter } from 'shared/models';
import dayjs from 'dayjs';

export const useBatteryStatusReport = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { showError } = useToast();
  const push = useNavigate();
  const [isLoadingBatteryStatusReport, setLoading] = React.useState(false);
  const [isLoadingGraphBatteryStatusReport, setGraphLoading] = React.useState(false);

  const getBatteryStatusReport = async (filter: BatteryStatusExcludeFilter) => {
    setLoading(true);
    const filterData = {
      startDate: filter.startDate,
      endDate: filter.endDate,
      ...(filter.connectHMin !== '' && { connectHMin: filter.connectHMin }),
      ...(filter.daysPositiveP !== '' && { daysPositiveP: filter.daysPositiveP }),
      ...(filter.dayTimeStart !== '' && { dayTimeStart: filter.dayTimeStart }),
      ...(filter.dayTimeEnd !== '' && { dayTimeEnd: filter.dayTimeEnd }),
      ...(filter.nightTimeStart !== '' && { nightTimeStart: filter.nightTimeStart }),
      ...(filter.nightTimeEnd !== '' && { nightTimeEnd: filter.nightTimeEnd }),
    };
    try {
      const { data } = await axiosBase.post('/Maintenance/batteryreport', filterData);

      const items = data?.data.map((c: any) => ({
        ...c,
        HWID: c.hwId,
        objectId: c.deviceId,
        controllerName: c.deviceName,
        CTLVer: c.ctlVersion,
        powerSupCode: t(`PowerSupCode_${c.powerSupCode}`),
      }));
      dispatch(setBatteryStatusItems(items));
    } catch (error: any) {
      console.log('#ERROR', error?.response?.status);
      if (error?.response?.status === 401) {
        push('/login');
      } else {
        showError(t('something_went_wrong_toast_text'));
      }
    } finally {
      setLoading((pref) => false);
    }
    setLoading(false);
  };
  const getBatteryStatusExcludeDevice = async () => {
    try {
      const { data } = await axiosBase.get('/maintenance/batteryreport/exclude/device');
      dispatch(setBatteryStatusExcludeControllers(data.data));
    } catch (error: any) {
      console.log('#ERROR', error?.response?.status);
      if (error?.response?.status === 401) {
        push('/login');
      } else {
        showError(t('something_went_wrong_toast_text'));
      }
    }
  };
  const getBatteryStatusExcludeCustomer = async () => {
    try {
      const { data } = await axiosBase.get('/maintenance/batteryreport/exclude/customer');
      dispatch(setBatteryStatusExcludeProjects(data.data));
    } catch (error: any) {
      console.log('#ERROR', error?.response?.status);
      if (error?.response?.status === 401) {
        push('/login');
      } else {
        showError(t('something_went_wrong_toast_text'));
      }
    }
  };

  const addBatteryStatusExcludeController = async (id: number, date:string) => {
    setLoading(true);
    try {
      const excludeController = {
        id,
        note: '',
        expirationDate: date
      };
      const { data } = await axiosBase.post('/maintenance/batteryreport/exclude/device', excludeController);

      //dispatch(setBatteryStatusExclude(data));
    } catch (error: any) {
      console.log('#ERROR', error?.response?.status);
      if (error?.response?.status === 401) {
        push('/login');
      } else {
        showError(t('something_went_wrong_toast_text'));
      }
    }
    setLoading(false);
  };
  const addBatteryStatusExcludeProject = async (id: number, date) => {
    setLoading(true);
    try {
      const excludeProject = {
        id,
        note: '',
        expirationDate: date
      };
      await axiosBase.post('/maintenance/batteryreport/exclude/customer', excludeProject);
    } catch (error: any) {
      console.log('#ERROR', error?.response?.status);
      if (error?.response?.status === 401) {
        push('/login');
      } else {
        showError(t('something_went_wrong_toast_text'));
      }
    }
    setLoading(false);
  };
  const deleteExcludedController = async (id: string) => {
    try {
      await axiosBase.delete('/maintenance/batteryreport/exclude/device', { data: { id } });
    } catch (error: any) {
      console.log('#ERROR', error?.response?.status);
      if (error?.response?.status === 401) {
        push('/login');
      } else {
        showError(t('something_went_wrong_toast_text'));
      }
    }
  };
  const deleteExcludedProject = async (id: string) => {
    try {
      await axiosBase.delete('/maintenance/batteryreport/exclude/customer', { data: { id } });
    } catch (error: any) {
      console.log('#ERROR', error?.response?.status);
      if (error?.response?.status === 401) {
        push('/login');
      } else {
        showError(t('something_went_wrong_toast_text'));
      }
    }
  };
  const getBatteryStatusReportProject = async (filter: BatteryStatusReportProjectFilter) => {
    if (!filter.deviceId || !filter.fromDate || !filter.toDate) return;
    setGraphLoading(true);
    try {
      const customFilter={...filter}
      const dbYday = dayjs(filter.toDate);
      const incrementerdToDay = dbYday.add(1, 'day').format('YYYY-MM-DD');
      customFilter.toDate=incrementerdToDay;
      const { data } = await axiosBase.post('/maintenance/batteryreport/device', customFilter);
      return data;
    } catch (error: any) {
      console.log('#ERROR', error?.response?.status);
      if (error?.response?.status === 401) {
        push('/login');
      } else {
        showError(t('something_went_wrong_toast_text'));
      }
    } finally {
      setGraphLoading(false);
    }
  };

  return {
    getBatteryStatusReport,
    getBatteryStatusExcludeDevice,
    getBatteryStatusExcludeCustomer,
    addBatteryStatusExcludeController,
    addBatteryStatusExcludeProject,
    deleteExcludedController,
    deleteExcludedProject,
    getBatteryStatusReportProject,
    setLoading,
    isLoadingBatteryStatusReport,
    isLoadingGraphBatteryStatusReport,
  };
};

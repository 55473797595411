import { ChangeEvent, FC, useEffect, useMemo, useState, useRef, startTransition } from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  IconButtonProps,
  Popover,
  Radio,
  RadioGroup,
  Tooltip,
} from '@mui/material';
import { BaseBtn, Icon, Row, SearchInput, Text } from '../../shared/ui';
import { Height, PlayArrow } from '@mui/icons-material';
import { useAnchorEl, useExtendedTranslation } from '../../shared/hooks';
import { checked_box, clear_filter, home, un_checked_box } from 'shared/assets';
import { SxProps } from '@mui/system';
interface ResetButtonProps extends IconButtonProps {
  showHomeButton?: boolean;
}

const ResetButton: FC<ResetButtonProps> = ({ onClick, sx, showHomeButton }) => (
  <IconButton
    onClick={onClick}
    sx={{
      position: 'absolute',
      zIndex: 2,
      height: '29px',
      width: '33px',
      ...sx,
    }}
  >
    <Icon src={showHomeButton ? home : clear_filter} />
  </IconButton>
);

interface Props {
  title: string;
  items: { value: string; label: string; description?: string }[];
  value?: string;
  onChange?: (value: string) => void;
  checkBoxValues?: string[];
  onChangeCheckBoxValues?: (value: string[]) => void;
  withSearch?: boolean;
  withConfirm?: boolean;
  hideAllButton?: boolean;
  showHomeButton?: boolean;
  setDefaultValue?: () => void;
  messageEmptyFilter?: string;
  sx?: SxProps;
  placeholder?: string;
  hideResetButton?: boolean;
  fullWidth?: boolean;
  marginRight?: string;
  formStyle?: boolean;
  open?: () => void;
  close?: () => void;
}

export const Filter: FC<Props> = ({
  sx,
  title,
  items,
  value,
  onChange,
  checkBoxValues,
  onChangeCheckBoxValues,
  withSearch,
  withConfirm,
  hideAllButton,
  showHomeButton,
  setDefaultValue,
  messageEmptyFilter = 'display_all_filter',
  placeholder,
  hideResetButton,
  fullWidth,
  marginRight,
  formStyle,
  open,
  close

}) => {
  const filterPopoverRef = useRef<HTMLDivElement>(null);
  const { t } = useExtendedTranslation();
  const { isOpen, anchorEl, onOpenMenu, onCloseMenu } = useAnchorEl();
  const [innerValue, setInnerValue] = useState<string>(value ?? '');
  const [filterWidth, setFilterWidth] = useState<string>('160');
  const [filterItems, setfilterItems] = useState<{ value: string; label: string; description?: string }[]>(items);
  const handleChange = (value: string) => {
    close && close()
    onCloseMenu();
    if (withConfirm) {
      setInnerValue(value);
      return;
    }
    onChange?.(value);
  };
  useEffect(() => {
    setfilterItems(items);
  }, [items]);
  const valueItems = [...items];
  const currentFilterName = useMemo(() => valueItems.find((item) => item.value === value)?.label, [value]);

  const [innerCheckBoxValues, setInnerCheckBoxValue] = useState<string[]>(checkBoxValues ?? []);
  const handleChangeCheckBoxValues = (value: string) => {
    const isAdd = innerCheckBoxValues.includes(value);

    if (isAdd) {
      const newValues = innerCheckBoxValues.filter((v) => v !== value);
      setInnerCheckBoxValue(newValues);
    } else {
      setInnerCheckBoxValue((prev) => [...prev, value]);
    }
  };

  const currentFilterItemNames = useMemo(() => {
    if (items.length === checkBoxValues?.length) return t(`${messageEmptyFilter}`);

    return items
      .filter((item) => checkBoxValues?.includes(item.value))
      .map((item) => item.label)
      .join(' , ');
  }, [checkBoxValues]);

  const [search, setSearch] = useState('');
  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

  useEffect(() => {
    setfilterItems(
      valueItems.filter((i) => {
        const findByLabel = i.label.toLowerCase().includes(search.toLowerCase().trim());
        const findByValue = i.value.toLowerCase().includes(search.toLowerCase().trim());
        return findByLabel || findByValue;
      })
    );
  }, [search]);

  const handleApply = () => {
    if (checkBoxValues?.length) {
      onChangeCheckBoxValues?.(innerCheckBoxValues);
    }
    if (value) {
      onChange?.(innerValue);
    }
    close && close()
    onCloseMenu();
    setSearch('');
  };

  const handleClose = () => {
    close && close();
    onCloseMenu();
    setInnerCheckBoxValue(checkBoxValues as string[]);
  };

  const clearFilterValue = () => onChange?.('');
  const toggleAllCheckBoxFilters = () => {
    if (innerCheckBoxValues.length === items.length) {
      setInnerCheckBoxValue([]);
    } else {
      setInnerCheckBoxValue(items.map((v) => v.value));
    }
  };
  const resetFilters = () => {
    setInnerCheckBoxValue(items.map((v) => v.value));
    onChangeCheckBoxValues?.(items.map((v) => v.value));
  };

  const homeButtonClick = () => {
    if (setDefaultValue) setDefaultValue();
  };
  const handleOpenMenu = (e) => {
    onOpenMenu(e)
    open && open();
  }


  const controlLabel = (value: string, label: string) => (<FormControlLabel
    key={value}
    value={value}
    onChange={() => handleChange(value)}
    control={<Radio size="small" />}
    label={<Text sx={{ color: 'black', pr: '10px' }}>{label}</Text>}
    sx={{
      fontSize: '11px',
      marginLeft: '8px',
      borderRadius: '10px',
      '&:hover': { bgcolor: 'primary.light' },
    }}
  />)

  return (
    <Box sx={{ mr: marginRight ? marginRight : '20px', width: fullWidth ? '100%' : filterWidth, ...sx }}>
      <Text noWrap sx={{ color: formStyle ? 'rgb(10, 62, 144)' : 'info.dark', fontFamily: 'Noto Sans Hebrew', fontSize: formStyle ? '14px' : '12px', fontWeight: formStyle ? 600 : 500, pr: '2px', lineHeight: '15px', mb: formStyle ? '6px' : 'auto' }}>
        {title}
      </Text>

      <Row position="relative" justifyContent="space-between">
        {/* showHomeButton */}
        {showHomeButton ? (
          <>
            {!checkBoxValues?.length && value !== '' && !hideResetButton && (
              <ResetButton showHomeButton onClick={homeButtonClick} />
            )}
          </>
        ) : (
          <>
            {!checkBoxValues?.length && value !== '' && !hideResetButton && <ResetButton onClick={clearFilterValue} />}
          </>
        )}
        {checkBoxValues?.length && items.length !== checkBoxValues?.length && !hideResetButton && (
          <ResetButton showHomeButton={!!showHomeButton} onClick={resetFilters} />
        )}

        <BaseBtn btnColor="info.main" onClick={handleOpenMenu} sx={{ height: '29px', minWidth: '100%', px: 0 }}>
          <Text
            noWrap
            sx={{
              color: value === '' ? '#d3d3d3 !important' : 'primary.dark',
              fontSize: '14px',
              fontWeight: 500,
              width: '100%',
              maxWidth: '300px',
              pl:
                (!checkBoxValues?.length && value !== '' && !hideResetButton) ||
                  (checkBoxValues?.length && items.length !== checkBoxValues?.length && !hideResetButton)
                  ? '35px'
                  : '10px',
              textAlign: 'left',
            }}
          >
            {checkBoxValues?.length ? currentFilterItemNames : currentFilterName && t(`${currentFilterName}`)}
            {value === '' ? t(messageEmptyFilter) : ''}
          </Text>

          <PlayArrow
            sx={{
              m: '7px',
              fontSize: '14px',
              color: 'primary.dark',
              transform: `rotate(${isOpen ? '-90deg' : '90deg'})`,
            }}
          />
        </BaseBtn>
      </Row>

      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        ref={filterPopoverRef}
        sx={{
          mt: '5px',
          '& .MuiPaper-root': {
            borderRadius: '12px',
          },
        }}
      >
        <Box ref={filterPopoverRef} sx={{}}>
          {withSearch && (
            <SearchInput value={search} onChange={handleChangeSearch} placeholder={placeholder} noBorder />
          )}

          <Box
            sx={{
              borderTop: '1px solid grey',
              borderColor: 'palette.primary.dark',
            }}
          >
            {filterItems.length === 0 ? (
              <Text sx={{ color: 'primary.main', textAlign: 'center', my: '10px' }}>{t('filter_no_items')}</Text>
            ) : (
              <FormControl sx={{ maxHeight: '500px' }}>
                {checkBoxValues ? (
                  <FormGroup sx={{ display: 'flex', flexDirection: 'row', overflowY: 'auto' }}>
                    <Box>
                      <Box
                        sx={{
                          borderBottom: '1px solid black',
                          borderColor: 'primary.dark',
                          px: '8px',
                          pb: '2px',
                          mb: '2px',
                        }}
                      >
                        <Row
                          onClick={toggleAllCheckBoxFilters}
                          sx={{
                            borderRadius: '10px',
                            height: '38px',
                            px: '2px',
                            cursor: 'pointer',
                            '&:hover': { bgcolor: 'primary.light' },
                            width: '100%',
                          }}
                        >
                          <Checkbox
                            checked={innerCheckBoxValues.length === items.length}
                            icon={<Icon src={un_checked_box} />}
                            checkedIcon={<Icon src={checked_box} />}
                          />
                          <Text sx={{ color: 'black', pr: '10px' }}>{t(`${messageEmptyFilter}`)}</Text>
                        </Row>
                      </Box>
                      <Box sx={{ width: '100%', paddingBottom: '50px' }}>
                        {filterItems.map(({ value, label }) => (
                          <Row
                            key={value}
                            onClick={() => handleChangeCheckBoxValues(value)}
                            sx={{
                              borderRadius: '10px',
                              height: '38px',
                              mx: '8px',
                              px: '2px',
                              cursor: 'pointer',
                              '&:hover': { bgcolor: 'primary.light' },
                            }}
                          >
                            <Checkbox
                              checked={innerCheckBoxValues.includes(value)}
                              icon={<Icon src={un_checked_box} />}
                              checkedIcon={<Icon src={checked_box} />}
                            />
                            <Text sx={{ color: 'black', pr: '10px' }}>{label}</Text>
                          </Row>
                        ))}
                      </Box>
                    </Box>
                  </FormGroup>
                ) : (
                  <RadioGroup value={withConfirm ? innerValue : value}>
                    {!hideAllButton && (
                      <Box
                        sx={{
                          borderBottom: '1px solid black',
                          borderColor: 'primary.dark',
                          px: '8px',
                          mb: '2px',
                        }}
                      >
                        <Box
                          onClick={() => handleChange('')}
                          sx={{
                            borderRadius: '10px',
                            cursor: 'pointer',
                            '&:hover': { bgcolor: 'primary.light' },
                          }}
                        >
                          <FormControlLabel
                            control={<Radio checked={value === ''} size="small" />}
                            label={<Text sx={{ color: 'black', pr: '10px' }}>{t(`${messageEmptyFilter}`)}</Text>}
                            sx={{
                              fontSize: '11px',
                              mx: 0,
                            }}
                          />
                        </Box>
                      </Box>
                    )}

                    {filterItems.map(({ value, label, description }) => (
                      <>
                        {description ?
                          <Tooltip
                            title={description}
                            placement="right"
                          >
                            {controlLabel(value, label)}
                          </Tooltip>
                          :

                          controlLabel(value, label)

                        }
                      </>

                    ))}
                  </RadioGroup>
                )}
              </FormControl>
            )}
          </Box>
        </Box>
        {withConfirm && (
          <Row
            spacing="10px"
            sx={{
              p: '5px 8px 7px 8px',
              width: 'calc(100% - 16px)',
              position: 'absolute',
              bottom: '0px',
              background: 'white',
            }}
          >
            <BaseBtn onClick={handleClose} btnColor="info.main" fullWidth>
              {t('cancel_filter')}
            </BaseBtn>
            <BaseBtn onClick={handleApply} disabled={!innerCheckBoxValues.length} btnColor="primary.dark" fullWidth>
              {t('apply_filter')}
            </BaseBtn>
          </Row>
        )}
      </Popover>
    </Box >
  );
};

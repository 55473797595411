import { Box, IconButton, Stack } from '@mui/material';
import { WeatherStation } from 'features/WeatherStationsManager/WeatherStationModels';
import { useWeatherStationsManager } from 'features/WeatherStationsManager/hooks/api';
import { t } from 'i18next';
import { FC, useEffect, useState } from 'react';
import { useDashboardDetails } from 'shared/api/useDashboardDetails';
import { close_popup, refresh } from 'shared/assets';
import { useAppSelector, useToast } from 'shared/hooks';
import { formatDtoDate } from 'shared/lib';
import { BaseBtn, BaseInput, BaseSlider, BigTitle, Icon, Modal, Row, Text } from 'shared/ui';
import {
  AddedCustomer,
  CustomerListEditable,
  EvaporationAndRainData,
  IrrigationFactorData,
  PlantTypeControlled,
} from './components';
import { UpdateMaxEvaporationRates } from './components/UpdateMaxEvaporationRates';
import { IrrigationControllers } from './components/IrrigationControllers';
interface Props {
  currentMeteorologicalStation: WeatherStation;
  openMeteorologicalStation: boolean;
  onCloseModal: () => void;
  updateMeteorologicalStation: () => void;
}

export const MeteorologicalStationPopup: FC<Props> = ({
  currentMeteorologicalStation,
  openMeteorologicalStation,
  onCloseModal,
  updateMeteorologicalStation,
}) => {
  const { WEATHER_STATIONS } = useAppSelector((st) => st.globalSlice);
  const [currentController, setCurrentController] = useState(null);
  const [openAddedCustomerModal, setOpenAddedCustomerModal] = useState(false);
  const { isLoadingDashboardDetails, getCustomerDetailsById } = useDashboardDetails();
  const [newValueMaxEvaporationRate, setNewValueMaxEvaporationRate] = useState(0);
  const [openUpdateMaxEvaporationRatesModal, setOpenUpdateMaxEvaporationRatesModal] = useState(false);
  const [seasonalCoefficient, setSeasonalCoefficient] = useState<number[][]>(null);
  const [isChartLoading, setIsChartLoading] = useState<boolean>(true);

  const {
    getEvaporationAndRainData,
    getIrrigationFactorData,
    updateMaxEvaporationRate,
    updateRetroactive,
    updateTargetFactor,
    loading,
  } = useWeatherStationsManager();

  const [startReportDate, setStartReportDate] = useState(null);
  const [endReportDate, setEndReportDate] = useState(null);
  const [maxEvaporationRate, setMaxEvaporationRate] = useState(0);

  const { showSuccess } = useToast();

  const marks = [
    {
      value: 1,
      label: '1',
    },
    {
      value: 2,
      label: '2',
    },
    {
      value: 3,
      label: '3',
    },
    {
      value: 4,
      label: '4',
    },
    {
      value: 5,
      label: '5',
    },
    {
      value: 6,
      label: '6',
    },
    {
      value: 7,
      label: '7',
    },
    {
      value: 8,
      label: '8',
    },
    {
      value: 9,
      label: '9',
    },
    {
      value: 10,
      label: '10',
    },
  ];
  function valuetext(value: number) {
    return `${value}`;
  }
  useEffect(() => {
    const date2 = new Date();
    date2.setHours(0, 0, 0, 0);
    const date1 = new Date(date2);
    date1.setDate(date2.getDate() - WEATHER_STATIONS.DAYS_FOR_UPDATE_MAX_EVAPORATION_RATES);

    setStartReportDate(formatDtoDate(date1));
    setEndReportDate(formatDtoDate(date2));
  }, []);

  useEffect(() => {
    if (currentMeteorologicalStation) {
      setNewValueMaxEvaporationRate(currentMeteorologicalStation.eT_MaxValue);
      setMaxEvaporationRate(currentMeteorologicalStation.eT_MaxValue);
      //setCurrentControllerValues();
      if (openMeteorologicalStation) {
        updateCurrentController();

        const objectID = currentMeteorologicalStation.objectID;
        getEvaporationAndRainData(startReportDate, endReportDate, objectID);
        //  getIrrigationFactorData(startReportDate, endReportDate, objectID, selectedCustomer);
      }
    }
    if (!openMeteorologicalStation) {
      setNewValueMaxEvaporationRate(0);
      setMaxEvaporationRate(0);
      setCurrentController(null);
      setIsChartLoading(true);
    }
  }, [openMeteorologicalStation, currentMeteorologicalStation]);

  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const onChangeSelectedCustomer = async (customer) => {
    setSelectedCustomer(customer);
    const objectID = currentMeteorologicalStation.objectID;
    await getIrrigationFactorData(startReportDate, endReportDate, objectID, customer);
    setIsChartLoading(false);
  };
  const handleOpenUpdateMaxEvaporationRatesModal = () => {
    setOpenUpdateMaxEvaporationRatesModal(true);
  };
  const closeUpdateMaxEvaporationRatesModal = () => {
    setOpenUpdateMaxEvaporationRatesModal(false);
  };

  const updateCurrentController = async () => {
    if (!currentMeteorologicalStation) return;
    const updatetedCurrentController = await getCustomerDetailsById(currentMeteorologicalStation.objectID);
    const controller = {
      ...currentMeteorologicalStation,
      ['deviceType']: updatetedCurrentController?.data[0].deviceType,
      ['powerSupCode']: updatetedCurrentController?.data[0].powerSupCode,
      ['customerAgreementStatus']: updatetedCurrentController?.data[0].customerAgreementStatus,
      ['modelId']: updatetedCurrentController?.data[0].modelID,
      ['objectName']: updatetedCurrentController?.data[0].objectName,

      ['customerName']: updatetedCurrentController?.data[0].customerName,
      ['customerId']: updatetedCurrentController?.data[0].customerID,
      ['hwId']: updatetedCurrentController?.data[0].hwid,
      ['deviceId']: updatetedCurrentController?.data[0].objectID,

      ['objectNotes']: updatetedCurrentController?.data[0].notes,
      ['installAddress']: updatetedCurrentController?.data[0].installAddress,

      ['latitudeDec']: updatetedCurrentController?.data[0].latitude_Dec,
      ['longitudeDec']: updatetedCurrentController?.data[0].longitude_Dec,
      ['installDate']: updatetedCurrentController?.data[0].installDate,
    };
    setCurrentController(controller);
  };
  const addCustomerOpenModal = () => {
    setOpenAddedCustomerModal(true);
  };
  const closeAddedCustomerModal = () => {
    setOpenAddedCustomerModal(false);
  };
  const onChangeNewValueMaxEvaporationRate = (v) => {
    let value = v.target.value;
    if (v.target.value < 1) {
      value = 1;
    }
    if (v.target.value > 10) {
      value = 10;
    }
    setNewValueMaxEvaporationRate(value);
  };

  const updateMaxEvaporationRates = async (selectedMethod: 'option 1' | 'option 2') => {
    if (selectedMethod === 'option 1') {
      saveMaxEvaporationRates();
      return;
    }
    if (selectedMethod === 'option 2') {
      const resultUpdateMaxEvaporationRate = await updateMaxEvaporationRate({
        wE_ObjectID: currentMeteorologicalStation.objectID,
        customerID: currentMeteorologicalStation.customerID,
        newValue: newValueMaxEvaporationRate,
      });
      if (resultUpdateMaxEvaporationRate) {
        closeUpdateMaxEvaporationRatesModal();
        updateMeteorologicalStation();
        showSuccess(t('irrigation_factor_retroactively_successfully_updated_toast_text'));
        setMaxEvaporationRate(newValueMaxEvaporationRate);
      }
    }
  };

  const saveMaxEvaporationRates = async () => {
    const objectID = currentMeteorologicalStation.objectID;
    const customerID = currentMeteorologicalStation.customerID;

    const factors = seasonalCoefficient[0]
      .map((timestamp, index) => {
        if (timestamp && seasonalCoefficient[1][index] !== null) {
          return {
            objectID: objectID,
            customerID: customerID,
            date_: formatDtoDate(new Date(timestamp * 1000)),
            factor: seasonalCoefficient[1][index],
          };
        }
      })
      .filter((item) => item !== undefined);

    const isSucceeded = await updateTargetFactor(factors);
    if (isSucceeded) {
      const resultUpdateMaxEvaporationRate = await updateMaxEvaporationRate({
        wE_ObjectID: objectID,
        customerID: customerID,
        newValue: newValueMaxEvaporationRate,
      });
      if (resultUpdateMaxEvaporationRate) {
        updateMeteorologicalStation();
        setMaxEvaporationRate(newValueMaxEvaporationRate);
        showSuccess(t('irrigation_factor_retroactively_successfully_updated_toast_text'));
        getEvaporationAndRainData(startReportDate, endReportDate, objectID);
        getIrrigationFactorData(startReportDate, endReportDate, objectID, customerID);
        closeUpdateMaxEvaporationRatesModal();
      }
    }
  };

  return (
    <Modal
      open={openMeteorologicalStation}
      onClose={onCloseModal}
      width={'100%'}
      padding={'8px 16px'}
      maxHeight={'995px'}
      height={'calc(100vh - 10px)'}
    >
      <Box>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <BigTitle>
            {t('title_meteorological_station_modal')} {currentController?.objectName}
          </BigTitle>
          <IconButton onClick={onCloseModal}>
            <img src={close_popup} />
          </IconButton>
        </Stack>
      </Box>
      <Row spacing="20px" alignItems="start" sx={{ mb: '4px', mt: '5px' }}>
        <Box width={'65%'}>
          <Row spacing="20px" alignItems="start">
            <Box width={'50%'}>
              <Row spacing="20px" alignItems="start">
                <BaseInput
                  label={`${t('controller_id_label_meteorological_station_modal')}`}
                  name="deviceType"
                  disabled
                  helperText=""
                  value={currentController?.objectID ? currentController?.objectID : ''}
                  onChange={(event) => {}}
                  small
                  compact
                  blueBorder
                />
                <BaseInput
                  label={`${t('hwid_label_meteorological_station_modal')}`}
                  name="deviceType"
                  disabled
                  helperText=""
                  value={currentController?.hwid ? currentController?.hwid : ''}
                  onChange={(event) => {}}
                  small
                  compact
                  blueBorder
                />
                <BaseInput
                  label={`${t('model_id_label_meteorological_station_modal')}`}
                  name="deviceType"
                  disabled
                  helperText=""
                  value={currentController?.modelId ? currentController?.modelId : ''}
                  onChange={(event) => {}}
                  small
                  compact
                  blueBorder
                />
              </Row>
            </Box>
            <Box width={'50%'}></Box>
          </Row>
          <Row spacing="20px" alignItems="start">
            <Box width={'50%'}>
              <Row spacing="20px" alignItems="start">
                <Box width={'50%'}>
                  <BaseInput
                    label={`${t('device_type_label_meteorological_station_modal')}`}
                    name="deviceType"
                    disabled
                    helperText=""
                    value={currentController?.deviceType ? currentController?.deviceType : ''}
                    onChange={(event) => {}}
                    small
                    compact
                    blueBorder
                  />
                </Box>
                <Box width={'50%'}>
                  <BaseInput
                    label={`${t('notes_label_meteorological_station_modal')}`}
                    name="notes"
                    disabled
                    helperText=""
                    value={currentController?.notes ? currentController?.notes : ''}
                    onChange={(event) => {}}
                    small
                    compact
                    blueBorder
                  />
                </Box>
              </Row>
            </Box>
            <Box width={'50%'}>
              <Row spacing="20px" alignItems="end">
                <Box sx={{ width: '50%' }}>
                  {currentMeteorologicalStation && (
                    <CustomerListEditable
                      value={selectedCustomer}
                      onChange={onChangeSelectedCustomer}
                      objectID={currentMeteorologicalStation.objectID}
                      sx={{ width: '100%' }}
                      objectName={currentMeteorologicalStation?.objectName}
                    />
                  )}
                </Box>

                <BaseBtn
                  onClick={addCustomerOpenModal}
                  btnColor="primary.dark"
                  fullWidth
                  sx={{ height: '29px', width: 'auto' }}
                >
                  {t('add_customer_meteorological_station_modal')}
                  <Text sx={{ fontSize: '25px', mb: '4px', ml: '4px' }}>+</Text>
                </BaseBtn>
              </Row>
            </Box>
          </Row>
          <Row spacing="20px" alignItems="end">
            <BaseInput
              label={`${t('max_evaporation_rate_label_meteorological_station_modal')}`}
              name="MaxEvaporationRate"
              disabled
              helperText=""
              value={maxEvaporationRate}
              onChange={(event) => {}}
              small
              compact
              blueBorder
            />

            <BaseInput
              label={`${t('new_value_label_meteorological_station_modal')}`}
              name="newValue"
              helperText=""
              value={newValueMaxEvaporationRate}
              onChange={onChangeNewValueMaxEvaporationRate}
              small
              compact
              blueBorder
              type="number"
              valueForNumberChanging={0.1}
            />
            <Box sx={{ width: '200px' }}>
              <BaseSlider
                aria-label="Always visible"
                defaultValue={newValueMaxEvaporationRate}
                getAriaValueText={valuetext}
                step={0.1}
                value={newValueMaxEvaporationRate}
                marks={marks}
                valueLabelDisplay="auto"
                max={10}
                min={1}
                sx={{ mb: '0px', height: '52px' }}
                label={t('select_a_value_meteorological_station_modal')}
                handleChange={(v: number) => setNewValueMaxEvaporationRate(v)}
              />
            </Box>
            <BaseBtn
              disabled={newValueMaxEvaporationRate == maxEvaporationRate || loading}
              onClick={(e) => {
                handleOpenUpdateMaxEvaporationRatesModal();
                //saveMaxEvaporationRates();
              }}
              sx={{ height: '29px' }}
            >
              {/* <Icon src={refresh} /> */}
              {`${t('update_max_evaporation_rates')}`}
            </BaseBtn>
          </Row>
          <Box>
            <EvaporationAndRainData
              startReportDate={startReportDate}
              endReportDate={endReportDate}
              loading={isChartLoading}
            />
          </Box>
          <Row alignItems="end">
            <IrrigationFactorData
              newValueMaxEvaporationRate={newValueMaxEvaporationRate}
              maxEvaporationRate={maxEvaporationRate}
              startReportDate={startReportDate}
              endReportDate={endReportDate}
              loading={loading}
              updateSeasonalCoefficient={setSeasonalCoefficient}
            />
          </Row>
        </Box>

        <Box width={'35%'}>
          {currentMeteorologicalStation && selectedCustomer && (
            <>
              <PlantTypeControlled objectID={currentMeteorologicalStation.objectID} customerID={selectedCustomer} />
              <IrrigationControllers objectID={currentMeteorologicalStation.objectID} customerID={selectedCustomer} />
            </>
          )}
        </Box>
      </Row>

      <AddedCustomer openModal={openAddedCustomerModal} onCloseModal={closeAddedCustomerModal} />
      <UpdateMaxEvaporationRates
        openModal={openUpdateMaxEvaporationRatesModal}
        onCloseModal={closeUpdateMaxEvaporationRatesModal}
        update={updateMaxEvaporationRates}
        loading={loading}
      />
    </Modal>
  );
};

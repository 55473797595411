/* eslint-disable prefer-const */
import { useState } from 'react';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { axiosBase } from 'shared/config';
import { useAppDispatch, useAppSelector, useToast } from 'shared/hooks';
import { useFilterControllers } from 'features/ControllerManager/hooks';
import { useCustomerToMove } from 'features/ControllerManager/components/DeviceTransferring/hooks/useFilterCustomerToMove';
import { setErrorUpload, setProgress, setSuccessUpload } from 'shared/slices';
import { useTranslation } from 'react-i18next';

const useMoveControllers = (): {
  isLoading: boolean;
  isUpdated: boolean;
  handleRequest: (selectedControllersToMove: number[], selectedCustomerToMove: string) => Promise<void>;
  setIsUpdated: (status: boolean) => void;
} => {
  const { t } = useTranslation();
  const { showError } = useToast();
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { customerFilter } = useFilterControllers();

  let response: AxiosResponse;

  const handleRequest = async (selectedControllersToMove: number[], selectedCustomerToMove: string) => {
    try {
      setIsLoading(true);
      setIsUpdated(false);

      const totalRequests = selectedControllersToMove.length;
      let completedRequests = 0;
      await Promise.all(
        selectedControllersToMove.map(async (controller) => {
          try {
            const config: AxiosRequestConfig = {
              data: {
                deviceId: controller,
                currentCustomerId: customerFilter,
                newCustomerId: selectedCustomerToMove,
                isClearSettings: 0,
                isClearHistory: 0,
                isClearStatistic: 0,
                extendedOptions: 0,
              },
            };

            response = await axiosBase.put('/Device/customerId', config.data);
            if (response.data.error !== null) {
              dispatch(setErrorUpload(controller));
              completedRequests++;
              dispatch(setProgress((completedRequests / totalRequests) * 100));
              return;
            }

            dispatch(setSuccessUpload(response.data));
            completedRequests++;
            dispatch(setProgress((completedRequests / totalRequests) * 100));

            return response;
          } catch (error: unknown) {
            dispatch(setErrorUpload(controller));
            throw error;
          }
        })
      );
      if (completedRequests === totalRequests) {
        setIsUpdated(true);
      }
      setIsLoading(false);
    } catch (error: unknown) {
      setIsLoading(false);
      setIsUpdated(true);
      showError(t('something_went_wrong_toast_text'));
    }
  };

  return { handleRequest, isLoading, isUpdated, setIsUpdated };
};

export default useMoveControllers;

import { Box, IconButton, Stack, Typography } from '@mui/material';
import { useDeleteCustomer } from 'features/CustomerManager/hooks/api/useDeleteCustomer';
import { useTranslation } from 'react-i18next';

import { close_popup } from 'shared/assets';
import localStorageService from 'shared/localStorage';
import { BaseBtn, BigTitle, Modal, Row } from 'shared/ui';

interface Props {
  openDeleteModal: boolean;
  loading: boolean;
  onCloseModal: () => void;
  handleDelete: () => void;
  selectedMessages: number;
}

export const DeleteMessages = ({ openDeleteModal, onCloseModal, handleDelete, loading, selectedMessages }: Props) => {
  const { t } = useTranslation();
  const closeModal = () => onCloseModal();
  return (
    <Modal open={openDeleteModal} onClose={closeModal}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <BigTitle>{t('delete_messages_delete_modal')}</BigTitle>
        <IconButton onClick={closeModal}>
          <img src={close_popup} />
        </IconButton>
      </Stack>
      <Typography
        sx={{
          fontWeight: 700,
          fontSize: '16px',
          fontFamily: 'Noto Sans Hebrew',
          lineHeight: '19px',
          color: 'primary.dark',
          mt: '16px',
        }}
      >
        <Row spacing="6px" sx={{ flexDirection: 'row' }}>
          <Typography
            sx={{
              fontFamily: 'Noto Sans Hebrew',
              fontSize: '16px',
              fontWeight: 600,
              lineHeight: '19px',
              color: 'primary.dark',
            }}
          >
            {t('number_of_messages_for_delete')}
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Noto Sans Hebrew',
              fontSize: '16px',
              fontWeight: 600,
              lineHeight: '19px',
              color: 'primary.dark',
            }}
          >
            {selectedMessages.toString()}
          </Typography>
        </Row>
        <p>{t('please_confirm_delete_modal')}</p>
      </Typography>

      <Row justifyContent="center" mt={'35px'}>
        <Row spacing="15px" width={500}>
          <BaseBtn onClick={closeModal} btnColor="info.main" fullWidth>
            {t('cancel_delete_modal')}
          </BaseBtn>

          <BaseBtn onClick={handleDelete} loading={loading} btnColor="primary.dark" fullWidth>
            {t('delete_delete_modal')}
          </BaseBtn>
        </Row>
      </Row>
    </Modal>
  );
};

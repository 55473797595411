import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface CustomerState {
  selectedControllers: number[];
  selectedControllersToMove: number[];
  customerIDForFilter: number;
  errorUpload: number[];
  successUpload: number[];
  progress: number;
}

const initialState: CustomerState = {
  selectedControllers: [],
  selectedControllersToMove: [],
  customerIDForFilter: 0,
  errorUpload: [],
  successUpload: [],
  progress: 0,
};

const deviceTransferringSlice = createSlice({
  name: 'deviceTransferring',
  initialState,
  reducers: {
    toggleSelectedController: (state, action: PayloadAction<number>) => {
      const index = state.selectedControllers.findIndex((i) => i === action.payload);
      if (index === -1) {
        state.selectedControllers.push(action.payload);
        state.selectedControllersToMove = state.selectedControllers;
      } else {
        state.selectedControllers.splice(index, 1);
        state.selectedControllersToMove.splice(index, 1);
      }
    },
    toggleSelectedControllerToMove: (state, action: PayloadAction<number>) => {
      const index = state.selectedControllersToMove.findIndex((i) => i === action.payload);
      if (index === -1) {
        state.selectedControllersToMove.push(action.payload);
      } else {
        state.selectedControllersToMove.splice(index, 1);
      }
    },
    setCustomerToMove: (state, action: PayloadAction<number>) => {
      if (!action.payload) {
        state.selectedControllersToMove = state.selectedControllers;
      }
      state.customerIDForFilter = action.payload;
    },
    setErrorUpload: (state, action: PayloadAction<number>) => {
      state.errorUpload.push(action.payload);
    },
    setSuccessUpload: (state, action: PayloadAction<number>) => {
      state.successUpload.push(action.payload);
    },
    setProgress: (state, action: PayloadAction<number>) => {
      state.progress = action.payload;
    },
    resetSelectedControllersToMove: (state) => {
      state.selectedControllersToMove = [];
    },
    clearSelectedController: (state) => {
      state.selectedControllers = [];
      state.selectedControllersToMove = [];
      state.errorUpload = [];
      state.successUpload = [];
      state.progress = 0;
      state.customerIDForFilter = 0;
    },
  },
});

export const {
  toggleSelectedController,
  clearSelectedController,
  toggleSelectedControllerToMove,
  setCustomerToMove,
  setErrorUpload,
  setSuccessUpload,
  setProgress,
  resetSelectedControllersToMove,
} = deviceTransferringSlice.actions;

export const deviceTransferringReducer = deviceTransferringSlice.reducer;

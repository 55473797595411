import { Stack, IconButton, Box, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useState, useEffect, memo } from 'react';
import { BaseDataPicker } from 'features';
import { t } from 'i18next';
import { FC } from 'react';
import { close_popup, clients } from 'shared/assets';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { toggleOpenShowGraphModal, setCurrentBatteryStatusReportProjectDates, setServiceCalls } from 'shared/slices';
import { BigTitle, Modal, Row, BaseBtn, Icon } from 'shared/ui';
import { useBatteryStatusReport, useServiceCallsManagement } from 'shared/api';
import { useGetDefaultDates } from './hooks';
import { ServiceCalls } from './components';
import { useFormattedGraphData, FormattedGraphData } from 'shared/lib';
import { BatteryStatusComposedChart } from 'entities_';
import { PowerProblemsItem } from 'features/ExternalPowerProblemsReportManager/PowerProblemsReportModels';
import { ComposedChart } from 'components/ComposedChart';

interface Props {
  changedServiceCalls: () => void;
  closedModal: () => void;
  onCloseModal: () => void;
  currentPowerProblem: PowerProblemsItem;
  openModal: boolean;
}

export const PowerProblemsReportGraph: FC<Props> = ({
  changedServiceCalls,
  closedModal,
  openModal,
  onCloseModal,
  currentPowerProblem,
}) => {
  const dispatch = useAppDispatch();
  const { getBatteryStatusReportProject, isLoadingGraphBatteryStatusReport } = useBatteryStatusReport();
  const { getServiceCalls } = useServiceCallsManagement();
  const { filterGraph, filter: reportFilter } = useAppSelector((st) => st.batteryStatusReport);
  const { powerProblemsItems } = useAppSelector((st) => st.powerProblems);
  const { startDate, endDate } = useGetDefaultDates(reportFilter);
  const { openEditServiceCall, openAddServiceCall } = useAppSelector((st) => st.modals);
  const { BATT_REP } = useAppSelector((st) => st.globalSlice);
  const initialFilter = {
    //fromDate: '2023-05-06',
    //toDate: '2023-05-07',
    fromDate: startDate,
    toDate: endDate,
  };
  const [filter, setFilter] = useState(initialFilter);
  const [formattedData, setFormattedData] = useState<FormattedGraphData[]>([]);

  const onChangeDateValues = (param: string, value: string) => {
    setFilter((prevValues) => ({
      ...prevValues,
      [param]: value,
    }));
  };

  const fetchData = async () => {
    const customFilterGraph = { ...filterGraph };
    customFilterGraph.eventId = 1702;
    const data = await getBatteryStatusReportProject(customFilterGraph);
    // if (data) {
    //   data.data.push({
    //     deviceId: 8741,
    //     eventId: 1702,
    //     eventDate: '2024-01-29T06:30:01',
    //     dataIndex: 7769,
    //     dataSource: 1,
    //     dataValue: 4,
    //   });
    //   data.data.push({
    //     deviceId: 8741,
    //     eventId: 1702,
    //     eventDate: '2024-01-30T06:30:01',
    //     dataIndex: 77999,
    //     dataSource: 1,
    //     dataValue: 3,
    //   });
    // }
    const graphData = data ? data.data : [];
    const formattedData = useFormattedGraphData(graphData, filterGraph);
    setFormattedData(formattedData);
  };
  const fetchServiceCallsData = async () => {
    getServiceCalls(filterGraph.deviceId);
  };
  useEffect(() => {
    setFilter(initialFilter);
  }, [reportFilter]);

  useEffect(() => {
    if (openModal) {
      updateGraph();
      fetchData();
      fetchServiceCallsData();
    } else {
      dispatch(setCurrentBatteryStatusReportProjectDates({ fromDate: '', toDate: '' }));
      setFilter(initialFilter);
      dispatch(setServiceCalls([]));
    }
  }, [openModal]);

  const updateGraph = () => {
    dispatch(setCurrentBatteryStatusReportProjectDates(filter));
  };

  useEffect(() => {
    fetchData();
  }, [filterGraph]);

  const closeModal = () => {
    setFormattedData([]);
    //dispatch(toggleOpenShowGraphModal());
    onCloseModal();
    closedModal();
  };
  const controllerName = powerProblemsItems.find((item) => item.objectID === filterGraph.deviceId)?.objectName;
  const powerSource = powerProblemsItems.find((item) => item.objectID === filterGraph.deviceId)?.powerSupCode;

  return (
    <Modal width={'100%'} open={openModal} onClose={closeModal}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <BigTitle>{controllerName}</BigTitle>
        <IconButton onClick={closeModal}>
          <img src={close_popup} />
        </IconButton>
      </Stack>
      <Box
        sx={{
          display: 'flex',
          columnGap: '20px',

          top: '100px',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Noto Sans Hebrew',
            fontWeight: 600,
          }}
        >
          {t('powersupcode_battery_status_report_grid_table')}:
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Noto Sans Hebrew',
            fontWeight: 600,
          }}
        >
          {t(`PowerSupCode_${powerSource}`)}
        </Typography>
      </Box>
      <Row spacing="24px" justifyContent="center" alignItems="flex-end">
        <BaseBtn onClick={updateGraph} sx={{ height: '29px' }}>
          {t('graph_battery_status_update')}
        </BaseBtn>
        <BaseDataPicker
          label={`${t('start_date_graph_battery_status_report_label')}`}
          name="fromDate"
          value={filter.fromDate}
          onChange={onChangeDateValues}
        />
        <BaseDataPicker
          label={`${t('end_date_graph_battery_status_report_label')}`}
          name="toDate"
          value={filter.toDate}
          onChange={onChangeDateValues}
          error={null}
        />
      </Row>
      <Box
        sx={{
          margin: '20px auto',
          //height:'380px',
          overflow: 'hidden',
          opacity: isLoadingGraphBatteryStatusReport ? 0 : 1,
          position: isLoadingGraphBatteryStatusReport ? 'absolute' : 'inherit',
        }}
      >
        {/* <BatteryStatusComposedChart showEventIds={[1702]} data={formattedData} />  */}
        <ComposedChart
          showEventIds={[1702]}
          data={formattedData}
          startDate={filter.fromDate}
          endDate={filter.toDate}
          popupPadding={120}
        />
      </Box>
      {isLoadingGraphBatteryStatusReport && (
        <Row height={280} justifyContent="center" margin="20px auto">
          <CircularProgress disableShrink />
        </Row>
      )}

      <ServiceCalls currentPowerProblem={currentPowerProblem} changedServiceCalls={changedServiceCalls} />
    </Modal>
  );
};

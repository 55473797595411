import { useAppSelector } from 'shared/hooks';
import { useEffect } from 'react';
import { useState } from 'react';
interface SimOperatorsItem {
  value: string;
  label: string;
}
export const useOperatorsSelect = () => {
  const { simOperators } = useAppSelector((st) => st.simManagement);
  const [simOperatorsItems, setSimOperatorsItems] = useState<SimOperatorsItem[]>([]);
  const generateSimOperatorsItems = () => {
    const result: SimOperatorsItem[] = [];
    simOperators.forEach((s: { operatorName: string }) => {
      if (s && s.operatorName)
        result.push({
          value: s.operatorName.toString(),
          label: s.operatorName.toString(),
        });
    });
    setSimOperatorsItems(result);
  };
  useEffect(() => {
    generateSimOperatorsItems();
  }, [simOperators]);
  useEffect(() => {
    generateSimOperatorsItems();
  }, []);
  const [simOperatorsSelect, setSimOperatorsSelect] = useState<string>('');
  const onChangeSimOperatorsSelect = (values: string) => setSimOperatorsSelect(values);
  return {
    simOperatorsItems,
    onChangeSimOperatorsSelect,
    simOperatorsSelect,
  };
};

export type Calculated = { dState?: number; isCon?: boolean; mState?: number; state?: number };

export type Controller = {
  customerId: number;
  controllerName: string;
  customerName?: string;
  deviceId: number;
  hwId: number;
  modelId: DeviceModelTypes;
  objectName: string;
  stationId: number;
  gatewayId: number;
  driverCode: string;
  objStatus: number;
  isObjectDisabled: boolean;
  objectNotes: string;
  installAddress: string;
  gisHorisontal: string;
  gisVertical: string;
  latitudeDec: number;
  longitudeDec: number;
  installDate: string;
  powerConnectDate: string;
  powerSupCode?: number;
  deviceType?: string;
  customerAgreementStatus?:number;
  state?: {
    alarmStatus?: string;
    batteryVoltage?: number;
    controllerTime?: string;
    dataSource?: number;
    syncDate?: string;
    calculated?: Calculated;
    currentFlow?: string;
    totalSlavesFlow?: string;
    rainRate?: string;
  };
};

export type NewController = {
  controllersCount: string;
  modelId: string;
  customerId: string;
  nameTemplate: string;
  notes: string;
  initialControllerId: string;
};

export function getRegisterLanguageText(registerText: ControllerRegistersEntry['text'], lang: string) {
  if (lang?.includes('en')) {
    return registerText.en ?? registerText.he;
  }

  return registerText.he;
}

export type ControllerRegistersEntry = {
  regNumber: number;
  bitNumber: number;
  regType: number;
  isDigital: boolean;
  eventID: number;
  oN_EventID: number;
  ofF_EventID: number;
  methode: string;
  methodeASCII: number;
  methodeIndex99: number;
  indexInRegType: number;
  blockNumberInRegType: number;
  text: {
    he: string;
    en?: string;
  };
  value: number;
};

export type ControllerRegisters = Record<string, ControllerRegistersEntry[]>;
export type DailyConsumption = {
  date: string;
  totalProg: number;
  totalActual: number;
  totalUncontrolled: number;
  dataIndex?: number;
};
export type ControllerClearDataFilter = {
  startDate: string;
  endDate: string;
  uncontrolledFlow: boolean;
  actualConsumption: boolean;
};
export type DeviceModelTypes = 'I1' | 'IP' | 'WE';

type TableData = Pick<Controller, 'deviceId' | 'objectName' | 'customerId' | 'customerName'>;
export type TableDataWithId = Omit<TableData, 'deviceId'> & { id: TableData['deviceId'] };
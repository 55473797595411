import { useAppDispatch } from 'shared/hooks';
import { setCustomerFilter } from 'shared/slices';
import { useAppSelector, useExtendedTranslation } from 'shared/hooks';
import { useState } from 'react';
interface SimProviderItem {
  value: string;
  label: string;
}
export const useFilterNetworkProtection = () => {
  const { t } = useExtendedTranslation();
  const { iCProjects } = useAppSelector((st) => st.networkProtection);

  // const { customerIDForFilter } = useAppSelector((st) => st.controllers);
  //const dispatch = useAppDispatch();
  const ICProjectFilterItems = iCProjects.map((s: { icProjectName: string, icProjectID: string }) => {
    return {
      value: s.icProjectID.toString(),
      label: s.icProjectName.toString(),
    };
  });

  const [ICProjectFilter, setICProjectFilter] = useState<string>('');
  const onChangeICProjectFilter = (values: string) => setICProjectFilter(values);
const statuses=[
  {status:'1'},
  {status:'0'},
]
  const statusFilterItems = statuses.map((s: { status: string }) => {
    return {
      value: s.status.toString(),
      label: t(`ic_project_status_${s.status.toString()}`),
    };
  });
  const [statusFilter, setStatusFilter] = useState<string>('1');
  const onChangeStatusFilter = (values: string) => setStatusFilter(values);


  return {
    ICProjectFilter,
    onChangeICProjectFilter,
    ICProjectFilterItems,

    statusFilter,
    onChangeStatusFilter,
    statusFilterItems
  };
};

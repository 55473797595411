import { TableTitle, TranslateColumn } from "components/BaseTable";
import { UserHistory } from "features/UserManager/UserModels";

import { t } from "i18next";

export const expirationDateItems = [

	{
		value: 'one Year',
		label: t('one_year_interval_filter_item'),
	},
	{
		value: 'Half Year',
		label: t('half_year_interval_filter_item'),
	},
	{
		value: '1 month',
		label: t('1_month_interval_filter_item'),
	},

	{
		value: '1 week',
		label: t('1_week_interval_filter_item'),
	},

]


export const datesIntervalItems = [

	{
		value: 'Half-year back',
		label: t('Half_year_back_dates_Interval_filter_item'),
	},
	{
		value: 'Year back',
		label: t('year_back_dates_Interval_filter_item'),
	},
	{
		value: 'Previous year',
		label: t('previous_year_dates_Interval_filter_item'),
	},

	{
		value: 'Current year',
		label: t('current_year_dates_Interval_filter_item'),
	},

]

export const TABLE_TITLES: TableTitle<UserHistory>[] = [
	{ title: 'action_date', key: 'actionDate', width: 140 },
	{ title: 'action_code', key: 'actionCode', width: 220 },
	{ title: 'status', key: 'status', width: 80 },
	{ title: 'user_name', key: 'userName', width: 140 },

	{ title: 'user_login', key: 'userLogin', width: 140 },
	{ title: 'app_name', key: 'appName', width: 120 },

	{ title: 'action_by_user_name', key: 'actionByUserName', width: 160 },
	{ title: 'action_by_user_login', key: 'actionByUserLogin', width: 160 },
	{ title: 'action_data', key: 'actionData', width: 160 },
	{ title: 'user_id', key: 'userID', width: 65 },
	{ title: 'action_by_user_id', key: 'actionByUserID', width: 120 },
	{ title: 'app_key', key: 'appKey', width: 160 },

];

export const TRANSLATE_COLUMNS: TranslateColumn<UserHistory>[] = [
	{ key: 'actionCode', additionalKey: '_action_code' },
	{ key: 'status', additionalKey: '_status_code' }

]

export const DEFAULT_ACTION_TYPE = ["password_check_failed", "CHECK_MAIL", "CHECK_PHONE", "CHANGE_PASSWORD_BY_USER", 'SEND_LINK_TO_RESET_PASSWORD', 'CHANGE_PASSWORD_WITH_CODE', 'CHANGE_PASSWORD_BY_ADMIN']
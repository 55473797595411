import { Box } from '@mui/material';
import { getFormattedDate } from 'shared/lib';
import {} from 'shared/lib';
import { t } from 'i18next';
export const CustomTooltip = (props: any) => {
  const { active, payload, label } = props;
  let text = '';
  if (label) {
    text = getFormattedDate(label);
  }

  let item = [];
  if (payload && payload.length) {
    item = payload.filter(
      (c: any) =>
        c.name == t('graph_Controllers_irrigation_data_totalProg') ||
        c.name == t('graph_Controllers_irrigation_data_totalUncontrolled') ||
        c.name === t('graph_Controllers_irrigation_data_totalActual')
    );
    item = item.map((c: any) => {
      if (c.name == t('graph_Controllers_irrigation_data_totalProg')) c.color = '#e9d698';
      if (c.name == t('graph_Controllers_irrigation_data_totalUncontrolled')) c.color = '#df91b5';
      if (c.name == t('graph_Controllers_irrigation_data_totalActual')) c.color = '#66aedf';
      return c;
    });
  }
  if (active && payload && payload.length) {
    return (
      <Box
        sx={{
          background: 'white',
          boxShadow: '2px 2px 5px 1px rgba(0, 0, 0, 0.1), -2px -2px 5px 1px rgba(0, 0, 0, 0.1)',
          padding: '8px',
        }}
      >
        <div>
          <span>{t('graph_Controllers_irrigation_tooltip_time')}</span>
          <span style={{ fontWeight: 'bold', margin: '0 5px' }}>{text}</span>
        </div>

        {item.map((i: any) => (
          <div key={i.name}>
            <span
              style={{
                width: '10px',
                height: '10px',
                borderRadius: '50%',
                backgroundColor: i.color,
                display: 'inline-block',
                margin: '0 5px',
              }}
            ></span>
            <span>{i.name}</span> <span style={{ fontWeight: 'bold' }}>{i.value}</span>
          </div>
        ))}
        <span></span>
      </Box>
    );
  }
  return null;
};

import { ServiceCallsReportManager } from 'features/ServiceCallsReportManager';
import { Layout } from 'shared/ui';
import { Header } from 'widgets';

export const ServiceCallsReportPage = () => {
	return (
		<Layout>
			<Header />
			<Layout.Body>
				<ServiceCallsReportManager />
			</Layout.Body>
		</Layout>
	);
};

import { useEffect, useState } from "react";
import { useGetCustomerTypes } from 'shared/api';
interface CustomerTypeFilter {
	value: string; label: string;
}
export const useFilter = () => {
	const [typeFilterItems, setChangedFilter] = useState<CustomerTypeFilter[]>(null);
	const { getCustomerTypes } = useGetCustomerTypes();

	useEffect(() => {
		const fetchData = async () => {
			let typeFilterItems = await getCustomerTypes();
			typeFilterItems = typeFilterItems.map((t) => ({ value: t.customerTypeID.toString(), label: t.customerType }));
			setChangedFilter(typeFilterItems);
		};
		fetchData();
	}, []);

	const [typeFilter, setTypeFilter] = useState("1");
	const onChangeTypeFilter = (value) => {
		setTypeFilter(value)
	}


	//const typeFilter = null;

	return {
		onChangeTypeFilter,
		typeFilter,
		typeFilterItems
	}

}
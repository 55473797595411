import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { WAMessageStatus } from './WAMessageStatusModels';


interface FotaState {
    wAMessageStatuses: WAMessageStatus[]
}

const initialState: FotaState = {
    wAMessageStatuses:[]
};

const wAMessageStatusSlice = createSlice({
  name: 'wAMessageStatus',
  initialState,
  reducers: {
    setWAMessageStatus: (state, action: PayloadAction<WAMessageStatus[]>) => {
      state.wAMessageStatuses = action.payload.map(item => {
          const result = {...item};  

           const serverCreateDate = new Date(item.createdAt);
          
           const clientCreateDate = new Date(serverCreateDate.getTime() );
           result.createdAt = clientCreateDate.toString();  

           const serverStatusDate = new Date(item.statusDate);
           
           const clientStatusDate = new Date(serverStatusDate.getTime());
           result.statusDate = clientStatusDate.toString();  


          return result;
      });
  },

  },
});

export const {
    setWAMessageStatus,
} = wAMessageStatusSlice.actions;

export const wAMessageStatusReducer = wAMessageStatusSlice.reducer;

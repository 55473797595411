

import useAxios from 'app/useAxios';
import { UserActions, ValvesReportView } from 'features/ControllerManager/controllerModels';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useToast } from 'shared/hooks';

export const useEventHistory = () => {
  const {loading, request } = useAxios();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { showError, showSuccess } = useToast();

	
  interface RequestData{
		startDate:string;
		endDate:string;
		deviceId:number;
	}
  return {

    getControllerEventHistory: (RequestData: RequestData) =>
      request<RequestData, { data: ValvesReportView[], succeeded:boolean }>({
        method: 'post',
        showSuccessMessageCode: undefined,
        url: `/device/eventHistory`,
        onDataReceiveCompleted: (data) => {
					
          if(!data.succeeded){
            showError(t('error_toast_text'));
            return []
          }
          return data
        },
        payload: RequestData
      }),

      getUserActions: (data: RequestData) =>
      request<undefined, { data: UserActions[]; succeeded: boolean }>({
        method: 'get',
        showSuccessMessageCode: undefined,
        url: `/device/userActions?startDate=${data.startDate}&endDate=${data.endDate}&deviceId=${data.deviceId}`,
        onDataReceiveCompleted: (data) => {
          if(!data.succeeded){
            showError(t('error_toast_text'));
            return []
          }
          return data
        },
      }),

    isLoadingUser: loading,
    isLoading: loading,
  };
};

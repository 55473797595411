import useAxios from './useAxios';
import { ScriptParam } from 'shared/models';
import { setScriptParameters } from 'shared/slices';
import { useAppDispatch } from 'shared/hooks';
export const useGetScriptParametersDataGrid = () => {
  const dispatch = useAppDispatch();
  const {loading, request } = useAxios();
  return {
    getScriptParametersDataGrid: (id: string) =>
      request<undefined, { data: ScriptParam[] }>({
        method: 'get',
		
        showSuccessMessageCode: undefined,
        url: `/networkprotection/controller/datagrid`,
        onDataReceiveCompleted: (data) => {
          dispatch(setScriptParameters(data.data));
        },
        params: { id: id },
      }),

    isLoadingCustomers: loading,
  };
};

import { Box, IconButton, MenuItem, Stack } from '@mui/material';
import { User } from 'features/UserManager/UserModels';
import {
  useServiceUsers,
  useUpdateAccessLevel,
  useUpdateUser,
  useUserManagement,
} from 'features/UserManager/hooks/api';
import { setUserProjects } from 'features/UserManager/userSlice';
import { t } from 'i18next';
import { ChangeEvent, MouseEvent, useEffect, useRef, useState } from 'react';
import { close_popup } from 'shared/assets';
import { useAnchorEl, useAppDispatch, useAppSelector, useTokenInfo } from 'shared/hooks';
import { BaseBtn, BaseCheckbox, BaseInput, BigTitle, Confirm, Modal, Row, Select } from 'shared/ui';
import { EditPermissions } from './../';
import { accessLevelItems } from './constants';
import { UserDetailsModal, UserResetPasswordModal } from './components/';
import { replaceSpacesWithUnderscores } from 'shared/lib';
interface Props {
  currentUser: User;
  openUserEditModal: boolean;
  onCloseModal: () => void;
}
export const UserEditModal = ({ currentUser, openUserEditModal, onCloseModal }: Props) => {
  const initialValues = {
    customerID: 0,
    userID: 0,
    userName: '',
    userLogin: '',
    userStatus: '',
    lastLoginDate: '',
    lastMonthLoginCount: '',
    lastPasswordSelfUpdate: '',
    lastPasswordTeamUpdate: '',
    nextPasswordUpdate: '',
    eMailAddress: '',
    phoneNumber: '',
    userRoleDescription: '',
    notes: '',
    backOfficeAccessLevel: 0,
    projectsCount: 0,
    foreignProjectAccess: '',
    isMainContact: 0
  };
  const initialErrors = {
    phoneNumber: '',
    eMailAddress: ''
  };
  const [errors, setErrors] = useState(initialErrors);
  const [hasErrors, setHasErrors] = useState(false);
  const { isAgamTechnician } = useAppSelector((st) => st.users);

  const dispatch = useAppDispatch();
  const [values, setValues] = useState<User>(initialValues);
  const [loadedData, setLoadedData] = useState(false);
  const [openEditPermissions, setOpenEditPermissions] = useState<boolean>(false);
  const [currentUserProjectAccess, setCurrentUserProjectAccess] = useState(null);
  const closeModal = () => {
    onCloseModal();
    setValues(initialValues);
    setCurrentUserProjectAccess(null);
    setLoadedData(false);
    setErrors(initialErrors)
    setHasErrors(false)
  };
  const { isOpen, anchorEl, onOpenMenu, onCloseMenu } = useAnchorEl();
  const [isChanged, setIsChanged] = useState(false);
  const { accessLevel } = useTokenInfo();
  const { isLoading, getUserProjects } = useUserManagement();
  const { updateAccessLevel } = useUpdateAccessLevel();
  const { updateBackofficeAccesslevelUser, updateUser } = useUpdateUser();
  const { getServiceUsers, setTechnician, deleteTechnician, isLoadingUsers } = useServiceUsers();
  const [isAgamTechnicianSelected, setIsAgamTechnicianSelected] = useState(false);
  const [isMainContactSelected, setIsMainContactSelected] = useState(false);
  const [isOpenUserDetailsModal, setIsOpenUserDetailsModal] = useState(false);

  useEffect(() => {
    if (openUserEditModal) {
      setValues(currentUser);
      setIsChanged(false);
      getServiceUsers(currentUser.userID);
      setLoadedData(true);
      setIsMainContactSelected(!!currentUser.isMainContact)
    }
  }, [openUserEditModal]);
  useEffect(() => {
    setIsAgamTechnicianSelected(isAgamTechnician);
  }, [isAgamTechnician]);

  const onChangeValues = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, propertyName: keyof User) => {
    const newValue = event.target.value;
    setValues((prevValues) => ({
      ...prevValues,
      [propertyName]: newValue,
    }));
    checkAndDeleteErrors(propertyName);

    setIsChanged(true);
  };
  const checkAndDeleteErrors = (propertyName: string) => {
    if (propertyName === 'phoneNumber') {
      setErrors((prevValues) => ({
        ...prevValues,
        ['phoneNumber']: '',
      }));
    }
    if (propertyName === 'eMailAddress') {
      setErrors((prevValues) => ({
        ...prevValues,
        ['eMailAddress']: '',
      }));
    }
    //console.log("valid", Object.values(errors).every((value) => value === ''))
    //  setHasErrors(Object.values(errors).every((value) => value === ''));
  }
  const handleCloseEditModal = (e: MouseEvent<HTMLElement>) => {
    //
    if (isLoading) return;
    isChanged || currentUserProjectAccess ? onOpenMenu(e) : closeModal();
  };

  const updateIsAgamTechnician = () => {
    if (isAgamTechnician && !isAgamTechnicianSelected) {
      deleteTechnician(currentUser.userID);
    }
    if (!isAgamTechnician && isAgamTechnicianSelected) {
      setTechnician({
        userID: currentUser.userID,
        customerID: currentUser.customerID,
        isTechnician: 1,
        isDispeture: 0,
      });
    }
  };

  const handleSave = async () => {
    updateIsAgamTechnician();
    if (currentUserProjectAccess) {
      const succeeded = await updateAccessLevel({
        userID: values.userID,
        newAccessLevelCode: currentUserProjectAccess,
      });
      if (!succeeded) return;
    }
    if (values.backOfficeAccessLevel !== currentUser.backOfficeAccessLevel) {
      await updateBackofficeAccesslevelUser(values);
    }
    const newValues = { ...values };
    if (newValues.phoneNumber || newValues.eMailAddress) {
      newValues.isMainContact = isMainContactSelected
    } else {
      newValues.isMainContact = false
    }
    updateUser(newValues);
    closeModal();
  };
  const onSubmit = () => {
    const hasErrors = validateForm()
    setHasErrors(hasErrors)
    if (hasErrors) {
      return
    }
    handleSave();
  };
  const onChangeSelectValues = (e: ChangeEvent<HTMLInputElement>) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    setIsChanged(true);
  };
  const handleOpenPermissionsEdit = () => {
    setOpenEditPermissions(true);
    getUserProjects(currentUser.userID);
  };
  const handleCloseEditPermissionsModal = () => {
    dispatch(setUserProjects([]));
    setOpenEditPermissions(false);
  };
  const handleLinkedAccountsChanged = () => {
    setIsChanged(true);
  };
  const onChangeCurrentUserProjectAccess = (access: string) => {
    setCurrentUserProjectAccess(access);
  };
  const changeIsAgamTechnician = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    setIsChanged(true);
    setIsAgamTechnicianSelected(newValue);
  };

  const changeIsMainContact = (event: ChangeEvent<HTMLInputElement>) => {
    if (!values?.phoneNumber && !values?.eMailAddress) return
    const newValue = event.target.checked;
    setIsChanged(true);
    setIsMainContactSelected(newValue);
  };

  function validatePhoneNumber(phoneNumber: string) {
    const phoneRegex = /^[+]?[\d]{0,3}\W?[(]?[\d]{3}[)]?[-\s.]?[\d]{3}[-\s.]?[\d]{4,6}$/im;
    return phoneRegex.test(phoneNumber);
  }

  const checkPhoneNumber = () => {
    const { phoneNumber } = values;
    if (phoneNumber && !validatePhoneNumber(phoneNumber)) {
      setErrors((prevValues) => ({
        ...prevValues,
        ['phoneNumber']: 'phoneNumber is not correct',
      }));
      return true;
    }

    return false;
  }
  const validateMail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  const checkMail = () => {
    const { eMailAddress } = values;
    if (eMailAddress && !validateMail(eMailAddress)) {
      setErrors((prevValues) => ({
        ...prevValues,
        ['eMailAddress']: 'e-Mail is not correct',
      }));
      return true;
    }

    return false;
  }
  const validateForm = () => {
    return checkPhoneNumber() || checkMail()
  };
  const openUserDetailsModal = () => {
    setIsOpenUserDetailsModal(true)
  }
  const handleCloseUserDetailsModal = () => {
    setIsOpenUserDetailsModal(false)
  }

  const closeButtonRef = useRef<HTMLButtonElement | null>(null);
  const handleModalClose = () => {
    if (closeButtonRef.current) {
      closeButtonRef.current.click();
    }
  };
  return (
    <Modal open={openUserEditModal} onClose={handleModalClose}>
      <Box sx={{ opacity: isLoadingUsers || !loadedData ? '0.2' : '1' }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <BigTitle>{t('user_edit_modal')}</BigTitle>
          <IconButton ref={closeButtonRef} onClick={handleCloseEditModal}>
            <img src={close_popup} />
          </IconButton>
        </Stack>
        <Box component="form" autoComplete="off">
          <Row spacing="20px" alignItems="flex-start" justifyContent="space-between">
            <Box width={'50%'}>
              <BaseInput
                label={`${t('customer_id_edit_modal')} *`}
                name="customerID"
                helperText={''}
                value={values?.customerID}
                onChange={(event) => onChangeValues(event, 'customerID')}
                disabled
              />
              <BaseInput
                label={`${t('user_name_edit_modal')} *`}
                name="userName"
                helperText={''}
                value={values?.userName}
                onChange={(event) => onChangeValues(event, 'userName')}
                disabled
              />
              <BaseInput
                label={`${t('user_status_edit_modal')} *`}
                name="userStatus"
                helperText={''}
                value={t(values?.userStatus)}
                onChange={(event) => onChangeValues(event, 'userStatus')}
                disabled
              />
              <BaseInput
                label={`${t('user_phone_edit_modal')}`}
                name="phoneNumber"
                value={t(values?.phoneNumber)}
                error={!!errors.phoneNumber}
                helperText={replaceSpacesWithUnderscores(errors.phoneNumber)}
                onChange={(event) => onChangeValues(event, 'phoneNumber')}
              />
            </Box>
            <Box width={'50%'}>
              <BaseInput
                label={`${t('user_id_edit_modal')} *`}
                name="userID"
                helperText={''}
                value={values?.userID}
                onChange={(event) => onChangeValues(event, 'userID')}
                disabled
              />
              <BaseInput
                label={`${t('user_login_edit_modal')} *`}
                name="userLogin"
                helperText={''}
                value={values?.userLogin}
                onChange={(event) => onChangeValues(event, 'userLogin')}
                disabled
              />
              <Select
                label={`${t('user_access_level_edit_modal')} *`}
                name="backOfficeAccessLevel"
                value={values?.backOfficeAccessLevel}
                onChange={onChangeSelectValues}
                displayEmpty
                sx={{
                  fontFamily: 'Noto Sans Hebrew !important',
                }}
                disabled={accessLevel < 15}
              >
                {accessLevelItems.map((s) => (
                  <MenuItem
                    key={s.accessLevelKey}
                    value={s.accessLevelValue}
                    sx={{
                      fontFamily: 'Noto Sans Hebrew !important',
                    }}
                  >
                    {t(s.accessLevelKey)}
                  </MenuItem>
                ))}
              </Select>
              <BaseInput
                label={`${t('user_email_edit_modal')}`}
                name="eMailAddress"
                value={t(values?.eMailAddress)}
                onChange={(event) => onChangeValues(event, 'eMailAddress')}
                error={!!errors.eMailAddress}
                helperText={replaceSpacesWithUnderscores(errors.eMailAddress)}

              />
            </Box>
          </Row>
          <Row spacing="20px" alignItems="flex-start" justifyContent="space-between">
            <Box width={'50%'}>
              <Row spacing="20px" alignItems="flex-start" justifyContent="space-between">
                <Box width={'50%'}>
                  <BaseInput
                    label={`${t('access_permissions_edit_modal')}`}
                    name="foreignProjectAccess"
                    helperText={''}
                    value={t(
                      `${currentUserProjectAccess ? currentUserProjectAccess : values?.foreignProjectAccess}_access_level`
                    )}
                    onChange={(event) => onChangeValues(event, 'foreignProjectAccess')}
                    disabled
                  />
                </Box>
                <Box width={'25%'}>
                  <BaseInput
                    label={`${t('projects_count_edit_modal')}`}
                    name="projectsCount"
                    helperText={''}
                    value={values?.projectsCount}
                    onChange={(event) => onChangeValues(event, 'projectsCount')}
                    disabled
                  />
                </Box>
                <Box width={'25%'}>
                  <BaseBtn
                    onClick={handleOpenPermissionsEdit}
                    btnColor="primary.dark"
                    sx={{ marginTop: '44px' }}
                    fullWidth
                    disabled={accessLevel < 15}
                  >
                    {t('change_permissions_edit_modal')}
                  </BaseBtn>
                </Box>

              </Row>

            </Box>
            <Box width={'50%'}>
              <Row spacing="20px" alignItems="flex-start" justifyContent="space-between">
                <Box width={'50%'} sx={{ mt: '34px' }}>
                  <BaseCheckbox
                    label={t('is_main_contact')}
                    value={isMainContactSelected}
                    onChange={changeIsMainContact}
                    marginTop="0px"
                    disabled={!values?.phoneNumber && !values?.eMailAddress}
                  />
                  <BaseCheckbox
                    label={t('is_agam_technician')}
                    value={isAgamTechnicianSelected}
                    onChange={changeIsAgamTechnician}
                    marginTop="0px"
                  />
                </Box>
                <Box width={'50%'}>
                  <BaseBtn onClick={openUserDetailsModal} fullWidth sx={{ mt: '44px' }}>
                    {t('user_authentication_details_button')}
                  </BaseBtn>
                </Box></Row>
            </Box>

          </Row>
          <Row justifyContent="flex-end" mt={'35px'}>
            <Row width={300}>
              <BaseBtn onClick={handleCloseEditModal} btnColor="info.main" fullWidth>
                {t('cancel_edit_modal')}
              </BaseBtn>
              <Box onClick={handleCloseEditModal} sx={{ height: '40px', width: '25px' }} />
              <BaseBtn
                onClick={onSubmit}
                disabled={!isChanged && !currentUserProjectAccess}
                loading={isLoading}
                btnColor="primary.dark"
                fullWidth
              >
                {t('save_edit_modal')}
              </BaseBtn>
            </Row>
          </Row>
        </Box>

        <Confirm
          open={isOpen}
          anchorEl={anchorEl}
          onClose={onCloseMenu}
          confirm={closeModal}
          buttonTexts={[t('cancel_edit_edit_modal'), t('back_to_edit_edit_modal')]}
        />
        <EditPermissions
          isOpenModal={openEditPermissions}
          currentUser={currentUser}
          closeModal={handleCloseEditPermissionsModal}
          linkedAccountsChanged={handleLinkedAccountsChanged}
          changeCurrentUserProjectAccess={onChangeCurrentUserProjectAccess}
          currentUserProjectAccess={currentUserProjectAccess}
          isLoading={isLoading}
        />

        <UserDetailsModal
          isOpenModal={isOpenUserDetailsModal}
          closeModal={handleCloseUserDetailsModal}
          currentUser={currentUser}
        />
      </Box >
    </Modal >
  );
};

import { CircularProgressProps, Box, CircularProgress, Typography, styled } from '@mui/material';

const CustomCircularProgress = styled(CircularProgress)`
  & .MuiCircularProgress-circle {
    stroke-dashoffset: 60;
  }
`;

export const ProgressBar = (props: CircularProgressProps & { progress: number }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box sx={{ position: 'relative' }}>
        <CircularProgress
          variant="determinate"
          sx={{
            color: '#d2d6e3',
          }}
          size={73.56}
          thickness={6}
          {...props}
          value={100}
        />
        <CustomCircularProgress
          sx={{ position: 'absolute', left: 0 }}
          variant="indeterminate"
          size={73.56}
          disableShrink
          thickness={6}
          {...props}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 5,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 600,
              lineHeight: '19px',
              color: 'primary.dark',
            }}
            variant="caption"
            component="div"
            color="text.secondary"
          >{`${Math.round(props.progress)}%`}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from 'features/ImportExcel/hooks/useMapExcelData';
import { Sim, SimStatuses, SimProviders, SimOperators, SimImportResult } from 'shared/models';

interface SimManagementState {
  sims: Sim[];
  currentSim: Sim | null;
  simStatuses: SimStatuses[];
  simProviders: SimProviders[];
  simOperators: SimOperators[];
  simImportResult: SimImportResult | null;
  simImportStatus: string;
  simImportTotalRows: number;
  simImportProvider: string;
  simImportErrors: IError[];
  simImportFileName: string;
  simNotValidRows: number[];
  isSimsLoading: boolean;
}

const initialState: SimManagementState = {
  sims: [],
  currentSim: null,
  simStatuses: [],
  simProviders: [],
  simOperators: [],
  simImportResult: null,
  simImportStatus: 'no_file_selected_import_status',
  simImportTotalRows: 0,
  simImportProvider: '',
  simImportFileName: '',
  simImportErrors: [],
  simNotValidRows: [],
  isSimsLoading: false,
};

const simManagementSlice = createSlice({
  name: 'simManagement',
  initialState,
  reducers: {
    setSims: (state, action: PayloadAction<Sim[]>) => {
      state.sims = action.payload;
    },
    setSimStatuses: (state, action: PayloadAction<SimStatuses[]>) => {
      state.simStatuses = action.payload;
    },
    setSimProviders: (state, action: PayloadAction<SimStatuses[]>) => {
      state.simProviders = action.payload;
    },
    setSimOperators: (state, action: PayloadAction<SimOperators[]>) => {
      state.simOperators = action.payload;
    },
    setCurrentSim: (state, action: PayloadAction<Sim>) => {
      state.currentSim = action.payload;
    },
    updateSim: (state, action: PayloadAction<Sim>) => {
      if (state.sims) {
        const index = state.sims.findIndex((item) => item.id === action.payload.id);
        state.sims[index] = action.payload;
      }
    },
    setSimImportResult: (state, action: PayloadAction<SimImportResult | null>) => {
      state.simImportResult = action.payload;
    },
    setSimImportStatus: (state, action: PayloadAction<string>) => {
      state.simImportStatus = action.payload;
    },
    setSimImportTotalRows: (state, action: PayloadAction<number>) => {
      state.simImportTotalRows = action.payload;
    },
    setSimImportProvider: (state, action: PayloadAction<string>) => {
      state.simImportProvider = action.payload;
    },
    setSimImportFileName: (state, action: PayloadAction<string>) => {
      state.simImportFileName = action.payload;
    },
    setSimImportErrors: (state, action: PayloadAction<IError[]>) => {
      state.simImportErrors = action.payload;
    },
    setSimNotValidRows: (state, action: PayloadAction<number[]>) => {
      state.simNotValidRows = action.payload;
    },
    setSimsLoading: (state, action: PayloadAction<boolean>) => {
      state.isSimsLoading = action.payload;
    },
  },
});

export const {
  setSims,
  setSimStatuses,
  setSimProviders,
  setSimOperators,
  setCurrentSim,
  updateSim,
  setSimImportResult,
  setSimImportStatus,
  setSimImportTotalRows,
  setSimImportProvider,
  setSimImportErrors,
  setSimNotValidRows,
  setSimImportFileName,
  setSimsLoading,
} = simManagementSlice.actions;
export const simManagementReducer = simManagementSlice.reducer;

import uPlot from "uplot";
import { chart_general_fault_start, chart_general_fault_end, chart_manual_valve_opening_start } from 'shared/assets';
import { SIGNAL_POINT, COMMUNICATION_RESTORED_POINT, COMMUNICATION_FAULT_POINT, LINE_HEIGHT_RATIO, DOT_SIZE, ICON_LEFT_POSITION, ICON_TOP_POSITION } from './../components/Constants';
import { usePlugins } from '../hooks/usePlugins';
// Enum values
const SPACE_BETWEEN = 1;
const SPACE_AROUND = 2;
const SPACE_EVENLY = 3;
//const { chartSize } = usePlugins();

// Helper functions
function pointWithin(px, py, rlft, rtop, rrgt, rbtm) {
  return px >= rlft && px <= rrgt && py >= rtop && py <= rbtm;
}

function roundDec(val, dec) {
  return Math.round(val * (dec = 10 ** dec)) / dec;
}

function coord(i, offset, itemWidth, gap) {
  return roundDec(offset + i * (itemWidth + gap), 6);
}

function distributeItems(numItems, sizeFactor, justify, onlyIdx, each) {
  let remainingSpace = 1 - sizeFactor;

  let gap =
    justify === SPACE_BETWEEN
      ? remainingSpace / (numItems - 1)
      : justify === SPACE_AROUND
        ? remainingSpace / numItems
        : justify === SPACE_EVENLY
          ? remainingSpace / (numItems + 1)
          : 0;

  if (isNaN(gap) || gap === Infinity) gap = 0;

  let offset =
    justify === SPACE_BETWEEN
      ? 0
      : justify === SPACE_AROUND
        ? gap / 2
        : justify === SPACE_EVENLY
          ? gap
          : 0;

  let itemWidth = sizeFactor / numItems;
  let roundedItemWidth = roundDec(itemWidth, 6);

  if (onlyIdx === null) {
    for (let i = 0; i < numItems; i++)
      each(i, coord(i, offset, itemWidth, gap), roundedItemWidth);
  } else {
    each(onlyIdx, coord(onlyIdx, offset, itemWidth, gap), roundedItemWidth);
  }
}

const createQuadtree = (x, y, w, h, l = 0) => {
  const o = [];
  let q = null;

  const split = () => {
    const nw = createQuadtree(x, y, w / 2, h / 2, l + 1);
    const ne = createQuadtree(x + w / 2, y, w / 2, h / 2, l + 1);
    const sw = createQuadtree(x, y + h / 2, w / 2, h / 2, l + 1);
    const se = createQuadtree(x + w / 2, y + h / 2, w / 2, h / 2, l + 1);

    q = [nw, ne, sw, se];
  };

  const quads = (x1, y1, w1, h1, cb) => {
    const x2 = x + w / 2;
    const y2 = y + h / 2;

    const startIsNorth = y1 < y2;
    const startIsWest = x1 < x2;
    const endIsEast = x1 + w1 > x2;
    const endIsSouth = y1 + h1 > y2;

    if (startIsNorth && endIsEast) cb(q[0]); // top-right quad
    if (startIsWest && startIsNorth) cb(q[1]); // top-left quad
    if (startIsWest && endIsSouth) cb(q[2]); // bottom-left quad
    if (endIsEast && endIsSouth) cb(q[3]); // bottom-right quad
  };

  const add = (obj) => {
    if (q !== null) {
      quads(obj.x, obj.y, obj.w, obj.h, (quad) => {
        quad.add(obj);
      });
    } else {
      o.push(obj);
      const maxObjects = 10;
      const maxLevels = 4;

      if (o.length > maxObjects && l < maxLevels) {
        split();
        for (let i = 0; i < o.length; i++) {
          quads(o[i].x, o[i].y, o[i].w, o[i].h, (quad) => {
            quad.add(o[i]);
          });
        }
        o.length = 0;
      }
    }
  };

  const get = (x1, y1, w1, h1, cb) => {
    for (let i = 0; i < o.length; i++) {
      cb(o[i]);
    }
    if (q !== null) {
      quads(x1, y1, w1, h1, (quad) => {
        quad.get(x1, y1, w1, h1, cb);
      });
    }
  };

  const clear = () => {
    o.length = 0;
    q = null;
  };

  return { add, get, clear };
};

// Plugin

function timelinePlugin(opts) {
  const { count, fill, stroke } = opts;
  const pxRatio = devicePixelRatio;

  const laneWidth = 0.9;
  const laneDistr = SPACE_BETWEEN;

  const font = Math.round(0 * pxRatio) + "px Arial"; // font

  function walk(yIdx, count, dim, draw) {
    distributeItems(count, laneWidth, laneDistr, yIdx, (i, offPct, dimPct) => {
      let laneOffPx = dim * offPct;
      let laneWidPx = dim * dimPct;

      draw(i, laneOffPx, laneWidPx);
    });
  }

  const fillPaths = new Map();
  const strokePaths = new Map();

  function drawBoxes(ctx) {
    fillPaths.forEach((fillPath, fillStyle) => {
      ctx.fillStyle = fillStyle;
      ctx.fill(fillPath);
    });

    strokePaths.forEach((strokePath, strokeStyle) => {
      ctx.strokeStyle = strokeStyle;
      ctx.stroke(strokePath);
    });

    fillPaths.clear();
    strokePaths.clear();
  }
  function putBox(
    _ctx,
    rect,
    xOff,
    yOff,
    lft,
    top,
    wid,
    hgt,
    strokeWidth,
    iy,
    ix,
    value
  ) {
    const TOP_MARGIN = 1;
    const TOP_MARGIN_MANUAL_ICON = -4;
    let fillStyle = fill(iy + 1, ix, value);
    let fillPath = fillPaths.get(fillStyle);
    if (fillPath == null) {
      fillPaths.set(fillStyle, (fillPath = new Path2D()));
    }
    if (isNaN(wid)) wid = 1000
    rect(fillPath, lft, top + TOP_MARGIN, wid, hgt);

    if (strokeWidth) {
      let strokeStyle = stroke(iy + 1, ix, value);
      let strokePath = strokePaths.get(strokeStyle);
      if (strokePath == null) {
        strokePaths.set(strokeStyle, (strokePath = new Path2D()));
      }
      rect(
        strokePath,
        lft + strokeWidth / 2,
        top + strokeWidth / 2,
        wid - strokeWidth,
        hgt - strokeWidth
      );
    }

    qt.add({
      x: Math.round(lft - xOff),
      y: Math.round(top - yOff),
      w: wid,
      h: hgt,
      sidx: iy + 1,
      didx: ix,
    });

    switch (value) {
      case "start":
        drawPoint(_ctx, lft, top + TOP_MARGIN + hgt / 2, SIGNAL_POINT);
        break;

      case "end":
        if (!wid) {
          wid = 1;
        }
        drawPoint(_ctx, lft + wid, top + TOP_MARGIN + hgt / 2, SIGNAL_POINT);
        break;

      case "communication fault":
        if (!wid) {
          wid = 1;
        }
        drawPoint(_ctx, lft, top + TOP_MARGIN + hgt / 2, COMMUNICATION_FAULT_POINT);
        break;

      case "communication restored":
        if (!wid) {
          wid = 1;
        }
        drawPoint(_ctx, lft + wid, top + TOP_MARGIN + hgt / 2, COMMUNICATION_RESTORED_POINT);
        break;

      // case "generalFault":
      case "highFlowRate":
      case "lowFlowRate":
      case "missingPulses":
        if (!wid) {
          wid = 1;
        }
        drawSvgIcon(_ctx, lft + wid, top + TOP_MARGIN_MANUAL_ICON, chart_general_fault_start, 20, 20);
        break;

      case "waterLeakage": if (!wid) {
        wid = 1;
      }
        drawSvgIcon(_ctx, lft, top + TOP_MARGIN_MANUAL_ICON + 24, chart_general_fault_start, 20, 20);
        break;

      case "manualValveOpening":
        if (!wid) {
          wid = 1;
        }
        drawSvgIcon(_ctx, lft + wid, top + TOP_MARGIN - 5, chart_manual_valve_opening_start, 20, 20);
        break;
      case "userActionsEvent": if (!wid) {
          wid = 1;
        }
          drawSvgIcon(_ctx, lft, top + TOP_MARGIN_MANUAL_ICON + 24, chart_manual_valve_opening_start, 20, 20);
          break;
      default:

        break;
    }


  }

  function drawPoint(ctx, x, y, color) {
    const scaleFactor = window.devicePixelRatio || 1;

    const adjustedDotSize = DOT_SIZE * scaleFactor;

    ctx.beginPath();
    ctx.arc(x, y, adjustedDotSize, 0, 4 * Math.PI);
    ctx.fillStyle = color;
    ctx.fill();
  }

  function drawSvgIcon(ctx, x, y, svgUrl, width, height) {
    if (x < 80 || x > ctx.canvas.width - 80) return
    const img = new Image();
    img.onload = function () {
      ctx.drawImage(img, x - ICON_LEFT_POSITION, y - ICON_TOP_POSITION, width, height);
    };

    img.src = svgUrl;
  }

  function drawPaths(u, sidx, idx0, idx1) {
    uPlot.orient(
      u,
      sidx,
      (
        series,
        dataX,
        dataY,
        scaleX,
        _scaleY,
        valToPosX,
        _valToPosY,
        xOff,
        yOff,
        xDim,
        yDim,
        _moveTo,
        _lineTo,
        rect
      ) => {
        let strokeWidth = Math.round((series.width || 0) * pxRatio);

        u.ctx.save();
        rect(u.ctx, u.bbox.left, u.bbox.top, u.bbox.width, u.bbox.height);
        u.ctx.clip();

        walk(sidx - 1, count, yDim, (iy, y0, hgt) => {
          for (let ix = 0; ix < dataY.length; ix++) {
            if (dataY[ix] != null) {
              let lft = Math.round(valToPosX(dataX[ix], scaleX, xDim, xOff));

              let nextIx = ix + 1;
              while (nextIx < dataY.length && dataY[nextIx] === undefined) {
                nextIx++;
              }

              let rgt =
                nextIx === dataY.length
                  ? xOff + xDim + strokeWidth
                  : Math.round(valToPosX(dataX[nextIx], scaleX, xDim, xOff));

              putBox(
                u.ctx,
                rect,
                xOff,
                yOff,
                lft,
                Math.round(yOff + y0),
                rgt - lft,
                Math.round(hgt / LINE_HEIGHT_RATIO),
                strokeWidth,
                iy,
                ix,
                dataY[ix]
              );

              ix = nextIx - 1;
            }
          }
        });

        u.ctx.lineWidth = strokeWidth;
        drawBoxes(u.ctx);

        u.ctx.restore();
      }
    );

    return null;
  }
  function drawPoints(u, sidx, i0, i1) {
    u.ctx.save();
    u.ctx.rect(u.bbox.left, u.bbox.top, u.bbox.width, u.bbox.height);
    u.ctx.clip();

    u.ctx.font = font;
    u.ctx.fillStyle = "black";
    u.ctx.textAlign = "left";
    u.ctx.textBaseline = "middle";

    uPlot.orient(
      u,
      sidx,
      (
        series,
        dataX,
        dataY,
        scaleX,
        _scaleY,
        valToPosX,
        _valToPosY,
        xOff,
        yOff,
        xDim,
        _yDim,
        _moveTo,
        _lineTo,
        _rect
      ) => {
        let strokeWidth = Math.round((series.width || 0) * pxRatio);
        let textOffset = strokeWidth + 2;

        let y = Math.round(yOff + yMids[sidx - 1]);

        for (let ix = 0; ix < dataY.length; ix++) {
          if (dataY[ix] != null) {
            let x = valToPosX(dataX[ix], scaleX, xDim, xOff) + textOffset;
            u.ctx.fillText(dataY[ix], x, y);
          }
        }
      }
    );

    u.ctx.restore();

    return false;
  }

  let qt;
  let hovered = Array(count).fill(null);

  let yMids = Array(count).fill(0);
  let ySplits = Array(count).fill(0);


  const drawClear = (u) => {
    qt = qt || createQuadtree(0, 0, u.bbox.width, u.bbox.height);
    qt.clear();

    // force-clear the path cache to cause drawBars() to rebuild new quadtree
    u.series.forEach((s) => {
      s._paths = null;
    });
  };

  const setCursor = (u) => {
    let val = u.posToVal(u.cursor.left, "x");
    // legendTimeValueEl.textContent = u.scales.x.time
    //   ? fmtDate(new Date(val * 1e3))
    //   : val.toFixed(2);
  };

  function setDataIdx(u, seriesIdx, closestIdx, _xValue) {
    if (seriesIdx === 0) return closestIdx;

    let cx = Math.round(u.cursor.left * pxRatio);
    if (cx >= 0) {
      let cy = yMids[seriesIdx - 1];
      hovered[seriesIdx - 1] = null;

      qt.get(cx, cy, 1, 1, (o) => {
        if (pointWithin(cx, cy, o.x, o.y, o.x + o.w, o.y + o.h))
          hovered[seriesIdx - 1] = o;
      });
    }

    return hovered[seriesIdx - 1]?.didx;
  }

  function setPointsBbox(_u, seriesIdx) {
    let hRect = hovered[seriesIdx - 1];

    return {
      left: hRect ? Math.round(hRect.x / devicePixelRatio) : -10,
      top: hRect ? Math.round(hRect.y / devicePixelRatio) : -10,
      width: hRect ? Math.round(hRect.w / devicePixelRatio) : 0,
      height: hRect ? Math.round(hRect.h / devicePixelRatio) : 0
    };
  }

  function getSplits(u, _axisIdx) {
    walk(null, count, u.bbox.height, (iy, y0, hgt) => {
      // vertical midpoints of each series' timeline (stored relative to .u-over)
      yMids[iy] = Math.round(y0 + hgt / 6);
      ySplits[iy] = u.posToVal(yMids[iy] / pxRatio, "y");
    });

    return ySplits;
  }

  function assignSeries(s, i) {
    if (i > 1) {
      uPlot.assign(s, {
        paths: drawPaths,
        points: {
          show: drawPoints
        }
      });
    }
  }

  return {
    hooks: {
      init: (u) => {
        // legendTimeValueEl = u.root.querySelector(
        //   ".u-series:first-child .u-value"
        // );
      },
      drawClear,
      setCursor
    },
    opts: (u, opts) => {
      uPlot.assign(opts, {
        cursor: {
          y: false,
          dataIdx: setDataIdx,
          points: {
            fill: "rgba(0,0,0,0.3)",
            bbox: setPointsBbox
          }
        },
        scales: {
          flow_rate: {},
          x: {
            range(u, min, max) {
              return [min, max];
            }
          },
          y: {
            range: [0, 1]
          },

        }
      });

      uPlot.assign(opts.axes[0], {
        splits: null,

      });

      uPlot.assign(opts.axes[1], {
        splits: getSplits,
        values: () =>
          Array(count)
            .fill(null)
            .map((v, i) => u.series[i + 1].label),
        gap: 15,
        size: 40,
        grid: { show: true, dash: [4, 4], },
        ticks: { show: false },

      });

      opts.series.forEach(assignSeries);
    }
  };
}

export default timelinePlugin;

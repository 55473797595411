import { useAppDispatch } from 'shared/hooks';
import { setCustomers } from 'shared/slices';
import useAxios from './useAxios';
import { Customer } from 'shared/models';

export const useGetCustomers = () => {
  const dispatch = useAppDispatch();
  const { loading, request } = useAxios();

  return {
    getCustomer: () =>
      request<undefined, { data: Customer[] }>({
        method: 'get',
        url: `/Customer`,
        showSuccessMessageCode: undefined,
        onDataReceiveCompleted: (data) => {
          dispatch(setCustomers(data.data));
        },
      }),
    isLoadingCustomers: loading,
  };
};

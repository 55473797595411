import { Box } from '@mui/material';
import { BaseExportToExcel, BaseTable, PagePreloader } from 'components';
import { Filter } from 'features';
import { useEffect, useMemo, useState } from 'react';
import { useSimManagement } from 'shared/api';
import { edit } from 'shared/assets';
import { useAppDispatch, useAppSelector, useExtendedTranslation } from 'shared/hooks';
import { getSearchedSims } from 'shared/lib';
import { setCurrentSim, toggleEditSim, toggleImportSim } from 'shared/slices';
import { BaseBtn, BigTitle, Row, SearchInput, Text } from 'shared/ui';
import TotalRows from 'shared/ui/TotalRows';
import { SIM_TABLE_TITLES, TRANSLATE_COLUMNS } from './constants';
import { useFilterSims, useFormatXlsxData, useSearchSims } from './hooks';

export const SimManager = () => {
	const dispatch = useAppDispatch();
	const { formatTable } = useFormatXlsxData();
	const { isLoadingSim, getSim, getStatuses, getProvider } = useSimManagement();
	const loading = isLoadingSim;
	const [loaded, setLoaded] = useState(false);
	const { sims } = useAppSelector((st) => st.simManagement);
	const {
		simStatusFilter,
		onChangeSimStatusFilter,
		simStatusFilterItems,
		simProviderItems,
		onChangeSimProviderFilter,
		simProviderFilter,
	} = useFilterSims();
	const { searchValue, changeSearch } = useSearchSims();
	const [isStartedLoadingTable, setIsStartedLoadingTable] = useState(false);

	// const fetchData = async () => {
	//   getStatuses();
	//   getProvider();
	//   await getSim();
	// };

	// const { isLoading } = useQuery('sim data', fetchData);

	useEffect(() => {
		const fetchData = async () => {
			getStatuses();
			getProvider();
			await getSim();
			setLoaded(true)
		};
		fetchData();
		setIsStartedLoadingTable(true);
	}, []);

	const { t } = useExtendedTranslation();


	const tableSims = useMemo(() => {
		let filteredSims = sims.filter((c) => (simStatusFilter ? c.statusCode?.toString() === simStatusFilter : true));
		filteredSims = filteredSims.filter((c) =>
			simProviderFilter ? c.simProvider?.toString() === simProviderFilter : true
		);
		const searchedFilteredSims = getSearchedSims(filteredSims, searchValue);
		return searchedFilteredSims;
	}, [searchValue, sims, simStatusFilter, simProviderFilter]);



	const onClickImportSIMList = () => {
		dispatch(toggleImportSim());
	};
	const { currentSim } = useAppSelector((st) => st.simManagement);
	const handleOpenSimEditModal = (item) => {
		dispatch(toggleEditSim());
		dispatch(setCurrentSim(item));
	}
	const actions = useMemo(
		() => [
			{ icon: edit, title: `${t('edit-user-grid-table-modal')}`, onClick: handleOpenSimEditModal },
		],
		[]
	);
	const BaseExportToExcelComponent = useMemo(
		() => (<BaseExportToExcel
			tableData={tableSims}
			sheetName="SIM"
			fileName="sims.xlsx"
			buttonText={t('export_sims_page_button')}
			dateColumnIndexes={[8, 9, 10, 11]}
			stringColumnIndexes={[1, 2]}
			formattedTable={formatTable(tableSims)}
			tableTitles={SIM_TABLE_TITLES}
			headerItemsPrefix={'_sim_grid_table'}
		/>)
		,
		[tableSims]
	);
	return (
		<>
			<PagePreloader loading={!loaded} />
			<>
				<Row sx={{ mb: '12px' }} flexWrap="wrap" rowGap="5px">
					<BigTitle sx={{ mr: '25px' }}>{t('title_sim_management_page')}</BigTitle>
					<BaseBtn onClick={onClickImportSIMList}>
						{t('Import_sim_management_page')}
						<Text sx={{ fontSize: '25px', mb: '4px', ml: '4px' }}>+</Text>
					</BaseBtn>
				</Row>
				<SearchInput value={searchValue} onChange={changeSearch} placeholder={`${t('search_sim_management_page')}`} />
				<Row sx={{ mb: '12px' }}>
					<BigTitle sx={{ fontSize: '24px', mr: '20px', mt: '16px' }}>
						{t('filter_title_sim_management_page')}
					</BigTitle>

					{simStatusFilterItems && simStatusFilterItems.length ? (
						<Filter
							title={`${t('sim_status_label')}`}
							value={simStatusFilter}
							onChange={onChangeSimStatusFilter}
							items={simStatusFilterItems}
							sx={{ width: '260px' }}
							messageEmptyFilter="display_all_sim_status"
							placeholder={`${t('filter_sim_status_placeholder')}`}
						/>
					) : (
						<></>
					)}
					<BigTitle sx={{ fontSize: '24px', mr: '20px', mt: '16px' }}>{t('selected_sim_page_title')}</BigTitle>
					{simProviderItems && simProviderItems.length ? (
						<Filter
							title={`${t('sim_provider_filter')}`}
							value={simProviderFilter}
							onChange={onChangeSimProviderFilter}
							items={simProviderItems}
							sx={{ width: '260px' }}
							messageEmptyFilter={`${t('display_all_sim_provider')}`}
							placeholder={`${t('filter_sim_provider_placeholder')}`}
						/>
					) : (
						<></>
					)}
					<Row sx={{ flexGrow: 1 }} />
					<Box sx={{ alignSelf: 'end' }}>{/* {tableSims && <ControllersExportBtn controllers={tableSims} />} */}</Box>
					<Box sx={{ display: 'flex', marginLeft: 'auto', alignItems: 'flex-end' }}>
						<TotalRows count={tableSims.length} />

						{BaseExportToExcelComponent}

					</Box>
				</Row>

				{/* <SimManagementTable isStartedLoadingTable={isStartedLoadingTable} tableSim={tableSims} /> */}

				<BaseTable
					tableItems={tableSims}
					loadedItems={true}
					idField={'id'}
					noItemsText={t('no_sims_sims_grid_table')}
					headerItemsPrefix={'_sim_grid_table'}
					dateColumnIndexes={[8, 9, 10, 11]}
					dateTimeColumnIndexes={[9]}
					selectedRowID={currentSim?.id}
					maxHeight="calc(100vh - 265px)"
					height="calc(100vh - 265px)"
					tableTitles={SIM_TABLE_TITLES}
					translateColumns={TRANSLATE_COLUMNS}
					actions={actions}
					linkIndex={[1]}
					cellClick={handleOpenSimEditModal}
					loading={!loaded}
				/>


			</>
		</>
	)
}

import { Box, Typography } from '@mui/material';
import { BaseDataPicker } from 'features';
import { t } from 'i18next';
import { FC, useEffect, useState } from 'react';
import { arrow_right } from 'shared/assets';
import { BaseBtn, BigTitle, Icon, Row } from 'shared/ui';
import { useGetDefaultDates } from './hooks';
import { FormattedGraphData } from '../hooks/useFormattedGraphData';
import { useBatteryStatusReport } from 'shared/api';
import { useFormattedGraphData } from 'shared/lib';
import { useAppSelector, useToast } from 'shared/hooks';
import { Controller } from 'features/ControllerManager/controllerModels';
import localStorageService from 'shared/localStorage';

type Props = {
  deviceId: number;
  powerSource?: string;
  setFormattedData: (formattedData: FormattedGraphData[]) => void;
  setBatteryGraphParams: (params: { fromDate: string; toDate: string }) => void;
  setIsOpen: (isOpen: boolean) => void;
  isOpenModal: boolean;
  currentController: Controller;
  reportStartDate?: string;
  reportEndDate?: string;
  dataLoaded: (isLoaded: boolean) => void;
  allControllerDataLoaded: boolean;
  isImplemented: boolean;
};

export const BatteryGraphHeader: FC<Props> = ({
  powerSource,
  deviceId,
  setFormattedData,
  setBatteryGraphParams,
  setIsOpen,
  isOpenModal,
  currentController,
  reportStartDate,
  reportEndDate,
  dataLoaded,
  allControllerDataLoaded,
  isImplemented
}) => {
  useEffect(() => {
    if (allControllerDataLoaded && isOpenModal) {
      fetchData();

    }
  }, [allControllerDataLoaded]);
  const { isRTL } = localStorageService;
  const { getBatteryStatusReportProject, isLoadingGraphBatteryStatusReport } = useBatteryStatusReport();
  const { BATT_REP } = useAppSelector((st) => st.globalSlice);
  const { startDate, endDate } = useGetDefaultDates({ reportStartDate, reportEndDate });
  const initialFilter = {
    fromDate: startDate,
    toDate: endDate,
  };
  const { showError } = useToast();
  const [filter, setFilter] = useState(initialFilter);
  const [isChanged, setIsChanged] = useState(false);
  const onChangeDateValues = (param: string, value: string) => {
    setIsChanged(true);
    setFilter((prevValues) => {
      const result = {
        ...prevValues,
        [param]: value,
      };
      setBatteryGraphParams(result);
      return result;
    });
  };
  const updateGraph = () => {
    setIsChanged(false);
    fetchData();
    setIsOpen(true);
  };

  const fetchData = async () => {
    if (!isImplemented) return
    const timeLine = new Date(filter.toDate).getTime() - new Date(filter.fromDate).getTime();
    const oneDay = 86400000;
    const maxTimeLine = oneDay * BATT_REP.DATE_RANGE;
    if (timeLine > maxTimeLine) {
      showError(t('date_range_too_large'));
      return;
    }
    dataLoaded(false);
    const filterGraph = { fromDate: filter.fromDate, toDate: filter.toDate, deviceId: deviceId };
    const data = await getBatteryStatusReportProject(filterGraph);
    const graphData = data ? data.data : [];
    const formattedData = useFormattedGraphData(graphData, filter);
    setFormattedData(formattedData);
    if (data) {
      dataLoaded(true);
    }
  };

  return (
    <Row spacing="24px" sx={{ width: '100%' }} justifyContent="flex-start" alignItems="flex-end">
      <BigTitle sx={{ height: '40px' }}>{t('battery_graph_edit_modal')}</BigTitle>
      <BaseDataPicker
        label={`${t('start_date_graph_battery_status_report_label')}`}
        name="fromDate"
        value={filter.fromDate}
        onChange={onChangeDateValues}
        formStyle
        showFuture
      />
      <Box sx={{ mr: '10px', fontWeight: 700, fontSize: "20px", color: "#888" }}>{t('arrow_symbol')}</Box>
      {/* <Icon src={arrow_right} sx={{ py: '7px', transform: isRTL ? 'rotate(180deg)' : 'rotate(0deg)' }} /> */}
      <BaseDataPicker
        label={`${t('end_date_graph_battery_status_report_label')}`}
        name="toDate"
        value={filter.toDate}
        onChange={onChangeDateValues}
        error={null}
        formStyle
        showFuture
      />

      <Row spacing="24px" justifyContent="flex-start" sx={{ paddingTop: '20px' }}>
        <BaseBtn onClick={updateGraph} sx={{ height: '29px' }}>
          {t('graph_battery_status_update')}
        </BaseBtn>
      </Row>
      <Row sx={{ flexGrow: 1 }} />
      {currentController.powerSupCode ? (
        <Box
          sx={{
            display: 'flex',
            columnGap: '20px',
            pr: '80px',
            top: '100px',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Noto Sans Hebrew',
              fontWeight: 600,
            }}
          >
            {t('powersupcode_battery_status_report_grid_table')}:
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Noto Sans Hebrew',
              fontWeight: 600,
            }}
          >
            {t(`PowerSupCode_${currentController.powerSupCode}`)}
          </Typography>
        </Box>
      ) : (
        ''
      )}
      {/*   
    {powerSource && <Typography
      sx={{
        fontFamily: 'Noto Sans Hebrew',
        fontWeight: 600,
      }}
    >
      {t(`${powerSource}`)}
    </Typography>} */}
    </Row>
  );
};

import useAxios from './useAxios';
import { DashboardDetails } from 'shared/models';
import { useAppDispatch, useToast } from 'shared/hooks';
import { useTranslation } from 'react-i18next';
import { setUpdateCurrentController, setDashboardDetails } from 'shared/slices';

export const useDashboardDetails = () => {
  const dispatch = useAppDispatch();
  const {loading, request } = useAxios();
  const { t } = useTranslation();
	interface DashboardDetailsFilter {
		deviceTypes?: string[],
    customerIds?: number[],
    modelIds?: string[],
    customerType?: number,
    cellProvider?: string,
    disabledFlag?:boolean,
    deviceId?:number,
    lastCommDates?: {
        from: string,
        to: string
    },
    installDates?: {
        from: string,
        to: string
    }
	}
  return {
    getDashboardDetails: (data: DashboardDetailsFilter) =>
      request<DashboardDetailsFilter, { data: DashboardDetails[] }>({
        method: 'post',
        showSuccessMessageCode: undefined,
        url: `/dashboard/details`,
				payload: data,
        onDataReceiveCompleted: (dashboardDetails) => {
          dispatch(setDashboardDetails(dashboardDetails?.data));
        },
      }),
    getCustomerDetailsById: (id: number) =>
    request<DashboardDetailsFilter, {data: DashboardDetails[]}>({
      method: 'post',
      showSuccessMessageCode: undefined,
      url: `/dashboard/details`,
      payload: {deviceId :id},
      onDataReceiveCompleted: (dashboardDetails) => {
        return dashboardDetails?.data[0]
        // if(dashboardDetails?.data[0]){
        //   dispatch(setUpdateCurrentController(dashboardDetails?.data[0]));
        // }
       //dispatch(setCurrentController(dashboardDetails[0]));
        
        //updateCurrentCustomer(dashboardDetails?.data)
        // dispatch(setDashboardDetails(dashboardDetails?.data));
      },
    }),
    isLoadingDashboardDetails: loading,
  };
};

import { useTranslation } from 'react-i18next';
import { WeatherStation } from '../WeatherStationModels';
import { getFormattedDateAndTime } from 'shared/lib';
export const useFormatXlsxData = () => {
  const { t } = useTranslation();
  const formatTable = (arr:  WeatherStation[]) => {
    const exportArr = arr.map((item) => {
      return {
        [t('object_id_weather_stations_grid_table')]: item.objectID,
        [t('object_name_weather_stations_grid_table')]: item.objectName,
        [t('hwid_weather_stations_grid_table')]: item.hwid,
        [t('station_type_weather_stations_grid_table')]: t(item.stationType),
				[t('et_max_value_weather_stations_grid_table')]: item.eT_MaxValue,
        [t('last_evaporation_value_weather_stations_grid_table')]: item.lastEvaporationValue,
        [t('last_evaporation_date_weather_stations_grid_table')]: item.lastEvaporationDate,
        [t('customer_id_weather_stations_grid_table')]: item.customerID,
				[t('customer_name_weather_stations_grid_table')]: item.customerName,
        [t('customer_name_ex_weather_stations_grid_table')]: item.customerName_EX,
        [t('customer_id_ex_weather_stations_grid_table')]: item.customerID_EX,

      };
    });
    return exportArr;
  };
  return { formatTable };
};

type AccessLevelItems = {
  accessLevelValue: string;
  accessLevelKey: string;
};

export const accessLevelItems: AccessLevelItems[] = [
  { accessLevelValue: '0', accessLevelKey: 'access_level_no_access' },
  { accessLevelValue: '1', accessLevelKey: 'access_level_operator' },
  { accessLevelValue: '5', accessLevelKey: 'access_level_customer_manager' },
  { accessLevelValue: '10', accessLevelKey: 'access_level_system_manager' },
  { accessLevelValue: '15', accessLevelKey: 'access_level_administrator' },
];

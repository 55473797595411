

import { FC } from 'react';
import { DashboardData } from 'shared/models';

interface CustomizedLabelProps {
  value: number;
  y: number;
  x: number;
	width:number;
	name:string;
	data:DashboardData[]
	selectItem: (name:string)=>void
}

export const CustomizedLabel: FC<CustomizedLabelProps> = (props:CustomizedLabelProps) => {
  const {selectItem, name, width, data, value, x, y } = props;
	const currentItem=data.find((item)=>item.name===name)
	const key=currentItem.key
  return (<g style={{cursor:"pointer"}} onClick={()=>selectItem(key)} transform={`translate(${x+(width/2)},${y-2})`}>
		<text  fontSize='14px' textAnchor={'middle'} fill="black">
    {value}</text></g>
  );
};

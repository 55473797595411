import { ICItem } from 'shared/models';


export const getSearchedCItems = (cItems: ICItem[], searchValue: string) => {
  const searched = cItems.filter((c) => {
    const values = Object.values(c);

    return values.some((v) => v?.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase().trim()));
  });

  return searched;
};
import { Box, useTheme } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

type LayoutComponent = FC<PropsWithChildren> & { Body: FC<PropsWithChildren> };

const LayoutBody: FC<PropsWithChildren> = ({ children }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        height: 'calc(100% - 64px)',
        bgcolor: 'info.light',
        p: '15px 36px 15px 36px',
        overflow: 'auto',
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: theme.palette.primary.dark,
          borderTopLeftRadius: '30px',
          borderBottomLeftRadius: '30px',
        },
        '&::-webkit-scrollbar': {
          width: '11px',
          backgroundColor: theme.palette.info.light,
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: theme.palette.info.light,
          my: '5px',
        },
      }}
    >
      {children}
    </Box>
  );
};

export const Layout: LayoutComponent = ({ children }) => {
  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
      }}
    >
      {children}
    </Box>
  );
};

Layout.Body = LayoutBody;

export const CustomizedAxisXTick = (props) => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={8} fontSize='11px' textAnchor="middle" fill="black">
          {payload.value}
      </text>
    </g>
  );
};

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ServiceCall } from 'shared/models';
import { isTemplateExpression } from 'typescript';
interface Code {
  code: number;
  text: string;
  languageCode?: string;
  userName?: string;
  userID?: number;
}
interface serviceCallsState {
  serviceCalls: ServiceCall[];
  currentServiceCall: ServiceCall | null;
  serviceCallsFaultEquipmentCodes: Code[];
  serviceCallsTechnicianCodes: Code[];
  serviceCallsStatusCodes: Code[];
}
const initialState: serviceCallsState = {
  serviceCalls: [],
  currentServiceCall: null,
  serviceCallsFaultEquipmentCodes: [],
  serviceCallsTechnicianCodes: [],
  serviceCallsStatusCodes: [],
};

const serviceCallsSlice = createSlice({
  name: 'serviceCalls',
  initialState,
  reducers: {
    setServiceCalls: (state, action: PayloadAction<ServiceCall[]>) => {
      state.serviceCalls = action.payload;
    },
    setCurrentServiceCall: (state, action: PayloadAction<ServiceCall>) => {
      state.currentServiceCall = action.payload;
    },
    setCallsFaultEquipmentCodes: (state, action: PayloadAction<Code[]>) => {
      state.serviceCallsFaultEquipmentCodes = action.payload.map((item) => ({
        code: item.code,
        text: item.languageCode ? item.languageCode : '',
      }));
    },
    setCallsTechnicianCodes: (state, action: PayloadAction<Code[]>) => {
      state.serviceCallsTechnicianCodes = action.payload.map((item) => ({
        code: item.userID ? item.userID : 0,
        text: item.userName ? item.userName : '',
      }));
    },
    setCallsStatusCodes: (state, action: PayloadAction<Code[]>) => {
      state.serviceCallsStatusCodes = action.payload.map((item) => ({
        code: item.code,
        text: item.languageCode ? item.languageCode : '',
      }));
    },
  },
});

export const {
  setServiceCalls,
  setCurrentServiceCall,
  setCallsFaultEquipmentCodes,
  setCallsTechnicianCodes,
  setCallsStatusCodes,
} = serviceCallsSlice.actions;

export const serviceCallsReducer = serviceCallsSlice.reducer;

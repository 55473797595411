import React, { FC, useMemo, useState } from 'react';
import { Box, IconButton, Typography, styled } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';
import { Cell, MenuList } from 'shared/ui';
import { useAnchorEl, useAppDispatch, useAppSelector } from 'shared/hooks';
import { delete_icon, duplicate, edit } from 'shared/assets';
import { getFormattedDate } from 'shared/lib';
import { setCurrentServiceCall, toggleEditServiceCall } from 'shared/slices';
import { GridRowParams } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';

const SelectMark = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '5px',
  p: 0,
  height: '100%',
  backgroundColor: theme.palette.primary.dark,
  borderTopRightRadius: '10px',
  borderBottomRightRadius: '10px',
}));

interface visibleColumns {
  width: number;
}
interface CustomGridRowParams extends GridRowParams {
  visibleColumns: visibleColumns[];
}

export const ServiceCallItem: FC<CustomGridRowParams> = (props: CustomGridRowParams) => {
  const {
    serviceCallID,
    createDate,
    currentStatusCode,
    currentStatus,
    customerID,
    customerName,
    closeDate,
    controllerID,
    controllerName,
    openByUserID,
    openByUserName,
    faultEquipmentCode,
    faultEquipment,
    faultDescription,
    notesForTechnician,
    assignedTechnicianID,
    assignedTechnicianName,
    actionDescription,
    takenActionCode,
    takenAction,
    replacedEquipment1Code,
    replacedEquipment1,
    replacedEquipment2Code,
    replacedEquipment2,
  } = props.row;
  const { currentServiceCall } = useAppSelector((st) => st.serviceCalls);
  const { visibleColumns } = props;
  const cellSizes = visibleColumns.map((s) => s.width);
  const { isOpen, anchorEl, onOpenMenu, onCloseMenu } = useAnchorEl();
  const [activeModal, setActiveModal] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const handleOpenEditModal = () => {
    onCloseMenu();
    setActiveModal(!activeModal);
    dispatch(setCurrentServiceCall(props.row));
    dispatch(toggleEditServiceCall());

    // dispatch(toggleEditCustomer());
  };

  const selected = useMemo(() => currentServiceCall?.serviceCallID === serviceCallID, [currentServiceCall]);
  const bgcolor = currentStatus == 'service_status_new_fault' ? '#B5FE32' : selected ? 'info.light' : 'info.main';
  return (
    <>
      <Box
        id={`serviceCall_${serviceCallID}`}
        sx={{
          '&': {
            bgcolor: selected ? '#d0e7ff' : bgcolor,
          },
          '&:hover': {
            bgcolor: selected ? '#c0d7ff' : '#f0f0f0',
          },
        }}
        onDoubleClick={handleOpenEditModal}
      >
        <>
          <Cell cellSize={cellSizes[0]} onClick={handleOpenEditModal} sx={{ position: 'relative' }}>
            {serviceCallID}{selected && <SelectMark />} <IconButton sx={{ height: '33px', opacity: 0 }}></IconButton>
          </Cell>
          <Cell cellSize={cellSizes[1]}>{getFormattedDate(createDate as string)}</Cell>
          <Cell cellSize={cellSizes[2]}>{t(currentStatus)}</Cell>
          <Cell cellSize={cellSizes[3]}>{closeDate ? getFormattedDate(closeDate as string) : ''}</Cell>
          <Cell cellSize={cellSizes[4]}>{customerName}</Cell>
          <Cell cellSize={cellSizes[5]}>{controllerName}</Cell>
          <Cell cellSize={cellSizes[6]}>{openByUserName}</Cell>
          <Cell color={faultEquipment === 'FaultEquepment_complex_repeatable_fault' ? 'red' : 'black'} cellSize={cellSizes[7]}>{t(faultEquipment)}</Cell>
          <Cell cellSize={cellSizes[8]}>{faultDescription}</Cell>
          <Cell cellSize={cellSizes[9]}>{notesForTechnician}</Cell>
          <Cell cellSize={cellSizes[10]}>{assignedTechnicianName}</Cell>
          <Cell cellSize={cellSizes[11]}>{actionDescription}</Cell>
          <Cell cellSize={cellSizes[12]}>{t(takenAction)}</Cell>
        </>
      </Box>
    </>
  );
};

import { FC, useState, useEffect } from 'react';
import localStorageService from 'shared/localStorage';
export const useTabs = (defaultTab) => {
  const initial = defaultTab ? defaultTab : parseInt(localStorageService.activeTabEditController);
  const [activeTab, setActiveTab] = useState<number>(initial ? initial : 2);
  const tabs = [
    {
      text: 'last_irrigation_data_edit_modal',
      value: 1,
    },
    {
      text: 'last_service_call',
      value: 2,
    },
  ];
  const onChangeTab = (value: number) => {
    setActiveTab(value);
    if (!defaultTab) {
      localStorageService.activeTabEditController = value.toString();
    }
  };
  return {
    activeTab,
    onChangeTab,
    tabs,
  };
};

import { getFormattedDateAndTime } from 'shared/lib';
import { COUNT_OF_Y_LINES } from './Constants';
interface UserActionsData {
  commandDescription: string;
  userName: string;
}
const updateValveBasedOnPreviousValue = (
  index: number,
  valves: (string | number | UserActionsData)[][],
  previousValue: (string | number)[]
) => {
  const currentValue = previousValue[index];

  switch (currentValue) {
    case 'start':
    case ' ':
      return { previous: ' ', current: ' ' };

    case 'end':
    case 'generalFaultEnd':
    case 'waterLeakageEnd':
    case 'userActionsEvent':
      return { previous: null, current: null };

    case 'generalFault':
    case 'generalFaultContinue':
      return { previous: 'generalFaultContinue', current: 'generalFaultContinue' };

    case 'waterLeakage':
    case 'waterLeakageContinue':
      return { previous: 'waterLeakageContinue', current: 'waterLeakageContinue' };

    case null:
      return { previous: null, current: null };

    default:
      if (+currentValue < 1 && +currentValue > -1) {
        return { previous: currentValue, current: currentValue };
      }
      return { previous: currentValue, current: currentValue };
  }
};

export const handleValveOpenEvent = (
  valves: (string | number | UserActionsData)[][],
  previousValue: (string | number)[],
  valveIndex: number,
  timestamp: number,
  timestamps: number[]
) => {
  valves.forEach((valve, index) => {
    if (index + 1 !== valveIndex) {
      // інші клапани
      const { previous, current } = updateValveBasedOnPreviousValue(index, valves, previousValue);
      valves[index].push(current);
      previousValue[index] = previous;
    } else {
      // поточний клапан
      if (previousValue[index] === 'start' || previousValue[index] === ' ') {
        valves[index].push(' ');
        previousValue[index] = ' ';
      } else {
        valves[index].push('start');
        previousValue[index] = ' ';
      }
    }
  });

  timestamps.push(timestamp);
};

export const handleValveCloseEvent = (
  valves: (string | number)[][],
  previousValue: (string | number)[],
  valveIndex: number,
  timestamp: number,
  timestamps: number[]
) => {
  valves.forEach((valve, index) => {
    if (index + 1 !== valveIndex) {
      // others valve

      const { previous, current } = updateValveBasedOnPreviousValue(index, valves, previousValue);
      valves[index].push(current);
      previousValue[index] = previous;
    } else {
      // current valve
      valves[index].push('end');
      previousValue[index] = 'end';

      for (let index = 0; index < valves.length; index++) {
        const { previous, current } = updateValveBasedOnPreviousValue(index, valves, previousValue);
        valves[index].push(current);
        previousValue[index] = previous;
      }
      timestamps.push(timestamp);
    }
  });
  timestamps.push(timestamp);
};

export const handleHighFlowRateEvent = (
  valves: (string | number)[][],
  previousValue: (string | number)[],
  valveIndex: number,
  timestamp: number,
  timestamps: number[]
) => {
  valves.forEach((valve, index) => {
    if (index + 1 !== valveIndex) {
      // others valve
      const { previous, current } = updateValveBasedOnPreviousValue(index, valves, previousValue);

      valves[index].push(current);
      previousValue[index] = previous;
    } else {
      // current valve
      valves[index].push('highFlowRate');
      //previousValue[index] = current;

      for (let index = 0; index < valves.length; index++) {
        const { previous, current } = updateValveBasedOnPreviousValue(index, valves, previousValue);
        valves[index].push(current);
        previousValue[index] = previous;
      }
      timestamps.push(timestamp);
    }
  });
  timestamps.push(timestamp);
};
export const handleHighFlowRateEventEnd = (
  valves: (string | number)[][],
  previousValue: (string | number)[],
  valveIndex: number,
  timestamp: number,
  timestamps: number[]
) => {
  valves.forEach((valve, index) => {
    if (index + 1 !== valveIndex) {
      // others valve
      const { previous, current } = updateValveBasedOnPreviousValue(index, valves, previousValue);

      valves[index].push(current);
      previousValue[index] = previous;
    } else {
      // current valve
      valves[index].push('highFlowRateEnd');
      //previousValue[index] = current;

      for (let index = 0; index < valves.length; index++) {
        const { previous, current } = updateValveBasedOnPreviousValue(index, valves, previousValue);
        valves[index].push(current);
        previousValue[index] = previous;
      }
      timestamps.push(timestamp);
    }
  });
  timestamps.push(timestamp);
};
export const handleLowFlowRateEvent = (
  valves: (string | number)[][],
  previousValue: (string | number)[],
  valveIndex: number,
  timestamp: number,
  timestamps: number[]
) => {
  valves.forEach((valve, index) => {
    if (index + 1 !== valveIndex) {
      // others valve
      const { previous, current } = updateValveBasedOnPreviousValue(index, valves, previousValue);

      valves[index].push(current);
      previousValue[index] = previous;
    } else {
      valves[index].push('lowFlowRate');
      for (let index = 0; index < valves.length; index++) {
        const { previous, current } = updateValveBasedOnPreviousValue(index, valves, previousValue);
        valves[index].push(current);
        previousValue[index] = previous;
      }
      timestamps.push(timestamp);
    }
  });
  timestamps.push(timestamp);
};
export const handleLowFlowRateEventEnd = (
  valves: (string | number)[][],
  previousValue: (string | number)[],
  valveIndex: number,
  timestamp: number,
  timestamps: number[]
) => {
  valves.forEach((valve, index) => {
    if (index + 1 !== valveIndex) {
      // others valve
      const { previous, current } = updateValveBasedOnPreviousValue(index, valves, previousValue);

      valves[index].push(current);
      previousValue[index] = previous;
    } else {
      valves[index].push('lowFlowRateEnd');
      for (let index = 0; index < valves.length; index++) {
        const { previous, current } = updateValveBasedOnPreviousValue(index, valves, previousValue);
        valves[index].push(current);
        previousValue[index] = previous;
      }
      timestamps.push(timestamp);
    }
  });
  timestamps.push(timestamp);
};
export const handleMissingPulsesEvent = (
  valves: (string | number)[][],
  previousValue: (string | number)[],
  valveIndex: number,
  timestamp: number,
  timestamps: number[]
) => {
  valves.forEach((valve, index) => {
    if (index + 1 !== valveIndex) {
      // others valve
      const { previous, current } = updateValveBasedOnPreviousValue(index, valves, previousValue);
      valves[index].push(current);
      previousValue[index] = previous;
    } else {
      valves[index].push('missingPulses');
      for (let index = 0; index < valves.length; index++) {
        const { previous, current } = updateValveBasedOnPreviousValue(index, valves, previousValue);
        valves[index].push(current);
        previousValue[index] = previous;
      }
      timestamps.push(timestamp);
    }
  });
  timestamps.push(timestamp);
};
export const handleMissingPulsesEventEnd = (
  valves: (string | number)[][],
  previousValue: (string | number)[],
  valveIndex: number,
  timestamp: number,
  timestamps: number[]
) => {
  valves.forEach((valve, index) => {
    if (index + 1 !== valveIndex) {
      // others valve
      const { previous, current } = updateValveBasedOnPreviousValue(index, valves, previousValue);
      valves[index].push(current);
      previousValue[index] = previous;
    } else {
      valves[index].push('missingPulsesEnd');
      for (let index = 0; index < valves.length; index++) {
        const { previous, current } = updateValveBasedOnPreviousValue(index, valves, previousValue);
        valves[index].push(current);
        previousValue[index] = previous;
      }
      timestamps.push(timestamp);
    }
  });
  timestamps.push(timestamp);
};
export const handleManualValveOpeningEvent = (
  valves: (string | number)[][],
  previousValue: (string | number)[],
  valveIndex: number,
  timestamp: number,
  timestamps: number[]
) => {
  valves.forEach((valve, index) => {
    if (index + 1 !== valveIndex) {
      // others valve
      const { previous, current } = updateValveBasedOnPreviousValue(index, valves, previousValue);
      valves[index].push(current);
      previousValue[index] = previous;
    } else {
      valves[index].push('manualValveOpening');
      for (let index = 0; index < valves.length; index++) {
        const { previous, current } = updateValveBasedOnPreviousValue(index, valves, previousValue);
        valves[index].push(current);
        previousValue[index] = previous;
      }
      timestamps.push(timestamp);
    }
  });
  timestamps.push(timestamp);
};
export const handleManualValveOpeningEventEnd = (
  valves: (string | number)[][],
  previousValue: (string | number)[],
  valveIndex: number,
  timestamp: number,
  timestamps: number[]
) => {
  valves.forEach((valve, index) => {
    if (index + 1 !== valveIndex) {
      // others valve
      const { previous, current } = updateValveBasedOnPreviousValue(index, valves, previousValue);
      valves[index].push(current);
      previousValue[index] = previous;
    } else {
      valves[index].push('manualValveClose');
      for (let index = 0; index < valves.length; index++) {
        const { previous, current } = updateValveBasedOnPreviousValue(index, valves, previousValue);
        valves[index].push(current);
        previousValue[index] = previous;
      }
      timestamps.push(timestamp);
    }
  });
  timestamps.push(timestamp);
};
export const handleCommunicationFault = (
  valves: (string | number)[][],
  previousValue: (string | number)[],
  valveIndex: number,
  timestamp: number,
  timestamps: number[]
) => {
  for (let index = 0; index < COUNT_OF_Y_LINES; index++) {
    if (COUNT_OF_Y_LINES - 1 == index) {
      valves[index].push(0);
      valves[index].push(0);
      valves[index].push(0);
    } else {
      valves[index].push(previousValue[index]);

      valves[index].push('communication fault');
      valves[index].push('communication fault');
    }
  }
  timestamps.push(timestamp);
  timestamps.push(timestamp);
  timestamps.push(timestamp);
};

export const handleCommunicationRestored = (
  valves: (string | number)[][],
  previousValue: (string | number)[],
  valveIndex: number,
  timestamp: number,
  timestamps: number[]
) => {
  for (let index = 0; index < COUNT_OF_Y_LINES; index++) {
    if (COUNT_OF_Y_LINES - 1 == index) {
      valves[index].push(0);
      valves[index].push(0);
      valves[index].push(0);
    } else {
      valves[index].push('communication fault');
      valves[index].push('communication restored');
      valves[index].push(previousValue[index]);
    }
  }
  timestamps.push(timestamp);
  timestamps.push(timestamp);
  timestamps.push(timestamp);
};

export const handleFlowRate = (
  valves: (string | number)[][],
  previousValue: (string | number)[],
  valveIndex: number,
  timestamp: number,
  timestamps: number[],
  dataValue: number
) => {
  for (let index = 0; index < COUNT_OF_Y_LINES - 1; index++) {
    const { current } = updateValveBasedOnPreviousValue(index, valves, previousValue);
    valves[index].push(current);
  }

  valves[COUNT_OF_Y_LINES - 1].push(dataValue / 100);
  previousValue[COUNT_OF_Y_LINES - 1] = dataValue / 100;
  timestamps.push(timestamp);
};

export const handleGeneralFault = (
  valves: (string | number)[][],
  previousValue: (string | number)[],
  valveIndex: number,
  timestamp: number,
  timestamps: number[]
) => {
  for (let index = 0; index < COUNT_OF_Y_LINES; index++) {
    if (index == 16) {
      valves[16].push('generalFault');
      previousValue[16] = 'generalFaultContinue';
    } else if (index == COUNT_OF_Y_LINES - 1) {
      const { current } = updateValveBasedOnPreviousValue(index, valves, previousValue);
      valves[index].push(current);
    } else {
      const { current } = updateValveBasedOnPreviousValue(index, valves, previousValue);
      valves[index].push(current);
    }
  }

  timestamps.push(timestamp);
};

export const handleGeneralFaultEnd = (
  valves: (string | number)[][],
  previousValue: (string | number)[],
  valveIndex: number,
  timestamp: number,
  timestamps: number[]
) => {
  for (let index = 0; index < COUNT_OF_Y_LINES; index++) {
    if (index == 16) {
      valves[16].push('generalFaultEnd');
      valves[16].push(null);
      previousValue[16] = null;
    } else if (index == COUNT_OF_Y_LINES - 1) {
      const { current } = updateValveBasedOnPreviousValue(index, valves, previousValue);
      valves[index].push(current);
      valves[index].push(current);
    } else {
      const { current } = updateValveBasedOnPreviousValue(index, valves, previousValue);
      valves[index].push(current);
      valves[index].push(current);
    }
  }
  timestamps.push(timestamp);
  timestamps.push(timestamp);
};

export const handleWaterLeakage = (
  valves: (string | number)[][],
  previousValue: (string | number)[],
  valveIndex: number,
  timestamp: number,
  timestamps: number[]
) => {
  for (let index = 0; index < COUNT_OF_Y_LINES; index++) {
    if (index == 17) {
      valves[17].push('waterLeakage');
      previousValue[17] = 'waterLeakageContinue';
    } else if (index == COUNT_OF_Y_LINES - 1) {
      const { current } = updateValveBasedOnPreviousValue(index, valves, previousValue);
      valves[index].push(current);
    } else {
      const { current } = updateValveBasedOnPreviousValue(index, valves, previousValue);
      valves[index].push(current);
    }
  }

  timestamps.push(timestamp);
};

export const handleWaterLeakageEnd = (
  valves: (string | number)[][],
  previousValue: (string | number)[],
  valveIndex: number,
  timestamp: number,
  timestamps: number[]
) => {
  for (let index = 0; index < COUNT_OF_Y_LINES; index++) {
    if (index == 17) {
      valves[17].push('waterLeakageEnd');
      valves[17].push(null);
      previousValue[17] = null;
    } else if (index == COUNT_OF_Y_LINES - 1) {
      const { current } = updateValveBasedOnPreviousValue(index, valves, previousValue);
      valves[index].push(current);
      valves[index].push(current);
    } else {
      const { current } = updateValveBasedOnPreviousValue(index, valves, previousValue);
      valves[index].push(current);
      valves[index].push(current);
    }
  }
  timestamps.push(timestamp);
  timestamps.push(timestamp);
};

export const handleUserActionsEvent = (
  valves: (string | number | UserActionsData)[][],
  previousValue: (string | number)[],
  valveIndex: number,
  timestamp: number,
  timestamps: number[],
  userActionsData: UserActionsData
) => {
  for (let index = 0; index < COUNT_OF_Y_LINES; index++) {
    if (index == 18) {
      valves[18].push('userActionsEvent');
      valves[19].push(userActionsData);
      previousValue[18] = 'userActionsEvent';
    } else if (index == COUNT_OF_Y_LINES - 1) {
      const { current } = updateValveBasedOnPreviousValue(index, valves, previousValue);
      valves[index].push(current);
    } else {
      const { current } = updateValveBasedOnPreviousValue(index, valves, previousValue);
      valves[index].push(current);
    }
  }

  timestamps.push(timestamp);
};

import { Box, Tooltip, useTheme } from '@mui/material';
import { t } from 'i18next';
import { FC, PropsWithChildren, useState, useRef, useEffect } from 'react';
import { arrow_down_blue, arrow_in_circle, arrow_in_circle_blue, collapse_arrow, collapse_arrow_black } from 'shared/assets';
import { Icon, Row } from 'shared/ui';
import { Text } from 'shared/ui';

interface Props extends PropsWithChildren {
  title?: string;
  header?: JSX.Element;
  openDefault?: boolean;
  height?: number;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void
}

export const BaseCollapse: FC<Props> = ({ children, title, header, openDefault, height, isOpen, setIsOpen }) => {
  const { palette } = useTheme();
  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };
  const contentRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  useEffect(() => {
    if (height) {
      setContentHeight(isOpen ? height : 0);
      return
    }
    if (contentRef.current) {
      setContentHeight(isOpen ? contentRef.current.scrollHeight : 0);
    }
  }, [isOpen, children]);

  return (
    <Box
      sx={{
        borderRadius: '15px',
        p: '8px 16px',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: 'primary.dark',
        my: '8px',
        //borderColor: 'red',
        boxShadow: isHovered ? '0px 0px 4px #245AA5' : 'auto',
      }}
    >

      <Row alignItems="flex-center" justifyContent="space-between" sx={{ alignItems: 'center' }}>
        {header ? header : <></>}
        <Text
          noWrap
          sx={{
            fontSize: '14px',
            fontWeight: 600,
            textTransform: 'capitalize',
            color: palette.primary.dark,
          }}
        >
          {title}
        </Text>
        <Box sx={{ display: 'block', ml: '20px' }}>
          <Box >
            <Tooltip title={t("show_more_tooltip")} placement="top">
              <Box sx={{ display: 'block', position: 'relative', width: '34px', height: '34px' }}>

                <Icon
                  src={arrow_in_circle_blue}
                  onClick={toggleCollapse}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    cursor: 'pointer',
                    opacity: 0.9,
                    transform: `rotate(${isOpen ? '-90deg' : '90deg'})`,
                  }}
                />
                <Icon
                  src={arrow_in_circle}
                  onClick={toggleCollapse}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    color: palette.primary.dark,
                    cursor: 'pointer',
                    opacity: 0,
                    transform: `rotate(${isOpen ? '-90deg' : '90deg'})`,
                    '&:hover': {
                      opacity: 1
                    },
                  }}

                />
              </Box>
            </Tooltip>
          </Box></Box>
      </Row>
      <Box
        sx={{
          overflow: 'hidden',
          transition: 'height 0.3s ease',
          height: `${contentHeight}px`,
        }}
      >
        <Box ref={contentRef}>
          <Box sx={{ height: '360px', minHeight: '200px', overflowY: 'hidden', overflowX: 'hidden' }}>
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

import { Box, Checkbox, Tooltip } from '@mui/material';
import { BaseExportToExcel, BaseMultiselect, BaseTable, CustomerSelect } from 'components';
import { BaseDataPicker, Filter, SelectWithDelete } from 'features';
import { EditController } from 'features/ControllerManager/components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useBatteryStatusReport, useDashboardDetails } from 'shared/api';
import { action_start, cancel, checked_box, delete_icon, un_checked_box } from 'shared/assets';
import { useAppDispatch, useAppSelector, useExtendedTranslation } from 'shared/hooks';
import { setBatteryStatusFilter, setCurrentBatteryStatusReportProjectID } from 'shared/slices';
import { BaseBtn, BaseInput, BigTitle, Icon, Row } from 'shared/ui';
import TotalRows from 'shared/ui/TotalRows';
import { ExcludeFromReportModal } from 'widgets';
import { PowerProblemsFilter, PowerProblemsItem } from './PowerProblemsReportModels';
import { ChangeExpirationDate } from './components/ChangeExpirationDate';
import { PowerProblemsReportGraph } from './components/PowerProblemsReportGraph';
import {
  DATE_COLUMN_INDEXES,
  TABLE_TITLES,
  TRANSLATE_COLUMNS,
  daysPositivePItems,
  serviceCallsItems,
} from './constants/index';
import { useFilterPowerProblemsReport, useFormatXlsxData, useGetDefaultParams } from './hooks';
import { usePowerProblemReport } from './hooks/api/usePowerProblemReport';
export const ExternalPowerProblemsReportManager = () => {
  const {
    getBatteryStatusExcludeDevice,
    getBatteryStatusExcludeCustomer,
    deleteExcludedController,
    deleteExcludedProject,
    addBatteryStatusExcludeController,
    addBatteryStatusExcludeProject,
  } = useBatteryStatusReport();
  const [selectedPowerProblemID, setSelectedPowerProblemID] = useState<number[]>([]);
  const [selectedPowerProblem, setPowerProblem] = useState<PowerProblemsItem>(null);
  const [isOpenPowerProblemModal, setIsOpenPowerProblemModal] = useState(false);
  const { getPowerProblemsReport, isLoading } = usePowerProblemReport();
  const { formatTable } = useFormatXlsxData();
  const dispatch = useAppDispatch();
  const { excludeControllers, excludeProjects } = useAppSelector((st) => st.batteryStatusReport);
  const { exceptionTypeFilter, onChangeExceptionTypeFilter, exceptionTypeFilterItems } = useFilterPowerProblemsReport();
  const { powerProblemsItems } = useAppSelector((st) => st.powerProblems);
  const [excludeParamID, setExcludeParamID] = useState(0);
  const [controllerName, setControllerName] = useState<string>('');
  const [clientName, setClientName] = useState<string>('');
  const [excludeParamType, setExcludeParamType] = useState<'objectId' | 'projectId'>('objectId');
  const [openExcludeFromReportModal, setOpenExcludeFromReportModal] = useState(false);
  const [advancedParameters, setAdvancedParameters] = useState(false);
  const [hideExceptionTypeTooltip, setHideExceptionTypeTooltip] = useState(false);
  const [currentController, setCurrentController] = useState(null);
  const [hideTooltip, setHideTooltip] = useState(false);

  const { t } = useExtendedTranslation();
  const serviceCallsItemsValues = serviceCallsItems.map((item) => ({
    value: item.value,
    label: `${t(item.label)}`,
  }));
  const [openChangeExpirationDate, setOpenChangeExpirationDate] = useState(false);
  const [isStartWasPressed, setIsStartWasPressed] = useState(false);
  const toggleAdvancedParameters = () => {
    setAdvancedParameters(!advancedParameters);
  };
  const { POW_PROBLEM } = useAppSelector((st) => st.globalSlice);

  useEffect(() => {
    const fetchData = async () => {
      getBatteryStatusExcludeDevice();
      getBatteryStatusExcludeCustomer();
    };
    fetchData();
  }, []);
  const {
    startDate,
    endDate,

    // connectHMin,
    // daysPositiveP,
    exceptionTypes,
    daysInTheReport,
    batteryLowVoltage,
    vinLowVoltage,
    chargingTimeLowMinutes,
    daysPositivePercentage,
    saveToLocalStorage,
  } = useGetDefaultParams();

  const initialFilter = {
    startDate: startDate,
    endDate: endDate,
    serviceCallsFilter: '',
    customerFilter: '',
    daysInTheReport: daysInTheReport,
    exceptionTypes: exceptionTypes,
    batteryLowVoltage,
    vinLowVoltage,
    chargingTimeLowMinutes,
    daysPositivePercentage,
  };
  useEffect(() => {
    if (isOpenPowerProblemModal) {
      updateCurrentController();
    }
  }, [isOpenPowerProblemModal]);
  const { getCustomerDetailsById } = useDashboardDetails();
  const updateCurrentController = async () => {
    const updatetedCurrentControllerData = await getCustomerDetailsById(selectedPowerProblem.objectID);
    const upCurrentController = updatetedCurrentControllerData.data[0];
    setCurrentController(() => ({
      customerId: selectedPowerProblem.projectID,
      controllerName: selectedPowerProblem.objectName,
      customerName: selectedPowerProblem.projectName,
      deviceId: selectedPowerProblem.objectID,
      hwId: selectedPowerProblem.hwid,
      modelId: upCurrentController.modelID,
      objectName: selectedPowerProblem.objectName,
      stationId: null,
      gatewayId: null,
      driverCode: null,
      objStatus: null,
      isObjectDisabled: null,
      objectNotes: upCurrentController.objectNotes,
      installAddress: upCurrentController.installAddress,
      gisHorisontal: null,
      gisVertical: null,
      latitudeDec: upCurrentController.latitude_Dec,
      longitudeDec: upCurrentController.longitude_Dec,
      installDate: upCurrentController.installDate,
      powerConnectDate: null,
      deviceType: upCurrentController.deviceType,
      powerSupCode: upCurrentController.powerSupCode,
    }));
  };

  const [filter, setfilter] = useState<PowerProblemsFilter>(initialFilter);
  useEffect(() => {

    dispatch(setBatteryStatusFilter(filter));
  }, [filter]);
  const onChangeExceptionType = (values: string[]) => {
    setfilter((prev) => ({ ...prev, exceptionTypes: values }));
    onChangeExceptionTypeFilter(values);
  };
  const onChangeDateValues = (param: string, value: string) => {
    setfilter((prevValues) => ({
      ...prevValues,
      [param]: value,
    }));
  };
  const onChangeServiceCallsFilter = (value: string) => {
    setfilter((prevValues) => ({
      ...prevValues,
      serviceCallsFilter: value,
    }));
  };
  const onChangeCustomerFilter = (value: string) => {
    setfilter((prevValues) => ({
      ...prevValues,
      customerFilter: value,
    }));
  };
  const onChangeDaysPositiveP = (value: string) => {
    setfilter((prevValues) => ({
      ...prevValues,
      daysPositivePercentage: value,
    }));
  };
  const onChangeFilterItem = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, name: string) => {
    setfilter((prev) => ({ ...prev, [name]: e.target.value }));
  };
  const generateReport = () => {
    //dispatch(setBatteryStatusItems([]));
    saveToLocalStorage(filter);
    setIsStartWasPressed(true);
    getPowerProblemsReport(filter);
  };
  const onDeleteExcludedController = async (value: string) => {
    await deleteExcludedController(value);
    await getBatteryStatusExcludeDevice();
    await getPowerProblemsReport(filter);
  };
  const onDeleteExcludedProject = async (value: string) => {
    await deleteExcludedProject(value);
    await getBatteryStatusExcludeCustomer();
    await getPowerProblemsReport(filter);
  };
  const filteredByCellsFilter = useMemo(
    () => {
      return powerProblemsItems.filter((c) => {
        if (filter.serviceCallsFilter == 'Hide') return !c.lastOpenServiceCallDate;
        if (filter.serviceCallsFilter == 'Show') return c.lastOpenServiceCallDate;
        return true;
      });
    }, [filter, isLoading]
  );


  const filteredItems = !exceptionTypeFilter.length ? filteredByCellsFilter : filteredByCellsFilter.filter((c) => {
    if (exceptionTypeFilter.some((item) => c[item] > 0)) {
      return filter.customerFilter ? filter.customerFilter === c.projectID.toString() : true;
    }
  });

  const changedServiceCalls = () => {
    getPowerProblemsReport(filter);
  };

  const excludeController = (item: PowerProblemsItem) => {
    const objectId = item.objectID;
    setExcludeParamType('objectId');
    setExcludeParamID(objectId);
    setControllerName(item.objectName);
    setOpenExcludeFromReportModal(true);
  };
  const excludeProject = (item: PowerProblemsItem) => {
    const projectId = item.projectID;
    setExcludeParamType('projectId');
    setExcludeParamID(projectId);
    setClientName(item.projectName);
    setOpenExcludeFromReportModal(true);
  };
  const closeExcludeFromReportModal = () => {
    setOpenExcludeFromReportModal(false);
    setClientName('');
    setControllerName('');
  };
  const excludeFromReport = async (upToDate: string) => {
    closeExcludeFromReportModal();
    if (excludeParamType === 'objectId') {
      await addBatteryStatusExcludeController(excludeParamID, upToDate);
      await getBatteryStatusExcludeDevice();
    }
    if (excludeParamType === 'projectId') {
      await addBatteryStatusExcludeProject(excludeParamID, upToDate);
      await getBatteryStatusExcludeCustomer();
    }

    // addBatteryStatusExcludeController,
    // addBatteryStatusExcludeProject,

    await getPowerProblemsReport(filter);
  };
  const expirationDateClick = () => {
    setOpenChangeExpirationDate(true);
  };
  const handleOpenPowerProblemsEditModal = (selected: PowerProblemsItem) => {
    setPowerProblem(selected);
    dispatch(setCurrentBatteryStatusReportProjectID(selected.objectID));
    setIsOpenPowerProblemModal(true);
  };
  const rowSelectionModelChange = useCallback((rows) => {
    setSelectedPowerProblemID(rows);
  }, []);
  const closeChangeExpirationDateModal = () => {
    setOpenChangeExpirationDate(false);
  };
  const scrollToSelectedRow = () => { };

  const closePowerProblemModal = () => {
    setIsOpenPowerProblemModal(false);
  };
  const onOpenedExceptionTypeMenu = (isOpen: boolean) => {
    if (isOpen) {
      setHideExceptionTypeTooltip(isOpen);
      return;
    }
    setTimeout(() => {
      setHideExceptionTypeTooltip(isOpen);
    }, 300);
  };
  const updateFilter = () => {
    setHideTooltip(true);
    setTimeout(() => {
      setHideTooltip(false);
    }, 0);

  }
  const actions = useMemo(
    () => [
      { icon: delete_icon, title: `${t('exclude_controller_table_modal')}`, onClick: excludeController },
      { icon: delete_icon, title: `${t('exclude_project_table_modal')}`, onClick: excludeProject },
      { icon: cancel, title: `${t('cancel_table_modal')}`, onClick: () => { } },
    ],
    []
  );

  const BaseExportToExcelComponent = useMemo(
    () => (<BaseExportToExcel
      tableData={filteredItems}
      sheetName="Power Problems Report"
      fileName="PowerProblemsReportItems.xlsx"
      buttonText={t('export_power_problems_report_items_page_button')}
      formattedTable={formatTable(filteredItems)}
      tableTitles={TABLE_TITLES}
      dateColumnIndexes={DATE_COLUMN_INDEXES}
      headerItemsPrefix={'_power_problem_grid_table'}
    />)
    ,
    [filteredItems]
  );
  return (
    <>
      <Row sx={{ mb: '12px' }}>
        <BigTitle sx={{ mr: '25px' }}>{t('external_power_problems_report')}</BigTitle>
      </Row>

      <Row sx={{ mt: '6px' }} flexWrap="wrap" rowGap="5px" alignItems="flex-end">
        <BigTitle sx={{ fontSize: '24px', mr: '20px' }}>{t('battery_status_report_start')}</BigTitle>
        <BaseDataPicker
          label={`${t('start_date_filter_battery_status_report_label')}`}
          name="startDate"
          value={filter.startDate}
          onChange={onChangeDateValues}
        />
        <BigTitle sx={{ fontSize: '24px', ml: '20px', mr: '20px' }}>{t('battery_status_report_end')}</BigTitle>
        <BaseDataPicker
          label={`${t('end_date_filter_battery_status_report_label')}`}
          name="endDate"
          value={filter.endDate}
          onChange={onChangeDateValues}
          error={null}
        />
        <Checkbox
          sx={{ ml: '20px' }}
          size="small"
          checked={advancedParameters}
          onChange={toggleAdvancedParameters}
          icon={<Icon src={un_checked_box} />}
          checkedIcon={<Icon src={checked_box} />}
        ></Checkbox>

        <Tooltip
          disableHoverListener={!excludeControllers.length && !excludeProjects.length}
          title={`${t('excluded_controllers_tooltip')} ${excludeControllers.length}
               ${t('excluded_projects_tooltip')} ${excludeProjects.length}`}
          placement="bottom"
        >
          <Box sx={{ mb: '5px' }}>
            {t('advanced_parameters_filter_battery_status_report')}
            {excludeControllers.length || excludeProjects.length ? <span>*</span> : <></>}
          </Box>
        </Tooltip>
        <Box sx={{ p: '5px', cursor: 'pointer' }}></Box>
        {!advancedParameters && (
          <>
            <BaseBtn onClick={generateReport} sx={{ mt: '16px', height: '29px', mr: '20px' }}>
              {t('battery_status_action_start')}
              <Icon sx={{ ml: '6px' }} src={action_start} />
            </BaseBtn>
            <Row sx={{ flexGrow: 1 }} />
            <Box sx={{ display: 'flex', marginLeft: 'auto', alignItems: 'flex-end' }}>
              {!hideTooltip && <Tooltip
                disableHoverListener={hideExceptionTypeTooltip || !exceptionTypeFilter.length}
                title={`${exceptionTypeFilter.map((item) => ` ${t(`${item.toUpperCase()}_exception_type_item`)}`)} `}
                placement="bottom"
              >
                <Box sx={{ mb: '0px', width: '260px', maxWidth: '260px' }}>
                  <BaseMultiselect
                    title={`${t('exception_type_label')}`}
                    values={exceptionTypeFilter}
                    onChange={onChangeExceptionType}
                    onOpenedMenu={onOpenedExceptionTypeMenu}
                    items={exceptionTypeFilterItems}
                    hideAllButton
                    hideValue
                    applyFilter={updateFilter}
                  />
                </Box>
              </Tooltip>}
              <CustomerSelect
                title={`${t('customer_label')}`}
                value={filter.customerFilter}
                onChange={onChangeCustomerFilter}
                hideAllButton
                showHomeButton
                showOnlyResetButton
                withSearch
                sx={{ width: '260px' }}
              />
              <Filter
                title={`${t('service_calls_label')}`}
                value={filter.serviceCallsFilter}
                onChange={onChangeServiceCallsFilter}
                items={serviceCallsItemsValues}
                messageEmptyFilter="disconnect_display_all"
                sx={{ width: '260px' }}
                placeholder={`${t('filter_service_calls_battery_status_page_placeholder')}`}
                hideResetButton
              />
              <TotalRows count={filteredItems.length} />
              {BaseExportToExcelComponent}
            </Box>
          </>
        )}
      </Row>
      {advancedParameters && (
        <Row sx={{ mt: '6px' }} flexWrap="wrap" rowGap="5px" alignItems="flex-end">
          <BigTitle sx={{ fontSize: '14px', mr: '20px', fontWeight: '600' }}>
            {t('battery_status_report_advanced')}
          </BigTitle>
          <SelectWithDelete
            title={`${t('excluded_controllers_filter')}`}
            value={filter.daysPositiveP}
            onChange={onDeleteExcludedController}
            onExpirationDateClick={expirationDateClick}
            items={excludeControllers}
            sx={{ width: '260px' }}
            placeholder={`${t('filter_excluded_controllers_search_battery_status_page_placeholder')}`}
            tooltipText={`${t('controller_to_report_hint')}`}
          />
          <SelectWithDelete
            title={`${t('excluded_projects_filter')}`}
            value={filter.daysPositiveP}
            onChange={onDeleteExcludedProject}
            onExpirationDateClick={expirationDateClick}
            items={excludeProjects}
            sx={{ width: '260px' }}
            placeholder={`${t('filter_excluded_projects_search_battery_status_page_placeholder')}`}
            tooltipText={`${t('restore_project_hint')}`}
          />
          <Row alignItems="flex-start" justifyContent="space-between">
            <BaseInput
              label={`${t('days_in_the_report_label')}`}
              name="daysInTheReport"
              value={filter.daysInTheReport}
              onChange={(e) => onChangeFilterItem(e, 'daysInTheReport')}
              error={false}
              helperText={''}
              type="number"
              small
              compact
              smallLabel
              marginRight
            />
            <BaseInput
              label={`${t('low_battery_voltage')}`}
              name="lowBattery"
              value={filter.batteryLowVoltage}
              onChange={(e) => onChangeFilterItem(e, 'batteryLowVoltage')}
              error={false}
              helperText={''}
              type="number"
              small
              compact
              smallLabel
              marginRight
              valueForNumberChanging={0.1}
            />
            <BaseInput
              label={`${t('low_input_voltage')}`}
              name="lowInput"
              value={filter.vinLowVoltage}
              onChange={(e) => onChangeFilterItem(e, 'vinLowVoltage')}
              error={false}
              helperText={''}
              type="number"
              small
              compact
              smallLabel
              marginRight
              valueForNumberChanging={0.1}
            />
            <BaseInput
              label={`${t('insufficient_battery_charging_time')}`}
              name="insufficientBatteryChargingTime"
              value={filter.chargingTimeLowMinutes}
              onChange={(e) => onChangeFilterItem(e, 'chargingTimeLowMinutes')}
              error={false}
              helperText={''}
              type="number"
              small
              compact
              smallLabel
              marginRight
            />
            <Filter
              title={`${t('percentage_of_days_with_problems')}`}
              value={filter.daysPositivePercentage}
              onChange={onChangeDaysPositiveP}
              items={daysPositivePItems}
              hideAllButton
              messageEmptyFilter="disconnect_display_all"
              sx={{ width: '260px', mt: '8px' }}
              placeholder={`${t('filter_search_battery_status_page_placeholder')}`}
              hideResetButton
            />
          </Row>
          <BaseBtn onClick={generateReport} sx={{ mb: '0px', height: '29px', mr: '20px' }}>
            {t('battery_status_action_start')}
            <Icon sx={{ ml: '6px' }} src={action_start} />
          </BaseBtn>

          <Row sx={{ flexGrow: 1 }} />
          <Box sx={{ display: 'flex', marginLeft: 'auto', alignItems: 'flex-end' }}>
            {!hideTooltip && <Tooltip
              disableHoverListener={hideExceptionTypeTooltip || !exceptionTypeFilter.length}
              title={`${exceptionTypeFilter.map((item) => ` ${t(`${item.toUpperCase()}_exception_type_item`)}`)} `}
              placement="bottom"
              sx={{ backgroundColor: 'red' }}
            >
              <Box sx={{ mb: '0px', width: '260px', maxWidth: '260px' }}>
                <BaseMultiselect
                  title={`${t('exception_type_label')}`}
                  values={exceptionTypeFilter}
                  onChange={onChangeExceptionType}
                  onOpenedMenu={onOpenedExceptionTypeMenu}
                  items={exceptionTypeFilterItems}
                  hideAllButton
                  hideValue
                  applyFilter={updateFilter}

                />
              </Box>
            </Tooltip>}
            <CustomerSelect
              title={`${t('customer_label')}`}
              value={filter.customerFilter}
              onChange={onChangeCustomerFilter}
              hideAllButton
              showHomeButton
              showOnlyResetButton
              withSearch
              sx={{ width: '260px' }}
            />
            <Filter
              title={`${t('service_calls_label')}`}
              value={filter.serviceCallsFilter}
              onChange={onChangeServiceCallsFilter}
              items={serviceCallsItemsValues}
              messageEmptyFilter="disconnect_display_all"
              sx={{ width: '260px' }}
              placeholder={`${t('filter_service_calls_battery_status_page_placeholder')}`}
              hideResetButton
            />
            <TotalRows count={filteredItems.length} />

            {BaseExportToExcelComponent}
          </Box>
        </Row>
      )}
      {isStartWasPressed && (
        <BaseTable
          tableTitles={TABLE_TITLES}
          dateColumnIndexes={DATE_COLUMN_INDEXES}
          tableItems={filteredItems}
          loadedItems={!isLoading}
          selectedRowID={selectedPowerProblem?.objectID}
          idField={'objectID'}
          noItemsText={t('no_power_problem_grid_table')}
          headerItemsPrefix={'_power_problem_grid_table'}
          linkIndex={[6]}
          actions={actions}
          cellClick={handleOpenPowerProblemsEditModal}
          onRowSelectionModelChange={rowSelectionModelChange}
          translateColumns={TRANSLATE_COLUMNS}
        />
      )}
      <ExcludeFromReportModal
        openModal={openExcludeFromReportModal}
        controllerName={controllerName}
        clientName={clientName}
        onCloseModal={closeExcludeFromReportModal}
        onSubmit={excludeFromReport}
      />
      <ChangeExpirationDate openModal={openChangeExpirationDate} handleCloseModal={closeChangeExpirationDateModal} />
      {POW_PROBLEM.IS_USE_UNIVERSAL_CONTROLLER_POPUP ? (
        <EditController
          currentController={currentController}
          openControllerEditModal={isOpenPowerProblemModal}
          onCloseModal={() => {
            scrollToSelectedRow();
            closePowerProblemModal();
            setCurrentController(null);
          }}
          reportEndDate={filter.endDate}
          reportStartDate={filter.startDate}
          faultEquipmentCode={30}
          updateReport={changedServiceCalls}
          showRegistersButton={true}
          defaultTab={2}
          defaultCollapse={["batteryGraph"]}
        />
      ) : (
        <PowerProblemsReportGraph
          currentPowerProblem={selectedPowerProblem}
          openModal={isOpenPowerProblemModal}
          onCloseModal={closePowerProblemModal}
          changedServiceCalls={changedServiceCalls}
          closedModal={scrollToSelectedRow}
        />
      )}
    </>
  );
};

import { Box, IconButton, MenuItem, Stack } from '@mui/material';
import { BaseBtn, BigTitle, BaseInput, Modal, Row, Select } from 'shared/ui';
import { FC, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { close_popup } from 'shared/assets';
import { useAppDispatch, useAppSelector, useToast, useAnchorEl, useSimDateErrors } from 'shared/hooks';
import { toggleEditSim } from 'shared/slices';
import { useSimManagement } from 'shared/api';
import { Sim } from 'shared/models';
import { getFormattedDateAndTime, getFormattedDate } from 'shared/lib';
import { getDeepTrimSimValues, isHebrewFont } from 'shared/lib';
import { replaceSpacesWithUnderscores } from 'shared/lib/replaceSpacesWithUnderscores';
import { useEditSimChanges } from './hooks';
import { DatePicker } from 'features';
import { Confirm } from 'shared/ui';
const initialValues = {
  id: 0,
  simNumber: '',
  phoneNumber: '',
  simProvider: null,
  statusCode: '',
  lastDeviceId: null,
  deviceName: '',
  customerName: null,
  customerId: null,
  activationDate: null,
  deActivationDate: null,
  lastCommunicationDate: null,
  lastUpdateDate: null,
  notes: '',
};

const initialErrors = {
  simNumber: '',
  phoneNumber: '',
  activationDate: '',
  deActivationDate: '',
  notes: '',
};
export const EditSim: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { showError } = useToast();
  const { currentSim, sims, simStatuses, simProviders } = useAppSelector((st) => st.simManagement);
  const { openEditSim } = useAppSelector((st) => st.modals);
  const { isOpen, anchorEl, onOpenMenu, onCloseMenu } = useAnchorEl();
  const { isLoading, editSim } = useSimManagement();
  const [isChanged, setIsChanged] = useState(false);
  const [values, setValues] = useState<Sim>(initialValues);
  const [errors, setErrors] = useState(initialErrors);
  const [hasErrors, setHasErrors] = useState(false);
  const closeModal = () => dispatch(toggleEditSim());
  useEffect(() => {
    if (currentSim) {
      setValues(currentSim);
    }
  }, [currentSim, openEditSim]);
  useEffect(() => {
    if (!openEditSim) {
      resetValues();
      resetErrors();
      setIsChanged(false);
    }
  }, [openEditSim]);

  useEffect(() => {
    if (isChanged) {
      validateForm();
    }
  }, [values]);

  useEffect(() => {
    setHasErrors(Object.values(errors).every((value) => value === ''));
  }, [errors]);

  const resetErrors = () => {
    setErrors(initialErrors);
  };
  const resetValues = () => {
    setValues(initialValues);
  };
  const onChangeValues = (event: any, propertyName: string) => {
    const newValue = event.target.value;
    setValues((prevValues) => ({
      ...prevValues,
      [propertyName]: newValue,
    }));
    setIsChanged(true);
  };
  const onChangeSelectValues = (event: any) => {
    const newValue = event.target.value;
    const property = event.target.name;
    setValues((prevValues) => ({
      ...prevValues,
      [property]: newValue,
    }));
    setIsChanged(true);
  };
  const onChangeDateValues = (name: string, property: string) => {
    setValues((prevValues) => ({
      ...prevValues,
      [name]: property,
    }));
    setIsChanged(true);
  };
  const checkSimNumber = () => {
    const { simNumber } = values;
    if (!simNumber) {
      setErrors((prevValues) => ({
        ...prevValues,
        ['simNumber']: 'Please enter sim Number ',
      }));
      return false;
    }
    if (simNumber.length > 50) {
      setErrors((prevValues) => ({
        ...prevValues,
        ['simNumber']: 'Max length 50 characters',
      }));
      return false;
    }

    setErrors((prevValues) => ({
      ...prevValues,
      ['customerName']: '',
    }));
    return true;
  };

  const checkPhoneNumber = () => {
    const { phoneNumber } = values;
    if (!phoneNumber) {
      setErrors((prevValues) => ({
        ...prevValues,
        ['phoneNumber']: 'Please enter phone Number',
      }));
      return false;
    }
    if (phoneNumber.length > 50) {
      setErrors((prevValues) => ({
        ...prevValues,
        ['phoneNumber']: 'Max length 50 characters',
      }));
      return false;
    }
    setErrors((prevValues) => ({
      ...prevValues,
      ['phoneNumber']: '',
    }));
    return true;
  };
  const checkSimNotes = () => {
    const { notes } = values;
    if (notes?.length > 150) {
      setErrors((prevValues) => ({
        ...prevValues,
        ['notes']: 'Max length 150 characters',
      }));
      return false;
    }
    setErrors((prevValues) => ({
      ...prevValues,
      ['notes']: '',
    }));
    return true;
  };
  const validateForm = () => {
    checkSimNumber();
    checkPhoneNumber();
    checkSimNotes();
  };
  const onSubmit = () => {
    if (hasErrors) {
      handleSave();
    }
  };
  const { boxRef } = useEditSimChanges();
  const { activationDateError, setActivationDateError, deActivationDateError, setDeActivationDateError } =
    useSimDateErrors();
  const handleCloseEditModal = (e: React.MouseEvent<HTMLElement>) => {
    if (isLoading) return;
    // setCreatedDateError(null);
    // setStartDateError(null);

    isChanged ? onOpenMenu(e) : closeModal();
  };

  const onClickAway = () => {
    if (!anchorEl) {
      (boxRef.current as HTMLDivElement)?.click();
    }
  };
  const handleSave = () => {
    if (sims) {
      const nameIsExist = sims.find(
        (c) =>
          c.simNumber.toLowerCase().trim() === values.simNumber.toLowerCase().trim() &&
          currentSim?.simNumber.toLowerCase().trim() !== values.simNumber.toLowerCase().trim()
      );
      if (nameIsExist) {
        showError(t('simNumber_is_already_exist_message'));
        setErrors((prevValues) => ({
          ...prevValues,
          ['simNumber']: t('simNumber_is_already_exist_validation_text'),
        }));
        return;
      }
    }
    const trimedValues = getDeepTrimSimValues(values);

    editSim(trimedValues);
  };
  const closeButtonRef = useRef<HTMLButtonElement | null>(null);
  const handleModalClose = () => {
    if (closeButtonRef.current) {
      closeButtonRef.current.click();
    }
  };
  return (
    <Modal open={openEditSim} onClose={handleModalClose}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <BigTitle>{t('edit_sim_modal')}</BigTitle>

        <IconButton ref={closeButtonRef} onClick={handleCloseEditModal}>
          <img src={close_popup} />
        </IconButton>
      </Stack>

      {values && (
        <Box component="form" autoComplete="off">
          <Row spacing="20px" alignItems="flex-start" justifyContent="space-between">
            <Box width={'30%'}>

              <BaseInput
                label={`${t('sim_sim_number_edit_modal')} *`}
                name="simNumber"
                error={!!errors.simNumber}
                helperText={replaceSpacesWithUnderscores(errors.simNumber)}
                value={values?.simNumber ?? ''}
                onChange={(event) => onChangeValues(event, 'simNumber')}
              />
              <BaseInput
                label={`${t('sim_phone_number_edit_modal')} *`}
                name="phoneNumber"
                error={!!errors.phoneNumber}
                helperText={replaceSpacesWithUnderscores(errors.phoneNumber)}
                value={values?.phoneNumber ?? ''}
                onChange={(event) => onChangeValues(event, 'phoneNumber')}
              />
              <Select
                label={`${t('sim_provider_label_edit_modal')} *`}
                name="simProvider"
                value={values.simProvider ? values.simProvider : ''}
                onChange={onChangeSelectValues}
                displayEmpty
                sx={{
                  fontFamily: 'Noto Sans Hebrew !important',
                }}
              >
                {simProviders.map((a) => (
                  <MenuItem key={a.name ? a.name : ''} value={a.name ? a.name : ''}>
                    {a.name ? a.name : 'not select'}
                  </MenuItem>
                ))}
              </Select>
              <Select
                label={`${t('sim_statuses_label_edit_modal')} *`}
                name="statusCode"
                value={values?.statusCode}
                onChange={onChangeSelectValues}
                displayEmpty
                sx={{
                  fontFamily: 'Noto Sans Hebrew !important',
                }}
              >
                {simStatuses.map((a) => (
                  <MenuItem key={a.name} value={a.name}>
                    {a.name ? t(a.name) : 'not select'}
                  </MenuItem>
                ))}
              </Select>
              <BaseInput
                label={`${t('sim_notes')} *`}
                name="notes"
                error={!!errors.notes}
                helperText={replaceSpacesWithUnderscores(errors.notes)}
                value={values?.notes ?? ''}
                onChange={(event) => onChangeValues(event, 'notes')}
              />
            </Box>
            {/* lastDeviceId: 0, customerName: '', customerId: 0, */}
            <Box width={'30%'}>
              <BaseInput
                label={`${t('sim_last_device_id')} *`}
                name="lastDeviceId"
                value={values?.lastDeviceId ?? ''}
                onChange={(event) => onChangeValues(event, 'lastDeviceId')}
                helperText={''}
                disabled
              />
              <BaseInput
                label={`${t('sim_customer_name')} *`}
                name="customerName"
                value={values?.customerName ?? ''}
                onChange={(event) => onChangeValues(event, 'customerName')}
                helperText={''}
                disabled
              />
              <BaseInput
                label={`${t('sim_customer_id')} *`}
                name="customerId"
                value={values?.customerId ?? ''}
                onChange={(event) => onChangeValues(event, 'customerId')}
                helperText={''}
                disabled
              />
              <DatePicker
                label={`${t('sim_activation_date_edit_modal')}`}
                name="activationDate"
                value={values.activationDate}
                onChange={onChangeDateValues}
                error={activationDateError}
                setError={setActivationDateError}
                hideError={!!values.activationDate}
              />
            </Box>
            <Box width={'30%'}>
              <BaseInput
                label={`${t('device_name_edit_modal')}`}
                name="deviceName"
                error={false}
                helperText={""}
                value={values?.deviceName ?? ''}
                onChange={(event) => { }}
                disabled
              />
              <DatePicker
                label={`${t('sim_de_activation_date_edit_modal')}`}
                name="deActivationDate"
                value={values.deActivationDate}
                onChange={onChangeDateValues}
                hideError
              />
              <BaseInput
                label={`${t('sim_last_communication_date')} *`}
                name="lastCommunicationDate"
                value={values?.lastCommunicationDate ? getFormattedDate(values.lastCommunicationDate) : ''}
                onChange={(event) => onChangeValues(event, 'lastCommunicationDate')}
                helperText={''}
                disabled
              />
              <BaseInput
                label={`${t('sim_last_update_date')} *`}
                name="lastUpdateDate"
                value={values?.lastUpdateDate ? getFormattedDateAndTime(values.lastUpdateDate) : ''}
                onChange={(event) => onChangeValues(event, 'lastUpdateDate')}
                helperText={''}
                disabled
              />

            </Box>
          </Row>
          <Row justifyContent="flex-end" mt={'35px'}>
            <Row width={300}>
              <BaseBtn onClick={handleCloseEditModal} btnColor="info.main" fullWidth>
                {t('cancel_sim_edit_modal')}
              </BaseBtn>
              <Box ref={boxRef} onClick={handleCloseEditModal} sx={{ height: '40px', width: '25px' }} />
              <BaseBtn
                onClick={onSubmit}
                disabled={!isChanged || !hasErrors}
                loading={isLoading}
                btnColor="primary.dark"
                fullWidth
              >
                {t('save_sim_edit_modal')}
              </BaseBtn>
            </Row>
          </Row>
        </Box>
      )}
      <Confirm
        open={isOpen}
        anchorEl={anchorEl}
        onClose={onCloseMenu}
        confirm={closeModal}
        buttonTexts={[t('cancel_edit_edit_modal'), t('back_to_edit_edit_modal')]}
      />
    </Modal>
  );
};

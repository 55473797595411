import { Code } from '@mui/icons-material';
import { Box, FormControlLabel, IconButton, Radio, Stack, Typography } from '@mui/material';
import { User } from 'features/UserManager/UserModels';
import { useReceiveResetLink } from 'features/UserManager/hooks/api';

import { t } from 'i18next';
import { FC, useEffect, useState } from 'react';
import { close_popup, copy_icon, email_icon, export_icon, wa_icon } from 'shared/assets';
import { useAppSelector, useToast } from 'shared/hooks';
import localStorageService from 'shared/localStorage';
import { BaseBtn, BaseInput, BigTitle, Icon, Modal, Row } from 'shared/ui';

interface ModalState {
  isOpenModal: boolean
  currentUser: User
  closeModal: () => void
}

export const UserResetPasswordModal: FC<ModalState> = (props: ModalState) => {
  const { CUSTOMERS } = useAppSelector((st) => st.globalSlice);
  const DEFAULT_COUNTRY_CODE = CUSTOMERS.DEFAULT_COUNTRY_CODE;
  const { isOpenModal, closeModal, currentUser } = props;
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [isMessageSent, setIsMessageSent] = useState(false);
  const [isMessageSentSuccess, setIsMessageSentSuccess] = useState(false);
  const [link, setLink] = useState("");
  const { showError, showSuccess } = useToast();
  const [selectedMethod, setSelectedMethod] = useState('');
  const { isLoading, receiveResetLink } = useReceiveResetLink();
  const { currentLang } = localStorageService;
  const handleCloseEditModal = () => {
    closeModal();
    setIsValidEmail(true);
    setIsValidPhone(true);
    setIsMessageSent(false)
    setSelectedMethod('');
    setIsMessageSentSuccess(false)
  }

  const removeLeadingZero = (str) => {
    if (str.startsWith('0')) {
      return str.slice(1);
    }
    return str;
  };


  const addCountryCode = (phone: string) => {
    let newPhone = '';
    const currentPhone = phone.replace(/[+-]/g, '');
    currentPhone.startsWith(DEFAULT_COUNTRY_CODE) ? (newPhone = currentPhone) : (newPhone = DEFAULT_COUNTRY_CODE + removeLeadingZero(currentPhone));
    return newPhone;
  };
  const emailMessageBody = t('email_message_body_password_reset').replace(/\n/g, "<br/>");
  const getEmailText = () => {
    return `<div dir="${currentLang === 'he' ? 'rtl' : 'ltr'}">
              <p>${emailMessageBody}</p>
              <p> ${t('email_message_footer_password_reset')}</p>
            </div>`
  }
  const handleSave = async () => {


    if (selectedMethod === 'email') {
      let resetPasswordByEmail = localStorageService.resetPasswordByEmail;
      if (!resetPasswordByEmail) {
        resetPasswordByEmail = []
      }
      resetPasswordByEmail.push({ userID: currentUser.userID, timestamp: Date.now() })
      localStorageService.resetPasswordByEmail = resetPasswordByEmail;

    }
    if (selectedMethod === 'phone') {
      let resetPasswordByPhone = localStorageService.resetPasswordByPhone;
      if (!resetPasswordByPhone) {
        resetPasswordByPhone = []
      }
      resetPasswordByPhone.push({ userID: currentUser.userID, timestamp: Date.now() })
      localStorageService.resetPasswordByPhone = resetPasswordByPhone;
    }


    const data = {
      selectedContactMethod: selectedMethod,
      email: currentUser.eMailAddress,
      userName: currentUser.userName,
      userId: currentUser.userID,
      lang: currentLang,
      phone: addCountryCode(currentUser.phoneNumber),
      customerId: currentUser.customerID,
      resetPasswordMessage: getEmailText(),
      emailSubject: t('email_subject_password_reset')
    }

    const result = await receiveResetLink(data)
    if (result) {
      setIsMessageSentSuccess(true);
      setLink(result)
    }
  }
  function validatePhoneNumber(phoneNumber: string) {
    const phoneRegex = /^[+]?[\d]{0,3}\W?[(]?[\d]{3}[)]?[-\s.]?[\d]{3}[-\s.]?[\d]{4,6}$/im;
    return phoneRegex.test(phoneNumber);
  }
  const validateMail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  useEffect(() => {
    if (isOpenModal) {
      const isValidEmail = currentUser.eMailAddress ? validateMail(currentUser.eMailAddress) : false
      const isValidPhone = currentUser.phoneNumber ? validatePhoneNumber(currentUser.phoneNumber) : false
      setIsValidEmail(isValidEmail)
      setIsValidPhone(isValidPhone)
    }
  }, [isOpenModal])



  useEffect(() => {
    setIsMessageSentSuccess(false)
  }, [selectedMethod])




  const copyToClipboard = () => {
    navigator.clipboard.writeText(link)
      .then(() => {
        showSuccess(t('link_copied_password_reset_link_to'))
      })
      .catch((err) => {
        showError(t('link_did_not_copied_password_reset_link_to'))
      });

  }
  return (
    <Modal open={isOpenModal} onClose={handleCloseEditModal}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <BigTitle><span>{t('sending_a_password_reset_link_to')}</span> <span style={{ fontWeight: 900 }}>{currentUser.userName}</span></BigTitle>

        <IconButton onClick={handleCloseEditModal}>
          <img src={close_popup} />
        </IconButton>
      </Stack>

      <Box mt={'12px'} >
        <Box ><Typography sx={{
          whiteSpace: "normal", maxWidth: "100%", display: "block", wordBreak: "normal",
          overflowWrap: "break-word"
        }}>{t('info_text_reset_password_modal')}</Typography></Box>
        <Box mt={'24px'}><BigTitle >{t('choose_how_you_want_to_receive_the_reset_link')}</BigTitle></Box>
        <Box mt={'12px'} height={"200px"} >

          <Row onClick={() => isValidEmail ? setSelectedMethod('email') : {}} sx={{ cursor: 'pointer', justifyContent: "space-between" }}>
            <Row >
              <Icon src={email_icon} sx={{ ml: '7px' }} />
              <Radio disabled={!isValidEmail} checked={selectedMethod === 'email'} size="small" />
              {t('e_mail_address_reset_password_modal')}
            </Row>
            <Box width="80%">
              {isValidEmail ? currentUser.eMailAddress : t('incorrect_email_reset_password_modal')}
            </Box>
          </Row>

          <Row onClick={() => isValidPhone ? setSelectedMethod('phone') : {}} sx={{ cursor: 'pointer', justifyContent: "space-between" }} mt={'15px'}>
            <Row >
              <Icon src={wa_icon} sx={{ ml: '7px' }} />
              <Radio disabled={!isValidPhone} checked={selectedMethod === 'phone'} size="small" />
              {t('phone_number_reset_password_modal')}
            </Row>
            <Box width="80%" dir="ltr" textAlign={'left'}>{isValidPhone ? currentUser.phoneNumber : t('incorrect_phone_number_reset_password_modal')}
            </Box>
          </Row>
        </Box>
        {isMessageSentSuccess && <Box mt={'15px'} justifyContent="center" sx={{ textAlign: "center" }}>
          <BigTitle>{t('the_message_was_sent_successfully_reset_password_modal')}</BigTitle>
          <Row sx={{ mt: '8px', cursor: 'pointer' }} onClick={copyToClipboard}><Box color="blue">{link}</Box> <Icon sx={{ ml: '6px' }} src={copy_icon} /></Row>
        </Box>}
        <Row mt={'15px'} justifyContent="flex-end">
          <Row width={300}>
            {isMessageSentSuccess ?
              <BaseBtn onClick={handleCloseEditModal} fullWidth>
                {t('ok_reset_password_modal')}
              </BaseBtn> : <>
                <BaseBtn onClick={handleCloseEditModal} btnColor="info.main" fullWidth>
                  {t('cancel_reset_password_modal')}
                </BaseBtn>
                <Box onClick={handleCloseEditModal} sx={{ height: '40px', width: '25px' }} />
                <BaseBtn loading={isLoading} disabled={!selectedMethod || isMessageSent} onClick={handleSave} btnColor="primary.dark" fullWidth>
                  {t('reset_reset_password_modal')}
                </BaseBtn></>}
          </Row>
        </Row>
      </Box>

    </Modal >
  );
};

import { useAppDispatch } from 'shared/hooks';
import { useState } from 'react';
import { useAppSelector, useExtendedTranslation } from 'shared/hooks';
import { setCustomerFilter } from '../controllerSlice';
export const useFilterControllers = () => {
  const { t } = useExtendedTranslation();
  const { customers } = useAppSelector((st) => st.customers);
  const { customerIDForFilter } = useAppSelector((st) => st.controllers);
  const dispatch = useAppDispatch();

  const customerFilterItems = customers.map((s) => ({
    value: s.id.toString(),
    label: s.customerName,
  }));
  const actionsFilterItems = [
    {
      value: '1',
      label: t('move_device_between_customers_action'),
    },
    {
      value: '2',
      label: t('update_controller_name_customers_action'),
    },
  ];
  const [actionsFilter, setActionsFilter] = useState<string>('');
  const customerFilter = customerIDForFilter.toString();
  const onChangeCustomerFilter = (value: string) => dispatch(setCustomerFilter(parseInt(value)));
  const onChangeActionsFilter = (value: string) => setActionsFilter(value);
  return {
    customerFilter,
    onChangeCustomerFilter,
    customerFilterItems,
    actionsFilter,
    onChangeActionsFilter,
    actionsFilterItems,
  };
};

import { Box, IconButton, FormControlLabel, Checkbox } from '@mui/material';
import { BigTitle, Row, BaseBtn, Modal, Icon } from 'shared/ui';
import { useState, useEffect, memo, FC } from 'react';
import { Filter, BaseDataPicker } from 'features';
import { toggleOpenEditControllerClearDataModal } from 'shared/slices';
import { t } from 'i18next';
import { useAppSelector, useAppDispatch } from 'shared/hooks';
import { close_popup } from 'shared/assets';
import { useFilterControllers, useControllerClearDataFilter } from './../hooks';
import { checked_box, un_checked_box } from 'shared/assets';
import { setDailyConsumption, setControllerClearDataFilter, setDailyConsumptionChanged } from 'shared/slices';
import { useDailyConsumption } from 'features/ControllerManager/hooks/api/useDailyConsumption';
import { Controller } from 'shared/models';
type FormattedDailyConsumption = {
  date: string;
  totalProg: number;
  totalActual: number;
  totalUncontrolled: number;
};
interface Props {
  currentController: Controller;
}

export const EditControllerClearDataModal: FC<Props> = ({ currentController }) => {
  const dispatch = useAppDispatch();
  const { openEditControllerClearDataModal } = useAppSelector((st) => st.modals);
  const { logDailyConsumption } = useDailyConsumption();
  useEffect(() => {
    if (openEditControllerClearDataModal) {
      setIsActual(true);
      setIsUncontrolled(true);
      setStartDate(fromDate);
      setEndDate(toDate);
    } else {
      // const [startDate, setStartDate] = useState(fromDate);
      // const [endDate, setEndDate] = useState(toDate);
    }
  }, [openEditControllerClearDataModal]);
  const closeModal = () => dispatch(toggleOpenEditControllerClearDataModal());
  const { dailyConsumption } = useAppSelector((st) => st.controllers);
  const { controllerClearDataFilter } = useAppSelector((st) => st.controllers);

  const onSubmit = () => {
    const result = {
      startDate,
      endDate,
      uncontrolledFlow: isUncontrolled,
      actualConsumption: isActual,
    };
    dispatch(setControllerClearDataFilter(result));
    const logParam = {
      objectID: currentController.deviceId,
      startDate,
      endDate,
      uncontrolledFlow: isUncontrolled,
      actualConsumption: isActual

    }

    logDailyConsumption(logParam);
    // const startDate = new Date(controllerClearDataFilter.startDate).getTime();
    // const endDate = new Date(controllerClearDataFilter.endDate).getTime();
    const startDateIndex = new Date(startDate).getTime();
    const endDateIndex = new Date(endDate).getTime();
    const actualConsumption = isActual;
    const uncontrolledFlow = isUncontrolled;
    const formattedDailyConsumption: FormattedDailyConsumption[] = dailyConsumption.map(
      (item: FormattedDailyConsumption) => ({
        ...item,
      })
    );
    formattedDailyConsumption.map((item) => {
      const dateIndex = new Date(item.date).getTime();
      if (dateIndex + 86400000 > startDateIndex && dateIndex <= endDateIndex) {
        if (uncontrolledFlow) {
          item.totalUncontrolled = 0;
        }
        if (actualConsumption) {
          item.totalActual = 0;
        }
      }
      return item;
    });
    dispatch(setDailyConsumption(formattedDailyConsumption));
    dispatch(setDailyConsumptionChanged(true));
    closeModal();
  };

  const { actionsFilter, onChangeActionsFilter, actionsFilterItems } = useFilterControllers();
  const { fromDate, toDate } = useControllerClearDataFilter();
  const [isActual, setIsActual] = useState(true);
  const [isUncontrolled, setIsUncontrolled] = useState(true);
  const [startDate, setStartDate] = useState(fromDate);
  const [endDate, setEndDate] = useState(toDate);
  const onChangeDateValues = (param: string, value: string) => {
    if (param === 'fromDate') {
      setStartDate(value);
      // changeFromDate(value);
    }
    if (param === 'toDate') {
      setEndDate(value);
      // changeToDate(value);
    }
  };
  const toggleUncontrolled = () => {
    setIsUncontrolled(!isUncontrolled);
    //  setUncontrolled(!uncontrolled);
  };
  const toggleActual = () => {
    setIsActual(!isActual);
    // setActual(!actual);
  };
  const changeStartDate = (value: number) => {
    const data = startDate;
    const dateObject = new Date(data);
    dateObject.setDate(dateObject.getDate() + value);
    const localOffsetMinutes = dateObject.getTimezoneOffset();
    const adjustedDate = new Date(dateObject.getTime() - localOffsetMinutes * 60000);
    const updatedDateString = adjustedDate.toISOString().slice(0, 10);
    onChangeDateValues('fromDate', updatedDateString);
  };
  const changeEndDate = (value: number) => {
    const data = endDate;
    const dateObject = new Date(data);
    dateObject.setDate(dateObject.getDate() + value);
    const localOffsetMinutes = dateObject.getTimezoneOffset();
    const adjustedDate = new Date(dateObject.getTime() - localOffsetMinutes * 60000);
    const updatedDateString = adjustedDate.toISOString().slice(0, 10);
    onChangeDateValues('toDate', updatedDateString);
  };
  return (
    <Modal open={openEditControllerClearDataModal} sx={{ width: 600, margin: 'auto' }} onClose={closeModal}>
      <Box component="form" autoComplete="off">
        <Row spacing="20px" alignItems="flex-start" justifyContent="space-between">
          <BigTitle>{t('last_irrigation_clear_data_edit_modal')}</BigTitle>
          <IconButton onClick={closeModal}>
            <img src={close_popup} />
          </IconButton>
        </Row>
        <Box>
          <Row spacing="24px" sx={{ mb: '30px' }}>
            <Filter
              title={`${t('actions_filter')}`}
              value={actionsFilter}
              onChange={onChangeActionsFilter}
              items={actionsFilterItems}
              hideAllButton
              sx={{ width: '260px' }}
              messageEmptyFilter={`${t('actions_please_select_an_action')}`}
              placeholder={`${t('filter_search_clear_data_modal')}`}
            />
          </Row>
          <Row spacing="24px" sx={{ mb: '30px' }} alignItems="flex-end">
            <BaseBtn onClick={() => changeStartDate(-1)} fullWidth sx={{ height: '29px' }}>
              {t('-')}
            </BaseBtn>
            <Box sx={{ minWidth: '250px' }}>
              <BaseDataPicker
                label={`${t('start_date_clear_data_modal')}`}
                name="fromDate"
                value={startDate}
                onChange={onChangeDateValues}
                formStyle
              />
            </Box>
            <BaseBtn onClick={() => changeStartDate(1)} fullWidth sx={{ height: '29px' }}>
              {t('+')}
            </BaseBtn>
          </Row>
          <Row spacing="24px" sx={{ mb: '30px' }} alignItems="flex-end">
            <BaseBtn onClick={() => changeEndDate(-1)} fullWidth sx={{ height: '29px' }}>
              {t('-')}
            </BaseBtn>
            <Box sx={{ minWidth: '250px' }}>
              <BaseDataPicker
                label={`${t('end_date_clear_data_modal')}`}
                name="toDate"
                value={endDate}
                onChange={onChangeDateValues}
                error={null}
                formStyle
              />
            </Box>
            <BaseBtn onClick={() => changeEndDate(1)} fullWidth sx={{ height: '29px' }}>
              {t('+')}
            </BaseBtn>
          </Row>
          <Row spacing="24px" sx={{ mb: '10px' }}>
            <FormControlLabel
              value="end"
              sx={{ ml: '20px' }}
              control={
                <Checkbox
                  size="small"
                  checked={isUncontrolled}
                  onChange={toggleUncontrolled}
                  icon={<Icon src={un_checked_box} />}
                  checkedIcon={<Icon src={checked_box} />}
                ></Checkbox>
              }
              label={`${t('uncontrolled_clear_data_modal')}`}
              labelPlacement="end"
            />
          </Row>
          <Row spacing="24px" sx={{ mb: '10px' }}>
            <FormControlLabel
              value="end"
              sx={{ ml: '20px' }}
              control={
                <Checkbox
                  size="small"
                  checked={isActual}
                  onChange={toggleActual}
                  icon={<Icon src={un_checked_box} />}
                  checkedIcon={<Icon src={checked_box} />}
                ></Checkbox>
              }
              label={`${t('actual_clear_data_modal')}`}
              labelPlacement="end"
            />
          </Row>
        </Box>
        <Row justifyContent="flex-end" mt={'35px'}>
          <Row width={300}>
            <BaseBtn onClick={closeModal} btnColor="info.main" fullWidth>
              {t('cancel_edit_modal')}
            </BaseBtn>
            <Box sx={{ height: '40px', width: '25px' }} />
            <BaseBtn onClick={onSubmit} disabled={!isActual && !isUncontrolled} btnColor="primary.dark" fullWidth>
              {t('save_edit_modal')}
            </BaseBtn>
          </Row>
        </Row>
      </Box>
    </Modal>
  );
};

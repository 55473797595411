import { Sim } from 'shared/models';
import { makeDeepTrim } from './makeDeepTrim';

export const checkSimChanges = (initial: Sim, checking: Sim) => {
  const keys = Object.keys(initial);

  const changes = keys.map((key) => (checking?.[key as keyof Sim] ?? '') !== (initial?.[key as keyof Sim] ?? ''));

  const isChanged = changes.includes(true);

  return isChanged;
};

export const getSearchedSims = (sims: Sim[], searchValue: string) => {
  if (!sims) return [];
  const searched = sims.filter((c) => {
    const values = Object.values(c);

    return values.some((v) => v?.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase().trim()));
  });

  return searched;
};

export const getDeepTrimSimValues = (sim: Sim) => {
  const entries = Object.entries(sim).map((item) => {
    const [key, value] = item;
    const isStr = typeof value === 'string';

    if (!isStr) return item;

    const newValue = makeDeepTrim(value);
    return [key, newValue];
  });

  const trimSim = Object.fromEntries(entries);
  return trimSim;
};

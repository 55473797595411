import { useAppDispatch } from 'shared/hooks';

import useAxios from 'shared/api/useAxios';
import { WAMessageStatus } from 'features/WAMessageStatusManager/WAMessageStatusModels';
import { setWAMessageStatus } from 'features/WAMessageStatusManager/WAMessageStatusSlice';


export const useWAMessageStatus = () => {
  const dispatch = useAppDispatch();
  const { loading, request } = useAxios();
  return {
    getWAMessageStatus: (dateFrom:string, dateTo:string, timezone:number) =>
    request< undefined, { data: WAMessageStatus[] }>({
      method: 'get',
      url: `/WhatsApp/messages?${dateFrom ? "dateFrom="+dateFrom:""}${dateTo? "&dateTo="+dateTo :""}&timezoneInMinutes=${timezone}`,
      showSuccessMessageCode: undefined,
      onDataReceiveCompleted: (data) => {
        dispatch(setWAMessageStatus(data.data))
      },
    }),
    isLoading: loading,
  };
};

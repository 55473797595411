
export const LINE_COLOR = 'rgba(0, 0, 0, 0.8)';
export const lOST_SIGNAL_LINE_COLOR = 'rgba(255, 255, 0, 1)';
export const GENERAL_FAULT = 'rgba(255, 0, 0, 1)';
export const SYSTEM_FAULT = 'rgba(255, 0, 0, 0)';
export const SIGNAL_POINT = 'rgba(0, 0, 0, 1)';
export const COMMUNICATION_FAULT_POINT = 'rgba(255, 0, 0, 0)';
export const COMMUNICATION_RESTORED_POINT = 'rgba(0, 126, 0, 0)';
export const LINE_HEIGHT_RATIO = 4;
export const COUNT_OF_Y_LINES = 23
export const DOT_SIZE = 4
export const WIDTH_OF_Y_AXES = 165;
export const FLOW_RATE_STROKE_COLOR = 'rgb(80,80,256, 0.6)'
export const FLOW_RATE_FILL_COLOR = 'rgb(175,175,256, 0.6)'
export const ICON_TOP_POSITION = 16
export const ICON_LEFT_POSITION = 10
export const POSITION_OF_Y_TOOLTIP = 40
export const WEEK_DAY_NAMES_ENG = {
	WWW: ["Su", "M", "Tu", "W", "Th", "F", "Sa"],
	MMMM: ["", "", "", "", "", "", "", "", "", "", "", ""],
	MMM: ["", "", "", "", "", "", "", "", "", "", "", ""],
	WWWW: ["", "", "", "", "", "", ""],
};
export const WEEK_DAY_NAMES_HE = {
	WWW: ["א", "ב", "ג", "ד", "ה", "ו", "ש",],
	MMMM: ["", "", "", "", "", "", "", "", "", "", "", ""],
	MMM: ["", "", "", "", "", "", "", "", "", "", "", ""],
	WWWW: ["", "", "", "", "", "", ""],
};
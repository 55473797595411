import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MUIDirectionContext } from 'shared/contexts';
import localStorageService from 'shared/localStorage';

export const useExtendedTranslation = () => {
  const { t, i18n } = useTranslation();
  const { changeDirection } = useContext(MUIDirectionContext);

  const changeLanguage = (i18n: any) => (language: string) => {
    localStorageService.currentLang = language;
    i18n.changeLanguage(language);

    const isRtlLang = language === 'he' || language === 'ar';
    isRtlLang ? changeDirection('rtl') : changeDirection('ltr');
  };

  return { t, changeLanguage: changeLanguage(i18n) };
};

import { useEffect, useState } from 'react';
import { useAppSelector, useExtendedTranslation} from 'shared/hooks';
import localStorageService from 'shared/localStorage';
import { ReplacedEquipmentReportFilter} from 'shared/models';
export const useFilterReplacedEquipment = () => {
  const { t } = useExtendedTranslation();
  const { customers } = useAppSelector((st) => st.customers);
  const {  equipmentTypes } = useAppSelector((st) => st.equipmentReplacementReport);

  const customersFilterItems = customers.map((s) => ({
    value: s.id.toString(),
    label: s.customerName,
  }));
  //const allAgreements = customersFilterItems.map((item) => item.value);

  const [customersFilterValue, setCustomersFilterValue] = useState<string>(null);
  const onChangeCustomersFilterItem = (values: string) => setCustomersFilterValue(values);





  const equipmentTypesFilterItems = equipmentTypes.map((s) => ({
    value: s.id.toString(),
    label: t(`equipment_type_${s.name}`),
  }));

  const getDataFromLocalStorage = () => {
    const data =  localStorageService.replacedEquipmentReport;
    return data ? JSON.parse(data) : null;
  };

  const saveToLocalStorage = (filter: {equipmentTypesFilterItems:string[]}) => {
     localStorageService.replacedEquipmentReport =  JSON.stringify(filter);
  };
  //const allEquipmentTypes = equipmentTypesFilterItems.map((item) => item.value);
  const localData = getDataFromLocalStorage()
    const defaultEquipmentTypes: string[] = localData ? localData.equipmentTypesFilterItems : ["EQ_BATT","EQ_SOLAR_PANEL","EQ_CONTROLLER","EQ_VALVE_SOL"];
  const [equipmentTypesFilterValues, setEquipmentTypesFilterItems] = useState<string[]>(defaultEquipmentTypes);
  const onChangeEquipmentTypesFilter = (values: string[]) =>{
    saveToLocalStorage({equipmentTypesFilterItems:values})
    setEquipmentTypesFilterItems(values)
  };

  useEffect(() => {
    setEquipmentTypesFilterItems(defaultEquipmentTypes);
  }, [equipmentTypes ]);




  return {
    customersFilterValue,
    onChangeCustomersFilterItem,
    customersFilterItems,

		equipmentTypesFilterValues,
		onChangeEquipmentTypesFilter,
		equipmentTypesFilterItems,
    saveToLocalStorage
  };
};

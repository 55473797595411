import { Stack, IconButton, Box, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useState, useEffect, memo } from 'react';
import { BaseDataPicker } from 'features';
import { t } from 'i18next';
import { FC } from 'react';
import { close_popup, clients } from 'shared/assets';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { toggleOpenShowGraphModal, setCurrentBatteryStatusReportProjectDates, setServiceCalls } from 'shared/slices';
import { BigTitle, Modal, Row, BaseBtn, Icon } from 'shared/ui';
import { useBatteryStatusReport, useServiceCallsManagement } from 'shared/api';
import { useGetDefaultDates } from './hooks';
import { ServiceCalls } from './components';
import { useFormattedGraphData, FormattedGraphData } from 'shared/lib';
import { BatteryStatusComposedChart } from 'entities_';
import { ComposedChart } from 'components/ComposedChart';

interface Props {
  changedServiceCalls: () => void;
  closedModal: () => void;
}

export const BatteryStatusReportGraph: FC<Props> = ({ changedServiceCalls, closedModal }) => {
  const dispatch = useAppDispatch();
  const { getBatteryStatusReportProject, isLoadingGraphBatteryStatusReport } = useBatteryStatusReport();
  const { getServiceCalls } = useServiceCallsManagement();
  const { openShowGraphModal } = useAppSelector((st) => st.modals);
  const { items, filterGraph, filter: reportFilter } = useAppSelector((st) => st.batteryStatusReport);
  const { startDate, endDate } = useGetDefaultDates(reportFilter);
  const { openEditServiceCall, openAddServiceCall } = useAppSelector((st) => st.modals);
  const { BATT_REP } = useAppSelector((st) => st.globalSlice);
  const initialFilter = {
    //fromDate: '2023-01-09',
    //toDate: '2023-01-10',
    fromDate: startDate,
    toDate: endDate,
  };
  const [filter, setFilter] = useState(initialFilter);
  const [formattedData, setFormattedData] = useState<FormattedGraphData[]>([]);

  const onChangeDateValues = (param: string, value: string) => {
    setFilter((prevValues) => ({
      ...prevValues,
      [param]: value,
    }));
  };

  const fetchData = async () => {
    const data = await getBatteryStatusReportProject(filterGraph);
    const graphData = data ? data.data : [];
    const formattedData = useFormattedGraphData(graphData, filterGraph);
    setFormattedData(formattedData);
  };
  const fetchServiceCallsData = async () => {
    getServiceCalls(filterGraph.deviceId);
  };
  useEffect(() => {
    setFilter(initialFilter);
  }, [reportFilter]);

  useEffect(() => {
    if (openShowGraphModal) {
      updateGraph();
      fetchData();
      fetchServiceCallsData();
    } else {
      dispatch(setCurrentBatteryStatusReportProjectDates({ fromDate: '', toDate: '' }));
      setFilter(initialFilter);
      dispatch(setServiceCalls([]));
    }
  }, [openShowGraphModal]);

  const updateGraph = () => {
    dispatch(setCurrentBatteryStatusReportProjectDates(filter));
  };

  useEffect(() => {
    fetchData();
  }, [filterGraph]);

  const onCloseModal = () => {
    setFormattedData([]);
    dispatch(toggleOpenShowGraphModal());
    closedModal();
  };
  const controllerName = items.find((item) => item.objectId === filterGraph.deviceId)?.controllerName;
  const powerSource = items.find((item) => item.objectId === filterGraph.deviceId)?.powerSupCode;

  return (
    <Modal width={'100%'} open={openShowGraphModal} onClose={onCloseModal}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <BigTitle>{controllerName}</BigTitle>

        <IconButton onClick={onCloseModal}>
          <img src={close_popup} />
        </IconButton>
      </Stack>
      <Box
        sx={{
          display: 'flex',
          columnGap: '20px',

          top: '100px',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Noto Sans Hebrew',
            fontWeight: 600,
          }}
        >
          {t('powersupcode_battery_status_report_grid_table')}:
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Noto Sans Hebrew',
            fontWeight: 600,
          }}
        >
          {t(`${powerSource}`)}
        </Typography>
      </Box>
      <Row spacing="24px" justifyContent="center" alignItems="flex-end">
        <BaseBtn onClick={updateGraph} sx={{ height: '29px' }}>
          {t('graph_battery_status_update')}
        </BaseBtn>
        <BaseDataPicker
          label={`${t('start_date_graph_battery_status_report_label')}`}
          name="fromDate"
          value={filter.fromDate}
          onChange={onChangeDateValues}
        />
        <BaseDataPicker
          label={`${t('end_date_graph_battery_status_report_label')}`}
          name="toDate"
          value={filter.toDate}
          onChange={onChangeDateValues}
          error={null}
        />
      </Row>

      <Box
        sx={{
          margin: '20px auto',
          opacity: isLoadingGraphBatteryStatusReport ? 0 : 1,
          position: isLoadingGraphBatteryStatusReport ? 'absolute' : 'inherit',
        }}
      >
        {/* <BatteryStatusComposedChart data={formattedData} /> */}
        {/* <ComposedChart data={formattedData} /> */}
        <ComposedChart
          showEventIds={[1702]}
          data={formattedData}
          startDate={filter.fromDate}
          endDate={filter.toDate}
          popupPadding={140}
        />
      </Box>

      {isLoadingGraphBatteryStatusReport && (
        <Row height={400} justifyContent="center" margin="20px auto">
          <CircularProgress disableShrink />
        </Row>
      )}
      {/* <ServiceCalls changedServiceCalls={changedServiceCalls} /> */}
    </Modal>
  );
};

import { WAMessageStatus } from 'features/WAMessageStatusManager/WAMessageStatusModels';
import { useTranslation } from 'react-i18next';
interface Props {
  row?: WAMessageStatus;
}

const getStatusIcon = (status: string) => {
  switch (status) {
    case 'sent':
      return <span style={{ width: '26px', margin: '0 6px', display: 'inline-block' }}> ✓ </span>;
    case 'delivered':
      return <span style={{ width: '26px', margin: '0 6px', display: 'inline-block' }}> ✓✓ </span>;
    case 'read':
      return <span style={{ color: '#4185F4', width: '26px', margin: '0 6px', display: 'inline-block' }}> ✓✓ </span>;
    case 'failed':
      return <span style={{ color: 'red', width: '26px', margin: '0 6px', display: 'inline-block' }}> x </span>;
  }
  return '';
};
export const WaMessageStatus = ({ row }: Props) => {
  return <>{getStatusIcon(row.status)}</>;
};

import { IconButton, Stack } from '@mui/material';
import { User } from 'features/UserManager/UserModels';

import { UnderConstructionBlock } from 'entities_';
import { t } from 'i18next';
import { FC } from 'react';
import { close_popup } from 'shared/assets';
import { BigTitle, Modal } from 'shared/ui';

interface ModalState {
	isOpenModal: boolean
	currentUser: User
	closeModal: () => void
}

export const ChangeExpirationDateModal: FC<ModalState> = (props: ModalState) => {
	const { isOpenModal, closeModal, currentUser } = props;


	const handleCloseEditModal = () => {
		closeModal();
	}

	return (
		<Modal open={isOpenModal} disableEscapeKeyDown onClose={handleCloseEditModal}>
			<Stack direction="row" justifyContent="space-between" alignItems="center">
				<BigTitle><span>{t('change_expiration_date_details_title')}</span></BigTitle>

				<IconButton onClick={handleCloseEditModal}>
					<img src={close_popup} />
				</IconButton>
			</Stack>
			<UnderConstructionBlock text={t("this_feature_is_still_under_development")} />
		</Modal >
	);
};
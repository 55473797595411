import localStorageService from 'shared/localStorage';
export const CustomizedAxisYTick = (props) => {
  const isRTL =localStorageService.isRTL;
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={-5} y={-5} dy={8} fontSize='11px' textAnchor={isRTL ? 'start' : 'end'} fill="black">
          {payload.value}
      </text>
    </g>
  );
};

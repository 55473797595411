import { useState, useEffect, useMemo } from 'react';
import localStorageService from 'shared/localStorage';
export const useFilterMessagesTemplates = (updateAllFota = true) => {
  const { currentLang } = localStorageService;
  const capitalizeFirstLetter = (string: string) => {
    if (string.length === 0) {
      return string;
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  }


  const [messageTemplateLang, setMessageTemplateLang] = useState(capitalizeFirstLetter(currentLang));

  const messageTemplateLangItems = [
    {
      value: 'En',
      label: `En`,
    },
    {
      value: 'He',
      label: `He`,
    },
  ];

  const onChangeMessageTemplateLang = (value: string) => {
    setMessageTemplateLang(value);
  };

  return {
    messageTemplateLang,
    onChangeMessageTemplateLang,
    messageTemplateLangItems,
  };
};

import { BatteryStatusItem } from 'shared/models';
import { useTranslation } from 'react-i18next';
export const useFormatXlsxData = () => {
  const { t } = useTranslation();
 
  const formatTable = (arr: BatteryStatusItem[]) => {
    const exportArr = arr.map((item) => {
      return {
        [t('project_id_battery_status_report_grid_table')]: item.projectId,
        [t('project_name_battery_status_report_grid_table')]: item.projectName,
        [t('hwid_battery_status_report_grid_table')]: item.HWID,
        [t('object_id_battery_status_report_grid_table')]: item.objectId,
        [t('controller_name_battery_status_report_grid_table')]: item.controllerName,
        [t('ctlver_battery_status_report_grid_table')]: item.CTLVer,
        [t('powersupcode_battery_status_report_grid_table')]: item.powerSupCode,
        [t('disconnect_type_battery_status_report_grid_table')]: t(`disconnect_type_${item.disconnectType}`),
        [t('last_open_service_call_date_battery_status_report_grid_table')]: item.lastOpenServiceCallDate,
      };
    });

    return exportArr;
  };

  return { formatTable };
};

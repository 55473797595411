export const useValidateExcelData = (excelData: string[][], SIM_IMPORT: any) => {
  const SIMNumberValues = SIM_IMPORT.COL_SIM_NUMBER;
  const PhoneNumberValues = SIM_IMPORT.COL_PHONE_NUMBER;
  const SIMStatusValues = SIM_IMPORT.COL_SIM_STATUS;
  const header = excelData[0];
  const findIndexSIMNumberData = () => {
    for (let i = 0; i < SIMNumberValues.length; i++) {
      const value = SIMNumberValues[i].toLowerCase();
      const indexInArray1 = header.findIndex((item) => item.toLowerCase().trim() === value);
      if (indexInArray1 !== -1) {
        return indexInArray1;
      }
    }
    return -1;
  };
  const findIndexPhoneNumberData = () => {
    for (let i = 0; i < PhoneNumberValues.length; i++) {
      const value = PhoneNumberValues[i].toLowerCase();
      const indexInArray1 = header.findIndex((item) => item.toLowerCase().trim() === value);
      if (indexInArray1 !== -1) {
        return indexInArray1;
      }
    }
    return -1;
  };
  const findIndexSIMStatusData = () => {
    for (let i = 0; i < SIMStatusValues.length; i++) {
      const value = SIMStatusValues[i].toLowerCase();
      const indexInArray1 = header.findIndex((item) => item.toLowerCase().trim() === value);
      if (indexInArray1 !== -1) {
        return indexInArray1;
      }
    }
    return -1;
  };
  const indexSIMNumber: number = findIndexSIMNumberData();
  const indexPhoneNumber: number = findIndexPhoneNumberData();
  const indexSIMStatus: number = findIndexSIMStatusData();
  return {
    indexSIMNumber,
    indexPhoneNumber,
    indexSIMStatus,
  };
};

import { FC, startTransition, useEffect, useState } from 'react';
import { DateValidationError, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Box, IconButton, IconButtonProps, TextField, Typography, useTheme } from '@mui/material';
import { formatDtoDate } from 'shared/lib';
import { t } from 'i18next';
import { Icon } from 'shared/ui';
import { clear_filter } from 'shared/assets';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

interface Props {
  label: string;
  name: string;
  value: string;
  onChange: (name: string, value: string) => void;
  disabled?: boolean;
  error?: DateValidationError | null;
  setError?: (error: DateValidationError) => void;
  formStyle?: boolean;
  showFuture?: boolean;
  disablePast?: boolean;
  clearIcon?: boolean;
  clearFilterValue?: () => void;
  changedText?: (value: string) => void;
}
interface ResetButtonProps extends IconButtonProps {
  showHomeButton?: boolean;
}
const ResetButton: FC<ResetButtonProps> = ({ onClick, sx }) => (
  <IconButton
    onClick={onClick}
    sx={{
      position: 'absolute',
      zIndex: 2,
      height: '30px',
      width: '33px',
      ...sx,
    }}
  >
    <Icon src={clear_filter} />
  </IconButton>
);
export const BaseDataAndTimePicker: FC<Props> = ({
  label,
  name,
  value,
  onChange,
  disabled,
  error,
  setError,
  changedText,
  clearFilterValue,
  formStyle,
  showFuture,
  disablePast,
  clearIcon,
}) => {
  const { palette } = useTheme();

  const [date, setDate] = useState<Dayjs | null>(dayjs(value));
  const [newDate, setNewDate] = useState<string>(value);
  const [closePopup, setClosePopup] = useState(false);
  const handleChange = (newDateTime: Dayjs) => {
    const updatedDateTime = newDateTime ? newDateTime.format('YYYY-MM-DD HH:mm:ss') : '';
    setNewDate(updatedDateTime);
    //onChange(name, newDate);
  };
  const onSelect = () => {
    setClosePopup(true);
  };
  useEffect(() => {
    if (closePopup) {
      setClosePopup(false);
      onChange(name, newDate);
    }
  }, [closePopup]);

  useEffect(() => setDate(dayjs(value)), [value]);

  const isShowResetButton = () => {
    if (!value) {
      //setNewDate(null);
      // setDate(null);
      return false;
    }
    if (!clearIcon) return false;
    return dayjs(value).isValid();
  };
  const onChangeText = (value) => {
    changedText(newDate);
  };
  return (
    <Box>
      {label && (
        <Typography
          noWrap
          sx={{
            fontSize: formStyle ? '14px' : '12px',
            fontWeight: formStyle ? 600 : 500,
            color: formStyle ? 'rgb(10, 62, 144)' : 'info.dark',
            marginBottom: '0px',
          }}
        >
          {label}
        </Typography>
      )}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {isShowResetButton() ? <ResetButton onClick={clearFilterValue} /> : <></>}
        <DateTimePicker
          format={'DD.MM.YYYY HH:mm'}
          disabled={disabled}
          value={date}
          ampm={false}
          onClose={onSelect}
          onChange={(newDate) => handleChange(newDate as Dayjs)}
          disableFuture={!showFuture}
          disablePast={disablePast}
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
            seconds: renderTimeViewClock,
          }}
          slotProps={{
            textField: {
              name,
              onBlur: onChangeText,

              onKeyDown: (event) => {
                if (event.key === 'Enter') {
                  event.currentTarget.blur();
                }
              },
              helperText: error && t('invalid_date_date_picker'),
              sx: {
                width: '100%',
                '& .MuiOutlinedInput-root': {
                  paddingLeft: isShowResetButton() ? '20px' : 'auto',
                  borderRadius: '12px',
                  height: '29px',
                  bgcolor: 'white',
                  '& > fieldset': { border: `1.5px solid ${palette.primary.dark}` },
                  '&.Mui-disabled > fieldset': { border: `1.5px solid ${palette.primary.dark}` },
                  '&.Mui-disabled': { bgcolor: 'info.contrastText' },
                  '& .MuiTypography-caption': { color: 'white' },
                },
                '.MuiOutlinedInput-root.Mui-disabled': {
                  WebkitTextFillColor: 'info.dark',
                },
                '& fieldset': {
                  borderColor: `${palette.primary.dark} !important`,
                },
                '& input': {
                  color: value ? palette.primary.dark : '#d3d3d3',
                },
              },
            },
            layout: {
              sx: {
                '& .Mui-selected': {
                  color: 'white !important',
                },
                '& .MuiPickersYear-yearButton.Mui-selected': {
                  color: 'white  !important',
                },
              },
            },
          }}
        />
      </LocalizationProvider>
    </Box>
  );
};

/* eslint-disable prefer-const */
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { TableDataWithId } from 'features/ControllersMoveTable';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { axiosBase } from 'shared/config';
import { useAppDispatch, useToast } from 'shared/hooks';
import { setErrorUpload, setProgress, setSuccessUpload } from 'shared/slices';

const UseRenameDevice = (): {
  isLoading: boolean;
  isUpdated: boolean;
  handleRequest: (modifiedControllers: TableDataWithId[]) => Promise<void>;
  setIsUpdated: (status: boolean) => void;
} => {
  const { t } = useTranslation();
  const { showError } = useToast();
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  let response: AxiosResponse;

  const handleRequest = async (modifiedControllers: TableDataWithId[]) => {
    try {
      setIsLoading(true);
      setIsUpdated(false);

      const totalRequests = modifiedControllers.length;
      let completedRequests = 0;

      await Promise.all(
        modifiedControllers.map(async (controller) => {
          try {
            const config: AxiosRequestConfig = {
              data: {
                objectName: controller.objectName,
                objectID: controller.id,
              },
            };

            response = await axiosBase.put('/Device', config.data);

            if (response.data.error !== null) {
              dispatch(setErrorUpload(controller.id));
              completedRequests++;
              dispatch(setProgress((completedRequests / totalRequests) * 100));
              return;
            }

            dispatch(setSuccessUpload(response.data));
            completedRequests++;
            dispatch(setProgress((completedRequests / totalRequests) * 100));

            return response;
          } catch (error: unknown) {
            // dispatch(setErrorUpload(controller));
            // throw error;
          }
        })
      );

      if (completedRequests === totalRequests) {
        setIsUpdated(true);
      }

      setIsLoading(false);
    } catch (error: unknown) {
      setIsLoading(false);
      setIsUpdated(true);
      showError(t('something_went_wrong_toast_text'));
    }
  };

  return { handleRequest, isLoading, isUpdated, setIsUpdated };
};

export default UseRenameDevice;

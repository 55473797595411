import { DashboardDetails, TableDataWithId } from 'shared/models';
import { TableTitle, TranslateColumn } from 'components/BaseTable';
import { PlantType } from 'features/WeatherStationsManager/WeatherStationModels';

export const TABLE_TITLES: TableTitle<PlantType>[] = [
	{ title: 'id', key: 'id', width: '30%' },
	{ title: 'plant_type', key: 'plantType', width: '100%' },
	// { title: 'status', key: 'status', width: '100%' },
];

export const TRANSLATE_COLUMNS: TranslateColumn<PlantType>[] = [
	{ key: 'plantType', preKey: 'WE_PLANTTYPE_' },
]
import { Box, TextField, TextFieldProps, Typography, useTheme } from '@mui/material';
import { forwardRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { autoFillColor } from 'shared/config';
import { isHebrewFont } from 'shared/lib';
import { replaceSpacesWithUnderscores } from 'shared/lib/replaceSpacesWithUnderscores';

type Props = TextFieldProps & {
  label?: string;
  big?: boolean;
};

export const Input = forwardRef<unknown, Props>(({ label, big, name, ...props }, ref) => {
  const { palette, direction } = useTheme();
  const { t } = useTranslation();
  const isHebrew = isHebrewFont(props?.value?.toString() ?? '');
  const isRtl = direction === 'rtl';

  const {
    register,
    formState: { errors },
  } = useFormContext();

  const helpertext =
    errors?.[name as string]?.message?.toString() !== undefined &&
    t(`${replaceSpacesWithUnderscores(errors?.[name as string]?.message?.toString() || '')}_validation_text`);

  return (
    <Box mt={'20px'}>
      {label && (
        <Typography
          noWrap
          sx={{
            fontSize: '14px',
            fontWeight: 600,
            textTransform: 'capitalize',
            color: palette.primary.dark,
            marginBottom: '3px',
          }}
        >
          {label}
        </Typography>
      )}
      <TextField
        fullWidth
        multiline={big ? true : false}
        rows={big ? 5 : 1}
        autoComplete="none"
        {...register(name as string)}
        error={!!errors[name as string]}
        // helperText={errors?.[name as string]?.message?.toString()}
        helperText={helpertext}
        sx={{
          background: palette.info.main,
          borderRadius: '12px',
          color: 'black',

          '& .MuiOutlinedInput-root': {
            '& > fieldset': { border: `1.5px solid ${palette.primary.dark}` },
            '&.Mui-disabled > fieldset': { border: `1.5px solid ${palette.primary.dark}` },
            '&.Mui-disabled': { bgcolor: 'info.contrastText' },
          },
          '& .Mui-disabled': {
            WebkitTextFillColor: 'info.dark',
          },
          '& .MuiFormHelperText-root': {
            lineHeight: '12px',
            position: 'absolute',
            bottom: '-15px',
            color: 'error.main',
            fontSize: '12px',
            fontWeight: 500,
            fontFamily: 'Noto Sans Hebrew',
            ml: '5px',
          },
          'input, textarea': {
            // textAlign: isHebrewFont(props?.value?.toString() ?? '') ? 'right' : 'left',
            direction: isRtl ? (isHebrew ? 'ltr' : 'rtl') : isHebrew ? 'rtl' : 'ltr',
            fontFamily: 'Noto Sans Hebrew',
            ...autoFillColor,
          },
        }}
        inputProps={{ min: 0 }}
        InputProps={{
          sx: {
            maxHeight: big ? '143px' : '49px',
            borderRadius: '12px',
            borderColor: palette.error.main,
            color: 'black',
          },
        }}
        inputRef={ref}
        color="primary"
        {...props}
      />
    </Box>
  );
});

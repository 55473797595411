import { Box } from '@mui/material';
import { BaseExportToExcel, BaseTable, PagePreloader } from 'components';
import { t } from 'i18next';
import { useEffect, useMemo, useState, useTransition } from 'react';
import { useGetCustomers } from 'shared/api/useGetCustomers';
import { action_start, refresh } from 'shared/assets';
import { useAppSelector } from 'shared/hooks';
import localStorageService from 'shared/localStorage';
import { BaseBtn, BaseCheckbox, Icon, Row, TotalRows, Text } from 'shared/ui';
import { ChangeFotaSettings, TableFotaHeader } from './components';
import { All_FOTA_TABLE_TITLES, FOTA_TABLE_TITLES, TRANSLATE_COLUMNS } from './constants';
import { Fota } from './fotaModels';
import { useGetAllFota, useSearchFota } from './hooks';
import { useFormatXlsxData } from './hooks/useFormatXlsxData';
export const FotaManager = () => {
  const { getFotaTypes, getFotaFiles } = useGetAllFota();
  const { allFota } = useAppSelector((st) => st.fota);
  const [selectedFota, setSelectedFota] = useState<Fota>(null);
  const [isShowMoreSelected, setIsShowMoreSelected] = useState(false);

  const [isMultiChangeFotaSettings, setIsMultiChangeFotaSettings] = useState(false);
  const [isShowChangeFotaSettingsModal, setIsShowChangeFotaSettingsModal] = useState(false);
  const [selectedFotaItems, setSelectedFotaItems] = useState([]);
  const [fotaChanged, setFotaChanged] = useState(false);

  const { formatTable } = useFormatXlsxData();
  const { searchValue, changeSearch } = useSearchFota();
  const [isPending, startTransition] = useTransition();
  const { getCustomer } = useGetCustomers();

  const [loading, setLoading] = useState(false);
  const currentLang = localStorageService.currentLang;
  useEffect(() => {
    getCustomer();
    getFotaTypes();
    getFotaFiles();
  }, []);
  const rowSelectionModelChange = (value) => {
    setSelectedFotaItems(value);
  };
  const handleOpenChangeFotaSettingsEditModal = (value: Fota) => {
    setSelectedFota(value);
    setIsMultiChangeFotaSettings(false);
    setIsShowChangeFotaSettingsModal(true);
  };
  const handleOpenMultipleChangeFotaSettingsEditModal = () => {
    if (selectedFotaItems.length === 1) {
      const selectedFota = allFota.find((item) => item.hwid === selectedFotaItems[0]);
      handleOpenChangeFotaSettingsEditModal(selectedFota);
      return;
    }
    setSelectedFota(null);
    // setSelectedFota(value);
    //if()
    setIsMultiChangeFotaSettings(true);
    setIsShowChangeFotaSettingsModal(true);
  };
  const onSelectedFilter = () => {
    startTransition(() => { });
  };
  const handleSetLoading = (value) => {
    setLoading(value);
  };
  const getSearchedFota = (fota: Fota[], searchValue: string) => {
    const searched = fota.filter((c) => {
      const values = Object.values(c);
      return values.some((v) => v?.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase().trim()));
    });
    return searched;
  };
  const tableFota = useMemo(() => {
    const searchedFota = getSearchedFota(allFota, searchValue);
    return searchedFota;
  }, [searchValue, allFota]);
  const changeIsShowMoreSelected = () => {
    setIsShowMoreSelected(!isShowMoreSelected);
  };
  const closeChangeFotaSettingsModal = () => {
    setIsShowChangeFotaSettingsModal(false);
  };
  const handleuUpdateFota = () => {
    setFotaChanged(true);
  };
  const fotaBaseTable = useMemo(
    () => (
      <BaseTable
        tableTitles={isShowMoreSelected ? All_FOTA_TABLE_TITLES : FOTA_TABLE_TITLES}
        tableItems={tableFota}
        translateColumns={TRANSLATE_COLUMNS}
        loadedItems={!loading}
        selectedRowID={selectedFota?.hwid}
        idField={'hwid'}
        noItemsText={t('no_fota_grid_table')}
        headerItemsPrefix={'_fota_grid_table'}
        height="calc(100vh - 430px)"
        maxHeight="calc(100vh - 430px)"
        dateTimeColumnIndexes={[10, 12, 14, 15, 31, 39]}
        linkIndex={[4]}
        cellClick={handleOpenChangeFotaSettingsEditModal}
        checkboxSelection
        onRowSelectionModelChange={rowSelectionModelChange}
        hideLoader
        loading={loading || isPending}
      />
    ),
    [tableFota, isShowMoreSelected, selectedFota, loading, isPending]
  );





  const fotaTableFotaHeader = useMemo(
    () => (
      <TableFotaHeader
        searchValue={searchValue}
        setLoading={handleSetLoading}
        changeSearch={changeSearch}
        onSelectFilter={onSelectedFilter}
        fotaChanged={fotaChanged}
        fotaUpdated={() => setFotaChanged(false)}
      />
    ),
    [tableFota, searchValue, fotaChanged]
  );

  const BaseExportToExcelComponent = useMemo(
    () => (<BaseExportToExcel
      tableData={tableFota}
      sheetName="FOTA"
      fileName="fota.xlsx"
      buttonText={t('export_button_fota')}
      dateColumnIndexes={[10, 12, 14, 30, 38]}
      formattedTable={formatTable(tableFota)}
      tableTitles={All_FOTA_TABLE_TITLES}
      headerItemsPrefix={'_fota_grid_table'}
    />)
    ,
    [tableFota]
  );

  return (
    <>
      <PagePreloader loading={loading || isPending} />
      <Box>
        {fotaTableFotaHeader}
        <Box sx={{ height: '1px', backgroundColor: 'primary.main', mb: '16px' }} />
        <Row spacing="20px" alignItems="flex-end" justifyContent="space-between">
          <BaseBtn onClick={handleOpenMultipleChangeFotaSettingsEditModal} disabled={!selectedFotaItems.length}>
            {t('fota_change_status_action_start')}
            <Icon sx={{ ml: '6px' }} src={action_start} />
          </BaseBtn>
          <BaseBtn
            onClick={handleuUpdateFota}
            sx={{ mt: '16px', height: '29px' }}
          >
            <Row >

              <Icon src={refresh} />
              <Text
                noWrap
                sx={{
                  fontSize: '14px',
                  height: '24px',
                  margin: '0 4px',
                  fontFamily: 'Noto Sans Hebrew !@important',
                }}
              >
                {t('refresh_fota_button')}
              </Text>
            </Row>
          </BaseBtn>

          <Row sx={{ flexGrow: 1 }}></Row>
          <BaseCheckbox
            label={t('show_more_fota_table')}
            value={isShowMoreSelected}
            onChange={changeIsShowMoreSelected}
          />
          <TotalRows count={tableFota.length} />
          {BaseExportToExcelComponent}

        </Row>
        <Box>{fotaBaseTable}</Box>
      </Box>

      <ChangeFotaSettings
        onCloseModal={closeChangeFotaSettingsModal}
        openModal={isShowChangeFotaSettingsModal}
        selectedFota={selectedFota}
        isMultiChangeFotaSettings={isMultiChangeFotaSettings}
        selectedFotaItems={selectedFotaItems}
        setLoading={handleSetLoading}
        allFota={allFota}
        updateFota={handleuUpdateFota}
      ></ChangeFotaSettings>
    </>
  );
};

import { Tooltip, tooltipClasses, styled, TooltipProps } from '@mui/material';
export const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} disableInteractive={true} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    width: '400px',
    height: 'auto',
    color: 'red',
    fontSize: '14px',
    fontWeight: 'normal',
    borderRadius: 4,
    border: '1px solid red',
    overflowY: 'auto',
  },
}));

import { useAppSelector, useExtendedTranslation } from 'shared/hooks';
import { useState } from 'react';
import { DeviceModelTypes } from 'shared/models/controllerModels';
import { SelectChangeEvent } from '@mui/material';
import { FormattedGraphData, formatDtoDate } from 'shared/lib';
import { UserActions, ValvesReportView } from 'features/ControllerManager/controllerModels';
import { useEventHistory } from 'features/ControllerManager/hooks';
import { useBatteryStatusReport } from 'shared/api';
import { useFormattedGraphData } from 'shared/lib';
export const useFilter = () => {
  const { t } = useExtendedTranslation();
  const [irrigationChartData, setIrrigationChartData] = useState<ValvesReportView[]>(null);
  const [userActionsEventIDs, setUserActionsEventIDs] = useState<number[]>(null);

  const [userActionsChartData, setUserActionsChartData] = useState<UserActions[]>(null);
  const [batteryVoltageChartData, setBatteryVoltageChartData] = useState<FormattedGraphData[]>([]);
  const { isLoading, getControllerEventHistory, getUserActions } = useEventHistory();
  const { getBatteryStatusReportProject } = useBatteryStatusReport();
  const [isLoadingGraphBatteryStatusReport, setIsLoadingGraphBatteryStatusReport] = useState(false);

  const datesIntervalItems = [
    {
      value: 'today',
      description: t('today_interval_description'),
      label: t('today_interval_filter_item'),
    },
    {
      value: 'from yesterday',
      description: t('from_yesterday_interval_description'),
      label: t('from_yesterday_interval_filter_item'),
    },
    {
      value: 'current week',
      description: t('current_week_interval_description'),
      label: t('current_week_interval_filter_item'),
    },
    {
      value: 'previous week',
      description: t('previous_week_interval_description'),
      label: t('previous_week_interval_filter_item'),
    },
    {
      value: 'last two weeks',
      description: t('last_two_weeks_interval_description'),
      label: t('last_two_weeks_interval_filter_item'),
    },
    {
      value: 'current month',
      description: t('current_month_interval_description'),
      label: t('current_month_interval_filter_item'),
    },
    {
      value: 'previous month',
      description: t('previous_month_interval_description'),
      label: t('previous_month_interval_filter_item'),
    },
    {
      value: 'last two months',
      description: t('last_two_months_interval_description'),
      label: t('last_two_months_interval_filter_item'),
    },
    {
      value: 'last six months',
      description: t('last_six_months_interval_description'),
      label: t('last_six_months_interval_filter_item'),
    },
    {
      value: 'current year',
      description: t('current_year_interval_description'),
      label: t('current_year_interval_filter_item'),
    },
  ];

  const graphTypes = {
    I1: [
      {
        value: 'battery voltage',
        label: t('eventlog_gr_I1_battery_voltage_graph_type'),
      },
      {
        value: 'communication Status',
        label: t('eventlog_gr_I1_communication_status_graph_type'),
      },
      {
        value: 'irrigation',
        label: t('eventlog_gr_I1_irrigation_graph_type'),
      },
      {
        value: 'custom graph 1',
        label: t('eventlog_gr_I1_custom_graph_1'),
      },
    ],
    IP: [
      {
        value: 'battery voltage',
        label: t('eventlog_gr_IP_battery_voltage_graph_type'),
      },
      {
        value: 'communication Status',
        label: t('eventlog_gr_IP_communication_status_graph_type'),
      },
      {
        value: 'slave Operations',
        label: t('eventlog_gr_IP_slave_operations_graph_type'),
      },
      {
        value: 'total Irrigation',
        label: t('eventlog_gr_IP_total_irrigation_graph_type'),
      },
    ],
    WE: [],
  };

  const [selectedStartDate, setSelectedStartDate] = useState<string>('');
  const [selectedEndDate, setSelectedEndDate] = useState<string>('');
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [selectedGraphType, setSelectedGraphType] = useState<string>('');

  const [datesIntervalFilter, setDatesIntervalFilter] = useState<string>('');
  const onChangeGraphType = (e: SelectChangeEvent<string>) => {
    const value = e.target.value;
    setSelectedGraphType(value);
  };

  const onChangeStartDate = (value: string) => {
    setStartDate(value);
  };
  const onChangeEndDate = (value: string) => {
    setEndDate(value);
  };
  const onChangeSelectedStartDate = (value: string) => {
    setDatesIntervalFilter('');
    setSelectedStartDate(value);
  };
  const onChangeSelectedEndDate = (value: string) => {
    setDatesIntervalFilter('');
    setSelectedEndDate(value);
  };
  const getGraphTypes = (modelId: DeviceModelTypes) => {
    return graphTypes[modelId] ? graphTypes[modelId] : [];
  };
  const getInterval = (value) => {
    const today = new Date();
    switch (value) {
      case 'today': {
        const startDate = formatDtoDate(today);
        const endDate = formatDtoDate(today);
        setSelectedEndDate(endDate);
        setSelectedStartDate(startDate);
        return { startDate, endDate };
      }
      case 'from yesterday': {
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);
        const startDate = formatDtoDate(yesterday);
        const endDate = formatDtoDate(today);
        setSelectedEndDate(endDate);
        setSelectedStartDate(startDate);
        return { startDate, endDate };
      }
      case 'current week': {
        const firstDayOfWeek = new Date(today);
        const dayOfWeek = firstDayOfWeek.getDay();
        firstDayOfWeek.setDate(today.getDate() - dayOfWeek);
        const startDate = formatDtoDate(firstDayOfWeek);
        const endDate = formatDtoDate(today);
        setSelectedEndDate(endDate);
        setSelectedStartDate(startDate);
        return { startDate, endDate };
      }

      case 'previous week': {
        const firstDayOfPreviousWeek = new Date(today);
        const dayOfWeek = firstDayOfPreviousWeek.getDay();
        firstDayOfPreviousWeek.setDate(today.getDate() - dayOfWeek - 7); //

        const lastDayOfPreviousWeek = new Date(firstDayOfPreviousWeek);
        lastDayOfPreviousWeek.setDate(firstDayOfPreviousWeek.getDate() + 6); //

        const startDate = formatDtoDate(firstDayOfPreviousWeek);
        const endDate = formatDtoDate(lastDayOfPreviousWeek);
        setSelectedEndDate(endDate);
        setSelectedStartDate(startDate);
        return { startDate, endDate };
      }
      case 'last two weeks': {
        const firstDayOfPreviousWeek = new Date(today);
        const dayOfWeek = firstDayOfPreviousWeek.getDay();
        firstDayOfPreviousWeek.setDate(today.getDate() - dayOfWeek - 7);

        const startDate = formatDtoDate(firstDayOfPreviousWeek);
        const endDate = formatDtoDate(today);

        setSelectedEndDate(endDate);
        setSelectedStartDate(startDate);
        return { startDate, endDate };
      }
      case 'current month': {
        const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        const startDate = formatDtoDate(firstDayOfMonth);
        const endDate = formatDtoDate(today);
        setSelectedEndDate(endDate);
        setSelectedStartDate(startDate);
        return { startDate, endDate };
      }
      case 'previous month': {
        const firstDayOfPreviousMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        const lastDayOfPreviousMonth = new Date(today.getFullYear(), today.getMonth(), 0);
        const startDate = formatDtoDate(firstDayOfPreviousMonth);
        const endDate = formatDtoDate(lastDayOfPreviousMonth);
        setSelectedEndDate(endDate);
        setSelectedStartDate(startDate);
        return { startDate, endDate };
      }
      case 'last two months': {
        const firstDayOfPreviousMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        const startDate = formatDtoDate(firstDayOfPreviousMonth);
        const endDate = formatDtoDate(today);
        setSelectedEndDate(endDate);
        setSelectedStartDate(startDate);
        return { startDate, endDate };
      }
      case 'last six months': {
        const firstDayOfSixthMonthAgo = new Date(today.getFullYear(), today.getMonth() - 5, 1);
        const startDate = formatDtoDate(firstDayOfSixthMonthAgo);
        const endDate = formatDtoDate(today);
        setSelectedEndDate(endDate);
        setSelectedStartDate(startDate);
        return { startDate, endDate };
      }
      case 'current year': {
        const firstDayOfCurrentYear = new Date(today.getFullYear(), 0, 1);
        const startDate = formatDtoDate(firstDayOfCurrentYear);
        const endDate = formatDtoDate(today);
        setSelectedEndDate(endDate);
        setSelectedStartDate(startDate);
        return { startDate, endDate };
      }
      default:
        setSelectedEndDate('');
        setSelectedStartDate('');
        return { startDate, endDate };
    }
  };
  const changeDatesIntervalFilter = (value: string) => {
    setDatesIntervalFilter(value);

    const result = getInterval(value);
    setEndDate(result.endDate);
    setStartDate(result.startDate);
    return result;
  };

  const clearData = () => {
    setSelectedGraphType('');
    setStartDate('');
    setEndDate('');
  };
  const getGraphData = async (params: {
    deviceId: number;
    selectedGraphType: string;
    selectedStartDate: string;
    selectedEndDate: string;
  }) => {
    const { deviceId, selectedGraphType, selectedStartDate, selectedEndDate } = params;
    if (selectedGraphType == 'irrigation' || selectedGraphType === 'total Irrigation') {
      const endDatePlusDay = new Date(selectedEndDate + 'T00:00:00');

      endDatePlusDay.setDate(endDatePlusDay.getDate() + 1);
      endDatePlusDay.setHours(0, 0, 0, 0);

      //	endDatePlusDay = new Date(endDatePlusDay.getTime() + 24 * 60 * 60 * 1000);

      const graphData = await getControllerEventHistory({
        startDate: selectedStartDate,
        endDate: formatDtoDate(endDatePlusDay),
        deviceId: deviceId,
      });
      const userActionsData = await getUserActions({
        startDate: selectedStartDate,
        endDate: formatDtoDate(endDatePlusDay),
        deviceId: deviceId,
      });

      const userActionsDataMapped = userActionsData.data.map((item) => ({
        objectID: item.objectID,
        eventID: item.eventID,
        eventDate: item.actionDate,
        dataIndex: 0,
        dataSource: 1,
        dataValue: null,
        eventDescription_tmp: item.eventDescription_tmp,
        userName: item.userName,
      }));
      const userActionsEventIDs = userActionsData.data
        .map((action) => action.eventID)
        .filter((value, index, self) => self.indexOf(value) === index);
      const allData = [...userActionsDataMapped, ...graphData.data];
      setUserActionsEventIDs(userActionsEventIDs);
      setIrrigationChartData(allData);
    }

    if (selectedGraphType == 'battery voltage') {
      setIsLoadingGraphBatteryStatusReport(true);
      const filterGraph = { fromDate: selectedStartDate, toDate: selectedEndDate, deviceId: deviceId };
      const data = await getBatteryStatusReportProject(filterGraph);
      const graphData = data ? data.data : [];
      const formattedData = useFormattedGraphData(graphData, { fromDate: selectedStartDate, toDate: selectedEndDate });

      setBatteryVoltageChartData(formattedData);
      setIsLoadingGraphBatteryStatusReport(false);
    }
  };
  const checkIsLoading = () => {
    return isLoading || isLoadingGraphBatteryStatusReport;
  };
  const getGraphDataByDefaultDefault = (params: { deviceId: number; type: string; datesInterval: string }) => {
    const { deviceId, type, datesInterval } = params;
    const { startDate, endDate } = changeDatesIntervalFilter(datesInterval);

    getGraphData({ deviceId, selectedGraphType: type, selectedStartDate: startDate, selectedEndDate: endDate });
  };
  return {
    batteryVoltageChartData,
    setBatteryVoltageChartData,

    irrigationChartData,
    setIrrigationChartData,

    userActionsChartData,
    setUserActionsChartData,

    onChangeSelectedStartDate,
    selectedStartDate,

    onChangeSelectedEndDate,
    selectedEndDate,

    onChangeStartDate,
    startDate,

    onChangeEndDate,
    endDate,

    datesIntervalItems,
    changeDatesIntervalFilter,
    datesIntervalFilter,

    selectedGraphType,
    onChangeGraphType,
    getGraphTypes,
    clearData,
    getGraphData,
    checkIsLoading,
    getGraphDataByDefaultDefault,

    userActionsEventIDs,
    setUserActionsEventIDs,
  };
};

import { useAppSelector, useExtendedTranslation, useAppDispatch } from 'shared/hooks';
import { useState, } from 'react';
import { DataSourceTypes } from 'shared/api';
import { setControllers } from 'shared/slices';
import {  DeviceModelTypes } from 'shared/models';
export const useFilterControllers = () => {
  const { t } = useExtendedTranslation();
   const { customers } = useAppSelector((st) => st.customers);
   const customerFilterItems = customers.map((s) => ({
    value: s.id.toString(),
    label: s.customerName,
  }));
  // const { customerIDForFilter } = useAppSelector((st) => st.controllers);
   const dispatch = useAppDispatch();
  const clearControllers=()=>{
    dispatch(setControllers(null));
  }
  const sourceTypesFilterItems = [
    {
      value: '0',
      label: t('realtime_source_type'),
    },
    {
      value: '1',
      label: t('history_source_type'),
    },
  ];
  const [sourceTypeFilter, setSourceTypeFilter] = useState<DataSourceTypes>(1);
  const onChangeSourceTypeFilter = (value: string) =>{
   setSourceTypeFilter(parseInt(value))
   clearControllers()
  };


  const modelTypesFilterItems = [
    {
      value: 'I1',
      label: t('I1_model_type'),
    },
    {
      value: 'IP',
      label: t('IP_model_type'),
    },
    {
      value: 'WE',
      label: t('WE_model_type'),
    },
  ];
  const [modelTypeFilter, setModelTypeFilter] = useState<DeviceModelTypes>('I1');
  const onChangeModelTypeFilter = (value: DeviceModelTypes) =>{
    clearControllers()
    setModelTypeFilter(value)
  };


  const [selectedCustomer, setSelectedCustomer] = useState<string>('67');
  const onChangeSelectedCustomer = (value: string) =>{ 
    clearControllers()
    return setSelectedCustomer(value)
  }
    ;


  return {
    sourceTypeFilter,
    onChangeSourceTypeFilter,
    sourceTypesFilterItems,
    modelTypeFilter,
    onChangeModelTypeFilter,
    modelTypesFilterItems,
    customerFilterItems,
    selectedCustomer,
    onChangeSelectedCustomer
  };
};

import { FC, memo, useEffect, useRef, useState } from 'react';
import { Controller, DeviceModelTypes } from 'shared/models';
import {
  TABLE_TITLES,
  alarmStatusValueGetter,
  batteryVoltageValueGetter,
  controllerTimeValueGetter,
  dataSourceValueGetter,
  syncDateValueGetter,
  currentFlowValueGetter,
  totalSlavesValueGetter,
  rainRateValueGetter,
} from './constants';
import { RealTimeControllerItem } from 'entities_';
import { BigTitle } from 'shared/ui';
import { useExtendedTranslation } from 'shared/hooks';
import { DataGridPro, GridColDef, GridColumnHeaders, useGridApiRef, GridComparatorFn } from '@mui/x-data-grid-pro';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
interface Props {
  tableControllers: Controller[];
  modelTypeFilter: DeviceModelTypes;
}
export type TableTitle = {
  title: string;
  key: string;
  comparator?: GridComparatorFn;
  valueGetter?: FC;
  width: number | string;
};
export const RealTimeControllerTable: FC<Props> = memo(({ tableControllers, modelTypeFilter }) => {
  const { t } = useExtendedTranslation();
  const apiRef = useGridApiRef();
  const tableRef = useRef<HTMLTableElement | null>(null);
  const [tableTitles, setTableTitles] = useState<TableTitle[]>([]);
  useEffect(() => {
    createTableTitles();
  }, []);
  const MemoizedRow = memo(RealTimeControllerItem);
  const MemoizedColumnHeaders = GridColumnHeaders;
  const [unitWidth, setUnitWidth] = useState(0);

  const createTableTitles = () => {
    const tableTitles = [...TABLE_TITLES];

    switch (modelTypeFilter) {
      case 'I1':
        tableTitles.push({
          title: 'alarm_status',
          key: 'alarmStatus',
          valueGetter: alarmStatusValueGetter,
          width: '100%',
        });
        tableTitles.push({
          title: 'battery_voltage',
          key: 'batteryVoltage',
          valueGetter: batteryVoltageValueGetter,
          width: '100%',
        });
        tableTitles.push({
          title: 'controller_real_time',
          key: 'controllerTime',
          valueGetter: controllerTimeValueGetter,
          width: '100%',
        });
        tableTitles.push({
          title: 'data_source',
          key: 'dataSource',
          valueGetter: dataSourceValueGetter,
          width: '100%',
        });
        tableTitles.push({ title: 'sync_date', key: 'syncDate', valueGetter: syncDateValueGetter, width: '100%' });
        break;
      case 'IP':
        tableTitles.push({
          title: 'battery_voltage',
          key: 'batteryVoltage',
          valueGetter: batteryVoltageValueGetter,
          width: '100%',
        });
        tableTitles.push({
          title: 'current_flow',
          key: 'currentFlow',
          valueGetter: currentFlowValueGetter,
          width: '100%',
        });
        tableTitles.push({
          title: 'data_source',
          key: 'dataSource',
          valueGetter: dataSourceValueGetter,
          width: '100%',
        });
        tableTitles.push({ title: 'sync_date', key: 'syncDate', valueGetter: syncDateValueGetter, width: '100%' });
        tableTitles.push({
          title: 'total_slaves_flow',
          key: 'totalSlavesFlow',
          valueGetter: totalSlavesValueGetter,
          width: '100%',
        });
        break;
      case 'WE':
        tableTitles.push({
          title: 'battery_voltage',
          key: 'batteryVoltage',
          valueGetter: batteryVoltageValueGetter,
          width: '100%',
        });
        tableTitles.push({
          title: 'data_source',
          key: 'dataSource',
          valueGetter: dataSourceValueGetter,
          width: '100%',
        });
        tableTitles.push({ title: 'rain_rate', key: 'rainRate', valueGetter: rainRateValueGetter, width: '100%' });
        tableTitles.push({ title: 'sync_date', key: 'syncDate', valueGetter: syncDateValueGetter, width: '100%' });
        break;
    }
    setTableTitles(tableTitles);
    calculateUnitWidth(tableTitles);
  };

  const calculateUnitWidth = (tableTitles: TableTitle[]) => {
    const scroolWidth = 20;
    let screenWidth = window.innerWidth;
    if (tableRef && tableRef.current) {
      screenWidth = tableRef.current.clientWidth - scroolWidth;
    }
    let sumOfStaticWidth = 0;
    let sumOfPercentWidth = 0;
    tableTitles.forEach((title) => {
      if (typeof title.width === 'number') {
        sumOfStaticWidth += title.width;
      } else if (typeof title.width === 'string') {
        sumOfPercentWidth += parseInt(title.width);
        return 20;
      }
    });
    const dinamicWidth = screenWidth - sumOfStaticWidth;
    const unitWidth = dinamicWidth / sumOfPercentWidth;
    setUnitWidth(unitWidth);
  };

  const calculateWidthInPixels = (width: number | string): number => {
    if (typeof width === 'number') {
      return width;
    } else if (typeof width === 'string') {
      return parseInt(width) * unitWidth;
    }
    return 100;
  };

  const columns: GridColDef<Controller>[] = tableTitles.map((title) => ({
    field: title.key,
    headerName: `${t(title.title !== '' ? `${title.title}_real_time_controller_grid_table` : '')}`,
    width: calculateWidthInPixels(title.width),
    headerClassName: 'headerTable',
    valueGetter: (item) => {
      if (title.valueGetter) {
        return title.valueGetter(item);
      }
      return item.value;
    },
  }));

  function CustomSortedAscendingIcon() {
    return <ArrowDropDownIcon />;
  }
  function CustomSortedDescendingIcon() {
    return <ArrowDropUpIcon />;
  }
  function CustomNoRowsOverlay() {
    return <ArrowDropUpIcon />;
  }
  return (
    <>
      <div ref={tableRef} style={{ width: '100%' }}>
        {unitWidth && (
          <>
            <DataGridPro
              {...tableControllers}
              getRowId={(row) => row.deviceId}
              loading={tableControllers.length === 0}
              checkboxSelection={false}
              disableRowSelectionOnClick
              slots={{
                row: (props) => <MemoizedRow {...props} modelTypeFilter={modelTypeFilter} />,
                columnHeaders: MemoizedColumnHeaders,
                columnSortedAscendingIcon: CustomSortedAscendingIcon,
                columnSortedDescendingIcon: CustomSortedDescendingIcon,
                noResultsOverlay: CustomNoRowsOverlay,
              }}
              apiRef={apiRef}
              density={'compact'}
              rows={tableControllers}
              columns={columns}
              disableColumnMenu
              hideFooter
              initialState={{ sorting: { sortModel: [{ field: 'objectName', sort: 'asc' }] } }}
              sx={{
                maxHeight: 'calc(100vh - 265px)',
                maxWidth: '100%',
                borderRadius: '12px',
                position: 'relative',
                mt: '12px',
                pr: '1px',
                bgcolor: 'info.main',
                overflowY: 'auto',
                '.MuiDataGrid-columnHeaders': {
                  backgroundColor: '#0a3e90',
                },
                '.headerTable': {
                  backgroundColor: '#0a3e90',
                  color: 'white',
                  borderRight: '1px solid #7a7a7a',
                  fontWeight: 'bold',
                  pr: '2px',
                  height: '38px',
                  '.MuiDataGrid-columnSeparator': {
                    opacity: '0 !important',
                  },
                  svg: {
                    fill: 'white',
                  },
                },
                '.MuiDataGrid-virtualScrollerRenderZone': {},
                '.MuiDataGrid-virtualScroller': {
                  overflow: `${!tableControllers.length && 'hidden'}`,
                },
                '& .MuiDataGrid-virtualScrollerContent': {
                  marginTop: '-1px',
                },
              }}
            />
            {!tableControllers.length && (
              <BigTitle align="center" sx={{ mt: '20px' }}>
                {t('no_controllers_controllers_grid_table')}
              </BigTitle>
            )}
          </>
        )}
      </div>
    </>
  );
});

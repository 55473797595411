export const CustomizedLegend = (props) => {
  const { payload, selectItem} = props;
  return (
    <div style={{display:'flex', flexDirection: 'column', width:'80px', cursor:'pointer'}}>
      {
        payload.map((entry, index) => (
					<div key={`item-${index}`} onClick={()=>selectItem(entry)} style={{height:'20px', display:'flex',  whiteSpace: 'nowrap'}}>
          <span style={{fontSize:'13px'}}>	<span style={{display:'inline-block', margin:'0 4px', width:'10px', height: '10px', backgroundColor:entry.color}}></span> <span dir="auto">{entry.value} </span>-<span> {entry.payload.value}</span></span>
					</div>
        ))
      }
    </div>
  );
};

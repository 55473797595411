import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Controller, ControllerRegisters, DailyConsumption, ControllerClearDataFilter, DashboardDetails } from 'shared/models';
interface CustomerState {
  controllers: Controller[];
  selectedControllers: number[];
  customerIDForFilter: number | string;
  currentController: Controller | null;
  dailyConsumption: DailyConsumption[];
  controllerClearDataFilter: ControllerClearDataFilter;
  defaultData: { startDate: string; endDate: string };
  controllerClearDataFilterChanged: boolean;
  controllerRegisters: ControllerRegisters;
  initHwId: number;
}

const initialState: CustomerState = {
  controllers: [],
  selectedControllers: [],
  customerIDForFilter: '',
  currentController: null,
  dailyConsumption: null,
  controllerClearDataFilter: {
    startDate: '2023-07-20',
    endDate: '2023-07-29',
    uncontrolledFlow: true,
    actualConsumption: true,
  },
  defaultData: {
    startDate: '',
    endDate: '',
  },
  controllerClearDataFilterChanged: false,
  controllerRegisters: null,
  initHwId: null,
};

const controllerSlice = createSlice({
  name: 'controllers',
  initialState,
  reducers: {
    setControllers: (state, action: PayloadAction<Controller[]>) => {
      state.controllers = action.payload;
    },
    setCustomerFilter: (state, action: PayloadAction<number | string>) => {
      state.customerIDForFilter = action.payload;
    },
    setCurrentController: (state, action: PayloadAction<Controller | null>) => {
      state.currentController = action.payload;
    },
    setUpdateCurrentController : (state, action: PayloadAction<DashboardDetails>)=>{
      state.currentController.customerId=action.payload.customerID
      state.currentController.controllerName=action.payload.objectName
      state.currentController.customerName=action.payload.customerName
      state.currentController.deviceId=action.payload.objectID
      state.currentController.hwId=action.payload.hwid
      state.currentController.modelId=action.payload.modelID
      state.currentController.objectName=action.payload.objectName
      state.currentController.deviceType=action.payload.deviceType
      state.currentController.objectNotes=action.payload.objectNotes
      state.currentController.installAddress=action.payload.installAddress
      state.currentController.latitudeDec=action.payload.latitude_Dec
      state.currentController.longitudeDec=action.payload.longitude_Dec
      state.currentController.installDate=action.payload.installDate
      state.currentController.powerSupCode=action.payload.powerSupCode
    },
    editCurrentController: (state, action: PayloadAction<Controller>) => {
      const editingControllers = action.payload;
      state.controllers = state.controllers.map((c) =>
        c.deviceId === editingControllers.deviceId ? editingControllers : c
      );
    },
    setDailyConsumption: (state, action: PayloadAction<DailyConsumption[]>) => {
      state.dailyConsumption = action.payload;
    },
    clearDailyConsumption: (state) => {
      state.dailyConsumption = [
        {
          date: '',
          totalProg: 0,
          totalActual: 0,
          totalUncontrolled: 0,
        },
      ];
    },
    setDailyConsumptionDefaultData: (state, action: PayloadAction<{ startDate: string; endDate: string }>) => {
      state.defaultData = action.payload;
      state.controllerClearDataFilter = {
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        uncontrolledFlow: true,
        actualConsumption: true,
      };
    },
    setControllerClearDataFilter: (state, action: PayloadAction<ControllerClearDataFilter>) => {
      state.controllerClearDataFilter = action.payload;
      controllerSlice;
    },
    setDailyConsumptionChanged: (state, action: PayloadAction<boolean>) => {
      state.controllerClearDataFilterChanged = action.payload;
    },
    setInitHwId: (state, action: PayloadAction<number>) => {
      state.initHwId = action.payload;
    },
  },
});

export const {
  setControllers,
  setCustomerFilter,
  setCurrentController,
  setUpdateCurrentController,
  editCurrentController,
  setDailyConsumption,
  setDailyConsumptionDefaultData,
  setControllerClearDataFilter,
  setDailyConsumptionChanged,
  clearDailyConsumption,
  setInitHwId,
} = controllerSlice.actions;

export const controllerReducer = controllerSlice.reducer;

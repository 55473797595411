import { createSlice } from '@reduxjs/toolkit';

interface ModalState {
  openAddCustomer: boolean;
  openEditCustomer: boolean;
  openDeleteCustomer: boolean;
  openAddController: boolean;
  openEditController: boolean;
  openDeleteController: boolean;
  openDeviceTransferring: boolean;
  openUpdateDeviceTransferring: boolean;
  openAbout: boolean;
  openShowGraphModal: boolean;
  openImportSim: boolean;
  openEditSim: boolean;
  openResetPasswordModal: boolean;
  openAskUpdatePasswordModal: boolean;
  openNewProviderEditModal: boolean;
  openMapEditModal: boolean;
  openEditControllerClearDataModal: boolean;
  openEditServiceCall: boolean;
  openAddServiceCall: boolean;
  openEditPermissionsModal: boolean;
  openNewAssociateAccount: boolean;
  openDeleteAssociateAccount: boolean;
  openNetworkProtectionCreateModal: boolean;
  openNetworkProtectionEditModal: boolean;
  openNewConnectedController:boolean;
  openEditConnectedController: boolean;
  openNotSupportModal: boolean;
}

const initialState: ModalState = {
  openAddCustomer: false,
  openEditCustomer: false,
  openDeleteCustomer: false,
  openAddController: false,
  openEditController: false,
  openDeleteController: false,
  openDeviceTransferring: false,
  openUpdateDeviceTransferring: false,
  openAbout: false,
  openShowGraphModal: false,
  openImportSim: false,
  openEditSim: false,
  openResetPasswordModal: false,
  openAskUpdatePasswordModal: false,
  openNewProviderEditModal: false,
  openMapEditModal: false,
  openEditControllerClearDataModal: false,
  openEditServiceCall: false,
  openAddServiceCall: false,
  openEditPermissionsModal: false,
  openNewAssociateAccount: false,
  openDeleteAssociateAccount: false,
  openNetworkProtectionCreateModal: false,
  openNetworkProtectionEditModal: false,
  openNewConnectedController: false,
  openEditConnectedController:false,
  openNotSupportModal: false
};

const modalSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    toggleAddCustomer: (state) => {
      state.openAddCustomer = !state.openAddCustomer;
    },
    toggleEditCustomer: (state) => {
      state.openEditCustomer = !state.openEditCustomer;
    },
    toggleDeleteCustomer: (state) => {
      state.openDeleteCustomer = !state.openDeleteCustomer;
    },
    toggleAddController: (state) => {
      state.openAddController = !state.openAddController;
    },
    toggleEditController: (state) => {
      state.openEditController = !state.openEditController;
    },
    toggleDeleteController: (state) => {
      state.openDeleteController = !state.openDeleteController;
    },
    toggleDeviceTransferring: (state) => {
      state.openDeviceTransferring = !state.openDeviceTransferring;
    },
    toggleUpdateDeviceTransferring: (state) => {
      state.openUpdateDeviceTransferring = !state.openUpdateDeviceTransferring;
    },
    toggleOpenAbout: (state) => {
      state.openAbout = !state.openAbout;
    },
    toggleOpenShowGraphModal: (state) => {
      state.openShowGraphModal = !state.openShowGraphModal;
    },
    toggleImportSim: (state) => {
      state.openImportSim = !state.openImportSim;
    },
    toggleEditSim: (state) => {
      state.openEditSim = !state.openEditSim;
    },
    toggleCreateProviderModal: (state) => {
      state.openNewProviderEditModal = !state.openNewProviderEditModal;
    },
    toggleOpenMapEditModal: (state) => {
      state.openMapEditModal = !state.openMapEditModal;
    },
    toggleOpenEditControllerClearDataModal: (state) => {
      state.openEditControllerClearDataModal = !state.openEditControllerClearDataModal;
    },
    toggleEditServiceCall: (state) => {
      state.openEditServiceCall = !state.openEditServiceCall;
    },
    toggleAddServiceCall: (state) => {
      state.openAddServiceCall = !state.openAddServiceCall;
    },
    toggleNetworkProtectionCreateModal: (state) => {
      state.openNetworkProtectionCreateModal = !state.openNetworkProtectionCreateModal;
    },
    toggleNetworkProtectionEditModal: (state) => {
      state.openNetworkProtectionEditModal = !state.openNetworkProtectionEditModal;
    },
    toggleNewConnectedController: (state) => {
      state.openNewConnectedController = !state.openNewConnectedController;
    },
    toggleEditConnectedController: (state) => {
      state.openEditConnectedController = !state.openEditConnectedController;
    },
    toggleOpenNotSupportModal: (state) => {
      state.openNotSupportModal = !state.openNotSupportModal;
    },
  },
});

export const {
  toggleAddCustomer,
  toggleEditCustomer,
  toggleDeleteCustomer,
  toggleAddController,
  toggleEditController,
  toggleDeleteController,
  toggleDeviceTransferring,
  toggleUpdateDeviceTransferring,
  toggleOpenAbout,
  toggleOpenShowGraphModal,
  toggleImportSim,
  toggleEditSim,
  toggleCreateProviderModal,
  toggleOpenMapEditModal,
  toggleOpenEditControllerClearDataModal,
  toggleEditServiceCall,
  toggleAddServiceCall,
  toggleNetworkProtectionCreateModal,
  toggleNetworkProtectionEditModal,
  toggleNewConnectedController,
  toggleEditConnectedController,
  toggleOpenNotSupportModal
} = modalSlice.actions;

export const modalReducer = modalSlice.reducer;

import { FC, memo, useEffect, useRef, useState } from 'react';
import { ControllerRegisters, ControllerRegistersEntry } from 'shared/models';
import { TABLE_TITLES } from './constants';
import { ControllerRegistersTableItem } from 'entities_';
import { BigTitle } from 'shared/ui';
import { useExtendedTranslation } from 'shared/hooks';
import { DataGridPro, GridColDef, GridColumnHeaders, useGridApiRef } from '@mui/x-data-grid-pro';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Box, CircularProgress } from '@mui/material';
interface Props {
  filteredRegisters: ControllerRegistersEntry[];
  registerTypeFilter: string;
  searchValue: string;
  currentSearchID: number;
}
export const ControllerRegistersTable: FC<Props> = memo(({ filteredRegisters, searchValue, currentSearchID }) => {
  const { t } = useExtendedTranslation();
  const apiRef = useGridApiRef();
  const tableRef = useRef<HTMLTableElement | null>(null);
  useEffect(() => {
    calculateUnitWidth();
  }, []);

  useEffect(() => {
    if (!apiRef || !apiRef.current || !apiRef.current.getRowIndexRelativeToVisibleRows) return;
    const index = apiRef.current.getRowIndexRelativeToVisibleRows(currentSearchID);
    if (index === -1) return;
    apiRef.current.scrollToIndexes({ rowIndex: index });
  }, [currentSearchID]);
  const MemoizedRow = memo(ControllerRegistersTableItem);
  const MemoizedColumnHeaders = GridColumnHeaders;
  const [unitWidth, setUnitWidth] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const calculateUnitWidth = () => {
    const scroolWidth = 20;
    let screenWidth = window.innerWidth;
    if (tableRef && tableRef.current) {
      screenWidth = tableRef.current.clientWidth - scroolWidth;
    }
    let sumOfStaticWidth = 0;
    let sumOfPercentWidth = 0;
    TABLE_TITLES.forEach((title) => {
      if (typeof title.width === 'number') {
        sumOfStaticWidth += title.width;
      } else if (typeof title.width === 'string') {
        sumOfPercentWidth += parseInt(title.width);
        return 20;
      }
    });
    const dinamicWidth = screenWidth - sumOfStaticWidth;
    const unitWidth = dinamicWidth / sumOfPercentWidth;
    setUnitWidth(unitWidth);
  };

  const calculateWidthInPixels = (width: number | string): number => {
    if (typeof width === 'number') {
      return width;
    } else if (typeof width === 'string') {
      return parseInt(width) * unitWidth;
    }
    return 100;
  };

  const columns = TABLE_TITLES.map((title) => ({
    field: title.key,
    headerName: `${t(title.title !== '' ? `${title.title}_controller_registers_grid_table` : '')}`,
    width: calculateWidthInPixels(title.width),
    headerClassName: 'headerTable',
  }));
  function CustomSortedAscendingIcon() {
    return <ArrowDropDownIcon />;
  }
  function CustomSortedDescendingIcon() {
    return <ArrowDropUpIcon />;
  }
  function CustomNoRowsOverlay() {
    return <ArrowDropUpIcon />;
  }
  return (
    <>
      <div ref={tableRef} style={{ width: '100%' }}>
        {/* <Box onClick={supperSearch}>supper Search</Box> */}
        {unitWidth && (
          <>
            <DataGridPro
              {...filteredRegisters}
              getRowId={(row) => row.eventID}
              checkboxSelection={false}
              disableRowSelectionOnClick
              slots={{
                row: (props) => <MemoizedRow searchValue={searchValue} currentSearchID={currentSearchID} {...props} />,
                columnHeaders: MemoizedColumnHeaders,
                columnSortedAscendingIcon: CustomSortedAscendingIcon,
                columnSortedDescendingIcon: CustomSortedDescendingIcon,
                noResultsOverlay: CustomNoRowsOverlay,
              }}
              apiRef={apiRef}
              density={'compact'}
              rows={filteredRegisters}
              columns={columns}
              disableColumnMenu
              hideFooter
              initialState={{
                sorting: {
                  sortModel: [
                    { field: 'regNumber', sort: 'asc' },
                    { field: 'bitNumber', sort: 'asc' },
                  ],
                },
              }}
              sx={{
                maxHeight: 'calc(100vh - 365px)',
                maxWidth: '100%',
                borderRadius: '12px',
                position: 'relative',
                pr: '1px',
                bgcolor: 'info.main',
                overflowY: 'auto',
                '.MuiDataGrid-columnHeaders': {
                  backgroundColor: '#0a3e90',
                },
                '.headerTable': {
                  backgroundColor: '#0a3e90',
                  color: 'white',
                  borderRight: '1px solid #7a7a7a',
                  fontWeight: 'bold',
                  pr: '2px',
                  height: '32px',
                  '.MuiDataGrid-columnSeparator': {
                    opacity: '0 !important',
                  },
                  svg: {
                    fill: 'white',
                  },
                },
                '.MuiDataGrid-virtualScrollerRenderZone': {},
                '.MuiDataGrid-virtualScroller': {
                  overflow: `${!filteredRegisters?.length && 'hidden'}`,
                },
                '& .MuiDataGrid-virtualScrollerContent': {
                  marginTop: '-1px',
                },
                '.MuiBadge-anchorOriginTopRightRectangular': {
                  display: 'none',
                },
              }}
            />
          </>
        )}
      </div>
    </>
  );
});

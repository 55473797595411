import { Box, Stack, IconButton } from '@mui/material';
import { Row } from 'shared/ui';
import { t } from 'i18next';
import { FC, useMemo, useState } from 'react';
import { close_popup } from 'shared/assets';
import { BigTitle, Modal, BaseInput, Loader, BaseSearch } from 'shared/ui';
import { Filter, ControllerRegistersTable } from 'features';
import { useFilterRegisterType, useSearch } from './hooks';
import { Controller, ControllerRegisters, ControllerRegistersEntry } from 'shared/models';
interface ModalState {
  isOpenModal: boolean;
  closeModal: () => void;
  currentController: Controller;
  controllerRegisters: ControllerRegisters;
}
interface ModalState {
  isOpenModal: boolean;
  closeModal: () => void;
}

export const EditRealTimeControllerRegisters: FC<ModalState> = ({
  isOpenModal,
  closeModal,
  currentController,
  controllerRegisters,
}) => {
  const { registerTypeFilter, onChangeRegisterTypeFilter, registerTypeFilterItems } = useFilterRegisterType();

  const handleCloseEditModal = () => {
    clearSearch();
    closeModal();
    onChangeRegisterTypeFilter('');
  };
  const filteredRegisters = useMemo(() => {
    if (!controllerRegisters) return null;
    if (!registerTypeFilter) return [].concat(...Object.values(controllerRegisters));

    return controllerRegisters[registerTypeFilter];
  }, [controllerRegisters, registerTypeFilter]);
  const {
    changeSearch,
    searchValue,
    clearSearch,
    onPreviousItemSearch,
    onNextItemSearch,
    numberOfResults,
    itemNumber,
    currentSearchID,
  } = useSearch(filteredRegisters);
  return (
    <Modal open={isOpenModal} onClose={handleCloseEditModal}>
      <Box>
        <Stack direction="row" sx={{ mb: '16px' }} justifyContent="space-between" alignItems="center">
          <BigTitle>{currentController?.objectName}</BigTitle>
          <IconButton onClick={handleCloseEditModal}>
            <img src={close_popup} />
          </IconButton>
        </Stack>
        <Row spacing="20px" alignItems="flex-start" justifyContent="space-between">
          <Box width={'25%'}>
            <Filter
              title={`${t('registers_type_filter')}`}
              value={registerTypeFilter}
              onChange={onChangeRegisterTypeFilter}
              items={registerTypeFilterItems}
              hideAllButton
              sx={{ width: '100%' }}
              messageEmptyFilter="registers_all_type_filter"
              placeholder={`${t('registers_type_clear_data_modal')}`}
            />
            <BaseInput
              label={`${t('hwId_registers_modal_label')}`}
              name="hwId"
              disabled
              helperText=""
              value={currentController?.hwId}
              small
            />
            <BaseInput
              label={`${t('device_id_registers_modal_label')}`}
              name="deviceId"
              disabled
              helperText=""
              value={currentController?.deviceId}
              small
            />
            <BaseInput
              label={`${t('customer_id_registers_modal_label')}`}
              name="customerId"
              disabled
              helperText=""
              value={currentController?.customerId}
              small
            />
            <BaseInput
              label={`${t('customer_name_registers_modal_label')}`}
              name="customerName"
              disabled
              helperText=""
              value={currentController?.customerName}
              small
            />
          </Box>
          <Box width={'75%'} height={'calc(100vh - 265px)'}>
            <Box sx={{ mb: '16px' }}>
              <BaseSearch
                numberOfResults={numberOfResults}
                itemNumber={itemNumber}
                onChange={changeSearch}
                value={searchValue}
                onClear={clearSearch}
                onPrevious={onPreviousItemSearch}
                onNext={onNextItemSearch}
              />
            </Box>
            {controllerRegisters ? (
              filteredRegisters && (
                <ControllerRegistersTable
                  registerTypeFilter={registerTypeFilter}
                  filteredRegisters={filteredRegisters}
                  searchValue={searchValue}
                  currentSearchID={currentSearchID}
                />
              )
            ) : (
              <Box height={'calc(100vh - 465px)'}>
                <Loader />
              </Box>
            )}
          </Box>
        </Row>
      </Box>
    </Modal>
  );
};

import { FC } from 'react';
import {  Stack } from '@mui/material';
import './style.css';
export const Loader: FC = () => {
  return (
    <Stack justifyContent="center" alignItems="center" sx={{ width: '100%', height: '100%' }}>
       <div className="loader"></div>
    </Stack>
  );
};

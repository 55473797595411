import { useTranslation } from 'react-i18next';
import { PowerProblemsItem } from '../PowerProblemsReportModels';
export const useFormatXlsxData = () => {
  const { t } = useTranslation();
  const formatTable = (arr: PowerProblemsItem[]) => {
    const exportArr = arr.map((item) => {
      return {
        [t('date_from_power_problem_grid_table')]: item.datE_FROM,
        [t('date_to_power_problem_grid_table')]: item.datE_TO,
        [t('project_id_power_problem_grid_table')]: item.projectID,
        [t('project_name_power_problem_grid_table')]: item.projectName,
        [t('hwid_power_problem_grid_table')]: t(item.hwid),
        [t('object_ID_power_problem_grid_table')]: item.objectID,
        [t('object_name_power_problem_grid_table')]: item.objectName,
        [t('device_type_power_problem_grid_table')]: item.deviceType,
        [t('ctl_ver_power_problem_grid_table')]: item.ctlVer,
        [t('power_sup_code_power_problem_grid_table')]: item.powerSupCode,
        [t('install_date_power_problem_grid_table')]: item.installDate,
        [t('last_open_service_call_date_power_problem_grid_table')]: item.lastOpenServiceCallDate,
        [t('batt_max_power_problem_grid_table')]: item.batT_MAX,
        [t('batt_min_power_problem_grid_table')]: item.batT_MIN,
        [t('total_d_power_problem_grid_table')]: item.totaL_D,
        [t('batt_low_d_power_problem_grid_table')]: item.batT_LOW_D,
        [t('charge_time_low_d_power_problem_grid_table')]: item.chargE_TIME_LOW_D,
        [t('vin_low_d_power_problem_grid_table')]: item.viN_LOW_D,
        [t('no_vin_d_power_problem_grid_table')]: item.nO_VIN_D,
      };
    });

    return exportArr;
  };

  return { formatTable };
};

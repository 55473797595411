import { useAppSelector } from 'shared/hooks';
import dayjs from 'dayjs';
export const useGetDefaultDates = (reportDates: { reportStartDate: string; reportEndDate: string } = null) => {
  const { BATT_REP } = useAppSelector((st) => st.globalSlice);
  if (reportDates && reportDates.reportStartDate) {
    let startDateDayjs = dayjs(reportDates.reportStartDate);
    startDateDayjs = startDateDayjs.subtract(BATT_REP.GRAPH_START_DAYS_AGO, 'day');
    const endDateDayjs = startDateDayjs.add(BATT_REP.GRAPH_INTERVAL_DAYS, 'day');
    const startDate = startDateDayjs.format('YYYY-MM-DD');
    const endDate = endDateDayjs.format('YYYY-MM-DD');
    return {
      startDate,
      endDate,
    };
  } else {
    const dbYday = dayjs();
    const startDateDayjs = dbYday.subtract(BATT_REP.REPORT_START_DAYS_AGO, 'day');
    const startDate = startDateDayjs.format('YYYY-MM-DD');
    const endDateDayjs = startDateDayjs.add(BATT_REP.REPORT_INTERVAL_DAYS, 'day');
    const endDate = endDateDayjs.format('YYYY-MM-DD');
    return {
      startDate,
      endDate,
    };
  }
};

import { BatteryStatusExcludeFilter } from 'shared/models';
import { useAppSelector } from 'shared/hooks';
import localStorageService from 'shared/localStorage';
import dayjs, { Dayjs } from 'dayjs';
export const useGetDefaultParams = () => {
  const { BATT_REP, POW_PROBLEM } = useAppSelector((st) => st.globalSlice);

  const getDataFromLocalStorage = () => {
    const data = localStorageService.PowerProblemReport;
    return data ? JSON.parse(data) : null;
  };

  const localData = getDataFromLocalStorage() || {};

  const dbYday = dayjs();
  const startDateDayjs = dbYday.subtract(localData.daysInTheReport ?? BATT_REP.REPORT_START_DAYS_AGO, 'day');
  const startDate = startDateDayjs.format('YYYY-MM-DD');

  const endDateDayjs = dbYday.subtract(1, 'day');
  const endDate = endDateDayjs.format('YYYY-MM-DD');

  const saveToLocalStorage = (filter: BatteryStatusExcludeFilter) => {
    const keysToCopy = [
      'connectHMin',
      'dayTimeEnd',
      'dayTimeStart',
      'daysPositiveP',
      'nightTimeEnd',
      'nightTimeStart',
      'daysInTheReport',
      'exceptionTypes',
      'batteryLowVoltage',
      'vinLowVoltage',
      'chargingTimeLowMinutes',
      'daysPositivePercentage',
    ];

    const PowerProblemReportData: { [key: string]: any } = {};
    keysToCopy.forEach(key => {
      PowerProblemReportData[key] = filter[key];
    });

    localStorageService.PowerProblemReport = JSON.stringify(PowerProblemReportData);

  };
  const isValidNumber = (value) => {
    return !isNaN(Number(value)) && value !== null && value !== undefined;
  };
  const isValidDaysPositiveP = (value) => {
    const number = Number(value);
    return isValidNumber(value) && number >= 10 && number <= 100 && number % 10 === 0;
  };
  const isValidTime = (value) => {
    const timePattern = /^(\d{1,2}):(\d{2})$/;
    const match = timePattern.exec(value);

    if (!match) return false;

    const [_, hours, minutes] = match;
    const isHoursValid = !isNaN(Number(hours)) && Number(hours) >= 0 && Number(hours) < 24;
    const isMinutesValid = !isNaN(Number(minutes)) && Number(minutes) >= 0 && Number(minutes) < 60;

    return isHoursValid && isMinutesValid;
  };



  const {
    daysInTheReport: storedDaysInTheReport,
    dayTimeStart: storedDayTimeStart,
    dayTimeEnd: storedDayTimeEnd,
    nightTimeStart: storedNightTimeStart,
    nightTimeEnd: storedNightTimeEnd,
    connectHMin: storedConnectHMin,
    batteryLowVoltage = 3.7,
    vinLowVoltage = 4.7,
    chargingTimeLowMinutes = 30,
    daysPositivePercentage: storedDaysPositiveP,
    exceptionTypes = POW_PROBLEM.EXCEPTION_TYPES,
  } = localData;


  const dayTimeStart = isValidTime(storedDayTimeStart) ? storedDayTimeStart : BATT_REP.DAY_TIME_START;
  const dayTimeEnd = isValidTime(storedDayTimeEnd) ? storedDayTimeEnd : BATT_REP.DAY_TIME_END;
  const nightTimeStart = isValidTime(storedNightTimeStart) ? storedNightTimeStart : BATT_REP.NIGHT_TIME_START;
  const nightTimeEnd = isValidTime(storedNightTimeEnd) ? storedNightTimeEnd : BATT_REP.NIGHT_TIME_END;
  const connectHMin = isValidNumber(storedConnectHMin) ? storedConnectHMin : BATT_REP.MIN_CONNECTION_TIME.toString();
  const daysInTheReport = isValidNumber(storedDaysInTheReport) ? storedDaysInTheReport : BATT_REP.REPORT_START_DAYS_AGO;
  const daysPositivePercentage = isValidDaysPositiveP(storedDaysPositiveP) ? storedDaysPositiveP : BATT_REP.DISCONNECTED_DAYS_IN_THE_PERIOD.toString();

  return {
    startDate,
    endDate,
    dayTimeStart,
    dayTimeEnd,
    nightTimeStart,
    nightTimeEnd,
    connectHMin,
    saveToLocalStorage,
    daysInTheReport,
    exceptionTypes,
    batteryLowVoltage,
    vinLowVoltage,
    chargingTimeLowMinutes,
    daysPositivePercentage,
  };
};

import { Customer } from 'shared/models';
import { TableTitle, TranslateColumn } from 'components/BaseTable';

export const TABLE_TITLES: TableTitle<Customer>[] = [
  { title: 'id', key: 'id', width: 40 },
  { title: 'name', key: 'customerName', width: '200%' },
  { title: 'type', key: 'customerTypeId', width: '100%' },
  { title: 'status', key: 'customerStatusId', width: '100%' },
  { title: 'agreement', key: 'customerAgreementStatusId', width: '100%' },
  { title: 'address', key: 'postAddress', width: '200%' },
  { title: 'phone', key: 'officePhoneNumber', width: '100%' },
  { title: 'mail', key: 'eMailAddress', width: '100%' },
  { title: 'created', key: 'createdDate', width: '100%', type:"date" },
  { title: 'started', key: 'operationStartDate', width: '100%',  type:"date"},
  { title: 'notes', key: 'notes', width: '100%' },
  { title: 'contact_name', key: 'contactPersonName', width: '100%' },
  { title: 'contact_phone', key: 'contactPersonPhoneNumber', width: '100%' },
];


export const DATE_COLUMN_INDEXES=[8,9]
import { ServiceCall } from 'shared/models';

export type TableTitle = { title: string; key: keyof ServiceCall; width: number | string };

export const TABLE_TITLES: TableTitle[] = [
  { title: 'service_call_ID', key: 'serviceCallID', width: '100%' },
  { title: 'create_date', key: 'createDate', width: '100%' },
  { title: 'current_status', key: 'currentStatus', width: '100%' },
  { title: 'close_date', key: 'closeDate', width: '100%' },
  { title: 'customer_name', key: 'customerName', width: '100%' },
  { title: 'controller_name', key: 'controllerName', width: '150%' },
  { title: 'open_by_user_name', key: 'openByUserName', width: '100%' },
  { title: 'fault_equipment', key: 'faultEquipment', width: '100%' },
  { title: 'fault_description', key: 'faultDescription', width: '200%' },
  { title: 'notes_for_technician', key: 'notesForTechnician', width: '100%' },
  { title: 'assigned_technician_name', key: 'assignedTechnicianName', width: '100%' },
  { title: 'action_description', key: 'actionDescription', width: '100%' },
  { title: 'taken_action', key: 'takenAction', width: '100%' },
];


import { Box } from "@mui/system";
import { t } from "i18next";
import { FC } from "react";
import { useAppSelector } from "shared/hooks";
import { Controller } from "shared/models";
import { BaseInput, Row } from "shared/ui"

interface Props {
	currentController: Controller;
}

export const ControllerHeader: FC<Props> = ({ currentController }) => {
	const { customerAgreementCodes } = useAppSelector((st) => st.customers);
	const getCustomerAgreementName = (id: number) => {
		const index = customerAgreementCodes.findIndex((item) => item.code === id);
		return customerAgreementCodes[index].text;
	};
	return (<Row spacing="20px" width="100%" alignItems="flex-end" justifyContent="space-between">
		<Box width={'32.5%'}>
			<Row spacing="20px" width="100%" alignItems="flex-end" justifyContent="space-between">
				<BaseInput
					label={`${t('controller_id_edit_modal')}`}
					name="deviceId"
					disabled
					helperText=""
					value={currentController?.deviceId ?? ''}
					onChange={(event) => { }}
					maxWidth={100}
					small
					compact
					blueBorder
				/>
				<BaseInput
					label={`${t('hwId_edit_modal')}`}
					name="hwId"
					disabled
					helperText=""
					value={currentController?.hwId ?? ''}
					onChange={(event) => { }}
					maxWidth={100}
					small
					compact
					blueBorder
				/>
				<BaseInput
					label={`${t('model_id_edit_modal')}`}
					name="modelId"
					disabled
					helperText=""
					value={currentController?.modelId ?? ''}
					onChange={(event) => { }}
					maxWidth={100}
					small
					compact
					blueBorder
				/>
				<BaseInput
					label={`${t('device_type_label')}`}
					name="deviceType"
					disabled
					helperText=""
					value={currentController?.deviceType ? t(`${currentController?.deviceType}_chart_item`) : ''}
					onChange={(event) => { }}
					small
					compact
					blueBorder
				/>
			</Row>
		</Box>
		<Row sx={{ flexGrow: 1 }} />
		<Box width={'20%'}>
			<BaseInput
				label={`${t('objectNotes_edit_modal')}`}
				name="objectNotes"
				disabled
				value={currentController?.objectNotes ?? ''}
				onChange={(event) => { }}

				helperText=""
				small
				compact
				fullWidth
			/>
		</Box>
		<BaseInput
			label={`${t('customer_id_edit_modal')}`}
			name="customerId"
			disabled
			helperText=""
			value={currentController?.customerId ?? ''}
			onChange={(event) => { }}
			small
			compact
			blueBorder
		/>
		<BaseInput
			label={`${t('customerName_edit_modal')}`}
			name="customerName"
			disabled
			helperText=""
			value={currentController?.customerName ?? ''}
			onChange={(event) => { }}
			small
			compact
			blueBorder
		/>
		<BaseInput
			label={`${t('customer_agreement_status_edit_modal')}`}
			name="customerAgreementStatus"
			disabled
			helperText=""
			value={currentController?.customerAgreementStatus ? getCustomerAgreementName(currentController?.customerAgreementStatus) : ''}
			onChange={() => { }}
			small
			compact
			blueBorder
		/>
	</Row>)
}
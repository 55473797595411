import { Box, Typography } from '@mui/material';
import { BaseTable } from 'components';
import { TableDataWithId } from 'features/ControllersMoveTable';
import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'shared/hooks';
import { BaseBtn, BaseInput, Modal, ModalLabel, Row } from 'shared/ui';
import { UpdatingDeviceName } from '../UpdatingDeviceName';
import { TABLE_TITLES } from './constants';
interface Props {
  openUpdateDeviceName: boolean;
  onCloseModal: () => void;
  selectedControllers: number[];
  customerFilter: string;
}

export const UpdateDeviceName: FC<Props> = ({
  selectedControllers,
  openUpdateDeviceName,
  onCloseModal,
  customerFilter,
}) => {
  const { t } = useTranslation();

  const { controllers } = useAppSelector((st) => st.controllers);
  const [filteredData, setFilteredData] = useState<TableDataWithId[]>([]);
  const [defaultData, setDefaultData] = useState<TableDataWithId[]>([]);
  const [selectedControllersID, setSelectedControllersID] = useState<number[]>([]);
  const [modifiedControllers, setModifiedControllers] = useState<TableDataWithId[]>([]);
  const [viewControllers, setViewControllers] = useState<TableDataWithId[]>([]);
  const [currentNameMask, setCurrentNameMask] = useState('');
  const [newNameMask, setNewNameMask] = useState('');
  const [isValidNames, setIsValidNames] = useState(true);

  const [showTextWithNewMask, setShowTextWithNewMask] = useState(false);
  const [isOpenUpdateDeviceTransferringModal, setIsOpenUpdateDeviceTransferringModal] = useState<boolean>(false);
  const cancel = () => {
    setNewNameMask('');
    setCurrentNameMask('');
    setDefaultData([]);
    setFilteredData([]);
    setModifiedControllers([]);
    setViewControllers([]);
    setSelectedControllersID([]);
    setShowTextWithNewMask(false);
    setIsValidNames(true);
    setIsOpenUpdateDeviceTransferringModal(false);
    onCloseModal();
  };

  const update = () => {
    if (newNameMask != currentNameMask) {
      setShowTextWithNewMask(true);
    }
    changeSelectedByNewMask(true);
    setIsOpenUpdateDeviceTransferringModal(true);

    // if (currentNameMask[currentNameMask.length - 1] != '*') {
    //   setCurrentNameMask(currentNameMask + ' *');
    // }
    // if (newNameMask[newNameMask.length - 1] != '*') {
    //   setNewNameMask(newNameMask + ' *');
    // }
    // setShowTextWithNewMask(true);
    // changeSelectedByNewMask();
  };

  const formatControllerText = (count: number) => {
    if (count === 1) {
      return `${t('controller_device_rename_count')}`;
    } else {
      return `${t('controllers_device_rename_text')}`;
    }
  };

  useEffect(() => {
    const tableDataArray: TableDataWithId[] = controllers.map((controller) => ({
      id: controller.deviceId,
      objectName: controller.objectName,
      customerId: controller.customerId,
      customerName: controller.customerName,
    }));
    if (openUpdateDeviceName) {
      const filteredData = tableDataArray.filter((obj) => selectedControllers.includes(obj.id));
      filteredData.sort((a, b) => {
        if (a.objectName < b.objectName) return -1;
        if (a.objectName > b.objectName) return 1;
        return 0;
      });
      setViewControllers(filteredData);
      setFilteredData(filteredData);
      setDefaultData(filteredData);
      getDefaultMaskAndSet(filteredData);
    }
  }, [openUpdateDeviceName]);

  const findMask = (selectedController: TableDataWithId) => {
    let maskLetters = '';
    let maskNumbers = '';
    let isMaskValid = true;
    const checkForLettersAfterNumbers = () => {
      return !!maskNumbers;
    };
    const words = selectedController.objectName.split(' ');
    if (words.length < 2) return false;

    const firstWord = words[0];
    if (!isNaN(parseInt(firstWord[0]))) {
      return false;
    }

    firstWord.split('').forEach((char) => {
      if (isNaN(parseInt(char))) {
        if (checkForLettersAfterNumbers()) isMaskValid = false;
        maskLetters += char;
      } else {
        maskNumbers += '0';
      }
    });

    if (maskLetters.length > 3) return false;
    if (maskNumbers.length > 4 || !maskNumbers) return false;
    return isMaskValid ? maskLetters + maskNumbers : false;
  };

  const findMaskWithThreeConsecutiveValues = (masks: string[]) => {
    const consecutiveCount = 3;
    let found = false;

    for (let i = 0; i <= masks.length - consecutiveCount; i++) {
      if (masks[i] === masks[i + 1] && masks[i] === masks[i + 2]) {
        found = true;
        return masks[i];
      }
    }

    if (!found) {
      return null;
    }
  };

  const getDefaultMaskAndSet = (filteredData: TableDataWithId[]) => {
    const allMasks = [];
    filteredData.forEach((item) => {
      const result = findMask(item);
      allMasks.push(result);
    });
    const mask = findMaskWithThreeConsecutiveValues(allMasks);
    if (mask) {
      setCurrentNameMask(mask + ' *');
      setNewNameMask(mask + ' *');
    }
  };

  const rowSelectionModelChange = useCallback((rows) => {
    //setSelectedControllersID(rows);
  }, []);
  const closeUpdateDeviceTransferringModal = () => {
    setViewControllers(filteredData);
    // showPreview();
    setShowTextWithNewMask(false);
    setIsOpenUpdateDeviceTransferringModal(false);
  };
  const changeCurrentNameMask = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newValue = event.target.value;
    setCurrentNameMask(newValue);
    setShowTextWithNewMask(false);
  };
  const changeNewNameMask = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newValue = event.target.value;
    setNewNameMask(newValue);
    setShowTextWithNewMask(false);
  };
  const showPreview = () => {
    if (currentNameMask[currentNameMask.length - 1] != '*') {
      setCurrentNameMask(currentNameMask + ' *');
    }
    if (newNameMask[newNameMask.length - 1] != '*') {
      setNewNameMask(newNameMask + ' *');
    }
    if (newNameMask != currentNameMask) {
      setShowTextWithNewMask(true);
    }

    changeSelectedByNewMask();

    // const [currentNameMask, setCurrentNameMask] = useState('');
    // const [newNameMask, setNewNameMask] = useState('');
  };
  const reload = () => {
    setShowTextWithNewMask(false);
    setModifiedControllers(defaultData);
    setViewControllers(defaultData);
    setFilteredData(defaultData);
    setSelectedControllersID([]);
  };
  const handleChangeDataInTable = (row: TableDataWithId) => {
    const editedControllers = [...filteredData];

    const index = editedControllers.findIndex((item) => item.id === row.id);

    editedControllers[index] = row;
    setIsValidNames(row.objectName.length > 4);
    setFilteredData(editedControllers);

    checkForEditCellsAndCheckboxStatuses(editedControllers);
  };

  const checkForEditCellsAndCheckboxStatuses = (
    editedControllers: TableDataWithId[],
    selectedControllers: number[] = selectedControllersID,
    newControllers: TableDataWithId[] = modifiedControllers
  ) => {
    const defaultDataMap = defaultData.reduce((map, obj) => {
      map[obj.id] = obj;
      return map;
    }, {});
    const changedControllers = editedControllers
      .filter(
        (controller) =>
          defaultDataMap[controller.id] && defaultDataMap[controller.id].objectName !== controller.objectName
      )
      .map((controller) => controller.id);

    const changedControllersByMask = newControllers
      .filter(
        (controller) =>
          defaultDataMap[controller.id] && defaultDataMap[controller.id].objectName !== controller.objectName
      )
      .map((controller) => controller.id);
    const allChangedControllers = changedControllersByMask.concat(changedControllers).filter((item, index, self) => {
      return self.indexOf(item) === index;
    });
    setSelectedControllersID(allChangedControllers);
    setModifiedControllers(newControllers.filter((item) => allChangedControllers.includes(item.id)));
  };
  const mergeUniqueById = (arr1, arr2) => {
    const map = new Map();

    arr1.forEach((item) => map.set(item.id, item));
    arr2.forEach((item) => {
      if (!map.has(item.id)) {
        map.set(item.id, item);
      }
    });

    return Array.from(map.values());
  };
  const changeSelectedByNewMask = (onlyUpdateModifiedControllers = false) => {
    const selectedControllers = [];
    const newControllers: TableDataWithId[] = [];

    const getPartsOfString = (mask: string) => {
      let beforeZeros = '';
      let zeros = '';
      let afterZeros = '';
      for (let i = 0; i < mask.length; i++) {
        const char = mask[i];
        if (!isNaN(parseInt(char)) && !afterZeros) {
          zeros += char;
        } else if (char !== '*') {
          if (zeros) {
            afterZeros += char;
          } else {
            beforeZeros += char;
          }
        }
      }
      return { beforeZeros, zeros, afterZeros };
    };

    const getNewName = (item: TableDataWithId) => {
      const { beforeZeros, zeros, afterZeros } = getPartsOfString(currentNameMask);
      const { beforeZeros: letters, zeros: digits, afterZeros: others } = getPartsOfString(item.objectName);
      if (beforeZeros.toUpperCase() !== letters.toUpperCase()) return;
      if (zeros.length !== digits.length) return;
      if (!others.toUpperCase().startsWith(afterZeros.toUpperCase())) return;

      selectedControllers.push(item.id);

      const { beforeZeros: newLetters, zeros: newDigits, afterZeros: afterNumbers } = getPartsOfString(newNameMask);

      const moreNumbersBecome = newDigits.length - digits.length;
      if (moreNumbersBecome > -1) {
        return `${newLetters}${moreNumbersBecome > 0 ? '0'.repeat(moreNumbersBecome) : ''}${digits}${others}`;
      }
      if (moreNumbersBecome < 0) {
        const cutDigits = digits.substring(moreNumbersBecome * -1);
        return `${newLetters}${cutDigits}${afterNumbers}${others}`;
      }
      // return `${newLetters}${moreNumbersBecome > 0 ? '0'.repeat(moreNumbersBecome) : ''}${digits}${afterNumbers}${others}`;
    };
    filteredData.forEach((item) => {
      if (currentNameMask[currentNameMask.length - 1] === '*') {
        const newController = { ...item };
        const formattedWithNewName = getNewName(item);
        newController.objectName = formattedWithNewName ? formattedWithNewName : item.objectName;
        newControllers.push(newController);
      }
    });

    if (!onlyUpdateModifiedControllers) {
      setSelectedControllersID(selectedControllers);
      setViewControllers(newControllers);
    } else {
      const viewControllersMap = viewControllers.reduce((map, obj) => {
        map[obj.id] = obj;
        return map;
      }, {});

      const changedControllers = filteredData.filter(
        (controller) =>
          viewControllersMap[controller.id] && viewControllersMap[controller.id].objectName !== controller.objectName
      );

      const mergedArray = mergeUniqueById(newControllers, changedControllers);
      const selected = Array.from(new Set([...selectedControllers, changedControllers.map((item) => item.id)]));
      setSelectedControllersID(selectedControllers);
      setViewControllers(mergedArray.filter((item) => selectedControllers.includes(item.id)));
      setFilteredData(filteredData);
      checkForEditCellsAndCheckboxStatuses(filteredData, selectedControllers, mergedArray);
      return;
    }
    setFilteredData(filteredData);
    checkForEditCellsAndCheckboxStatuses(filteredData, selectedControllers, newControllers);
  };
  return (
    <>
      <Modal
        PaperProps={{ sx: { maxWidth: '562px', borderRadius: '12px' } }}
        open={openUpdateDeviceName}
        disableEscapeKeyDown
      >
        <Box
          sx={{
            p: '41px 36px 36px 36px',
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Noto Sans Hebrew',
              fontSize: '24px',
              fontWeight: 600,
              lineHeight: '29px',
              color: 'primary.dark',
            }}
          >
            {t('update_controller_name')}
          </Typography>
          <Box sx={{ mt: '16px' }}>
            <Row spacing={'8px'} sx={{ maxWidth: '100%' }}>
              <Box sx={{ width: '175px' }}>
                <ModalLabel text={t('current_name_mask')} />
                <ModalLabel text={t('new_name_mask')} />
                <Box sx={{ height: '40px' }}></Box>
              </Box>
              <Box sx={{ width: '100%' }}>
                <BaseInput
                  name="currentNameMask"
                  helperText=""
                  value={currentNameMask}
                  onChange={(event) => {
                    changeCurrentNameMask(event);
                  }}
                  small
                  compact
                  smallLabel
                  changeColorOnFocus
                  placeholder={t('current_name_mask_placeholder')}
                  marginBottom={16}
                />
                <BaseInput
                  name="newNameMask"
                  helperText=""
                  value={newNameMask}
                  onChange={(event) => {
                    changeNewNameMask(event);
                  }}
                  small
                  compact
                  smallLabel
                  changeColorOnFocus
                  placeholder={t('new_name_mask_placeholder')}
                  marginBottom={16}
                />
                <Box sx={{ width: '100%', marginLeft: '0%' }}>
                  <Row spacing={'20px'}>
                    <BaseBtn onClick={reload} btnColor="info.main" fullWidth>
                      {t('reload_update_device_name')}
                    </BaseBtn>
                    <BaseBtn
                      disabled={!currentNameMask || !newNameMask || showTextWithNewMask}
                      onClick={showPreview}
                      btnColor="info.main"
                      fullWidth
                    >
                      {t('preview_update_device_name')}
                    </BaseBtn>
                  </Row>
                </Box>
              </Box>
            </Row>
          </Box>
          {/* <Typography
            sx={{
              fontFamily: 'Noto Sans Hebrew',
              fontSize: '16px',
              fontWeight: 600,
              lineHeight: '19px',
              color: 'primary.dark',
            }}
          >
            {`${t(`total_device_rename_total`)} ${formatControllerText(selectedControllers.length)}`}
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Noto Sans Hebrew',
              fontSize: '16px',
              fontWeight: 600,
              lineHeight: '19px',
              color: 'primary.dark',
            }}
          >
            {`${t(`updated_device_total`)} ${formatControllerText(selectedControllersID.length)}`}
          </Typography> */}
          <Row spacing={'5px'}>
            <Box>
              <Typography
                sx={{
                  fontFamily: 'Noto Sans Hebrew',
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '19px',
                  color: 'primary.dark',
                }}
              >
                {`${t(`total_device_rename_total`)}`}
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Noto Sans Hebrew',
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '19px',
                  color: 'primary.dark',
                }}
              >
                {t(`updated_device_total`)}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontFamily: 'Noto Sans Hebrew',
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '19px',
                  color: 'primary.dark',
                }}
              >
                {selectedControllers.length}
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Noto Sans Hebrew',
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '19px',
                  color: 'primary.dark',
                }}
              >
                {selectedControllersID.length}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontFamily: 'Noto Sans Hebrew',
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '19px',
                  color: 'primary.dark',
                }}
              >
                {formatControllerText(selectedControllers.length)}
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Noto Sans Hebrew',
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '19px',
                  color: 'primary.dark',
                }}
              >
                {formatControllerText(selectedControllersID.length)}
              </Typography>
            </Box>
          </Row>
          <Box>
            {openUpdateDeviceName && viewControllers.length && (
              <BaseTable
                tableTitles={TABLE_TITLES}
                tableItems={showTextWithNewMask ? viewControllers : filteredData}
                loadedItems={true}
                idField={'id'}
                noItemsText={t('no_items_controllers_device_rename_grid_table')}
                headerItemsPrefix={'_controllers_device_rename_grid_table'}
                onRowSelectionModelChange={rowSelectionModelChange}
                selectedIDs={selectedControllersID}
                // sortModel={[{ field: 'objectName', sort: 'asc' }]}
                maxHeight={'320px'}
                height={'300px'}
                underlineRow={0}
                searchValueForHighlight={currentNameMask}
                newHighlightText={newNameMask}
                checkboxSelection
                modifyColumnIndexByMask={showTextWithNewMask ? 0 : null}
                changeDataInTable={handleChangeDataInTable}
                editable
              />
            )}
          </Box>
          <Row position="relative" justifyContent="flex-end" alignItems="center" mt="34px">
            <Row justifyContent="center">
              <Row width={300} gap="10px">
                <BaseBtn onClick={cancel} btnColor="info.main" fullWidth>
                  {t('cancel_update_device_rename_button')}
                </BaseBtn>
                <BaseBtn
                  type="submit"
                  onClick={update}
                  disabled={!selectedControllersID.length || !isValidNames}
                  btnColor="primary.dark"
                  fullWidth
                >
                  {t('update_update_device_rename_button')}
                </BaseBtn>
              </Row>
            </Row>
          </Row>
        </Box>
      </Modal>
      <UpdatingDeviceName
        onCloseModal={closeUpdateDeviceTransferringModal}
        openUpdateDeviceTransferring={isOpenUpdateDeviceTransferringModal}
        selectedControllers={selectedControllersID}
        newNameMask={newNameMask}
        currentNameMask={currentNameMask}
        modifiedControllers={modifiedControllers}
        onCloseAfterUpdate={cancel}
        customerFilter={customerFilter}
      />
    </>
  );
};

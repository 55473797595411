import { useAppDispatch } from 'shared/hooks';

import useAxios from 'shared/api/useAxios';
import { ServiceCall } from 'features/ServiceCallsReportManager/ServiceCallModels';
import { ActionType, AssignedTech, FaultEquipment, setActionType, setAllServiceCalls, setAssignedTech, setFaultEquipment} from 'features/ServiceCallsReportManager/AllServiceCalls';


export const useServiceCalls = () => {
  const dispatch = useAppDispatch();
  const { loading, request } = useAxios();
  return {
    getServiceCalls: (startDate:string, endDate:string, minCallCount:number) =>
    request< undefined, { data: ServiceCall[] }>({
      method: 'get',
      url: `serviceCalls?startDate=${startDate}&endDate=${endDate}&minCallCount=${minCallCount}`,
      showSuccessMessageCode: undefined,
      onDataReceiveCompleted: (data) => {
        dispatch(setAllServiceCalls(data.data))
      },
    }),


    getFaultEquipment:()=>{
      request< undefined, { data: FaultEquipment[] }>({
        method: 'get',
        url: `serviceCalls/faultequipment/`,
        showSuccessMessageCode: undefined,
        onDataReceiveCompleted: (data) => {
          dispatch(setFaultEquipment(data.data))
        },
      })
    },

    getActionType:()=>{
      request< undefined, { data: ActionType[] }>({
        method: 'get',
        url: `serviceCalls/actionType/`,
        showSuccessMessageCode: undefined,
        onDataReceiveCompleted: (data) => {
          dispatch(setActionType(data.data))
        },
      })
    },

    getAssignedTech:()=>{
      request< undefined, { data: AssignedTech[] }>({
        method: 'get',
        url: `serviceCalls/assignedTech/`,
        showSuccessMessageCode: undefined,
        onDataReceiveCompleted: (data) => {
          dispatch(setAssignedTech(data.data))
        },
      })
    },

    isLoading: loading,
  };
};

import { Box, IconButton, MenuItem, SelectChangeEvent, Stack, Tooltip } from '@mui/material';
import { IrrigationChart } from 'components';
import { ComposedChart } from 'components/ComposedChart';
import { BaseDataPicker, Filter } from 'features';
import { t } from 'i18next';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { close_popup, refresh } from 'shared/assets';
import { Controller } from 'shared/models';
import { BaseBtn, BaseInput, BigTitle, Icon, Loader, Modal, Row, Select, Text } from 'shared/ui';
import { useFilter } from './hooks';
import { useEventHistory } from 'features/ControllerManager/hooks';
import { mockData } from './mockData';
interface ModalState {
  isOpenModal: boolean;
  closeModal: () => void;
  currentController: Controller;
}
interface ModalState {
  isOpenModal: boolean;
  closeModal: () => void;
}

export const ControllerEventHistory: FC<ModalState> = ({ isOpenModal, closeModal, currentController }) => {
  const handleCloseEditModal = () => {
    setIrrigationChartData(null);
    setUserActionsChartData(null);
    clearData();
    closeModal();
  };
  const {
    irrigationChartData,
    setIrrigationChartData,
    setUserActionsChartData,
    batteryVoltageChartData,
    setBatteryVoltageChartData,
    onChangeStartDate,
    startDate,
    onChangeEndDate,
    endDate,
    onChangeSelectedStartDate,
    selectedStartDate,
    onChangeSelectedEndDate,
    selectedEndDate,

    datesIntervalItems,
    changeDatesIntervalFilter,
    datesIntervalFilter,
    selectedGraphType,
    onChangeGraphType,
    getGraphTypes,
    clearData,
    getGraphData,
    getGraphDataByDefaultDefault,
    checkIsLoading,
    userActionsEventIDs,
    setUserActionsEventIDs,
  } = useFilter();
  useEffect(() => {
    if (isOpenModal) {
      changeDatesIntervalFilter('');
      if (currentController.modelId === 'I1') {
        handleChangeGraphType({ target: { value: 'irrigation' } } as SelectChangeEvent<string>);
      }
    }
  }, [isOpenModal]);

  const closeButtonRef = useRef<HTMLButtonElement | null>(null);

  const handleModalClose = () => {
    if (closeButtonRef.current) {
      closeButtonRef.current.click();
    }
  };
  const handleChangeGraphType = (e: SelectChangeEvent<string>) => {
    onChangeGraphType(e);
    const value = e.target.value;
    if (value == 'irrigation' || value === 'total Irrigation') {
      setIrrigationChartData(null);
      setUserActionsChartData(null);
      setUserActionsEventIDs(null);
      changeDatesIntervalFilter('current week');
      getGraphDataByDefaultDefault({
        deviceId: currentController.deviceId,
        type: 'irrigation',
        datesInterval: 'current week',
      });
    }
    if (value == 'battery voltage') {
      setBatteryVoltageChartData([]);
      changeDatesIntervalFilter('last two weeks');
    }
  };
  const handleUpdateEventHistory = async () => {
    onChangeStartDate(selectedStartDate);
    onChangeEndDate(selectedEndDate);
    getGraphData({ deviceId: currentController.deviceId, selectedGraphType, selectedStartDate, selectedEndDate });
  };

  const memoizedComposedChart = useMemo(
    () => (
      <ComposedChart
        showEventIds={[1702]}
        data={batteryVoltageChartData}
        startDate={startDate}
        endDate={endDate}
        height={359}
        popupPadding={160}
      />
    ),
    [batteryVoltageChartData]
  );
  return (
    <Modal
      open={isOpenModal}
      width={'100%'}
      padding={'16px 24px'}
      maxHeight={'995px'}
      height={'calc(100vh - 10px)'}
      onClose={handleModalClose}
    >
      <Box>
        <Stack direction="row" sx={{ mb: '16px' }} justifyContent="space-between" alignItems="center">
          <BigTitle>{currentController?.objectName}</BigTitle>
          <Row sx={{ flexGrow: 1 }} />
          <Row spacing="20px" mr="8px" alignItems="start">
            <BaseInput
              label={`${t('device_type_label')}`}
              name="deviceType"
              disabled
              helperText=""
              value={currentController?.deviceType ? t(`${currentController?.deviceType}_chart_item`) : ''}
              onChange={(event) => {}}
              small
              compact
              blueBorder
            />
            <BaseInput
              label={`${t('controller_id_edit_modal')}`}
              name="deviceId"
              disabled
              helperText=""
              value={currentController?.deviceId ?? ''}
              onChange={(event) => {}}
              maxWidth={100}
              small
              compact
              blueBorder
            />
            <BaseInput
              label={`${t('hwId_edit_modal')}`}
              name="hwId"
              disabled
              helperText=""
              value={currentController?.hwId ?? ''}
              onChange={(event) => {}}
              maxWidth={100}
              small
              compact
              blueBorder
            />
            <BaseInput
              label={`${t('model_id_edit_modal')}`}
              name="modelId"
              disabled
              helperText=""
              value={currentController?.modelId ?? ''}
              onChange={(event) => {}}
              maxWidth={100}
              small
              compact
              blueBorder
            />
          </Row>
          <IconButton ref={closeButtonRef} onClick={handleCloseEditModal}>
            <img src={close_popup} />
          </IconButton>
        </Stack>

        <Row spacing="20px" alignItems="flex-end">
          {currentController.modelId && (
            <Select
              label={`${t('graph_type_event_history_label')}`}
              name="GraphType"
              value={selectedGraphType}
              onChange={handleChangeGraphType}
              displayEmpty
              marginTop="0px"
              marginBottom="1px"
              sx={{
                fontFamily: 'Noto Sans Hebrew !important',
                width: '260px',
              }}
              small
            >
              {getGraphTypes(currentController?.modelId).map((a) => (
                <MenuItem key={a.value} value={a.value}>
                  {t(a.label)}
                </MenuItem>
              ))}
            </Select>
          )}

          <BaseDataPicker
            label={`${t('start_date_event_history_label')}`}
            name="fromDate"
            value={selectedStartDate}
            onChange={(type, value) => onChangeSelectedStartDate(value)}
            formStyle
            showFuture
          />
          <Box sx={{ mr: '10px', pt: '20px', fontWeight: 700, fontSize: '20px', color: '#888' }}>
            {t('dates_sep_arrow_event_history')}
          </Box>

          <BaseDataPicker
            label={`${t('end_date_event_history_label')}`}
            name="toDate"
            value={selectedEndDate}
            onChange={(type, value) => onChangeSelectedEndDate(value)}
            error={null}
            formStyle
            showFuture
          />
          <Filter
            title={`${t('dates_interval_event_history_label')}`}
            value={datesIntervalFilter}
            onChange={(value) => {
              changeDatesIntervalFilter(value);
            }}
            items={datesIntervalItems}
            hideAllButton
            sx={{ maxWidth: '200px', minWidth: '200px' }}
            placeholder={`${t('filter_interval_event_history_placeholder')}`}
            messageEmptyFilter={`${t('filter_interval_event_history_placeholder')}`}
            hideResetButton
            formStyle
          />

          <BaseBtn
            sx={{ height: '29px', width: '100px' }}
            btnColor="primary.dark"
            disabled={!selectedGraphType}
            onClick={handleUpdateEventHistory}
          >
            <Row>
              <Icon src={refresh} />
              <Text
                noWrap
                sx={{
                  fontSize: '14px',
                  height: '24px',
                  margin: '0 4px',
                  fontFamily: 'Noto Sans Hebrew !@important',
                }}
              >
                {t('update_event_history_button')}
              </Text>
            </Row>
          </BaseBtn>
        </Row>
        {checkIsLoading() ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '60vh',
            }}
          >
            <Loader />
          </Box>
        ) : (
          <Box sx={{ mt: '16px' }}>
            {selectedGraphType === 'irrigation' || selectedGraphType === 'total Irrigation' ? (
              irrigationChartData && (
                <IrrigationChart
                  //data={mockData}
                  data={irrigationChartData}
                  userActionsEventIDs={userActionsEventIDs}
                  startDate={startDate}
                  endDate={endDate}
                  height={600}
                  popupPadding={240}
                  deviceType={currentController?.deviceType}
                />
              )
            ) : selectedGraphType === 'battery voltage' ? (
              !!batteryVoltageChartData.length && memoizedComposedChart
            ) : (
              <Box
                style={{
                  width: '100%',
                  display: 'flex',
                  height: '400px',
                  background: '#3D86BE',
                  marginTop: '16px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  borderRadius: '25px',
                  position: 'relative',
                }}
              >
                <Box>
                  <BigTitle sx={{ mr: '25px', color: 'white' }}>
                    {t('please_select_a_graph_type_event_history_message')}
                  </BigTitle>
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Modal>
  );
};

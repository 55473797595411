import { Box, Tooltip as TooltipItem } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { BaseDataPicker } from 'features';
import { t } from 'i18next';
import { FC, memo, useEffect, useState } from 'react';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useDailyConsumption } from 'shared/api';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import localStorageService from 'shared/localStorage';
import { DailyConsumption } from 'shared/models';
import { setControllerClearDataFilter, toggleOpenEditControllerClearDataModal } from 'shared/slices';
import { BaseBtn, Row } from 'shared/ui';
import { CustomTooltip, CustomizedAxisTick } from './';
import { useGetDefaultDates } from './../hooks';
import { Controller } from 'features/ControllerManager/controllerModels';
type TempData = {
  date: string;
  totalProg: number;
  totalActual: number;
  totalUncontrolled: number;
};
type InitialFilter = {
  fromDate: string;
  toDate: string;
};

const Chart = memo(({ data }: { data: TempData[] }) => {
  const isRTL = localStorageService.isRTL;
  const maxValueTotalActual =
    Math.round(Math.max(...data.map((item) => (item.totalActual ? item.totalActual : 0)))) + 2;
  const maxValueTotalProg = Math.round(Math.max(...data.map((item) => (item.totalProg ? item.totalProg : 0)))) + 2;
  const maxValue = Math.max(maxValueTotalActual, maxValueTotalProg);
  const maxDomainValue = maxValue + maxValue / 10;
  const TotalProgBar = (props: any) => {
    const { fill, x, y, width, height } = props;
    return <rect x={x + width / 2} y={y} width={width} height={height} fill={fill} />;
  };
  const ActualBar = (props: any) => {
    const { fill, x, y, width, height } = props;
    return <rect x={x - width / 2} y={y} width={width} height={height} fill={fill} />;
  };
  const UncontrolledProgBar = (props: any) => {
    const { fill, x, y, width, height } = props;
    return <rect x={x} y={y} width={width} height={height} fill={fill} />;
  };

  return (
    <ResponsiveContainer width="98%">
      <ComposedChart height={220} data={data}>
        <Tooltip content={<CustomTooltip />} />
        <defs>
          <linearGradient id="gradient-totalProg" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#e9d698" />
            <stop offset="100%" stopColor="#968852" />
          </linearGradient>
          <linearGradient id="gradient-totalUncontrolled" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#df91b5" />
            <stop offset="100%" stopColor="#c56d93" />
          </linearGradient>
          <linearGradient id="gradient-totalActual" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#66aedf" />
            <stop offset="100%" stopColor="#1c72b5" />
          </linearGradient>
        </defs>

        <Bar
          name={`${t('graph_Controllers_irrigation_data_totalProg')}`}
          dataKey="totalProg"
          stroke="yellow"
          fill="url(#gradient-totalProg)"
          type="stepAfter"
          isAnimationActive={false}
          shape={<TotalProgBar />}
        >
          <LabelList
            dataKey="totalProg"
            position="top"
            content={(props) => {
              const { x, y, width } = props;
              const value = props.value === 0 ? '' : props.value;
              return (
                x &&
                y &&
                width && (
                  <text x={+x + +width} y={+y - 5} fill="black" fontSize={12} textAnchor="middle">
                    {value}
                  </text>
                )
              );
            }}
          />
        </Bar>

        <Bar
          name={`${t('graph_Controllers_irrigation_data_totalUncontrolled')}`}
          dataKey="totalUncontrolled"
          fill="url(#gradient-totalUncontrolled)"
          shape={<UncontrolledProgBar />}
        >
          <LabelList
            dataKey="totalUncontrolled"
            position="top"
            content={(props) => {
              const { x, y, width } = props;
              const value = props.value === 0 ? '' : props.value;
              return (
                x &&
                y &&
                width && (
                  <text x={+x + +width / 2} y={+y - 5} fill="black" fontSize={12} textAnchor="middle">
                    {value}
                  </text>
                )
              );
            }}
          />
        </Bar>
        <Bar
          name={`${t('graph_Controllers_irrigation_data_totalActual')}`}
          dataKey="totalActual"
          fill="url(#gradient-totalActual)"
          shape={<ActualBar />}
        >
          <LabelList
            dataKey="totalActual"
            position="top"
            content={(props) => {
              const { x, y, width } = props;
              const value = props.value === 0 ? '' : props.value;
              return (
                x &&
                y &&
                width && (
                  <text x={x} y={+y - 5} fill="black" fontSize={12} textAnchor="middle">
                    {value}
                  </text>
                )
              );
            }}
          />
        </Bar>
        <CartesianGrid stroke="#ccc" vertical={false} />
        <XAxis
          dataKey="date"
          domain={['min', 'max']}
          tickCount={9}
          height={30}
          tickSize={2}
          allowDataOverflow={true}
          tick={<CustomizedAxisTick />}
          interval="preserveStartEnd"
        />
        <YAxis tickCount={9} domain={[0, maxDomainValue]} textAnchor={`${isRTL ? 'start' : 'end'}`} />
        <Legend height={20} />
      </ComposedChart>
    </ResponsiveContainer>
  );
});

interface Props {
  currentController: Controller;
  showFull: boolean;
  allControllerDataLoaded: boolean;
}
export const LastIrrigationData: FC<Props> = ({ currentController, showFull, allControllerDataLoaded }) => {
  const { dailyConsumption, controllerClearDataFilterChanged } = useAppSelector((st) => st.controllers);
  const dailyConsumptionFormatted: DailyConsumption[] = [];
  if (dailyConsumption[0]) {
    dailyConsumption.forEach((item) => {
      const result: DailyConsumption = Object.assign(
        {
          date: '',
          totalProg: 0,
          totalActual: 0,
          totalUncontrolled: 0,
          dataIndex: 0,
        },
        item
      );
      result.dataIndex = new Date(item.date).getTime();
      dailyConsumptionFormatted.push(result);
    });
  }
  const { getDailyConsumption, deleteDailyConsumption, isLoadingDailyConsumption } = useDailyConsumption();
  const { fromDate, toDate } = useGetDefaultDates(currentController);
  const dispatch = useAppDispatch();

  const initialFilter = {
    fromDate,
    toDate,
  };
  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return year + '-' + month + '-' + day;
  };
  const [filter, setFilter] = useState<InitialFilter>(initialFilter);
  const [isChanged, setIsChanged] = useState(false);
  const fetchData = async () => {

    if (currentController && allControllerDataLoaded) {
      if (fromDate && toDate) {
        setFilter({ fromDate, toDate });
        getDailyConsumption({ objectID: currentController.deviceId, startDate: fromDate, endDate: toDate, actionType: "Automatic" });
      } else {
        const today = new Date();
        const toDate = formatDate(today);
        const yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);
        const fromDate = formatDate(yesterday);
        setFilter({ fromDate, toDate });
        getDailyConsumption({ objectID: currentController.deviceId, startDate: fromDate, endDate: toDate, actionType: "Automatic" });
      }
    }
  };


  useEffect(() => {
    fetchData();
  }, [fromDate, toDate, allControllerDataLoaded]);
  const onChangeDateValues = (param: string, value: string) => {
    setIsChanged(true);
    setFilter((prevValues) => ({
      ...prevValues,
      [param]: value,
    }));
  };
  const updateGraph = () => {
    setIsChanged(false);
    if (currentController) {
      getDailyConsumption({ objectID: currentController.deviceId, startDate: filter.fromDate, endDate: filter.toDate, actionType: "Manual" });
    }
  };
  const openClearDataModal = () => {
    const result = {
      startDate: filter.fromDate,
      endDate: filter.toDate,
      uncontrolledFlow: true,
      actualConsumption: true,
    };
    dispatch(setControllerClearDataFilter(result));
    dispatch(toggleOpenEditControllerClearDataModal());
  };
  const deleteGraph = () => {
    deleteDailyConsumption(currentController);
  };
  return (
    <Box component="form" autoComplete="off">
      <Box>
        <Row spacing="24px" justifyContent="flex-start">
          <Row spacing="24px" justifyContent="flex-start">
            <BaseDataPicker
              label={`${t('start_date_graph_irrigation_data_report_label')}`}
              name="fromDate"
              value={filter.fromDate}
              onChange={onChangeDateValues}
              formStyle
            />
            <BaseDataPicker
              label={`${t('end_date_graph_irrigation_data_report_label')}`}
              name="toDate"
              value={filter.toDate}
              onChange={onChangeDateValues}
              error={null}
              formStyle
            />
          </Row>
          <Row spacing="24px" justifyContent="flex-start" sx={{ paddingTop: '20px' }}>
            <BaseBtn onClick={updateGraph} sx={{ height: '29px' }} btnColor="info.main">
              {t('graph_irrigation_data_update')}
            </BaseBtn>
          </Row>
          <Row sx={{ flexGrow: 1 }} />
          <Row spacing="24px" sx={{ paddingTop: '7px' }} justifyContent="flex-end">
            <TooltipItem
              title={t('graph_irrigation_clear_last_data_tooltip')}
              style={{ fontSize: '2em' }}
              placement="top"
            >
              <Box component={'span'}>
                <BaseBtn onClick={openClearDataModal} sx={{ height: '29px' }}>
                  {t('graph_irrigation_clear_last_date')}
                </BaseBtn>
              </Box>
            </TooltipItem>
            <TooltipItem title={t('graph_irrigation_update_tooltip')} style={{ fontSize: '2em' }} placement="top">
              <Box component={'span'}>
                <BaseBtn onClick={deleteGraph} sx={{ height: '29px' }}>
                  {t('graph_irrigation_update')}
                </BaseBtn>
              </Box>
            </TooltipItem>
          </Row>
        </Row>
        {!isLoadingDailyConsumption ? (
          <Box sx={{ margin: 'auto', marginTop: '8px', height: showFull ? '610px' : '250px' }}>
            {dailyConsumptionFormatted && !!dailyConsumptionFormatted[0] && !!dailyConsumptionFormatted[0].date && (
              <Chart data={dailyConsumptionFormatted} />
            )}
          </Box>
        ) : (
          <Row height={240} justifyContent="center" margin="0px auto">
            <CircularProgress disableShrink />
          </Row>
        )}
      </Box>
    </Box>
  );
};

import { Stack, IconButton, MenuItem, SelectChangeEvent } from '@mui/material';
import { Box } from '@mui/material';
import { BaseDataPicker } from 'features';
import { t } from 'i18next';
import { close_popup, close_blue } from 'shared/assets';
import { useAppDispatch, useAppSelector, useControllerDateErrors, useAnchorEl } from 'shared/hooks';
import { toggleAddServiceCall } from 'shared/slices';
import { BigTitle, Modal, BaseInput, Row, BaseBtn, Confirm, Select, Icon } from 'shared/ui';
import { ServiceCall } from 'shared/models';
import { FC, useState, useEffect, useRef } from 'react';
import { useEditController } from 'shared/api';
import { replaceSpacesWithUnderscores } from 'shared/lib/replaceSpacesWithUnderscores';
import { useAddServiceCallsChanges } from './hooks';
import { useToast } from 'shared/hooks';
import { useBatteryStatusReport, useServiceCallsManagement } from 'shared/api';
import { getFormattedDate } from 'shared/lib';
import localStorageService from 'shared/localStorage';

const initialErrors = {
  customerName: '',
  objectName: '',
  installAddress: '',
  objectNotes: '',
  latitudeDec: '',
  longitudeDec: '',
};

interface Props {
  changedServiceCalls: () => void;
  faultEquipmentCode?: number;
  customerID?: number;
  customerName?: string;
  controllerID?: number;
  controllerName?: string;
}

export const AddServiceCalls: FC<Props> = ({
  changedServiceCalls,
  faultEquipmentCode,
  customerID,
  customerName,
  controllerID,
  controllerName,
}) => {
  const dispatch = useAppDispatch();

  const initialValues = {
    serviceCallID: '',
    createDate: getFormattedDate(new Date()),
    currentStatusCode: 0,
    currentStatus: '',
    customerID: customerID,
    customerName: customerName,
    closeDate: '',
    controllerID: controllerID,
    controllerName: controllerName,
    openByUserID: 0,
    openByUserName: '',
    faultEquipmentCode: faultEquipmentCode ? faultEquipmentCode : null,
    faultEquipment: '',
    faultDescription: '',
    notesForTechnician: '',
    assignedTechnicianID: null,
    assignedTechnicianName: '',
    actionDescription: '',
    takenActionCode: 0,
    takenAction: '',
    replacedEquipment1Code: 0,
    replacedEquipment1: '',
    replacedEquipment2Code: 0,
    replacedEquipment2: '',
  };
  const { openAddServiceCall } = useAppSelector((st) => st.modals);
  const [values, setValues] = useState<ServiceCall>(initialValues);
  const closeModal = () => dispatch(toggleAddServiceCall());
  const [errors, setErrors] = useState(initialErrors);
  const [isChanged, setIsChanged] = useState(false);
  const { batteryStatusReportProject } = useAppSelector((st) => st.batteryStatusReport);
  const { serviceCallsFaultEquipmentCodes, serviceCallsTechnicianCodes, serviceCallsStatusCodes } = useAppSelector(
    (st) => st.serviceCalls
  );
  const { isOpen, anchorEl, onOpenMenu, onCloseMenu } = useAnchorEl();
  const { boxRef } = useAddServiceCallsChanges();
  const { showError, showSuccess } = useToast();
  const {
    isLoading,
    getServiceCallsFaultEquipmentCodes,
    getServiceCallsTechnicianCodes,
    getServiceCallsStatusCodes,
    useAddServiceCall,
  } = useServiceCallsManagement();

  const onChangeValues = (event: any, propertyName: string) => {
    const newValue = event.target.value;
    setValues((prevValues) => ({
      ...prevValues,
      [propertyName]: newValue,
    }));

    setIsChanged(true);
  };
  const onChangeSelectValues = (e: SelectChangeEvent<unknown>) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const handleResetSelect = (value: string) => {
    setValues((prev) => ({ ...prev, [value]: null }));
  };
  const resetErrors = () => {
    setErrors(initialErrors);
  };
  const resetValues = () => {
    initialValues.customerID = customerID ? customerID : batteryStatusReportProject.projectId;
    initialValues.customerName = customerName ? customerName : batteryStatusReportProject.projectName;
    initialValues.controllerID = controllerID ? controllerID : batteryStatusReportProject.deviceId;
    initialValues.controllerName = controllerName ? controllerName : batteryStatusReportProject.deviceName;

    setValues(initialValues);
  };
  const fetchData = () => {
    getServiceCallsFaultEquipmentCodes();
    getServiceCallsTechnicianCodes();
    getServiceCallsStatusCodes();
  };
  useEffect(() => {
    if (!openAddServiceCall) {
      resetValues();
      resetErrors();
      setIsChanged(false);
    } else {
      fetchData();
      const userName = localStorageService.currentUserName ?? '';
      setValues((prevValues) => ({
        ...prevValues,
        ['openByUserName']: userName,
      }));
    }
  }, [openAddServiceCall]);
  const [hasErrors, setHasErrors] = useState(false);
  const handleSave = () => {
    useAddServiceCall(values);
  };
  const { installDateError, setInstallDateError } = useControllerDateErrors();
  const onSubmit = async () => {
    if (!hasErrors) {
      await handleSave();
      changedServiceCalls();
    }
  };
  const handleCloseAddModal = (e: React.MouseEvent<HTMLElement>) => {
    if (isLoading) return;
    isChanged ? onOpenMenu(e) : closeModal();
  };
  useEffect(() => {
    if (isChanged) {
      validateForm();
    }
  }, [values]);
  const validateForm = () => { };
  const closeButtonRef = useRef<HTMLButtonElement | null>(null);
  const handleModalClose = () => {
    if (closeButtonRef.current) {
      closeButtonRef.current.click();
    }
  };
  return (
    <Modal width={'100%'} open={openAddServiceCall} onClose={handleModalClose}>
      <Box sx={{ paddingRight: '10px' }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <BigTitle>{t('service_calls_add_modal')}</BigTitle>

          <IconButton ref={closeButtonRef} onClick={handleCloseAddModal}>
            <img src={close_popup} />
          </IconButton>
        </Stack>
        {values && (
          <Box component="form" autoComplete="off">
            <Row spacing="20px" alignItems="flex-start" justifyContent="space-between">
              <Box width={'25%'}>
                <BaseInput
                  label={`${t('service_call_ID_add_modal')}`}
                  name="serviceCallID"
                  disabled
                  helperText=""
                  value={values?.serviceCallID ?? ''}
                  onChange={(event) => onChangeValues(event, 'serviceCallID')}
                  small
                />
                <BaseInput
                  label={`${t('customer_id_add_modal')}`}
                  name="customerID"
                  disabled
                  helperText=""
                  value={values?.customerID ?? ''}
                  onChange={(event) => onChangeValues(event, 'customerID')}
                  small
                />
              </Box>
              <Box width={'25%'}>
                <Select
                  label={`${t('current_status_code_add_modal')}`}
                  name="currentStatusCode"
                  value={values?.currentStatusCode}
                  onChange={onChangeSelectValues}
                  displayEmpty
                  sx={{
                    fontFamily: 'Noto Sans Hebrew !important',
                  }}
                  small
                >
                  {serviceCallsStatusCodes.map((a) => (
                    <MenuItem key={a.code} value={a.code}>
                      {t(a.text)}
                    </MenuItem>
                  ))}
                </Select>
                <BaseInput
                  label={`${t('customer_name_add_modal')}`}
                  name="customerName"
                  disabled
                  helperText=""
                  value={values?.customerName ?? ''}
                  onChange={(event) => onChangeValues(event, 'customerName')}
                  small
                />
              </Box>
              <Box width={'25%'}>
                <BaseInput
                  label={`${t('create_date_add_modal')}`}
                  name="createDate"
                  disabled
                  helperText=""
                  value={values?.createDate ?? ''}
                  onChange={(event) => onChangeValues(event, 'createDate')}
                  small
                />
                <BaseInput
                  label={`${t('controller_ID_add_modal')}`}
                  name="controllerID"
                  disabled
                  helperText=""
                  value={values?.controllerID ?? ''}
                  onChange={(event) => onChangeValues(event, 'controllerID')}
                  small
                />
              </Box>
              <Box width={'25%'}>
                <BaseInput
                  label={`${t('open_by_user_name_add_modal')}`}
                  name="openByUserName"
                  disabled
                  helperText=""
                  value={values?.openByUserName ?? ''}
                  onChange={(event) => onChangeValues(event, 'openByUserName')}
                  small
                />
                <BaseInput
                  label={`${t('controller_name_add_modal')}`}
                  name="controllerName"
                  disabled
                  helperText=""
                  value={values?.controllerName ?? ''}
                  onChange={(event) => onChangeValues(event, 'controllerName')}
                  small
                />
              </Box>
            </Row>

            <Row spacing="20px" alignItems="flex-start" justifyContent="space-between">
              <Box width={'25%'} sx={{ paddingRight: '12px' }}>
                <Select
                  label={`${t('fault_Equipment_code_add_modal')}`}
                  name="faultEquipmentCode"
                  value={values?.faultEquipmentCode}
                  onChange={onChangeSelectValues}
                  displayEmpty
                  sx={{
                    fontFamily: 'Noto Sans Hebrew !important',
                  }}
                  small
                >
                  {serviceCallsFaultEquipmentCodes.map((a) => (
                    <MenuItem key={'fault_Equipment' + a.code} value={a.code}>
                      {t(a.text)}
                    </MenuItem>
                  ))}
                </Select>
                <Row width={'100%'}>
                  <Box width={'100%'}>
                    <Select
                      label={`${t('assigned_technician_ID_add_modal')}`}
                      name="assignedTechnicianID"
                      value={values.assignedTechnicianID}
                      onChange={onChangeSelectValues}
                      displayEmpty
                      sx={{
                        fontFamily: 'Noto Sans Hebrew !important',
                      }}
                      small
                    >
                      {serviceCallsTechnicianCodes.map((a) => (
                        <MenuItem key={a.code} value={a.code}>
                          {a.text}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                  {values?.assignedTechnicianID && (
                    <Box
                      width={36}
                      sx={{
                        paddingTop: '44px',
                        paddingLeft: '7px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Icon
                        sx={{
                          width: '26px',
                          cursor: 'pointer',
                          ml: '0px',
                          mr: '0px',
                        }}
                        src={close_blue}
                        onClick={() => handleResetSelect('assignedTechnicianID')}
                      />
                    </Box>
                  )}
                </Row>
              </Box>
              <Box width={'75%'}>
                <Row spacing="20px" alignItems="flex-end" justifyContent="space-between">
                  <BaseInput
                    label={`${t('fault_description_add_modal')}`}
                    name="faultDescription"
                    value={values?.faultDescription ?? ''}
                    onChange={(event) => onChangeValues(event, 'faultDescription')}
                    helperText=""
                    medium
                    fullWidth
                  />
                </Row>
                <Row spacing="20px" alignItems="flex-end" justifyContent="space-between">
                  <BaseInput
                    label={`${t('notes_for_technician_add_modal')}`}
                    name="notesForTechnician"
                    value={values?.notesForTechnician ?? ''}
                    onChange={(event) => onChangeValues(event, 'notesForTechnician')}
                    helperText=""
                    medium
                    fullWidth
                  />
                </Row>
              </Box>
            </Row>

            <Row justifyContent="flex-end" mt={'35px'}>
              <Row width={300}>
                <BaseBtn onClick={handleCloseAddModal} btnColor="info.main" fullWidth>
                  {t('cancel_add_modal')}
                </BaseBtn>
                <Box ref={boxRef} onClick={handleCloseAddModal} sx={{ height: '40px', width: '25px' }} />
                <BaseBtn
                  onClick={onSubmit}
                  disabled={!isChanged || hasErrors || !values.faultEquipmentCode}
                  loading={isLoading}
                  btnColor="primary.dark"
                  fullWidth
                >
                  {t('save_add_modal')}
                </BaseBtn>
              </Row>
            </Row>
          </Box>
        )}
        <Confirm
          open={isOpen}
          anchorEl={anchorEl}
          onClose={onCloseMenu}
          confirm={closeModal}
          buttonTexts={[t('cancel_add_add_modal'), t('back_to_add_add_modal')]}
        />
      </Box>
    </Modal>
  );
};

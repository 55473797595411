import { useAppSelector } from 'shared/hooks';
import { useDailyConsumption } from 'shared/api';
import { useEffect } from 'react';
import { Controller } from 'features/ControllerManager/controllerModels';
export const useGetDefaultDates = (currentController: Controller) => {
  const { getDailyConsumptionInitialdates } = useDailyConsumption();

  const { defaultData } = useAppSelector((st) => st.controllers);
  const fetchData = async () => {
    if (currentController) {
      //  getDailyConsumption({ objectID: currentController.deviceId, startDate: filter.fromDate, endDate: filter.toDate });
      await getDailyConsumptionInitialdates(currentController.deviceId);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  //const { IRRIGATION_DATA } = useAppSelector((st) => st.globalSlice);

  const fromDate = defaultData.startDate;
  const toDate = defaultData.endDate;
  return {
    fromDate,
    toDate,
  };
};

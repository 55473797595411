import { IconButton, Stack } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector, } from 'shared/hooks';
import { toggleOpenNotSupportModal } from 'shared/slices';
import { BigTitle, Modal, Row, Select } from 'shared/ui';
import { BaseBtn } from '../../shared/ui';
import React from 'react';
import { Box } from '@mui/material';
import { close_popup } from 'shared/assets';

export const NotSupportModal: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { openNotSupportModal } = useAppSelector((st) => st.modals);

  const closeModal = () => dispatch(toggleOpenNotSupportModal());
  const handleCloseEditModal = (e: React.MouseEvent<HTMLElement>) => {
    closeModal();
  };

  return (
    <Modal padding={'20px'} height={'calc(100vh - 20px)'} open={openNotSupportModal} onClose={handleCloseEditModal}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <BigTitle >{t('the_current_version_does_not_support_this_feature')}</BigTitle>
        <IconButton onClick={handleCloseEditModal}>
          <img src={close_popup} />
        </IconButton>
      </Stack>
      <Box>
        <Box component="form" autoComplete="off">
          <Row justifyContent="flex-end" mt={'35px'}>
            <Row width={300}>
              <BaseBtn
                onClick={handleCloseEditModal}
                btnColor="primary.dark"
                fullWidth
              >
                {t('ok_edit_modal')}
              </BaseBtn>
            </Row>
          </Row>
        </Box>
      </Box>
    </Modal>
  );
};

import React from 'react';
import { Box, Button, Link } from '@mui/material';
import { AuthInput, BaseBtn, Row, Text } from 'shared/ui';
import { useLogin } from 'shared/api';
import { HelloToast } from 'features';
import { useCheckToken, useExtendedTranslation } from 'shared/hooks';
import localStorageService from 'shared/localStorage';
// import { toggleExpirationToken } from 'shared/slices';
// import { useAppSelector } from 'shared/hooks';
// import { useAppDispatch } from 'shared/hooks';
export const LoginForm = () => {
  useCheckToken();
  const { t } = useExtendedTranslation();
  // const dispatch = useAppDispatch();
  const [isError, setIsError] = React.useState(false);
  const [isForgot, setIsForgot] = React.useState(false);
  const [authInfo, setAuthInfo] = React.useState({ userName: '', password: '' });

  // const { openExpirationToken } = useAppSelector((st) => st.popups);

  const forgotLink = process.env.REACT_APP_FORGOT_URL;

  const handleChangeUserName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAuthInfo((prev) => ({ ...prev, userName: event.target.value }));
  };
  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAuthInfo((prev) => ({ ...prev, password: event.target.value }));
  };
  const toggleForgot = () => setIsForgot((prev) => !prev);

  const { isLoading, login } = useLogin();

  const onClickSubmit = async () => {
    if (!authInfo.userName || !authInfo.password) {
      setIsError(true);
      return;
    }
    login(authInfo);

    localStorageService.currentUserName = authInfo.userName;
    localStorageService.invalidToken = undefined;
    
  };
  // const closeExpirationTokenPopup = () => {
  //   dispatch(toggleExpirationToken());
  // };

  return (
    <Box component="form" autoComplete="off" sx={{ width: '573px' }}>
      <Text
        sx={{ color: 'primary.main', fontSize: '32px', lineHeight: '48px', fontFamily: 'Noto Sans Hebrew', mb: '10px' }}
      >
        {t('login_title')}
      </Text>

      <AuthInput
        name="login"
        value={authInfo.userName}
        onChange={handleChangeUserName}
        error={isError && !authInfo.userName}
        placeholder={`${t('login_username')}`}
        // autoComplete={'nope'}
      />
      <AuthInput
        name="password"
        value={authInfo.password}
        onChange={handleChangePassword}
        error={isError && !authInfo.password}
        placeholder={`${t('login_password')}`}
        type="password"
        // autoComplete={'nope'}
      />

      <Row justifyContent="flex-end">
        <Button
          onClick={toggleForgot}
          sx={{
            color: 'info.dark',
            fontFamily: 'Noto Sans Hebrew',
            mb: '40px',
            mt: '10px',
            textTransform: 'capitalize',
            borderRadius: '10px',
            fontSize: '16px',
            lineHeight: '24px',
          }}
        >
          {t('login_forgot_password')}
        </Button>
      </Row>

      <BaseBtn
        onClick={onClickSubmit}
        loading={isLoading}
        fullWidth
        btnColor="primary.main"
        sx={{
          borderRadius: '30px',
          height: '66px',
          fontSize: '24px',
          fontFamily: 'Noto Sans Hebrew',
        }}
      >
        {t('login_button')}
      </BaseBtn>

      <HelloToast message={t('hi_there_hello_toast_title')} open={isForgot} onClose={toggleForgot}>
        {forgotLink && forgotLink !== '' && (
          <>
            <Text sx={{ fontFamily: 'Noto Sans Hebrew', fontSize: 13, lineHeight: '17px' }}>
              {t('do_you_have_a_problem?_Just_use_this_hello_toast_text')}
            </Text>
            <Link
              href={forgotLink}
              target="blank"
              sx={{ color: 'info.main', ml: '6px', textDecoration: 'underline', cursor: 'pointer' }}
            >
              {t('contact_form._hello_toast_text')}
            </Link>
          </>
        )}
      </HelloToast>
      {/* <HelloToast
        message={t('hi_there_hello_toast_title')}
        open={openExpirationToken}
        onClose={closeExpirationTokenPopup}
      >
        <Text sx={{ fontFamily: 'Noto Sans Hebrew', fontSize: 13, lineHeight: '17px', fontWeight: '700' }}>
          {t('your_session_has_expired_toast_text')}
        </Text>
        <Text sx={{ fontFamily: 'Noto Sans Hebrew', fontSize: 13, lineHeight: '17px', fontWeight: '400' }}>
          {t('please_log_in_again_to_continue_toast_text')}
        </Text>
      </HelloToast> */}
    </Box>
  );
};

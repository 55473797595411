import { Layout } from '../../shared/ui';
import { Header } from '../../widgets';
import { ExternalPowerProblemsReportManager } from '../../features';
export const ExternalPowerProblemsReport = () => {
  return (
    <Layout>
      <Header />
      <Layout.Body>
        <ExternalPowerProblemsReportManager />
        {/* <BatteryStatusReportManager />
        <BatteryStatusReportGraph /> */}
      </Layout.Body>
    </Layout>
  );
};

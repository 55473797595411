import { IconButton, Stack } from '@mui/material';
import { User, UserAccountsItem } from 'features/UserManager/UserModels';
import { useUserManagement } from 'features/UserManager/hooks/api';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { close_popup } from 'shared/assets';
import { BaseBtn, BigTitle, Modal, Row } from 'shared/ui';

interface ModalState {
  isOpenModal: boolean
  closeModal: () => void
  currentUser: User
  currentAssociateAccount: UserAccountsItem
}

export const DeleteAssociateAccountModal: FC<ModalState> = (props: ModalState) => {
  const { isOpenModal, closeModal, currentUser, currentAssociateAccount } = props;
  const { t } = useTranslation();
  const { isLoading, deleteAssociatedAccount } = useUserManagement();

  const handleDelete = async () => {
    const data = {
      userId: currentUser?.userID,
      associatedProjectId: currentAssociateAccount?.customerID,
      associatedUserId: currentAssociateAccount?.userID,
      accessCode: 0,
    };
    await deleteAssociatedAccount(data);
    closeModal();
  };

  return (
    <Modal open={isOpenModal} onClose={closeModal}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <BigTitle>{t('delete-associate_account_delete_modal')}</BigTitle>

        <IconButton onClick={closeModal}>
          <img src={close_popup} />
        </IconButton>
      </Stack>

      <Row justifyContent="center" mt={'35px'}>
        <Row spacing="15px" width={500}>
          <BaseBtn onClick={closeModal} btnColor="info.main" fullWidth>
            {t('cancel_delete_modal')}
          </BaseBtn>

          <BaseBtn onClick={handleDelete} loading={isLoading} btnColor="primary.dark" fullWidth>
            {t('delete_delete_modal')}
          </BaseBtn>
        </Row>
      </Row>
    </Modal>
  );
};

import { FC, useMemo, memo, useState } from 'react';
import { Box, IconButton, Typography, styled } from '@mui/material';
import { Cell } from 'shared/ui';
import { useAnchorEl, useAppDispatch, useAppSelector } from 'shared/hooks';
import { GridRowParams } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { DeviceModelTypes } from 'shared/models';
import { getFormattedDate } from 'shared/lib';
import { CellWithIcon } from './components';
import { Controller } from 'shared/models';
import { setCurrentController, toggleEditController } from 'shared/slices'; //
const SelectMark = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '5px',
  p: 0,
  height: '100%',
  backgroundColor: theme.palette.primary.dark,
  borderTopRightRadius: '10px',
  borderBottomRightRadius: '10px',
}));

interface visibleColumns {
  width: number;
}
interface CustomGridRowParams extends GridRowParams {
  visibleColumns: visibleColumns[];
  modelTypeFilter: DeviceModelTypes;
}

export const RealTimeControllerItem: FC<CustomGridRowParams> = memo((props: CustomGridRowParams) => {
  const { customerId, status, customerName, deviceId, objectName, hwId, modelId, state } = props.row;
  const { visibleColumns, modelTypeFilter } = props;
  const { currentController } = useAppSelector((st) => st.controllers);
  const cellSizes = visibleColumns.map((s) => s.width);
  const [activeModal, setActiveModal] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { isOpen, anchorEl, onOpenMenu, onCloseMenu } = useAnchorEl();
  const handleOpenEditModal = () => {
    onCloseMenu();
    setActiveModal(!activeModal);
    dispatch(setCurrentController(props.row));
    dispatch(toggleEditController());
  };
  const selected = currentController?.deviceId === deviceId;
  return (
    <>
      <Box
        id={`controller_${deviceId}`}
        sx={{
          '&': {
            bgcolor: activeModal || selected ? '#d0e7ff' : 'info.main',
          },
          '&:hover': {
            bgcolor: activeModal || selected ? '#c0d7ff' : '#f0f0f0',
          },
        }}
        onDoubleClick={handleOpenEditModal}
      >
        <>
          {/* <Cell cellSize={cellSizes[0]} hideTooltip sx={{ position: 'relative' }}>
            <Checkbox
              size="small"
              checked={checked}
              onChange={onChangeSelectedControllers}
              icon={<Icon src={un_checked_box} />}
              checkedIcon={<Icon src={checked_box} />}
            ></Checkbox> 
          </Cell>*/}

          <Cell cellSize={cellSizes[0]} hideTooltip sx={{ position: 'relative' }}>
            {deviceId}
            {selected && <SelectMark />} <IconButton sx={{ height: '33px', opacity: 0 }}></IconButton>
          </Cell>
          <Cell cellSize={cellSizes[1]} sx={{ verticalAlign: 'middle' }}>
            <Box>
              <CellWithIcon status={state?.calculated?.state} modelId={modelId} />
            </Box>
          </Cell>
          <Cell onClick={handleOpenEditModal} cellSize={cellSizes[2]}>
            {objectName}
          </Cell>
          <Cell cellSize={cellSizes[3]}>{customerId}</Cell>
          <Cell cellSize={cellSizes[4]}>{customerName}</Cell>
          <Cell cellSize={cellSizes[5]}>{hwId}</Cell>
          <Cell cellSize={cellSizes[6]}>{modelId}</Cell>
          {modelTypeFilter === 'I1' && (
            <>
              <Cell cellSize={cellSizes[7]}>{state?.alarmStatus}</Cell>
              <Cell cellSize={cellSizes[8]}>{state?.batteryVoltage}</Cell>
              <Cell cellSize={cellSizes[9]}>{state?.controllerTime}</Cell>
              <Cell cellSize={cellSizes[10]}>{state?.dataSource}</Cell>
              <Cell cellSize={cellSizes[11]}>{state?.syncDate ? getFormattedDate(state.syncDate as string) : ''}</Cell>
            </>
          )}
          {modelTypeFilter === 'IP' && (
            <>
              <Cell cellSize={cellSizes[7]}>{state?.batteryVoltage}</Cell>
              <Cell cellSize={cellSizes[8]}>{state?.currentFlow}</Cell>
              <Cell cellSize={cellSizes[9]}>{state?.dataSource}</Cell>
              <Cell cellSize={cellSizes[10]}>{state?.syncDate ? getFormattedDate(state.syncDate as string) : ''}</Cell>
              <Cell cellSize={cellSizes[11]}>{state?.totalSlavesFlow}</Cell>
            </>
          )}
          {modelTypeFilter === 'WE' && (
            <>
              <Cell cellSize={cellSizes[7]}>{state?.batteryVoltage}</Cell>
              <Cell cellSize={cellSizes[8]}>{state?.dataSource}</Cell>
              <Cell cellSize={cellSizes[9]}>{state?.rainRate}</Cell>
              <Cell cellSize={cellSizes[10]}>{state?.syncDate ? getFormattedDate(state.syncDate as string) : ''}</Cell>
            </>
          )}
        </>
      </Box>
    </>
  );
});

import { FC, memo } from 'react';
import { Row } from 'shared/ui';
import { Typography, Box, Checkbox } from '@mui/material';
import { Icon } from 'shared/ui';
import { SxProps } from '@mui/system';
import { checked_box, clear_filter, un_checked_box } from 'shared/assets';
type Props = {
  labels: Record<string, string>;
  onChange: (value: string) => void;
  value: string;
  sx?: SxProps;
  selected?: boolean;
  withCheckboxes?: boolean;
  checked?: string[];
  hideValue?: boolean;
  tryToDelete?: () => void;
  showDeleteIcon?: boolean;
};
const FormControlLabel = ({ labels, sx, value, onChange, selected, withCheckboxes, checked, hideValue, tryToDelete, showDeleteIcon }) => {

  return (
    <Row
      onClick={() => onChange(value)}
      sx={{
        height: '33px',
        px: '2px',
        cursor: 'pointer',
        color: 'black',
        display: 'flex',
        justifyContent: 'space-around',
        borderBottom: '1px solid ',
        borderColor: selected ? 'primary.dark' : 'secondary.light',
        mx: '10px',
        fontSize: '12px',
        '&:hover': { bgcolor: 'secondary.contrastText' },
        ...sx,
      }}
    >
      {withCheckboxes && (
        <Checkbox
          size="small"
          checked={checked?.includes(value)}
          icon={<Icon src={un_checked_box} />}
          checkedIcon={<Icon src={checked_box} />}
        ></Checkbox>
      )}
      {!hideValue && (
        <Box
          sx={{
            width: '20%',
          }}
        >
          {labels.value}
        </Box>
      )}
      <Typography
        noWrap
        sx={{
          width: hideValue ? '100%' : '70%',
          fontWeight: 'revert',
          fontSize: 'revert',
        }}
      >
        {labels.label}
      </Typography>
      {showDeleteIcon && <Icon src={clear_filter} onClick={(event) => {
        event.stopPropagation();
        tryToDelete()
      }} />}
    </Row>
  );
};
export const CustomFormControlLabel = memo(FormControlLabel) as (props: Props) => React.ReactElement;

import { Box, Checkbox, Tooltip } from '@mui/material';
import { BaseTable, CustomerSelect, PagePreloader } from 'components';
import { BaseExportOptions } from 'entities_';
import { BaseDataPicker, Filter, SelectWithDelete } from 'features';
import { EditController } from 'features/ControllerManager/components';
import { useEffect, useMemo, useState } from 'react';
import { useBatteryStatusReport } from 'shared/api';
import { action_start, cancel, checked_box, delete_icon, un_checked_box, } from 'shared/assets';
import { useAppDispatch, useAppSelector, useExtendedTranslation } from 'shared/hooks';
import { BatteryStatusExcludeFilter, BatteryStatusItem, Controller } from 'shared/models';
import { setBatteryStatusFilter, setBatteryStatusItems } from 'shared/slices';
import { BaseBtn, BaseTimePicker, BigTitle, Icon, Row } from 'shared/ui';
import TotalRows from 'shared/ui/TotalRows';
import { BatteryStatusReportGraph, ExcludeFromReportModal } from 'widgets';
import { ChangeExpirationDate } from './components/ChangeExpirationDate';
import { TABLE_TITLES, activeHoursItems, disconnectsItems, serviceCallsItems } from './constants/index';
import { useFormatXlsxData, useGetDefaultParams, useSortItems } from './hooks';
export const BatteryStatusReportManager = () => {
	const {
		getBatteryStatusReport,
		getBatteryStatusExcludeDevice,
		getBatteryStatusExcludeCustomer,
		deleteExcludedController,
		deleteExcludedProject,
		isLoadingBatteryStatusReport,
		addBatteryStatusExcludeController,
		addBatteryStatusExcludeProject,
	} = useBatteryStatusReport();
	const { BATT_REP } = useAppSelector((st) => st.globalSlice);
	const { formatTable } = useFormatXlsxData();
	const dispatch = useAppDispatch();
	const { excludeControllers, excludeProjects, items } = useAppSelector((st) => st.batteryStatusReport);
	const [excludeParamID, setExcludeParamID] = useState(0);
	const [controllerName, setControllerName] = useState<string>('');
	const [clientName, setClientName] = useState<string>('');
	const [excludeParamType, setExcludeParamType] = useState<'objectId' | 'projectId'>('objectId');
	const [selectedController, setSelectedController] = useState<Controller>(null);
	const [openControllerEditModal, setOpenControllerEditModal] = useState(false);
	const [openExcludeFromReportModal, setOpenExcludeFromReportModal] = useState(false);
	const [advancedParameters, setAdvancedParameters] = useState(false);
	const [loading, setLoading] = useState(false);
	const [loadedItems, setLoadedItems] = useState(false);
	const { t } = useExtendedTranslation();
	const activeHoursItemsValues = activeHoursItems.map((item) => ({
		value: item.value,
		label: `${item.value} ${t(item.label)}`,
	}));
	const serviceCallsItemsValues = serviceCallsItems.map((item) => ({
		value: item.value,
		label: `${t(item.label)}`,
	}));
	const [openChangeExpirationDate, setOpenChangeExpirationDate] = useState(false);
	const [isStartWasPressed, setIsStartWasPressed] = useState(false);
	const toggleAdvancedParameters = () => {
		setAdvancedParameters(!advancedParameters);
	};
	useEffect(() => {
		const fetchData = async () => {
			getBatteryStatusExcludeDevice();
			getBatteryStatusExcludeCustomer();
		};
		fetchData();
	}, []);
	const {
		startDate,
		endDate,
		dayTimeStart,
		dayTimeEnd,
		nightTimeStart,
		nightTimeEnd,
		connectHMin,
		daysPositiveP,
		saveToLocalStorage,
	} = useGetDefaultParams();
	const initialFilter = {
		// startDate: '2022-12-28',
		// endDate: '2022-12-30',
		startDate: startDate,
		endDate: endDate,
		dayTimeStart,
		dayTimeEnd,
		nightTimeStart,
		nightTimeEnd,
		connectHMin,
		daysPositiveP,
		serviceCallsFilter: '',
		customerFilter: '',
	};

	const [filter, setfilter] = useState<BatteryStatusExcludeFilter>(initialFilter);
	useEffect(() => {

		dispatch(setBatteryStatusFilter(filter));
	}, [filter]);
	const onChangeDateValues = (param: string, value: string) => {
		setfilter((prevValues) => ({
			...prevValues,
			[param]: value,
		}));
	};
	const onChangeActiveHoursFilter = (value: string) => {
		setfilter((prevValues) => ({
			...prevValues,
			connectHMin: value,
		}));
	};
	const onChangeDisconnectsFilter = (value: string) => {
		setfilter((prevValues) => ({
			...prevValues,
			daysPositiveP: value,
		}));
	};
	const onChangeServiceCallsFilter = (value: string) => {
		setfilter((prevValues) => ({
			...prevValues,
			serviceCallsFilter: value,
		}));
	};
	const onChangeCustomerFilter = (value: string) => {
		setfilter((prevValues) => ({
			...prevValues,
			customerFilter: value,
		}));
	};
	const onChangeDayTime = (value: string) => {
		const activeHours = value.split('-');
		setfilter((prevValues) => ({
			...prevValues,
			dayTimeStart: activeHours[0],
			dayTimeEnd: activeHours[1],
		}));
	};
	const onChangeNightTime = (value: string) => {
		const activeHours = value.split('-');
		setfilter((prevValues) => ({
			...prevValues,
			nightTimeStart: activeHours[0],
			nightTimeEnd: activeHours[1],
		}));
	};

	const generateReport = async () => {
		saveToLocalStorage(filter);
		await dispatch(setBatteryStatusItems([]));
		await setIsStartWasPressed(true);
		await getBatteryStatusReport(filter);
		setLoadedItems(true)
	};
	const onDeleteExcludedController = async (value: string) => {
		await deleteExcludedController(value);
		await getBatteryStatusExcludeDevice();
		await getBatteryStatusReport(filter);
	};
	const onDeleteExcludedProject = async (value: string) => {
		await deleteExcludedProject(value);
		await getBatteryStatusExcludeCustomer();
		await getBatteryStatusReport(filter);
	};
	const filteredByCellsFilter = items.filter((c) => {
		if (filter.serviceCallsFilter == 'Hide') return !c.lastOpenServiceCallDate;
		if (filter.serviceCallsFilter == 'Show') return c.lastOpenServiceCallDate;
		return true;
	});
	const filteredItems = filteredByCellsFilter.filter((c) => {
		return filter.customerFilter ? filter.customerFilter === c.projectId.toString() : true;
	});


	const changedServiceCalls = () => {
		getBatteryStatusReport(filter);
	};
	const excludeController = (item: BatteryStatusItem) => {
		setExcludeParamType('objectId');
		setExcludeParamID(item.objectId);
		setControllerName(item.controllerName);
		setOpenExcludeFromReportModal(true);

	};
	const excludeProject = (item: BatteryStatusItem) => {
		setExcludeParamType('projectId');
		setExcludeParamID(item.projectId);
		setClientName(item.projectName);
		setOpenExcludeFromReportModal(true);
	};
	const closeExcludeFromReportModal = () => {
		setOpenExcludeFromReportModal(false);
		setClientName('');
		setControllerName('');
	};
	const excludeFromReport = async (upToDate: string) => {
		setLoading(true)
		console.log("loading")
		closeExcludeFromReportModal();
		if (excludeParamType === 'objectId') {
			await addBatteryStatusExcludeController(excludeParamID, upToDate);
			await getBatteryStatusExcludeDevice();
		}
		if (excludeParamType === 'projectId') {
			await addBatteryStatusExcludeProject(excludeParamID, upToDate);
			await getBatteryStatusExcludeCustomer();
		}

		// addBatteryStatusExcludeController,
		// addBatteryStatusExcludeProject,

		await getBatteryStatusReport(filter);
		setLoading(false)
		console.log("loading end")
	};
	const expirationDateClick = () => {
		setOpenChangeExpirationDate(true);
	};
	const closeChangeExpirationDateModal = () => {
		setOpenChangeExpirationDate(false);
	};
	const handleOpenControllerEditModal = (row) => {
		setSelectedController(row);
		setOpenControllerEditModal(true);
		// dispatch(setCurrentBatteryStatusReportProjectID(objectId));
		// dispatch(setBatteryStatusReportProject(props.row));
		// dispatch(toggleOpenShowGraphModal());
	}
	const scrollToSelectedRow = () => {

	}
	const actions = useMemo(
		() => [
			{ icon: delete_icon, title: `${t('exclude_controller_table_modal')}`, onClick: (value) => excludeController(value) },
			{ icon: delete_icon, title: `${t('exclude_project_table_modal')}`, onClick: (value) => excludeProject(value) },
			{ icon: cancel, title: `${t('cancel_table_modal')}`, onClick: () => { } },
		],
		[]
	);
	const BaseExportToExcelComponent = useMemo(
		() => (<BaseExportOptions
			tableData={filteredItems}
			sheetName="BATTERY STATUS REPORT"
			fileName="batteryStatusReportItems.xlsx"
			buttonText={t('export_battery_status_report_items_page_button')}
			dateColumnIndexes={[8, 9]}
			stringColumnIndexes={[6, 12]}
			formattedTable={formatTable(filteredItems)}
			tableTitles={TABLE_TITLES}
			headerItemsPrefix={'_battery_status_report_grid_table'}
		/>

		)
		,
		[filteredItems]
	);
	return (
		<>
			<PagePreloader loading={loading || isLoadingBatteryStatusReport} />
			<>
				<Row sx={{ mb: '12px' }}>
					<BigTitle sx={{ mr: '25px' }}>{t('battery_status_report_title')}</BigTitle>
				</Row>

				<Row sx={{ mt: '6px' }} flexWrap="wrap" rowGap="5px" alignItems="flex-end">
					<BigTitle sx={{ fontSize: '24px', mr: '20px' }}>{t('battery_status_report_start')}</BigTitle>
					<BaseDataPicker
						label={`${t('start_date_filter_battery_status_report_label')}`}
						name="startDate"
						value={filter.startDate}
						onChange={onChangeDateValues}
					/>
					<BigTitle sx={{ fontSize: '24px', ml: '20px', mr: '20px' }}>{t('battery_status_report_end')}</BigTitle>
					<BaseDataPicker
						label={`${t('end_date_filter_battery_status_report_label')}`}
						name="endDate"
						value={filter.endDate}
						onChange={onChangeDateValues}
						error={null}
					/>
					<Checkbox
						sx={{ ml: '20px' }}
						size="small"
						checked={advancedParameters}
						onChange={toggleAdvancedParameters}
						icon={<Icon src={un_checked_box} />}
						checkedIcon={<Icon src={checked_box} />}
					></Checkbox>

					<Tooltip
						disableHoverListener={!excludeControllers.length && !excludeProjects.length}
						title={`${t('excluded_controllers_tooltip')} ${excludeControllers.length}
               ${t('excluded_projects_tooltip')} ${excludeProjects.length}`}
						placement="bottom"
					>
						<Box sx={{ mb: '5px' }}>
							{t('advanced_parameters_filter_battery_status_report')}
							{excludeControllers.length || excludeProjects.length ? <span>*</span> : <></>}
						</Box>
					</Tooltip>
					<Box sx={{ p: '5px', cursor: 'pointer' }}></Box>
					{!advancedParameters && (
						<>
							<BaseBtn onClick={generateReport} sx={{ mt: '16px', height: '29px', mr: '20px' }}>
								{t('battery_status_action_start')}
								<Icon sx={{ ml: '6px' }} src={action_start} />
							</BaseBtn>
							<Row sx={{ flexGrow: 1 }} />
							<Box sx={{ display: 'flex', marginLeft: 'auto', alignItems: 'flex-end' }}>
								<CustomerSelect
									title={`${t('customer_label')}`}
									value={filter.customerFilter}
									onChange={onChangeCustomerFilter}
									hideAllButton
									showHomeButton
									showOnlyResetButton
									withSearch
									sx={{ width: '260px' }}
								/>
								<Filter
									title={`${t('service_calls_label')}`}
									value={filter.serviceCallsFilter}
									onChange={onChangeServiceCallsFilter}
									items={serviceCallsItemsValues}
									messageEmptyFilter="disconnect_display_all"
									sx={{ width: '260px' }}
									placeholder={`${t('filter_service_calls_battery_status_page_placeholder')}`}
									hideResetButton
								/>
								<TotalRows count={filteredItems.length} />
								{BaseExportToExcelComponent}
							</Box>
						</>
					)}
				</Row>
				{advancedParameters && (
					<Row sx={{ mt: '6px' }} flexWrap="wrap" rowGap="5px" alignItems="flex-end">
						<BigTitle sx={{ fontSize: '14px', mr: '20px', fontWeight: '600' }}>
							{t('battery_status_report_advanced')}
						</BigTitle>
						<BaseTimePicker
							startTimeLabel={t('battery_status_report_day_time_logout_starting_at')}
							endTimeLabel={t('battery_status_report_day_time_logout_finishing_at')}
							label={`${t('battery_status_report_day_time_label')}`}
							minHour={0}
							maxHour={23}
							value={`${filter.dayTimeStart}-${filter.dayTimeEnd}`}
							onChanged={onChangeDayTime}
							marginRight="15px"
						/>
						<BaseTimePicker
							startTimeLabel={t('battery_status_report_night_time_logout_starting_at')}
							endTimeLabel={t('battery_status_report_night_time_logout_finishing_at')}
							label={`${t('battery_status_report_night_time_label')}`}
							minHour={0}
							maxHour={23}
							value={`${filter.nightTimeStart}-${filter.nightTimeEnd}`}
							onChanged={onChangeNightTime}
							marginRight="15px"
						/>
						<Filter
							title={`${t('disconnects_filter')}`}
							value={filter.daysPositiveP}
							onChange={onChangeDisconnectsFilter}
							items={disconnectsItems}
							hideAllButton
							messageEmptyFilter="disconnect_display_all"
							sx={{ width: '260px' }}
							placeholder={`${t('filter_search_battery_status_page_placeholder')}`}
							hideResetButton
						/>
						<SelectWithDelete
							title={`${t('excluded_controllers_filter')}`}
							value={filter.daysPositiveP}
							onChange={onDeleteExcludedController}
							onExpirationDateClick={expirationDateClick}
							items={excludeControllers}
							sx={{ width: '260px' }}
							placeholder={`${t('filter_excluded_controllers_search_battery_status_page_placeholder')}`}
							tooltipText={`${t('controller_to_report_hint')}`}
						/>
						<SelectWithDelete
							title={`${t('excluded_projects_filter')}`}
							value={filter.daysPositiveP}
							onChange={onDeleteExcludedProject}
							onExpirationDateClick={expirationDateClick}
							items={excludeProjects}
							sx={{ width: '260px' }}
							placeholder={`${t('filter_excluded_projects_search_battery_status_page_placeholder')}`}
							tooltipText={`${t('restore_project_hint')}`}
						/>
						<BaseBtn onClick={generateReport} sx={{ mt: '16px', height: '29px', mr: '20px' }}>
							{t('battery_status_action_start')}
							<Icon sx={{ ml: '6px' }} src={action_start} />
						</BaseBtn>
						<Row sx={{ flexGrow: 1 }} />
						<Box sx={{ display: 'flex', marginLeft: 'auto', alignItems: 'flex-end' }}>
							<CustomerSelect
								title={`${t('customer_label')}`}
								value={filter.customerFilter}
								onChange={onChangeCustomerFilter}
								hideAllButton
								showHomeButton
								showOnlyResetButton
								withSearch
								sx={{ width: '260px' }}
							/>
							<Filter
								title={`${t('service_calls_label')}`}
								value={filter.serviceCallsFilter}
								onChange={onChangeServiceCallsFilter}
								items={serviceCallsItemsValues}
								messageEmptyFilter="disconnect_display_all"
								sx={{ width: '260px' }}
								placeholder={`${t('filter_service_calls_battery_status_page_placeholder')}`}
								hideResetButton
							/>
							<TotalRows count={filteredItems.length} />
							{BaseExportToExcelComponent}

						</Box>
					</Row>
				)}

				{loadedItems && <BaseTable
					tableItems={filteredItems}
					loadedItems={true}
					idField={'deviceId'}
					noItemsText={t('no_controllers_report_table_grid_table')}
					headerItemsPrefix={'_battery_status_report_grid_table'}
					dateColumnIndexes={[8, 9]}
					actions={actions}
					selectedRowID={selectedController?.deviceId}
					maxHeight="calc(100vh - 255px)"
					height="0px"
					tableTitles={TABLE_TITLES}
					linkIndex={[4]}
					cellClick={handleOpenControllerEditModal}
				/>}
				{/* )} */}
				<ExcludeFromReportModal
					openModal={openExcludeFromReportModal}
					controllerName={controllerName}
					clientName={clientName}
					onCloseModal={closeExcludeFromReportModal}
					onSubmit={excludeFromReport}
				/>

				{BATT_REP.IS_USE_UNIVERSAL_CONTROLLER_POPUP ? (
					<EditController
						currentController={selectedController}
						openControllerEditModal={openControllerEditModal}
						onCloseModal={() => {
							setOpenControllerEditModal(false)
						}}
						reportEndDate={filter.endDate}
						reportStartDate={filter.startDate}
						faultEquipmentCode={35}
						updateReport={changedServiceCalls}
						showRegistersButton={true}
						defaultTab={2}
						defaultCollapse={["batteryGraph"]}
					/>
				) : (
					<BatteryStatusReportGraph changedServiceCalls={changedServiceCalls} closedModal={scrollToSelectedRow} />
				)}
				<ChangeExpirationDate openModal={openChangeExpirationDate} handleCloseModal={closeChangeExpirationDateModal} />
			</></>
	);
};

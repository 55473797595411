import { Box } from '@mui/material';
import { FC } from 'react';
import { head, login } from './assets';
import { Link, styled } from '@mui/material';
const StyledLink = styled(Link)`
  text-decoration: none;
`;
interface Props {
  isLogin?: boolean;
  to?: string;
}

export const Logo: FC<Props> = ({ isLogin, to }) => {
  return (
    <StyledLink href={to}>
      <Box
        component="img"
        src={isLogin ? login : head}
        alt="logo"
        sx={{
          pt: `${isLogin ? '6px' : '12px'}`,
          mr: '30px',
        }}
      />
    </StyledLink>
  );
};

import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { ComposedChart } from 'components/ComposedChart';
import { FC } from 'react';
import { useBatteryStatusReport } from 'shared/api';
import { FormattedGraphData } from 'shared/lib';
import { Row } from 'shared/ui';

type Props = {
  formattedData: FormattedGraphData[];
  startDate: string;
  endDate: string;
};
export const BatteryStatusReportGraph: FC<Props> = ({ formattedData, startDate, endDate }) => {
  const { isLoadingGraphBatteryStatusReport } = useBatteryStatusReport();
  return (
    <>
      <Box
        sx={{
          margin: '10px auto',
          opacity: isLoadingGraphBatteryStatusReport ? 0 : 1,
          position: isLoadingGraphBatteryStatusReport ? 'absolute' : 'inherit',
          overflow: 'hidden',
        }}
      >
        <ComposedChart
          showEventIds={[1702]}
          data={formattedData}
          startDate={startDate}
          endDate={endDate}
          height={359}
          popupPadding={160}
        />
      </Box>
      {isLoadingGraphBatteryStatusReport && (
        <Row height={260} justifyContent="center" margin="20px auto">
          <CircularProgress disableShrink />
        </Row>
      )}
    </>
  );
};

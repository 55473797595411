import { ChangeEvent, useState } from 'react';

export const useSearchUsers = () => {
  const [searchValue, setSearchValue] = useState('');
  const changeSearch = (e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value);

  return {
    searchValue,
    changeSearch,
  };
};

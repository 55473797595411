import { Box, Typography } from '@mui/material';
import { ControllersMoveTable, TableDataWithId } from 'features/ControllersMoveTable';
import { SelectCustomerFilter } from 'features/SelectCustomerFilter';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { toggleDeviceTransferring, toggleUpdateDeviceTransferring, setCustomerToMove } from 'shared/slices';
import { BaseBtn, Modal, Row } from 'shared/ui';
import { useCustomerToMove } from './hooks/useFilterCustomerToMove';
import { useFilterControllers } from 'features/ControllerManager/hooks';
import { BaseTable, CustomerSelect } from 'components';
import { TABLE_TITLES } from './constants';
import { UpdateDeviceTransferring } from '../UpdateDeviceTransferring';
import localStorageService from 'shared/localStorage';
interface Props {
  openDeviceTransferring: boolean;
  onCloseModal: () => void;
  selectedControllers: number[];
}

export const DeviceTransferring: FC<Props> = ({ selectedControllers, openDeviceTransferring, onCloseModal }) => {
  const { t } = useTranslation();
  const isRTL = localStorageService.isRTL;
  const { controllers } = useAppSelector((st) => st.controllers);
  const { onChangeCustomerToMove, customerItems, selectedCustomerToMove } = useCustomerToMove();
  const { customerFilter } = useFilterControllers();
  const [filteredData, setFilteredData] = useState<TableDataWithId[]>([]);
  const [selectedControllersID, setSelectedControllersID] = useState<number[]>(selectedControllers);
  const [isOpenUpdateDeviceTransferringModal, setIsOpenUpdateDeviceTransferringModal] = useState<boolean>(false);
  const cancel = () => {
    onCloseModal();
    onChangeCustomerToMove(null);
  };
  const update = () => {
    // onCloseModal();

    setIsOpenUpdateDeviceTransferringModal(true);
  };

  const formatControllerText = (count: number) => {
    if (count === 1) {
      return `${count} ${t('controller_device_transferring_count')}`;
    } else {
      return `${count} ${t('controllers_device_transferring_text')}`;
    }
  };

  useEffect(() => {
    const tableDataArray: TableDataWithId[] = controllers.map((controller) => ({
      id: controller.deviceId,
      objectName: controller.objectName,
      customerId: controller.customerId,
      customerName: controller.customerName,
    }));
    setFilteredData(tableDataArray.filter((obj) => selectedControllers.includes(obj.id)));
    setSelectedControllersID(selectedControllers);
  }, [selectedControllers]);
  const rowSelectionModelChange = useCallback((rows) => {
    setSelectedControllersID(rows);
  }, []);

  const closeUpdateDeviceTransferringModal = () => {
    onChangeCustomerToMove(null);
    setIsOpenUpdateDeviceTransferringModal(false);
    onCloseModal();
    //setIsOpenUpdateDeviceTransferringModal(false);
  };
  const backToPreviousModal = () => {
    setIsOpenUpdateDeviceTransferringModal(false);
  };
  return (
    <>
      <Modal
        PaperProps={{ sx: { maxWidth: '562px', borderRadius: '12px' } }}
        open={openDeviceTransferring}
        disableEscapeKeyDown
      >
        <Box
          sx={{
            p: '41px 36px 36px 36px',
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Noto Sans Hebrew',
              fontSize: '24px',
              fontWeight: 600,
              lineHeight: '29px',
              color: 'primary.dark',
            }}
          >
            {t('move_device_between_customers_device_transferring_title')}
          </Typography>
          {/* <SelectCustomerFilter
          title={`${t('select_customer_for_move_to_label')}`}
          value={selectedCustomerToMove }
          onChange={onChangeCustomerToMove}
          items={customerItems.filter((customer) => customer.value !== customerFilter)}
          hideAllButton
          withSearch
          setDefaultValue={'87'}
          isCustomFilter
        /> */}
          <CustomerSelect
            title={`${t('select_customer_for_move_to_label')}`}
            value={selectedCustomerToMove}
            onChange={onChangeCustomerToMove}
            hideAllButton
            showHomeButton
            showOnlyResetButton
            withSearch
            defaultValue={'87'}
            sx={{ width: '260px' }}
          />
          <Typography
            sx={{
              mt: '17px',
              fontFamily: 'Noto Sans Hebrew',
              fontSize: '24px',
              fontWeight: 600,
              lineHeight: '29px',
              color: 'primary.dark',
            }}
          >
            {t('list_of_controllers_to_move_device_transferring_title')}
          </Typography>

          <Box>
            <BaseTable
              tableTitles={TABLE_TITLES}
              tableItems={filteredData}
              loadedItems={true}
              idField={'id'}
              noItemsText={t('no_controllers_device_transferring_grid_table')}
              headerItemsPrefix={'_controllers_device_transferring_grid_table'}
              onRowSelectionModelChange={rowSelectionModelChange}
              selectedIDs={selectedControllersID}
              height={'300px'}
              checkboxSelection
            />
          </Box>
          <Row position="relative" justifyContent="space-between" alignItems="center" mt="34px">
            <Row sx={{ flexDirection: isRTL ? 'row-reverse' : 'row' }}>
              <Typography
                sx={{
                  fontFamily: 'Noto Sans Hebrew',
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '19px',
                  color: 'primary.dark',
                }}
              >
                {t(`total_device_transferring_total`)}
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Noto Sans Hebrew',
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '19px',
                  color: 'primary.dark',
                }}
              >
                {formatControllerText(selectedControllersID.length)}
              </Typography>
            </Row>
            <Row justifyContent="center">
              <Row width={300} gap="10px">
                <BaseBtn onClick={cancel} btnColor="info.main" fullWidth>
                  {t('cancel_device_transferring_button')}
                </BaseBtn>
                <BaseBtn
                  type="submit"
                  disabled={!selectedCustomerToMove}
                  onClick={update}
                  btnColor="primary.dark"
                  fullWidth
                >
                  {t('update_device_transferring_button')}
                </BaseBtn>
              </Row>
            </Row>
          </Row>
        </Box>
      </Modal>
      <UpdateDeviceTransferring
        onCloseModal={closeUpdateDeviceTransferringModal}
        backToPreviousModal={backToPreviousModal}
        openUpdateDeviceTransferring={isOpenUpdateDeviceTransferringModal}
        selectedControllers={selectedControllersID}
        selectedCustomerToMove={selectedCustomerToMove}
      />
    </>
  );
};

import ReactDOM from 'react-dom/client';
import App from './app';
import './shared/config/i18n/i18n';
import { createBrowserHistory } from 'history';
import { LicenseInfo } from '@mui/x-license-pro';
import { QueryClient, QueryClientProvider } from 'react-query';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  console.info('development mode...');
  //template key. reason key expired for development
  LicenseInfo.setLicenseKey(
    'e0d9bb8070ce0054c9d9ecb6e82cb58fTz0wLEU9MzI0NzIxNDQwMDAwMDAsUz1wcmVtaXVtLExNPXBlcnBldHVhbCxLVj0y'
  );
} else {
  console.info('production mode');
  LicenseInfo.setLicenseKey(
    //siema oficial license

    'f013965b4b1cd3c20fe22642e924e474Tz02NjQ3MCxFPTE3MTU3NjEzODgzNjMsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
  );
}

const queryClient = new QueryClient();

const history = createBrowserHistory();

const app = document.getElementById('root');

if (app) {
  const path = (/#!(\/.*)$/.exec(location.hash) || [])[1];
  if (path) {
    history.replace(path);
  }

  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

  root.render(
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  );

  serviceWorkerRegistration.unregister();
}

import { Layout } from '../../shared/ui';
import { Header, ReplacedEquipmentReportManager } from '../../widgets';
export const ReplacedEquipmentReport = () => {
  return (
    <Layout>
      <Header />
      <Layout.Body>
        <ReplacedEquipmentReportManager />
        {/* <BatteryStatusReportManager />
        <BatteryStatusReportGraph /> */}
      </Layout.Body>
    </Layout>
  );
};

import { useAppDispatch } from 'shared/hooks';
import { setCustomerFilter } from 'shared/slices';
import { useAppSelector, useExtendedTranslation } from 'shared/hooks';
import { useMemo, useState } from 'react';
import { forEachChild } from 'typescript';
interface SimProviderItem {
  value: string;
  label: string;
}
export const useFilterSims = () => {
  const { t } = useExtendedTranslation();
  const { simStatuses, simProviders } = useAppSelector((st) => st.simManagement);
  // const { customerIDForFilter } = useAppSelector((st) => st.controllers);
  //const dispatch = useAppDispatch();
  const simStatusFilterItems = useMemo(() => {
    return simStatuses.map((s: { name: string }) => ({
      value: s.name.toString(),
      label: t(`SIM_STATUS_${s.name.toString().substring(s.name.lastIndexOf('_') + 1)}`),
    }));
  }, [simStatuses]);

  const simProviderItems = useMemo(() => {
    return simProviders.map((s: { name: string }) => {
      if (s && s.name) {
        return {
          value: s.name.toString(),
          label: s.name.toString(),
        };
      }
      return null;
    }).filter(item => item !== null) as SimProviderItem[];
  }, [simProviders]);

  const [simStatusFilter, setSimStatusFilter] = useState<string>('SIM_STATUS_1');
  const [simProviderFilter, setSimProviderFilter] = useState<string>('');
  const onChangeSimStatusFilter = (values: string) => setSimStatusFilter(values);
  const onChangeSimProviderFilter = (values: string) => setSimProviderFilter(values);
  return {
    simStatusFilter,
    onChangeSimStatusFilter,
    simStatusFilterItems,
    simProviderItems,
    onChangeSimProviderFilter,
    simProviderFilter,
  };
};

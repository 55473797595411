import { Stack, IconButton, MenuItem, SelectChangeEvent } from '@mui/material';
import { Box } from '@mui/material';
import { BaseDataPicker } from 'features';
import { t } from 'i18next';
import { close_popup, close_blue } from 'shared/assets';
import { useAppDispatch, useAppSelector, useControllerDateErrors, useAnchorEl } from 'shared/hooks';
import { toggleEditServiceCall } from 'shared/slices';
import { BigTitle, Modal, BaseInput, Row, BaseBtn, Confirm, Select, Icon } from 'shared/ui';
import { ServiceCall } from 'shared/models';
import { FC, useState, useEffect, useRef } from 'react';
import { replaceSpacesWithUnderscores } from 'shared/lib/replaceSpacesWithUnderscores';
import { useEditServiceCallsChanges } from './hooks';
import { useToast } from 'shared/hooks';
import { useBatteryStatusReport, useServiceCallsManagement } from 'shared/api';
import { getFormattedDate } from 'shared/lib';
const initialValues = {
  serviceCallID: 0,
  createDate: '',
  currentStatusCode: 0,
  currentStatus: '',
  customerID: 0,
  customerName: '',
  closeDate: '',
  controllerID: 0,
  controllerName: '',
  openByUserID: 0,
  openByUserName: '',
  faultEquipmentCode: 0,
  faultEquipment: '',
  faultDescription: '',
  notesForTechnician: '',
  assignedTechnicianID: null,
  assignedTechnicianName: '',
  actionDescription: '',
  takenActionCode: 0,
  takenAction: '',
  replacedEquipment1Code: 0,
  replacedEquipment1: '',
  replacedEquipment2Code: 0,
  replacedEquipment2: '',
};

const initialErrors = {
  customerName: '',
  objectName: '',
  installAddress: '',
  objectNotes: '',
  latitudeDec: '',
  longitudeDec: '',
};

interface Props {
  changedServiceCalls: () => void;
}

export const EditServiceCalls: FC<Props> = ({ changedServiceCalls }) => {
  const dispatch = useAppDispatch();

  const { openEditServiceCall } = useAppSelector((st) => st.modals);
  const [values, setValues] = useState<ServiceCall>(initialValues);
  const closeModal = () => dispatch(toggleEditServiceCall());
  const [errors, setErrors] = useState(initialErrors);
  const [isChanged, setIsChanged] = useState(false);
  const { currentServiceCall, serviceCallsFaultEquipmentCodes, serviceCallsTechnicianCodes, serviceCallsStatusCodes } =
    useAppSelector((st) => st.serviceCalls);
  const { isOpen, anchorEl, onOpenMenu, onCloseMenu } = useAnchorEl();
  const { boxRef } = useEditServiceCallsChanges();
  const { showError, showSuccess } = useToast();
  const {
    getServiceCallsFaultEquipmentCodes,
    getServiceCallsTechnicianCodes,
    getServiceCallsStatusCodes,
    editServiceCall,
  } = useServiceCallsManagement();
  useEffect(() => {
    if (currentServiceCall) {
      setValues(currentServiceCall);
    }
  }, [currentServiceCall, openEditServiceCall]);
  const onChangeValues = (event: any, propertyName: string) => {
    const newValue = event.target.value;
    setValues((prevValues) => ({
      ...prevValues,
      [propertyName]: newValue,
    }));

    setIsChanged(true);
  };
  const onChangeSelectValues = (e: SelectChangeEvent<unknown>) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    setIsChanged(true);
  };
  const resetErrors = () => {
    setErrors(initialErrors);
  };
  const resetValues = () => {
    setValues(initialValues);
  };
  const fetchData = () => {
    getServiceCallsFaultEquipmentCodes();
    getServiceCallsTechnicianCodes();
    getServiceCallsStatusCodes();
  };
  useEffect(() => {
    if (!openEditServiceCall) {
      resetValues();
      resetErrors();
      setIsChanged(false);
    } else {
      fetchData();
    }
  }, [openEditServiceCall]);
  const [hasErrors, setHasErrors] = useState(false);
  const handleSave = async () => {
    await editServiceCall(values);
    changedServiceCalls();
  };
  const { installDateError, setInstallDateError } = useControllerDateErrors();
  const onSubmit = () => {
    if (!hasErrors) {
      handleSave();
    }
    console.log('err');
    //showError(t('this service is temporarily unavailable'));
  };
  const handleCloseEditModal = (e: React.MouseEvent<HTMLElement>) => {
    isChanged ? onOpenMenu(e) : closeModal();
  };
  const handleResetSelect = (value: string) => {
    setValues((prev) => ({ ...prev, [value]: null }));
  };
  useEffect(() => {
    if (isChanged) {
      validateForm();
    }
  }, [values]);
  // const checkObjectName = () => {
  //   const { objectName } = values;
  //   if (!objectName) {
  //     setErrors((prevValues) => ({
  //       ...prevValues,
  //       ['objectName']: 'Please enter object name ',
  //     }));
  //     return false;
  //   }
  //   if (objectName.length < 3) {
  //     setErrors((prevValues) => ({
  //       ...prevValues,
  //       ['objectName']: 'Name must contain at least 3 characters',
  //     }));
  //     return false;
  //   }
  //   if (objectName.length > 50) {
  //     setErrors((prevValues) => ({
  //       ...prevValues,
  //       ['objectName']: 'Max length 50 characters',
  //     }));
  //     return false;
  //   }

  //   setErrors((prevValues) => ({
  //     ...prevValues,
  //     ['objectName']: '',
  //   }));
  //   return true;
  // };
  // const checkInstallAdress = () => {
  //   const { installAddress } = values;
  //   if (installAddress && installAddress.length > 250) {
  //     setErrors((prevValues) => ({
  //       ...prevValues,
  //       ['installAddress']: 'Max length 250 characters',
  //     }));
  //     return false;
  //   }
  //   setErrors((prevValues) => ({
  //     ...prevValues,
  //     ['installAddress']: '',
  //   }));
  //   return true;
  // };
  // const checkObjectNotes = () => {
  //   const { objectNotes } = values;
  //   if (objectNotes && objectNotes.length > 250) {
  //     setErrors((prevValues) => ({
  //       ...prevValues,
  //       ['objectNotes']: 'Max length 250 characters',
  //     }));
  //     return false;
  //   }
  //   setErrors((prevValues) => ({
  //     ...prevValues,
  //     ['objectNotes']: '',
  //   }));
  //   return true;
  // };
  const checkLatitudeDec = () => { };
  const checkLongitudeDec = () => { };
  const validateForm = () => {
    // checkObjectName();
    // checkInstallAdress();
    // checkObjectNotes();
    // checkLatitudeDec();
    // checkLongitudeDec();
  };

  const closeButtonRef = useRef<HTMLButtonElement | null>(null);
  const handleModalClose = () => {
    if (closeButtonRef.current) {
      closeButtonRef.current.click();
    }
  };
  return (
    <Modal width={'100%'} open={openEditServiceCall} onClose={handleModalClose}>
      <Box sx={{ paddingRight: '10px' }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <BigTitle>{t('service_calls_edit_modal')}</BigTitle>

          <IconButton ref={closeButtonRef} onClick={handleCloseEditModal}>
            <img src={close_popup} />
          </IconButton>
        </Stack>
        {values && (
          <Box component="form" autoComplete="off">
            <Row spacing="20px" alignItems="flex-start" justifyContent="space-between">
              <Box width={'25%'}>
                <BaseInput
                  label={`${t('service_call_ID_edit_modal')}`}
                  name="serviceCallID"
                  disabled
                  helperText=""
                  value={values?.serviceCallID ?? ''}
                  onChange={(event) => onChangeValues(event, 'serviceCallID')}
                  small
                />
                <BaseInput
                  label={`${t('customer_id_edit_modal')} `}
                  name="customerID"
                  disabled
                  helperText=""
                  value={values?.customerID ?? ''}
                  onChange={(event) => onChangeValues(event, 'customerID')}
                  small
                />
              </Box>
              <Box width={'25%'}>
                <Select
                  label={`${t('current_status_code_edit_modal')}`}
                  name="currentStatusCode"
                  value={values?.currentStatusCode}
                  onChange={onChangeSelectValues}
                  displayEmpty
                  sx={{
                    fontFamily: 'Noto Sans Hebrew !important',
                  }}
                  small
                >
                  {serviceCallsStatusCodes.map((a) => (
                    <MenuItem key={a.code} value={a.code}>
                      {t(a.text)}
                    </MenuItem>
                  ))}
                </Select>
                <BaseInput
                  label={`${t('customer_name_edit_modal')} `}
                  name="customerName"
                  disabled
                  helperText=""
                  value={values?.customerName ?? ''}
                  onChange={(event) => onChangeValues(event, 'customerName')}
                  small
                />
              </Box>
              <Box width={'25%'}>
                <BaseInput
                  label={`${t('create_date_edit_modal')} `}
                  name="createDate"
                  disabled
                  helperText=""
                  value={values?.createDate ? getFormattedDate(values.createDate) : ''}
                  onChange={(event) => onChangeValues(event, 'createDate')}
                  small
                />
                <BaseInput
                  label={`${t('controller_ID_edit_modal')} `}
                  name="controllerID"
                  disabled
                  helperText=""
                  value={values?.controllerID ?? ''}
                  onChange={(event) => onChangeValues(event, 'controllerID')}
                  small
                />
              </Box>
              <Box width={'25%'}>
                <BaseInput
                  label={`${t('open_by_user_name_edit_modal')} `}
                  name="openByUserName"
                  disabled
                  helperText=""
                  value={values?.openByUserName ?? ''}
                  onChange={(event) => onChangeValues(event, 'openByUserName')}
                  small
                />
                <BaseInput
                  label={`${t('controller_name_edit_modal')} `}
                  name="controllerName"
                  disabled
                  helperText=""
                  value={values?.controllerName ?? ''}
                  onChange={(event) => onChangeValues(event, 'controllerName')}
                  small
                />
              </Box>
            </Row>

            <Row spacing="20px" alignItems="flex-start" justifyContent="space-between">
              <Box width={'25%'} sx={{ paddingRight: '12px' }}>
                <Select
                  label={`${t('fault_Equipment_code_edit_modal')}`}
                  name="faultEquipmentCode"
                  value={values?.faultEquipmentCode}
                  onChange={onChangeSelectValues}
                  displayEmpty
                  sx={{
                    fontFamily: 'Noto Sans Hebrew !important',
                  }}
                  small
                >
                  {serviceCallsFaultEquipmentCodes.map((a) => (
                    <MenuItem key={'fault_Equipment' + a.code} value={a.code}>
                      {t(a.text)}
                    </MenuItem>
                  ))}
                </Select>
                <Row width={'100%'}>
                  <Box width={'100%'}>
                    <Select
                      label={`${t('assigned_technician_ID_edit_modal')}`}
                      name="assignedTechnicianID"
                      value={values.assignedTechnicianID}
                      onChange={onChangeSelectValues}
                      displayEmpty
                      sx={{
                        fontFamily: 'Noto Sans Hebrew !important',
                      }}
                      small
                    >
                      {serviceCallsTechnicianCodes.map((a) => (
                        <MenuItem key={a.code} value={a.code}>
                          {a.text}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                  {values?.assignedTechnicianID ? (
                    <Box
                      width={36}
                      sx={{
                        paddingTop: '44px',
                        paddingLeft: '7px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Icon
                        sx={{
                          width: '26px',
                          cursor: 'pointer',
                          ml: '0px',
                          mr: '0px',
                        }}
                        src={close_blue}
                        onClick={() => handleResetSelect('assignedTechnicianID')}
                      />
                    </Box>
                  ) : (
                    <></>
                  )}
                </Row>
              </Box>
              <Box width={'75%'}>
                <Row spacing="20px" alignItems="flex-end" justifyContent="space-between">
                  <BaseInput
                    label={`${t('fault_description_edit_modal')}`}
                    name="faultDescription"
                    value={values?.faultDescription ?? ''}
                    onChange={(event) => onChangeValues(event, 'faultDescription')}
                    helperText=""
                    medium
                    fullWidth
                  />
                </Row>
                <Row spacing="20px" alignItems="flex-end" justifyContent="space-between">
                  <BaseInput
                    label={`${t('notes_for_technician_edit_modal')}`}
                    name="notesForTechnician"
                    value={values?.notesForTechnician ?? ''}
                    onChange={(event) => onChangeValues(event, 'notesForTechnician')}
                    helperText=""
                    medium
                    fullWidth
                  />
                </Row>
              </Box>
            </Row>

            <Row justifyContent="flex-end" mt={'35px'}>
              <Row width={300}>
                <BaseBtn onClick={handleCloseEditModal} btnColor="info.main" fullWidth>
                  {t('cancel_edit_modal')}
                </BaseBtn>
                <Box ref={boxRef} onClick={handleCloseEditModal} sx={{ height: '40px', width: '25px' }} />
                <BaseBtn
                  onClick={onSubmit}
                  disabled={!isChanged || hasErrors}
                  loading={false}
                  btnColor="primary.dark"
                  fullWidth
                >
                  {t('save_edit_modal')}
                </BaseBtn>
              </Row>
            </Row>

            {values?.takenAction && (
              <Row spacing="20px" alignItems="flex-start" justifyContent="space-between">
                <Box width={'25%'} sx={{ paddingRight: '12px' }}>
                  <BaseInput
                    label={`${t('taken_action_edit_modal')}`}
                    name="takenAction"
                    disabled
                    value={values?.takenAction ? t(values?.takenAction) : ''}
                    onChange={(event) => onChangeValues(event, 'takenAction')}
                    helperText=""
                    small
                  />
                </Box>
                <Box width={'25%'} sx={{ paddingRight: '12px' }}>
                  <BaseInput
                    label={`${t('close_date_edit_modal')}`}
                    name="closeDate"
                    disabled
                    value={values?.closeDate ? getFormattedDate(values?.closeDate as string) : ''}
                    onChange={(event) => onChangeValues(event, 'closeDate')}
                    helperText=""
                    small
                  />
                </Box>
                <Box width={'25%'} sx={{ paddingRight: '12px' }}>
                  <BaseInput
                    label={`${t('replaced_equipment_1_edit_modal')}`}
                    name="replacedEquipment1"
                    disabled
                    value={values?.replacedEquipment1 ?? ''}
                    onChange={(event) => onChangeValues(event, 'replacedEquipment1')}
                    helperText=""
                    small
                  />
                </Box>
                <Box width={'25%'} sx={{ paddingRight: '12px' }}>
                  <BaseInput
                    label={`${t('replaced_equipment_2_edit_modal')}`}
                    name="replacedEquipment2"
                    disabled
                    value={values?.replacedEquipment2 ?? ''}
                    onChange={(event) => onChangeValues(event, 'replacedEquipment2')}
                    helperText=""
                    small
                  />
                </Box>
              </Row>
            )}
            {values?.takenAction && (
              <Row spacing="20px" alignItems="flex-start" justifyContent="space-between">
                <Box width={'100%'} sx={{ paddingRight: '12px' }}>
                  <BaseInput
                    label={`${t('action_description_edit_modal')}`}
                    name="actionDescription"
                    disabled
                    value={values?.actionDescription ?? ''}
                    onChange={(event) => onChangeValues(event, 'actionDescription')}
                    helperText=""
                    medium
                    fullWidth
                  />
                </Box>
              </Row>
            )}
          </Box>
        )}
        <Confirm
          open={isOpen}
          anchorEl={anchorEl}
          onClose={onCloseMenu}
          confirm={closeModal}
          buttonTexts={[t('cancel_edit_edit_modal'), t('back_to_edit_edit_modal')]}
        />
      </Box>
    </Modal>
  );
};

import { TableTitle, TranslateColumn } from 'components/BaseTable';
import { DashboardDetails } from 'shared/models';


//18px - 1 letter
export const TABLE_TITLES: TableTitle<DashboardDetails>[] = [
  { title: 'objectID', key: 'objectID', width: 108 },
  { title: 'hWID', key: 'hwid', width: 108 },
  { title: 'object_name', key: 'objectName', width: 432 },
  { title: 'customer_id', key: 'customerID', width: 72 },
  { title: 'customer_name', key: 'customerName', width: 216 },
  { title: 'model_id', key: 'modelID', width: 72 },
  { title: 'device_type', key: 'deviceType', width: 216 },
  { title: 'controller_version', key: 'controllerVersion', width: 72},
  { title: 'install_date', key: 'installDate', width: 216, type:'date' },
  { title: 'last_comm_dt', key: 'lastCommDT', width: 216, type:'date'},
  { title: 'cell_provider', key: 'cellProvider', width:  216},
  { title: 'modem_type', key: 'modemType', width: 216 },
  { title: 'modem_last_3g_4g', key: 'modemLast3G4G', width: 72 },
  { title: 'sim_number', key: 'simNumber', width: 216 },
  { title: 'disabled_flag', key: 'disabledFlag', width: 108},
  { title: 'comm_adapter_type', key: 'commAdapterType', width: 216 },
  { title: 'comm_adapter_ver', key: 'commAdapterVer', width: 108 },
  { title: 'controller_type', key: 'controllerType', width: 216 },
  { title: 'modem_board_type', key: 'modemBoardType', width: 216 },
  { title: 'customer_type', key: 'customerType', width: 72 },
  { title: 'notes', key: 'notes', width: 216},
  { title: 'longitude_dec', key: 'longitude_Dec', width:216  },
  { title: 'latitude_dec', key: 'latitude_Dec', width:216  },
  { title: 'device_type2', key: 'deviceType2', width: 108  },
  { title: 'last_change_dt', key: 'lastChangeDT', width: 216 },
  { title: 'device_info_auto_note', key:'deviceInfoAutoNote', width: 216 },
  { title: 'device_info_note', key: 'deviceInfoNote', width: 216 },
];
export const TRANSLATE_COLUMNS: TranslateColumn<DashboardDetails>[]=[
{ key: 'deviceType', additionalKey:'_chart_item' },
{ key: 'cellProvider', additionalKey:'_chart_item_cell_provider' },
{key: 'disabledFlag', additionalKey:''  }

]

import axios from 'axios';
import localStorageService from 'shared/localStorage';
const source = axios.CancelToken.source();
const deleteToken = () => {
  localStorageService.userToken = undefined;
  localStorageService.invalidToken = true;
  // sessionStorage.setItem('invalidToken', 'true');
  // window.location.href = '/login';
};

const axiosBase = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

axiosBase.interceptors.request.use((config) => {
  config.headers.Authorization = localStorageService.userToken;
  return config;
});

axiosBase.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error?.response?.status === 401) {
      deleteToken();
    }
    return Promise.reject(error);
  }
);

export default axiosBase;

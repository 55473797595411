import { useTranslation } from 'react-i18next';
import { Controller } from '../controllerModels';
import { useMemo } from 'react';

export const useFormatXlsxData = () => {
  const { t } = useTranslation();

  const formatTable = useMemo(() => (arr: Controller[]) => {
    const exportArr = arr.map((item) => {
      return {
        [t('ID_controller_grid_table')]: item.deviceId,
        [t('Controller_Name_controller_grid_table')]: item.objectName,
        [t('Customer_ID_controller_grid_table')]: item.customerId,
        [t('Customer_Name_controller_grid_table')]: item.customerName,
        [t('Hardware_ID_controller_grid_table')]: item.hwId,
        [t('Model_controller_grid_table')]: item.modelId,
        [t('Station_controller_grid_table')]: item.stationId,
        [t('Gateway_controller_grid_table')]: item.gatewayId,
        [t('Install_address_controller_grid_table')]: item.installAddress,
        [t('Latitude_Dec_controller_grid_table')]: item.latitudeDec,
        [t('Longitude_Dec_controller_grid_table')]: item.longitudeDec,
        [t('Install_Date_controller_grid_table')]: item.installDate,
        [t('Notes_controller_grid_table')]: item.objectNotes,
      };
    });

    return exportArr;
  }, [t]);

  return { formatTable };
};

import { Controller } from 'shared/models';
import { makeDeepTrim } from './makeDeepTrim';

export const checkControllerChanges = (initial: Controller, checking: Controller) => {
  const keys = Object.keys(initial);

  const changes = keys.map(
    (key) => (checking?.[key as keyof Controller] ?? '') !== (initial?.[key as keyof Controller] ?? '')
  );

  const isChanged = changes.includes(true);

  return isChanged;
};

export const getSearchedControllers = (Controllers: Controller[], searchValue: string) => {
  const searched = Controllers.filter((c) => {
    const values = Object.values(c);

    return values.some((v) => v?.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase().trim()));
  });

  return searched;
};

export const getSortedControllersByKey = (controllers: Controller[], sortKey: keyof Controller) => {
  const sornFunc = (c1: Controller, c2: Controller) => {
    const v1 = c1?.[sortKey] ?? '';
    const v2 = c2?.[sortKey] ?? '';

    if (v1 > v2) return 1;
    if (v1 === v2) return 0;
    if (v1 < v2) return -1;

    return 0;
  };

  const copy = controllers.map((c) => c);
  const sorted = copy.sort(sornFunc);

  return sorted;
};

export const getPrintControllerColumns = (controllers: Controller[]) => {
  const items = controllers.map((c) => c);

  const PAGE_COLS = 4;
  const COL_SIZE = 75;
  const colAmount = Math.ceil(items.length / COL_SIZE);

  const pages = [];

  for (let i = 0; i < colAmount; i++) {
    if (!items.length) break;

    const pageTables = [];

    for (let i = 0; i < PAGE_COLS; i++) {
      if (!items.length) break;

      const col = items.splice(0, COL_SIZE);
      pageTables.push(col);
    }

    pages.push(pageTables);
  }

  return pages;
};

export const getDeepTrimControllerValues = (controller: Controller) => {
  const entries = Object.entries(controller).map((item) => {
    const [key, value] = item;
    const isStr = typeof value === 'string';

    if (!isStr) return item;

    const newValue = makeDeepTrim(value);
    return [key, newValue];
  });

  const trimController = Object.fromEntries(entries);
  return trimController;
};

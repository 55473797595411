
import { red, blue, deepPurple,orange, green, grey, yellow} from '@mui/material/colors';
export const colors = 
  { 
	'0': grey[900] ,
	'1': green[500] ,
	'2': blue[500],
	'3': orange[500],
	'4': red[500],
	'5': yellow[500],
	'6': yellow[500],
	'7': deepPurple[500]
 }
;

import { useContext } from 'react';
import { AuthContext } from 'shared/contexts';

export const useAuth = () => {
  const { handleSaveToken, logout } = useContext(AuthContext);

  return {
    handleSaveToken,
    logout,
  };
};

import { Box } from '@mui/material';
import { BaseExportToExcel, BaseTable, PagePreloader } from 'components';
import { BaseSelect, Filter } from 'features';
import { useEffect, useMemo, useState } from 'react';
import { useNetworkProtectionManagement } from 'shared/api';
import { useAppDispatch, useAppSelector, useExtendedTranslation } from 'shared/hooks';
import { getSearchedCItems } from 'shared/lib';
import { setCurrentNPController, setCurrentNPControllerId, toggleNetworkProtectionCreateModal, toggleNetworkProtectionEditModal } from 'shared/slices';
import { BaseBtn, BigTitle, Row, SearchInput, Text, TotalRows } from 'shared/ui';
import { TABLE_TITLES, TRANSLATE_COLUMNS } from './constants';
import { useFilterNetworkProtection, useFormatXlsxData, useSearchNetworkProtection } from './hooks';
import { ICItem } from './networkProtectionModels';
export const NetworkProtectionManager = () => {
	const { formatTable } = useFormatXlsxData();
	const { t } = useExtendedTranslation();
	const { searchValue, changeSearch } = useSearchNetworkProtection();
	const { ICProjectFilter,
		onChangeICProjectFilter,
		ICProjectFilterItems,
		statusFilter,
		onChangeStatusFilter,
		statusFilterItems
	} = useFilterNetworkProtection()
	const [loaded, setLoaded] = useState(false);
	const { getICRulesFull, getICProjects } = useNetworkProtectionManagement();
	const [isStartedLoadingTable, setIsStartedLoadingTable] = useState(false);
	const [currentICItemId, setCurrentICItemId] = useState('');
	const { iCItems } = useAppSelector((st) => st.networkProtection);
	const dispatch = useAppDispatch();
	const tableICItems = useMemo(() => {
		const filteredCItems = iCItems
			.filter(c => (!statusFilter || c.comStatus?.toString() === statusFilter) &&
				(!ICProjectFilter || c.icProjectID?.toString() === ICProjectFilter));

		const searchedCItems = getSearchedCItems(filteredCItems, searchValue);


		return searchedCItems;
	}, [searchValue, iCItems, statusFilter, ICProjectFilter]);



	const onClickAddNetworkProtectionController = () => {
		dispatch(toggleNetworkProtectionCreateModal());
	}
	useEffect(() => {
		const fetchData = async () => {
			getICProjects();
			await getICRulesFull();
			setLoaded(true)
		};
		fetchData();
		setIsStartedLoadingTable(true);
	}, []);

	const handleDstObjectName = (row: ICItem) => {
		dispatch(setCurrentNPController(row));
		dispatch(setCurrentNPControllerId(row.dstObjectID.toString()));
		openEditModal()
	}


	const handleSrcObjectID = (row: ICItem) => {
		const currentNPController = {
			...row,
			dstObjectName: row.srcObjectName,
			dstModelID: row.srcModelID,
			// dstVersion: row.srcVersion.toString(),
			dstObjectID: row.srcObjectID,
			dstHWID: row.srcHWID,
		}
		dispatch(setCurrentNPController(currentNPController));
		dispatch(setCurrentNPControllerId(row.srcObjectID.toString()));
		openEditModal()
	}
	const handleRowClick = (row: ICItem, index: number) => {
		setCurrentICItemId(`${row.icProjectID}-${row.dstObjectID}-${row.srcObjectID}-${row.dstHWID}-${row.ruleID}-_id}`)
		if (index === 3) {
			handleDstObjectName(row);
		}
		if (index === 5) {
			handleSrcObjectID(row);
		}
	}

	const openEditModal = () => {
		dispatch(toggleNetworkProtectionEditModal());

	};
	const BaseExportToExcelComponent = useMemo(
		() => (<BaseExportToExcel
			tableData={tableICItems}
			sheetName="Network Protection"
			fileName="networkProtection.xlsx"
			buttonText={t('network_protection_export_btn')}
			dateColumnIndexes={[11]}
			formattedTable={formatTable(tableICItems)}
			tableTitles={TABLE_TITLES}
			headerItemsPrefix={'_network_protection_grid_table'}
		/>)
		,
		[tableICItems]
	);
	return (
		<>
			<PagePreloader loading={!loaded} />
			<>
				<Row sx={{ mb: '12px' }}>
					<BigTitle sx={{ mr: '25px' }}>{t('network_protection_manager')}</BigTitle>
					<BaseBtn onClick={onClickAddNetworkProtectionController}>
						{t('new_network_protection_controller_button')}
						<Text sx={{ fontSize: '25px', mb: '4px', ml: '4px' }}>+</Text>
					</BaseBtn>
				</Row>
				<SearchInput value={searchValue} onChange={changeSearch} placeholder={`${t('search_network_protection_management_page')}`} />
				<Row sx={{ mt: '6px' }} flexWrap="wrap" rowGap="5px" alignItems="flex-end">
					<BigTitle sx={{ fontSize: '24px', mr: '20px' }}>{t('filter_network_protection')}</BigTitle>
					{ICProjectFilterItems?.length > 0 && <BaseSelect
						title={`${t('iCProject_label')}`}
						value={ICProjectFilter}
						onChange={onChangeICProjectFilter}
						items={ICProjectFilterItems}
						hideAllButton
						showHomeButton
						showOnlyResetButton
						withSearch
						sx={{ width: '260px' }}
					/>}

					<BigTitle sx={{ fontSize: '24px', mr: '20px' }}>{t('filter_status_network_protection')}</BigTitle>
					<Filter
						title={`${t('ic_project_status_status_label')}`}
						value={statusFilter}
						onChange={onChangeStatusFilter}
						items={statusFilterItems}
						sx={{ width: '220px' }}
						messageEmptyFilter="ic_project_status_status_any"
						placeholder={`${t('ic_project_status_placeholder')}`}
					/>

					<Box sx={{ display: 'flex', marginLeft: 'auto', alignItems: 'flex-end' }}>
						<TotalRows count={tableICItems.length} />


						{BaseExportToExcelComponent}
					</Box>
				</Row>
				<BaseTable
					tableTitles={TABLE_TITLES}
					tableItems={tableICItems}
					loadedItems={true}
					translateColumns={TRANSLATE_COLUMNS}
					selectedRowID={currentICItemId}
					idFields={['icProjectID', 'dstObjectID', 'srcObjectID', 'dstHWID', 'ruleID']}
					noItemsText={t('no_network_protection_controllers_grid_table')}
					headerItemsPrefix={'_network_protection_grid_table'}
					maxHeight="calc(100vh - 275px)"
					height="calc(100vh - 275px)"
					linkIndex={[3]}
					additionalIndex={5}
					conditionForAddIndex={'srcDestCount'}
					cellClick={handleRowClick}
					loading={!loaded}
				/>
				{/* <NetworkProtectionTable
				tableItems={tableICItems}
				isStartedLoadingTable={isStartedLoadingTable}
			/> */}
			</></>
	);
};

import { Box, Tooltip, Typography, Zoom } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { clearSelectedController, setCustomerToMove } from 'shared/slices';
import { BaseBtn, Modal, Row, TransferringConfirmInfo } from 'shared/ui';
import { ProgressBar } from 'shared/ui/ProgressBar';
import { arrow, successIcon, errorIcon } from 'shared/assets';
import { useCustomerToMove } from 'features/ControllerManager/components/DeviceTransferring/hooks/useFilterCustomerToMove';
import useMoveControllers from './hooks/useMoveControllers';
import { useFilterControllers } from 'features/ControllerManager/hooks';
import { useControllers } from 'shared/api';
import localStorageService from 'shared/localStorage';

interface Props {
  openUpdateDeviceTransferring: boolean;
  onCloseModal: () => void;
  backToPreviousModal: () => void;
  selectedControllers: number[];
  selectedCustomerToMove: string;
}

export const UpdateDeviceTransferring: FC<Props> = ({
  openUpdateDeviceTransferring,
  onCloseModal,
  selectedControllers,
  selectedCustomerToMove,
  backToPreviousModal,
}) => {
  const currentLang = localStorageService.currentLang;
  const { getControllers } = useControllers();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { customerItems } = useCustomerToMove();
  const { customerFilter, customerFilterItems } = useFilterControllers();
  const { successUpload, errorUpload, progress } = useAppSelector((st) => st.deviceTransferring);
  const { handleRequest, isUpdated, isLoading, setIsUpdated } = useMoveControllers();

  const valueItems = [...customerItems];
  const currentCustomerName = useMemo(
    () => valueItems.find((item) => item.value === selectedCustomerToMove)?.label,
    [selectedCustomerToMove]
  );

  const valueFilterItems = [...customerFilterItems];
  const currentFilterName = useMemo(
    () => valueFilterItems.find((item) => item.value === customerFilter)?.label,
    [selectedCustomerToMove]
  );

  const cancel = () => {
    dispatch(setCustomerToMove(0));
    backToPreviousModal();
    // closeModal();
  };
  const closeModal = () => {
    onCloseModal();
    getControllers(parseInt(customerFilter));
  };
  const clearData = () => {
    dispatch(clearSelectedController());
    setIsUpdated(false);
    closeModal();
  };

  const handleUpdate = () => {
    handleRequest(selectedControllers, selectedCustomerToMove);
  };
  return (
    <Modal
      PaperProps={{ sx: { maxWidth: '562px', borderRadius: '12px' } }}
      open={openUpdateDeviceTransferring}
      disableEscapeKeyDown

    >
      <Box
        sx={{
          p: '41px 36px 78px 36px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          gap: '55px',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Noto Sans Hebrew',
            fontSize: '24px',
            fontWeight: 600,
            lineHeight: '29px',
            color: 'primary.dark',
          }}
        >
          {t('move_device_between_customers_device_transferring_title')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '490px',
            height: '440px',
            border: '1px solid #dddddd',
            borderTopLeftRadius: '12px',
            borderTopRightRadius: '12px',
          }}
        >
          <Box
            sx={{
              height: '38px',
              bgcolor: 'primary.dark',
              color: 'white',
              pt: '10px',
              pl: '16px',
              borderTopLeftRadius: '12px',
              borderTopRightRadius: '12px',
              mb: '40px',
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '14px',
                fontFamily: 'Noto Sans Hebrew',
                lineHeight: '17px',
              }}
            >
              {t('transaction_confirmation_device_transferring_update_title')}
            </Typography>
          </Box>
          <Box
            sx={{
              textAlign: 'center',
              mb: '23px',
            }}
          >
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: '16px',
                fontFamily: 'Noto Sans Hebrew',
                lineHeight: '19px',
                color: 'primary.dark',
              }}
            >
              {`${t('you_chose_to_transfer_device_transferring_subtitle')} `}
              <Box component={'span'} sx={{ textDecoration: 'underline' }}>
                {selectedControllers.length}
              </Box>{' '}
              {t('controllers_device_transferring_text')}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: '33px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '193px',
                position: 'relative',
              }}
            >
              <Box sx={{ mb: '8px' }}>
                <TransferringConfirmInfo
                  id={customerFilter}
                  name={`${currentFilterName}`}
                  label={`${t('from_device_transferring_update_label')}`}
                />
              </Box>
              <img
                src={arrow}
                style={{
                  height: '17px',
                  position: 'absolute',
                  top: '60%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              />
              <TransferringConfirmInfo
                id={selectedCustomerToMove}
                name={`${currentCustomerName}`}
                label={`${t('to_device_transferring_update_label')}`}
              />
            </Box>
          </Box>
          {!isUpdated && !isLoading && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '21px',
                  textAlign: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: '16px',
                    fontFamily: 'Noto Sans Hebrew',
                    lineHeight: '19px',
                    color: 'primary.dark',
                  }}
                >
                  {t('are_you_sure_device_transferring_confirm')}
                </Typography>
                <Row justifyContent="center">
                  <Row width={300} gap="10px">
                    <BaseBtn onClick={cancel} btnColor="info.main" fullWidth>
                      {t('cancel_update_device_transferring_button')}
                    </BaseBtn>
                    <BaseBtn type="submit" onClick={handleUpdate} btnColor="primary.dark" fullWidth>
                      {t('update_update_device_transferring_button')}
                    </BaseBtn>
                  </Row>
                </Row>
              </Box>
            </>
          )}
          {isLoading ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                textAlign: 'center',
                rowGap: '21px',
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Noto Sans Hebrew',
                  fontWeight: 600,
                  fontSize: '16px',
                  lineHeight: '19px',
                  color: 'primary.dark',
                }}
              >
                {t('transfer_progress_device_transferring_progress_text')}
              </Typography>
              <ProgressBar progress={progress} />
            </Box>
          ) : (
            isUpdated && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'center',
                  rowGap: '14px',
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Noto Sans Hebrew',
                    fontWeight: 600,
                    fontSize: '16px',
                    lineHeight: '19px',
                    color: errorUpload.length ? '#D74747' : '#166534',
                    mb: '16px',
                  }}
                >
                  {errorUpload.length > 0 ? t('error_device_transferring_error') : t('done_device_transferring_done')}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    columnGap: '8px',
                  }}
                >
                  <img src={successIcon} />
                  <Typography
                    sx={{
                      fontFamily: 'Noto Sans Hebrew',
                      fontWeight: 700,
                      fontSize: '16px',
                      lineHeight: '19px',
                      color: '#166534',
                      textDecoration: 'underline',
                    }}
                  >
                    {successUpload.length}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: 'Noto Sans Hebrew',
                      fontWeight: 700,
                      fontSize: '16px',
                      lineHeight: '19px',
                      color: '#166534',
                    }}
                  >
                    {t('controllers_transferred_successfully_device_transferring_success')}
                  </Typography>
                </Box>
                {errorUpload.length > 0 && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      columnGap: '8px',
                    }}
                  >
                    <img src={errorIcon} />
                    <Typography
                      sx={{
                        fontFamily: 'Noto Sans Hebrew',
                        fontWeight: 700,
                        fontSize: '16px',
                        lineHeight: '19px',
                        color: 'red',
                        textDecoration: 'underline',
                      }}
                    >
                      {errorUpload.length}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: 'Noto Sans Hebrew',
                        fontWeight: 700,
                        fontSize: '16px',
                        lineHeight: '19px',
                        color: 'red',
                      }}
                    >
                      {t('controllers_did_not _transfer_device_transferring_error')}
                    </Typography>
                    <Tooltip
                      title={`Error to transfer: ${errorUpload.length > 1 ? errorUpload.join(', ') : errorUpload}`}
                      placement="right-start"
                      TransitionComponent={Zoom}
                    >
                      <Box
                        component={'span'}
                        sx={{
                          width: '51px',
                          height: '15px',
                          border: '1px solid #D74747',
                          borderRadius: '10px',
                        }}
                      >
                        <Typography
                          sx={{
                            color: '#D74747',
                            fontFamily: 'Noto Sans Hebrew',
                            fontSize: '12px',
                            fontWeight: 500,
                            lineHeight: '13px',
                          }}
                        >
                          {t('view_device_transferring_view_button')}
                        </Typography>
                      </Box>
                    </Tooltip>
                  </Box>
                )}

                <Row justifyContent={'center'} sx={{ mt: errorUpload.length === 0 ? '38px' : '3px', pb: '13px' }}>
                  <BaseBtn
                    sx={{ width: currentLang === 'he' ? '134px' : '83px', height: '34px' }}
                    type="submit"
                    onClick={() => clearData()}
                    btnColor="primary.dark"
                    fullWidth
                  >
                    {t('done_device_transferring_button')}
                  </BaseBtn>
                </Row>
              </Box>
            )
          )}
        </Box>
      </Box>
    </Modal>
  );
};

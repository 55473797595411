import useAxios from 'app/useAxios';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useToast } from 'shared/hooks';
interface messageTemplateParams {
  messageTemplateName: string;
  messageTemplateText: string;
  messageTemplateLanguage: string;
  messageTemplateCategory: string;
}
interface deleteMessageTemplateParams {
  messageTemplateName: string;
  id: string;
}
export const useMessagesTemplates = () => {
  const dispatch = useAppDispatch();
  const { loading, request } = useAxios();
  const { showError } = useToast();
  const { t } = useTranslation();

  return {
    createMessageTemplate: (messageTemplateParams: messageTemplateParams) =>
      request<undefined, { data: any; succeeded: boolean }>({
        method: 'post',
        showSuccessMessageCode: undefined,
        url: `/WhatsApp/messagetemplates`,
        onDataReceiveCompleted: (data) => {
          if (!data.succeeded) {
            showError(t('error_toast_text'));
          }
        },
        payload: messageTemplateParams,
      }),
    deleteMessageTemplate: (deleteMessageTemplateParams: deleteMessageTemplateParams) =>
      request<undefined, { data: any; succeeded: boolean }>({
        method: 'delete',
        showSuccessMessageCode: undefined,
        url: `/WhatsApp/messagetemplates`,
        onDataReceiveCompleted: (data) => {
          if (!data.succeeded) {
            showError(t('error_toast_text'));
          }
        },
        payload: deleteMessageTemplateParams,
      }),

    isLoading: loading,
  };
};

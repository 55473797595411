import { FC, useEffect, useState } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, ResponsiveContainer  } from 'recharts';
import localStorageService from 'shared/localStorage';
import { CustomizedAxisXTick, CustomizedAxisYTick, CustomizedLabel} from './BarGraphsComponents';
import { ChartNames, DashboardData} from 'shared/models';
type Props = {
	data: DashboardData[],
  chartName: ChartNames
  bigLegendText?: boolean
  openDashboardDetails: (chartName: string, itemName: string ) => void;
};

export const BarGraphs: FC<Props>= ({data, chartName, bigLegendText, openDashboardDetails}) => {
const selectItem=(e)=>{
  openDashboardDetails(chartName, e.key)
}
const selectLabel=(name:string)=>{
  openDashboardDetails(chartName, name)
}
const [windowWidth, setWindowWidth] = useState(window.innerWidth);
useEffect(() => {
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };
  window.addEventListener('resize', handleResize);
  return () => {
  window.removeEventListener('resize', handleResize);
  };
}, []);
const isSmallChart = windowWidth <= 1450;
  return (
      <ResponsiveContainer width="100%" height="100%">
         <BarChart width={300} height={40} data={data}  
          margin={{
            top: 15,
            right: 30,
            left: 20,
            bottom: bigLegendText ? 35 : 10,
          }}>
          <XAxis tick={(props)=><CustomizedAxisXTick {...props} bigLegendText={bigLegendText} isSmallChart={ isSmallChart} />} tickSize={2} tickCount={20} dataKey="name" interval={0}  />
          <YAxis tick={(props)=> <CustomizedAxisYTick  {...props} />}  />
            <Bar cursor={'pointer'} onClick={selectItem} dataKey="value" label={(props)=><CustomizedLabel data={data} selectItem={selectLabel} {...props} />} >
            </Bar>
          </BarChart>
      </ResponsiveContainer>
  );
};

import { useAppDispatch, useToast } from 'shared/hooks';
import { Controller, DeviceModelTypes, ControllerRegisters } from 'shared/models';
import { setControllers, setInitHwId } from 'shared/slices';
import useAxios from './useAxios';
import { t } from 'i18next';

export const enum DataSourceTypes {
  Realtime = 0,
  History = 1,
}
interface NewController {
  controllersCount: number;
  modelId: string;
  customerId: number;
  nameTemplate: string;
  notes: string;
}
interface NewControllerCreateResult {
  processResultValue: number;
}
export const useControllers = () => {
  const dispatch = useAppDispatch();
  const { loading, request, error } = useAxios();
  const { showError, showSuccess } = useToast();
  return {
    getControllers: (customerId: number | string) =>
      request<{ customerId: number | string }, { data: Controller[] }>({
        method: 'post',
        url: `/device`,
        showSuccessMessageCode: undefined,
        onDataReceiveCompleted: (data) => {
          dispatch(setControllers(data?.data));
        },
        payload: { customerId: customerId?.toString() },
      }),
    getControllersWithState: (input: {
      customerId: number | string;
      modelId: DeviceModelTypes;
      dataSourceType: DataSourceTypes;
    }) =>
      request<{ customerId: number | string }, { data: Controller[] }>({
        method: 'post',
        url: `/device/state`,
        showSuccessMessageCode: undefined,
        onDataReceiveCompleted: (data) => {
          dispatch(setControllers(data?.data));
        },
        payload: { ...input, customerId: input.customerId?.toString() },
      }),
    getInitHwId: () =>
      request<undefined, { data: any }>({
        method: 'get',
        url: `device/InitHwId`,
        showSuccessMessageCode: undefined,
        onDataReceiveCompleted: (data) => {
          dispatch(setInitHwId(data?.data.deviceInitHwdIdEx));
        },
      }),
    createController: (payload: NewController) =>
      request<{ customerId: number | string }, { data: NewControllerCreateResult }>({
        method: 'post',
        url: `/device/create`,
        showSuccessMessageCode: undefined,
        onDataReceiveCompleted: (data) => {
          if (data?.data.processResultValue > 0) {
            showSuccess(t('controller_successfully_added_toast_text'));
            return;
          }
          switch (data?.data.processResultValue) {
            case -1:
              showError(t('not_implemented_controller_update_error'));
              return false;
            case -2:
              showError(t('invalid_controllers_count_controller_update_error'));
              return false;
            case -3:
              showError(t('invalid_model_id_controller_update_error'));
              return false;
            case -4:
              showError(t('invalid_customer_id_controller_update_error'));
              return false;
            case -5:
              showError(t('hwid_exist_controller_update_error'));
              return false;
            case -6:
              showError(t('invalid_st_gw_controller_update_error'));
              return false;
            default:
              showError(t('update_failed_special_logical_requirements_are_not_meted'));
              return false;
          }
          //dispatch(setControllers(data?.data));
        },
        payload,
      }),
    isLoadingControllers: loading,
    error,
  };
};

import { IconButton, Stack } from '@mui/material';
import { useMessageTemplates } from 'features/CustomerManager/components/SendWaMessage/hooks/api/useMessageTemplates';
import { useDeleteCustomer } from 'features/CustomerManager/hooks/api/useDeleteCustomer';
import { WATemplate } from 'features/CustomerManager/whatsAppModels';
import { useMessagesTemplates } from 'features/MessagesTemplatesManager/hooks/api/useMessagesTemplates';
import { useTranslation } from 'react-i18next';

import { close_popup } from 'shared/assets';
import { useToast } from 'shared/hooks';
import { BaseBtn, BigTitle, Modal, Row } from 'shared/ui';

interface Props {
  openDeleteModal: boolean;
  onCloseModal: () => void;
  currentMessageTemplate: WATemplate;
  updateMessageTemplates: () => void;
}

export const DeleteMessageTemplate = ({
  openDeleteModal,
  onCloseModal,
  currentMessageTemplate,
  updateMessageTemplates,
}: Props) => {
  const { t } = useTranslation();
  const closeModal = () => onCloseModal();
  const { deleteMessageTemplate, isLoading } = useMessagesTemplates();
  const { showError, showSuccess } = useToast();
  const handleDelete = async () => {
    const params = {
      messageTemplateName: currentMessageTemplate.name,
      id: currentMessageTemplate.id,
    };

    const result = await deleteMessageTemplate(params);
    if (result.data.succeeded) {
      onCloseModal();
      showSuccess(t('message_template_deleted'));
      updateMessageTemplates();
      return;
    }
    showError(t('something_went_wrong_toast_text'));
  };

  return (
    <Modal open={openDeleteModal} onClose={closeModal} sx={{ maxWidth: 660, margin: 'auto' }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <BigTitle>{t('delete_mssage_template_delete_modal')}</BigTitle>
        <IconButton onClick={closeModal}>
          <img src={close_popup} />
        </IconButton>
      </Stack>

      <Row justifyContent="center" mt={'35px'}>
        <Row spacing="15px" width={500}>
          <BaseBtn onClick={closeModal} btnColor="info.main" fullWidth>
            {t('cancel_delete_modal')}
          </BaseBtn>

          <BaseBtn onClick={handleDelete} loading={isLoading} btnColor="primary.dark" fullWidth>
            {t('delete_delete_modal')}
          </BaseBtn>
        </Row>
      </Row>
    </Modal>
  );
};

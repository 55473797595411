import useAxios from 'app/useAxios';
import {  useExtendedTranslation, useToast } from 'shared/hooks';
interface newAccessLevelCodeData {
	userID: number, newAccessLevelCode:string 
}
export const useUpdateAccessLevel = () => {
  const { showError} = useToast();
  const {loading, request } = useAxios();
  const { t } = useExtendedTranslation();
  return {
    updateAccessLevel: async (data: newAccessLevelCodeData) =>{
    const result= await request<newAccessLevelCodeData, { data: {result:number}, succeeded :boolean  }>({
        method: 'put',
        showSuccessMessageCode: undefined,
        url: `/user/accessLevel`,
        payload: data,
      })
      if(!result.succeeded){
        showError(t('update_failed_special_logical_requirements_are_not_meted'));
        return false
      }
      switch (result.data.result) {
        case -3:
          showError(t('user_has_access_to_all_objects'));
          return false
        case -2:
          showError(t('user_does_not_belong_to_the_system_customer'));
          return false
        case 1:
          return true
        default:
          showError(t('update_failed_special_logical_requirements_are_not_meted'));
          return false
      }
    },
    isLoadingCustomers: loading,
  }

};

import { useAppDispatch } from 'shared/hooks';
import { ControllerRegisters } from 'shared/models';
import useAxios from './useAxios';

export const enum DataSourceTypes {Realtime=0,History=1}
export type  DeviceModelTypes = "I1" | "IP" | "WI";


export const useControllerRegisters = () => {
  // const dispatch = useAppDispatch();
  const { loading, request,error} = useAxios();

  return {
   
      getControllerRegisterEvents: (input:{deviceId: number | string}) =>
      request<{ deviceId: number | string}, { data: ControllerRegisters }>({
        method: 'post',
        url: `/device/registers`,
        showSuccessMessageCode: undefined,
        onDataReceiveCompleted: (data) => {
         // dispatch(setControllers(data?.data));
        },
        payload: {objectId:input.deviceId?.toString()}
      }),
      isLoadingControllerRegisters: loading,
      
      error
  };
};


import { TableTitle, TranslateColumn} from 'components/BaseTable';
import { ServiceCall } from '../ServiceCallModels';

// customer_id_service_calls_grid_table
// customer_name_service_calls_grid_table
// controller_id_service_calls_grid_table
// controller_name_service_calls_grid_table
// fault_equipment_service_calls_grid_table
// hwid_service_calls_grid_table
// action_type_service_calls_grid_table
// assigned_tech_service_calls_grid_table
// calls_count_service_calls_grid_table

export const TABLE_TITLES: TableTitle< ServiceCall>[] = [
  { title: 'customer_id', key: 'customerID', width: 80 },
  { title: 'customer_name', key: 'customerName', width: '100%' },
  { title: 'controller_id', key: 'controllerID', width: 80 },
  { title: 'controller_name', key: 'controllerName', width: '100%' },
  { title: 'fault_equipment', key: 'faultEquipment', width: '100%' },
  { title: 'hwid', key: 'hwid', width: 80 },
  { title: 'action_type', key: 'actionType', width: '100%' },
  { title: 'assigned_tech', key: 'assignedTech', width: '100%' },
  { title: 'calls_count', key: 'callsCount', width: 80 },
  { title: 'last_dt', key: 'lastDT', width: '75%' }
];

export const TRANSLATE_ARRAY_COLUMNS= [4,6]
// export const TRANSLATE_COLUMNS: TranslateColumn<ServiceCall>[]=[
//     {  key: 'status', additionalKey:'_message_status_item' },
// ]



import { Box } from '@mui/material';
import { BaseExportToExcel, BaseTable, PagePreloader } from 'components';
import { TranslateColumn } from 'components/BaseTable';
import { Filter, PrintTable } from 'features';
import { useCallback, useEffect, useMemo, useRef, useState, useTransition } from 'react';
import ReactToPrint from 'react-to-print';
import { action_start, delete_icon, duplicate, edit, print } from 'shared/assets';
import { useAppSelector, useExtendedTranslation } from 'shared/hooks';
import { getSearchedCustomers, getSortedCustomersByKey } from 'shared/lib';
import { BaseBtn, BigTitle, Icon, Row, SearchInput, Text } from 'shared/ui';
import TotalRows from 'shared/ui/TotalRows';
import { AddCustomer, DeleteCustomer, EditCustomer, SendWaMessage } from './components';
import { DATE_COLUMN_INDEXES, TABLE_TITLES } from './constants';
import { Customer } from './customerModels';
import {
  useFilterCustomers,
  useFormatXlsxData,
  useGetCustomerAgreementCodes,
  useGetCustomerStatusCodes,
  useGetCustomerTypes,
  useGetCustomers,
  useSearchCustomers,
  useSortCustomers,
} from './hooks';
import { BaseExportOptions } from 'entities_';

export const CustomerManager = () => {
  const { t } = useExtendedTranslation();
  const { isLoadingCustomers, getCustomer } = useGetCustomers();
  const { isLoadingTypes, getCustomerTypes } = useGetCustomerTypes();
  const { isLoadingStatusCodes, getCustomerStatusCodes } = useGetCustomerStatusCodes();
  const { isLoadingAgreementCodes, getCustomerAgreementCodes } = useGetCustomerAgreementCodes();
  const loading = isLoadingCustomers || isLoadingTypes || isLoadingStatusCodes || isLoadingAgreementCodes;
  const { customers, customerStatuses, customerTypes, customerAgreementCodes } = useAppSelector((st) => st.customers);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer>(null);
  const [openCustomerEditModal, setOpenCustomerEditModal] = useState(false);
  const [openCustomerAddModal, setOpenCustomerAddModal] = useState(false);
  const [openDeleteCustomerModal, setOpenDeleteCustomerModal] = useState(false);
  const [selectedCustomersID, setSelectedCustomersID] = useState([]);
  const { formatTable } = useFormatXlsxData();
  const [openSendWaMessageModal, setOpenSendWaMessageModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([getCustomer(), getCustomerTypes(), getCustomerStatusCodes(), getCustomerAgreementCodes()]);
    };
    fetchData();
  }, []);

  const { searchValue, changeSearch } = useSearchCustomers();
  const { sortBy, onChangeSort, isIncreaseSort } = useSortCustomers();
  const {
    agreementFilterValues,
    statusFilter,
    typeFilter,
    onChangeAgreementFilterItems,
    onChangeStatusFilter,
    onChangeTypeFilter,
    agreementFilterItems,
    statusFilterItems,
    typeFilterItems,
    actionsFilter,
    onChangeActionsFilter,
    actionsItemsFilter,
  } = useFilterCustomers();

  const tableCustomers = useMemo(() => {
    const filteredCustomers = customers
      .filter((c) => (typeFilter ? c.customerTypeId?.toString() === typeFilter : true))
      .filter((c) => (statusFilter ? c.customerStatusId?.toString() === statusFilter : true))
      .filter((c) => agreementFilterValues.includes(c.customerAgreementStatusId?.toString()));
    const searchedCustomers = getSearchedCustomers(filteredCustomers, searchValue);
    const sortedCustomers = getSortedCustomersByKey(searchedCustomers, sortBy);

    if (!isIncreaseSort) sortedCustomers.reverse();

    return sortedCustomers;
  }, [searchValue, agreementFilterValues, statusFilter, typeFilter, customers, sortBy, isIncreaseSort]);

  const onClickAddCustomer = () => {
    setOpenCustomerAddModal(true);
  };

  const ref = useRef<HTMLDivElement>(null);
  const reactToPrintContent = useCallback(() => ref.current, [ref.current]);
  const reactToPrintTrigger = useCallback(
    () => (
      <BaseBtn btnColor="info.main" sx={{ mr: '20px' }}>
        {t('print_button')}
        <Icon src={print} ml={'7px'} />
      </BaseBtn>
    ),
    []
  );
  const handleOpenCustomerEditModal = (row: Customer) => {
    setSelectedCustomer(row);
    setOpenCustomerEditModal(true);
  };
  const handleOpenCustomerDeleteModal = (row: Customer) => {
    setSelectedCustomer(row);
    setOpenDeleteCustomerModal(true);
  };
  const closeCustomerEditModal = () => {
    setOpenCustomerEditModal(false);
  };
  const closeCustomerAddModal = () => {
    setOpenCustomerAddModal(false);
  };
  const closeCustomerDeleteModal = () => {
    setOpenDeleteCustomerModal(false);
  };
  const setCurrentCustomer = (row: Customer) => {
    setSelectedCustomer(row);
  };
  const customerTypeTranslationMap = () =>
    customerTypes.reduce((map, item) => {
      map[item.customerTypeID] = item.customerType;
      return map;
    }, {});
  const customerStatusTranslationMap = () =>
    customerStatuses.reduce((map, item) => {
      map[item.customerStatusCode] = item.customerStatus;
      return map;
    }, {});
  const customerAgreementTranslationMap = () =>
    customerAgreementCodes.reduce((map, item) => {
      map[item.code] = item.text;
      return map;
    }, {});
  const translateColumns: TranslateColumn<Customer>[] = [
    { key: 'customerTypeId', translationMap: customerTypeTranslationMap() },
    { key: 'customerStatusId', translationMap: customerStatusTranslationMap() },
    { key: 'customerAgreementStatusId', translationMap: customerAgreementTranslationMap() },
  ];

  const actions = useMemo(
    () => [
      { icon: edit, title: `${t('edit-user-grid-table-modal')}`, onClick: handleOpenCustomerEditModal },
      { icon: delete_icon, title: `${t('delete-customers-grid-table-modal')}`, onClick: handleOpenCustomerDeleteModal },
      { icon: duplicate, title: `${t('duplicate-customers-grid-table-modal')}`, onClick: () => { } },
    ],
    []
  );
  const [isPending, startTransition] = useTransition();
  const onSelectFilter = () => {
    startTransition(() => { });
  };
  const rowSelectionModelChange = useCallback((rows) => {
    setSelectedCustomersID(rows);
  }, []);
  const onClickActionStart = () => {
    setOpenSendWaMessageModal(true);
  };
  const closeOpenSendWaMessageModal = () => {
    setOpenSendWaMessageModal(false);
  };

  const BaseExportToExcelComponent = useMemo(
    () => (<BaseExportOptions
      tableData={tableCustomers}
      sheetName="CLIENTS"
      fileName="clients.xlsx"
      buttonText={t('export_customers_page_button')}
      dateColumnIndexes={[8, 9]}
      stringColumnIndexes={[6, 12]}
      formattedTable={formatTable(tableCustomers)}
      tableTitles={TABLE_TITLES}
      headerItemsPrefix={'_customer_grid_table'}
    />),
    [tableCustomers]
  );

  return (
    <>
      <PagePreloader loading={loading || isPending} />
      <>
        <Row sx={{ mb: '12px' }}>
          <BigTitle sx={{ mr: '25px' }}>{t('customers_title')}</BigTitle>

          <BaseBtn onClick={onClickAddCustomer}>
            {t('add_customer_button')}
            <Text sx={{ fontSize: '25px', mb: '4px', ml: '4px' }}>+</Text>
          </BaseBtn>
        </Row>
        <SearchInput
          value={searchValue}
          onChange={changeSearch}
          placeholder={`${t('which_customer_are_you_looking_for_search_placeholder_customer_page')}`}
        />

        <Row sx={{ mt: '6px' }} flexWrap="wrap" rowGap="5px" alignItems="flex-end">
          <BigTitle sx={{ fontSize: '24px', mr: '20px' }}>{t('filter_customers_page_title')}</BigTitle>
          {statusFilterItems.length ? (
            <Filter
              title={`${t('customer_status_label')}`}
              value={statusFilter}
              onChange={onChangeStatusFilter}
              items={statusFilterItems}
              sx={{ width: '180px' }}
              messageEmptyFilter="display_all_customer_status"
            />
          ) : (
            <></>
          )}
          <Filter
            title={`${t('customer_agreement_label')}`}
            items={agreementFilterItems}
            withConfirm
            checkBoxValues={agreementFilterValues}
            onChangeCheckBoxValues={(values: string[]) => {
              onSelectFilter();
              onChangeAgreementFilterItems(values);
            }}
            sx={{ width: '180px' }}
            messageEmptyFilter="display_all_customer_agreement"
          />

          {typeFilterItems.length ? (
            <Filter
              title={`${t('customer_type_label')}`}
              value={typeFilter}
              onChange={(value: string) => {
                onSelectFilter();
                onChangeTypeFilter(value);
              }}
              items={typeFilterItems}
              sx={{ width: '180px' }}
              messageEmptyFilter="display_all_customer_type"
            />
          ) : (
            <></>
          )}
          <Filter
            title={`${t('actions_filter')}`}
            value={actionsFilter}
            onChange={onChangeActionsFilter}
            items={actionsItemsFilter}
            hideAllButton
            sx={{ width: '180px' }}
            messageEmptyFilter={`${t('actions_please_select_an_action')}`}
            placeholder={`${t('filter_search_controller_page_placeholder')}`}
          />
          <BaseBtn
            onClick={onClickActionStart}
            disabled={!actionsFilter || !selectedCustomersID.length}
            sx={{ mt: '16px', height: '29px' }}
          >
            {t('controllers_action_start')}
            <Icon sx={{ ml: '6px' }} src={action_start} />
          </BaseBtn>

          <Row sx={{ flexGrow: 1 }} />
          <TotalRows count={tableCustomers.length} />

          <ReactToPrint trigger={reactToPrintTrigger} content={reactToPrintContent} />

          <div style={{ display: 'none' }}>
            <PrintTable customers={tableCustomers} ref={ref} />
          </div>

          <Box
            sx={{
              marginLeft: 'auto',
            }}
          >
            {BaseExportToExcelComponent}
          </Box>
        </Row>
        <BaseTable
          tableTitles={TABLE_TITLES}
          tableItems={tableCustomers}
          loadedItems={true}
          selectedRowID={selectedCustomer?.id}
          idField={'id'}
          noItemsText={t('no_clients_customer_grid_table')}
          headerItemsPrefix={'_customer_grid_table'}
          dateColumnIndexes={DATE_COLUMN_INDEXES}
          linkIndex={[1]}
          cellClick={handleOpenCustomerEditModal}
          actions={actions}
          translateColumns={translateColumns}
          checkboxSelection
          onRowSelectionModelChange={rowSelectionModelChange}
          loading={loading || isPending}
          stringColumnIndexes={[6, 12]}
        />
      </>
      <AddCustomer
        openCustomerAddModal={openCustomerAddModal}
        onCloseModal={closeCustomerAddModal}
        setCurrentCustomer={setCurrentCustomer}
      />
      <EditCustomer
        currentCustomer={selectedCustomer}
        openCustomerEditModal={openCustomerEditModal}
        onCloseModal={closeCustomerEditModal}
      />
      <DeleteCustomer openDeleteCustomerModal={openDeleteCustomerModal} onCloseModal={closeCustomerDeleteModal} />
      <SendWaMessage
        openModal={openSendWaMessageModal}
        onCloseModal={closeOpenSendWaMessageModal}
        selectedCustomersID={selectedCustomersID}
        customers={customers}
      />
    </>
  );
};

import { createSlice } from '@reduxjs/toolkit';

interface PopupState {
  openExpirationToken: boolean;
}

const initialState: PopupState = {
  openExpirationToken: false,
};

const popupSlice = createSlice({
  name: 'popups',
  initialState,
  reducers: {
    toggleExpirationToken: (state) => {
      state.openExpirationToken = !state.openExpirationToken;
    },
  },
});

export const { toggleExpirationToken } = popupSlice.actions;

export const popupReducer = popupSlice.reducer;

import { Box } from '@mui/material';
import { t } from 'i18next';
import React, { FC } from 'react';
import { Row } from '../Row';
import { useTranslation } from 'react-i18next';
import { Text } from '../Text';
import { Link } from 'react-router-dom';
import { replaceSpacesWithUnderscores } from 'shared/lib/replaceSpacesWithUnderscores';

interface INavSubMenuItem {
  title: string;
  path: string;
  clickReload: (path:string)=> void
}

const NavSubMenuItem: FC<INavSubMenuItem> = ({ title, path, clickReload }) => {
  const { t } = useTranslation();

  return (
    <Link to={path} onClick={()=>clickReload(path)}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ height: '1px', width: '90%', background: '#ffffff', alignSelf: 'center', opacity: 0.2 }}></Box>
        <Row sx={{ p: '7px 15px ' }}>
          {/* <Icon src={navIcons[i]} /> */}
          <Text
            sx={{ fontSize: '12px', whiteSpace: 'nowrap', color: 'info.main', ml: '7px', textTransform: 'capitalize' }}
          >
            {t(`header_${replaceSpacesWithUnderscores(title)}`)}
          </Text>
        </Row>
      </Box>
    </Link>
  );
};

export default NavSubMenuItem;

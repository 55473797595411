import { Box, Stack } from '@mui/material';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { select_all, select_none } from 'shared/assets';
import localStorageService from 'shared/localStorage';
import { BaseBtn, BaseCheckbox, BigTitle, Icon, Modal, Row } from 'shared/ui';

type TableTitle<T> = { title: string; key: keyof T; width: number | string };
export type TableTitleSimple = { title: string; key: string; width: number | string };
interface Props<T> {
  openModal: boolean;
  onCloseModal: () => void;
  tableTitles: TableTitle<T>[] | TableTitleSimple[];
  headerItemsPrefix: string;
  sheetName: string;
  preHeaderItemsPrefix?: string;
  onExport: () => void;
}
type CheckboxesStatuses<T> = { [K in keyof T]: boolean };
export const FieldSelectionModal = <T,>({
  openModal,
  onCloseModal,
  tableTitles,
  headerItemsPrefix,
  preHeaderItemsPrefix,
  sheetName,
  onExport,
}: Props<T>) => {
  const [checkboxesStatuses, setCheckboxesStatuses] = useState<CheckboxesStatuses<T>>();

  useEffect(() => {
    if (openModal) {
      initializeCheckboxes();
    }
  }, [openModal]);

  const getDataFromLocalStorage = () => {
    const data = localStorageService.itemsFieldsToExport;
    return data ? JSON.parse(data) : null;
  };

  const initializeCheckboxes = () => {
    const checkboxesStatuses = {} as { [K in keyof T]: boolean };
    let data = getDataFromLocalStorage();
    if (!data) data = {};
    tableTitles.forEach((item) => {
      checkboxesStatuses[item.key] = true;
    });
    if (!data[sheetName]) {
      const newItems = { ...data };
      newItems[sheetName] = checkboxesStatuses;
      localStorageService.itemsFieldsToExport = JSON.stringify(newItems);
      setCheckboxesStatuses(checkboxesStatuses);
      return;
    }
    const statuses = data[sheetName];
    setCheckboxesStatuses(statuses);
  };

  const saveCheckboxesStatusesToLocalStorage = () => {
    const data = getDataFromLocalStorage();
    const newItems = { ...data };
    newItems[sheetName] = checkboxesStatuses;
    localStorageService.itemsFieldsToExport = JSON.stringify(newItems);
  };

  const changeCheckboxStatus = (event, key) => {
    const newValue = event.target.checked;
    checkboxesStatuses[key] = newValue;
    setCheckboxesStatuses((prevState) => ({
      ...prevState,
      [key]: newValue,
    }));
  };
  const exportSelected = () => {
    saveCheckboxesStatusesToLocalStorage();
    onExport();
  };

  const changeAll = (status: boolean) => {

    const statuses = {} as { [K in keyof T]: boolean };
    // Object.keys(checkboxesStatuses).forEach((key) => {
    //   statuses[key] = status;
    // });
    // setCheckboxesStatuses(statuses);

    tableTitles.forEach(item => {
      statuses[item.key] = status
    })
    setCheckboxesStatuses(statuses);

  };

  const isSelectAnyCheckbox = () => {
    let isAnySelected = false;
    Object.keys(checkboxesStatuses).forEach((key) => {
      if (checkboxesStatuses[key]) {
        isAnySelected = true;
        return;
      }
    });
    return isAnySelected;
  };

  return (
    <Modal open={openModal} sx={{ margin: 'auto', width: '860px' }} onClose={onCloseModal}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <BigTitle sx={{ fontSize: '24px', mr: '20px' }}>{t('fields_to_export_modal')}</BigTitle>
        <Row sx={{ flexGrow: 1 }} />
        <BaseBtn sx={{ height: '29px', mr: '20px' }} btnColor="primary.dark" onClick={() => changeAll(true)}>
          <Icon src={select_all} sx={{ mr: '8px' }} /> {t('select_all')}
        </BaseBtn>

        <BaseBtn sx={{ height: '29px' }} btnColor="info.main" onClick={() => changeAll(false)}>
          <Icon src={select_none} sx={{ mr: '8px' }} /> {t('select_none')}
        </BaseBtn>
      </Stack>
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        {tableTitles.map((s) => (
          <Box key={s.title} width={'33%'}>
            {checkboxesStatuses && (
              <BaseCheckbox
                labelColor="black"
                label={t(`${preHeaderItemsPrefix ? preHeaderItemsPrefix : ''}${s.title}${headerItemsPrefix}`)}
                value={checkboxesStatuses[s.key] || false}
                onChange={(e) => changeCheckboxStatus(e, s.key)}
              />
            )}
          </Box>
        ))}
      </Box>

      <Row justifyContent="right" mt={'35px'}>
        {checkboxesStatuses && (
          <Row spacing="15px" width={500}>
            <BaseBtn onClick={onCloseModal} btnColor="info.main" fullWidth>
              {t('cancel_modal')}
            </BaseBtn>

            <BaseBtn btnColor="primary.dark" onClick={exportSelected} disabled={!isSelectAnyCheckbox()} fullWidth>
              {t('export_modal')}
            </BaseBtn>
          </Row>
        )}
      </Row>
    </Modal>
  );
};

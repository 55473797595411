import { BatteryStatusExcludeFilter } from 'shared/models';
import { useAppSelector } from 'shared/hooks';
import dayjs from 'dayjs';
export const useGetDefaultDates = (reportFilter: BatteryStatusExcludeFilter) => {
  const { BATT_REP } = useAppSelector((st) => st.globalSlice);
  let startDateDayjs = dayjs(reportFilter.startDate);
  startDateDayjs = startDateDayjs.subtract(BATT_REP.GRAPH_START_DAYS_AGO, 'day');
  const endDateDayjs = startDateDayjs.add(BATT_REP.GRAPH_INTERVAL_DAYS, 'day');
  const startDate = startDateDayjs.format('YYYY-MM-DD');
  const endDate = endDateDayjs.format('YYYY-MM-DD');
  return {
    startDate,
    endDate,
  };
};

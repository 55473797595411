import { User } from "features/UserManager/UserModels";

export const  getSearchedAccounts = (customers: User[], searchValue: string) => {
  const searched = customers.filter((c) => {
    const values = Object.values(c);

    return values.some((v) => v?.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase().trim()));
  });

  return searched;
};
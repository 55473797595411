import * as yup from 'yup';

export const customerSchema = yup.object().shape({
  customerName: yup
    .string()
    .required('Please enter customer name')
    .min(3, 'Name must contain at least 3 characters')
    .max(50, 'Max length 50 characters')
    .trim(),

  postAddress: yup.string().max(250, 'Max length 250 characters').trim(),
  eMailAddress: yup.string().max(250, 'Max length 250 characters').email().trim().notRequired(),
  officePhoneNumber: yup.string().max(250, 'Max length 250 characters').trim(),
  contactPersonName: yup.string().max(250, 'Max length 250 characters').trim(),
  contactPersonPhoneNumber: yup.string().max(250, 'Max length 250 characters').trim(),
  notes: yup.string().max(510, 'Max length 510 characters').trim(),
  shortNote: yup.string().max(50, 'Max length 50 characters').trim(),
});

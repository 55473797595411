import { Box } from '@mui/material';
import { t } from 'i18next';
import { BigTitle, BaseBtn, Row, Icon, Text, Loader, BaseInput } from 'shared/ui';
import { ServiceCallsTable } from 'features/ServiceCallsTable';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { EditServiceCalls, AddServiceCalls } from 'widgets';
import { toggleAddServiceCall } from 'shared/slices';
import { FC, useEffect } from 'react';
import { PowerProblemsItem } from 'features/ExternalPowerProblemsReportManager/PowerProblemsReportModels';
import { useServiceCallsManagement } from 'shared/api';

interface Props {
  changedServiceCalls: () => void;
  customerID: number;
  customerName: string;
  controllerID: number;
  controllerName: string;
  faultEquipmentCode?: number;
  showFull: boolean;
  customerAgreementStatus: number;
}

export const ServiceCalls: FC<Props> = ({
  changedServiceCalls,
  customerID,
  customerName,
  controllerID,
  controllerName,
  faultEquipmentCode,
  showFull,
  customerAgreementStatus,
}) => {
  const { getServiceCalls, isLoading } = useServiceCallsManagement();
  const dispatch = useAppDispatch();
  const { serviceCalls } = useAppSelector((st) => st.serviceCalls);
  const handleOpenAddModal = () => {
    dispatch(toggleAddServiceCall());
  };
  useEffect(() => {
    getServiceCalls(controllerID);
  }, [controllerID]);
  const { customerAgreementCodes } = useAppSelector((st) => st.customers);
  const getCustomerAgreementName = (id: number) => {
    const index = customerAgreementCodes.findIndex((item) => item.code === id);
    return customerAgreementCodes[index].text;
  };
  return (
    <Box>
      <Row sx={{ mt: '6px' }} flexWrap="wrap" rowGap="5px" alignItems="flex-end">
        <BigTitle>{t('last_service_calls_title')}</BigTitle>
        <BaseBtn disabled={!controllerName} onClick={handleOpenAddModal} sx={{ fontFamily: 'Noto Sans Hebrew', marginLeft: '20px' }}>
          <Box component="span">{t('add_service_call')}</Box>
          <Text sx={{ fontSize: '25px', mb: '4px', ml: '4px' }}>+</Text>
        </BaseBtn>
        <Row sx={{ flexGrow: 1 }} />
        <BaseInput
          label={``}
          name="customerAgreementStatus"
          disabled
          helperText=""
          value={customerAgreementStatus ? getCustomerAgreementName(customerAgreementStatus) : ''}
          onChange={() => { }}
          small
          compact
          blueBorder
        />
        <Box sx={{ width: '71px' }}></Box>
      </Row>
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '250px' }}>
          <Loader />
        </Box>
      ) : (
        <ServiceCallsTable serviceCalls={serviceCalls} tableMaxHeight={showFull ? 610 : 240} />
      )}
      <EditServiceCalls changedServiceCalls={changedServiceCalls} />
      {controllerName && customerName && customerID && controllerID && <AddServiceCalls
        customerID={customerID}
        customerName={customerName}
        controllerID={controllerID}
        controllerName={controllerName}
        changedServiceCalls={changedServiceCalls}
        faultEquipmentCode={faultEquipmentCode}
      />}
    </Box>
  );
};

import { Box, IconButton, Stack } from '@mui/material';
import { AssignedCustomer } from 'features/WeatherStationsManager/WeatherStationModels';
import { t } from 'i18next';
import { FC } from 'react';
import { close_popup } from 'shared/assets';
import { BaseBtn, BigTitle, Modal, Row } from 'shared/ui';


interface Props {
	openModal: boolean;
	onCloseModal: () => void;
}

export const AddedCustomer: FC<Props> = ({
	openModal,
	onCloseModal
}) => {
	const deleteCustomer = () => {

	}
	return (
		<Modal open={openModal} onClose={onCloseModal}
			padding={'8px 16px'}>
			<Box>
				<Stack direction="row" justifyContent="space-between" alignItems="center">
					<BigTitle>{t('add_new_Customer')} </BigTitle>
					<IconButton onClick={onCloseModal}>
						<img src={close_popup} />
					</IconButton>
				</Stack>
				<Row justifyContent="center">
					<Row width={300} gap="10px">
						<BaseBtn onClick={onCloseModal} btnColor="info.main" fullWidth>
							{t('cancel_add_new_customer_button_modal')}
						</BaseBtn>
					</Row>
				</Row>
			</Box>
		</Modal >
	);
};

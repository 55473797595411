import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WaTemplatesResult } from './whatsAppModels';

interface whatsAppState {
    messageTemplatesData: WaTemplatesResult;
}

const initialState: whatsAppState = {
    messageTemplatesData: null,
};

const whatsAppSlice = createSlice({
  name: 'whatsApp',
  initialState,
  reducers: {
    setMessageTemplates: (state, action: PayloadAction<WaTemplatesResult>) => {
      state.messageTemplatesData = action.payload;
      state.messageTemplatesData.data=state.messageTemplatesData.data.filter(item=>!item.name.includes("sys_"))
      state.messageTemplatesData.data.map(item=>{
       // console.log(item.components[0].text)
        item.text=item?.components[0]?.text
        return item
      })
    },

  },
});

export const {
    setMessageTemplates
} = whatsAppSlice.actions;

export const whatsAppReducer = whatsAppSlice.reducer;

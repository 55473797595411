import { ChangeEvent, FC, useEffect, useMemo, useState, useRef } from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  IconButtonProps,
  Popover,
  Radio,
  RadioGroup,
} from '@mui/material';
import { BaseBtn, Icon, Row, SearchInput, Text } from '../../shared/ui';
import { Height, PlayArrow } from '@mui/icons-material';
import { useAnchorEl, useAppDispatch, useExtendedTranslation } from '../../shared/hooks';
import { checked_box, clear_filter, home, un_checked_box } from 'shared/assets';
import { SxProps } from '@mui/system';
import { toggleCreateProviderModal } from 'shared/slices';
interface ResetButtonProps extends IconButtonProps {
  showHomeButton?: boolean;
}

const ResetButton: FC<ResetButtonProps> = ({ onClick, sx, showHomeButton }) => (
  <IconButton
    onClick={onClick}
    sx={{
      position: 'absolute',
      zIndex: 2,
      height: '29px',
      width: '33px',
      ...sx,
    }}
  >
    <Icon src={showHomeButton ? home : clear_filter} />
  </IconButton>
);

interface Props {
  title: string;
  items: { value: string; label: string }[];
  value?: string;
  onChange?: (value: string) => void;
  checkBoxValues?: string[];
  onChangeCheckBoxValues?: (value: string[]) => void;
  withSearch?: boolean;
  withConfirm?: boolean;
  hideAllButton?: boolean;
  showHomeButton?: boolean;
  setDefaultValue?: () => void;
  defaultValue?: string;
  messageEmptyFilter?: string;
  sx?: SxProps;
  placeholder?: string;
  hideResetButton?: boolean;
}

export const ImportSimProviderFilter: FC<Props> = ({
  sx,
  title,
  items,
  value,
  onChange,
  checkBoxValues,
  onChangeCheckBoxValues,
  withSearch,
  withConfirm,
  hideAllButton,
  showHomeButton,
  setDefaultValue,
  defaultValue,
  messageEmptyFilter = 'display_all_filter',
  placeholder,
  hideResetButton,
}) => {
  const filterPopoverRef = useRef<HTMLDivElement>(null);
  const { t } = useExtendedTranslation();
  const { isOpen, anchorEl, onOpenMenu, onCloseMenu } = useAnchorEl();
  const [innerValue, setInnerValue] = useState<string>(value ?? '');
  const [filterWidth, setFilterWidth] = useState<string>('160');
  const [filterItems, setfilterItems] = useState<{ value: string; label: string }[]>(items);
  const dispatch = useAppDispatch();

  const handleChange = (value: string) => {
    if (withConfirm) {
      setInnerValue(value);
      return;
    }
    onChange?.(value);
    onCloseMenu();
  };
  useEffect(() => {
    setfilterItems(items);
  }, [items]);
  const valueItems = [...items];
  const currentFilterName = useMemo(() => valueItems.find((item) => item.value === value)?.label, [value]);

  const [innerCheckBoxValues, setInnerCheckBoxValue] = useState<string[]>(checkBoxValues ?? []);
  const handleChangeCheckBoxValues = (value: string) => {
    const isAdd = innerCheckBoxValues.includes(value);

    if (isAdd) {
      const newValues = innerCheckBoxValues.filter((v) => v !== value);
      setInnerCheckBoxValue(newValues);
    } else {
      setInnerCheckBoxValue((prev) => [...prev, value]);
    }
  };

  const currentFilterItemNames = useMemo(() => {
    if (items.length === checkBoxValues?.length) return t(`${messageEmptyFilter}`);

    return items
      .filter((item) => checkBoxValues?.includes(item.value))
      .map((item) => item.label)
      .join(' , ');
  }, [checkBoxValues]);

  const [search, setSearch] = useState('');
  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

  useEffect(() => {
    setfilterItems(
      valueItems.filter((i) => {
        const findByLabel = i.label.toLowerCase().includes(search.toLowerCase().trim());
        const findByValue = i.value.toLowerCase().includes(search.toLowerCase().trim());
        return findByLabel || findByValue;
      })
    );
  }, [search]);

  const handleApply = () => {
    if (checkBoxValues?.length) {
      onChangeCheckBoxValues?.(innerCheckBoxValues);
    }
    if (value) {
      onChange?.(innerValue);
    }

    onCloseMenu();
    setSearch('');
  };

  const handleClose = () => {
    onCloseMenu();
    setInnerCheckBoxValue(checkBoxValues as string[]);
  };

  const clearFilterValue = () => onChange?.('');
  const toggleAllCheckBoxFilters = () => {
    if (innerCheckBoxValues.length === items.length) {
      setInnerCheckBoxValue([]);
    } else {
      setInnerCheckBoxValue(items.map((v) => v.value));
    }
  };
  const resetFilters = () => {
    setInnerCheckBoxValue(items.map((v) => v.value));
    onChangeCheckBoxValues?.(items.map((v) => v.value));
  };

  const homeButtonClick = () => {
    if (setDefaultValue) setDefaultValue();
  };

  const handleOpenCreateOperator = () => {
    dispatch(toggleCreateProviderModal());
  };
  return (
    <Box sx={{ mr: '20px', width: filterWidth, ...sx }}>
      <Text noWrap sx={{ color: 'info.dark', fontSize: '12px', fontWeight: 500, pr: '2px', lineHeight: '15px' }}>
        {title}
      </Text>

      <Row position="relative" justifyContent="space-between">
        {/* showHomeButton */}
        {showHomeButton ? (
          <>
            {!checkBoxValues?.length && value !== '' && !hideResetButton && (
              <ResetButton showHomeButton onClick={homeButtonClick} />
            )}
          </>
        ) : (
          <>
            {!checkBoxValues?.length && value !== '' && !hideResetButton && <ResetButton onClick={clearFilterValue} />}
          </>
        )}
        {checkBoxValues?.length && items.length !== checkBoxValues?.length && !hideResetButton && (
          <ResetButton showHomeButton={!!showHomeButton} onClick={resetFilters} />
        )}

        <BaseBtn btnColor="info.main" onClick={onOpenMenu} sx={{ height: '29px', minWidth: '100%', px: 0 }}>
          <Text
            noWrap
            sx={{
              color: value === '' ? '#d3d3d3 !important' : 'primary.dark',
              fontSize: '14px',
              fontWeight: 500,
              width: '100%',
              maxWidth: '300px',
              pl:
                (!checkBoxValues?.length && value !== '' && !hideResetButton) ||
                (checkBoxValues?.length && items.length !== checkBoxValues?.length && !hideResetButton)
                  ? '35px'
                  : '10px',
              textAlign: 'left',
            }}
          >
            {checkBoxValues?.length ? currentFilterItemNames : currentFilterName && t(`${currentFilterName}`)}
            {value === '' ? t(messageEmptyFilter) : ''}
          </Text>

          <PlayArrow
            sx={{
              m: '7px',
              fontSize: '14px',
              color: 'primary.dark',
              transform: `rotate(${isOpen ? '-90deg' : '90deg'})`,
            }}
          />
        </BaseBtn>
      </Row>

      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        ref={filterPopoverRef}
        sx={{
          mt: '5px',
          '& .MuiPaper-root': {
            borderRadius: '12px',
          },
        }}
      >
        <Box ref={filterPopoverRef}>
          {withSearch && (
            <SearchInput value={search} onChange={handleChangeSearch} placeholder={placeholder} noBorder />
          )}

          <Box
            sx={{
              borderTop: '1px solid grey',
              borderColor: withSearch ? 'info.dark' : 'info.main',
            }}
          >
            {filterItems.length === 0 ? (
              <Text sx={{ color: 'primary.main', textAlign: 'center', my: '10px' }}>{t('filter_no_items')}</Text>
            ) : (
              <FormControl sx={{ width: '100%', maxHeight: '500px' }}>
                {checkBoxValues ? (
                  <FormGroup>
                    <Box
                      sx={{
                        borderBottom: '1px solid black',
                        borderColor: 'primary.light',
                        px: '8px',
                        pb: '2px',
                        mb: '2px',
                      }}
                    >
                      <Row
                        onClick={toggleAllCheckBoxFilters}
                        sx={{
                          borderRadius: '10px',
                          height: '38px',
                          px: '2px',
                          cursor: 'pointer',
                          '&:hover': { bgcolor: 'primary.light' },
                        }}
                      >
                        <Checkbox
                          checked={innerCheckBoxValues.length === items.length}
                          icon={<Icon src={un_checked_box} />}
                          checkedIcon={<Icon src={checked_box} />}
                        />
                        <Text sx={{ color: 'black', pr: '10px' }}>{t(`${messageEmptyFilter}`)}</Text>
                      </Row>
                    </Box>
                    {filterItems.map(({ value, label }) => (
                      <Row
                        key={value}
                        onClick={() => handleChangeCheckBoxValues(value)}
                        sx={{
                          borderRadius: '10px',
                          height: '38px',
                          mx: '8px',
                          px: '2px',
                          cursor: 'pointer',
                          '&:hover': { bgcolor: 'primary.light' },
                        }}
                      >
                        <Checkbox
                          checked={innerCheckBoxValues.includes(value)}
                          icon={<Icon src={un_checked_box} />}
                          checkedIcon={<Icon src={checked_box} />}
                        />
                        <Text sx={{ color: 'black', pr: '10px' }}>{label}</Text>
                      </Row>
                    ))}
                  </FormGroup>
                ) : (
                  <RadioGroup value={withConfirm ? innerValue : value} sx={{ width: '100%' }}>
                    {!hideAllButton && (
                      <Box
                        sx={{
                          borderBottom: '1px solid black',
                          borderColor: 'primary.light',
                          px: '8px',
                          mb: '2px',
                        }}
                      >
                        <Box
                          onClick={() => handleChange('')}
                          sx={{
                            borderRadius: '10px',
                            cursor: 'pointer',
                            '&:hover': { bgcolor: 'primary.light' },
                          }}
                        >
                          <FormControlLabel
                            control={<Radio checked={value === ''} size="small" />}
                            label={<Text sx={{ color: 'black', pr: '10px' }}>{t(`${messageEmptyFilter}`)}</Text>}
                            sx={{
                              fontSize: '11px',
                              mx: 0,
                            }}
                          />
                        </Box>
                      </Box>
                    )}
                    {/* {defaultValue && (
                      <FormControlLabel
                        key={value}
                        value={value}
                        onChange={() => handleChange(defaultValue)}
                        control={<Radio size="small" />}
                        label={<Text sx={{ color: 'black', pr: '10px' }}>{defaultValue}</Text>}
                        sx={{
                          fontSize: '11px',
                          mx: '7px',
                          borderRadius: '10px',
                          '&:hover': { bgcolor: 'primary.light' },
                        }}
                      />
                    )} */}
                    {filterItems.map(({ value, label }) => (
                      <FormControlLabel
                        key={value}
                        value={value}
                        onChange={() => handleChange(value)}
                        control={<Radio size="small" />}
                        label={<Text sx={{ color: 'black', pr: '10px' }}>{label}</Text>}
                        sx={{
                          fontSize: '11px',
                          mx: '7px',
                          borderRadius: '10px',
                          '&:hover': { bgcolor: 'primary.light' },
                        }}
                      />
                    ))}
                    <BaseBtn
                      sx={{
                        fontFamily: 'Noto Sans Hebrew',
                        height: '29px',
                        borderRadius: '0',
                        borderRight: 0,
                        borderLeft: 0,
                        borderBottom: 0,
                      }}
                      btnColor="info.main"
                      onClick={handleOpenCreateOperator}
                    >
                      {t('sim_open_new_operator')}
                      <Text sx={{ color: 'primary.dark', fontSize: '25px', mb: '4px', ml: '4px' }}>+</Text>
                    </BaseBtn>
                  </RadioGroup>
                )}
              </FormControl>
            )}

            {withConfirm && (
              <Row spacing="10px" sx={{ m: '5px 8px 2px 8px' }}>
                <BaseBtn onClick={handleClose} btnColor="info.main" fullWidth>
                  {t('cancel_filter')}
                </BaseBtn>
                <BaseBtn onClick={handleApply} disabled={!innerCheckBoxValues.length} btnColor="primary.dark" fullWidth>
                  {t('apply_filter')}
                </BaseBtn>
              </Row>
            )}
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

import React, { FC, memo, useMemo, useState } from 'react';
import { Box, IconButton, Typography, styled } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';
import { Cell, MenuList } from 'shared/ui';
import { useAnchorEl, useAppDispatch, useAppSelector } from 'shared/hooks';
import { delete_icon, cancel } from 'shared/assets';
import { toggleOpenShowGraphModal } from 'shared/slices'; //
import { GridRowParams } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { getFormattedDate } from 'shared/lib';
import { useBatteryStatusReport } from 'shared/api';
import {
  setBatteryStatusItemsLoading,
  setCurrentBatteryStatusReportProjectID,
  setBatteryStatusReportProject,
} from 'shared/slices';
import { BatteryStatusItem } from 'shared/models';

interface visibleColumns {
  width: number;
}
interface CustomGridRowParams extends GridRowParams<BatteryStatusItem> {
  visibleColumns: visibleColumns[];
  onExcludeController: (objectId: number) => void;
  onExcludeProject: (projectId: number) => void;
}
const SelectMark = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '5px',
  p: 0,
  height: '100%',
  backgroundColor: theme.palette.primary.dark,
  borderTopRightRadius: '10px',
  borderBottomRightRadius: '10px',
}));
export const BatteryStatusReportItem: FC<CustomGridRowParams> = memo((props: CustomGridRowParams) => {
  const { filter, filterGraph } = useAppSelector((st) => st.batteryStatusReport);
  const { currentController } = useAppSelector((st) => st.controllers);
  const {
    projectId,
    projectName,
    HWID,
    objectId,
    controllerName,
    CTLVer,
    powerSupCode,
    disconnectType,
    lastOpenServiceCallDate,
  } = props.row;
  const { onExcludeController, onExcludeProject } = props;
  const { visibleColumns } = props;
  const cellSizes = visibleColumns.map((s) => s.width);
  const { isOpen, anchorEl, onOpenMenu, onCloseMenu } = useAnchorEl();
  const [activeModal, setActiveModal] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const handleOpenMenu = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setActiveModal(!activeModal);

    onOpenMenu(e);
  };
  const handleOpenShowGraphModal = () => {
    dispatch(setCurrentBatteryStatusReportProjectID(objectId));
    dispatch(setBatteryStatusReportProject(props.row));
    dispatch(toggleOpenShowGraphModal());
  };
  const handleCloseMenu = () => {
    setActiveModal(!activeModal);
    onCloseMenu();
  };
  const handleExcludeController = async () => {
    handleCloseMenu();
    onExcludeController(objectId);
  };

  const handleExcludeProject = async () => {
    handleCloseMenu();
    onExcludeProject(projectId);
  };

  const selected = useMemo(() => objectId === filterGraph.deviceId, [filterGraph.deviceId]);

  return (
    <>
      <Box
        id={`object${objectId}`}
        sx={{
          '&': {
            bgcolor: activeModal || selected ? '#d0e7ff' : 'info.main',
          },
          '&:hover': {
            bgcolor: activeModal || selected ? '#c0d7ff' : '#f0f0f0',
          },
        }}
      >
        <>
          <Cell cellSize={cellSizes[0]} hideTooltip sx={{ position: 'relative' }}>
            {selected && <SelectMark />}
            {projectId}
          </Cell>
          <Cell cellSize={cellSizes[1]}>{projectName}</Cell>
          <Cell cellSize={cellSizes[2]}>{HWID}</Cell>
          <Cell cellSize={cellSizes[3]}>{objectId}</Cell>
          <Cell cellSize={cellSizes[4]} onClick={handleOpenShowGraphModal}>
            {controllerName}
          </Cell>
          <Cell cellSize={cellSizes[5]}>{CTLVer}</Cell>
          <Cell cellSize={cellSizes[6]}>{t(powerSupCode)}</Cell>
          <Cell cellSize={cellSizes[7]}>{t(`disconnect_type_${disconnectType}`)}</Cell>
          <Cell cellSize={cellSizes[8]}>
            {lastOpenServiceCallDate ? getFormattedDate(lastOpenServiceCallDate as string) : ''}
          </Cell>

          <Cell cellSize={cellSizes[9]} hideTooltip>
            <IconButton onClick={handleOpenMenu} sx={{ height: '33px' }}>
              <MoreHoriz sx={{ fontSize: '24px' }} />
            </IconButton>
          </Cell>
        </>
      </Box>
      <MenuList
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        actions={[
          { icon: delete_icon, title: `${t('exclude_controller_table_modal')}`, onClick: handleExcludeController },
          { icon: delete_icon, title: `${t('exclude_project_table_modal')}`, onClick: handleExcludeProject },
          { icon: cancel, title: `${t('cancel_table_modal')}`, onClick: handleCloseMenu },
        ]}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          mr: '40px',
          '& .MuiPaper-root': {
            borderRadius: '12px',
          },
        }}
      />
    </>
  );
});

import { Box, IconButton, Stack } from '@mui/material';
import { useDeleteCustomer } from 'features/CustomerManager/hooks/api/useDeleteCustomer';
import { FotaFile } from 'features/FotaManager/fotaModels';
import { useTranslation } from 'react-i18next';

import { close_popup } from 'shared/assets';
import { BaseBtn, BigTitle, Modal, Row } from 'shared/ui';

interface Props {
  openModal: boolean;
  onCloseModal: () => void;
  selectedFile: FotaFile;
}

export const FileInfo = ({ openModal, onCloseModal, selectedFile }: Props) => {
  const { t } = useTranslation();
  const closeModal = () => onCloseModal();
  const { isLoading } = useDeleteCustomer();

  const handleDelete = () => {
    onCloseModal();
  };

  return (
    <Modal open={openModal} onClose={closeModal}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <BigTitle>{t('file_info_modal')}</BigTitle>
        <IconButton onClick={closeModal}>
          <img src={close_popup} />
        </IconButton>
      </Stack>
      {selectedFile && (
        <Row justifyContent="flex-start" mt={'35px'} spacing={'10px'}>
          <Box>{t('file_version_info_modal')} </Box>
          <Box>{selectedFile.note}</Box>
        </Row>
      )}
      <Row justifyContent="flex-end" mt={'35px'}>
        <Row spacing="15px" width={500}>
          <BaseBtn onClick={handleDelete} loading={isLoading} btnColor="primary.dark" fullWidth>
            {t('ok_edit_modal')}
          </BaseBtn>
        </Row>
      </Row>
    </Modal>
  );
};

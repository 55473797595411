import { Box } from '@mui/material';
import { Component } from 'features/CustomerManager/whatsAppModels';
import { FC, useEffect, useState } from 'react';
import { Params } from '../hooks/useWaMessageSettings';

interface Props {
  currentTemplate: Component[];
  paramsMessage: Params[];
}

export const FormatWaMessage: FC<Props> = ({ currentTemplate, paramsMessage }) => {
  const [header, setHeader] = useState<string>('');
  const [body, setBody] = useState<string>('');
  const [footer, setFooter] = useState<string>('');

  const changeString = (str: string, type: string) => {
    const params = paramsMessage.filter((item) => item.type == type);

    const replacedString = str.replace(/{{(\d+)}}/g, (match, group1) => {
      const index = params.findIndex((item) => item.number === group1);
      if (index !== -1) {
        return `[${params[index].value ? params[index].value : params[index].number}]`;
      } else {
        return match;
      }
    });
    return replacedString;
  };

  useEffect(() => {
    const header = currentTemplate?.find((item) => item.type == 'HEADER');
    const body = currentTemplate?.find((item) => item.type == 'BODY');
    const footer = currentTemplate?.find((item) => item.type == 'FOOTER');
    const changedHeader = header ? changeString(header.text, 'header') : null;
    const changedbody = body ? changeString(body.text, 'body') : null;
    const changedfooter = footer ? changeString(footer.text, 'footer') : null;

    setHeader(changedHeader);
    setBody(changedbody);
    setFooter(changedfooter);
  }, [paramsMessage]);
  const formatWithParagraph = (part) => {
    const paragraphs = part.split('\n'); // розділяємо текст на абзаци
    return paragraphs.length > 1
      ? paragraphs.map((paragraph, index) => {
          if (!index || index == paragraphs.length - 1) {
            return (
              <span style={{ margin: '0' }} key={index}>
                {paragraph}
              </span>
            );
          }
          return (
            <p style={{ margin: '0' }} key={index}>
              {paragraph}
            </p>
          );
        })
      : paragraphs[0];
  };
  return (
    <>
      {currentTemplate && (
        <div dir="auto">
          {header?.split(/(\[.*?\])/).map((part, index) => {
            return (
              <span key={index} style={{ ...(part.startsWith('[') ? { color: 'blue' } : null), fontWeight: '900' }}>
                {part}
              </span>
            );
          })}
          <Box>
            {body?.split(/(\[.*?\])/).map((part, index) => {
              return (
                <span key={index} style={{ ...(part.startsWith('[') ? { color: 'blue' } : null), fontWeight: '500' }}>
                  {formatWithParagraph(part)}
                </span>
              );
            })}
          </Box>
          <Box>
            {footer?.split(/(\[.*?\])/).map((part, index) => {
              return (
                <span key={index} style={{ ...(part.startsWith('[') ? { color: 'blue' } : null), fontWeight: '500' }}>
                  {part}
                </span>
              );
            })}
          </Box>
        </div>
      )}
    </>
  );
};

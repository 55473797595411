import { Box, useTheme } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { Icon, Row, Text, OutsideClickListener } from 'shared/ui';
import { navLinks } from './constants';
import { navIcons } from './navIcons';
import { useExtendedTranslation } from 'shared/hooks';
import { useState } from 'react';
import NavSubMenuItem from 'shared/ui/NavSubMenuItem';
import React, { useEffect } from 'react';
export const Navigation = () => {
  const [activeMenu, setActiveMenu] = useState<string>('');
  useEffect(() => {
    if (activeMenu) {
      window.addEventListener('keyup', onEscape);
    } else {
      window.removeEventListener('keyup', onEscape);
    }
  }, [activeMenu]);

  const { palette } = useTheme();
  const { t } = useExtendedTranslation();

  const [open, setOpen] = useState<boolean>(false);

  const handleCloseSubMenu = () => {
    setActiveMenu('');
  };
  const onEscape = (e: KeyboardEventInit) => {
    if (e.code === 'Escape') {
      setActiveMenu('');
    }
  };
  const toggleActiveMenu = (title: string) => {
    if (activeMenu === title) {
      setActiveMenu('');
      return;
    }
    setActiveMenu(title);
  };
  const handleClickReload = (path: string) => {
    const currentPath = window.location.pathname;
    if (currentPath === path) {
      window.location.reload();
    }
  };
  return (
    <Row
      sx={{
        alignItems: 'flex-start',
        mt: '-4px',
        '& a': {
          textDecoration: 'none',
          border: `1px solid ${palette.primary.dark}`,
          borderRadius: '40px',
          transition: 'all 0.3s',
        },
        '& a:hover': {
          borderColor: palette.primary.main,
          bgcolor: palette.primary.main,
        },
        '& a.active': { borderColor: palette.info.main },
      }}
    >
      {navLinks.map(({ title, path, links }, i) =>
        !links && path ? (
          <NavLink to={path} key={i} onClick={() => handleClickReload(path)}>
            <Row key={i} sx={{ p: '7px 15px ' }}>
              <Icon key={i} src={navIcons[i]} />
              <Text sx={{ color: 'info.main', ml: '7px', textTransform: 'capitalize' }}>{t(`header_${title}`)}</Text>
            </Row>
          </NavLink>
        ) : (
          <React.Fragment key={i}>
            <Box
              key={i}
              onClick={() => toggleActiveMenu(title)}
              // onMouseEnter={() => setOpen(true)}
              // onMouseLeave={() => setOpen(false)}
              // onClick={() => setOpen(!open)}
              sx={{
                position: 'relative',
                textDecoration: 'none',
                border: `1px solid ${palette.primary.dark}`,
                borderRadius: '40px',
                transition: 'all 0.3s',
                cursor: 'pointer',
                '&:hover': {
                  borderColor: palette.primary.main,
                  bgcolor: palette.primary.main,
                },
              }}
            >
              <Row key={i} sx={{ p: '7px 15px ' }}>
                <Icon key={i} src={navIcons[i]} />
                <Text sx={{ color: 'info.main', ml: '7px', textTransform: 'capitalize', cursor: 'pointer' }}>
                  {t(`header_${title}`)}
                </Text>
              </Row>
              {activeMenu === title && (
                <OutsideClickListener onOutsideClick={handleCloseSubMenu}>
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '0',
                      left: '-1px',
                      zIndex: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      minWidth: '145px',
                      border: '1px solid #ffffff',
                      borderRadius: '20px',
                      background: '#0a3e90',
                    }}
                  >
                    <Row sx={{ p: '7px 15px ' }}>
                      <Icon src={navIcons[i]} />
                      <Text sx={{ color: 'info.main', ml: '7px', textTransform: 'capitalize' }}>
                        {t(`header_${title}`)}
                      </Text>
                    </Row>
                    {links &&
                      links.map(({ title, path }, i) => (
                        <NavSubMenuItem
                          key={`${title}-${i}`}
                          title={t(`${title}`)}
                          path={path}
                          clickReload={handleClickReload}
                        />
                      ))}
                  </Box>
                </OutsideClickListener>
              )}{' '}
            </Box>
          </React.Fragment>
        )
      )}
    </Row>
  );
};

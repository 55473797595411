import { useState } from 'react';
import { Customer } from 'shared/models';

export const useSortCustomers = () => {
  const [sortBy, setSortBy] = useState<keyof Customer>('id');
  const [isIncreaseSort, setIsIncreaseSort] = useState(true);

  const onChangeSort = (sortKey: keyof Customer) => {
    if (sortBy !== sortKey) {
      setIsIncreaseSort(true);
      setSortBy(sortKey);
      return;
    }

    if (sortBy === sortKey) {
      if (isIncreaseSort) {
        setIsIncreaseSort(false);
        return;
      }

      setSortBy('id');
      setIsIncreaseSort(true);
    }
  };

  return {
    sortBy,
    onChangeSort,
    isIncreaseSort,
  };
};

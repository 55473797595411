import { FC, useEffect, useState } from 'react';
import { DateValidationError, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box, Typography, useTheme } from '@mui/material';
import { formatDtoDate } from 'shared/lib';
import { t } from 'i18next';

interface Props {
  label: string;
  name: string;
  value: string | null;
  onChange: (name: string, value: string) => void;
  disabled?: boolean;
  error?: DateValidationError | null;
  setError?: (error: DateValidationError) => void;
  hideError?: boolean;
}

export const DatePicker: FC<Props> = ({ label, name, value, onChange, disabled, error, setError, hideError }) => {
  const { palette } = useTheme();

  const [date, setDate] = useState<Dayjs | null>(dayjs(value));
  const handleChange = (newDate: Dayjs) => {
    setDate(newDate);
    const updatedDate = formatDtoDate(newDate?.toDate().toString() as string);

    onChange(name, updatedDate);
  };

  useEffect(() => setDate(dayjs(value)), [value]);

  return (
    <Box mt={'20px'}>
      {label && (
        <Typography
          noWrap
          sx={{
            fontSize: '14px',
            fontWeight: 600,
            textTransform: 'capitalize',
            color: palette.primary.dark,
            marginBottom: '3px',
          }}
        >
          {label}
        </Typography>
      )}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MuiDatePicker
          disabled={disabled}
          value={date}
          onChange={(newDate) => handleChange(newDate as Dayjs)}
          views={['year', 'day']}
          disableFuture
          format={'DD.MM.YYYY'}
          onError={(newError) => (hideError ? setError?.(newError) : false)}
          slotProps={{
            textField: {
              name,
              helperText: error && t('invalid_date_date_picker'),
              sx: {
                width: '100%',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '12px',
                  height: '49px',
                  '& > fieldset.MuiOutlinedInput-notchedOutline': { border: `1.5px solid rgb(10, 62, 144)`, },
                  '&.Mui-disabled > fieldset': { border: `1.5px solid ${palette.primary.dark}` },
                  '&.Mui-disabled': { bgcolor: 'info.contrastText' },
                  '& > input': {
                    color: date.isValid() ? 'black' : '#d3d3d3'
                  }
                },
                '& .Mui-disabled': {
                  WebkitTextFillColor: 'info.dark',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: palette.primary.dark,
                },
              },
            },
            layout: {
              sx: {
                '& .Mui-selected': {
                  color: 'white !important',
                },
                '& .MuiPickersYear-yearButton.Mui-selected': {
                  color: 'white  !important',
                },
              },
            },
          }}
        />
      </LocalizationProvider>
    </Box>
  );
};

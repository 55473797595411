import { useAppDispatch } from 'shared/hooks';

import { Fota, FotaFile, FotaFilter, FotaSettings, FotaType } from '../../fotaModels';
import useAxios from 'shared/api/useAxios';
import { setAllFota, setFotaTypes, setFotaFiles} from 'features/FotaManager/fotaSlice';
import { t } from 'i18next';

export const useChangeFotaSettings = () => {
  const dispatch = useAppDispatch();
  const { loading, request } = useAxios();
  return {
    changeFotaSettings: (data: FotaSettings) =>
    request<FotaFilter, { data: {updateResult:number} }>({
      method: 'post',
      url: `/fota/updateSettings`,
      showSuccessMessageCode: undefined,
      onDataReceiveCompleted: (data) => {
        return data?.data.updateResult
      },
      payload: data,
    }),

    isLoadingFota: loading,
  };
};

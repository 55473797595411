import {  useState } from 'react';

export const useSearch = () => {
  const [searchValue, setSearchValue] = useState('');
  const changeSearch = (e: string) => setSearchValue(e);

  return {
    searchValue,
    changeSearch,
  };
	
};

import { DashboardDetails, TableDataWithId } from 'shared/models';
import { TableTitle, TranslateColumn } from 'components/BaseTable';
import { IrrigationController } from 'features/WeatherStationsManager/WeatherStationModels';

export const TABLE_TITLES: TableTitle<IrrigationController>[] = [
	{ title: 'object_name', key: 'objectName', width: '100%' },
	{ title: 'zone_name', key: 'zoneName', width: '100%' },
	{ title: 'we_object_name', key: 'wE_ObjectName', width: '100%' },
	// { title: 'status', key: 'status', width: '100%' },
];

// export const TRANSLATE_COLUMNS: TranslateColumn<PlantType>[] = [
// 	{ key: 'plantType', preKey: 'WE_PLANTTYPE_' },
// ]
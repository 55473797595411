import useAxios from 'app/useAxios';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useToast } from 'shared/hooks';
import { setIsAgamTechnician } from '../../userSlice';
interface ServiceUsers {
    userID: number,
    customerID: number,
    isTechnician: number,
    isDispeture: number
}
export const useServiceUsers = () => {
  const dispatch = useAppDispatch();
  const {loading, request } = useAxios();
  const { showError } = useToast();
  const { t } = useTranslation();
  return {
    getServiceUsers: (userID: number) =>
      request<undefined, { data: ServiceUsers, succeeded:boolean }>({
        method: 'get',
        showSuccessMessageCode: undefined,
        url: `/serviceusers?userId=${userID}`,
        onDataReceiveCompleted: (data) => {
          if(data.succeeded){
					dispatch(setIsAgamTechnician(!!data?.data?.isTechnician));
          }else{
            showError(t('error_toast_text'));
          }
        },
      }),
    setTechnician: (serviceUsers: ServiceUsers) =>
      request<undefined, { data: ServiceUsers, succeeded:boolean }>({
        method: 'post',
        showSuccessMessageCode: undefined,
        url: `/serviceusers`,
        onDataReceiveCompleted: (data) => {
          if(!data.succeeded){
            showError(t('error_toast_text'));
          }
        },
        payload: serviceUsers
      }),
      deleteTechnician: (userID: number) =>
      request<undefined, { data: ServiceUsers, succeeded:boolean }>({
        method: 'delete',
        showSuccessMessageCode: undefined,
        url: `/serviceusers`,
        onDataReceiveCompleted: (data) => {
          if(!data.succeeded){
            showError(t('error_toast_text'));
          }
        },
        payload: {userID}
      }),
    isLoadingUsers: loading,
  };
};

import React, { FC, memo, useMemo, useState, useEffect } from 'react';
import { Box, IconButton } from '@mui/material';
import { Cell, MenuList } from 'shared/ui';
import { useAnchorEl, useAppDispatch, useAppSelector } from 'shared/hooks';
import { GridRowParams } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { getFormattedDate } from 'shared/lib';
interface visibleColumns {
  width: number;
}
interface CustomGridRowParams extends GridRowParams {
  visibleColumns: visibleColumns[];
}

export const ReplacedEquipmentReportItem: FC<CustomGridRowParams> = memo((props: CustomGridRowParams) => {
  const { currentController } = useAppSelector((st) => st.controllers);
  const { CustomerID, CustomerName, DateFrom, DateTo } = props.row;
  const { visibleColumns } = props;
  const cellSizes = visibleColumns.map((s) => s.width);
  const { isOpen, anchorEl, onOpenMenu, onCloseMenu } = useAnchorEl();
  const [activeModal, setActiveModal] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { equipmentTypes } = useAppSelector((st) => st.equipmentReplacementReport);
  const handleOpenMenu = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setActiveModal(!activeModal);

    onOpenMenu(e);
  };
  const handleCloseMenu = () => {
    setActiveModal(!activeModal);
    onCloseMenu();
  };
  const [tableTitles, setTableTitles] = useState([]);
  useEffect(() => {
    const tableTitles = []
    equipmentTypes.forEach((item => {
      // eslint-disable-next-line no-prototype-builtins
      if (props.row.hasOwnProperty(item.name)) {
        tableTitles.push(item.name)
      }
    }))
    setTableTitles(tableTitles)
  }, []);


  //const selected = useMemo(() => objectId === filterGraph.deviceId, [filterGraph.deviceId]);

  return (
    <>
      <Box
        id={`object${CustomerID}`}
        sx={{
          '&': {
            bgcolor: 'info.main',
          },
          '&:hover': {
            bgcolor: '#f0f0f0',
          },
        }}
      >
        <>
          <Cell cellSize={cellSizes[0]} sx={{ position: 'relative' }}>
            {getFormattedDate(DateFrom)} <IconButton sx={{ height: '33px', opacity: 0 }}></IconButton>
          </Cell>
          <Cell cellSize={cellSizes[1]}>{getFormattedDate(DateTo)} </Cell>
          <Cell cellSize={cellSizes[2]}>{CustomerID}</Cell>
          <Cell cellSize={cellSizes[3]}>{CustomerName}</Cell>
          {tableTitles.map((item, i) => (
            <Cell key={`${item}-${i}`} cellSize={cellSizes[4 + i]}>{props.row[item]}</Cell>
          ))}

        </>
      </Box>
    </>
  );
});

import { useAppSelector, useExtendedTranslation } from 'shared/hooks';
import { useState } from 'react';
export const useFilterControllers = () => {
  const { t } = useExtendedTranslation();
  const controllersCountItems = [
    {
      value: '10',
      label: '10',
    },
    {
      value: '50',
      label: '50',
    },
    {
      value: '100',
      label: '100',
    },
    {
      value: '200',
      label: '200',
    },
    {
      value: '500',
      label: '500',
    },
    {
      value: '1000',
      label: '1000',
    },
  ];
  const modelFilterItems = [
    {
      value: 'DM',
      label: `${t('DM_model_type')} - ${t('dam_model_description')}`,
    },
    {
      value: 'CR',
      label: `${t('CR_model_type')} - ${t('computer_room_model_description')}`,
    },
    {
      value: 'NV',
      label: `${t('NV_model_type')} - ${t('general_controller_model_description')}`,
    },
    {
      value: 'WE',
      label: `${t('WE_model_type')} - ${t('meteorological_station_model_description')}`,
    },
    {
      value: 'IP',
      label: `${t('IP_model_type')} - ${t('network_protection_model_description')}`,
    },
    {
      value: 'LT',
      label: `${t('LT_model_type')} - ${t('lighting_controller_model_description')}`,
    },
    {
      value: 'IA',
      label: `${t('IA_model_type')} - ${t('fountain_model_description')}`,
    },
    {
      value: 'I1',
      label: `${t('I1_model_type')} - ${t('irrigation_controller_model_description')}`,
    },
    {
      value: 'SG',
      label: `${t('SG_model_type')} - ${t('prismatic_sign_model_description')}`,
    },
  ];
  const { customers } = useAppSelector((st) => st.customers);
  const customerFilterItems = customers.map((s) => ({
    value: s.id.toString(),
    label: s.customerName,
  }));
  return {
    controllersCountItems,
    modelFilterItems,
    customerFilterItems,
  };
};

import { getFormattedDate } from 'shared/lib';
export const CustomizedAxisTick = (props: any) => {
  const { x, y, payload } = props;

  const formText = new Date(payload.value);
  const formattedText = getFormattedDate(formText);
  //const text = new Date(payload.value + 40000000);
  //const formattedText = getFormattedDate(text);
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={-5} y={10} dy={8} textAnchor="middle" fill="#666">
        {formattedText}
      </text>
    </g>
  );
};

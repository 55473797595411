import { CustomerManager } from 'features';
import { Layout } from 'shared/ui';
import { Header } from 'widgets';

export const CustomersPage = () => {
  return (
    <Layout>
      <Header />
      <Layout.Body>
        <CustomerManager />
      </Layout.Body>
    </Layout>
  );
};

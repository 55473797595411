import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { axiosBase } from 'shared/config';
import { useAppDispatch, useToast } from 'shared/hooks';
import { Controller } from 'shared/models';
import { editCurrentController,setCurrentController, toggleEditController } from 'shared/slices';

export const useEditController = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { showError, showSuccess } = useToast();
  const push = useNavigate();

  const [isLoading, setLoading] = useState(false);

  const editController = async (controllerData: Controller) => {
    setLoading(true);
    const controller = {
      objectID: controllerData.deviceId,
      objectName: controllerData.objectName,
      remarks: controllerData.objectNotes,
      streetAddress: controllerData.installAddress,
      installDate: controllerData.installDate,
      latitude_Dec: controllerData.latitudeDec,
      longitude_Dec: controllerData.longitudeDec,
    };
    try {
      const { data } = await axiosBase.put('/device', controller);

      const customer: Controller = data?.data;

      dispatch(editCurrentController(customer));
      dispatch(setCurrentController(customer));
      showSuccess(t('controller_successfully_updated_toast_text'));
    } catch (error: any) {
      console.log('#ERROR', error);
      if (error?.response?.status === 401) {
        push('/login');
      } else {
        showError(t('error_toast_text'));
      }
    } finally {
      setLoading(false);
    }
  };

  return { isLoading, editController };
};

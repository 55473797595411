import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { formatDtoDate } from 'shared/lib';
import { CustomerType, Customer, CustomerStatus, CustomerAgreementCode } from 'shared/models';
import { Fota, FotaFile, FotaType } from './fotaModels';
import { convertMinToTime } from './fotaHelpers';

interface FotaState {
    allFota: Fota[];
    fotaTypes: FotaType[];
    fotaFiles: FotaFile[]
}

const initialState: FotaState = {
    allFota: [],
    fotaTypes: [],
    fotaFiles:[]
};

const fotaSlice = createSlice({
  name: 'fota',
  initialState,
  reducers: {
    setAllFota: (state, action: PayloadAction<Fota[]>) => {
      const fota = action.payload.map(item=>{
        const result=item
        result.downloadTimeFrom=convertMinToTime(item.downloadTimeFrom);
        result.downloadTimeTo=convertMinToTime(item.downloadTimeTo);
        result.restartTimeFrom=convertMinToTime(item.restartTimeFrom);
        result.restartTimeTo=convertMinToTime(item.restartTimeTo);
        result.downloadValue=item.downloadPercent;
        result.downloadPercent=(item.lastRequest === 'DOWNLOAD' || item.lastRequest === 'UPGRADE')
          ? item.downloadPercent : null;
        return result
      });
      
      state.allFota = fota;
    },
    setFotaTypes: (state, action: PayloadAction<FotaType[]>) => {
      state.fotaTypes = action.payload;
    },
    setFotaFiles: (state, action: PayloadAction<FotaFile[]>) => {
      state.fotaFiles = action.payload;
    },
  },
});

export const {
   setAllFota,
   setFotaTypes,
   setFotaFiles
} = fotaSlice.actions;

export const fotaReducer = fotaSlice.reducer;

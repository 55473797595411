import { t } from 'i18next';
import { useState } from 'react';
import { useGetDefaultParams } from './useGetDefaultParams';

export const useFilterPowerProblemsReport = () => {
  const { exceptionTypes } = useGetDefaultParams();
  const [exceptionTypeFilter, setExceptionTypes] = useState(exceptionTypes);
  const exceptionTypeFilterItems = [
    {
      value: 'batT_LOW_D',
      label: `${t('BATT_LOW_D_exception_type_item')}`,
    },
    {
      value: 'chargE_TIME_LOW_D',
      label: `${t('CHARGE_TIME_LOW_D_exception_type_item')}`,
    },
    {
      value: 'viN_LOW_D',
      label: `${t('VIN_LOW_D_exception_type_item')}`,
    },
    {
      value: 'nO_VIN_D',
      label: `${t('NO_VIN_D_exception_type_item')}`,
    },
  ];

  const onChangeExceptionTypeFilter = (values: string[]) => {
    setExceptionTypes(values);
  };

  return {
    exceptionTypeFilterItems,
    exceptionTypeFilter,
    onChangeExceptionTypeFilter,
  };
};

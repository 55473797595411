import { ServiceCall } from 'shared/models';
export const checkServiceCallChanges = (initial: ServiceCall, checking: ServiceCall) => {
  const keys = Object.keys(initial);

  const changes = keys.map(
    (key) => (checking?.[key as keyof ServiceCall] ?? '') !== (initial?.[key as keyof ServiceCall] ?? '')
  );

  const isChanged = changes.includes(true);

  return isChanged;
};

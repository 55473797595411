import { IndexesExcelSimData } from 'shared/models';

interface MappedExcelData {
  simID: string;
  phoneNumber: string;
  statusCode?: string | null;
}
interface SimImport {
  IS_SIM_STATUS_1_VALUES: string[];
  IS_SIM_STATUS_0_VALUES: string[];
}

export interface IError {
  errInfo: {
    row?: string;
    error: string;
  };
}

export const useMapExcelData = (excelData: string[][], indexes: IndexesExcelSimData, SIM_IMPORT: SimImport) => {
  let dataIntegrity = true;
  const errors: IError[] = [];
  const notValidRows: number[] = [];
  const simIndex = indexes.simIndex;

  const getSimStatus = (item: string) => {
    const trueValues = SIM_IMPORT.IS_SIM_STATUS_1_VALUES;
    const falseValues = SIM_IMPORT.IS_SIM_STATUS_0_VALUES;
    const trueValFormatted = trueValues.map((item) => item.toLowerCase().trim());
    const falseValFormatted = falseValues.map((item) => item.toLowerCase().trim());
    const isTrue = trueValFormatted.includes(item.toLowerCase().trim());
    const isFalse = falseValFormatted.includes(item.toLowerCase().trim());
    return isTrue ? 'SIM_STATUS_1' : isFalse ? 'SIM_STATUS_0' : null;
  };

  const isUniqueArrayByKey = (arr: MappedExcelData[], key: keyof MappedExcelData) => {
    const uniqueValues = new Set();
    for (let index = 0; index < arr.length; index++) {
      const obj = arr[index];
      const value = obj[key];
      if (uniqueValues.has(value)) {
        errors.push({ errInfo: { row: `row ${index + 1}:`, error: 'duplicate_value_found_import_sim' } });
        notValidRows.push(index + 1);
        // return false;
      }
      uniqueValues.add(value);
    }

    if (uniqueValues.size < arr.length - 1) {
      return false;
    }
    return true; //
  };

  const mappedExcelData = [];

  for (let i = 1; i < excelData.length; i++) {
    if (!excelData[i][indexes.simIndex]) {
      dataIntegrity = false;
      errors.push({ errInfo: { row: `row ${i}:`, error: 'sim_num_data_integrity_import_sim' } });
      notValidRows.push(indexes.simIndex);
    } else if (!excelData[i][indexes.phoneIndex]) {
      dataIntegrity = false;
      errors.push({ errInfo: { row: `row ${i}:`, error: 'phone_num_data_integrity_import_sim' } });
      notValidRows.push(indexes.phoneIndex);
    } else {
      const statusCode = indexes.statusCode ? excelData[i][indexes.statusCode]?.toString() : null;
      mappedExcelData.push({
        simID: excelData[i][indexes.simIndex].toString(),
        phoneNumber: excelData[i][indexes.phoneIndex].toString(),
        statusCode: statusCode ? getSimStatus(statusCode) : null,
      });
    }
  }

  const uniqueValues = isUniqueArrayByKey(mappedExcelData, 'simID');
  //test
  // mappedExcelData.forEach((item) => {
  //   // console.log(typeof item.simID);
  //   console.log(typeof item.phoneNumber);
  // });
  return { dataIntegrity, mappedExcelData, uniqueValues, errors, simIndex, notValidRows };
};

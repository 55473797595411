import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User, UserAccountsItem, UserHistory, UserStatuses } from './UserModels';

interface UserState {
  users: User[];
  userStatuses: UserStatuses[] | [];
  userProjects: UserAccountsItem[];
  isAgamTechnician: boolean
  userActionHistory: UserHistory[]
}

const initialState: UserState = {
  users: [],
  userStatuses: [],
  userProjects: [],
  isAgamTechnician: false,
  userActionHistory:[]
};

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsers: (state, action: PayloadAction<User[]>) => {
      state.users = action.payload;
    },
    setUserStatuses: (state, action: PayloadAction<UserStatuses[]>) => {
      state.userStatuses = action.payload;
    },
    updateUser: (state, action: PayloadAction<User>) => {
      if (state.users) {
        const index = state.users.findIndex((item) => item.userID === action.payload.userID);
        const result = [...state.users];
        result[index]=action.payload
        state.users = [...result];
      }
    },

    setUserProjects: (state, action: PayloadAction<UserAccountsItem[]>) => {
      state.userProjects = action.payload;
    },
    setIsAgamTechnician:(state, action: PayloadAction<boolean>)=>{
      state.isAgamTechnician = action.payload;
    },
    setUserActionHistory:(state, action: PayloadAction<UserHistory[]>)=>{
      state.userActionHistory=action.payload;
    }
  },
});

export const {
  setUsers,
  updateUser,
  setUserStatuses,
  setUserProjects,
  setIsAgamTechnician,
  setUserActionHistory
} = userSlice.actions;

export const userReducer = userSlice.reducer;

import { createContext } from 'react';

export interface ILoginResponse {
  token: string;
}
interface IAuthState {
  handleSaveToken: (data: ILoginResponse) => void;
  logout: () => void;
}

export const AuthContext = createContext<IAuthState>({
  handleSaveToken: () => {},
  logout: () => {},
});

import { FC } from 'react';
import { Box, IconButton } from '@mui/material';
import { Cell } from 'shared/ui';
import { useAppDispatch, useAppSelector } from 'shared/hooks';

import { toggleNewConnectedController, setCurrentConnectedController, toggleEditConnectedController } from 'shared/slices';
import { GridRowParams } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';

interface visibleColumns {
  width: number;
}
interface CustomGridRowParams extends GridRowParams {
  visibleColumns: visibleColumns[];
}

export const NPConnectedСontrollersItem: FC<CustomGridRowParams> = (props: CustomGridRowParams) => {
  const {
    srcIndex, srcObjectID, srcObjectName, srcModelID, srcHWID, srcVersion, script_Type
  } = props.row;
  const { visibleColumns } = props;
  const cellSizes = visibleColumns.map((s) => s.width);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const addConnectedController = () => {
    dispatch(toggleNewConnectedController());
  };
  const editConnectedController = () => {
    dispatch(setCurrentConnectedController(props.row));
    dispatch(toggleEditConnectedController());
  };
  const getTranslate = (script_Type: string) => {
    return t('st_' + script_Type.trim())
  }
  //const selected = useMemo(() => currentUserProject?.controllerID === controllerID, [userProjects]);

  return (
    <>
      <Box
        id={`NPConnectedСontroller_${srcObjectID}`}
        sx={{
          '&': {
            //    bgcolor: selected ? 'secondary.main' : 'info.main',
          },
          '&:hover': {
            bgcolor: '#f0f0f0',
          },
        }}
      >
        <>
          {srcObjectID ? (
            <Cell onClick={editConnectedController} cellSize={cellSizes[0]}>
              {t('edit_NPConnectedСontroller')}
            </Cell>
          ) : (
            <Cell onClick={addConnectedController} cellSize={cellSizes[0]}>
              {t('add_new_NPConnectedСontroller')}
            </Cell>
          )}
          <Cell cellSize={cellSizes[1]} sx={{ position: 'relative' }}>
            {srcIndex === Infinity ? '' : srcIndex} <IconButton sx={{ height: '33px', opacity: 0 }}></IconButton>
          </Cell>
          <Cell cellSize={cellSizes[2]}>{srcObjectID ? srcObjectID : ''}</Cell>
          <Cell cellSize={cellSizes[3]}>{srcObjectName}</Cell>
          <Cell cellSize={cellSizes[4]}>{srcModelID}</Cell>
          <Cell cellSize={cellSizes[5]}>{srcHWID ? srcHWID : ''}</Cell>
          <Cell cellSize={cellSizes[6]}>{srcVersion ? srcVersion : ''}</Cell>
          <Cell cellSize={cellSizes[7]}>{script_Type ? getTranslate(script_Type) : ''}</Cell>
        </>
      </Box>
    </>
  );
};

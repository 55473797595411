import { useAppDispatch, useToast } from 'shared/hooks';

import useAxios from 'shared/api/useAxios';
import { t } from 'i18next';


export const useDeleteWAMessages = () => {
    
  const { loading, request } = useAxios();
  return {
    deleteWAMessages: (ids: number[]) =>
      request<undefined, { data: any; succeeded: boolean }>({
        method: 'delete',
        showSuccessMessageCode: undefined,
        url: `/WhatsApp/messages`,
        onDataReceiveCompleted: (data) => {
                return data
        },
        payload: {messageIds: ids},
      }),

      deleteInProgress: loading,
  };
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { axiosBase } from 'shared/config';
import { useAppDispatch, useToast } from 'shared/hooks';
import {
	setBatteryStatusExcludeControllers,
	setBatteryStatusExcludeProjects
} from 'shared/slices';

export const useServiceCallExcluding = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { showError } = useToast();
  const push = useNavigate();
  const [isLoading, setLoading] = React.useState(false);


  const getServiceCallExcludeDevice = async () => {
    try {
      const { data } = await axiosBase.get('/maintenance/batteryreport/exclude/device?type=serviceCalls');
      dispatch(setBatteryStatusExcludeControllers(data.data));
    } catch (error: any) {
      console.log('#ERROR', error?.response?.status);
      if (error?.response?.status === 401) {
        push('/login');
      } else {
        showError(t('something_went_wrong_toast_text'));
      }
    }
  };
  const getServiceCallExcludeCustomer = async () => {
    try {
      const { data } = await axiosBase.get('/maintenance/batteryreport/exclude/customer?type=serviceCalls');
      dispatch(setBatteryStatusExcludeProjects(data.data));
    } catch (error: any) {
      console.log('#ERROR', error?.response?.status);
      if (error?.response?.status === 401) {
        push('/login');
      } else {
        showError(t('something_went_wrong_toast_text'));
      }
    }
  };

  const addServiceCallExcludeController = async (id: number, date:string) => {
    setLoading(true);
    try {
      const excludeController = {
        id,
        note: '',
        expirationDate: date,
				type:"serviceCalls"
      };
      const { data } = await axiosBase.post('/maintenance/batteryreport/exclude/device', excludeController);

      //dispatch(setBatteryStatusExclude(data));
    } catch (error: any) {
      console.log('#ERROR', error?.response?.status);
      if (error?.response?.status === 401) {
        push('/login');
      } else {
        showError(t('something_went_wrong_toast_text'));
      }
    }
    setLoading(false);
  };
  const addServiceCallExcludeProject = async (id: number, date) => {
    setLoading(true);
    try {
      const excludeProject = {
        id,
        note: '',
        expirationDate: date,
				type:"serviceCalls"
      };
      await axiosBase.post('/maintenance/batteryreport/exclude/customer', excludeProject);
    } catch (error: any) {
      console.log('#ERROR', error?.response?.status);
      if (error?.response?.status === 401) {
        push('/login');
      } else {
        showError(t('something_went_wrong_toast_text'));
      }
    }
    setLoading(false);
  };
  const deleteExcludedController = async (id: string) => {
    try {
      await axiosBase.delete('/maintenance/batteryreport/exclude/device', { data: { id, type:"serviceCalls" } });
    } catch (error: any) {
      console.log('#ERROR', error?.response?.status);
      if (error?.response?.status === 401) {
        push('/login');
      } else {
        showError(t('something_went_wrong_toast_text'));
      }
    }
  };
  const deleteExcludedProject = async (id: string) => {
    try {
      await axiosBase.delete('/maintenance/batteryreport/exclude/customer', { data: { id, type:"serviceCalls" } });
    } catch (error: any) {
      console.log('#ERROR', error?.response?.status);
      if (error?.response?.status === 401) {
        push('/login');
      } else {
        showError(t('something_went_wrong_toast_text'));
      }
    }
  };


  return {
    getServiceCallExcludeDevice,
    getServiceCallExcludeCustomer,
    deleteExcludedController,
    deleteExcludedProject,
    addServiceCallExcludeController,
    addServiceCallExcludeProject,
    setLoading,

  };
};

import { Box, BoxProps } from '@mui/material';
import { close_blue } from '../../../shared/assets';
import { Icon, Confirm } from '../';
import { FC } from 'react';
import { useAnchorEl } from 'shared/hooks';
import { useExtendedTranslation } from 'shared/hooks';
interface Props {
  onDelete: () => void;
}

export const DeleteWithConfirmation: FC<Props> = ({ onDelete }) => {
  const { t } = useExtendedTranslation();
  const { isOpen, anchorEl, onOpenMenu, onCloseMenu } = useAnchorEl();
  const clearFile = () => {
    onDelete();
  };
  return (
    <Box>
      <Icon
        sx={{
          cursor: 'pointer',
          ml: '0px',
          mr: '0px',
        }}
        src={close_blue}
        onClick={onOpenMenu}
      />
      <Confirm
        titleText={'are_you_sure_to_clear_selection_confirm'}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={onCloseMenu}
        confirm={clearFile}
        buttonTexts={[t('clear_file_edit_edit_modal'), t('cancel_clear_edit_modal')]}
        inverseButtons
      />
    </Box>
  );
};

import {  Row,} from 'shared/ui';
import {InvertColors, Security, EmojiObjects, DeviceHub, DeviceThermostat} from '@mui/icons-material';
import {  TableCell, useTheme  } from '@mui/material';
import { Box } from '@mui/material';
import {colors} from './../constants/index';
import { useTranslation } from 'react-i18next';
import localStorageService from 'shared/localStorage';

const iconMap = {
  I1: InvertColors,
  IP: Security,
  LT: EmojiObjects,
  IA: DeviceHub,
  WE: DeviceThermostat,
};
export const CellWithIcon = ({status, modelId}:{status:number, modelId:string}) => {
  const { palette, direction } = useTheme();
	const { t } = useTranslation();
  const translateModel = ()=>{
   if (modelId==='I1') return 'I1_'
   if (modelId==='IP') return 'IP_'
   return ''
  }
  
  const isRtl = localStorageService.isRTL;
  const currentLang = localStorageService.currentLang;
  const IconComponent = iconMap[modelId];
  return (
    <Box sx={{display:'flex', alignItems: 'center', height: '33px', direction: isRtl ? 'rtl'  : 'ltr', flexDirection: isRtl ?'row-reverse': 'row'}}
    >
      {status ? (
        <>
          {IconComponent && <IconComponent style={{ marginTop: '3px', marginRight: '3px', color: colors[status] }} fontSize='small' />}
          <Box
            sx={{
              fontFamily: currentLang === 'he' ? 'Noto Sans Hebrew' : 'Inter',
              fontSize: '13px',
              fontWeight: 500,
              userSelect: 'none',
              cursor: 'default',
              color:  'black',
              '&:hover': {
                color:  'black',
                textDecoration:  'none',
              },
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              lineHeight:'30px'
            }}
          >
            {t(`${translateModel()}real_time_status_${status < 8 ? status : ''}`)}
            </Box>
        </>
      ) : (
        <>
          {IconComponent && <IconComponent style={{ marginBottom: '3px', marginRight: '3px', color: colors[1] }} fontSize='small' />}
          <Box
            style={{}}
            sx={{
              fontFamily: currentLang === 'he' ? 'Noto Sans Hebrew' : 'Inter',
              width:'100%',
              fontSize: '13px',
              fontWeight: 500,
              userSelect: 'none',
              cursor: 'default',
              color:  'black',
              '&:hover': {
                color:  'black',
                textDecoration:  'none',
              },
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          > {t(`${translateModel()}real_time_status_2`)}</Box>
        </>
      )}
    </Box>
  );
};

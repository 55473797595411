import { Box } from '@mui/material';
import { forwardRef } from 'react';
import { getPrintColumns, getSortedCustomersByKey } from 'shared/lib';
import { Customer } from 'shared/models';

export const PrintTable = forwardRef<HTMLDivElement, { customers: Customer[] }>(({ customers }, ref) => {
  const sortedByName = getSortedCustomersByKey(customers, 'customerName');
  const sortedById = getSortedCustomersByKey(customers, 'id');

  const pagesById = getPrintColumns(sortedById);
  const pagesByName = getPrintColumns(sortedByName);

  const print = [pagesById, pagesByName];

  return (
    <Box ref={ref} width="97%">
      {print.map((p, i) => (
        <div style={{ pageBreakAfter: 'always' }} key={i}>
          {p.map((page, i) => (
            <Box
              key={i}
              display="flex"
              justifyContent="flex-start"
              width="100%"
              gap={'16px'}
              padding="16px"
              pb={0}
              height="1120px"
            >
              {page.map((col, i) => (
                <Box key={i} width="25%">
                  <table style={{ borderCollapse: 'collapse', width: '100%', maxWidth: '100%' }}>
                    <thead>
                      <tr>
                        <th style={{ background: '#7F7F7F', border: '1px solid black', fontSize: '9px' }}>ID</th>
                        <th style={{ background: '#7F7F7F', border: '1px solid black', fontSize: '9px' }}>Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {col.map((item) => (
                        <tr key={item.id}>
                          <td style={{ textAlign: 'center', border: '1px solid black', fontSize: '7.5px' }}>
                            {item.id}
                          </td>
                          <td
                            style={{
                              textAlign: 'right',
                              border: '1px solid black',
                              fontSize: '7.5px',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                            }}
                          >
                            {item.customerName}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Box>
              ))}
            </Box>
          ))}
        </div>
      ))}
    </Box>
  );
});

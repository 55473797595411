import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Dashboard } from 'shared/models';

interface dashboardState {
  dashboard: Dashboard

}
const initialState: dashboardState  = {
  dashboard: null,
};

const dashboardSlice = createSlice({
  name: 'dashboardSlice',
  initialState,
  reducers: {
    setDashboard: (state, action: PayloadAction<Dashboard>) => {
      state.dashboard = action.payload;
    },
  },
});

export const {
  setDashboard,
} = dashboardSlice.actions;

export const dashboardReducer = dashboardSlice.reducer;

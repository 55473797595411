import { ICItem} from 'shared/models';
import { getFormattedDate } from 'shared/lib';
import { useTranslation } from 'react-i18next';
export const useFormatXlsxData = () => {
  const { t } = useTranslation();
  
  const formatTable = (arr: ICItem[]) => {
    const exportArr = arr.map((item) => {
      return {
        [t('ic_project_ID_network_protection_grid_table')]: item.icProjectID,
        [t('ic_project_name_network_protection_grid_table')]: item.icProjectName,
        [t('dst_object_ID_network_protection_grid_table')]: item.dstObjectID,
        [t('dst_object_name_network_protection_grid_table')]: item.dstObjectName,
        [t('src_object_ID_network_protection_grid_table')]:item.srcObjectID,
        [t('src_object_name_network_protection_grid_table')]: item.srcObjectName,
        [t('com_status_network_protection_grid_table')]: t(`ic_project_status_${item.comStatus}`),
        [t('src_dest_count_network_protection_grid_table')]: item.srcDestCount,
      };
    });

    return exportArr;
  };

  return { formatTable };
};

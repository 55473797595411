import { t } from 'i18next';
import { BigTitle, Layout } from '../../shared/ui';
import { Header,} from '../../widgets';
import { UnderConstructionBlock } from 'entities_';
export const UnderConstruction = () => {
  return (
    <Layout>
      <Header />
      <Layout.Body>
			<BigTitle sx={{ mr: '25px' }}>{t('dashboard_title')}</BigTitle>
			<UnderConstructionBlock/>	
      </Layout.Body>
    </Layout>
  );
};

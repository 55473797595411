import { FC } from 'react';
import { Controller } from 'shared/models';

interface CustomController extends Controller {
  options: string | null;
  checkboxes: string | null;
  alarmStatus?: string;
  batteryVoltage?: number;
  controllerTime?: string;
  dataSource?: number;
  syncDate?: string;
  currentFlow?: string;
  totalSlavesFlow?: string;
  rainRate?: string;
  status?: string;
}
export type TableTitle = { title: string; key: keyof CustomController; valueGetter?: FC; width: number | string };

const stateValueGetter = (item) => {
  return item.row?.state?.calculated?.state
}
export const alarmStatusValueGetter = (item) => {
  return item.row?.state?.alarmStatus
}
export const batteryVoltageValueGetter = (item) => {
  return item.row?.state?.batteryVoltage
}

export const controllerTimeValueGetter = (item) => {
  return item.row?.state?.controllerTime
}

export const dataSourceValueGetter = (item) => {
  return item.row?.state?.dataSource
}

export const syncDateValueGetter = (item) => {
  return item.row?.state?.syncDate
}

export const currentFlowValueGetter = (item) => {
  return item.row?.state?.currentFlow
}

export const totalSlavesValueGetter = (item) => {
  return item.row?.state?.totalSlavesFlow
}
export const rainRateValueGetter = (item) => {
  return item.row?.state?.rainRate
}
export const TABLE_TITLES: TableTitle[] = [
  { title: 'ID', key: 'deviceId', width: 80 },
  { title: 'Status', key: 'state' as keyof Controller, width: '150%', valueGetter: stateValueGetter },
  { title: 'Controller_Name', key: 'objectName', width: '300%' },
  { title: 'Customer_ID', key: 'customerId', width: '100%' },
  { title: 'Customer_Name', key: 'customerName', width: '100%' },
  { title: 'Hardware_ID', key: 'hwId', width: '100%' },
  { title: 'Model', key: 'modelId', width: '100%' },
];

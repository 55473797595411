import { useContext } from 'react';
import { ToastContext } from 'shared/contexts';

export const useToast = () => {
  const { showSuccess, showError } = useContext(ToastContext);

  return {
    showSuccess,
    showError,
  };
};

import { Box, IconButton, Stack } from '@mui/material';
import { BaseSelect } from 'features/BaseSelect';

import { useMessagesTemplates } from 'features/MessagesTemplatesManager/hooks/api/useMessagesTemplates';
import { t } from 'i18next';
import { useEffect, useRef, useState } from 'react';
import { close_popup } from 'shared/assets';
import { useAnchorEl, useToast } from 'shared/hooks';
import { replaceSpacesWithUnderscores } from 'shared/lib';
import localStorageService from 'shared/localStorage';
import { BaseBtn, BaseInput, BigTitle, Confirm, Modal, Row, WaMessageBackground } from 'shared/ui';

interface Props {
  openCustomerAddModal: boolean;
  onCloseModal: () => void;
  updateMessageTemplates: () => void;
}
const messageLanguages = [
  {
    value: 'en',
    label: 'en',
  },
  {
    value: 'he',
    label: 'he',
  },
];
const messageCategories = [
  {
    value: 'MARKETING',
    label: 'MARKETING',
  },
  {
    value: 'UTILITY',
    label: 'UTILITY',
  },
];

export const CreateMessageTemplate = ({ openCustomerAddModal, onCloseModal, updateMessageTemplates }: Props) => {
  const { isOpen, anchorEl, onOpenMenu, onCloseMenu } = useAnchorEl();
  const [isChanged, setIsChanged] = useState(false);

  const { createMessageTemplate, isLoading } = useMessagesTemplates();
  const { showError, showSuccess } = useToast();
  const handleCloseAddModal = (e: React.MouseEvent<HTMLElement>) => {
    isChanged ? onOpenMenu(e) : closeModalAndClearData();
  };
  const currentLang = localStorageService.currentLang;
  const closeModalAndClearData = () => {
    onCloseModal();
    setMessageTemplate('');
    setMessageTemplateName('');
    setSelectedCategory('UTILITY');
    setSelectedMessageLanguage(currentLang);
    setParamsExamples({});
    setNewFieldsForParams([]);
    setParamError([]);
    setTextMustBeLongerError(false);
    setNameError(null);
    setIsChanged(false);
  };
  const [messageTemplate, setMessageTemplate] = useState('');
  const [messageTemplateName, setMessageTemplateName] = useState('');
  const [selectedMessageLanguage, setSelectedMessageLanguage] = useState(currentLang);
  const [selectedCategory, setSelectedCategory] = useState('UTILITY');
  const [textMustBeLongerError, setTextMustBeLongerError] = useState(false);
  const [nameError, setNameError] = useState(null);
  const [paramError, setParamError] = useState([]);
  const [newFieldsForParams, setNewFieldsForParams] = useState<string[]>([]);
  const [paramsExamples, setParamsExamples] = useState<{ [key: string]: string }>({});
  const [showParamsFields, setShowParamsFields] = useState(false);

  const onChangeMessageTemplate = (value: string) => {
    setIsChanged(true);
    setMessageTemplate(value);
  };
  const onChangeMessageTemplateName = (value: string) => {
    setIsChanged(true);
    setNameError(null);
    setMessageTemplateName(value);
  };
  const onChangeMessageLanguage = (value: string) => {
    setIsChanged(true);
    setSelectedMessageLanguage(value);
  };
  const onChangeMessageCategory = (value: string) => {
    setIsChanged(true);
    setSelectedCategory(value);
  };
  const isValid = () => {
    if (newFieldsForParams && newFieldsForParams.length) {
      const isVallidallValues = Object.values(newFieldsForParams).every((value) => {
        return !!paramsExamples[value];
      });
      if (!isVallidallValues) return true;
    }
    if (!messageTemplateName || !messageTemplate || !selectedMessageLanguage || !selectedCategory) {
      return true;
    }
  };

  const formatMessageTemplateExamples = (paramsExamples: { [key: string]: string }) => {
    let keys = Object.keys(paramsExamples).sort();

    keys = keys.filter((key) => {
      return newFieldsForParams.includes(key);
    });
    return keys.map((key) => paramsExamples[key]);
  };
  const checkForValid = () => {
    if (checkTextLength(messageTemplate)) return false;
    if (!messageTemplateName) {
      setNameError('name_cannot_be_empty_edit_modal');
      return false;
    }

    if (newFieldsForParams && newFieldsForParams.length) {
      const paramError = [];
      let hasError = false;
      newFieldsForParams.forEach((item, index) => {
        if (paramsExamples[item]) {
          paramError[item] = true;
        } else {
          paramError[item] = false;
          hasError = true;
        }
      });
      setParamError(paramError);

      if (hasError) return false;
    }

    return true;
  };
  const handleSaveMessageTemplate = async () => {
    const isValid = checkForValid();
    if (!isValid) return;
    const messageTemplateParams = {
      messageTemplateName: messageTemplateName,
      messageTemplateText: messageTemplate,
      messageTemplateLanguage: selectedMessageLanguage,
      messageTemplateCategory: selectedCategory,
      messageTemplateExamples: formatMessageTemplateExamples(paramsExamples),
    };
    const result = await createMessageTemplate(messageTemplateParams);
    if (result.data.succeeded) {
      closeModalAndClearData();
      showSuccess(t('message_template_create_successful'));
      updateMessageTemplates();
      return;
    }
    const sendResult = JSON.parse(result.data.sendResult);
    if (sendResult?.error?.error_user_title === 'Name Format is Incorrect') {
      setNameError(sendResult?.error?.error_user_msg);
      return;
    }
    showError(sendResult?.error?.error_user_msg);
  };
  const boxRef = useRef<HTMLDivElement>();

  const getParamsForMessage = (text: string) => {
    const values = text.match(/\{\{([^{}]*)\}\}/g);
    if (values) {
      return values ? Array.from(new Set(values.map((match) => match.replace(/\{\{|\}\}/g, '')))) : [];
    } else {
      return [];
    }
  };

  const getCountOfWords = (text: string) => {
    const cleanedText = text.replace(/\{\{[^{}]*\}\}/g, '');
    const words = cleanedText.split(/\s+/).filter((word) => word.trim() !== '');
    return words.length;
  };

  const checkTextAndParams = (countOfParams, countOfWords) => {
    if (!countOfParams) return true;
    const minWordsRequired = 4 * countOfParams;
    return countOfWords >= minWordsRequired;
  };

  const checkTextLength = (value: string) => {
    const params = getParamsForMessage(value);
    const countOfWords = getCountOfWords(value);
    const isTextLong = checkTextAndParams(params.length, countOfWords);
    setShowParamsFields(true);
    if (!value || !isTextLong) {
      setTextMustBeLongerError(true);
      return true;
    }
    setTextMustBeLongerError(false);
    setNewFieldsForParams(params);

    return false;
  };
  const checkForNewFields = (value: string) => {
    const params = getParamsForMessage(value);
    setNewFieldsForParams(params);

    setShowParamsFields(true);
  };
  const onChangeParamsMessage = (item: string, value: string) => {
    setParamsExamples((prevState) => ({
      ...prevState,
      [item]: value,
    }));
    if (value) {
      setParamError((prevState) => ({
        ...prevState,
        [item]: value,
      }));
    }
  };
  const hasErrors = () => {
    return textMustBeLongerError || !!nameError;
  };
  const closeButtonRef = useRef<HTMLButtonElement | null>(null);
  const handleModalClose = () => {
    if (closeButtonRef.current) {
      closeButtonRef.current.click();
    }
  };
  return (
    <Modal onClose={handleModalClose} PaperProps={{ sx: { maxWidth: '562px' } }} open={openCustomerAddModal}>
      <Box
        sx={{
          p: '41px 36px 36px 36px',
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
        }}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <BigTitle>{t('create_message_template_title')}</BigTitle>

          <IconButton ref={closeButtonRef} onClick={handleCloseAddModal}>
            <img src={close_popup} />
          </IconButton>
        </Stack>

        <BaseInput
          label={`${t('message_template_name_edit_modal')}`}
          placeholder={`${t('message_template_name_placeholder_edit_modal')}`}
          value={messageTemplateName}
          error={!!nameError}
          helperText={nameError ? replaceSpacesWithUnderscores(nameError) : ''}
          onChange={(event) => onChangeMessageTemplateName(event.target.value)}
          small
          compact
        />
        <BaseSelect
          title={`${t('select_category_message_label_edit_modal')}`}
          value={selectedCategory}
          onChange={onChangeMessageCategory}
          items={messageCategories}
          hideAllButton
          noSelectLabel={`${t('select_category_no_select_placeholder_edit_modal')}`}
          hideValue
          formInput
        />
        <WaMessageBackground>
          <BaseInput
            value={messageTemplate}
            onChange={(event) => {
              onChangeMessageTemplate(event.target.value);
              setTextMustBeLongerError(false);
              checkForNewFields(event.target.value);
            }}
            error={!!textMustBeLongerError}
            textColor={'white'}
            helperText={textMustBeLongerError ? 'text_must_be_longer' : ''}
            big
            sx={{ border: 'none' }}
            view
          />
        </WaMessageBackground>
        <BaseSelect
          title={`${t('select_lang_message_label_edit_modal')}`}
          value={selectedMessageLanguage}
          onChange={onChangeMessageLanguage}
          items={messageLanguages}
          hideAllButton
          noSelectLabel={`${t('select_lang_message_no_select_placeholder_edit_modal')}`}
          hideValue
          formInput
          withoutMargin
        />
        <Box sx={{ minHeight: '140px' }}>
          {showParamsFields && (
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                alignContent: 'flex-start',
              }}
            >
              {newFieldsForParams.map(
                (item) =>
                  item && (
                    <BaseInput
                      key={item}
                      label={`${t(`${item}`)}`}
                      name={item}
                      onChange={(event) => onChangeParamsMessage(item, event.target.value)}
                      value={paramsExamples[item]}
                      small
                      compact
                      error={paramError.length && !paramError[item]}
                      helperText={paramError.length && !paramError[item] ? 'please_enter_an_example_parameter' : ''}
                      sx={{ width: '240px', height: '40px' }}
                    />
                  )
              )}
            </Box>
          )}
        </Box>
        <Row justifyContent="flex-end" mt={'35px'}>
          <Row width={300}>
            <BaseBtn onClick={handleCloseAddModal} btnColor="info.main" fullWidth>
              {t('cancel_add_modal')}
            </BaseBtn>
            <Box ref={boxRef} sx={{ height: '40px', width: '25px' }} />
            <BaseBtn
              onClick={handleSaveMessageTemplate}
              type="submit"
              loading={isLoading}
              disabled={hasErrors()}
              btnColor="primary.dark"
              fullWidth
            >
              {t('save_add_modal')}
            </BaseBtn>
          </Row>
        </Row>
        <Confirm
          open={isOpen}
          anchorEl={anchorEl}
          onClose={onCloseMenu}
          confirm={closeModalAndClearData}
          buttonTexts={[t('cancel_add_add_modal'), t('back_to_add_add_modal')]}
        />
      </Box>
    </Modal>
  );
};

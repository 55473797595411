import { Box, IconButton, Select as MuiSelect, SelectProps, Typography, useTheme } from '@mui/material';
import { Text } from 'shared/ui';
import { forwardRef, useState } from 'react';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

type Props = SelectProps & {
  label?: string;
  isResultBox?: boolean;
  isErrorBox?: boolean;
  small?: boolean;
  disabled?: boolean;
  marginTop?: string;
  onResetClick?: () => void;
  marginBottom?: string;
};

export const Select = forwardRef<unknown, Props>(({ label, sx, small, disabled, marginTop, marginBottom, ...props }, ref) => {
  const { palette } = useTheme();

  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    if (!disabled) {
      setOpen((prev) => !prev);
    }
  };

  return (
    <Box mt={marginTop ? marginTop : '20px'}>
      {label && (
        <Typography
          noWrap
          sx={{
            fontSize: '14px',
            fontWeight: 600,
            textTransform: 'capitalize',
            color: palette.primary.dark,
            marginBottom: marginBottom ? marginBottom : '3px',
          }}
        >
          {label}
        </Typography>
      )}
      <MuiSelect
        open={open}
        onClose={handleToggle}
        onOpen={handleToggle}
        fullWidth
        sx={{
          borderRadius: '12px',
          height: small ? '30px' : '49px',
          border: `1.5px solid ${palette.primary.dark}`,
          bgcolor: disabled ? 'info.contrastText' : 'info.main',
          '& > fieldset': {
            border: 'none',
          },
          '& .MuiOutlinedInput-root': {
            pr: '5px',
            border: `3.5px solid ${palette.primary.dark}`,
            '& > fieldset': { border: `1.5px solid ${palette.primary.dark}` },

            '&': { bgcolor: 'info.contrastText' },
          },

          ...sx,
        }}
        inputRef={ref}
        color="primary"
        {...props}
        MenuProps={{
          PaperProps: {
            sx: {
              maxHeight: '45%',
              mt: '10px',
              borderRadius: '12px',
              px: '8px',
              '& .MuiMenuItem-root': {
                borderRadius: '8px',
              },
              '& .Mui-selected, .MuiMenuItem-root:hover': {
                bgcolor: 'primary.light',
              },
            },
          },
        }}
        IconComponent={() => (
          <IconButton onClick={handleToggle} sx={{ mr: '5px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>

              <PlayArrowIcon
                sx={{
                  fontSize: '15px',
                  transform: `rotate(${open ? '-90' : '90'}deg)`,
                }}
              />
            </Box>
          </IconButton>
        )}
      >
        {props.children}
      </MuiSelect>
    </Box>
  );
});

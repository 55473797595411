import { Box } from '@mui/material';
import { ValvesReportView } from 'features/ControllerManager/controllerModels';
import { t } from 'i18next';
import { FC, useEffect, useRef, useState } from 'react';
import localStorageService from 'shared/localStorage';
import uPlot, { AlignedData, Series } from 'uplot';
import UplotReact from 'uplot-react';
import 'uplot/dist/uPlot.min.css';
import { usePlugins } from './../hooks/usePlugins';
import timelinePlugin from './../plugins/timePlugin';
import { CursorMemo, ValueInfo, tooltipsPlugin } from './../plugins/tooltipsPlugin';
import {
  COUNT_OF_Y_LINES,
  FLOW_RATE_FILL_COLOR,
  FLOW_RATE_STROKE_COLOR,
  GENERAL_FAULT,
  LINE_COLOR,
  SYSTEM_FAULT,
  WEEK_DAY_NAMES_ENG,
  WEEK_DAY_NAMES_HE,
  lOST_SIGNAL_LINE_COLOR,
} from './Constants';
import {
  handleCommunicationFault,
  handleCommunicationRestored,
  handleFlowRate,
  handleGeneralFault,
  handleGeneralFaultEnd,
  handleHighFlowRateEvent,
  handleHighFlowRateEventEnd,
  handleLowFlowRateEvent,
  handleLowFlowRateEventEnd,
  handleManualValveOpeningEvent,
  handleManualValveOpeningEventEnd,
  handleMissingPulsesEvent,
  handleMissingPulsesEventEnd,
  handleUserActionsEvent,
  handleValveCloseEvent,
  handleValveOpenEvent,
  handleWaterLeakage,
  handleWaterLeakageEnd,
} from './DrawEventsHelper';

interface Props {
  allData: ValvesReportView[];
  height?: number;
  showeventIDs?: number[];
  startDate: string;
  endDate: string;
  popupPadding?: number;
  isChangedZoom: (value: boolean) => void;
  isResetZoom: boolean;
  deviceType: string;
  userActionsEventIDs: number[];
}

export const TimeLineChart: FC<Props> = ({
  allData,
  height,
  startDate,
  endDate,
  popupPadding,
  isChangedZoom,
  isResetZoom,
  deviceType,
  userActionsEventIDs,
}) => {
  const { isRTL } = localStorageService;

  const [defaultScaleXParams, setDefaultScaleXParams] = useState({ min: 0, max: 0 });
  //chart.setScale('x', { min, max });

  useEffect(() => {
    const date1 = new Date(startDate + 'T00:00:00');
    const date2 = new Date(endDate + 'T00:00:00');

    // add 1 day
    date2.setDate(date2.getDate() + 1);
    date2.setHours(0, 0, 0, 0);
    const min = Math.floor(date1.getTime() / 1000);
    const max = Math.floor(date2.getTime() / 1000);

    setDefaultScaleXParams({ min, max });
    if (chartRef.current) {
      const chart = chartRef.current;
      chart.setScale('x', { min, max });
    }
  }, [startDate, endDate]);

  const { chartSize, getSize, setChartSize } = usePlugins();
  useEffect(() => {
    function handleResize() {
      setTimeout(() => {
        setChartSize(getSize(popupPadding, height));
      }, 0);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const { stepped } = uPlot.paths;
  const paths = (u, seriesIdx, idx0, idx1) => {
    const _stepAfter = stepped({ align: 1 }); //, ascDesc: true
    const s = u.series[seriesIdx];
    const interp = s.lineInterpolation;

    const renderer = interp == 2 ? _stepAfter : null;
    if (!renderer) return u;
    return renderer(u, seriesIdx, idx0, idx1);
  };

  const updateDateFormat = (u: uPlot) => {
    const min = Math.floor(u.scales.x.min);
    const max = Math.floor(u.scales.x.max);

    chartLayer2Ref.current.setScale('x', { min, max });
    chartRef.current.setScale('x', { min, max });

    if (chartRef.current) {
      isChangedZoom(!(Math.floor(min) === defaultScaleXParams.min && Math.floor(max) === defaultScaleXParams.max));
      chartLayer2Ref.current.setScale('x', { min, max });
    }
  };
  interface UserActionsData {
    commandDescription: string;
    userName: string;
  }
  const formatValvesInfo = (graphData: any) => {
    const valves = graphData.slice(COUNT_OF_Y_LINES - 15, COUNT_OF_Y_LINES + 1).reverse();
    //console.log(graphData[5]);
    //const userActions = graphData[5];
    const userActionsData = graphData[4];
    const userActions = graphData[5];
    const timeStamps = graphData[0];
    const flowData = graphData[1];
    const generalFault = graphData[7];
    const waterLeakage = graphData[6];
    const result = [];
    valves.push(userActions);
    valves.push(generalFault);
    valves.push(waterLeakage);
    const highFlow = [];
    const lowFlow = [];
    const missingPulses = [];
    const manualValve = [];
    const missingConnect = [];

    valves.forEach((item, index) => {
      const valueInfo: ValueInfo[] = [];

      item.forEach((event, valveIndex) => {
        // if (index + 1 === 9 && event) {
        //   console.log(event);
        // }
        if (event) {
          // console.log(event);
        }
        switch (event) {
          case 'start':
            valueInfo.push({ startTime: timeStamps[valveIndex], endTime: null });
            break;
          case 'end':
            if (valueInfo.length) {
              valueInfo[valueInfo.length - 1].endTime = timeStamps[valveIndex];
            } else {
              valueInfo.push({ startTime: null, endTime: timeStamps[valveIndex] });
            }
            break;
          case 'generalFault':
            valueInfo.push({ startTime: timeStamps[valveIndex], endTime: null });
            break;

          case 'generalFaultEnd':
            if (valueInfo.length) {
              valueInfo[valueInfo.length - 1].endTime = timeStamps[valveIndex];
            } else {
              valueInfo.push({ startTime: null, endTime: timeStamps[valveIndex] });
            }
            break;
          case 'waterLeakage':
            valueInfo.push({ startTime: timeStamps[valveIndex], endTime: null });
            break;

          case 'waterLeakageEnd':
            if (valueInfo.length) {
              valueInfo[valueInfo.length - 1].endTime = timeStamps[valveIndex];
            } else {
              valueInfo.push({ startTime: null, endTime: timeStamps[valveIndex] });
            }
            break;

          case 'highFlowRate':
            highFlow.push({ startTime: timeStamps[valveIndex], endTime: null, valve: index + 1 });
            break;
          case 'highFlowRateEnd':
            if (highFlow.length && highFlow[highFlow.length - 1]?.valve === index + 1) {
              highFlow[highFlow.length - 1].endTime = timeStamps[valveIndex];
            } else {
              highFlow.push({ startTime: null, endTime: timeStamps[valveIndex], valve: index + 1 });
            }
            break;

          case 'lowFlowRate':
            lowFlow.push({ startTime: timeStamps[valveIndex], endTime: null, valve: index + 1 });
            break;
          case 'lowFlowRateEnd':
            if (lowFlow.length && lowFlow[lowFlow.length - 1]?.valve === index + 1) {
              lowFlow[lowFlow.length - 1].endTime = timeStamps[valveIndex];
            } else {
              lowFlow.push({ startTime: null, endTime: timeStamps[valveIndex], valve: index + 1 });
            }
            break;

          case 'missingPulses':
            missingPulses.push({ startTime: timeStamps[valveIndex], endTime: null, valve: index + 1 });
            break;
          case 'missingPulsesEnd':
            if (
              missingPulses.length &&
              missingPulses[missingPulses.length - 1] &&
              missingPulses[missingPulses.length - 1].valve === index + 1
            ) {
              missingPulses[missingPulses.length - 1].endTime = timeStamps[valveIndex];
            } else {
              missingPulses.push({ startTime: null, endTime: timeStamps[valveIndex], valve: index + 1 });
            }
            break;

          case 'manualValveOpening':
            manualValve.push({ manualValve: timeStamps[valveIndex], manualValveEnd: null, valve: index });
            break;
          case 'manualValveClose':
            if (
              manualValve.length &&
              manualValve[missingPulses.length - 1] &&
              manualValve[missingPulses.length - 1].valve === index
            ) {
              manualValve[manualValve.length - 1].manualValveEnd = timeStamps[valveIndex];
            } else {
              manualValve.push({ manualValve: null, manualValveEnd: timeStamps[valveIndex], valve: index });
            }
            break;

          case 'communication fault':
            if (!missingConnect.length || missingConnect[missingConnect.length - 1]?.endTime)
              missingConnect.push({ startTime: timeStamps[valveIndex], endTime: null, valve: index });
            break;
          case 'communication restored':
            if (missingConnect.length) {
              missingConnect[missingConnect.length - 1].endTime = timeStamps[valveIndex];
            } else {
              missingConnect.push({ startTime: null, endTime: timeStamps[valveIndex], valve: index });
            }
            break;
          case 'userActionsEvent':
            console.log(userActionsData[valveIndex]);
            if (userActionsData[valveIndex]) {
              valueInfo.push({
                startTime: timeStamps[valveIndex],
                endTime: timeStamps[valveIndex] + 10000,
                commandDescription: userActionsData[valveIndex].commandDescription,
                userName: userActionsData[valveIndex].userName,
              });
            }
            break;
          default:
            break;
        }
      });

      result.push(valueInfo);
    });
    //result[17] = userActions;
    console.log('R', result);
    result[18] = highFlow;
    result[19] = lowFlow;
    result[20] = missingPulses;
    result[21] = manualValve;
    result[22] = missingConnect;
    result.push(timeStamps);
    result.push(flowData);

    return result;
  };

  const modifyFlowRate = (graphData) => {
    if (deviceType !== 'AFIK' && deviceType !== 'OAZIS') {
      return graphData;
    }
    const flowRate = [...graphData[1]];
    const openValueOrFault = [];
    for (let item = 0; item < graphData[1].length; item++) {
      let result = false;
      for (let i = 7; i < 24; i++) {
        if (graphData[i][item]) {
          result = true;
        }
      }
      openValueOrFault[item] = result;
    }

    graphData[1] = flowRate.map((item, index) => {
      return openValueOrFault[index] ? item : 0;
    });
    return graphData;
  };

  const formatData = (appData) => {
    function convertData(data) {
      const valves = Array.from({ length: COUNT_OF_Y_LINES }, () => []);
      const timestamps = [];
      const isValveOpen: boolean[] = [];
      const previousValue = Array.from({ length: COUNT_OF_Y_LINES }, () => null);

      timestamps.push(new Date(startDate + 'T00:00:00').getTime() / 1000);
      const closedValueBeforeTimeInterval = [];
      const openedValueBeforeTimeInterval = [];

      data.forEach((event, index) => {
        if (event.eventID >= 717 && event.eventID <= 732) {
          // Відкриття клапана

          if (!openedValueBeforeTimeInterval.includes(event.eventID)) {
            openedValueBeforeTimeInterval.push(event.eventID);
          }
        } else if (event.eventID >= 817 && event.eventID <= 832) {
          // Закриття клапана
          if (!openedValueBeforeTimeInterval.includes(event.eventID - 100)) {
            closedValueBeforeTimeInterval.push(event.eventID);
          }
        }
      });

      const generalFaultIndex = data.findIndex((item) => item.eventID === 715);
      const generalFaultEndIndex = data.findIndex((item) => item.eventID === 815);

      const waterLeakageIndex = data.findIndex((item) => item.eventID === 706);
      const waterLeakageEndIndex = data.findIndex((item) => item.eventID === 806);

      for (let index = 0; index < COUNT_OF_Y_LINES; index++) {
        if (index === 17 && waterLeakageIndex > waterLeakageEndIndex) {
          valves[17].push('waterLeakageContinue');
          previousValue[17] = 'waterLeakageContinue';
        } else if (index === 16 && generalFaultIndex > generalFaultEndIndex) {
          valves[16].push('generalFaultContinue');
          previousValue[16] = 'generalFaultContinue';
        } else if (index === COUNT_OF_Y_LINES - 1) {
          valves[index].push(0);
          previousValue[index] = 0;
        } else if (closedValueBeforeTimeInterval.includes(index + 817)) {
          valves[index].push(' '); //+
          previousValue[index] = ' ';
        } else {
          valves[index].push(null);
          previousValue[index] = null;
        }
      }

      data.forEach((item, index) => {
        const { eventID, eventDate, dataValue } = item;
        const timestamp = new Date(eventDate).getTime() / 1000;

        if (userActionsEventIDs.includes(eventID)) {
          const valveIndex = 17;
          const userActionsData = {
            commandDescription: item.userName,
            userName: item.eventDescription_tmp,
          };
          handleUserActionsEvent(valves, previousValue, valveIndex, timestamp, timestamps, userActionsData);
        } else {
          switch (eventID) {
            case eventID >= 717 && eventID <= 732 && eventID: {
              const valveIndex = eventID - 716;
              handleValveOpenEvent(valves, previousValue, valveIndex, timestamp, timestamps);
              break;
            }

            case eventID >= 817 && eventID <= 832 && eventID: {
              const valveIndex = eventID - 816;
              handleValveCloseEvent(valves, previousValue, valveIndex, timestamp, timestamps);
              break;
            }

            case 4:
              handleCommunicationFault(valves, previousValue, null, timestamp, timestamps);
              break;

            case 5:
              handleCommunicationRestored(valves, previousValue, null, timestamp, timestamps);
              break;

            case 1703:
              handleFlowRate(valves, previousValue, null, timestamp, timestamps, dataValue);
              break;

            case 715:
              handleGeneralFault(valves, previousValue, null, timestamp, timestamps);
              break;

            case 815:
              handleGeneralFaultEnd(valves, previousValue, null, timestamp, timestamps);

              break;
            case 706:
              handleWaterLeakage(valves, previousValue, null, timestamp, timestamps);

              break;
            case 806:
              handleWaterLeakageEnd(valves, previousValue, null, timestamp, timestamps);

              break;
            case eventID >= 781 && eventID <= 796 && eventID: {
              const valveIndex = eventID - 780;
              handleHighFlowRateEvent(valves, previousValue, valveIndex, timestamp, timestamps);
              break;
            }
            case eventID >= 881 && eventID <= 896 && eventID: {
              const valveIndex = eventID - 880;
              handleHighFlowRateEventEnd(valves, previousValue, valveIndex, timestamp, timestamps);
              break;
            }
            case eventID >= 901 && eventID <= 916 && eventID: {
              const valveIndex = eventID - 900;
              handleLowFlowRateEvent(valves, previousValue, valveIndex, timestamp, timestamps);
              break;
            }
            case eventID >= 1001 && eventID <= 1016 && eventID: {
              const valveIndex = eventID - 1000;
              handleLowFlowRateEventEnd(valves, previousValue, valveIndex, timestamp, timestamps);
              break;
            }
            case eventID >= 1133 && eventID <= 1148 && eventID: {
              const valveIndex = eventID - 1132;
              handleMissingPulsesEvent(valves, previousValue, valveIndex, timestamp, timestamps);
              break;
            }
            case eventID >= 1233 && eventID <= 1248 && eventID: {
              const valveIndex = eventID - 1232;
              handleMissingPulsesEventEnd(valves, previousValue, valveIndex, timestamp, timestamps);
              break;
            }
            case eventID >= 749 && eventID <= 764 && eventID: {
              const valveIndex = eventID - 748;
              handleManualValveOpeningEvent(valves, previousValue, valveIndex, timestamp, timestamps);
              break;
            }
            case eventID >= 849 && eventID <= 864 && eventID: {
              const valveIndex = eventID - 848;
              handleManualValveOpeningEventEnd(valves, previousValue, valveIndex, timestamp, timestamps);
              break;
            }
            default:
              // console.warn(`not implemented eventID: ${eventID}`);
              break;
          }
        }
      });

      valves.forEach((valve, index) => {
        if (index === COUNT_OF_Y_LINES - 1) {
          valves[index].push(0);
        } else if (!previousValue[index]) {
          valves[index].push(null); //+
          //previousValue[index] = null;
        } else if (previousValue[index] === 'end') {
          valves[index].push(null); //+
          //previousValue[index] = null;
        } else if (previousValue[index] === 'start') {
          valves[index].push(' '); //+
          //previousValue[index] = " ";
        }
      });
      let date2 = new Date(endDate + 'T00:00:00');
      date2 = new Date(date2.getTime() + 24 * 60 * 60 * 1000);
      const max = Math.floor(date2.getTime() / 1000);

      timestamps.push(max);

      const tempData: AlignedData = [
        timestamps,
        ...valves.reverse(), //r
      ];
      const maxCountOfYTicks = height / 2 / 30;
      return {
        tempData: [...modifyFlowRate(tempData)],
        series: Array.from({ length: COUNT_OF_Y_LINES - 1 }, (_, i) => {
          if (i + 1 === 1 || i == 15) {
            return {
              label: `${i + 1}`,
            };
          }
          const interval = Math.floor(16 / maxCountOfYTicks);

          if (i % interval === 0 && i <= 15) {
            return {
              label: `${i + 1}`,
            };
          }
          if (i > 15) {
            return {
              label: ` `,
            };
          }
          return {
            label: ` `,
          };
        }),
      };
    }

    const result = convertData(appData);
    //console.log("result", result)
    const series = [
      { label: 'Time' },
      {
        paths: paths as Series.PathBuilder,
        label: ' ',
        drawStyle: 0,
        lineInterpolation: 2,
        stroke: FLOW_RATE_STROKE_COLOR,
        fill: FLOW_RATE_FILL_COLOR,
        scale: 'flow_rate',
        side: 1,
      },
      ...result.series.reverse(),
    ];

    return {
      series,
      graphData: result.tempData,
    };
  };

  const sortedData = allData.sort((a, b) => +new Date(a.eventDate) - +new Date(b.eventDate));
  const { series, graphData } = formatData(sortedData);

  const graphDataLayer2: AlignedData = [graphData[0], graphData[1]];

  const valvesInfo = formatValvesInfo(graphData);
  graphData[1] = [];
  console.log('111', graphData);
  let cursLeft = -10;
  let cursTop = -10;
  const cursorMemo: CursorMemo = {
    set: (left, top) => {
      cursLeft = left;
      cursTop = top;
    },
    get: () => ({ left: cursLeft, top: cursTop }),
  };

  const customGridSplits = (uplot: uPlot, axisIdx, scaleMin, scaleMax, foundIncr, foundSpace, extraArg) => {
    const result = Array(COUNT_OF_Y_LINES)
      .fill(null)
      .map((v, i) => {
        if (i < COUNT_OF_Y_LINES - 16) {
          return null;
        }
        return axisIdx[i] || 0;
      });

    return result;
  };
  const dayTimeAndYear = isRTL ? `{HH}:{mm}\n{WWW} {DD}.{M}.{YYYY} ` : `{HH}:{mm}\n{DD}.{M}.{YYYY} {WWW}`;
  const dayAndYear = isRTL ? `\n{WWW} {DD}.{M}.{YYYY} ` : `\n{DD}.{M}.{YYYY} {WWW}`;

  const options: any = {
    ...chartSize,
    scales: {
      flow_rate: {},
      x: {
        time: true,
        range: [defaultScaleXParams.min, defaultScaleXParams.max],
        min: defaultScaleXParams.min,
        max: defaultScaleXParams.max,
      },
      y: {},
    },
    fmtDate: (tpl) => uPlot.fmtDate(tpl, isRTL ? WEEK_DAY_NAMES_HE : WEEK_DAY_NAMES_ENG), //future for translate
    axes: [
      {
        space: 70,

        values: [
          [31536000, '{YYYY} {WWW}', null, null, null, null, null, null, 1],
          [2419200, dayAndYear, null, null, null, null, null, null, 1],
          [86400, dayAndYear, null, null, null, null, null, null, 1],
          [3600, dayTimeAndYear, null, null, null, null, null, null, 1],
          [3000, '{HH}:{mm}', null, null, null, null, null, dayAndYear, 1],
          [60, '{HH}:{mm}:{ss}', null, null, null, null, null, dayAndYear, 1],
          [1, '{HH}:{mm}:{ss}', null, null, null, dayAndYear, 1],
          [0.001, '{mm}:{ss}.{fff}', null, null, dayAndYear, 1],
        ],

        ticks: {
          filter: (val, i, all) => {
            if (i.length === 0) return [];

            const dates = i.map((ts) => new Date(ts * 1000));

            let previousDate = dates[0];
            const result = [i[0]];

            dates.slice(1).forEach((currentDate, index) => {
              const startOfPreviousDay = new Date(previousDate);
              startOfPreviousDay.setHours(0, 0, 0, 0);

              const startOfCurrentDay = new Date(currentDate);
              startOfCurrentDay.setHours(0, 0, 0, 0);

              if (startOfCurrentDay > startOfPreviousDay) {
                previousDate = currentDate;
                result.push(i[index + 1]);
              } else {
                result.push(null);
              }
            });

            return result;
          },

          stroke: 'black',
          width: 1,
        },
        side: 2,
      },
      {
        labelSize: 130,
        space: (u, v) => {
          return v;
        },
        grid: { show: true, filter: customGridSplits },
        side: 3,
      },
      {
        scale: 'flow_rate',
        labelFont: '14px Noto Sans Hebrew',
        side: 1,
        grid: { show: false },
      },
    ],
    legend: {
      show: false,
    },
    splits: (v: uPlot) => {
      return v;
    },
    padding: [10, 85, 10, 40],

    series,
    hooks: {
      drawSeries: [],
      setScale: [updateDateFormat],
      draw: [],
    },
    plugins: [
      timelinePlugin({
        count: graphData.length - 1,
        fill: (seriesIdx, dataIdx, value) => {
          switch (value) {
            case 'communication fault':
              return lOST_SIGNAL_LINE_COLOR;
            case 'generalFault':
              return GENERAL_FAULT;
            case 'generalFaultContinue':
              return GENERAL_FAULT;
            case 'generalFaultEnd':
              return GENERAL_FAULT;

            case 'waterLeakage':
              return SYSTEM_FAULT;
            case 'waterLeakageContinue':
              return SYSTEM_FAULT;
            case 'waterLeakageEnd':
              return SYSTEM_FAULT;
            case 'userActionsEvent':
              return 'rgba(0, 0, 0, 0.0)';
            default:
              return 'rgba(0, 0, 0, 0.0)';
          }
        },
        stroke: (seriesIdx, dataIdx, value) => 'rgba(0, 0, 0, 0.0)',
      }),
      tooltipsPlugin({
        cursorMemo,
        valvesInfo,
      }),
    ],
  };
  const optionsLayer2 = { ...options };

  optionsLayer2.scales = {
    flow_rate: {
      time: false,
      range: (u, min, max) => {
        return [0, max + max * 0.45];
      },
    },
    x: {},
    y: {},
  };
  optionsLayer2.axes = [
    {
      space: 70,
      values: [],
      ticks: {},
      side: 2,
      grid: { show: false },
    },
    {
      labelSize: 130,
      grid: { show: false },
      side: 3,
    },
    {
      scale: 'flow_rate',
      labelFont: '14px Noto Sans Hebrew',
      side: 1,
      grid: { show: false },
    },
  ];
  optionsLayer2.padding = [10, 35, 10, 80];
  optionsLayer2.series = [
    { label: 'Time' },
    {
      paths: paths as Series.PathBuilder,
      label: ' ',
      drawStyle: 0,
      lineInterpolation: 2,
      stroke: FLOW_RATE_STROKE_COLOR,
      fill: FLOW_RATE_FILL_COLOR,
      scale: 'flow_rate',
      side: 1,
    },
  ];
  optionsLayer2.plugins = [];

  const chartRef = useRef(null);
  const chartLayer2Ref = useRef(null);

  return (
    <Box sx={{ position: 'relative', overflow: 'hidden', width: '100%' }}>
      <Box
        sx={{
          position: 'absolute',
          right: isRTL ? '0px' : 'auto',
          left: isRTL ? 'auto' : '0px',
          bottom: 60,
          height: height - 220,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '40px',
        }}
      >
        <Box sx={{}}>
          <Box sx={{ transform: 'rotate(-90deg)' }}>{t('valves_time_line_chart_label')}</Box>
        </Box>
      </Box>
      <Box sx={{ position: 'relative', display: 'block', height: height, width: '100%' }}>
        <Box sx={{ position: 'absolute', display: 'block', height: height, width: '100%', top: 0, opacity: 1 }}>
          <UplotReact
            options={optionsLayer2}
            data={graphDataLayer2}
            onCreate={(chart) => {
              chartLayer2Ref.current = chart;
            }}
            onDelete={(chart) => {
              chartLayer2Ref.current = null;
            }}
          />
        </Box>
        <Box sx={{ position: 'relative', display: 'block', height: height, width: '100%', top: 0, opacity: 1 }}>
          <UplotReact
            options={options}
            data={graphData}
            onCreate={(chart) => {
              chartRef.current = chart;
            }}
            onDelete={(chart) => {
              chartRef.current = null;
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};


export const WEEK_DAY_NAMES_ENG = {
	WWW: ["Su", "M", "Tu", "W", "Th", "F", "Sa"],
	MMMM: ["", "", "", "", "", "", "", "", "", "", "", ""],
	MMM: ["", "", "", "", "", "", "", "", "", "", "", ""],
	WWWW: ["", "", "", "", "", "", ""],
};
export const WEEK_DAY_NAMES_HE = {
	WWW: ["א", "ב", "ג", "ד", "ה", "ו", "ש",],
	MMMM: ["", "", "", "", "", "", "", "", "", "", "", ""],
	MMM: ["", "", "", "", "", "", "", "", "", "", "", ""],
	WWWW: ["", "", "", "", "", "", ""],
};
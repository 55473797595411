import { FC } from 'react';
import { Box, Button, Popover, PopoverProps, Stack, Typography } from '@mui/material';
import { triangle } from 'shared/assets';
import { useExtendedTranslation } from 'shared/hooks';

const btnStyles = {
  bgcolor: '#0000',
  fontSize: '11px',
  height: '27px',
  px: '12px',
  textTransform: 'capitalize',
  borderRadius: '10px',
  color: 'white',
  borderColor: 'red',
  border: '1px solid white',
  margin: '10px',
  '&:hover': {
    bgcolor: '#F8F8F81A',
    color: 'white',
  },
};
const btnConfirmStyles = {
  bgcolor: 'info.main',
  fontSize: '11px',
  height: '27px',
  px: '12px',
  textTransform: 'capitalize',
  borderRadius: '10px',
  margin: '10px 0',
  '&:hover': {
    bgcolor: '#E4E4E4',
  },
};
interface Props extends PopoverProps {
  confirm: (e: any) => void;
  titleText?: string;
  buttonTexts: string[];
  inverseButtons?: boolean;
}

export const Confirm: FC<Props> = ({
  open,
  titleText,
  anchorEl,
  onClose,
  confirm,
  buttonTexts,
  inverseButtons,
  ...props
}) => {
  const { t } = useExtendedTranslation();

  const handleClose = (e: React.MouseEvent<HTMLElement>) => onClose?.(e, 'backdropClick');

  const handleConfirm = (e: React.MouseEvent<HTMLElement>) => {
    onClose?.(e, 'backdropClick');
    confirm(e);
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '30px',
          maxWidth: '240px',
          pb: '16px',
          boxShadow: 'none',
          bgcolor: 'rgba(0,0,0,0)',
        },
      }}
      {...props}
    >
      <Box
        sx={{
          bgcolor: 'primary.dark',
          color: 'info.main',
          fontSize: '14px',
          borderRadius: '30px',
          maxWidth: '250px',
          minWidth: '150px',
          padding: '14px 10px 20px 10px',
          position: 'relative',
        }}
      >
        <Typography align="center" sx={{ fontSize: '14px', mb: '8px' }}>
          {titleText ? t(titleText) : t('are_you_sure_confirm')}
        </Typography>
        <Stack
          direction="column"
          sx={{ flexDirection: inverseButtons ? 'column' : 'column-reverse' }}
          spacing="6px"
          justifyContent="center"
        >
          <Button sx={{ ...btnConfirmStyles }} onClick={handleConfirm}>
            {buttonTexts[0]}
          </Button>
          <Button onClick={handleClose} sx={{ ...btnStyles }}>
            {buttonTexts[1]}
          </Button>
        </Stack>

        <Box sx={{ position: 'absolute', bottom: '-16px', left: 'calc(50% - 9px)' }}>
          <img src={triangle} />
        </Box>
      </Box>
    </Popover>
  );
};

import { Layout } from '../../shared/ui';
import { Header } from '../../widgets';
//import { ControllerManager } from 'widgets';
import { ControllerManager } from 'features/ControllerManager';
export const ControllersPage = () => {
  return (
    <Layout>
      <Header />
      <Layout.Body>
        <ControllerManager />

        {/* <EditController showRegistersButton/> */}
      </Layout.Body>
    </Layout>
  );
};

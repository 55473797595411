import {TableDataWithId } from 'shared/models';
import { TableTitle, TranslateColumn } from 'components/BaseTable';

export const TABLE_TITLES: TableTitle<TableDataWithId>[] = [
  { title: 'controller_id', key: 'id', width: 80},
  { title: 'controller_name', key: 'objectName', width: '100%' },
  { title: 'customer_id', key: 'customerId', width: 60 },
  { title: 'customer_name', key: 'customerName', width: '100%' },
];


// export const DATE_COLUMN_INDEXES=[8,9]
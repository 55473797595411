import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { formatDtoDate } from 'shared/lib';
import { CustomerType, Customer, CustomerStatus, CustomerAgreementCode } from 'shared/models';

interface CustomerState {
  customers: Customer[];
  customerTypes: CustomerType[];
  customerStatuses: CustomerStatus[];
  customerAgreementCodes: CustomerAgreementCode[];
  currentCustomer: Customer | null;
}

const initialState: CustomerState = {
  customers: [],
  customerTypes: [],
  customerStatuses: [],
  customerAgreementCodes: [],
  currentCustomer: null,
};

const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    setCustomers: (state, action: PayloadAction<Customer[]>) => {
      const customers = action.payload.map((c) => ({
        ...c,
        createdDate: formatDtoDate(c.createdDate),
        operationStartDate: formatDtoDate(c.operationStartDate),
      }));

      state.currentCustomer = null;
      state.customers = customers;
    },
    setCustomerTypes: (state, action: PayloadAction<CustomerType[]>) => {
      state.customerTypes = action.payload;
    },
    setCustomerStatuses: (state, action: PayloadAction<CustomerStatus[]>) => {
      state.customerStatuses = action.payload;
    },
    setCustomerAgreements: (state, action: PayloadAction<CustomerAgreementCode[]>) => {
      state.customerAgreementCodes = action.payload;
    },
    setCurrentCustomer: (state, action: PayloadAction<Customer | null>) => {
      state.currentCustomer = action.payload;
    },
    addNewCustomer: (state, action: PayloadAction<Customer>) => {
      state.customers.push(action.payload);
    },
    editCurrentCustomer: (state, action: PayloadAction<Customer>) => {
      const editingCustomer = action.payload;
      state.customers = state.customers.map((c) => (c.id === editingCustomer.id ? editingCustomer : c));
    },
  },
});

export const {
  setCustomers,
  setCustomerTypes,
  setCustomerStatuses,
  setCustomerAgreements,
  setCurrentCustomer,
  addNewCustomer,
  editCurrentCustomer,
} = customerSlice.actions;

export const customerReducer = customerSlice.reducer;

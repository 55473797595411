import { Box } from '@mui/material';
import { Icon, Logo, Row, Text } from '../../shared/ui';
import { illustration } from '../../shared/assets';
import { LoginForm } from '../../widgets';
import { useExtendedTranslation } from 'shared/hooks';

export const LoginPage = () => {
  const { t } = useExtendedTranslation();
  return (
    <Box sx={{ p: { xs: '18px 31px', xl: '58px 61px' } }}>
      <Logo isLogin />

      <Text
        sx={{
          color: 'primary.main',
          fontFamily: 'Noto Sans Hebrew',
          mb: { xs: '15px', xl: '50px' },
          fontSize: { xs: '41px', xl: '64px' },
          lineHeight: { xs: '46px', xl: '96px' },
          textAlign: 'center',
        }}
      >
        {t('back_office_system_header_text')}
      </Text>
      <Row justifyContent="center">
        <Icon
          src={illustration}
          sx={{
            mr: '95px',
            display: { xs: 'none', lg: 'block' },
          }}
        />
        <LoginForm />
      </Row>
    </Box>
  );
};

import { useState } from 'react';
import { useAppSelector } from 'shared/hooks';
export const useSelectProject = () => {
  const { customers } = useAppSelector((st) => st.customers);

  const projectItems = customers.map((s) => ({
    value: s.id.toString(),
    label: s.customerName,
  }));
  const [projectSelected, setProjectSelected] = useState<string>('');
  const onChangeProjectSelect = (value: string) => setProjectSelected(value);
  return {
    projectSelected,
    onChangeProjectSelect,
    projectItems,
  };
};

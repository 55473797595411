import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ServiceCall } from './ServiceCallModels';

export interface FaultEquipment{
  code:number, languageCode:string
}
export interface ActionType{
  code:number, languageCode:string
}
export interface AssignedTech{
  userID: number, userName: string
}
interface FotaState {
    allServiceCalls: ServiceCall[]
    faultEquipment: FaultEquipment[]
    actionType:ActionType[]
    assignedTech:AssignedTech[]
}

const initialState: FotaState = {
	allServiceCalls:[],
  faultEquipment:[],
  actionType:[],
  assignedTech:[]
	};

const AllServiceCallsSlice = createSlice({
  name: 'wAMessageStatus',
  initialState,
  reducers: {
    setAllServiceCalls: (state, action: PayloadAction<ServiceCall[]>) => {
      state.allServiceCalls= action.payload.map((item, index)=>{
        item.id=index+1;
        return item
      })
  },

  setFaultEquipment: (state, action: PayloadAction<FaultEquipment[]>) => {
    state.faultEquipment= action.payload
  },

  setActionType: (state, action: PayloadAction<ActionType[]>) => {
    state.actionType= action.payload
  },

  setAssignedTech: (state, action: PayloadAction<AssignedTech[]>) => {
    state.assignedTech= action.payload
  },



  },
});

export const {
	setAllServiceCalls,
  setFaultEquipment,
  setActionType,
  setAssignedTech
} = AllServiceCallsSlice.actions;

export const allServiceCallsReducer = AllServiceCallsSlice.reducer;


import { FC } from 'react';
import { PieChart, BarChart,Bar, XAxis, YAxis, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import localStorageService from 'shared/localStorage';
import { CustomizedAxisYTick, CustomizedAxisXTick, CustomizedLabel} from './HorizontalBarListsComponents';
import { ChartNames, DashboardData} from 'shared/models';
type Props = {
	data: DashboardData[],
  chartName: ChartNames,
  bigLegendText: boolean,
  openDashboardDetails: (chartName: string, itemName: string, ) => void;
};

export const HorizontalBarLists: FC<Props>= ({data,chartName, openDashboardDetails, bigLegendText}) => {
  const isRTL =localStorageService.isRTL;
  const selectItem=(e)=>{
    openDashboardDetails(chartName, e.key)
  }
  const selectLabel=(name:string)=>{
    openDashboardDetails(chartName, name)
  }
  return (
      <ResponsiveContainer width="100%" height="100%">
        <BarChart  layout="vertical" width={150} height={40} data={data} margin={{
            top: 5,
            right: 35,
            left: bigLegendText ? 45:20,
            bottom: 5,
          }}>
          <XAxis tick={<CustomizedAxisXTick />} type={'number'} textAnchor={`${isRTL? 'start' : 'end'}`}   />
          <YAxis tick={<CustomizedAxisYTick />} type={'category'} tickSize={2} dataKey="name" interval={0}  />
          <Bar cursor={'pointer'} onClick={selectItem} dataKey="value" label={(props)=><CustomizedLabel data={data} selectItem={selectLabel}  {...props} />}  />
        </BarChart>
      </ResponsiveContainer>
  );
};

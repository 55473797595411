import { IconButton, Stack } from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { close_popup } from 'shared/assets';
import { useAppDispatch, useAppSelector, useAnchorEl } from 'shared/hooks';
import { toggleNewConnectedController, setNodesConnections, setNodesPoints, setNodesLines, setNodesEndPoints, setCurrentNPControllerId, setNPDefaultSortIndex, setScriptParameters } from 'shared/slices';
import { BigTitle, Modal, Row, Select } from 'shared/ui';
import { Filter } from 'features';
import { BaseBtn, BaseInput, Icon } from '../../shared/ui';
import React from 'react';
import { Confirm, Text } from 'shared/ui';
import { Box, MenuItem, Checkbox } from '@mui/material';
import { ConnectedControllerItem } from 'shared/models';
import { BaseSelect, ScriptParametersTable } from 'features';
import { useDropDown } from './hooks';
import { useGetCustomers, useControllers, useNetworkProtectionManagement, useGetScriptParametersDataGrid } from 'shared/api';
import { checked_box, un_checked_box } from 'shared/assets';
export const NewConnectedControllerModal: FC = () => {
  const [isChanged, setIsChanged] = useState(false);
  const { isOpen, anchorEl, onOpenMenu, onCloseMenu } = useAnchorEl();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { openNewConnectedController, } = useAppSelector((st) => st.modals);
  const { currentNPController, NPScripts, NPSortIndexes, NPDefaultSortIndex, scriptParams } = useAppSelector((st) => st.networkProtection);
  const closeModal = () => dispatch(toggleNewConnectedController());
  const { getCustomer, isLoadingCustomers } = useGetCustomers();
  const { getControllers, isLoadingControllers } = useControllers();
  const [ruleEnable, setRuleEnable] = useState(true);

  const {
    getNPScripts,
    getNPSortIndex,
    isLoadingNPSortIndexes,
    addNewConnectedController,
    getNPDefaultSortIndex,
  } = useNetworkProtectionManagement();
  const {
    getScriptParametersDataGrid
  } = useGetScriptParametersDataGrid()
  useEffect(() => {
    if (!openNewConnectedController) {
      setValues(initialValues);
      onChangeCustomerItems('');
      onChangeControllerItems('');
      dispatch(setNPDefaultSortIndex(''));
      setIsChanged(false);
      setRuleEnable(true);
    }
  }, [openNewConnectedController]);
  const initialValues = {
    icProjectName: '',
    dstObjectName: '',
    dstModelID: '',
    dstVersion: '',
    icProjectID: 0,
    dstObjectCustomerID: 0,
    dstObjectID: 0,
    dstHWID: 0,
    description: '',
    ruleEnable: true,
    NPScript: '',
    sortIndex: ''
  };
  useEffect(() => {
    if (currentNPController) {
      // setValues(currentNPController);
      setValues(() => ({
        ...currentNPController,
        ['NPScript']: 'I12IP',
      }));
      onChangeCustomerItems(currentNPController.dstObjectCustomerID.toString());
      getControllers(currentNPController.dstObjectCustomerID.toString())

    }
  }, [currentNPController, openNewConnectedController]);
  useEffect(() => {
    if (openNewConnectedController) {
      getCustomer()
      getNPScripts()
    }
  }, [currentNPController, openNewConnectedController]);



  const [values, setValues] = useState<ConnectedControllerItem>(initialValues);

  useEffect(() => {
    setValues((prevValues) => ({
      ...prevValues,
      ['sortIndex']: '',
    }));
    if (values.NPScript) {
      getNPSortIndex(values.NPScript)
    }

  }, [values.NPScript]);
  const handleCloseEditModal = (e: React.MouseEvent<HTMLElement>) => {
    isChanged ? onOpenMenu(e) : closeModal();
  };
  const onSubmit = () => {
    const data = {
      projectId: values.icProjectID,
      srcObjectId: controllerItem,
      dstObjectId: values.dstObjectID,
      scriptId: values.sortIndex,
      ruleDescription: values.description,
      scriptParams: null
    }
    addNewConnectedController(data)
  };
  const { customerItem,
    onChangeCustomerItems,
    dropDownCustomerItems,
    controllerItem,
    onChangeControllerItems,
    dropDownControllersItems
  } = useDropDown()
  useEffect(() => {
    if (openNewConnectedController) {
      getControllers(customerItem)
    }
  }, [customerItem]);

  useEffect(() => {

    if (NPDefaultSortIndex) {
      setValues((prevValues) => ({
        ...prevValues,
        ['sortIndex']: NPDefaultSortIndex.toString(),
      }));
    }
  }, [NPDefaultSortIndex]);


  useEffect(() => {
    if (controllerItem) {
      getNPDefaultSortIndex(values?.NPScript, controllerItem)
    }
  }, [controllerItem]);

  useEffect(() => {
  }, [dropDownControllersItems]);
  const onChangeSelectValues = (event: any) => {
    const newValue = event.target.value;
    const property = event.target.name;
    setValues((prevValues) => ({
      ...prevValues,
      [property]: newValue,
    }));
    setIsChanged(true);
  };
  const onChangeValues = (event: any, propertyName: string) => {
    const newValue = event.target.value;
    setValues((prevValues) => ({
      ...prevValues,
      [propertyName]: newValue,
    }));

    setIsChanged(true);
  };
  useEffect(() => {
    if (values.sortIndex) {
      getScriptParametersDataGrid(values.sortIndex)
    } else {
      dispatch(setScriptParameters([]));
    }

  }, [values.sortIndex]);
  const onChangeRuleEnable = () => {
    setRuleEnable(!ruleEnable)
    setIsChanged(true);
  };
  const getTranslate = (script_Type: string) => {
    return t(`st_${script_Type.trim()}`)
  }
  const closeButtonRef = useRef<HTMLButtonElement | null>(null);
  const handleModalClose = () => {
    if (closeButtonRef.current) {
      closeButtonRef.current.click();
    }
  };
  return (
    <Modal padding={'20px'} height={'calc(100vh - 20px)'} open={openNewConnectedController} disableEscapeKeyDown onClose={handleModalClose}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <BigTitle >{t('Network_protection_add_connected_controller_modal_title')}</BigTitle>
        <IconButton onClick={handleCloseEditModal} ref={closeButtonRef} >
          <img src={close_popup} />
        </IconButton>

      </Stack>
      <Box>
        <Box sx={{ minHeight: '500px' }} component="form" autoComplete="off">
          <Row spacing="20px" alignItems="flex-start" justifyContent="space-between">
            <Box width={'32%'}>
              <BaseInput
                label={`${t('ic_project_name_np_edit_modal')}`}
                name="icProjectName"
                disabled
                helperText=""
                value={values?.icProjectName ?? ''}
                small
                compact
              />
              <BaseInput
                label={`${t('controller_name_np_edit_modal')}`}
                name="dstObjectName"
                disabled
                helperText=""
                value={values?.dstObjectName ?? ''}
                small
                compact
              />
              <BaseInput
                label={`${t('model_edit_np_modal')}`}
                name="dstModelID"
                disabled
                helperText=""
                value={values?.dstModelID ?? ''}
                small
                compact
              />
            </Box>
            <Box width={'32%'}>
              <BaseInput
                label={`${t('project_id_np_edit_modal')}`}
                name="icProjectID"
                disabled
                helperText=""
                value={values?.icProjectID ?? ''}
                small
                compact
              />
              <BaseInput
                label={`${t('objectID_np_edit_modal')}`}
                name="dstObjectID"
                disabled
                helperText=""
                value={values?.dstObjectID ?? ''}
                small
                compact
              />
              <BaseInput
                label={`${t('version_number_np_edit_modal')}`}
                name="dstVersion"
                disabled
                helperText=""
                value={values?.dstVersion ?? ''}
                small
                compact
              />
            </Box>
            <Box width={'32%'}>
              <BaseInput
                label={`${t('customer_id_name_np_edit_modal')} *`}
                name="dstObjectCustomerID"
                disabled
                helperText=""
                value={values?.dstObjectCustomerID ?? ''}
                small
                compact
              />
              <BaseInput
                label={`${t('WHID_name_np_edit_modal')} *`}
                name="dstHWID"
                disabled
                helperText=""
                value={values?.dstHWID ?? ''}
                small
                compact
              />
            </Box>
          </Row>

          <Row spacing="20px" alignItems="flex-start" justifyContent="space-between">

            <Box width={'32%'}>
              <BigTitle sx={{ marginBottom: '10px', marginTop: '20px' }}>{t('connected_controller_title_modal_title')}</BigTitle>
              <BaseSelect
                title={`${t('iCProject_label_edit_modal')} *`}
                value={customerItem}
                onChange={onChangeCustomerItems}
                items={dropDownCustomerItems}
                hideAllButton
                showHomeButton
                showOnlyResetButton
                withSearch
                formInput
                noSelectLabel={' '}
                sx={{ width: '320px', maxWidth: '100%' }}
              />
              {<BaseSelect
                title={`${t('controller_label_edit_modal')} *`}
                value={controllerItem}
                onChange={onChangeControllerItems}
                items={dropDownControllersItems}
                hideAllButton
                showHomeButton
                showOnlyResetButton
                withSearch
                formInput
                noSelectLabel={' '}
                disabled={!customerItem || isLoadingControllers}
                sx={{ width: '320px', maxWidth: '100%' }}
              />}
              <Select
                label={`${t('script_type_label_edit_modal')} *`}
                name="NPScript"
                value={values?.NPScript}
                onChange={onChangeSelectValues}
                displayEmpty
                marginTop="4px"
                sx={{
                  fontFamily: 'Noto Sans Hebrew !important',
                }}
                small
              >
                {NPScripts.map((a) => (
                  <MenuItem key={a.type} value={a.type}>
                    {a.type ? getTranslate(a.type) : 'not select'}
                  </MenuItem>
                ))}
              </Select>
              <Select
                label={`${t('index_label_edit_modal')} *`}
                name="sortIndex"
                value={values?.sortIndex}
                onChange={onChangeSelectValues}
                displayEmpty
                // disabled={!values?.NPScript || isLoadingNPSortIndexes}
                marginTop="4px"
                sx={{
                  fontFamily: 'Noto Sans Hebrew !important',
                }}
                small
              >
                {NPSortIndexes.map((a) => (
                  <MenuItem key={a.sortIndex} value={a.sortIndex}>
                    {a.sortIndex ? a.sortIndex : 'not select'}
                  </MenuItem>
                ))}

              </Select>
              <BaseInput
                label={`${t('description_np_edit_modal')} *`}
                name="description"
                helperText=""
                value={values?.description ?? ''}
                small
                compact
                onChange={(event) => onChangeValues(event, 'description')}
              />
              <Row spacing="0px" mt="10px" alignItems="center" >
                {values && <Checkbox
                  size="small"
                  checked={ruleEnable}
                  onChange={onChangeRuleEnable}
                  icon={<Icon src={un_checked_box} />}
                  checkedIcon={<Icon src={checked_box} />}
                ></Checkbox>}
                <Text
                  noWrap
                  sx={{
                    color: 'primary.dark',
                    fontSize: '14px',
                    fontWeight: 500,
                    textAlign: 'left',
                    fontFamily: 'Noto Sans Hebrew !@important',
                  }}
                >{t('is_rule_enable_np_edit_modal')}</Text></Row>
            </Box>
            <Box width={'64%'}>
              <BigTitle sx={{ fontSize: '18px', marginTop: "24px" }}>{t('script_parameters_edit_modal')}</BigTitle>
              <ScriptParametersTable scriptParams={scriptParams} />
            </Box>

          </Row>
        </Box>
        <Box component="form" autoComplete="off">
          <Row justifyContent="flex-end" mt={'35px'}>
            <Row width={300}>
              <BaseBtn onClick={handleCloseEditModal} btnColor="info.main" fullWidth>
                {t('cancel_edit_modal')}
              </BaseBtn>
              <Box onClick={handleCloseEditModal} sx={{ height: '40px', width: '25px' }} />
              <BaseBtn
                onClick={onSubmit}
                disabled={!values?.sortIndex || !controllerItem}
                btnColor="primary.dark"
                fullWidth
              >
                {t('save_edit_modal')}
              </BaseBtn>
            </Row>
          </Row>
        </Box>
        <Confirm
          open={isOpen}
          anchorEl={anchorEl}
          onClose={onCloseMenu}
          confirm={closeModal}
          buttonTexts={[t('cancel_edit_edit_modal'), t('back_to_edit_edit_modal')]}
        />
      </Box>
    </Modal>
  );
};

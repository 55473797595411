import { Box, IconButton, Stack, Typography } from '@mui/material';
import { BaseSelect } from 'features';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseBtn, BaseInput, BigTitle, Modal, Row, WaMessageBackground } from 'shared/ui';
import { useMessageTemplates, useWaMessageSettings } from './hooks';
import { FormatWaMessage } from './components';
import { useAppSelector, useToast } from 'shared/hooks';
import { Customer } from 'features/CustomerManager/customerModels';
import { CustomTooltip } from 'widgets/ImportSim/components/CustomTooltip';
import localStorageService from 'shared/localStorage';
interface Props {
  openModal: boolean;
  onCloseModal: () => void;
  selectedCustomersID: number[];
  customers: Customer[];
}

export const SendWaMessage = ({ openModal, onCloseModal, selectedCustomersID, customers }: Props) => {

  const { CUSTOMERS } = useAppSelector((st) => st.globalSlice);
  const DEFAULT_COUNTRY_CODE = CUSTOMERS.DEFAULT_COUNTRY_CODE;
  const { t } = useTranslation();
  const {
    selectedMessageTemplate,
    onChangeMessageTemplate,
    messageTemplates,
    selectedMessageLanguage,
    onChangeMessageLanguage,
    messageLanguages,
    paramsMessage,
    onChangeParamsMessage,
    currentTemplate,
  } = useWaMessageSettings();

  const [contactPersonPhoneNumbers, setContactPersonPhoneNumbers] = useState<string[]>(null);
  const [selectedCustomers, setSelectedCustomers] = useState<Customer[]>([]);
  const { currentLang } = localStorageService;
  useEffect(() => {
    if (openModal) {
      const selectedCustomers = customers.filter((item) => selectedCustomersID.includes(item.id));
      setSelectedCustomers(selectedCustomers);
      setContactPersonPhoneNumbers(selectedCustomers.map((item) => item.contactPersonPhoneNumber));
      onChangeMessageLanguage(currentLang)
    }
  }, [openModal]);

  const { messageTemplatesData } = useAppSelector((st) => st.whatsApp);
  const { getMessageTemplates, sendWAMessage, isLoading } = useMessageTemplates();
  useEffect(() => {
    getMessageTemplates();
  }, []);

  const closeModal = () => {
    onChangeMessageTemplate('');
    onCloseModal();
  };
  const cancel = () => {
    closeModal();
  };
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const handleShowTooltip = (status: boolean) => {
    if (selectedCustomers.length - contactPersonPhoneNumbers.filter((item) => item).length > 0) {
      setTooltipOpen(status);
    }
  };

  const { showError, showSuccess } = useToast();


  const removeLeadingZero = (str) => {
    if (str.startsWith('0')) {
      return str.slice(1);
    }
    return str;
  };

  const addCountryCode = (phone: string) => {
    let newPhone = '';
    const currentPhone = phone.replace(/[+-]/g, '');
    currentPhone.startsWith(DEFAULT_COUNTRY_CODE) ? (newPhone = currentPhone) : (newPhone = DEFAULT_COUNTRY_CODE + removeLeadingZero(currentPhone));
    return newPhone;
  };


  const send = async () => {
    const waTemplate = messageTemplatesData.data.find((obj) => obj.id === selectedMessageTemplate);
    const header = [];
    const body = [];
    const footer = [];
    paramsMessage.forEach((params) => {
      if (params.type === 'header') {
        header[parseInt(params.number) - 1] = params.value;
      } else if (params.type === 'body') {
        body[parseInt(params.number) - 1] = params.value;
      } else if (params.type === 'footer') {
        footer[parseInt(params.number) - 1] = params.value;
      }
    });






    const customerPhones = {};
    const validCustomers = selectedCustomers.filter((item) => item.contactPersonPhoneNumber);
    validCustomers.forEach((item) => {
      customerPhones[item.id] = addCountryCode(item.contactPersonPhoneNumber);
    });
    const data = {
      messageTemplate: waTemplate,
      paramsHeader: header,
      paramsBody: body,
      customerPhones,
    };
    const result = await sendWAMessage(data);
    if (result) {
      if (!result.data.succeeded && result.data.sendResult.length) {
        showError(`${t('message_not_sent_for')} ${result.data.sendResult.join(',')}`);
        closeModal();
        return;
      }
      closeModal();
      showSuccess(t('message_sent_successfully'));
    } else {
      showError(t('message_not_sent'));
    }
  };

  const uniqueKeys = new Set();
  const filteredParamsMessage = paramsMessage.filter((item) => {
    if (item.type === 'body' && !uniqueKeys.has(item.key)) {
      uniqueKeys.add(item.key);
      return true;
    }
    if (item.type === 'header') {
      uniqueKeys.add(item.key);
      return true;
    }
    if (item.type === 'footer') {
      uniqueKeys.add(item.key);
      return true;
    }
    return false;
  });
  return (
    <Modal PaperProps={{ sx: { maxWidth: '562px', borderRadius: '12px' } }} open={openModal} >
      <Box
        sx={{
          p: '41px 36px 36px 36px',
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Noto Sans Hebrew',
            fontSize: '24px',
            fontWeight: 600,
            lineHeight: '29px',
            color: 'primary.dark',
          }}
        >
          {t('send_wa_message_title')}
        </Typography>
        <Box sx={{ display: 'flex', alignContent: 'flex-start', flexDirection: 'column' }}>
          <Row>
            <Box
              sx={{
                fontSize: '16px',
                fontWeight: '600',
                whiteSpace: 'nowrap',
                lineHeight: '30px',
                width: '200px',
              }}
            >
              {t('total_recipients_message_template_label')}
            </Box>

            <Box
              sx={{
                fontSize: '16px',
                fontWeight: '600',
                whiteSpace: 'nowrap',
                lineHeight: '30px',
              }}
            >
              {selectedCustomers.length}
            </Box>
          </Row>
          <Row>
            <Box onMouseEnter={() => handleShowTooltip(true)}>
              {contactPersonPhoneNumbers &&
                selectedCustomers.length - contactPersonPhoneNumbers.filter((item) => item).length > 0 ? (
                <CustomTooltip
                  open={tooltipOpen}
                  onClose={() => handleShowTooltip(false)}
                  title={
                    <>
                      {selectedCustomers && (
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <Stack direction="row" justifyContent="space-between" alignItems="center">
                            <BigTitle></BigTitle>
                          </Stack>
                          {selectedCustomers.map((item, index) => (
                            <>
                              {!item.contactPersonPhoneNumber && (
                                <Typography key={index}>{item.customerName}</Typography>
                              )}
                            </>
                          ))}
                        </Box>
                      )}
                    </>
                  }
                  placement="right-start"
                >
                  <Box
                    onMouseEnter={() => handleShowTooltip(true)}
                    sx={{ marginRight: 'auto', display: 'flex', flex: 1, cursor: 'pointer', alignItems: 'center' }}
                  >
                    <Box
                      sx={{
                        fontSize: '16px',
                        fontWeight: '600',
                        whiteSpace: 'nowrap',
                        lineHeight: '30px',
                        color: 'red',
                        width: '200px',
                      }}
                    >
                      <Row>
                        {t('invalid_recipients_message_template_label')} <span>*</span>
                      </Row>
                    </Box>
                  </Box>
                </CustomTooltip>
              ) : (
                <Box
                  sx={{
                    fontSize: '16px',
                    fontWeight: '600',
                    whiteSpace: 'nowrap',
                    lineHeight: '30px',
                    color: 'red',
                    width: '200px',
                  }}
                >
                  <Row>{t('invalid_recipients_message_template_label')}</Row>
                </Box>
              )}
            </Box>

            {contactPersonPhoneNumbers && (
              <Box
                sx={{
                  fontSize: '16px',
                  fontWeight: '600',
                  whiteSpace: 'nowrap',
                  lineHeight: '30px',
                  color: 'red',
                }}
              >
                {selectedCustomers.length - contactPersonPhoneNumbers.filter((item) => item).length}
              </Box>
            )}
          </Row>
          {messageTemplates && messageTemplates.length && <BaseSelect
            title={`${t('select_message_template_label')}`}
            value={selectedMessageTemplate}
            onChange={onChangeMessageTemplate}
            items={messageTemplates}
            hideAllButton
            noSelectLabel={`${t('select_message_template_no_select_placeholder')}`}
            hideValue
            formInput
            withoutMargin
          />}
          <WaMessageBackground>
            <FormatWaMessage paramsMessage={paramsMessage} currentTemplate={currentTemplate} />
          </WaMessageBackground>
          <BaseSelect
            title={`${t('select_lang_message_label')}`}
            value={selectedMessageLanguage}
            onChange={onChangeMessageLanguage}
            items={messageLanguages}
            hideAllButton
            noSelectLabel={`${t('select_lang_message_no_select_placeholder')}`}
            hideValue
            formInput
            withoutMargin
          />
        </Box>
        <Box
          sx={{
            minHeight: '200px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              alignContent: 'flex-start',
              width: '490px',
            }}
          >
            {paramsMessage.map(
              (item) =>
                item.type === 'header' && (
                  <BaseInput
                    key={item.key}
                    label={`${t(`${item.key}_label_wa_message`)}`}
                    name={item.key}
                    onChange={(event) => onChangeParamsMessage(item.key, event.target.value)}
                    value={item.value}
                    small
                    compact
                    helperText={''}
                    sx={{ width: '490px', height: '40px' }}
                  />
                )
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              alignContent: 'flex-start',
            }}
          >
            {filteredParamsMessage.map(
              (item) =>
                item.type === 'body' && (
                  <BaseInput
                    key={item.key}
                    label={`${t(`${item.key}_label_wa_message`)}`}
                    name={item.key}
                    onChange={(event) => onChangeParamsMessage(item.key, event.target.value)}
                    value={item.value}
                    small
                    compact
                    helperText={''}
                    sx={{ width: '240px', height: '40px' }}
                  />
                )
            )}
          </Box>
        </Box>
        <Row position="relative" justifyContent={'flex-end'} alignItems="center" mt="34px">
          <Row justifyContent="center">
            <Row width={300} gap="10px">
              <BaseBtn onClick={cancel} btnColor="info.main" fullWidth>
                {t('cancel_wa_message')}
              </BaseBtn>
              <BaseBtn
                type="submit"
                onClick={send}
                btnColor="primary.dark"
                disabled={
                  (contactPersonPhoneNumbers && !contactPersonPhoneNumbers.filter((item) => item).length) ||
                  !selectedMessageTemplate ||
                  !selectedMessageLanguage
                }
                fullWidth
                loading={isLoading}
              >
                {t('send_wa_message')}
              </BaseBtn>
            </Row>
          </Row>
        </Row>
      </Box>
    </Modal>
  );
};

import { FC } from 'react';
import { Box, List, ListItemButton, Popover, PopoverProps, Typography } from '@mui/material';
import { isHebrewFont } from 'shared/lib';
export type MenuListItem<T> = {
  icon?: string;
  title: string;
  onClick?: (row: T) => void;
};
interface Props<T> extends PopoverProps {
  actions: MenuListItem<T>[];
  row: T;
}

export const GridMenuList = <T,>({ open, anchorEl, onClose, row, actions, ...props }: Props<T>) => {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      {...props}
    >
      <List disablePadding>
        {actions.map(({ icon, title, onClick }, index) => (
          <ListItemButton
            onClick={(event) => { onClick(row); onClose(event, 'backdropClick'); }}
            key={title}
            disabled={!onClick}
            sx={{
              m: '5px',
              borderRadius: '10px',
              '&.Mui-disabled': {
                opacity: 1,
                color: 'black',
                display: 'flex',
                flexDirection: 'column',
              },
              '&:hover': {
                bgcolor: 'primary.light',
              },
            }}
          >
            <>
              <img src={icon} />
              <Typography
                sx={{ ml: '11px', fontFamily: isHebrewFont(title?.toString() ?? '') ? 'Noto Sans Hebrew' : 'Inter' }}
              >
                {title}
              </Typography>
            </>
          </ListItemButton>
        ))}
      </List>
    </Popover>
  );
};

import { BatteryStatusReportProject } from 'shared/models';
import { getFormattedDateAndTime } from 'shared/lib';

export type FormattedGraphData = {
  name?: string;
  red?: number;
  green?: number;
  blue?: number;
  dataIndex: number;
  eventDate?: Date;
  isDisrupted?: boolean;
  isDay?: number | null;
  isNight?: number | null;
  iconNight?: number | null;
  iconDay?: number | null;
  visualItems?: boolean;
};
export const useFormattedGraphData = (batteryStatusReportProject: BatteryStatusReportProject[] | []) => {
  const formattedGraphData: FormattedGraphData[] = [];
  const getMinutes = (stamp: string | Date) => {
    return Math.round(new Date(stamp).getTime() / 60000);
  };
  const formatGraphData = () => {
    if (!batteryStatusReportProject.length) {
      return [];
    }
    const TYPES: Record<number, string> = {
      1701: 'blue',
      5: 'green',
      4: 'red',
    };
    batteryStatusReportProject.forEach((item:any) => {
      if (item.eventId === 5) return;
      const result: FormattedGraphData | any = {
        name: '',
        blue: 0,
        green: 1,
        dataIndex: 0,
        iconDay: null,
      };
      result.name = getFormattedDateAndTime(item.eventDate);
      result.eventDate = item.eventDate;
      result.dataIndex = getMinutes(item.eventDate);
      //1701 4 5
      result[TYPES[item.eventId as number]] = item.dataValue;
      if (item.eventId == 4 || item.dataValue === 0) {
        delete result.green;
      }
      formattedGraphData.push(result);
    });
  };

  const addedDayNightLine = () => {
    const getFirstDayAndTime = formattedGraphData[0].eventDate;
    const getLastDayAndTime = formattedGraphData[formattedGraphData.length - 1].eventDate;
    if (getFirstDayAndTime && getLastDayAndTime) {
      const getFirstDay = getFirstDayAndTime.toString().split('T')[0];
      const getLastDay = getLastDayAndTime.toString().split('T')[0];
      const fistMinute = getMinutes(getFirstDay) - 120;
      const lastMinute = getMinutes(getLastDay) + 720;
      let countDay = 0;
      let countNight = 0;

      formattedGraphData.push({ dataIndex: fistMinute, isDay: -1, isNight: null, visualItems: true });
      formattedGraphData.push({ dataIndex: fistMinute + 1, isDay: 0, isNight: null, visualItems: true });
      formattedGraphData.push({ dataIndex: fistMinute, isDay: null, isNight: -1, visualItems: true });
      formattedGraphData.push({ dataIndex: fistMinute + 360, isDay: null, isNight: -1, visualItems: true });
      for (let currentMinutes = fistMinute; currentMinutes < lastMinute; ) {
        let item: any;
        currentMinutes += 360;
        item = { dataIndex: currentMinutes, visualItems: true };
        item[`isNight${countNight}`] = -1;
        item[`isDay${countDay}`] = null;
        formattedGraphData.push(item);
        item = { dataIndex: currentMinutes + 1, visualItems: true };
        item[`isNight${countNight}`] = -1;
        item[`isDay${countDay}`] = null;
        formattedGraphData.push(item);
        item = { dataIndex: currentMinutes, visualItems: true };
        item[`isNight${countNight}`] = null;
        item[`isDay${countDay}`] = -1;
        formattedGraphData.push(item);
        item = {
          dataIndex: currentMinutes + 360,
          iconDay: -0.5,
          visualItems: true,
        };
        item[`isNight${countNight}`] = null;
        item[`isDay${countDay}`] = -1;
        formattedGraphData.push(item);

        currentMinutes += 720;
        countNight++;
        item = { dataIndex: currentMinutes, visualItems: true };
        item[`isNight${countNight}`] = null;
        item[`isDay${countDay}`] = -1;
        formattedGraphData.push(item);
        item = { dataIndex: currentMinutes + 1, visualItems: true };
        item[`isNight${countNight}`] = null;
        item[`isDay${countDay}`] = 0;
        formattedGraphData.push(item);
        item = { dataIndex: currentMinutes, visualItems: true };
        item[`isNight${countNight}`] = -1;
        item[`isDay${countDay}`] = null;
        formattedGraphData.push(item);
        item = {
          dataIndex: currentMinutes + 360,
          iconNight: -0.5,
          visualItems: true,
        };
        item[`isNight${countNight}`] = -1;
        item[`isDay${countDay}`] = null;
        formattedGraphData.push(item);
        currentMinutes += 360;
        countDay++;
      }
      const item = { dataIndex: lastMinute + 540, isDay: -1, visualItems: true };
      formattedGraphData.push(item);
    }
  };
  formatGraphData();
  if (formattedGraphData && formattedGraphData.length) {
    addedDayNightLine();
  }
  return formattedGraphData;
};

import { BaseCollapse, Loader } from 'shared/ui';
import { BatteryGraphHeader } from './BatteryGraphHeader';
import { Box } from '@mui/material';
import { BatteryStatusReportGraph } from './BatteryGraph';
import { FC, memo, useMemo, useState } from 'react';
import { FormattedGraphData } from '../hooks/useFormattedGraphData';
import { useGetDefaultDates } from './hooks';
import { Controller } from 'features/ControllerManager/controllerModels';
import { UnderConstructionBlock } from 'entities_';
import { t } from 'i18next';

type Props = {
  deviceId: number;
  setIsOpen: (isOpen: boolean) => void;
  toggleIsOpen: (isOpen: boolean) => void;
  isOpen: boolean;
  isOpenModal: boolean;
  currentController: Controller;
  reportStartDate?: string;
  reportEndDate?: string;
  allControllerDataLoaded: boolean;
  isImplemented: boolean;
};
const statusInfo: FC<Props> = ({
  deviceId,
  setIsOpen,
  isOpen,
  isOpenModal,
  currentController,
  reportStartDate,
  reportEndDate,
  toggleIsOpen,
  allControllerDataLoaded,
  isImplemented
}) => {
  const { startDate, endDate } = useGetDefaultDates({ reportStartDate, reportEndDate });
  const initialBatteryGraphParams = {
    fromDate: startDate,
    toDate: endDate,
  };
  const [batteryGraphParams, setBatteryGraphParams] = useState<{ fromDate: string; toDate: string }>(
    initialBatteryGraphParams
  );
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [formattedData, setFormattedData] = useState<FormattedGraphData[]>([]);

  const BatteryStatusReportGraphMemoized = useMemo(
    () => (
      <BatteryStatusReportGraph
        startDate={batteryGraphParams.fromDate}
        endDate={batteryGraphParams.toDate}
        formattedData={formattedData}
      />
    ),
    [formattedData]
  );
  const dataLoaded = (status) => {
    setIsDataLoaded(status);
  };

  return (
    <BaseCollapse
      setIsOpen={toggleIsOpen}
      isOpen={isOpen}
      header={
        <BatteryGraphHeader
          deviceId={deviceId}
          setFormattedData={setFormattedData}
          setBatteryGraphParams={setBatteryGraphParams}
          setIsOpen={setIsOpen}
          isOpenModal={isOpenModal}
          currentController={currentController}
          reportStartDate={reportStartDate}
          reportEndDate={reportEndDate}
          dataLoaded={dataLoaded}
          allControllerDataLoaded={allControllerDataLoaded}
          isImplemented={isImplemented}
        />
      }
      openDefault
    >
      {isDataLoaded || !isImplemented ? (
        <Box sx={{ position: 'relative' }}>
          {isImplemented ? BatteryStatusReportGraphMemoized :
            <UnderConstructionBlock height={"340px"} text={t("battery_status_info_not_yet_implemented")} />


          }


        </Box>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
          <Loader />
        </Box>
      )}
    </BaseCollapse>
  );
};

export const BatteryStatusInfo = memo(statusInfo) as (props: Props) => React.ReactElement;

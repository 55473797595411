import useAxios from 'app/useAxios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { axiosBase } from 'shared/config';
import { useAppDispatch, useToast, useAppSelector } from 'shared/hooks';
import { Controller } from 'shared/models';
import { setDailyConsumption, setDailyConsumptionChanged, setDailyConsumptionDefaultData } from 'shared/slices';
type DailyConsumption = {
  objectID: number;
  startDate: string;
  endDate: string;
};
type GraphDate = {
  graphDateEnd: string;
  graphDateStart: string;
};
type DailyConsumptionParams = {
  objectID:number,
  startDate:string,
  endDate:string,
  uncontrolledFlow:boolean
  actualConsumption:boolean
};
export const useDailyConsumption = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { showError, showSuccess } = useToast();
  const push = useNavigate();
  const { controllerClearDataFilter } = useAppSelector((st) => st.controllers);
  const [isLoadingDailyConsumption, setLoadingReq] = useState(true);
  const { loading, request } = useAxios();
  const getDailyConsumption = async (dailyConsumption: DailyConsumption) => {
    setLoadingReq(true);
    dispatch(setDailyConsumptionChanged(false));
    try {
      const { data } = await axiosBase.post('/device/dailyconsumption', dailyConsumption);
      dispatch(setDailyConsumption(data?.data));
    } catch (error: any) {
      console.log('#ERROR', error?.response?.status);
      if (error?.response?.status === 401) {
        push('/login');
      } else {
        showError(t('something_went_wrong_toast_text'));
      }
    } finally {
      setLoadingReq(false);
    }
  };
  const getDailyConsumptionInitialdates = (objectID: number) =>
    request<undefined, { data: GraphDate; succeeded: boolean }>({
      method: 'get',
      showSuccessMessageCode: undefined,
      url: `/device/dailyconsumption/initialdates?objectID=${objectID}`,
      onDataReceiveCompleted: (data) => {
        if (data) {
          const result = {
            startDate: data?.data.graphDateStart,
            endDate: data?.data.graphDateEnd,
          };
          dispatch(setDailyConsumptionDefaultData(result));
        } else {
          showError(t('error_toast_text'));
        }
      },
    });
  const deleteDailyConsumption = async (currentController: Controller) => {
    setLoadingReq(true);
    dispatch(setDailyConsumptionChanged(false));
    try {
      const objectID = currentController ? currentController.deviceId : null;
      if (!objectID) return;
      const filter = {
        objectID,
        startDate: controllerClearDataFilter.startDate,
        endDate: controllerClearDataFilter.endDate,
        uncontrolledFlow: controllerClearDataFilter.uncontrolledFlow,
        actualConsumption: controllerClearDataFilter.actualConsumption,
      };
      await axiosBase.put('/device/dailyconsumption/clear', filter);

      showSuccess(t('daily_consumption_success_deleted_graph_data'));
    } catch (error: any) {
      console.log('#ERROR', error?.response?.status);
      if (error?.response?.status === 401) {
        push('/login');
      } else {
        showError(t('something_went_wrong_toast_text'));
      }
    } finally {
      setLoadingReq(false);
    }
  };
  const logDailyConsumption = async (dailyConsumptionParams:DailyConsumptionParams) => {

    try {
       await axiosBase.post('/device/dailyconsumption/logClearData', dailyConsumptionParams);
    } catch (error: any) {
      console.log('#ERROR', error?.response?.status);
      if (error?.response?.status === 401) {
        push('/login');
      } else {
        showError(t('something_went_wrong_toast_text'));
      }
    } 
  };
  return {
    getDailyConsumption,
    isLoadingDailyConsumption,
    deleteDailyConsumption,
    getDailyConsumptionInitialdates,
    logDailyConsumption,
    isLoadingDailyConsumptionInitialdates: loading,
  };
};

import { TextField, InputProps } from '@mui/material';
import { FC } from 'react';
import { inputStyles } from './styles';
import { autoFillColor } from 'shared/config';
import { useTranslation } from 'react-i18next';

export const AuthInput: FC<InputProps> = ({ sx, placeholder, type, value, onChange, error, name, autoComplete }) => {
  const { t } = useTranslation();

  const loginError = error && t('please_enter_your_login_valid_text');
  const passwordError = error && t('please_enter_your_password_valid_text');

  return (
    <>
      <input name={name} style={{ display: 'none' }} />
      <TextField
        value={value}
        onChange={onChange}
        type={type}
        fullWidth
        placeholder={placeholder}
        error={error}
        helperText={name === 'login' ? loginError : passwordError}
        autoComplete={'dlqwer'}
        sx={{
          ...inputStyles,
          '& .MuiInputBase-root': {
            border: '1px solid blue',
            borderColor: error ? 'error.main' : 'primary.main',
            borderRadius: '20px',
            pl: '20px',
            height: '60px',
            fontFamily: 'Noto Sans Hebrew',
          },
          input: {
            ...autoFillColor,
          },
          ...sx,
        }}
      />
    </>
  );
};

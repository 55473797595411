import { Box } from '@mui/material';
import { FC } from 'react';
import { SxProps } from '@mui/system';

type Props = {
  text: string;
  sx?: SxProps;
};

export const ModalLabel: FC<Props> = ({ text, sx }) => {
  return (
    <Box sx={{ fontSize: '16px', fontWeight: '600', whiteSpace: 'nowrap', lineHeight: '30px', mb: '16px' }}>{text}</Box>
  );
};

import { useAppSelector } from 'shared/hooks';
import { useAppDispatch } from 'shared/hooks';
import { setControllerClearDataFilter } from 'shared/slices';

export const useControllerClearDataFilter = () => {
  const { controllerClearDataFilter } = useAppSelector((st) => st.controllers);
  const fromDate = controllerClearDataFilter.startDate;
  const toDate = controllerClearDataFilter.endDate;
  // const actual = controllerClearDataFilter.actualConsumption;
  // const uncontrolled = controllerClearDataFilter.uncontrolledFlow;
  // const changeFromDate = (value: string) => {
  //   const result = { ...controllerClearDataFilter };
  //   result.startDate = value;
  //   dispatch(setControllerClearDataFilter(result));
  // };
  // const changeToDate = (value: string) => {
  //   const result = { ...controllerClearDataFilter };
  //   result.endDate = value;
  //   dispatch(setControllerClearDataFilter(result));
  // };
  // const setActual = (value: boolean) => {
  //   const result = { ...controllerClearDataFilter };
  //   result.actualConsumption = value;
  //   dispatch(setControllerClearDataFilter(result));
  // };
  // const setUncontrolled = (value: boolean) => {
  //   const result = { ...controllerClearDataFilter };
  //   result.uncontrolledFlow = value;
  //   dispatch(setControllerClearDataFilter(result));
  // };
  return {
    fromDate,
    toDate,
    // actual,
    // uncontrolled,
    // changeFromDate,
    // changeToDate,
    // setActual,
    // setUncontrolled,
  };
};

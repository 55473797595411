import { TableTitle, TranslateColumn } from 'components/BaseTable';
import { WATemplate } from 'features/CustomerManager/whatsAppModels';

export const TABLE_TITLES: TableTitle<WATemplate>[] = [
  { title: 'name', key: 'name', width: '15%' },
  { title: 'template_text', key: 'text', width: '100%' },
  { title: 'status', key: 'status', width: '10%' },
];

// export const TRANSLATE_COLUMNS: TranslateColumn<User>[] = [
//   { key: 'userStatus', additionalKey: '' },
//   { key: 'foreignProjectAccess', additionalKey: '_access_level' },
//   { key: 'backOfficeAccessLevel', translationMap: accessLevelItems },

// ]

// export const DATE_COLUMN_INDEXES = [5, 6, 7, 8, 9];

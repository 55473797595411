import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { axiosBase } from 'shared/config';
import { useAppDispatch, useToast } from 'shared/hooks';
import { setAboutInfo } from 'shared/slices';

export const useGetAbout = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { showError } = useToast();
  const push = useNavigate();

  const [isLoadingAbout, setLoading] = React.useState(false);

  const getAbout = async () => {
    setLoading(true);

    try {
      const { data } = await axiosBase.get('/About');
      dispatch(setAboutInfo(data.data));
    } catch (error: any) {
      console.log('#ERROR', error);
      if (error?.response?.status === 401) {
        push('/login');
      } else {
        showError(t('something_went_wrong_toast_text'));
      }
    } finally {
      setLoading(false);
    }
  };

  return {
    getAbout,
    isLoadingAbout,
  };
};

import { SelectChangeEvent } from '@mui/material';
import { checkCustomerChanges } from 'features/CustomerManager/customerHelpers';
import { Customer } from 'features/CustomerManager/customerModels';
import { useEffect, useMemo, useRef, useState } from 'react';
import { formatDtoDate } from 'shared/lib';


export const useAddCustomerChanges = (openAddCustomer:boolean) => {


  const initialValues: Customer = {
    id: 0,
    customerName: ' ',
    customerAgreementStatusId: 1,
    postAddress: '',
    eMailAddress: '',
    officePhoneNumber: '',
    contactPersonName: '',
    contactPersonPhoneNumber: '',
    createdDate: formatDtoDate(new Date(Date.now())),
    operationStartDate: formatDtoDate(new Date(Date.now())),
    customerTypeId: 1,
    customerStatusId: 1,
    notes: '',
    shortNote: '',
    siteUrlCode: 'DEFAULT',
    siteUrlCodeSec: 'DEFAULT-SEC',
  };
  const [values, setValues] = useState<Customer>(initialValues);

  const isTyped = useMemo(() => checkCustomerChanges(initialValues, values), [values]);
  useEffect(() => setValues(initialValues), [openAddCustomer]);

  const onChangeValues = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const onChangeDateValues = (name: string, value: string) => {
    setValues((prev) => ({ ...prev, [name]: value }));
  };
  const onChangeSelectValues = (e: SelectChangeEvent<unknown>) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const boxRef = useRef<HTMLDivElement>();

  const onEscape = (e: KeyboardEventInit) => {
    if (e.code === 'Escape') {
      // (boxRef.current as HTMLDivElement)?.click();
    }
  };

  useEffect(() => {
    if (openAddCustomer) {
      window.addEventListener('keyup', onEscape);
    } else {
      window.removeEventListener('keyup', onEscape);
    }
  }, [openAddCustomer]);

  return {
    values,
    isTyped,
    onChangeValues,
    onChangeDateValues,
    onChangeSelectValues,
    boxRef,
  };
};

import { BatteryStatusReportProject } from 'shared/models';
import { getFormattedDateAndTime } from 'shared/lib';
export type FormattedGraphData = {
  name?: string;
  red?: number;
  green?: number;
  blue?: number;
  violet?: number;
  dataIndex: number;
  eventDate?: Date;
  isDisrupted?: boolean;
  isDay?: number | null;
  isNight?: number | null;
  iconNight?: number | null;
  iconDay?: number | null;
  visualItems?: boolean;
};
export const useFormattedGraphData = (
  batteryStatusReportProject: BatteryStatusReportProject[] | [],
  filterGraph: { fromDate: string; toDate: string },
) => {
  const formattedGraphData: FormattedGraphData[] = [];
  const getMinutes = (stamp: string | Date) => {
    return Math.round(new Date(stamp).getTime() / 60000);
  };
  const getMaxValue = (GraphData: FormattedGraphData[], key: string) => {
    let maxVal = -Infinity;
    for (let i = 0; i < GraphData.length; i++) {
      const currentVal = GraphData[i][key];
      if (currentVal !== null && currentVal !== undefined && !isNaN(currentVal)) {
        maxVal = Math.max(maxVal, currentVal);
      }
    }
    return maxVal;
  };

  const formatGraphData = (batteryStatusReportProject: BatteryStatusReportProject[] | []) => {
    const start = new Date().getTime();
    if (!batteryStatusReportProject.length) {
      return [];
    }
    const TYPES: Record<number, string> = {
      1702: 'violet',
      1701: 'blue',
      5: 'green',
      4: 'red',
    };
    // batteryStatusReportProject.sort((a: BatteryStatusReportProject, b: BatteryStatusReportProject) => {
    //   if (a.eventId === 1702) return 1;
    //   if (b.eventId === 1702) return -1;
    //   return 0;
    // });
    let lastBlueValue = 0;
    let lastVioletValue = -1;
    batteryStatusReportProject.forEach((item: BatteryStatusReportProject) => {
      if (item.eventId === 5) return;
      const result: FormattedGraphData | any = {
        name: '',
        blue: 0,
        green: 1,
        dataIndex: 0,
        iconDay: null,
      };
      result.name = getFormattedDateAndTime(item.eventDate);
      result.eventDate = item.eventDate;
      result.dataIndex = getMinutes(item.eventDate);
      //1701 1702 4 5
      result[TYPES[item.eventId as number]] = item.dataValue > -0.1 ? item.dataValue : 0;
      if (item.eventId == 4 || item.dataValue === 0) {
        delete result.green;
      }
      if (item.eventId == 1701) {
        lastBlueValue = item.dataValue;
        result.violet = lastVioletValue > -1 ? lastVioletValue : null;
      }
      if (item.eventId === 1702) {
        result.blue = lastBlueValue;
        result.violet = result.violet / 10;
        lastVioletValue = result.violet;
      }
      formattedGraphData.push(result);
    });
    const end = new Date().getTime();
    console.log(`formatGraphData: ${end - start}ms`);
  };
  const addFirstAndLastPoint = () => {};

  // const optimizedData = optimizeGraphData(batteryStatusReportProject);
  formatGraphData(batteryStatusReportProject);
  addFirstAndLastPoint();
  // if (formattedGraphData && formattedGraphData.length) {
  //   addedDayNightLine();
  // }
  return formattedGraphData;
};

import { IconButton, MenuItem, Stack } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useExcludePeriod } from './hooks';
import { toggleOpenNotSupportModal } from 'shared/slices';
import { BaseInput, BigTitle, Modal, Row, Select } from 'shared/ui';
import { BaseBtn } from '../../shared/ui';
import { Box } from '@mui/material';
import { close_popup } from 'shared/assets';
import { BaseDataPicker, Filter } from 'features';

type Props = {
	openModal: boolean;
	controllerName?: string;
	clientName?: string;
	onCloseModal: () => void
	onSubmit: (upToDate: string) => void
};

export const ExcludeFromReportModal: FC<Props> = ({ openModal, onCloseModal, onSubmit, controllerName, clientName }) => {
	const { t } = useTranslation();
	const closeModal = () => {
		onCloseModal()
		clearInputs()
	};
	const submit = () => {
		if (excludePeriod !== 'permanent') {
			onSubmit(upToDate)
		}
		if (excludePeriod === 'permanent') {
			onSubmit(null)
		}

		clearInputs()
	}
	const { excludePeriodItems,
		excludePeriod,
		onChangeExcludePeriod,
		upToDate,
		onChangeUpToDate,
		clearInputs
	} = useExcludePeriod();
	return (
		<Modal sx={{ width: 600, margin: 'auto' }} padding={'20px'} open={openModal} onClose={closeModal}>
			<Stack direction="row" justifyContent="space-between" alignItems="center">
				<BigTitle >{t('exclude_from_report_modal')}</BigTitle>
				<IconButton onClick={closeModal}>
					<img src={close_popup} />
				</IconButton>
			</Stack>
			<Box>
				<Row spacing="20px" alignItems="flex-start" justifyContent="space-between">
					<Box width={'50%'}>
						{controllerName && <BaseInput
							label={`${t('controller_name_exclude_from_report_modal')}`}
							name="controllerName"
							disabled
							helperText=""
							value={controllerName}
							small
						/>}
						{clientName && <BaseInput
							label={`${t('client_name_exclude_from_report_modal')}`}
							name="clientName"
							disabled
							helperText=""
							value={clientName}
							small
						/>}
					</Box>
					<Box width={'50%'}>
					</Box>
				</Row>
				<Row spacing="20px" alignItems="flex-start" justifyContent="space-between">
					<Box width={'50%'}>
						<Select
							label={`${t('exclude_period_label')}`}
							name="ExcludePeriod"
							value={excludePeriod}
							onChange={onChangeExcludePeriod}
							displayEmpty
							sx={{
								fontFamily: 'Noto Sans Hebrew !important',
							}}
							small
						>
							{excludePeriodItems.map((a) => (
								<MenuItem key={a.label} value={a.value}>
									{t(a.label)}
								</MenuItem>
							))}
						</Select>
					</Box>
					<Box width={'50%'}>
						<Box sx={{
							mt: '20px'
						}}>
							<BaseDataPicker
								label={`${t('up_to_date_label')}`}
								name="upToDate_exclude_from_report_modal"
								showFuture
								disablePast
								disabled={excludePeriod !== 'custom'}
								value={upToDate}
								onChange={onChangeUpToDate}
								formStyle
							/>
						</Box>
					</Box>
				</Row>
				<Box component="form" autoComplete="off">
					<Row justifyContent="flex-end" mt={'35px'}>
						<Row width={300}>
							<BaseBtn onClick={closeModal} btnColor="info.main" fullWidth>
								{t('cancel_edit_modal')}
							</BaseBtn>
							<Box sx={{ height: '40px', width: '25px' }} />
							<BaseBtn onClick={submit} btnColor="primary.dark" fullWidth>
								{t('save_edit_modal')}
							</BaseBtn>
						</Row>
					</Row>
				</Box>
			</Box>
		</Modal>
	);
};

import { Box, Checkbox, Tooltip } from '@mui/material';
import { BaseExportToExcel, BaseMultiselect, BaseTable, CustomerSelect, PagePreloader } from 'components';
import { t } from 'i18next';
import localStorageService from 'shared/localStorage';
import { BaseBtn, BaseInput, BigTitle, Icon, Row, SearchInput, TotalRows } from 'shared/ui';
import { useFilter, useFormatXlsxData, useSearch, useServiceCallExcluding, useServiceCalls } from './hooks';
import { useEffect, useMemo, useState, useTransition } from 'react';
import { useAppSelector } from 'shared/hooks';
import { Filter } from 'features/Filter';
import { BaseDataPicker } from 'features/BaseDataPicker';
import { ServiceCall } from './ServiceCallModels';
import { action_start, cancel, checked_box, delete_icon, un_checked_box } from 'shared/assets';
import { TABLE_TITLES, TRANSLATE_ARRAY_COLUMNS } from './constants';
import { Controller } from 'features/ControllerManager/controllerModels';
import { EditController } from 'features/ControllerManager/components';
import { SelectWithDelete } from 'features/SelectWithDelete';
import { useBatteryStatusReport } from 'shared/api';
import { ChangeExpirationDate } from 'features/ExternalPowerProblemsReportManager/components/ChangeExpirationDate';
import { ExcludeFromReportModal } from 'widgets';

export const ServiceCallsReportManager = () => {
  const { searchValue, changeSearch } = useSearch();
  const { getServiceCalls, getFaultEquipment, getActionType, getAssignedTech, isLoading } = useServiceCalls();
  const { allServiceCalls } = useAppSelector((st) => st.allServiceCalls);
  const [controllerName, setControllerName] = useState<string>('');
  const [clientName, setClientName] = useState<string>('');
  const [excludeParamType, setExcludeParamType] = useState<'objectId' | 'projectId'>('objectId');
  const [excludeParamID, setExcludeParamID] = useState(0);
  const [openExcludeFromReportModal, setOpenExcludeFromReportModal] = useState(false);
  const [isLoadedItems, setIsLoadedItems] = useState(false);

  useEffect(() => {
    const fetchData = () => {
      updateServiceCalls();
      getFaultEquipment();
      getActionType();
      getAssignedTech();
      getServiceCallExcludeDevice();
      getServiceCallExcludeCustomer();
    };
    fetchData();
  }, []);
  const { formatTable } = useFormatXlsxData();
  const updateServiceCalls = async () => {

    const result = await getServiceCalls(startDate, endDate, minCallCount);
    console.log("result", result)
    if (!result?.data.length) {
      setIsLoadedItems(true)
    }

  };
  const updateByDatesIntervalFilter = (value) => {
    const { startDate, endDate } = onChangeDatesIntervalFilter(value)

    getServiceCalls(startDate, endDate, minCallCount);
  }
  const [isPending, startTransition] = useTransition();
  const {
    startDate,
    endDate,
    minCallCount,
    onChangeStartDate,
    onChangeEndDate,
    onChangeMinCallCount,
    faultEquipmentValue,
    onChangeFaultEquipment,
    faultEquipmentItems,
    actionTypeValue,
    onChangeActionType,
    actionTypeItems,
    assignedTechValue,
    onChangeAssignedTech,
    assignedTechItems,
    callStatusValue,
    onChangeCallStatus,
    callStatusItems,
    onChangeDatesIntervalFilter,
    datesIntervalFilter,
    datesIntervalItems
  } = useFilter();
  const { excludeControllers, excludeProjects } = useAppSelector((st) => st.batteryStatusReport);
  const getSearchedServiceCalls = (serviceCalls: ServiceCall[], searchValue: string) => {
    const searched = serviceCalls.filter((c) => {
      const values = Object.values(c);
      return values.some((v) => v?.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase().trim()));
    });
    return searched;
  };
  const {
    getServiceCallExcludeDevice,
    getServiceCallExcludeCustomer,
    deleteExcludedController,
    deleteExcludedProject,
    addServiceCallExcludeController,
    addServiceCallExcludeProject,
  } = useServiceCallExcluding();
  const changedServiceCalls = () => {
    updateServiceCalls();
  };
  const toggleAdvancedParameters = () => {
    setAdvancedParameters(!advancedParameters);
  };
  const [advancedParameters, setAdvancedParameters] = useState(false);
  const [selectedController, setSelectedController] = useState<Controller>(null);
  const [openControllerEditModal, setOpenControllerEditModal] = useState(false);
  const handleOpenControllerEditModal = (row: ServiceCall) => {
    const currentController: Controller = {
      customerId: row.customerID,
      controllerName: row.controllerName,
      customerName: row.customerName,
      deviceId: row.controllerID,
      hwId: row.hwid,
      modelId: null,
      objectName: row.customerName,
      stationId: null,
      gatewayId: null,
      driverCode: '',
      objStatus: 0,
      isObjectDisabled: false,
      objectNotes: '',
      installAddress: '',
      gisHorisontal: '',
      gisVertical: '',
      latitudeDec: 0,
      longitudeDec: 0,
      installDate: '',
      powerConnectDate: '',
      id: row.id,
    };

    setSelectedController(currentController);
    setOpenControllerEditModal(true);
  };

  const filteredServiceCalls = useMemo(() => {
    console.log("allServiceCalls", allServiceCalls)
    const searchedServiceCalls = getSearchedServiceCalls(allServiceCalls, searchValue);
    let filteredServiceCalls = searchedServiceCalls;

    if (faultEquipmentValue.length) {
      filteredServiceCalls = filteredServiceCalls.filter((item) => {
        const faultEquipment = item.faultEquipment ? item.faultEquipment.split(',') : [];
        return faultEquipment.some((fault) => faultEquipmentValue.includes(fault));
      });
    }

    if (actionTypeValue.length) {
      filteredServiceCalls = filteredServiceCalls.filter((item) => {
        const actionType = item.actionType ? item.actionType.split(',') : [];
        return actionType.some((action) => actionTypeValue.includes(action));
      });
    }

    if (assignedTechValue.length) {
      filteredServiceCalls = filteredServiceCalls.filter((item) => {
        const assignedTech = item.assignedTech ? item.assignedTech.split(',') : [];
        return assignedTech.some((user) => assignedTechValue.includes(user));
      });
    }
    if (callStatusValue.length) {
      filteredServiceCalls = filteredServiceCalls.filter((item) => {
        if (callStatusValue.includes('complex calls') && item.lastDT_100) {
          return true
        }
        if (callStatusValue.includes('closed calls') && !item.lastDT_OPEN) {
          return true
        }
        if (callStatusValue.includes('open calls') && item.lastDT_OPEN) {
          return true
        }
        if (!callStatusValue.includes('closed calls') && !callStatusValue.includes('open calls')) {
          return false
        }

      });
    }
    if (allServiceCalls.length) {
      setIsLoadedItems(true)
    }
    return filteredServiceCalls;
  }, [searchValue, allServiceCalls, faultEquipmentValue, callStatusValue, actionTypeValue, assignedTechValue]);

  const onSelectFilter = () => {
    startTransition(() => { });
  };
  const closeControllerEditModal = () => {
    setOpenControllerEditModal(false);
  };

  const onDeleteExcludedController = async (value: string) => {
    await deleteExcludedController(value);
    await getServiceCallExcludeDevice();
    await updateServiceCalls();
    //await getPowerProblemsReport(filter);
  };
  const onDeleteExcludedProject = async (value: string) => {
    await deleteExcludedProject(value);
    await getServiceCallExcludeCustomer();
    await updateServiceCalls();
    //await getPowerProblemsReport(filter);
  };
  const [openChangeExpirationDate, setOpenChangeExpirationDate] = useState(false);
  const expirationDateClick = () => {
    setOpenChangeExpirationDate(true);
  };
  const closeChangeExpirationDateModal = () => {
    setOpenChangeExpirationDate(false);
  };

  const getTotalRows = () => {
    return filteredServiceCalls.reduce((sum, serviceCall) => sum + serviceCall.callsCount, 0);
  }



  const excludeController = (item: ServiceCall) => {
    const objectId = item.controllerID;
    setExcludeParamType('objectId');
    setExcludeParamID(objectId);
    setControllerName(item.controllerName);
    setOpenExcludeFromReportModal(true);
  };
  const excludeProject = (item: ServiceCall) => {
    const projectId = item.customerID;
    setExcludeParamType('projectId');
    setExcludeParamID(projectId);
    setClientName(item.customerName);
    setOpenExcludeFromReportModal(true);
  };

  const closeExcludeFromReportModal = () => {
    setOpenExcludeFromReportModal(false);
    setClientName('');
    setControllerName('');
  };

  const actions = useMemo(
    () => [
      { icon: delete_icon, title: `${t('exclude_controller_table_modal')}`, onClick: excludeController },
      { icon: delete_icon, title: `${t('exclude_project_table_modal')}`, onClick: excludeProject },
      { icon: cancel, title: `${t('cancel_table_modal')}`, onClick: () => { } },
    ],
    []
  );

  const excludeFromReport = async (upToDate: string) => {
    closeExcludeFromReportModal();
    if (excludeParamType === 'objectId') {
      await addServiceCallExcludeController(excludeParamID, upToDate);
      await getServiceCallExcludeDevice();
    }
    if (excludeParamType === 'projectId') {
      await addServiceCallExcludeProject(excludeParamID, upToDate);
      await getServiceCallExcludeCustomer();
    }

    // addBatteryStatusExcludeController,
    // addBatteryStatusExcludeProject,

    await getServiceCalls(startDate, endDate, minCallCount);
  };

  const BaseExportToExcelComponent = useMemo(
    () => (<BaseExportToExcel
      tableData={filteredServiceCalls}
      sheetName="ServiceCalls"
      fileName="ServiceCalls.xlsx"
      buttonText={t('export_service_calls_page_button')}
      formattedTable={formatTable(filteredServiceCalls)}
      tableTitles={TABLE_TITLES}
      headerItemsPrefix={'_service_calls_grid_table'}
    />)
    ,
    [filteredServiceCalls]
  );
  return (
    <>
      <PagePreloader loading={isLoading} />
      <Row sx={{ mb: '12px' }}>
        <BigTitle sx={{ mr: '25px' }}>{t('service_calls_report_manager_title')}</BigTitle>
      </Row>
      <SearchInput
        value={searchValue}
        onChange={(e) => changeSearch(e.target.value)}
        placeholder={`${t('search_service_calls_page')}`}
      />
      <Row sx={{ mt: '6px' }} flexWrap="wrap" rowGap="5px" alignItems="flex-end">
        <Box sx={{ width: '200px', mr: '20px' }}>
          <BaseDataPicker
            label={`${t('start_date_service_calls_filter_label')}`}
            name="startDate"
            value={startDate}
            onChange={(name, value) => {
              onChangeStartDate(value);
            }}
            error={null}
          />
        </Box>
        <Box sx={{ width: '200px', mr: '20px' }}>
          <BaseDataPicker
            label={`${t('end_date_service_calls_filter_label')}`}
            name="endDate"
            value={endDate}
            onChange={(name, value) => {
              onChangeEndDate(value);
            }}
            error={null}
          />
        </Box>

        <Filter
          title={`${t('dates_interval_service_calls_filter')}`}
          value={datesIntervalFilter}
          onChange={(value) => {
            updateByDatesIntervalFilter(value);
          }}
          items={datesIntervalItems}
          hideAllButton
          sx={{ maxWidth: '200px', minWidth: '200px' }}
          placeholder={`${t('filter_search_controller_page_placeholder')}`}
          messageEmptyFilter={`${t('select_interval_service_calls_placeholder')}`}
          hideResetButton
        />
        <Box sx={{ width: '200px', mr: '20px' }}>
          <BaseInput
            label={`${t('min_call_count_service_calls_label')}`}
            error={false}
            helperText={''}
            value={minCallCount}
            onChange={(event) => onChangeMinCallCount(parseInt(event.target.value))}
            small
            compact
            smallLabel
            type="number"
            labelMarginBottom={'0px'}
          />
        </Box>

        <Checkbox
          size="small"
          checked={advancedParameters}
          onChange={toggleAdvancedParameters}
          icon={<Icon src={un_checked_box} />}
          checkedIcon={<Icon src={checked_box} />}
        ></Checkbox>
        <Box sx={{ mr: "20px" }}>
          <Tooltip
            disableHoverListener={!excludeControllers.length && !excludeProjects.length}
            title={`${t('excluded_controllers_tooltip')} ${excludeControllers.length}
                ${t('excluded_projects_tooltip')} ${excludeProjects.length}`}
            placement="bottom"
          >
            <Box sx={{ mb: '5px' }}>
              {t('advanced_parameters_filter_battery_status_report')}
              {excludeControllers.length || excludeProjects.length ? <span>*</span> : <></>}
            </Box>
          </Tooltip>
        </Box>
        <Row sx={{ flexGrow: 1 }} />
        <TotalRows width={"140px"} count={getTotalRows()} label={t('total_service_calls_label')} />
        <TotalRows count={filteredServiceCalls.length} label={t('total_rows_service_calls_label')} />

        <Box
          sx={{
            marginLeft: 'auto',
          }}
        >

          {BaseExportToExcelComponent}
        </Box>
      </Row>
      <Row sx={{ mt: '6px' }} flexWrap="wrap" rowGap="5px" alignItems="flex-end">
        <BaseMultiselect
          title={`${t('fault_equipment_service_calls_filter')}`}
          values={faultEquipmentValue}
          onChange={onChangeFaultEquipment}
          applyFilter={onSelectFilter}
          items={faultEquipmentItems}
          withSearch
          hideValue
          sx={{ maxWidth: '200px', minWidth: '200px' }}
        />
        <BaseMultiselect
          title={`${t('action_type_service_calls_filter')}`}
          values={actionTypeValue}
          onChange={onChangeActionType}
          applyFilter={onSelectFilter}
          items={actionTypeItems}
          withSearch
          hideValue
          sx={{ maxWidth: '200px', minWidth: '200px' }}
        />
        <BaseMultiselect
          title={`${t('call_status_service_calls_filter')}`}
          values={callStatusValue}
          onChange={onChangeCallStatus}
          applyFilter={onSelectFilter}
          items={callStatusItems}
          withSearch
          hideValue
          sx={{ maxWidth: '200px', minWidth: '200px' }}
        />
        <BaseMultiselect
          title={`${t('assigned_tech_service_calls_filter')}`}
          values={assignedTechValue}
          onChange={onChangeAssignedTech}
          applyFilter={onSelectFilter}
          items={assignedTechItems}
          withSearch
          hideValue
          sx={{ maxWidth: '200px', minWidth: '200px' }}
        />
        {advancedParameters && (
          <>
            <SelectWithDelete
              title={`${t('excluded_controllers_filter')}`}
              onChange={onDeleteExcludedController}
              onExpirationDateClick={expirationDateClick}
              items={excludeControllers}
              sx={{ width: '260px' }}
              placeholder={`${t('filter_excluded_controllers_search_battery_status_page_placeholder')}`}
              tooltipText={`${t('controller_to_report_hint')}`}
            />
            <SelectWithDelete
              title={`${t('excluded_projects_filter')}`}
              onChange={onDeleteExcludedProject}
              onExpirationDateClick={expirationDateClick}
              items={excludeProjects}
              sx={{ width: '260px' }}
              placeholder={`${t('filter_excluded_projects_search_battery_status_page_placeholder')}`}
              tooltipText={`${t('restore_project_hint')}`}
            /></>)}
        <BaseBtn onClick={updateServiceCalls} sx={{ mt: '16px', height: '29px', mr: '20px' }}>
          {t('service_calls_action_update')}
        </BaseBtn>



      </Row>
      <Box>
        <BaseTable
          tableTitles={TABLE_TITLES}
          tableItems={filteredServiceCalls}
          loadedItems={isLoadedItems}
          idField={'id'}
          maxHeight="calc(100vh - 325px)"
          height="calc(100vh - 325px)"
          sortModel={[{ field: 'callsCount', sort: 'desc' }]}
          noItemsText={t('no_service_calls_grid_table')}
          headerItemsPrefix={'_service_calls_grid_table'}
          loading={isLoading}
          translateArrayColumns={TRANSLATE_ARRAY_COLUMNS}
          linkIndex={[3]}
          dateColumnIndexes={[9]}
          selectedRowID={selectedController?.id}
          cellClick={handleOpenControllerEditModal}
          defaultScrollWidth={22}
          actions={actions}
          serviceCallsLogic
        />
      </Box>
      <ExcludeFromReportModal
        openModal={openExcludeFromReportModal}
        controllerName={controllerName}
        clientName={clientName}
        onCloseModal={closeExcludeFromReportModal}
        onSubmit={excludeFromReport}
      />
      <ChangeExpirationDate openModal={openChangeExpirationDate} handleCloseModal={closeChangeExpirationDateModal} />
      <EditController
        currentController={selectedController}
        openControllerEditModal={openControllerEditModal}
        onCloseModal={() => {
          closeControllerEditModal();
        }}
        faultEquipmentCode={100}
        updateReport={changedServiceCalls}
        showRegistersButton={true}
        defaultTab={2}
        defaultCollapse={[]}
      />
    </>
  );
};

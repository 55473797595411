import { useState } from 'react';
export const usePlugins = () => {


	const getSize = (popupPadding, height) => {
		return {
			width: window.innerWidth - popupPadding,
			height: height,
		};
	}
	const [chartSize, setChartSize] = useState(getSize(160, 600));






	return {
		// tooltipsPlugin,
		// timelinePlugin,

		getSize,
		setChartSize,
		chartSize
	};
}
import { ScriptParam } from 'shared/models';

interface CustomUserAccountsItem extends ScriptParam {
  options: string | null;
}
export type TableTitle = { title: string; key: keyof CustomUserAccountsItem; width: number | string };

export const TABLE_TITLES: TableTitle[] = [
  { title: 'script_param_description', key: 'scriptParamDescription', width: '100%' },
  { title: 'scr_event', key: 'scrEvent', width: '100%' },
  { title: 'dst_event', key: 'dstEvent', width: '100%' },
  { title: '', key: 'options', width: '50%' },
];
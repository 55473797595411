 import { TableTitle, TranslateColumn} from 'components/BaseTable';
 import { WeatherStation } from '../WeatherStationModels';


export const TABLE_TITLES: TableTitle< WeatherStation >[] = [
  { title: 'object_id', key: 'objectID', width: 80 },
  { title: 'object_name', key: 'objectName', width: '100%' },
  { title: 'hwid', key: 'hwid', width: 80 },
  { title: 'station_type', key: 'stationType', width: '50%' },
  { title: 'et_max_value', key: 'eT_MaxValue', width: '50%' },
  { title: 'last_evaporation_value', key: 'lastEvaporationValue', width: '50%' },
  { title: 'last_evaporation_date', key: 'lastEvaporationDate', width: '50%' },
  { title: 'customer_id', key: 'customerID', width: 80 },
  { title: 'customer_name', key: 'customerName', width: '100%' },
  { title: 'customer_name_ex', key: 'customerName_EX', width: '100%' },
  { title: 'customer_id_ex', key: 'customerID_EX', width: '50%' },

  
];

export const TRANSLATE_COLUMNS: TranslateColumn <WeatherStation>[]=[
  {  key: 'stationType', additionalKey: '' },
]


import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AssignedCustomer, GeographicZone, IrrigationController, IrrigationFactorData, PlantType, RainData, WeatherStation } from './WeatherStationModels';

interface UserState {
  weatherStations: WeatherStation[];
  assignedCustomers: AssignedCustomer[];
  plantType:PlantType[];
  evaporationAndRainData:RainData[];
  irrigationFactorData:IrrigationFactorData[];
  irrigationControllers:IrrigationController[];
  geographicZones:GeographicZone[];
}

const initialState: UserState = {
  weatherStations: [],
  assignedCustomers: [],
  plantType:[],
  evaporationAndRainData:[],
  irrigationFactorData:[],
  irrigationControllers:[],
  geographicZones:[]
};

const weatherStation = createSlice({
  name: 'weatherStation',
  initialState,
  reducers: {
    setWeatherStation: (state, action: PayloadAction<WeatherStation[]>) => {
      state.weatherStations = action.payload;
    },
    setAssignedCustomers: (state, action: PayloadAction<AssignedCustomer[]>) =>{
      state.assignedCustomers = action.payload;
    },
    setPlantType: (state, action: PayloadAction<PlantType[]>) =>{
      state.plantType = action.payload;
    },
    setEvaporationAndRainData:(state, action: PayloadAction<RainData[]>) =>{
      state.evaporationAndRainData=action.payload;
    },
    setIrrigationFactorData:(state, action: PayloadAction<IrrigationFactorData[]>)=>{
      state.irrigationFactorData=action.payload;
    },
    setIrrigationControllers:(state, action: PayloadAction<IrrigationController[]>)=>{
      state.irrigationControllers=action.payload;
    },
    setGeographicZone:(state, action: PayloadAction<GeographicZone[]>)=>{
      state.geographicZones=action.payload;
    }
  },
});

export const {
	setWeatherStation,
  setAssignedCustomers,
  setPlantType,
  setEvaporationAndRainData,
  setIrrigationFactorData,
  setIrrigationControllers,
  setGeographicZone
 
} = weatherStation.actions;

export const weatherStationReducer = weatherStation.reducer;

import { Box, Typography } from '@mui/material';
import { FC } from 'react';

type Props = {
  id: string;
  name: string;
  label?: string;
};

export const TransferringConfirmInfo: FC<Props> = ({ id, name, label }) => {
  return (
    <Box
      sx={{
        width: '210px',
      }}
    >
      {label && (
        <Typography
          noWrap
          sx={{
            fontSize: '14px',
            fontWeight: 600,
            textTransform: 'capitalize',
            color: 'info.dark',
            marginBottom: '3px',
            textAlign: 'left',
          }}
        >
          {label}
        </Typography>
      )}
      <Box
        sx={{
          border: '1px solid black',
          borderRadius: '10px',
          borderColor: 'primary.dark',
          mr: '20px',
          pt: '6px',
          pb: '6px',
          pr: '16px',
          pl: '16px',
        }}
      >
        <Typography
          noWrap
          align={'left'}
          sx={{
            fontSize: '14px',
            fontWeight: 500,
            color: 'primary.dark',
            minWidth: '60px',
            lineHeight: '15px',
          }}
        >
          {id} - {name}
        </Typography>
      </Box>
    </Box>
  );
};

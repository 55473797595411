import useAxios from 'app/useAxios';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useToast } from 'shared/hooks';
import {  updateUser, } from '../../userSlice';
import { User } from 'features/UserManager/UserModels';

export const useUpdateUser = () => {
  const dispatch = useAppDispatch();
  const {loading, request } = useAxios();
  const { showError, showSuccess } = useToast();
  const { t } = useTranslation();
  return {
    updateBackofficeAccesslevelUser: (user: User) =>
      request<undefined, { data: User, succeeded:boolean }>({
        method: 'put',
        showSuccessMessageCode: undefined,
        url: `/user/backofficeaccesslevel`,
        onDataReceiveCompleted: (updatedUser) => {
          if(updatedUser.succeeded){
					dispatch(updateUser(updatedUser.data));
          showSuccess(t('user_successfully_updated_toast_text'));
          }else{
            showError(t('not_allowed'));
          }
        },
        payload: user,
      }),
      updateUser: (user: User) =>
      request<undefined, { data: User, succeeded:boolean }>({
        method: 'put',
        showSuccessMessageCode: undefined,
        url: `/user`,
        onDataReceiveCompleted: (updatedUser) => {
          if(updatedUser.succeeded){

            const newUser = { ...user};  
            newUser.eMailAddress=updatedUser.data.eMailAddress;
            newUser.phoneNumber=updatedUser.data.phoneNumber;
            newUser.isMainContact=updatedUser.data.isMainContact;
					dispatch(updateUser(newUser));
          showSuccess(t('user_successfully_updated_toast_text'));
          }else{
            showError(t('not_allowed'));
          }
        },
        payload: user,
      }),
    isLoadingCustomers: loading,
  };
};

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { axiosBase } from 'shared/config';
import { useAppDispatch, useToast } from 'shared/hooks';
import { Customer } from 'shared/models';
import { toggleAddCustomer, addNewCustomer, setCurrentCustomer } from 'shared/slices';

export const useAddCustomer = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { showError, showSuccess } = useToast();
  const push = useNavigate();

  const [isLoading, setLoading] = useState(false);

  const addCustomer = async (customerDto: Customer) => {
    setLoading(true);

    try {
      const { data } = await axiosBase.post('/Customer', customerDto);

      const customer: Customer = data?.data;

      dispatch(addNewCustomer(customer));

      showSuccess(t('customer_successfully_added_toast_text'));

      return true;
    } catch (error: any) {
      console.log('#ERROR', error);
      if (error.response.status === 401) {
        push('/login');
      } else {
        showError(t('error_toast_text'));
      }
      return false;
    } finally {
      setLoading(false);
    }
  };

  return { isLoading, addCustomer };
};

import { Box, Slider, Typography } from '@mui/material';
import { FC } from 'react';
import { SxProps } from '@mui/system';

interface Marks {
  value: number;
  label: string;
}
type Props = {
  sx?: SxProps;
  defaultValue?: number;
  step?: number;
  marks?: Marks[];
  label?: string;
  value: number;
  valueLabelDisplay?: 'auto' | 'on' | 'off';
  max?: number;
  min?: number;
  getAriaValueText?: (value: number, index: number) => string;
  handleChange: (value: number) => void;
};

export const BaseSlider: FC<Props> = ({
  sx,
  defaultValue,
  label,
  value,
  getAriaValueText,
  valueLabelDisplay,
  marks,
  max,
  min,
  step,
  handleChange,
  ...props
}) => {
  const changeValue = (_: Event, value: number) => {
    handleChange(value);
  };
  return (
    <Box
      sx={{
        mt: '0px',
        '.MuiSlider-markLabel': {
          fontFamily: 'Noto Sans Hebrew',
          fontSize: '8px',
          mt: '-5px',
        },
        ...sx,
      }}
    >
      {label && (
        <Typography
          noWrap
          sx={{
            fontSize: '14px',
            fontWeight: 600,
            textTransform: 'capitalize',
            color: 'primary.dark',
            marginBottom: '0px',
          }}
        >
          {label}
        </Typography>
      )}

      <Slider
        defaultValue={defaultValue}
        value={value}
        getAriaValueText={getAriaValueText}
        step={step}
        marks={marks}
        valueLabelDisplay={valueLabelDisplay}
        max={max}
        min={min}
        onChange={changeValue}
        {...props}
      />
    </Box>
  );
};

import { SelectChangeEvent } from "@mui/material";
import { t } from "i18next";
import { useState } from "react";
import dayjs from 'dayjs';

export const useExcludePeriod = () => {

  const excludePeriodItems = [
    {
      value: 'permanent',
      label: t('permanent_useExclude_period'),
    },
    {
      value: '1 weeks',
      label: t('1_week_useExclude_period'),
    },
    {
      value: '2 weeks',
      label: t('2_weeks_useExclude_period'),
    },
    {
      value: '1 month',
      label: t('1_month_useExclude_period'),
    },
    {
      value: '2 month',
      label: t('2_month_useExclude_period'),
    },
    {
      value: '6 month',
      label: t('6_month_useExclude_period'),
    },
    {
      value: 'custom',
      label: t('custom_useExclude_period'),
    },
  ];

  const [excludePeriod, setExcludePeriod] = useState<string>('permanent');
  const [upToDate, setUpToDate] = useState<string>('');
  const onChangeExcludePeriod = (e:  SelectChangeEvent<string>) => {
    setExcludePeriod( e.target.value)
    const today = dayjs();
    const dates={
      permanent: '',
      '1 weeks': today.add(1, 'week').format('YYYY-MM-DD'),
      '2 weeks': today.add(2, 'week').format('YYYY-MM-DD'),
      '1 month': today.add(1, 'month').format('YYYY-MM-DD'),
      '2 month': today.add(2, 'month').format('YYYY-MM-DD'),
      '6 month': today.add(6, 'month').format('YYYY-MM-DD'),
      'custom': today.format('YYYY-MM-DD')
    }
    setUpToDate(dates[e.target.value] )
  };
  const onChangeUpToDate= (name: string,value: string ) => {
  setUpToDate( value);
  }
  const clearInputs=()=>{
    setExcludePeriod('permanent')
    setUpToDate('');
  }
  return {
    excludePeriodItems,
    excludePeriod,
    onChangeExcludePeriod,
    upToDate,
		onChangeUpToDate,
    clearInputs
  };
};

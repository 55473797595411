import { Box, IconButton, MenuItem, Stack, useTheme } from '@mui/material';
import { BaseDataPicker } from 'features';
import { User, UserHistory } from 'features/UserManager/UserModels';
import { useActionHistory } from 'features/UserManager/hooks/api';
import { BaseExportToExcel, BaseMultiselect, BaseTable, PagePreloader } from 'components';
import { t } from 'i18next';
import { FC, startTransition, useEffect, useMemo, useState } from 'react';
import { close_popup, refresh } from 'shared/assets';
import { useAppDispatch, useAppSelector, useToast } from 'shared/hooks';
import { formatDtoDate, getFormattedDate } from 'shared/lib';
import { BaseBtn, BaseInput, BigTitle, Icon, Modal, Row, Select, Text } from 'shared/ui';
import { ChangeExpirationDateModal } from '../ChangeExpirationDateModal';
import { CreateNewPasswordModal } from '../CreateNewPasswordModal';
import { UserResetPasswordModal } from '../UserResetPasswordModal';
import { DEFAULT_ACTION_TYPE, TABLE_TITLES, TRANSLATE_COLUMNS, datesIntervalItems, expirationDateItems } from './constants';
import { setUserActionHistory } from 'features/UserManager/userSlice';
import { useFormatXlsxData } from './hooks/useFormatXlsxData';
import { UserHistoryInfoEditModal } from './components/UserHistoryInfoEditModal';

interface ModalState {
	isOpenModal: boolean
	currentUser: User
	closeModal: () => void
}





export const UserDetailsModal: FC<ModalState> = (props: ModalState) => {
	const { isOpenModal, closeModal, currentUser } = props;
	const [isOpenResetPasswordModal, setIsOpenResetPasswordModal] = useState(false);
	const [isOpenCreateNewPasswordModal, setIsOpenCreateNewPasswordModal] = useState(false);
	const [isOpenChangeExpirationDateModal, setIsOpenChangeExpirationDateModal] = useState(false);
	const [isPasswordExpirationDateChanged, setIsPasswordExpirationDateChanged] = useState(false);

	const [startDateInterval, setStartDateInterval] = useState('');
	const [endDateInterval, setEndDateInterval] = useState('');

	const [nextPasswordUpdate, setNextPasswordUpdate] = useState(currentUser.nextPasswordUpdate);
	const [datesIntervalFilter, setDatesIntervalFilter] = useState('');
	const [extendPasswordInterval, setExtendPasswordInterval] = useState('');
	const [selectedUserItem, setSelectedUserItem] = useState<UserHistory>(null);
	const [openUserHistoryInfoEditModal, setOpenUserHistoryInfoEditModal] = useState<boolean>(false);

	const [actionTypeValues, setActionTypeValues] = useState(DEFAULT_ACTION_TYPE)
	const { GetUserActionHistory, isLoading } = useActionHistory();
	const { userActionHistory } = useAppSelector((st) => st.users);
	const dispatch = useAppDispatch();
	const { formatTable } = useFormatXlsxData();
	const handleCloseEditModal = () => {
		setNextPasswordUpdate(currentUser.nextPasswordUpdate)
		setIsPasswordExpirationDateChanged(false)
		dispatch(setUserActionHistory([]));
		setExtendPasswordInterval('')
		setActionTypeValues(DEFAULT_ACTION_TYPE)
		closeModal();

	}
	const { palette } = useTheme();
	const onChangeNextPasswordUpdate = (name: string, value: string) => {
		setIsPasswordExpirationDateChanged(true)
		setExtendPasswordInterval("")
		setNextPasswordUpdate(value)
	}

	const onSelectFilter = () => {
		startTransition(() => { });
	};
	const onChangeActionType = (value) => {
		setActionTypeValues(value)
	}
	const handleGetUserActionHistory = () => {
		GetUserActionHistory(currentUser.userID, currentUser.userLogin, startDateInterval, endDateInterval);
	}
	useEffect(() => {
		if (isOpenModal) {
			const fetchData = () => {
				const today = new Date();
				const sixMonthsAgo = new Date(today);
				sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
				const startDate = formatDtoDate(sixMonthsAgo);
				const endDate = formatDtoDate(today);
				GetUserActionHistory(currentUser.userID, currentUser.userLogin, startDate, endDate);
			};
			fetchData();
			updateByDatesIntervalFilter('Half-year back');
			const newDate = new Date();
			setEndDateInterval(formatDtoDate(newDate));
		}
	}, [isOpenModal])

	const actionTypeItems = [
		{
			value: 'password_check_failed',
			label: t('password_check_failed_filter_item'),
		},
		{
			value: 'password_check_success',
			label: t('password_check_success_filter_item'),
		},
		{
			value: 'CHECK_MAIL',
			label: t('verification_by_email_filter_item'),
		},
		{
			value: 'CHECK_PHONE',
			label: t('verification_by_phone_filter_item'),
		},
		{
			value: 'CHANGE_PASSWORD_BY_USER',
			label: t('change_password_by_user_filter_item'),
		},
		{
			value: 'SEND_LINK_TO_RESET_PASSWORD',
			label: t('send_link_to_reset_password_filter_item'),
		},
		{
			value: 'CHANGE_PASSWORD_WITH_CODE',
			label: t('change_password_with_code_filter_item'),
		},
		{
			value: 'CHANGE_PASSWORD_BY_ADMIN',
			label: t('change_password_by_admin_filter_item'),
		}
	];
	const updateByDatesIntervalFilter = (value: string | unknown) => {
		if (typeof value === 'string') {
			setDatesIntervalFilter(value)
			const today = new Date();
			switch (value) {
				case "Half-year back": {
					const sixMonthsAgo = new Date(today);
					sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
					const startDate = formatDtoDate(sixMonthsAgo);
					const endDate = formatDtoDate(today);
					setStartDateInterval(formatDtoDate(startDate));
					setEndDateInterval(formatDtoDate(endDate));
					break;
				}
				case "Year back": {
					const lastYear = new Date(today);
					lastYear.setFullYear(lastYear.getFullYear() - 1);
					const startDate = formatDtoDate(lastYear);
					const endDate = formatDtoDate(today);
					setStartDateInterval(formatDtoDate(startDate));
					setEndDateInterval(formatDtoDate(endDate));
					break;
				}
				case "Previous year": {
					const previousYear = new Date(today.getFullYear() - 1, 0, 1);
					const previousYearEnd = new Date(today.getFullYear() - 1, 11, 31);
					const startDate = formatDtoDate(previousYear);
					const endDate = formatDtoDate(previousYearEnd);
					setStartDateInterval(formatDtoDate(startDate));
					setEndDateInterval(formatDtoDate(endDate));
					break;
				}
				case "Current year": {
					const currentYear = new Date(today.getFullYear(), 0, 1);
					const startDate = formatDtoDate(currentYear);
					const endDate = formatDtoDate(today);
					setStartDateInterval(formatDtoDate(startDate));
					setEndDateInterval(formatDtoDate(endDate));
					break;
				}
				default:
					break;
			}
		}
	}
	const updateByExtendPasswordInterval = (value: string | unknown) => {
		if (typeof value === 'string') {
			setExtendPasswordInterval(value)

			setIsPasswordExpirationDateChanged(true)
			switch (value) {
				case "one Year": {
					const newDate = new Date(currentUser.nextPasswordUpdate);
					newDate.setMonth(newDate.getMonth() + 12);
					setNextPasswordUpdate(formatDtoDate(newDate));
					break;
				}
				case "Half Year": {
					const newDate = new Date(currentUser.nextPasswordUpdate);
					newDate.setMonth(newDate.getMonth() + 6);
					setNextPasswordUpdate(formatDtoDate(newDate));
					break;
				}
				case "1 month": {
					const newDate = new Date(currentUser.nextPasswordUpdate);
					newDate.setMonth(newDate.getMonth() + 1);
					setNextPasswordUpdate(formatDtoDate(newDate));
					break;
				}
				case "1 week": {
					const newDate = new Date(currentUser.nextPasswordUpdate);
					newDate.setDate(newDate.getDate() + 7);
					setNextPasswordUpdate(formatDtoDate(newDate));
					break;
				}
				default:
					break;
			}
		}
	}


	const filteredUserActionHistory = useMemo(() => {
		const filteredItems = userActionHistory.filter(item => {
			if (!actionTypeValues.length) {
				return true
			}
			if (!actionTypeValues.includes('password_check_failed') && !item.status && item.actionCode === "CHECK_PASSWORD") {
				return false
			}
			if (actionTypeValues.includes('password_check_failed') && !item.status && item.actionCode === "CHECK_PASSWORD") {
				return true
			}
			if (!actionTypeValues.includes('password_check_success') && item.status === 1 && item.actionCode === "CHECK_PASSWORD") {
				return false
			}
			if (actionTypeValues.includes('password_check_success') && item.status === 1 && item.actionCode === "CHECK_PASSWORD") {
				return true
			}
			if (actionTypeValues.includes(item.actionCode)) {
				return true
			}
			return false
		})


		return filteredItems;
	}, [userActionHistory, actionTypeValues]);

	const changeExpirationDate = () => {
		setIsOpenChangeExpirationDateModal(true)
	}
	const sendPasswordResetLink = () => {
		setIsOpenResetPasswordModal(true)
	}

	const createNewPassword = () => {
		setIsOpenCreateNewPasswordModal(true)
	}
	const handleCloseResetPasswordModal = () => {
		setIsOpenResetPasswordModal(false)
	}

	const handleCloseCreateNewPasswordModal = () => {
		setIsOpenCreateNewPasswordModal(false)
	}

	const handleCloseChangeExpirationDateModal = () => {
		setIsOpenChangeExpirationDateModal(false)
	}
	const handleCloseUserHistoryInfoEditModal = () => {
		setOpenUserHistoryInfoEditModal(false);
	}
	const onChangeStartDateInterval = (name, value) => {
		updateByDatesIntervalFilter('');
		setStartDateInterval(value)
	}

	const onChangeEndDateInterval = (name, value) => {
		updateByDatesIntervalFilter('');
		setEndDateInterval(value)
	}

	const BaseExportToExcelComponent = useMemo(
		() => (<BaseExportToExcel
			tableData={filteredUserActionHistory}
			sheetName="userActionHistory"
			fileName="userActionHistory.xlsx"
			buttonText={t('export_user_action_history_page_button')}
			dateAndTimeColumnIndexes={[0]}
			formattedTable={formatTable(filteredUserActionHistory)}
			tableTitles={TABLE_TITLES}
			headerItemsPrefix={'_user_history_grid_table'}
			width="100px"
			sortModel={{ field: t('action_date_user_history_grid_table'), sort: 'desc' }}
		/>)
		,
		[filteredUserActionHistory]
	);

	const handleOpenUserHistoryInfoEditModal = (row: UserHistory) => {

		setSelectedUserItem(row);
		setOpenUserHistoryInfoEditModal(true);
	}
	return (
		<Modal open={isOpenModal} sx={{ margin: 'auto', width: '1300px' }} width='1300px' onClose={handleCloseEditModal}>
			<Stack direction="row" justifyContent="space-between" alignItems="center">
				<BigTitle>
					<span>{t('user_authentication_details_title')}</span>
				</BigTitle>
				<IconButton onClick={handleCloseEditModal}>
					<img src={close_popup} />
				</IconButton>
			</Stack>
			<Box>
				<Row sx={{ mb: '16px' }} alignItems="flex-start" justifyContent="space-between">
					<Box width={'24%'}>
						<BaseInput
							label={`${t('customer_id_label_authentication_details_modal')}`}
							name="customerID"
							onChange={(event) => { }}
							value={currentUser.customerID}
							small
							compact
							helperText={''}
							disabled
						/>
					</Box>
					<Box width={'24%'}>
						<BaseInput
							label={`${t('user_id_label_authentication_details_modal')}`}
							name="UserID"
							onChange={(event) => { }}
							value={currentUser.userID}
							small
							compact
							helperText={''}
							disabled
						/>
					</Box>
					<Box width={'24%'}>
						<BaseInput
							label={`${t('user_name_label_authentication_details_modal')}`}
							name="userName"
							onChange={(event) => { }}
							value={currentUser.userName}
							small
							compact
							helperText={''}
							disabled
						/>
					</Box>
					<Box width={'24%'}>
						<BaseInput
							label={`${t('user_login_label_authentication_details_modal')}`}
							name="UserLogin"
							onChange={(event) => { }}
							value={currentUser.userLogin}
							small
							compact
							helperText={''}
							disabled
						/>
					</Box></Row>
			</Box>
			<Box >
				<Box sx={{ mb: '20px' }}>
					<Text sx={{ fontSize: '20px', m: 0, fontWeight: 900, color: 'primary.dark' }}>{t('password_expiration')}</Text>
					<Row alignItems="flex-end" justifyContent="space-between">
						<Box width={'24%'}>
							<BaseInput
								label={`${t('last_password_update_label_authentication_details_modal')}`}
								name="last_password_update"
								onChange={(event) => { }}
								value={getFormattedDate(currentUser.lastPasswordTeamUpdate)}
								small
								compact
								helperText={''}
								disabled
							/>
						</Box>
						<Box width={'1%'}></Box>
						<Box width={'24%'}>
							<BaseDataPicker
								label={`${t('next_password_update_label_authentication_details_modal')}`}
								name="NextPasswordUpdate"
								value={nextPasswordUpdate}
								onChange={onChangeNextPasswordUpdate}
								formStyle
								showFuture
							/>
						</Box>
						<Box width={'1%'}></Box>
						<Box width={'24%'}>
							<Select
								label={`${t('next_password_update_interval_authentication_details_modal')}`}
								name="currentStatusCode"
								value={extendPasswordInterval}
								onChange={(value) => {
									updateByExtendPasswordInterval(value.target.value);
								}}
								displayEmpty
								small
								sx={{
									fontFamily: 'Noto Sans Hebrew !important',
								}}
								marginTop="0px"
								marginBottom="0px"
							>
								{expirationDateItems.map((a) => (
									<MenuItem key={a.value} value={a.value}>
										{t(a.label)}
									</MenuItem>
								))}
							</Select>
						</Box>
						<Box width={'1%'}></Box>
						<Box width={'24%'}>
							<BaseBtn sx={{ height: '29px', width: '100%' }} disabled={!isPasswordExpirationDateChanged} btnColor="primary.dark" onClick={changeExpirationDate}>
								{t('change_expiration_date_button')}
							</BaseBtn>
						</Box>
					</Row>
				</Box>

				<Box sx={{ mb: '20px' }}>
					<Text sx={{ fontSize: '20px', m: 0, fontWeight: 900, color: 'primary.dark' }}>{t('update_user_pasword')}</Text>
					<Row alignItems="flex-start" sx={{ mt: '4px' }} justifyContent="space-between" >

						<Box width={'24%'}>
							<BaseBtn sx={{ height: '29px', width: '100%' }} btnColor="primary.dark" onClick={sendPasswordResetLink}>
								{t('send_password_reset_link_button')}
							</BaseBtn></Box>
						<Box width={'1%'}></Box>
						<Box width={'24%'}>
							<BaseBtn sx={{ height: '29px', width: '100%' }} btnColor="primary.dark" onClick={createNewPassword}>
								{t('create_new_password_button')}
							</BaseBtn></Box>
						<Box width={'1%'}></Box>
						<Box width={'24%'}>
						</Box>
						<Box width={'24%'}>
						</Box>
						<Box width={'1%'}></Box>
					</Row>
				</Box>
				<Box sx={{ mb: '20px' }}>
					<Row alignItems="flex-start" justifyContent="space-between">
						<Text sx={{ fontSize: '20px', m: 0, fontWeight: 900, color: 'primary.dark' }}>{t('action_history')}</Text>
						<BaseBtn sx={{ height: '29px', width: "100px" }} btnColor="primary.dark" onClick={handleGetUserActionHistory}>
							<Row >
								<Icon src={refresh} />
								<Text
									noWrap
									sx={{
										fontSize: '14px',
										height: '24px',
										margin: '0 4px',
										fontFamily: 'Noto Sans Hebrew !@important',

									}}
								>
									{t('update_user_history_button')}
								</Text>
							</Row>

						</BaseBtn>
					</Row>

					<Row alignItems="flex-start" justifyContent="space-between" >
						<Box width={'21.8%'}>
							<BaseDataPicker
								label={`${t('start_dates_interval_authentication_details_modal')}`}
								name="startDateInterval"
								value={startDateInterval}
								onChange={onChangeStartDateInterval}
								formStyle
								showFuture
							/>
						</Box>
						<Box width={'1%'}></Box>

						<Box width={'21.8%'}>
							<BaseDataPicker
								label={`${t('end_dates_interval_authentication_details_modal')}`}
								name="endDateInterval"
								value={endDateInterval}
								onChange={onChangeEndDateInterval}
								formStyle
								showFuture
							/>
						</Box>
						<Box width={'1%'}></Box>
						<Box width={'21.8%'}>
							<Select
								label={`${t('dates_interval_authentication_details_modal')}`}
								name="currentStatusCode"
								value={datesIntervalFilter}
								onChange={(value) => {
									updateByDatesIntervalFilter(value.target.value);
								}}
								displayEmpty
								small
								sx={{
									fontFamily: 'Noto Sans Hebrew !important',
								}}
								marginTop="0px"
								marginBottom="0px"
							>
								{datesIntervalItems.map((a) => (
									<MenuItem key={a.value} value={a.value}>
										{t(a.label)}
									</MenuItem>
								))}
							</Select>
						</Box>

						<Box width={'1%'}></Box>
						<Box width={'21.8%'}>
							{!isLoading && <BaseMultiselect
								title={`${t('action_type_filter')}`}
								values={actionTypeValues}
								onChange={onChangeActionType}
								applyFilter={onSelectFilter}
								items={actionTypeItems}
								withSearch
								hideValue
								formInput
								hideLayoutMargin
								fullWidth
								marginRight={'0px'}
								boxWidth={'235px'}
								listHeight={215}

							/>}
						</Box>
						<Box width={'1%'}></Box>
						<Row sx={{ flexGrow: 1 }} />
						<Box sx={{ mt: '10px' }}>
							{BaseExportToExcelComponent}
						</Box>
					</Row>
				</Box>
				<Box>
					<PagePreloader loading={isLoading} />
					<BaseTable
						dateTimeColumnIndexes={[0]}
						tableTitles={TABLE_TITLES}
						tableItems={filteredUserActionHistory}
						translateColumns={TRANSLATE_COLUMNS}
						loadedItems={true}
						sortModel={[{ field: 'actionDate', sort: 'desc' }]}
						idField={'actionDate'}
						noItemsText={t('no_items_user_history_grid_table')}
						headerItemsPrefix={'_user_history_grid_table'}
						height={'310px'}
						maxHeight={'310px'}
						defaultScrollWidth={24}
						userDetailsLogic
						linkIndex={[0]}
						cellClick={handleOpenUserHistoryInfoEditModal}
						selectedRowID={selectedUserItem?.actionDate}
					/>
				</Box>
			</Box>
			<Box >

			</Box>
			<UserResetPasswordModal
				isOpenModal={isOpenResetPasswordModal}
				closeModal={handleCloseResetPasswordModal}
				currentUser={currentUser}
			/>
			<CreateNewPasswordModal
				isOpenModal={isOpenCreateNewPasswordModal}
				closeModal={handleCloseCreateNewPasswordModal}
				currentUser={currentUser}
			/>
			<ChangeExpirationDateModal
				isOpenModal={isOpenChangeExpirationDateModal}
				closeModal={handleCloseChangeExpirationDateModal}
				currentUser={currentUser}
			/>
			<UserHistoryInfoEditModal
				isOpenModal={openUserHistoryInfoEditModal}
				closeModal={handleCloseUserHistoryInfoEditModal}
				currentUserHistory={selectedUserItem}
			/>
		</Modal >
	);
};


import { Box, IconButton, Stack } from '@mui/material';
import { BaseTable } from 'components';
import { BaseSelect } from 'features';
import { User } from 'features/UserManager/UserModels';
import { useUserManagement } from 'features/UserManager/hooks/api';
import { t } from 'i18next';
import { FC, useEffect, useMemo, useState } from 'react';
import { close_popup } from 'shared/assets';
import { useAppSelector } from 'shared/hooks';
import { getSearchedAccounts } from 'shared/lib';
import { BaseBtn, BigTitle, Modal, Row, SearchInput } from 'shared/ui';
import { TABLE_TITLES } from './constants';
import { useSearchAccounts, useSelectProject } from './hooks';

interface ModalState {
  isOpenModal: boolean
  closeModal: () => void
  currentUser: User
}

export const NewAssociateAccountModal: FC<ModalState> = (props: ModalState) => {
  const { isOpenModal, closeModal, currentUser } = props;
  const { isLoading, addAssociatedAccount } = useUserManagement();
  const { projectSelected, onChangeProjectSelect, projectItems } = useSelectProject();
  const { users } = useAppSelector((st) => st.users);
  const [currentScreenUser, setCurrentScreenUser] = useState<User>(null);
  useEffect(() => {
    if (!isOpenModal) {
      onChangeProjectSelect('');
      setCurrentScreenUser(null);
      setSearchValue('')
    }
  }, [isOpenModal]);

  const { searchValue, changeSearch, setSearchValue } = useSearchAccounts();
  const tableAccounts = useMemo(() => {
    const filteredAccounts = users.filter((item) => item.customerID == parseInt(projectSelected))
    const searchedAccounts = getSearchedAccounts(filteredAccounts, searchValue);
    return searchedAccounts;
  }, [projectSelected, searchValue]);

  const handleCloseEditModal = () => {
    closeModal();
  };
  const handleSave = async () => {
    const data = {
      userId: currentUser?.userID,
      associatedProjectId: parseInt(projectSelected),
      associatedUserId: currentScreenUser?.userID,
      accessCode: 1,
    };
    await addAssociatedAccount(data);
    closeModal();
  };
  const onSubmit = async () => {
    handleSave();
  };

  const handleSelectAccount = (selectAccount: User) => {
    setCurrentScreenUser(selectAccount);
  }
  return (
    <Modal open={isOpenModal} onClose={handleCloseEditModal}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <BigTitle>{t('associate_account_edit_modal')}</BigTitle>
        <IconButton onClick={handleCloseEditModal}>
          <img src={close_popup} />
        </IconButton>
      </Stack>
      <Row position="relative" justifyContent="space-between">
        <BaseSelect
          title={`${t('project_label_associate_account_edit_modal')}`}
          value={projectSelected}
          onChange={onChangeProjectSelect}
          items={projectItems}
          hideAllButton
          withSearch
          noSelectLabel={`${t('select_project_label_associate_account_edit_modal')}`}
          sx={{ width: '260px', mb: '12px' }}
        />
        <SearchInput
          value={searchValue}
          onChange={changeSearch}
          placeholder={`${t('search_associate_account_edit_modal')}`}
          sx={{ width: '400px' }}
        />
      </Row>

      {projectSelected ? <BaseTable
        tableTitles={TABLE_TITLES}
        tableItems={tableAccounts}
        loadedItems={true}
        idField={"userID"}
        noItemsText={t('no_users_sims_grid_table')}
        headerItemsPrefix={'_user_grid_table'}
        height={'50vh'}
        rowClick={handleSelectAccount}
        rowSelectionOnClick
        selectedRowID={currentScreenUser?.userID}
      />
        : <div style={{ width: '100%', height: '50vh' }}></div>}


      <Box component="form" autoComplete="off">
        <Row justifyContent="flex-end" mt={'35px'}>
          <Row width={300}>
            <BaseBtn onClick={handleCloseEditModal} btnColor="info.main" fullWidth>
              {t('cancel_edit_modal')}
            </BaseBtn>
            <Box onClick={handleCloseEditModal} sx={{ height: '40px', width: '25px' }} />
            <BaseBtn
              onClick={onSubmit}
              disabled={!projectSelected || !currentScreenUser}
              loading={isLoading}
              btnColor="primary.dark"
              fullWidth
            >
              {t('save_edit_modal')}
            </BaseBtn>
          </Row>
        </Row>
      </Box>
    </Modal>
  );
};

import { Customer } from 'shared/models';
import { useAppSelector } from 'shared/hooks';
import { makeDeepTrim } from 'shared/lib';
export const checkCustomerChanges = (initial: Customer, checking: Customer) => {
  const keys = Object.keys(initial);

  const changes = keys.map(
    (key) => (checking?.[key as keyof Customer] ?? '') !== (initial?.[key as keyof Customer] ?? '')
  );

  const isChanged = changes.includes(true);

  return isChanged;
};

export const getSearchedCustomers = (customers: Customer[], searchValue: string) => {
  const searched = customers.filter((c) => {
    const values = Object.values(c);

    return values.some((v) => v?.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase().trim()));
  });

  return searched;
};

export const getSortedCustomersByKey = (customers: Customer[], sortKey: keyof Customer) => {
  const sornFunc = (c1: Customer, c2: Customer) => {
    const v1 = c1?.[sortKey] ?? '';
    const v2 = c2?.[sortKey] ?? '';

    if (v1 > v2) return 1;
    if (v1 === v2) return 0;
    if (v1 < v2) return -1;

    return 0;
  };

  const copy = customers.map((c) => c);
  const sorted = copy.sort(sornFunc);

  return sorted;
};

export const getPrintColumns = (customers: Customer[]) => {
  const items = customers.map((c) => c);
  const { CUSTOMERS } = useAppSelector((st) => st.globalSlice);
  const PAGE_COLS = CUSTOMERS.PAGE_COLS;
  const COL_SIZE = CUSTOMERS.COL_SIZE;
  const colAmount = Math.ceil(items.length / COL_SIZE);

  const pages = [];

  for (let i = 0; i < colAmount; i++) {
    if (!items.length) break;

    const pageTables = [];

    for (let i = 0; i < PAGE_COLS; i++) {
      if (!items.length) break;

      const col = items.splice(0, COL_SIZE);
      pageTables.push(col);
    }

    pages.push(pageTables);
  }

  return pages;
};

export const getDeepTrimCustomerValues = (customer: Customer) => {
  const entries = Object.entries(customer).map((item) => {
    const [key, value] = item;
    const isStr = typeof value === 'string';

    if (!isStr) return item;

    const newValue = makeDeepTrim(value);
    return [key, newValue];
  });

  const trimCustomer = Object.fromEntries(entries);
  return trimCustomer;
};
